/**
 * Created by piotr.pozniak@thebeaverhead.com on 17/11/2021.
 */
import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { useUserStore } from "../../../storeHooks/user";
import { useProjectStore } from "../../../storeHooks/project";
import HeaderInput from "./HeaderInput";
import { hasRights, inputSetDateAsValue } from "../../../helpers/tools";
import { useBidsStore } from "../../../storeHooks/bids";
import BidLink from "./BidLink";
import AxiosConfig from "../../../AxiosConfig";
import axios from "axios";
import { useParams } from "react-router-dom";

const Header = (props) => {
  const { user } = useUserStore();
  const { project, editProject } = useProjectStore();

  const [state, setState] = useState({
    dueDateInputLastValue: "",
    editorial_format: "",
    final_format: "",
  });

  const routerParams = useParams();

  const [bidsCollection, setBidsCollection] = useState([]);

  const isRefreshing =
    project.fetch && project.model && project.model.id === routerParams.id;

  const projectModelInitialized = useMemo(() => {
    return project.model && project.model.id ? true : false;
  }, [project]);

  useEffect(() => {
    if (
      projectModelInitialized &&
      hasRights(user.model, {}, ["admin", "producer", "accountant"])
    ) {
      const url =
        AxiosConfig.getEndpointAddress() +
        "/api/bids.json?" +
        AxiosConfig.objectToURLQuery({
          page: 1,
          limit: 100,
          project_id: project.model.id,
        });

      axios
        .get(url, AxiosConfig.getAuthConfig())
        .then((response) => {
          setBidsCollection(response.data.bids);
        })
        .catch(function (error) {});
    }
  }, [projectModelInitialized]);

  const projectModel = project.model || {};
  const isLoading = project.edit || isRefreshing;

  /**
   *
   * @param field
   * @returns {(function(*): void)|*}
   */
  const onUpdateValue = (field) => async (value) => {
    const stateData = { ...state };
    stateData[field] = value;

    await setState(stateData);

    const data = {};
    data[field] = value;

    await editProject(project.model.id, data);

    const clearState = { ...state };
    clearState[field] = "";
  };

  const headerStats = (
    <div className={"col-12 col-lg-6 pr-4 pr-lg-2"}>
      <div className="mb-1">Delivery format</div>

      <div className="d-flex align-items-baseline relative z-index-10">
        <div className="font-weight-bold">Editorial:&nbsp;</div>
        <div className="ml-n5px">
          <b>
            <HeaderInput
              userModel={user.model}
              placeholder={"Format not specified"}
              value={state.editorial_format}
              isLoading={isLoading}
              defaultValue={projectModel.editorial_format}
              onUpdateValue={onUpdateValue("editorial_format")}
            />
          </b>
        </div>
      </div>

      <div className="d-flex align-items-baseline">
        <div className="font-weight-bold">Final:&nbsp;</div>
        <div className="ml-n5px">
          <b>
            <HeaderInput
              userModel={user.model}
              placeholder={"Format not specified"}
              value={state.final_format}
              isLoading={isLoading}
              defaultValue={projectModel.final_format}
              onUpdateValue={onUpdateValue("final_format")}
            />
          </b>
        </div>
      </div>
    </div>
  );

  const statsUnderTitle = (
    <div className="mt-2 relative z-index-20">
      Due Date:
      <div className="ml-n5px text-nowrap">
        <b>
          <HeaderInput
            userModel={user.model}
            placeholder={""}
            value={state.due_date}
            isLoading={project.fetch}
            defaultValue={projectModel.due_date}
            onUpdateValue={onUpdateValue("due_date")}
            inputType={"date"}
            valueFormatter={(val) =>
              Number.parseInt(val)
                ? new Date(val * 1000).toLocaleDateString()
                : ""
            }
            onFocus={(ref) => {
              inputSetDateAsValue(ref.current, projectModel.due_date);
              ref.current.focus();
            }}
            onFieldUpdate={(ref) => +new Date(ref.current.value) / 1000}
          />
        </b>
      </div>
    </div>
  );

  const projectBidLink =
    bidsCollection.length &&
    ["admin", "producer", "client"].includes(user.model.role) ? (
      <BidLink
        bidsCollection={bidsCollection}
        projectName={projectModel.name}
      />
    ) : (
      projectModel.name
    );

  return (
    <>
      <div className={"header-stats-editable-project row"}>
        <div className={"col-12 col-lg-6 mb-1 mb-xl-0 pr-4"}>
          <span className={"h1"}>{projectBidLink}</span>
          {statsUnderTitle}
        </div>
        {headerStats}
      </div>
      {isLoading && (
        <div className="h-0 fs-14 fs-089rem text-nowrap" style={{ width: 0 }}>
          <LoadingIndicator
            className="d-inline"
            label={isRefreshing ? "Refreshing..." : "Processing..."}
          />
        </div>
      )}
    </>
  );
};

Header.propTypes = {
  isLoading: PropTypes.bool,
  projectModel: PropTypes.object,
};

export default Header;
