import React, { Component } from "react";

import ReportsPageTemplate, {
  staticAlertBody,
  staticAlertHeader,
} from "./ReportsPageTemplate";
import connect from "react-redux/es/connect/connect";
import CustomizableDataTable from "../components/CustomizableDataTable";
import moment from "moment";
import StaticAlert from "../components/StaticAlert";
import * as invoicesReportsActions from "../actions/reportsInvoices";
import { formatter } from "../helpers/tools";
import { Link } from "react-router-dom";
import { initialState } from "../reducers/filtersReducer";
import StatusBadge from "../components/StatusBadge";

class InvoicesReportsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      diagramData: undefined,
      filter: {
        fromDate: props.filtersReducer.reportsInvoicesFromDate,
        toDate: props.filtersReducer.reportsInvoicesToDate,
        status: props.filtersReducer.reportsInvoicesStatus,
        clients: props.filtersReducer.reportsInvoicesClients,
      },
    };

    this.statuses = props.statuses.collection.filter(
      (i) => i.type === "Invoices"
    );

    this.dataTableConfig = {
      columns: [
        {
          name: "Project - Bid",
          selector: "name",
          sortable: true,
          format: (row) => (
            <Link className="relative" to={`/invoice/${row.bid_id}`}>
              {row.name}
            </Link>
          ),
        },
        {
          name: "Invoice",
          selector: "invoice",
          center: true,
        },
        {
          name: "Invoice status",
          selector: "status",
          center: true,
          format: (row) => (
            <StatusBadge status={row.status} statuses={this.statuses} />
          ),
        },
        {
          name: "Number of shots",
          selector: "number_of_shots",
          center: true,
        },
        {
          name: "Shots subtotal",
          selector: "shots_quote_subtotal",
          center: true,
          format: (row) => (
            <span>{formatter.format(row.shots_quote_subtotal)}</span>
          ),
        },
        {
          name: "Total",
          selector: "total",
          center: true,
          format: (row) => <span>{formatter.format(row.total)}</span>,
        },
        {
          name: "Notes",
          selector: "notes",
          center: true,
        },
      ],
    };
  }

  /**
   *
   * @returns {Promise<void>}
   */
  async componentDidMount() {
    await this.fetchInvoicesReports();
    this.setState({ diagramData: this.getDiagramData() });
  }

  /**
   *
   * @param filter
   */
  onFilter = async (filter) => {
    let fromDate;
    let toDate;

    filter.forEach((i) => {
      if (i.fieldName === "fromDate" && i.value) {
        fromDate = moment(i.value, "YYYY-M-D");
      } else if (i.fieldName === "toDate" && i.value) {
        toDate = moment(i.value, "YYYY-M-D");
      }
    });

    this.setState(
      {
        filter: {
          ...this.state.filter,
          fromDate: fromDate,
          toDate: toDate,
        },
        diagramData: undefined, // clear the old chart
      },
      async () => {
        await this.fetchInvoicesReports();
        this.setState({ diagramData: this.getDiagramData() });
      }
    );
  };

  /**
   *
   */
  onClearAll = () => {
    this.setState(
      {
        filter: {
          status: [],
          clients: [],
        },
      },
      () => {
        this.onFilter([
          {
            fieldName: "fromDate",
            value: initialState.reportsInvoicesFromDate.format("YYYY-M-D"),
          },
          {
            fieldName: "toDate",
            value: initialState.reportsInvoicesToDate.format("YYYY-M-D"),
          },
        ]);
      }
    );
  };

  /**
   *
   * @returns {Promise<Promise<*>|*>}
   */
  fetchInvoicesReports = async () => {
    return this.props.fetchInvoicesReports(
      this.state.filter.fromDate
        ? this.state.filter.fromDate.format("YYYY-MM-DD")
        : null,
      this.state.filter.toDate
        ? this.state.filter.toDate.format("YYYY-MM-DD")
        : null,
      this.state.filter.status ? this.state.filter.status.map((i) => i) : [],
      this.state.filter.clients ? this.state.filter.clients.map((i) => i) : []
    );
  };

  /**
   *
   */
  getDiagramData = () => {
    if (this.props.reportsInvoices.collection.length === 0) {
      return null;
    }

    const columns = {
      number_of_shots: [],
      shots_quote_subtotal: [],
      total: [],
      invoice_name: [],
    };

    this.props.reportsInvoices.collection.forEach((client) => {
      client.invoices.forEach((invoice) => {
        columns.number_of_shots.push(invoice.shots.length);
        columns.shots_quote_subtotal.push(
          invoice.shots_quote_subtotal_from_approved_versions
        );
        columns.total.push(invoice.total);
        columns.invoice_name.push(
          `${invoice.bid.project.name} - ${invoice.bid.name} - ${invoice.create_order}`
        );
      });
    });

    return {
      //new diagram types can be added in public_html_dev/js/app.js, see App.initBarDiagram(settings ? settings.barDiagram : settings);
      barDiagram: [
        {
          bindto: document.getElementById("jsReportsPageDiagram"),
          size: { height: 300 },
          data: {
            columns: [
              ["Number of shots", ...columns.number_of_shots],
              ["Shots subtotal", ...columns.shots_quote_subtotal],
              ["Total", ...columns.total],
            ],
            type: "bar",
            labels: {
              format: {
                "Shots subtotal": (v, id) => formatter.format(v),
                Total: (v, id) => formatter.format(v),
              },
            },
            axes: {
              Total: "y",
              "Shots subtotal": "y",
              "Number of shots": "y2",
            },
          },
          color: {
            pattern: ["#2196F3", "#FF9800", "#4CAF50"],
          },
          bar: {
            width: {
              ratio: 0.5,
            },
          },
          grid: {
            y: {
              show: true,
            },
          },
          axis: {
            x: {
              type: "category",
              categories: columns.invoice_name,
            },
            y2: {
              show: true,
              tick: {
                format: function (x) {
                  return x % 1 === 0 ? x : "";
                },
              },
            },
          },
          tooltip: {
            format: {
              value: (v, ratio, id, index) => {
                if (id === "Shots subtotal" || id === "Total") {
                  return formatter.format(v);
                }
                return v;
              },
            },
          },
        },
      ],
    };
  };

  /**
   *
   * @param val
   */
  statusFilterOnUpdate = (val) => {
    const status = val || [];
    this.setState({
      filter: { ...this.state.filter, status },
    });
  };

  /**
   *
   * @param val
   */
  clientsFilterOnUpdate = (val) => {
    const clients = val || [];
    this.setState({
      filter: { ...this.state.filter, clients },
    });
  };

  /**
   *
   * @returns {XML}
   */
  render() {
    const isLoading = this.props.reportsInvoices.fetch;

    const dataTableCollection = [];
    this.props.reportsInvoices.collection.forEach((client) => {
      client.invoices.forEach((invoice) => {
        dataTableCollection.push({
          name: `${invoice.bid.project.name} - ${invoice.bid.name}`,
          invoice: invoice.create_order,
          status: invoice.status,
          number_of_shots: invoice.shots.length,
          shots_quote_subtotal:
            invoice.shots_quote_subtotal_from_approved_versions,
          total: invoice.total,
          notes: "--",
          bid_id: invoice.bid.id,
          project_id: invoice.bid.project.id,
        });
      });
    });

    const content = dataTableCollection.length > 0 && (
      <CustomizableDataTable
        collection={dataTableCollection}
        columns={this.dataTableConfig.columns}
      />
    );

    // empty table alert
    const staticAlert = dataTableCollection.length === 0 && (
      <StaticAlert header={staticAlertHeader} body={staticAlertBody} />
    );
    //

    return (
      <ReportsPageTemplate
        header="Invoices reports"
        filters={[
          {
            placeholderFrom: "From date",
            placeholderTo: "To date",
            fieldNameFrom: "fromDate",
            fieldNameTo: "toDate",
            fieldName: "dateRange",
            defaultValueFrom: this.state.filter.fromDate
              ? this.state.filter.fromDate.format("YYYY-MM-DD")
              : "",
            defaultValueTo: this.state.filter.toDate
              ? this.state.filter.toDate.format("YYYY-MM-DD")
              : "",
            type: "dateRange",
            wrapperClassName: "col-sm-6",
          },
          {
            placeholder: "Clients",
            fieldName: "clients",
            type: "customerSelect",
            reactSelectProps: {
              isMulti: true,
              value: this.state.filter.clients,
            },
            onUpdate: this.clientsFilterOnUpdate,
            wrapperClassName: "col-sm-6",
          },
          {
            placeholder: "Status",
            fieldName: "status",
            type: "select",
            reactSelectProps: {
              isMulti: true,
              value: this.state.filter.status,
            },
            onUpdate: this.statusFilterOnUpdate,
            wrapperClassName: "col-sm-6",
            data: this.statuses,
          },
        ]}
        onFilter={this.onFilter}
        onClearAll={this.onClearAll}
        diagramData={this.state.diagramData}
        isLoading={isLoading}
      >
        {content}
        {staticAlert}
      </ReportsPageTemplate>
    );
  }
}

const mapStateToProps = (state) => ({
  reportsInvoices: state.reportsInvoices,
  statuses: state.statuses,
  filtersReducer: state.filtersReducer,
});

const mapDispatchToProps = (dispatch) => ({
  fetchInvoicesReports: async (
    dateFrom,
    dateTo,
    status,
    clients,
    type = "reportsInvoices"
  ) =>
    await dispatch(
      invoicesReportsActions.fetchInvoicesReports(
        dateFrom,
        dateTo,
        status,
        clients,
        type
      )
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoicesReportsPage);
