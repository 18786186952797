/**
 * Created by piotr.pozniak@thebeaverhead.com on 17/05/2022
 */

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useWorkloadDashboardStore } from "../../../storeHooks/workloadDashboard";
import LoadingIndicator from "../../../components/LoadingIndicator";
import Radio from "../../../components/Radio";
import { formatError } from "../../../helpers/error";
import BarDiagram from "./BarDiagram";

const DiagramPanel = (props) => {
  const { filters } = props;
  const [state, setState] = useState({
    isRelativeIndividual: true,
  });

  useEffect(() => {
    props.fetchAction(
      filters.artistId,
      filters.status,
      filters.clientId,
      filters.projectId
    );
  }, [filters]);

  /**
   *
   * @param e
   */
  const onChangeRelativness = useCallback((e) => {
    setState({
      ...state,
      isRelativeIndividual: e.target.value !== "all",
    });
  }, []);

  const loader = props.isLoading ? <LoadingIndicator isModal /> : null;

  return (
    <>
      {loader}
      <div className="tab-pane fade active show " id={props.tabId}>
        <label className="d-block font-weight-semibold">Adjust bar area</label>
        <div className={"form-group d-flex"}>
          <Radio
            checked={state.isRelativeIndividual}
            onChange={onChangeRelativness}
            value={"individual"}
            name={"relative_invididual"}
          >
            {props.relativeToItemOptionLabel}
          </Radio>
          <Radio
            checked={!state.isRelativeIndividual}
            className={"ml-3"}
            onChange={onChangeRelativness}
            value={"all"}
            name={"relative_invididual"}
          >
            {props.relativeToAllItemsOptionLabel}
          </Radio>
        </div>
        <BarDiagram
          workloadData={props.workloadData}
          statuses={props.statuses}
          valuesAlignedIndividually={state.isRelativeIndividual}
          itemName={props.itemName}
          totalHoursLabel={props.totalHoursLabel}
          totalShotsLabel={props.totalShotsLabel}
          individualFieldName={props.individualFieldName}
        />
      </div>
    </>
  );
};

DiagramPanel.defaultProps = {};

DiagramPanel.propTypes = {
  tabId: PropTypes.string,
  statuses: PropTypes.array,
  filters: PropTypes.shape({
    projectId: PropTypes.string,
    clientId: PropTypes.string,
    status: PropTypes.string,
    artistId: PropTypes.string,
  }),

  workloadData: PropTypes.array,
  fetchAction: PropTypes.func.isRequired,
  itemName: PropTypes.string.isRequired,
  totalHoursLabel: PropTypes.string.isRequired,
  totalShotsLabel: PropTypes.string.isRequired,
  individualFieldName: PropTypes.string.isRequired,
  relativeToItemOptionLabel: PropTypes.string.isRequired,
  relativeToAllItemsOptionLabel: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
};

export default DiagramPanel;
