import PropTypes from "prop-types";

const NumberOfShots = (props) => {
  const { invoice } = props;

  if (!invoice) {
    return "--";
  }

  const invoicedShots = invoice.number_of_invoices_shots
    ? invoice.number_of_invoices_shots
    : "0";
  const totalShots = invoice.number_of_shots ? invoice.number_of_shots : "0";

  return `${invoicedShots} / ${totalShots}`;
};

NumberOfShots.propTypes = {
  invoice: PropTypes.object,
};

export default NumberOfShots;
