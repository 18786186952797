import React, { Component } from 'react';

import PageTemplate from './PageTemplate';

export default class ProfilePage extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  /**
   *
   * @returns {XML}
   */
  render() {
    return (
      <PageTemplate>
        ProfilePage
      </PageTemplate>
    );
  }
}