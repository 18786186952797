/**
 * Created by piotr.pozniak@thebeaverhead.com on 24/05/2022.
 */
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as activitiesActions from "../actions/activities";

export function useActivitiesStore() {
  const dispatch = useDispatch();
  const _activities = useSelector((store) => store.activities);

  const getPreviousActivities = useCallback(
    async (start_from_activity_id, data) =>
      await dispatch(
        activitiesActions.getPreviousActivities(start_from_activity_id, data)
      ),
    [dispatch]
  );

  const connectActivitiesSocket = useCallback(
    async (data) =>
      await dispatch(activitiesActions.connectActivitiesSocket(data)),
    [dispatch]
  );

  const disconnectActivitiesSocket = useCallback(
    async () => await dispatch(activitiesActions.disconnectActivitiesSocket()),
    [dispatch]
  );

  return {
    activities: _activities,
    getPreviousActivities,
    connectActivitiesSocket,
    disconnectActivitiesSocket,
  };
}
