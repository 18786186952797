import React, {Component} from 'react';

import Validator from 'validatorjs';

import LoadingIndicator from '../components/LoadingIndicator';
import ApiError from '../components/ApiError';
import FormValidationError from '../components/FormValidationError';
import connect from "react-redux/es/connect/connect";

class PasswordChangeForm extends Component {
  constructor(props) {
    super(props);

    this.state = {formValidationErrors: null};
  }

  /**
   *
   * @param event
   */
  onSubmit(event) {
    event.preventDefault();

    this.setState({formValidationErrors: null});

    const editingProfile = this.props.editingProfile;

    const updatedProfile = {
      old_password: document.querySelector('.js-profile-edit-old-password').value,
      new_password: document.querySelector('.js-profile-edit-new-password').value,
    };

    const rules = {
      new_password: 'required',
      old_password: 'required',
    };
    const validation = new Validator(updatedProfile, rules);

    this.setState({formValidationErrors: validation.errors.all()});

    if (validation.passes()) {
      this.props.onSubmit(editingProfile.id, updatedProfile);
    }
  };

  /**
   *
   * @returns {XML}
   */
  render() {
    const isUserOwner = this.props.editingProfile.id === this.props.user.model.id;
    const isLoading = this.props.isLoading;
    const error = this.props.error || null;

    const loadingIndicator = isLoading ? <LoadingIndicator/> : <span/>;

    return isUserOwner ?
      <form noValidate>
        <legend className="text-uppercase font-size-sm font-weight-bold">Change Password</legend>

        <div className="row">
          <div className="col-sm-6">

            <div className="form-group">
              <label className="form-label">
                Old Password <span className="text-danger">*</span>
              </label>
              <input type="password" className="form-control js-profile-edit-old-password" placeholder="********"/>
            </div>

          </div>
          <div className="col-sm-6">

            <div className="form-group">
              <label className="form-label">
                New Password <span className="text-danger">*</span>
              </label>
              <input type="password" className="form-control js-profile-edit-new-password" placeholder="********"/>
            </div>

          </div>
        </div>

        <FormValidationError errors={this.state.formValidationErrors}/>
        <ApiError error={error}/>

        <div className="d-flex justify-content-between align-items-center">
          {loadingIndicator}
          <button disabled={isLoading} type="button" className="btn bg-primary"
                  onClick={this.onSubmit.bind(this)}>Change Password <i className="icon-key ml-2"/>
          </button>
        </div>

      </form> : null;
  }
}

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(
  mapStateToProps,
)(PasswordChangeForm);