import { shotsSortFn } from "../helpers/tools";

export const initialState = {
  create: false,
  createSuccess: false,
  createError: null,

  edit: false,
  editSuccess: false,
  editError: null,

  fetch: false,
  fetchSuccess: false,
  fetchError: null,

  update: false,
  updateSuccess: false,
  updateError: null,

  approve: false,
  approveSuccess: false,
  approveError: null,

  fetchShots: false,
  fetchShotsSuccess: false,
  fetchShotsError: null,

  model: null,
  shots: [],
};

export default function reducer(
  state = {
    ...initialState,
  },
  action
) {
  switch (action.type) {
    case "BID_VERSION_CREATE":
      return {
        ...state,
        create: true,
        createSuccess: false,
        createError: null,
      };

    case "BID_VERSION_CREATE_FULFILLED":
      return {
        ...state,
        create: false,
        createSuccess: true,

        model: action.data,
      };

    case "BID_VERSION_CREATE_REJECTED":
      return {
        ...state,
        create: false,
        createSuccess: false,
        createError: action.data,
      };

    case "BID_VERSION_EDIT":
      return {
        ...state,
        edit: true,
        editSuccess: false,
        editError: null,
      };

    case "BID_VERSION_EDIT_FULFILLED":
      return {
        ...state,
        edit: false,
        editSuccess: true,

        model: action.data,
      };

    case "BID_VERSION_EDIT_REJECTED":
      return {
        ...state,
        edit: false,
        editSuccess: false,
        editError: action.data,
      };

    case "BID_VERSION_FETCH":
      return {
        ...state,
        fetch: true,
        fetchSuccess: false,
        fetchError: null,
      };

    case "BID_VERSION_FETCH_FULFILLED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: true,

        model: action.data,
      };

    case "BID_VERSION_FETCH_REJECTED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: false,
        fetchError: action.data,
      };

    case "BID_BY_BID_SLUG_FETCH":
      return {
        ...state,
        fetch: true,
        fetchSuccess: false,
        fetchError: null,
      };

    case "BID_BY_BID_SLUG_FETCH_FULFILLED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: true,

        model: action.data.recent_bid_version,
        shots: action.data.shot_versions
          .map((version) => ({
            ...version.shot,
            versions: [version],
          }))
          .sort(shotsSortFn)
          .reverse(),
      };

    case "BID_BY_BID_SLUG_FETCH_REJECTED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: false,
        fetchError: action.data,
      };

    case "BID_VERSION_UPDATE":
      return {
        ...state,
        update: true,
        updateSuccess: false,
        updateError: null,
        approveError: null,
      };

    case "BID_VERSION_UPDATE_FULFILLED":
      return {
        ...state,
        update: false,
        updateSuccess: true,

        model: action.data,
      };

    case "BID_VERSION_UPDATE_REJECTED":
      return {
        ...state,
        update: false,
        updateSuccess: false,
        updateError: action.data,
      };

    case "BID_VERSION_APPROVE":
      return {
        ...state,
        approve: true,
        approveSuccess: false,
        approveError: null,
      };

    case "BID_VERSION_APPROVE_FULFILLED":
      return {
        ...state,
        approve: false,
        approveSuccess: true,

        model: action.data,
      };

    case "BID_VERSION_APPROVE_REJECTED":
      return {
        ...state,
        approve: false,
        approveSuccess: false,
        approveError: action.data,
      };

    case "SHOTS_BY_BID_VERSION_FETCH":
      return {
        ...state,
        fetchShots: true,
        fetchShotsSuccess: false,
        fetchShotsError: null,
      };

    case "SHOTS_BY_BID_VERSION_FETCH_FULFILLED":
      return {
        ...state,
        fetchShots: false,
        fetchShotsSuccess: true,

        shots: action.data.responseData.shots.sort(shotsSortFn).reverse(),
      };

    case "SHOTS_BY_BID_VERSION_FETCH_REJECTED":
      return {
        ...state,
        fetchShots: false,
        fetchShotsSuccess: false,
        fetchShotsError: action.data,
      };

    case "SHOTS_CREATE_BULK_FULFILLED":
      return {
        ...state,
        shots: [...state.shots, ...action.data.shots]
          .sort(shotsSortFn)
          .reverse(),
      };

    case "SHOTS_EDIT_BULK_FULFILLED":
      const shotsWithUpdatedCollection = state.shots.map((shot) => {
        const currentShotInUpdatedShotsCollection = action.data.shots.find(
          (updatedShot) => updatedShot.id === shot.id
        );
        return currentShotInUpdatedShotsCollection
          ? { ...currentShotInUpdatedShotsCollection, versions: shot.versions }
          : shot;
      });

      return {
        ...state,
        shots: shotsWithUpdatedCollection.sort(shotsSortFn).reverse(),
      };

    case "SHOT_DELETE_FULFILLED":
      let isDeleted = !!action.data.responseData.success;
      let shotsDeleted = isDeleted
        ? state.shots.filter((item) => item.id !== action.data.id)
        : state.shots;
      return {
        ...state,
        shots: shotsDeleted,
      };

    case "SHOT_VERSION_CREATE_FULFILLED":
      return {
        ...state,
        shots: state.shots.map((shot) =>
          shot.id === action.data.shot_id
            ? {
                ...shot,
                current_version: action.data,
                versions: [...shot.versions, action.data],
              }
            : shot
        ),
      };

    case "BID_VERSION_SET":
      return {
        ...initialState,
        model: action.data,
      };

    case "CLEAR_ERRORS":
      return {
        ...initialState,
        model: state.model,
      };

    case "LOGOUT":
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
