import axios from 'axios';
import AxiosConfig from '../AxiosConfig';


/**
 *
 * @param page
 * @param limit
 * @param filters
 * @param type
 * @returns {Function}
 */
export const fetchInvoices = (page = 1, limit = 20, filters, type) => {

  return async dispatch => {

    dispatch({
      type: "INVOICES_FETCH",
      data: {page, type, filters}
    });

    const apiFilters = filters || {};
    if (filters && filters.status) {
      apiFilters.status = filters.status.map(x => x.value || x);
    }
    if (filters && filters.clients) {
      apiFilters.clients = filters.clients.map(x => x.value || x);
    }

    const url = AxiosConfig.getEndpointAddress() + '/api/bids/invoices.json?'
      + AxiosConfig.objectToURLQuery({page, limit, ...apiFilters});

    await axios.get(
      url,
      AxiosConfig.getAuthConfig(),
    )
      .then((response) => {

        dispatch({
          type: "INVOICES_FETCH_FULFILLED",
          data: response.data,
        });

      })
      .catch(function (error) {

        dispatch({
          type: "INVOICES_FETCH_REJECTED",
          data: error,
        });

      });
  }
};
