import React, { memo } from "react";
import PropTypes from "prop-types";

import classNames from "classnames";
import { getInitials, stringToColour, lightOrDark } from "../helpers/tools";

const squareAvatarUserRoles = ["client"];

/**
 *
 * @param user
 * @returns {boolean}
 */
const isSquare = (user) => {
  return squareAvatarUserRoles.includes(user.role);
};

const UserAvatar = (props) => {
  const { user, size, showTitle, className, ...other } = props;

  if (!user) {
    return null;
  }

  const { name, email, avatar_color: color } = user;
  const initials = getInitials(name);
  const bgColor = color || stringToColour(email || name);
  const textColor = lightOrDark(bgColor) === "light" ? "black" : "white";

  const style = {
    backgroundColor: bgColor,
    color: textColor,
  };

  const propClass = className ? className : "";
  const avatarClassName = classNames(propClass, "d-avatar", size, {
    "d-avatar_square": isSquare(user),
  });

  return (
    <div
      className={avatarClassName}
      title={showTitle ? name : undefined}
      {...other}
      style={style}
    >
      {initials}
    </div>
  );
};

UserAvatar.defaultProps = {
  size: "xs",
};

UserAvatar.propTypes = {
  user: PropTypes.object,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  showTitle: PropTypes.bool,
  className: PropTypes.string,
};

export default memo(UserAvatar);
