/**
 * Created by piotr.pozniak@thebeaverhead.com on 31/01/2022.
 */
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as supplierActions from "../actions/supplier";

export function useSupplierStore() {
  const dispatch = useDispatch();
  const _supplier = useSelector((store) => store.supplier);

  const fetchSupplierSettings = useCallback(
    async () => await dispatch(supplierActions.fetchSupplierSettings()),
    [dispatch]
  );

  const updateSupplierSettings = useCallback(
    async (data) =>
      await dispatch(supplierActions.updateSupplierSettings(data)),
    [dispatch]
  );

  return {
    supplier: _supplier,
    fetchSupplierSettings,
    updateSupplierSettings,
  };
}
