import React, {memo} from 'react';
import classNames from 'classnames';
import PropTypes from "prop-types";

const Footer = props => {

    const beginYear = 2019;
    const currentYear = new Date().getFullYear();
    const years = currentYear > beginYear ? beginYear + ' - ' + currentYear : beginYear;
    const className = classNames(
      "navbar navbar-expand-lg navbar-light",
      props.className
    );

    return (
      <footer className={className}>
        <div className="text-center d-lg-none w-100">
          <button type="button" className="navbar-toggler dropdown-toggle" data-toggle="collapse"
                  data-target="#navbar-footer">
            <i className="icon-unfold mr-2"/>
            Footer
          </button>
        </div>

        <div className="navbar-collapse collapse" id="navbar-footer">
          <span className="navbar-text">
            &copy; <a href="https://www.blpvfx.com/">BLiP</a> {years}
          </span>
        </div>
      </footer>
    );
}

Footer.defaultProps = {
  classNames: ""
};


Footer.propTypes = {
  className: PropTypes.string
}

export default memo(Footer);
