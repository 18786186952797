export default class WSConfig {
  static PORTS_CONFIG = {
    activities: {
      prod: "10404",
      dev: "8083",
    },
    rtc: {
      prod: "10504",
      dev: "10504",
    },
  };

  /**
   *
   * @param type
   * @returns {string}
   */
  static getEndpointAddress(type) {
    return this.createEndpointAddress(type);
  }

  /**
   *
   * @param type
   * @returns {string}
   */
  static createEndpointAddress(type) {
    const location = window.location;

    const backendAddress =
      location.protocol +
      "//" +
      location.hostname +
      (":" + WSConfig.PORTS_CONFIG[type].prod);

    return !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      ? `http://localhost:${WSConfig.PORTS_CONFIG[type].dev}`
      : backendAddress;
  }
}
