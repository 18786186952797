import React, { useCallback } from "react";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import ImportantItemsHeader from "./ImportantItemsHeader";
import GroupedShotsRow from "./GroupedShotsRow";
import StatusBadge from "../../../components/StatusBadge";
import useStatusHook from "../../../hooks/useStatusHook";
import StatusEditable from "../../../components/StatusEditable";

const GroupedShots = (props) => {
  const { data, statuses } = props;

  const collection = data.collection || [];

  const { statuses: shotsStatuses } = useStatusHook("Shots");

  let lastStatus = "";

  return (
    <div className="card mb-0">
      <ImportantItemsHeader title={data.title} />

      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th width={"15%"}>Due</th>
              <th width={"21%"}>Project</th>
              <th width={"27%"}>Shot Name</th>
              <th width={"13%"}>Version</th>
              <th width={"24%"}>Status</th>
            </tr>
          </thead>
          <tbody>
            {collection.map((shot) => {
              if (shot.status !== lastStatus) {
                lastStatus = shot.status;
                return (
                  <React.Fragment key={`grouped-shot-${shot.id}`}>
                    <tr className="table-active table-border-double">
                      <td colSpan="5">
                        <StatusBadge
                          status={shot.status}
                          statuses={shotsStatuses}
                        />
                      </td>
                    </tr>
                    <GroupedShotsRow data={shot} statuses={shotsStatuses} />
                  </React.Fragment>
                );
              } else {
                return (
                  <GroupedShotsRow
                    data={shot}
                    statuses={shotsStatuses}
                    key={`grouped-shot-${shot.id}`}
                  />
                );
              }
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

GroupedShots.propTypes = {
  data: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  statuses: state.statuses,
});

export default connect(mapStateToProps, null)(GroupedShots);
