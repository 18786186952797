import { shotsSortFn } from "../helpers/tools";

const initialState = {
  fetch: false,
  fetchSuccess: false,
  fetchError: null,

  create: false,
  createSuccess: false,
  createError: null,

  edit: false,
  editSuccess: false,
  editError: null,

  fetchBidVersionsByBid: false,
  fetchBidVersionsByBidSuccess: false,
  fetchBidVersionsByBidError: null,

  createShotsBulk: false,
  createShotsBulkSuccess: false,
  createShotsBulkError: null,

  model: {},
  versions: [],
};

export default function reducer(
  state = {
    ...initialState,
  },
  action
) {
  switch (action.type) {
    case "BID_FETCH":
      return {
        ...state,
        fetch: true,
        fetchSuccess: false,
        fetchError: null,
      };

    case "BID_FETCH_FULFILLED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: true,

        model: action.data,
      };

    case "BID_FETCH_REJECTED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: false,
        fetchError: action.data,
      };

    case "BID_CREATE":
      return {
        ...state,
        create: true,
        createSuccess: false,
        createError: null,
      };

    case "BID_CREATE_FULFILLED":
      return {
        ...state,
        create: false,
        createSuccess: true,

        model: action.data,
      };

    case "BID_CREATE_REJECTED":
      return {
        ...state,
        create: false,
        createSuccess: false,
        createError: action.data,
      };

    case "BID_EDIT":
      return {
        ...state,
        edit: true,
        editSuccess: false,
        editError: null,
      };

    case "BID_EDIT_FULFILLED":
      return {
        ...state,
        edit: false,
        editSuccess: true,

        model: {
          ...action.data,
          project: action.data.project
            ? action.data.project
            : { ...state.model.project },
        },
      };

    case "BID_EDIT_REJECTED":
      return {
        ...state,
        edit: false,
        editSuccess: false,
        editError: action.data,
      };

    case "BID_VERSIONS_BY_BID_FETCH":
      return {
        ...state,
        fetchBidVersionsByBid: true,
        fetchBidVersionsByBidSuccess: false,
        fetchBidVersionsByBidError: null,
      };

    case "BID_VERSIONS_BY_BID_FETCH_FULFILLED":
      return {
        ...state,
        fetchBidVersionsByBid: false,
        fetchBidVersionsByBidSuccess: true,

        versions: action.data.bid_versions,
      };

    case "BID_VERSIONS_BY_BID_FETCH_REJECTED":
      return {
        ...state,
        fetchBidVersionsByBid: false,
        fetchBidVersionsByBidSuccess: false,
        fetchBidVersionsByBidError: action.data,
      };

    case "SHOTS_CREATE_BULK":
      return {
        ...state,
        createShotsBulk: true,
        createShotsBulkSuccess: false,
        createShotsBulkError: null,
      };

    case "SHOTS_CREATE_BULK_FULFILLED":
      return {
        ...state,
        createShotsBulk: false,
        createShotsBulkSuccess: true,
      };

    case "SHOTS_CREATE_BULK_REJECTED":
      return {
        ...state,
        createShotsBulk: false,
        createShotsBulkSuccess: false,
        createShotsBulkError: action.data,
      };

    case "BID_BY_BID_SLUG_FETCH_FULFILLED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: true,

        model: action.data,
      };

    case "BID_VERSION_UPDATE_FULFILLED":
      return {
        ...state,
        model: { ...state.model, recent_bid_version: action.data },
      };

    case "SHOTS_BY_BID_VERSION_FETCH_FULFILLED":
      return {
        ...state,
        versions: state.versions.map((version) =>
          version.id === action.data.bidVersionId
            ? {
                ...version,
                shots: action.data.responseData.shots
                  .sort(shotsSortFn)
                  .reverse(),
              }
            : version
        ),
      };

    case "SET_BID_EDIT":
      return {
        ...state,
        model: action.data,
      };

    case "CLEAR_ERRORS":
      return {
        ...initialState,
        model: state.model,
        versions: state.versions,
      };

    case "LOGOUT":
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
