const RTCMiddleware = (storeAPI) => (next) => (action) => {
  const user = storeAPI.getState().user;

  switch (action.type) {
    case "RTC_GET_ENTITY_STATE_UPDATE":
      const data = action.payload.state[Object.keys(action.payload.state)[0]];
      if (data.user.id === user.model.id) {
        return null;
      }
      break;
  }

  next(action);
};

export default RTCMiddleware;
