import React, { Component } from "react";
import PageTemplate from "../PageTemplate";
import * as dashboardActions from "../../actions/dashboard";
import * as activitiesActions from "../../actions/activities";
import connect from "react-redux/es/connect/connect";
import { withRouter } from "react-router-dom";
import moment from "moment";

import HasRights from "../../components/HasRights";
import BarDiagram from "../../components/charts/BarDiagram";
import ImportantItems from "./components/ImportantItems";
import OverviewChart from "./components/OverviewChart";
import ShortStats from "./components/ShortStats";
import LoadingIndicator from "../../components/LoadingIndicator";
import RecentActivity from "../../components/RecentActivity";
import { typeOptions } from "../../consts/statusesTypes";
import { tablesTypesOrder } from "./consts/tablesTypesOrder";
import { ucFirst } from "../../helpers/error";
import { UserRole } from "../../consts/user";
import { blpDebug } from "../../helpers/debug";

class DashboardPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      refresh: false,
      refreshTime: null,
      isLoadingNextImportantItemsPageType: null,
    };
  }

  /**
   *
   */
  componentDidMount() {
    setTimeout(window.updateJQuery, 100);
    if (this.props.user.token && this.props.user.model) {
      this.onInitial();
    }
  }

  /**
   *
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.match.params.dashboardType !==
        this.props.match.params.dashboardType ||
      (!prevProps.statuses.collection.length &&
        prevProps.statuses.fetch &&
        this.props.statuses.fetchSuccess)
    ) {
      this.onInitial();
    }
  }

  /**
   *
   */
  onInitial = () => {
    this.props.resetCommonDashboard();
    this.fetchDashboard();
  };

  /**
   *
   */
  connectActivitiesSocket = () => {
    this.props.connectActivitiesSocket({ activity_type: "dashboard" });
  };

  /**
   *
   * @param role
   * @returns {string}
   */
  getDefaultDashboardTypeByRole = (role) => {
    switch (role) {
      case "client":
        return "client";

      case "admin":
      case "producer":
      case "artist":
      case "accountant":
        return "common";

      default:
        return "";
    }
  };

  /**
   *
   * @returns {{}}
   */
  getListOfTablesToLoad = () => {
    let roleToLoadTables = this.props.user.model.role;
    const dashboardType = this.props.match.params.dashboardType;
    if (["admin", "producer"].indexOf(roleToLoadTables) > -1) {
      if (dashboardType === "artist") {
        roleToLoadTables = "artist";
      }
      if (dashboardType === "accountant") {
        roleToLoadTables = "accountant";
      }
    }

    let result = {};
    typeOptions.forEach((type) => {
      result[type.value] = !!this.props.statuses.collection
        .filter((item) => item.type === type.value)
        .filter((item) =>
          item.statuses_dashboard.find(
            (setting) => setting.role === roleToLoadTables && setting.is_list
          )
        ).length;
    });
    return result;
  };

  /**
   *
   * @param page
   * @param type
   */
  fetchDashboard = (page = 1, type) => {
    const dashboardType = this.getDefaultDashboardTypeByRole(
      this.props.user.model.role
    );
    let dashboardCommonParams = {
      page,
      tablesTypes: type
        ? { [ucFirst(type)]: true }
        : this.getListOfTablesToLoad(),
    };
    let quickStatsCommonParams = {};
    if (this.props.match.params.dashboardType === "artist") {
      dashboardCommonParams.artist_id = this.props.user.model.id;
      quickStatsCommonParams.artist_id = this.props.user.model.id;
    }
    if (this.props.match.params.dashboardType === "accountant") {
      dashboardCommonParams.accountant_id = this.props.user.model.id;
      quickStatsCommonParams.accountant_id = this.props.user.model.id;
    }
    this.props
      .fetchDashboard(
        dashboardType,
        quickStatsCommonParams,
        dashboardCommonParams
      )
      .then(() => {
        setTimeout(this.setTimeSpentDiagram, 100);
        this.setState({
          refreshTime: moment().format("MM/DD/YY, HH:mm"),
          isLoadingNextImportantItemsPageType: "",
        });
      });
  };

  /**
   *
   */
  refreshDashboard = () => {
    this.setState({ refresh: true });
    this.fetchQuickStats().then(() => {
      this.setState({
        refreshTime: moment().format("MM/DD/YY, HH:mm"),
        refresh: false,
      });
    });
  };

  /**
   *
   * @returns {Promise<void>}
   */
  fetchQuickStats = async () => {
    const dashboardType = this.getDefaultDashboardTypeByRole(
      this.props.user.model.role
    );
    let quickStatsCommonParams = {};
    if (this.props.match.params.dashboardType === "artist") {
      quickStatsCommonParams.artist_id = this.props.user.model.id;
    }
    if (this.props.match.params.dashboardType === "accountant") {
      quickStatsCommonParams.accountant_id = this.props.user.model.id;
    }
    await this.props.fetchQuickStats(dashboardType, quickStatsCommonParams);
  };

  /**
   *
   * @param type
   * @returns {function(): void}
   */
  onContentEndReached = (type) => () => {
    blpDebug(
      `DashboardPage::onContentEndReached(${type})`,
      this.props.commonDashboard.collection[type].nextPageExist,
      this.state.isLoadingNextImportantItemsPageType
    );

    if (
      this.props.commonDashboard.collection[type].nextPageExist &&
      !this.state.isLoadingNextImportantItemsPageType
    ) {
      blpDebug(
        `DashboardPage::onContentEndReached(${type})`,
        `fetchDashboard(${
          this.props.commonDashboard.collection[type].maxPageLoaded + 1
        },
          ${type})`
      );

      this.setState({ isLoadingNextImportantItemsPageType: type }, () => {
        this.fetchDashboard(
          this.props.commonDashboard.collection[type].maxPageLoaded + 1,
          type
        );
      });
    }
  };

  /**
   *
   * @param value
   */
  onUserSelectUpdate = (value) => {
    if (value && value.id) {
      this.props.fetchParticularUserChart(value.id);
    } else {
      this.props.fetchParticularUserChart();
    }
  };

  /**
   *
   */
  setTimeSpentDiagram = () => {
    const diagramData = this.prepareDiagramData();

    window.updateJQuery(null, {
      barDiagram: [
        {
          bindto: document.getElementById("time-spent-diagram"),
          size: { height: 400 },
          data: {
            columns: diagramData.columns,
            type: "bar",
          },
          color: {
            pattern: ["#2196F3", "#FF9800", "#4CAF50"],
          },
          bar: {
            width: {
              ratio: 0.5,
            },
          },
          grid: {
            y: {
              show: true,
            },
          },
          axis: {
            x: {
              type: "category",
              categories: [
                "Sunday",
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
              ],
            },
          },
        },
      ],
    });
  };

  /**
   *
   * @returns {{columns: number[][]}}
   */
  prepareDiagramData = () => {
    const timeSpent =
      this.props.commonDashboard.quickStats &&
      this.props.commonDashboard.quickStats.timelogs
        ? this.props.commonDashboard.quickStats.timelogs
        : [];
    const weekData = [0, 0, 0, 0, 0, 0, 0];
    timeSpent.forEach((item) => {
      const dayIndex = new Date(item.date).getDay() - 1;
      weekData[dayIndex] = +(item.time / 60 / 60).toFixed(2);
    });
    weekData.unshift(weekData[6]);
    weekData.pop();
    weekData.unshift("Hours");
    return {
      columns: [weekData],
    };
  };

  /**
   *
   */
  onFetchPreviousActivities = (lastActivityId) => {
    this.props.getPreviousActivities(lastActivityId);
  };

  /**
   *
   * @param commonOverviewChartData
   * @param artistOverviewChartData
   * @param accountantOverviewChartData
   * @returns {*}
   */
  getAdminProducerOverviewChartData = ({
    commonOverviewChartData,
    artistOverviewChartData,
    accountantOverviewChartData,
  }) => {
    const dashboardType = this.props.match.params.dashboardType;
    if (dashboardType === "artist") {
      return artistOverviewChartData;
    }
    if (dashboardType === "accountant") {
      return accountantOverviewChartData;
    }
    return commonOverviewChartData;
  };

  /**
   *
   * @param commonQuickStatsData
   * @param accountantQuickStatsData
   * @returns {*}
   */
  getAdminProducerQuickStatsData = ({
    commonQuickStatsData,
    accountantQuickStatsData,
  }) => {
    if (this.props.match.params.dashboardType === "accountant") {
      return accountantQuickStatsData;
    }
    return commonQuickStatsData;
  };

  /**
   *
   * @returns {string[]|*}
   */
  getTablesTypesOrder = () => {
    const role = this.props.user.model.role;
    const dashboardType = this.props.match.params.dashboardType;
    if (["admin", "producer"].indexOf(role) > -1) {
      if (dashboardType === "artist") {
        return tablesTypesOrder["artist"];
      }
      if (dashboardType === "accountant") {
        return tablesTypesOrder["accountant"];
      }
    }
    return tablesTypesOrder[role];
  };

  /**
   *
   * @returns {XML}
   */
  render() {
    const user = this.props.user;
    const userModel = user.model;
    const isDarkThemed =
      this.props.match.params.dashboardType === UserRole.artist;

    const clientDashboard = this.props.clientDashboard;
    const clientDashboardModel = clientDashboard.model;

    const commonDashboard = this.props.commonDashboard;
    const commonDashboardCollection = commonDashboard.collection;

    const isLoading =
      (clientDashboard.fetch ||
        commonDashboard.fetch ||
        commonDashboard.fetchQuickStats) &&
      !this.state.refresh &&
      !this.state.isLoadingNextImportantItemsPageType;

    const timeSpentDiagramExists =
      !!commonDashboard.quickStats && commonDashboard.quickStats.timelogs;

    const timeSpentDiagram = !isLoading ? (
      <BarDiagram
        title="Time spent this week"
        id="time-spent-diagram"
        isDarkThemed={isDarkThemed}
      />
    ) : (
      <LoadingIndicator />
    );

    const timeSpentDiagramWrap = timeSpentDiagramExists ? (
      <HasRights
        allowedRoles={["producer", "admin", "artist"]}
        user={this.props.user.model || {}}
      >
        <div className="row">
          <div className="col-12">{timeSpentDiagram}</div>
        </div>
      </HasRights>
    ) : null;

    const recentActivity = (
      <RecentActivity
        id="dashboard-page-activities"
        onConnect={this.connectActivitiesSocket}
        onFetchPrevious={this.onFetchPreviousActivities}
      />
    );

    const commonQuickStatsData =
      commonDashboard.quickStats && commonDashboard.quickStats.stats
        ? {
            stats: commonDashboard.quickStats.stats,
            type: "Shots", // Hardcode as on the backend
          }
        : {};

    const accountantQuickStatsData =
      commonDashboard.quickStats && commonDashboard.quickStats.stats
        ? {
            stats: commonDashboard.quickStats.stats,
            type: "Projects", // Hardcode as on the backend
          }
        : {};

    const clientQuickStatsData = clientDashboardModel
      ? {
          stats: {
            bids_awaiting_payments:
              clientDashboardModel.short_stats.bids_awaiting_payments,
            overdue_invoices: clientDashboardModel.short_stats.overdue_invoices,
            paid_invoices: clientDashboardModel.short_stats.paid_invoices,
            new_invoices: clientDashboardModel.short_stats.new_invoices,
          },
          type: null,
        }
      : {};

    const commonImportantItemsData = [];
    const commonDashboardCollectionSorted = {};
    (this.props.user.model ? this.getTablesTypesOrder() : []).forEach(
      (type) => {
        commonDashboardCollectionSorted[type] = commonDashboardCollection[type];
      }
    );

    for (let collectionType in commonDashboardCollectionSorted) {
      if (commonDashboardCollectionSorted[collectionType].collection.length) {
        commonImportantItemsData.push({
          collection:
            commonDashboardCollectionSorted[collectionType].collection,
          type: collectionType,
          title: collectionType,
        });
      }
    }

    const clientImportantItemsData = clientDashboardModel
      ? {
          overdueInvoices: clientDashboardModel.unpaid_invoices,
          bidsAwaitingPayments: clientDashboardModel.bids_awaiting_payments,
          shotsPendingApproval: clientDashboardModel.shots_pending_approval,
          type: "projectsSummary",
          title: "Items requiring your attention",
        }
      : {};

    const commonOverviewChartData =
      commonDashboard.quickStats && commonDashboard.quickStats.chart
        ? {
            chart: commonDashboard.quickStats.chart,
            type: "shots", // Hardcode as on the backend
            title: "Artist Shot Overview",
            onUserSelectUpdate: this.onUserSelectUpdate,
          }
        : {};

    const artistOverviewChartData =
      commonDashboard.quickStats && commonDashboard.quickStats.chart
        ? {
            chart: commonDashboard.quickStats.chart,
            type: "shots", // Hardcode as on the backend
            title: "Artist Shot Overview",
          }
        : {};

    const accountantOverviewChartData =
      commonDashboard.quickStats && commonDashboard.quickStats.chart
        ? {
            chart: commonDashboard.quickStats.chart,
            type: "projects", // Hardcode as on the backend
            title: "Project Overview",
          }
        : {};

    const clientOverviewChartData = {
      chart: null,
      type: null,
      title: null,
    };

    const pages = [
      {
        allowedRoles: ["admin", "producer"],
        quickStatsData: this.getAdminProducerQuickStatsData({
          commonQuickStatsData,
          accountantQuickStatsData,
        }),
        importantItemsData: commonImportantItemsData,
        overviewChartData: this.getAdminProducerOverviewChartData({
          commonOverviewChartData,
          artistOverviewChartData,
          accountantOverviewChartData,
        }),
      },
      {
        allowedRoles: ["artist"],
        quickStatsData: commonQuickStatsData,
        importantItemsData: commonImportantItemsData,
        overviewChartData: artistOverviewChartData,
      },
      {
        allowedRoles: ["accountant"],
        quickStatsData: accountantQuickStatsData,
        importantItemsData: commonImportantItemsData,
        overviewChartData: accountantOverviewChartData,
      },
      {
        allowedRoles: ["client"],
        quickStatsData: clientQuickStatsData,
        importantItemsData: clientImportantItemsData,
        overviewChartData: clientOverviewChartData,
      },
    ];

    return (
      <PageTemplate
        header="Dashboard"
        subheader={userModel ? `${userModel.name} (${userModel.role})` : null}
        pageWrapperClassName={
          isDarkThemed ? "bg-dark page-header-dark-theme" : ""
        }
        pageFooterClassName={isDarkThemed ? "navbar-dark" : ""}
        breadcrumbClassName={isDarkThemed ? "breadcrumb-line-dark" : ""}
      >
        {pages.map((i) => (
          <HasRights
            allowedRoles={i.allowedRoles}
            user={this.props.user.model || {}}
          >
            <div className="row relative flex-column-reverse flex-xl-column dashboard-main-min-height">
              <div
                className="col-xl-5 limit-height-with-sibling-right-xl d-flex flex-column z-index-5
                                                                                            dashboard-main-min-height"
              >
                <ShortStats
                  time={this.state.refreshTime}
                  data={i.quickStatsData}
                  isLoading={isLoading}
                  onRefresh={this.refreshDashboard}
                  isRefreshing={this.state.refresh}
                />
                <OverviewChart
                  isLoading={isLoading}
                  isRefreshing={commonDashboard.fetchParticularUserChart}
                  data={i.overviewChartData}
                  type={i.overviewChartData.type}
                  title={i.overviewChartData.title}
                  onUserSelectUpdate={i.overviewChartData.onUserSelectUpdate}
                  isDarkThemed={isDarkThemed}
                  onRefresh={this.refreshDashboard}
                />
                {recentActivity}
              </div>
              <div className="col">
                <div className="row">
                  <div className="col-xl-7 mb-3">
                    {Array.isArray(i.importantItemsData) ? (
                      i.importantItemsData.map((itemData, index) => {
                        const isLastItem =
                          index === i.importantItemsData.length - 1;
                        const importantItemsClassName = !isLastItem
                          ? "mb-3"
                          : "";
                        return (
                          <div
                            className={importantItemsClassName}
                            key={`ImportantItems-${itemData.type}`}
                          >
                            <ImportantItems
                              isLoading={isLoading}
                              isLoadingNextPage={
                                this.state
                                  .isLoadingNextImportantItemsPageType ===
                                itemData.type
                              }
                              data={itemData}
                              user={this.props.user.model || {}}
                              type={itemData.type}
                              onContentEndReached={this.onContentEndReached(
                                itemData.type
                              )}
                            />
                          </div>
                        );
                      })
                    ) : (
                      <ImportantItems
                        isLoading={isLoading}
                        isLoadingNextPage={false}
                        data={i.importantItemsData}
                        user={this.props.user.model || {}}
                        type={i.importantItemsData.type}
                        onContentEndReached={this.onContentEndReached}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </HasRights>
        ))}
        {timeSpentDiagramWrap}
      </PageTemplate>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  statuses: state.statuses,
  commonDashboard: state.commonDashboard,
  clientDashboard: state.clientDashboard,
  activities: state.activities,
});

const mapDispatchToProps = (dispatch) => ({
  fetchDashboard: async (type, quickStatsCommonParams, dashboardCommonParams) =>
    await dispatch(
      dashboardActions.fetchDashboard(
        type,
        quickStatsCommonParams,
        dashboardCommonParams
      )
    ),
  fetchQuickStats: async (type, quickStatsCommonParams) =>
    await dispatch(
      dashboardActions.fetchQuickStats(type, quickStatsCommonParams)
    ),
  fetchParticularUserChart: async (artist_id) =>
    await dispatch(dashboardActions.fetchParticularUserChart(artist_id)),
  resetCommonDashboard: () => dispatch(dashboardActions.resetCommonDashboard()),
  connectActivitiesSocket: (data) =>
    dispatch(activitiesActions.connectActivitiesSocket(data)),
  getPreviousActivities: (startFromActivityId, data) =>
    dispatch(
      activitiesActions.getPreviousActivities(startFromActivityId, data)
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DashboardPage));
