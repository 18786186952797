/**
 * Created by piotr.pozniak@thebeaverhead.com on 27/06/2023
 */

import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import HoveredEdit from "../../../components/HoveredEdit";
import HasRights from "../../../components/HasRights";
import { useUserStore } from "../../../storeHooks/user";

const Notes = ({ allowedRoles, value, previewValue, onChange, isDisabled }) => {
  const { user } = useUserStore();
  const textarea = useRef(null);

  const [_previewValue, setPreviewValue] = useState("");

  useEffect(() => {
    if (previewValue) {
      setPreviewValue("");
    }
  }, [previewValue]);

  /**
   *
   */
  const onFocus = () => {
    textarea.current.focus();
  };

  const _onChange = useCallback(async () => {
    setPreviewValue(textarea.current.value);
    await onChange(textarea.current.value);
  }, [textarea.current, onChange]);

  return (
    <HasRights
      allowedRoles={allowedRoles}
      user={user.model}
      placeholder={
        <div className="ml-1">
          <span>{value}</span>
        </div>
      }
    >
      <HoveredEdit
        className="w-100"
        preview={
          <pre className="hovered-edit-pre w-100">
            {_previewValue || previewValue}
          </pre>
        }
        cutPreviewLength={"100%"}
        handleConfirm={_onChange}
        handleFocus={onFocus}
        disabled={isDisabled}
      >
        <textarea
          ref={textarea}
          defaultValue={value}
          className="input-pure minh-100 w-100"
        />
      </HoveredEdit>
    </HasRights>
  );
};

Notes.defaultProps = {};

Notes.propTypes = {
  allowedRoles: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
  previewValue: PropTypes.string,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
};

export default Notes;
