/**
 * Created by piotr.pozniak@thebeaverhead.com on 25/07/2022.
 */
import { useDispatch, useSelector } from "react-redux";

export function useInvoicesStoreStore() {
  const dispatch = useDispatch();
  const _invoicesStore = useSelector((store) => store.invoicesStore);

  return {
    invoicesStore: _invoicesStore,
  };
}
