import axios from "axios";
import AxiosConfig from "../AxiosConfig";

/**
 *
 * @param id
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export const fetchShot = (id) => {
  return async (dispatch) => {
    dispatch({ type: "SHOT_FETCH" });

    const url = AxiosConfig.getEndpointAddress() + "/api/shots/" + id + ".json";

    await axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "SHOT_FETCH_FULFILLED",
          data: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "SHOT_FETCH_REJECTED",
          data: error,
        });
      });
  };
};

/**
 *
 * @param project_id
 * @param data
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export const createShot = (project_id, data) => {
  return async (dispatch) => {
    dispatch({ type: "SHOT_CREATE" });

    const url = AxiosConfig.getEndpointAddress() + "/api/shots.json";
    const createShotData = { ...data, project_id };

    await axios
      .post(url, createShotData, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "SHOT_CREATE_FULFILLED",
          data: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "SHOT_CREATE_REJECTED",
          data: error,
        });
      });
  };
};

/**
 *
 * @param id
 * @param data
 * @returns {function(...[*]=)}
 */
export const editShot = (id, data) => {
  return async (dispatch) => {
    dispatch({ type: "SHOT_EDIT" });

    const url = AxiosConfig.getEndpointAddress() + "/api/shots/bulk.json";

    await axios
      .put(url, { data: [{ id, ...data }] }, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "SHOT_EDIT_FULFILLED",
          data: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "SHOT_EDIT_REJECTED",
          data: error,
        });
      });
  };
};

/**
 *
 * @param shot_id
 * @param data
 * @returns {Function}
 */
export const createShotUpdate = (shot_id, data) => {
  return async (dispatch) => {
    dispatch({ type: "SHOT_UPDATE_CREATE" });

    const url = AxiosConfig.getEndpointAddress() + "/api/shot_updates.json";
    const createShotUpdateData = { ...data, shot_id };

    await axios
      .post(url, createShotUpdateData, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "SHOT_UPDATE_CREATE_FULFILLED",
          data: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "SHOT_UPDATE_CREATE_REJECTED",
          data: error,
        });
      });
  };
};

/**
 *
 * @param id
 * @returns {Function}
 */
export const deleteShotUpdate = (id) => {
  return async (dispatch) => {
    dispatch({ type: "SHOT_UPDATE_DELETE" });

    const url =
      AxiosConfig.getEndpointAddress() + "/api/shot_updates/" + id + ".json";

    await axios
      .delete(url, { ...AxiosConfig.getAuthConfig() })
      .then((response) => {
        dispatch({
          type: "SHOT_UPDATE_DELETE_FULFILLED",
          data: {
            responseData: response.data,
            id,
          },
        });
      })
      .catch(function (error) {
        dispatch({
          type: "SHOT_UPDATE_DELETE_REJECTED",
          data: error,
        });
      });
  };
};

/**
 *
 * @param id
 * @param data
 * @returns {Function}
 */
export const editShotUpdate = (id, data) => {
  return async (dispatch) => {
    dispatch({ type: "SHOT_UPDATE_EDIT" });

    const url =
      AxiosConfig.getEndpointAddress() + "/api/shot_updates/" + id + ".json";

    await axios
      .put(url, data, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "SHOT_UPDATE_EDIT_FULFILLED",
          data: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "SHOT_UPDATE_EDIT_REJECTED",
          data: error,
        });
      });
  };
};

/**
 *
 * @param shotId
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export const fetchShotNotes = (shotId) => {
  return async (dispatch) => {
    dispatch({ type: "SHOT_NOTE_FETCH", shotId });
    const url = AxiosConfig.getEndpointAddress() + `/api/shot_notes.json?shot_id=${shotId}`;
    await axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "SHOT_NOTE_FETCH_FULFILLED",
          data: {
            responseData: response.data,
            shotId,
          },
        });
      })
      .catch(function (error) {
        dispatch({
          type: "SHOT_NOTE_FETCH_REJECTED",
          data: error,
          shotId
        });
      });
  };
};


/**
 *
 * @param shotId
 * @param note
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export const createShotNote = (shotId, note) => {
  return async (dispatch) => {
    dispatch({ type: "SHOT_NOTE_CREATE", shotId });
    const url = AxiosConfig.getEndpointAddress() + "/api/shot_notes.json";
    const data = { shot_id: shotId, note };
    await axios
      .post(url, data, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "SHOT_NOTE_CREATE_FULFILLED",
          data: {
            responseData: response.data,
            shotId,
          },
        });
      })
      .catch(function (error) {
        dispatch({
          type: "SHOT_NOTE_CREATE_REJECTED",
          data: error,
        });
      });
  };
};

/**
 *
 * @param shotNoteId
 * @param note
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export const editShotNote = (shotNoteId, note, shotId) => {
  return async (dispatch) => {
    dispatch({ type: "SHOT_NOTE_EDIT", shotId });
    const url =
      AxiosConfig.getEndpointAddress() +
      "/api/shot_notes/" +
      shotNoteId +
      ".json";
    const data = { note };
    await axios
      .put(url, data, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "SHOT_NOTE_EDIT_FULFILLED",
          data: {
            responseData: response.data,
            shotNoteId,
            shotId
          },

        });
      })
      .catch(function (error) {
        dispatch({
          type: "SHOT_NOTE_EDIT_REJECTED",
          data: error,
          shotId
        });
      });
  };
};

/**
 *
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 * @param shotNoteId
 */
export const deleteShotNote = (shotNoteId, shotId) => {
  return async (dispatch) => {
    dispatch({ type: "SHOT_NOTE_DELETE", shotId });
    const url =
      AxiosConfig.getEndpointAddress() +
      "/api/shot_notes/" +
      shotNoteId +
      ".json";
    await axios
      .delete(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "SHOT_NOTE_DELETE_FULFILLED",
          data: {
            responseData: response.data,
            shotNoteId,
            shotId
          },
        });
      })
      .catch(function (error) {
        dispatch({
          type: "SHOT_NOTE_DELETE_REJECTED",
          data: error,
          shotId
        });
      });
  };
};

/**
 *
 * @param shotId
 * @param bidId
 * @returns {(function(*): Promise<void>)|*}
 */
export const moveShot = (shotId, bidId) => {
  return async (dispatch) => {
    dispatch({ type: "SHOW_MOVE" });

    const url = AxiosConfig.getEndpointAddress() + "/api/shots/move.json";

    const data = {
      shot_id: shotId,
      bid_id: bidId,
    };

    await axios
      .post(url, data, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "SHOW_MOVE_FULFILLED",
          data: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "SHOW_MOVE_REJECTED",
          data: error,
        });
      });
  };
};
