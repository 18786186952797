import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ModalTemplate from "./ModalTemplate";
import connect from "react-redux/es/connect/connect";
import ProgressBar from "../components/ProgressBar";
import * as projectActions from "../actions/project";
import LoadingIndicator from "../components/LoadingIndicator";
import { arrayMove } from "../helpers/tools";

class ProjectProgressModal extends Component {
  /**
   *
   */
  async componentDidMount() {
    if (
      this.props.user.token &&
      this.props.user.model &&
      (!this.props.project.model ||
        this.props.project.model.id !== this.props.match.params.projectId)
    ) {
      await this.props.fetchProject(this.props.match.params.projectId, 1);
    }
  }

  /**
   *
   * @returns {XML}
   */
  render() {
    const statuses = this.props.statuses.collection.filter(
      i => i.type === "Shots"
    );

    const modalTitle = "Shot statues in %";
    const sort = (a, b) => (a.value > b.value) ? -1 : 1;
    const statusesSorted = [...statuses].sort(sort).reverse();
    const notStartedStatusIndex = statusesSorted.findIndex(
      x => x.value === "not_started"
    );
    const statusesReordered = arrayMove(
      statusesSorted.slice(),
      notStartedStatusIndex,
      statuses.length - 1
    );
    const shotStatuses =
      this.props.project.fetch ||
      statusesReordered.map(status => {
        const numberOfShots = this.props.project.shots.filter(
          shot => shot.status === status.value
        ).length;
        const percent =
          Math.round((numberOfShots / this.props.project.shots.length) * 100) +
          "%";
        return { ...status, numberOfShots, percent };
      });
    const loadingIndicator = this.props.project.fetch && <LoadingIndicator />;

    return (
      <ModalTemplate
        title={modalTitle}
        onClose={this.props.onClose}
        loadingIndicator={loadingIndicator}
        modalContentClassName={this.props.location.state && this.props.location.state.theme === 'dark' ? 'bg-dark' : ''}
      >
        {this.props.project.fetch ||
          shotStatuses.map(
            x =>
              x.numberOfShots > 0 && (
                <div className={"row align-items-center"} key={x.value}>
                  <div className="col-4">
                    {x.label} ({x.numberOfShots}/
                    {this.props.project.shots.length})
                  </div>
                  <div className="col">
                    <ProgressBar
                      progressClassNames="progress_height font-size-sm my-2"
                      progressAr={[
                        { percent: x.percent, text: x.percent, color: x.color }
                      ]}
                    />
                  </div>
                </div>
              )
          )}
        {this.props.project.fetch === false &&
          this.props.project.shots.length === 0 && (
            <ProgressBar
              progressClassNames="progress_height font-size-sm my-2"
              progressAr={[
                { percent: "100%", text: "0% done", color: "bg-empty" }
              ]}
            />
          )}
      </ModalTemplate>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  project: state.project,
  statuses: state.statuses
});

const mapDispatchToProps = dispatch => ({
  fetchProject: async (id, approvedShots) =>
    await dispatch(projectActions.fetchProject(id, approvedShots))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProjectProgressModal));
