import PropTypes from "prop-types";

/**
 * Get short version from shot model
 * @param shot
 * @param filler
 * @returns {*|string}
 */
export const getShortVersionFromShotModel = (shot, filler) => {
  return shot && shot.last_shot_update && shot.last_shot_update.version
    ? shot.last_shot_update.version
    : filler || "--";
};
const LastShotUpdateVersion = (props) => {
  const { shot, filler } = props;

  return getShortVersionFromShotModel(shot, filler);
};

LastShotUpdateVersion.propTypes = {
  shot: PropTypes.object,
  filler: PropTypes.string,
};

export default LastShotUpdateVersion;
