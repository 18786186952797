/**
 *
 * @returns {Function}
 */
export const clearErrors = () => {

  return dispatch => {

    dispatch({type: "CLEAR_ERRORS"});
  }
};

/**
 *
 * @param collapse
 * @returns {Function}
 */
export const collapseSideNav = collapse => {

  return dispatch => {

    dispatch({type: "COLLAPSE_SIDE_NAV", data: collapse});
  }
};

/**
 *
 * @param type
 * @param collapse
 * @returns {Function}
 */
export const collapseFilter = (type, collapse) => {

  return dispatch => {

    dispatch({type: "COLLAPSE_FILTER", data: {type, collapse}});
  }
};

/**
 *
 * @param type
 * @param collapse
 * @returns {function(...[*]=)}
 */
export const collapseSidebar = (type, collapse) => {

  return dispatch => {

    dispatch({type: "COLLAPSE_SIDEBAR", data: {type, collapse}});
  }
};