import axios from "axios";
import AxiosConfig from "../AxiosConfig";

/**
 *
 * @param page
 * @param limit
 * @param filters
 * @param type
 * @returns {function(*): Promise<T>}
 */
export const fetchAdditionalCosts = (page = 1, limit = 20) => {
  return (dispatch) => {
    dispatch({
      type: "ADDITIONAL_COSTS_FETCH",
      data: { page },
    });

    const url =
      AxiosConfig.getEndpointAddress() +
      "/api/additional_costs.json?" +
      AxiosConfig.objectToURLQuery({ page, limit });

    return axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "ADDITIONAL_COSTS_FETCH_FULFILLED",
          data: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "ADDITIONAL_COSTS_FETCH_REJECTED",
          data: error,
        });
      });
  };
};

/**
 *
 * @param id
 * @returns {function(*): Promise<T>}
 */
export const deleteAdditionalCost = (id) => {
  return async (dispatch) => {
    dispatch({ type: "ADDITIONAL_COST_DELETE" });

    const url = AxiosConfig.getEndpointAddress() + "/api/additional_costs/" + id + ".json";

    await axios
      .delete(url, { ...AxiosConfig.getAuthConfig() })
      .then((response) => {
        dispatch({
          type: "ADDITIONAL_COST_DELETE_FULFILLED",
          data: {
            responseData: response.data,
            id,
          },
        });
      })
      .catch(function (error) {
        dispatch({
          type: "ADDITIONAL_COST_DELETE_REJECTED",
          data: error,
        });
      });
  };
};


/**
 *
 * @param data
 * @returns {Function}
 */
export const createAdditionalCost = data => {

  return async dispatch => {

    dispatch({type: "ADDITIONAL_COST_CREATE"});

    const url = AxiosConfig.getEndpointAddress() + "/api/additional_costs.json";

    await axios.post(
      url,
      data,
      AxiosConfig.getAuthConfig(),
    )
      .then((response) => {

        dispatch({
          type: "ADDITIONAL_COST_CREATE_FULFILLED",
          data: response.data,
        });

      })
      .catch(function (error) {

        dispatch({
          type: "ADDITIONAL_COST_CREATE_REJECTED",
          data: error,
        });

      });
  }
};

/**
 *
 * @param id
 * @param data
 * @returns {Function}
 */
export const editAdditionalCost = (id, data) => {

  return async dispatch => {

    dispatch({type: "ADDITIONAL_COST_EDIT"});

    const url = AxiosConfig.getEndpointAddress() + "/api/additional_costs/" + id + ".json";

    await axios.put(
      url,
      data,
      AxiosConfig.getAuthConfig(),
    )
      .then((response) => {

        dispatch({
          type: "ADDITIONAL_COST_EDIT_FULFILLED",
          data: response.data,
        });

      })
      .catch(function (error) {

        dispatch({
          type: "ADDITIONAL_COST_EDIT_REJECTED",
          data: error,
        });

      });
  }
};
