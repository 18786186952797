export const initialState = {
  fetch: false,
  fetchSuccess: false,
  fetchError: null,

  fetchUser: false,
  fetchUserSuccess: false,
  fetchUserError: null,

  approve: false,
  approveSuccess: false,
  approveError: null,

  decline: false,
  declineSuccess: false,
  declineError: null,

  delete: false,
  deleteSuccess: false,
  deleteError: null,

  update: false,
  updateSuccess: false,
  updateError: null,

  collection: [],
  pagination: null,
};

export default function reducer(
  state = {
    ...initialState
  },
  action
) {
  switch (action.type) {
    case "USERS_FETCH":
      return {
        ...state,
        fetch: true,
        fetchSuccess: false,
        fetchError: null,
      };

    case "USERS_FETCH_FULFILLED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: true,

        collection: action.data.users,
        pagination: action.data.pagination,
      };

    case "USERS_FETCH_REJECTED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: false,
        fetchError: action.data,
      };

    case "USER_FETCH":
      return {
        ...state,
        fetchUser: true,
        fetchUserSuccess: false,
        fetchUserError: null,
      };

    case "USER_FETCH_FULFILLED":
      const collectionWithFetchedUser = !!state.collection.find(item => item.id === action.data.id) ?
        state.collection.map(item => item.id === action.data.id ? action.data : item) :
        [...state.collection, action.data];
      return {
        ...state,
        fetchUser: false,
        fetchUserSuccess: true,

        collection: collectionWithFetchedUser,
      };

    case "USER_FETCH_REJECTED":
      return {
        ...state,
        fetchUser: false,
        fetchUserSuccess: false,
        fetchUserError: action.data,
      };

    case "USERS_APPROVE":
      return {
        ...state,
        approve: true,
        approveSuccess: false,
        approveError: null,
      };

    case "USERS_APPROVE_FULFILLED":
      let isApproved = !!action.data.responseData.success;
      let collectionApproved = isApproved && state.collection
        ? state.collection.filter(value => value.email !== action.data.email)
        : state.collection;

      return {
        ...state,
        approve: false,
        approveSuccess: isApproved,

        collection: collectionApproved,
      };

    case "USERS_APPROVE_REJECTED":
      return {
        ...state,
        approve: false,
        approveSuccess: false,
        approveError: action.data,
      };

    case "USERS_DECLINE":
      return {
        ...state,
        decline: true,
        declineSuccess: false,
        declineError: null,
      };

    case "USERS_DECLINE_FULFILLED":
      let isDeclined = !!action.data.responseData.success;
      let collectionDeclined = isDeclined && state.collection
        ? state.collection.filter(value => value.email !== action.data.email)
        : state.collection;
      return {
        ...state,
        decline: false,
        declineSuccess: isDeclined,

        collection: collectionDeclined,
      };

    case "USERS_DECLINE_REJECTED":
      return {
        ...state,
        decline: false,
        declineSuccess: false,
        declineError: action.data,
      };

    case "USERS_DELETE":
      return {
        ...state,
        delete: true,
        deleteSuccess: false,
        deleteError: null,
      };

    case "USERS_DELETE_FULFILLED":
      let isDeleted = !!action.data.responseData.success;
      let collectionDeleted = isDeleted && state.collection
        ? state.collection.filter(value => value.id !== action.data.id)
        : state.collection;
      return {
        ...state,
        delete: false,
        deleteSuccess: isDeleted,

        collection: collectionDeleted,
      };

    case "USERS_DELETE_REJECTED":
      return {
        ...state,
        delete: false,
        deleteSuccess: false,
        deleteError: action.data,
      };

    case "USER_EDIT":
      return {
        ...state,
        update: true,
        updateSuccess: false,
        updateError: null,
      };

    case "USER_EDIT_FULFILLED":
      const userIndex = state.collection.findIndex(
        i => i.id === action.data.id
      );

      if (userIndex < 0) {
        return state;
      }

      return {
        ...state,
        updateSuccess: true,
        update: false,
        collection: state.collection.map(i =>
          i.id === action.data.id ? action.data : i
        )
      };

    case "USER_EDIT_REJECTED":
      return {
        ...state,
        update: false,
        updateError: action.data,
      };

    case "USER_PROFILE_EDIT_FULFILLED":
      const profileIndex = state.collection.findIndex(
        i => i.id === action.data.id
      );

      if (profileIndex < 0) {
        return state;
      }

      return {
        ...state,
        collection: state.collection.map(i =>
          i.id === action.data.id ? {...i, avatar_color: action.data.avatar_color} : i
        )
      };

    case "CLEAR_ERRORS":
      return {
        ...initialState,
        collection: state.collection,
        pagination: state.pagination,
      };

    case "LOGOUT":
      return {
        ...initialState,
      };

    default:
      return state;
  }
}