import React, { Component } from "react";
import PropTypes from "prop-types";
import StaticAlert from "../components/StaticAlert";
import DropdownMenu from "../components/DropdownMenu";
import CustomizableDataTable from "../components/CustomizableDataTable";
import LoadingIndicator from "../components/LoadingIndicator";
import TasksExpandable from "./TasksExpandable";
import connect from "react-redux/es/connect/connect";
import { filterAllowedRoles } from "../helpers/tools";
import * as tasksActions from "../actions/tasks";
import moment from "moment";
import { formatError } from "../helpers/error";
import * as appActions from "../actions/app";
import StatusEditable from "./StatusEditable";

const staticTasksAlertHeader = "No records to display";
const staticTasksAlertBody = <>This table lists tasks.</>;

const tasksOutstandingText =
  "This task has been added after shot has been approved by the client";

class TasksCustomizableDataTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editTaskId: null,
      assignTaskId: null,
      unassignTaskId: null,
    };

    this.statuses = this.props.statuses.collection.filter(
      (i) => i.type === "Tasks"
    );
  }

  /**
   *
   * @returns {{columns: *[], filters: *[]}}
   */
  get tasksDataTableConfig() {
    const isLoading =
      this.props.shot.fetch ||
      this.props.project.fetch ||
      this.props.tasks.assign ||
      this.props.tasks.fetch ||
      this.props.tasks.edit ||
      this.props.tasks.create;

    const isProjectOpen =
      this.props.project.model && this.props.project.model.is_open;

    const areAllTasksAssignedToUser = this.props.filteredTasks.every((row) =>
      row.assignee ? row.assignee.id === this.props.user.model.id : false
    );

    const assignToMeBulk = {
      label: "Assign to me",
      action: (data) => {
        this.props.onAssignYourselfTasks &&
          this.props.onAssignYourselfTasks(data.selectedRows);
      },
      allowedRoles: isProjectOpen ? ["artist"] : [],
    };

    const tasksStatusesSelect = {
      data: this.statuses,
      placeholder: "Choose status",
      fieldName: "status",
      type: "select",
      allowedRoles: ["admin", "producer"],
    };

    const assigneeSelect = {
      placeholder: "Assignee",
      fieldName: "assignee",
      type: "userSelect",
      addEmptyItem: true,
      emptyItemLabel: "Unassigned",
      emptyItemValue: "unassigned",
      reactSelectProps: {
        isMulti: false,
        isClearable: true,
        classNamePrefix: "bg-dark",
      },
      allowedRoles: ["admin", "producer"],
    };

    const updateBulkBtn = {
      label: "Bulk update",
      action: this.onBulkUpdatePress,
      allowedRoles: ["admin", "producer"],
    };

    const bulkActions = areAllTasksAssignedToUser
      ? [assigneeSelect, tasksStatusesSelect, updateBulkBtn]
      : [assigneeSelect, tasksStatusesSelect, updateBulkBtn, assignToMeBulk];

    return {
      columns: [
        {
          name: "Type",
          selector: "name",
          sortable: true,
          format: (row) => {
            if (!row) {
              return "";
            }

            let rowError = null;

            if (
              (Array.isArray(this.props.assignError) &&
                this.props.assignError.map((i) => i.id).includes(row.id) &&
                !this.props.tasks.edit) ||
              (this.props.assignError &&
                !Array.isArray(this.props.assignError) &&
                (row.id === this.state.assignTaskId ||
                  row.id === this.state.unassignTaskId))
            ) {
              rowError = (
                <i
                  className="mi-cancel mi-cancel_in-table text-danger"
                  data-popup="tooltip"
                  data-placement="right"
                  data-original-title={formatError(
                    this.props.assignError
                  ).pop()}
                />
              );
            }

            if (
              this.props.tasks.editError &&
              row.id === this.state.editTaskId
            ) {
              rowError = (
                <i
                  className="mi-cancel mi-cancel_in-table text-danger"
                  data-popup="tooltip"
                  data-placement="right"
                  data-original-title={formatError(
                    this.props.tasks.editError
                  ).pop()}
                />
              );
            }

            const rowLoading =
              (this.props.tasks.assign &&
                (row.id === this.state.assignTaskId ||
                  row.id === this.state.unassignTaskId)) ||
              (this.props.tasks.edit &&
                row.id === this.state.editTaskId &&
                !this.props.bulkActionsLoading) ? (
                <span className="icon-spinner2_in-table">
                  <i className="icon-spinner2 spinner fs-086rem" />
                </span>
              ) : null;

            const rowOutstanding = row.is_outstanding ? (
              <i
                className="icon-exclamation icon-exclamation_in-table mb-0 fs-086rem"
                data-popup="tooltip"
                data-placement="right"
                data-original-title={tasksOutstandingText}
              />
            ) : (
              <span />
            );

            return (
              <div className="pl-2">
                {rowError}
                {rowLoading}
                {rowOutstanding}
                <span>{row.name}</span>
              </div>
            );
          },
          width: "100px",
        },
        {
          name: "Description",
          selector: "notes",
          width: "300px",
        },
        {
          name: "Status",
          selector: "status",
          cell: (row) => {
            return (
              <StatusEditable
                status={row.status}
                statuses={this.statuses}
                onChange={this.onStatusChange(row)}
                loading={
                  this.props.tasks.edit && this.state.editTaskId === row.id
                }
                disabled={
                  (this.props.tasks.edit && this.state.editTaskId !== row.id) ||
                  (!["admin", "producer"].includes(
                    this.props.user.model.role
                  ) &&
                    (!row.assignee ||
                      row.assignee.id !== this.props.user.model.id))
                }
              />
            );
          },
          width: "150px",
        },
        {
          name: "Last update",
          selector: "modified",
          format: (row) =>
            row.modified
              ? moment(row.modified * 1000).format("DD/MM/YY, HH:mm")
              : moment(row.created * 1000).format("DD/MM/YY, HH:mm"),
        },
        {
          name: "Assignees",
          selector: "assignee.name",
          format: (row) => (row.assignee ? row.assignee.name : ""),
        },
        {
          name: "Actions",
          right: true,
          cell: (row) => {
            const buttons = [
              {
                icon: "mi-mode-edit",
                action: (row) => {
                  this.setState({
                    editTaskId: null,
                    assignTaskId: null,
                    unassignTaskId: null,
                  });
                  this.props.onEditTask(row);
                },
                data: row,
                label: "Edit task",
                allowedRoles: ["admin", "producer"],
              },
            ];

            const isTaskAssignedToUser = row.assignee
              ? row.assignee.id === this.props.user.model.id
              : false;

            if (
              typeof this.props.onAssignYourselfTasks === "function" &&
              !isTaskAssignedToUser
            ) {
              buttons.push({
                icon: "mi-subscriptions",
                action: this.onAssignYourselfTasks,
                data: row,
                label: "Assign to me",
                allowedRoles: isProjectOpen ? null : ["admin", "producer"],
              });
            }

            if (
              typeof this.props.onUnassignTask === "function" &&
              isTaskAssignedToUser
            ) {
              buttons.push({
                icon: "mi-event-busy",
                action: this.onUnassignTask,
                data: row,
                label: "Unassign me from this task",
              });
            }

            const filteredButtons = filterAllowedRoles(
              this.props.user.model,
              buttons
            );

            if (filteredButtons.length === 0) {
              return null;
            }

            const fakeDropdown = (
              <span className="list-icons-item">
                <i className="icon-menu9" />
              </span>
            );

            return !isLoading ? (
              <DropdownMenu
                buttons={filteredButtons}
                disabled={isLoading}
                dropdownMenuClassName="bg-dark"
              />
            ) : (
              fakeDropdown
            );
          },
          allowedRoles: null,
          width: "70px",
        },
      ],
      filters: [
        {
          placeholder: "Task name",
          defaultValue: "",
          fieldName: "name",
          type: "text",
        },
        {
          data: this.statuses,
          placeholder: "Choose status",
          fieldName: "status",
          type: "select",
        },
      ],
      bulkActions,
    };
  }

  /**
   *
   * @param row
   */
  onAssignYourselfTasks = (row) => {
    this.setState({ assignTaskId: row.id });
    this.props.onAssignYourselfTasks(row);
  };

  /**
   *
   * @param row
   */
  onUnassignTask = (row) => {
    this.setState({ unassignTaskId: row.id });
    this.props.onUnassignTask(row);
  };

  /**
   *
   * @param row
   * @returns {Function}
   */
  onStatusChange = (row) => async (value) => {
    if (value) {
      this.props.clearErrors();
      this.setState({ editTaskId: row.id });
      await this.props.editTask(row.id, { status: value });
      window.updateJQuery();
    }
  };

  /**
   *
   * @param data
   * @param actions
   */
  onBulkUpdatePress = (data, actions) => {
    if (this.props.onBulkUpdatePress) {
      this.setState({
        editTaskId: null,
        assignTaskId: null,
        unassignTaskId: null,
      });
      this.props.onBulkUpdatePress(data, actions);
    }
  };

  /**
   *
   * @param task
   */
  onEditTask = (task) => {
    this.props.clearErrors();
    this.setState({ editTaskId: task.id });
  };

  /**
   *
   * @returns {*}
   */
  render() {
    const tableColumns = filterAllowedRoles(
      this.props.user.model,
      this.tasksDataTableConfig.columns
    );

    const tableBulkActions =
      this.tasksDataTableConfig.bulkActions &&
      this.tasksDataTableConfig.bulkActions.length > 0 &&
      typeof this.props.onAssignYourselfTasks === "function" &&
      typeof this.props.onUnassignTask === "function"
        ? filterAllowedRoles(
            this.props.user.model,
            this.tasksDataTableConfig.bulkActions
          )
        : null;

    const expandableRowsComponent = (
      <TasksExpandable onEditTask={this.onEditTask} />
    );

    const dataTableTasks = this.props.filteredTasks ? (
      <div className="data-table-themed-short-cell-with-expander-btn data-table-themed_cells-nowrap">
        <CustomizableDataTable
          collection={this.props.filteredTasks}
          columns={tableColumns}
          title="Tasks"
          showFilters={true}
          filters={this.tasksDataTableConfig.filters}
          isFiltersApplied={this.props.isFiltersApplied}
          filterSelectorHtmlClass="js-tasks-filter"
          onFilter={this.props.onFilter}
          onClearAll={this.props.onClearAll}
          searchId="tasks"
          canSelectAll
          bulkActions={tableBulkActions}
          bulkActionsLoading={this.props.bulkActionsLoading}
          expandableRows={true}
          expandableRowsComponent={expandableRowsComponent}
          filtersClassName="bg-dark"
          bulkActionsClassName="bg-dark"
        />
      </div>
    ) : null;

    const staticAlertTasks =
      this.props.pureTasks && !this.props.pureTasks.length ? (
        <StaticAlert
          header={staticTasksAlertHeader}
          body={staticTasksAlertBody}
          className="bg-dark"
        />
      ) : null;

    return (
      <div id={this.props.id || null}>
        {this.props.filteredTasks === null && <LoadingIndicator />}
        {this.props.filteredTasks !== null && dataTableTasks}
        {staticAlertTasks}
      </div>
    );
  }
}

TasksCustomizableDataTable.propTypes = {
  filteredTasks: PropTypes.array.isRequired,
  pureTasks: PropTypes.array.isRequired,
  onFilter: PropTypes.func.isRequired,
  onClearAll: PropTypes.func.isRequired,
  onEditTask: PropTypes.func.isRequired,
  onAssignYourselfTasks: PropTypes.func,
  onBulkUpdatePress: PropTypes.func,
  id: PropTypes.string,
  isFiltersApplied: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  user: state.user,
  tasks: state.tasks,
  statuses: state.statuses,
  project: state.project,
  shot: state.shot,
});

const mapDispatchToProps = (dispatch) => ({
  editTask: async (taskId, data) =>
    await dispatch(tasksActions.editTask(taskId, data)),
  clearErrors: () => dispatch(appActions.clearErrors()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TasksCustomizableDataTable);
