/**
 * Created by piotr.pozniak@thebeaverhead.com on 09/07/2024
 */

import React from "react";
import PropTypes from "prop-types";
import { formatter } from "../../../helpers/tools";

const DiscountRushInvoiceFormatter = (row) => {
  if (row.type === "deposit_invoice") {
    return "--";
  }
  let discount = row.discount_from_recent_bid_version || 0;
  discount *= -1;
  const rush = row.rush_from_recent_bid_version || 0;
  return formatter.format(discount + rush);
};

DiscountRushInvoiceFormatter.defaultProps = {};

DiscountRushInvoiceFormatter.propTypes = {};

export default DiscountRushInvoiceFormatter;
