import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Validator from "validatorjs";
import ModalTemplate from "../ModalTemplate";
import connect from "react-redux/es/connect/connect";
import LoadingIndicator from "../../components/LoadingIndicator";
import ColorSelector from "../../components/ColorSelector";
import DashboardStatusSettings from "./components/DashboardStatusSettings";
import FormValidationError from "../../components/FormValidationError";
import ApiError from "../../components/ApiError";
import Select from "react-select";
import * as statusesActions from "../../actions/statuses";
import { prettifyProperty } from "../../helpers/error";
import { typeOptions } from "../../consts/statusesTypes";
import { groupOptions } from "../../consts/statusesGroups";
import { cloneDeep } from "lodash";

const hasStatusGroup = ["Projects", "Bids", "Shots"];

class StatusEditModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: {
        type: this.props.match.params.type,
        value: "",
        label: "",
        color: "ededed",
        status_group: "",
        status_order: "",
        statuses_dashboard: [],
      },
      formValidationErrors: null,
    };
  }

  /**
   *
   */
  componentDidMount() {
    this.props.fetchStatuses();
  }

  /**
   *
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    // fetch statuses and set current status on state
    if (prevProps.statuses.fetchSuccess !== this.props.statuses.fetchSuccess) {
      let status = this.props.statuses.collection.find(
        (i) => i.id === this.props.match.params.statusId
      );
      if (status && status.id) {
        this.setState({
          status: cloneDeep(status),
        });
      }

      window.updateJQuery();
    }

    // create success
    // go back
    if (
      prevProps.statuses.create &&
      !prevProps.statuses.createSuccess &&
      this.props.statuses.createSuccess
    ) {
      this.props.onClose();
    }

    // edit success
    // go back
    if (
      prevProps.statuses.edit &&
      !prevProps.statuses.editSuccess &&
      this.props.statuses.editSuccess
    ) {
      this.props.onClose();
    }
  }

  /**
   *
   * @returns {boolean}
   */
  get isEditMode() {
    return this.props.match.params.statusId !== "new";
  }

  /**
   *
   * @param key
   * @returns {Function}
   */
  onChangeInput = (key) => (e) => {
    this.setState({
      status: {
        ...this.state.status,
        [key]: e.target.value,
      },
    });
  };

  /**
   *
   * @param e
   */
  onChangeColor = (e) => {
    this.setState({
      status: {
        ...this.state.status,
        color: e.hex.replace("#", ""),
      },
    });
  };

  /**
   *
   * @param opt
   */
  onChangeStatus = (opt) => {
    this.setState({
      status: {
        ...this.state.status,
        status_group: opt.value,
      },
    });
  };

  /**
   *
   * @param opt
   */
  onChangeType = (opt) => {
    this.setState(
      {
        status: {
          ...this.state.status,
          type: opt.value,
        },
      },
      window.updateJQuery
    );
  };

  /**
   *
   * @param role
   * @param type
   * @param value
   */
  onChangeStatusesDashboard = (role, type, value) => {
    let roleSettings = this.state.status.statuses_dashboard.find(
      (item) => item.role === role
    );
    let updatedStatusesDashboard = {};
    if (roleSettings) {
      updatedStatusesDashboard = this.state.status.statuses_dashboard.map(
        (item) => (item.role === role ? { ...item, [type]: value } : item)
      );
    } else {
      updatedStatusesDashboard = [
        ...this.state.status.statuses_dashboard,
        { role, [type]: value },
      ];
    }

    console.log(updatedStatusesDashboard);
    this.setState({
      status: {
        ...this.state.status,
        statuses_dashboard: updatedStatusesDashboard,
      },
    });
  };

  /**
   *
   * @param successCb
   */
  validateForm = (successCb) => {
    const { status } = this.state;
    // form validation
    this.setState({ formValidationErrors: null }, () => {
      const rules = {
        type: "required",
        value: "required",
        label: "required",
        color: "required|hex|size:6",
        status_group: hasStatusGroup.includes(status.type) ? "required" : "",
        status_order: "required",
        statuses_dashboard: status.statuses_dashboard ? "array" : "required",
      };
      const validation = new Validator(status, rules);
      const formValidationErrors = validation.errors.all();
      validation.passes();
      this.setState({ formValidationErrors }, () => {
        if (validation.passes() && typeof successCb === "function") {
          successCb();
        }
      });
    });
  };

  /**
   *
   */
  saveForm = () => {
    const { status } = this.state;

    if (this.isEditMode) {
      this.props.editStatus(status.id, {
        value: !!(status.protected * 1) ? "" : status.value,
        label: status.label,
        color: status.color,
        type: status.type,
        status_group: hasStatusGroup.includes(this.state.status.type)
          ? status.status_group
          : null,
        status_order: status.status_order,
        statuses_dashboard: status.statuses_dashboard,
      });
    } else {
      this.props.createStatus({
        value: status.value,
        label: status.label,
        color: status.color,
        type: status.type,
        status_group: hasStatusGroup.includes(this.state.status.type)
          ? status.status_group
          : null,
        status_order: status.status_order,
        statuses_dashboard: status.statuses_dashboard,
      });
    }
  };

  /**
   *
   */
  onConfirm = (event) => {
    event.preventDefault();
    this.validateForm(this.saveForm);
  };

  /**
   *
   * @returns {[]}
   */
  getStatusGroups = () => {
    let statusGroupsBackend = [];
    let statusGroupsFrontend = [];

    try {
      statusGroupsBackend = this.props.statuses.collection
        .filter((i) => i.status_group && i.type === this.state.status.type)
        .reduce((acc, cur) => {
          if (
            acc.findIndex((j) => j.status_group === cur.status_group) === -1
          ) {
            acc.push(cur);
          }
          return acc;
        }, [])
        .map((i) => ({
          label: prettifyProperty(i.status_group),
          value: i.status_group,
          order: -1,
        }));
    } catch (e) {
      //
    }

    groupOptions
      .filter((group) => group.type === this.state.status.type)
      .forEach((group) => {
        const groupIndexInBackendAr = statusGroupsBackend.findIndex(
          (addedGroup) => addedGroup.value === group.value
        );
        if (groupIndexInBackendAr === -1) {
          statusGroupsFrontend.push(group);
        } else {
          statusGroupsBackend.splice(groupIndexInBackendAr, 1, group);
        }
      });

    let arResult = [...statusGroupsBackend, ...statusGroupsFrontend];
    arResult.sort((a, b) => a.order - b.order);

    return arResult;
  };

  /**
   *
   * @returns {XML}
   */
  render() {
    const isLoading =
      this.props.statuses.fetch ||
      this.props.statuses.create ||
      this.props.statuses.edit;
    const loadingIndicator = isLoading ? <LoadingIndicator /> : null;

    const modalTitle = this.isEditMode ? `Edit status` : `New status`;
    const confirmButtonLabel = this.isEditMode ? `Update` : `Create`;

    let statusGroups = this.getStatusGroups();

    return (
      <ModalTemplate
        title={modalTitle}
        onClose={this.props.onClose}
        confirmButtonLabel={confirmButtonLabel}
        onConfirm={this.onConfirm}
        cancelButtonLabel="Cancel"
        onCancel={this.props.onClose}
        loadingIndicator={loadingIndicator}
        disableConfirmButton={isLoading}
      >
        <form noValidate>
          <div className="form-group">
            <label className="form-label">
              Type <span className="text-danger">*</span>
            </label>
            <Select
              options={typeOptions}
              value={typeOptions.filter(
                (o) => o.value === this.state.status.type
              )}
              onChange={this.onChangeType}
              isDisabled={isLoading || this.isEditMode}
              isLoading={isLoading}
            />
          </div>
          <div className="form-group">
            <label className="form-label">
              Value <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control js-status-edit-value"
              placeholder="Status value"
              disabled={
                isLoading ||
                (this.isEditMode && !!(this.state.status.protected * 1))
              }
              value={this.state.status.value}
              onChange={this.onChangeInput("value")}
            />
          </div>
          <div className="form-group">
            <label className="form-label">
              Label <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control js-status-edit-label"
              placeholder="Status label"
              disabled={isLoading}
              value={this.state.status.label}
              onChange={this.onChangeInput("label")}
            />
          </div>
          <div className="form-group">
            <label className="form-label">
              Order <span className="text-danger">*</span>
            </label>
            <input
              type="number"
              className="form-control js-status-edit-order"
              placeholder="Status order"
              disabled={isLoading}
              value={this.state.status.status_order}
              onChange={this.onChangeInput("status_order")}
            />
          </div>
          <div className="form-group">
            <label className="form-label">
              Status color <span className="text-danger">*</span>
            </label>
            <ColorSelector
              onColorChange={this.onChangeColor}
              id={"js-status-edit-color"}
              disabled={isLoading}
              color={"#" + this.state.status.color}
            />
          </div>
          {hasStatusGroup.includes(this.state.status.type) && (
            <div className="form-group">
              <label className="form-label">
                Status group <span className="text-danger">*</span>
              </label>
              <Select
                onChange={this.onChangeStatus}
                options={statusGroups}
                isDisabled={isLoading}
                value={statusGroups.find(
                  (i) => i.value === this.state.status.status_group
                )}
              />
            </div>
          )}

          <DashboardStatusSettings
            status={this.state.status}
            onChange={this.onChangeStatusesDashboard}
            isLoading={isLoading}
          />

          <FormValidationError errors={this.state.formValidationErrors} />
          <ApiError
            error={
              this.props.statuses.fetchError ||
              this.props.statuses.createError ||
              this.props.statuses.editError
            }
          />
        </form>
      </ModalTemplate>
    );
  }
}

const mapStateToProps = (state) => ({
  statuses: state.statuses,
});

const mapDispatchToProps = (dispatch) => ({
  fetchStatuses: () => dispatch(statusesActions.fetchStatuses()),
  editStatus: (status_id, data) =>
    dispatch(statusesActions.editStatus(status_id, data)),
  createStatus: (data) => dispatch(statusesActions.createStatus(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StatusEditModal));
