/**
 * Created by piotr.pozniak@thebeaverhead.com on 17/11/2021.
 */
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as additionalCostsActions from "../actions/additionalCosts";

export function useAdditionalCostsStore() {
  const dispatch = useDispatch();
  const _additionalCosts = useSelector((store) => store.additionalCosts);

  const fetchAdditionalCosts = useCallback(
    async (page, limit) =>
      await dispatch(additionalCostsActions.fetchAdditionalCosts(page, limit)),
    [dispatch]
  );

  const deleteAdditionalCost = useCallback(
    async (id) =>
      await dispatch(additionalCostsActions.deleteAdditionalCost(id)),
    [dispatch]
  );
  const createAdditionalCost = useCallback(
    async (data) =>
      await dispatch(additionalCostsActions.createAdditionalCost(data)),
    [dispatch]
  );

  const editAdditionalCost = useCallback(
    async (id, data) =>
      await dispatch(additionalCostsActions.editAdditionalCost(id, data)),
    [dispatch]
  );

  return {
    additionalCosts: _additionalCosts,
    fetchAdditionalCosts,
    deleteAdditionalCost,
    createAdditionalCost,
    editAdditionalCost
  };
}
