export const initialState = {
  fetch: false,
  fetchSuccess: false,
  fetchError: null,

  create: false,
  createSuccess: false,
  createError: null,

  edit: false,
  editSuccess: false,
  editError: null,

  delete: false,
  deleteSuccess: false,
  deleteError: null,

  collection: [],
};

export default function reducer(
  state = {
    ...initialState
  },
  action
) {
  switch (action.type) {
    case "TASKS_TYPES_FETCH":
      return {
        ...state,
        fetch: true,
        fetchSuccess: false,
        fetchError: null,
      };

    case "TASKS_TYPES_FETCH_FULFILLED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: true,

        collection: action.data.task_types,
      };

    case "TASKS_TYPES_FETCH_REJECTED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: false,
        fetchError: action.data,
      };

    case "TASKS_TYPE_CREATE":
      return {
        ...state,
        create: true,
        createSuccess: false,
        createError: null,
      };

    case "TASKS_TYPE_CREATE_FULFILLED":
      const newCollectionWithCreated = [...state.collection];
      newCollectionWithCreated.push(action.data);
      return {
        ...state,
        create: false,
        createSuccess: true,

        collection: newCollectionWithCreated,
      };

    case "TASKS_TYPE_CREATE_REJECTED":
      return {
        ...state,
        create: false,
        createSuccess: false,
        createError: action.data,
      };

    case "TASKS_TYPE_EDIT":
      return {
        ...state,
        edit: true,
        editSuccess: false,
        editError: null,
      };

    case "TASKS_TYPE_EDIT_FULFILLED":
      let newCollectionWithEdited = state.collection.map((value) => {
        return value.id === action.data.id ? action.data : {...value};
      });
      return {
        ...state,
        edit: false,
        editSuccess: true,

        collection: newCollectionWithEdited,
      };

    case "TASKS_TYPE_EDIT_REJECTED":
      return {
        ...state,
        edit: false,
        editSuccess: false,
        editError: action.data,
      };

    case "TASKS_TYPE_DELETE":
      return {
        ...state,
        delete: true,
        deleteSuccess: false,
        deleteError: null,
      };

    case "TASKS_TYPE_DELETE_FULFILLED":
      let isDeleted = !!action.data.responseData.success;
      let newCollectionWithDeleted = isDeleted
        ? state.collection.filter(value => value.id !== action.data.id)
        : state.collection;
      return {
        ...state,
        delete: false,
        deleteSuccess: isDeleted,

        collection: newCollectionWithDeleted,
      };

    case "TASKS_TYPE_DELETE_REJECTED":
      return {
        ...state,
        delete: false,
        deleteSuccess: false,
        deleteError: action.data,
      };

    case "CLEAR_ERRORS":
      return {
        ...initialState,
        collection: state.collection,
      };

    case "LOGOUT":
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
