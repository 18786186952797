import React, { useCallback } from "react";
import PropTypes from "prop-types";
import AxiosConfig from "../../AxiosConfig";
import axios from "axios";
import GenericAsyncSelector from "./GenericAsyncSelector";

const page = 1;
const limit = 100;

/**
 *
 * @param item
 * @returns {{data: ({client}|*), label: string, value}}
 */
const formatItem = (item) => {
  const clientName =
    item.client && item.client.name ? ` (${item.client.name})` : "";

  return {
    label: item.name + clientName,
    value: item.id,
    data: item,
  };
};

const CustomerSelect = (props) => {
  /**
   *
   * @param search
   * @returns {Promise<AxiosResponse<any> | never>}
   */
  const fetchItems = useCallback((search) => {
    const url =
      AxiosConfig.getEndpointAddress() +
      "/api/clients.json?" +
      AxiosConfig.objectToURLQuery({
        page,
        limit,
        search,
      });

    return axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        return response.data.clients;
      })
      .catch((error) => {
        return null;
      });
  }, []);

  const defaultValue = props.defaultValue
    ? formatItem(props.defaultValue)
    : null;

  const value = props.value ? formatItem(props.value) : undefined;

  return (
    <GenericAsyncSelector
      {...props}
      fetchItems={fetchItems}
      formatItem={formatItem}
      defaultValue={defaultValue}
      value={value}
    />
  );
  /*return (
    <MyAsyncSelect
      cacheOptions
      defaultOptions
      defaultValue={defaultValue}
      value={value}
      onChange={onUpdate}
      loadOptions={loadOptionsDebounced}
      styles={{
        menu: (provided) => ({ ...provided, zIndex: 9999 }),
      }}
      {...props.reactSelectProps}
    />
  );*/
};

CustomerSelect.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  defaultValue: PropTypes.object,
  value: PropTypes.object,
  reactSelectProps: PropTypes.object,
  creatable: PropTypes.bool,
};

export default CustomerSelect;
