import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

class HoveredEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      previewMode: true,
    };
  }

  /**
   *
   */
  componentDidUpdate() {
    if (this.state.previewMode === false) {
      this.props.handleFocus && this.props.handleFocus();
    }
  }

  /**
   *
   */
  previewOn = () => {
    this.setState({ previewMode: true });
  };

  /**
   *
   */
  previewOff = () => {
    if (!this.props.disabled) {
      this.setState({ previewMode: false });
    }
  };

  /**
   *
   */
  handleCancel = () => {
    this.previewOn();
    this.props.handleCancel && this.props.handleCancel();
  };

  /**
   *
   */
  handleConfirm = () => {
    this.previewOn();
    this.props.handleConfirm();
  };

  /**
   *
   * @returns {XML}
   */
  render() {
    const buttonGroup = (
      <div className="btn-group">
        <button
          type="button"
          className="btn btn-light"
          onClick={this.handleConfirm}
        >
          <i className="mi-check"></i>
        </button>
        <button
          type="button"
          className="btn btn-light"
          onClick={this.handleCancel}
        >
          <i className="mi-close"></i>
        </button>
      </div>
    );

    const editButton = (
      <button
        type="button"
        className="ml-1 btn-mode-edit border-transparent"
        onClick={this.previewOff}
      >
        <i className="mi-mode-edit" />
      </button>
    );

    const previewCutStyles = this.props.cutPreviewLength
      ? {
          width: this.props.cutPreviewLength,
        }
      : {};

    const previewCutClassName = classNames({
      "hovered-edit__preview_cut": this.props.cutPreviewLength,
    });

    const preview = (
      <span
        onClick={this.previewOff}
        className={previewCutClassName}
        style={previewCutStyles}
        title={this.props.previewTitle ? this.props.previewTitle : undefined}
      >
        {this.props.preview}
      </span>
    );

    const propsClassName = " " + this.props.className;
    const className =
      classNames(
        "hovered-edit",
        { "hovered-edit_open": !this.state.previewMode },
        { disabled: this.props.disabled }
      ) + propsClassName;

    return (
      <div className={className}>
        {this.state.previewMode && preview}
        {this.state.previewMode && editButton}
        {this.state.previewMode || this.props.children}
        {this.state.previewMode || buttonGroup}
      </div>
    );
  }
}

HoveredEdit.propTypes = {
  preview: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  className: PropTypes.string,
  handleConfirm: PropTypes.func.isRequired,
  handleCancel: PropTypes.func,
  handleFocus: PropTypes.func,
  children: PropTypes.node.isRequired,
  cutPreviewLength: PropTypes.string,
  previewTitle: PropTypes.string,
  disabled: PropTypes.bool,
};

export default HoveredEdit;
