export const initialState = {
  fetch: false,
  fetchSuccess: false,
  fetchError: null,

  update: false,
  updateSuccess: false,
  updateError: null,

  model: null,
};

export default function reducer(
  state = {
    ...initialState
  },
  action
) {
  switch (action.type) {
    case "SUPPLIER_FETCH":
      return {
        ...state,
        fetch: true,
        fetchSuccess: false,
        fetchError: null,
      };

    case "SUPPLIER_FETCH_FULFILLED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: true,

        model: action.data,
      };

    case "SUPPLIER_FETCH_REJECTED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: false,
        fetchError: action.data,
      };

    case "SUPPLIER_UPDATE":
      return {
        ...state,
        update: true,
        updateSuccess: false,
        updateError: null,
      };

    case "SUPPLIER_UPDATE_FULFILLED":
      return {
        ...state,
        update: false,
        updateSuccess: true,

        model: action.data,
      };

    case "SUPPLIER_UPDATE_REJECTED":
      return {
        ...state,
        update: false,
        updateSuccess: false,
        updateError: action.data,
      };

    case "CLEAR_ERRORS":
      return {
        ...initialState,
        model: state.model,
      };

    case "LOGOUT":
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
