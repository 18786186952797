import { shotsSortFn } from "../helpers/tools";

export const initialState = {
  create: false,
  createSuccess: false,
  createError: null,

  edit: false,
  editSuccess: false,
  editError: null,

  fetch: false,
  fetchSuccess: false,
  fetchError: null,

  delete: false,
  deleteSuccess: false,
  deleteError: null,

  bulkEdit: false,
  bulkEditSuccess: false,
  bulkEditError: null,

  createShotVersion: false,
  createShotVersionSuccess: false,
  createShotVersionError: null,

  editShotVersion: false,
  editShotVersionSuccess: false,
  editShotVersionError: null,

  shotNoteCreateId: null,
  shotNoteCreate: false,
  shotNoteCreateSuccess: false,
  shotNoteCreateError: null,

  shotNoteEditId: null,
  shotNoteEdit: false,
  shotNoteEditSuccess: false,
  shotNoteEditError: null,

  shotNoteDeleteId: null,
  shotNoteDelete: false,
  shotNoteDeleteSuccess: false,
  shotNoteDeleteError: null,

  linkWithTSheets: false,
  linkWithTSheetsSuccess: false,
  linkWithTSheetsError: null,

  unlinkWithTSheets: false,
  unlinkWithTSheetsSuccess: false,
  unlinkWithTSheetsError: null,

  shotNoteFetchId: null,
  shotNoteFetch: true,
  shotNoteFetchSuccess: false,
  shotNoteFetchError: null,

  model: null,
  shots: [],
};

export default function reducer(
  state = {
    ...initialState,
  },
  action
) {
  switch (action.type) {
    case "PROJECT_CREATE":
      return {
        ...state,
        create: true,
        createSuccess: false,
        createError: null,
      };

    case "PROJECT_CREATE_FULFILLED":
      return {
        ...state,
        create: false,
        createSuccess: true,

        model: action.data,
      };

    case "PROJECT_CREATE_REJECTED":
      return {
        ...state,
        create: false,
        createSuccess: false,
        createError: action.data,
      };

    case "PROJECT_EDIT":
      return {
        ...state,
        edit: true,
        editSuccess: false,
        editError: null,
      };

    case "PROJECT_EDIT_FULFILLED":
      return {
        ...state,
        edit: false,
        editSuccess: true,

        // PP05082019 not sure if this is correct
        model: {
          ...state.model,
          ...action.data,
        },
      };

    case "PROJECT_EDIT_REJECTED":
      return {
        ...state,
        edit: false,
        editSuccess: false,
        editError: action.data,
      };

    case "DEPOSIT_INVOICE_STATUS_EDIT_FULFILLED":
      return {
        ...state,
        model: state.model
          ? {
              ...state.model,
              deposit_invoice_status: action.data.deposit_invoice_status,
            }
          : state.model,
      };

    case "PROJECT_FETCH":
      const resetModel =
        state.model && state.model.id !== action.projectId ? true : false;
      return {
        ...state,
        fetch: true,
        fetchSuccess: false,
        fetchError: null,
        model: resetModel ? null : state.model,
        shots: resetModel ? [] : state.shots,
      };

    case "PROJECT_FETCH_FULFILLED":
      const { shots, ...modelData } = action.data;
      return {
        ...state,
        fetch: false,
        fetchSuccess: true,

        model: modelData,
        shots: shots.sort(shotsSortFn).reverse(),
      };

    case "PROJECT_FETCH_REJECTED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: false,
        fetchError: action.data,
      };

    case "BID_BY_BID_SLUG_FETCH":
      return {
        ...state,
        fetch: true,
        fetchSuccess: false,
        fetchError: null,
      };

    // case "BID_BY_BID_SLUG_FETCH_FULFILLED": {
    //   const { shots, ...modelData } = action.data;
    //
    //   return {
    //     ...state,
    //     fetch: false,
    //     fetchSuccess: true,
    //
    //     model: modelData,
    //     shots: shots.sort(shotsSortFn).reverse(),
    //   };
    // }

    case "BID_BY_BID_SLUG_FETCH_REJECTED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: false,
        fetchError: action.data,
      };

    case "SHOTS_BY_BID_VERSION_FETCH_FULFILLED": {
      let shotsByBidVersion = state.shots;
      if (state.model && state.model.bids && state.model.bids.length) {
        let bidsCollection = [...state.model.bids];
        bidsCollection.sort((a, b) => b.created - a.created);
        if (action.data.bidVersionId !== bidsCollection[0].id) {
          shotsByBidVersion = action.data.responseData.shots.map((shot) => {
            let versions = [...shot.versions];
            versions.sort((a, b) => b.created - a.created);
            const shotLastVersionForThisBid = versions[0];
            const tasks = (shotLastVersionForThisBid.task_types || []).map(
              (task_type) => ({
                task_type,
              })
            );
            return {
              ...shot,
              current_version: shotLastVersionForThisBid,
              tasks,
            };
          });
        }
      }

      let bids = (state.model && state.model.bids ? state.model.bids : []).map(
        (bid) =>
          bid.id === action.data.bidVersionId
            ? {
                ...bid,
                shots: action.data.responseData.shots
                  .sort(shotsSortFn)
                  .reverse(),
              }
            : bid
      );

      return {
        ...state,
        model: {
          ...state.model,
          bids,
        },
        shots: shotsByBidVersion.sort(shotsSortFn).reverse(),
      };
    }

    case "SHOT_DELETE":
      return {
        ...state,
        delete: true,
        deleteSuccess: false,
        deleteError: null,
      };

    case "SHOT_DELETE_FULFILLED":
      let isDeleted = !!action.data.responseData.success;
      let shotsDeleted = isDeleted
        ? state.shots.filter((i) => i.id !== action.data.id)
        : state.shots;
      return {
        ...state,
        delete: false,
        deleteSuccess: isDeleted,

        shots: shotsDeleted,
      };

    case "SHOT_DELETE_REJECTED":
      return {
        ...state,
        delete: false,
        deleteSuccess: false,
        deleteError: action.data,
      };

    case "SHOT_CREATE_FULFILLED":
      const withNewShot = [...state.shots];
      withNewShot.push(action.data);
      return {
        ...state,
        shots: withNewShot.sort(shotsSortFn).reverse(),
      };

    case "SHOT_EDIT_FULFILLED":
      let shotsEdited = state.shots.map((item) => {
        return item.id === action.data.shots[0].id
          ? { ...item, ...action.data.shots[0] }
          : item;
      });
      return {
        ...state,
        shots: shotsEdited.sort(shotsSortFn).reverse(),
      };

    case "SHOTS_CREATE_BULK_FULFILLED":
      return {
        ...state,
        shots: [...state.shots, ...action.data.shots]
          .sort(shotsSortFn)
          .reverse(),
      };

    case "SET_PROJECT_EDIT":
      const resetModelEdit = state.model && state.model.id !== action.data.id;
      return {
        ...state,
        model: resetModelEdit ? action.data : state.model,
        shots: resetModelEdit ? [] : state.shots,
      };

    case "SHOT_UPDATE_CREATE_FULFILLED":
      return {
        ...state,
        shots: state.shots.map((shot) =>
          shot.id === action.data.shot_id
            ? { ...shot, last_shot_update: action.data }
            : shot
        ),
      };

    case "SHOTS_EDIT_BULK":
      return {
        ...state,
        bulkEdit: true,
        bulkEditSuccess: false,
        bulkEditError: null,
      };

    case "SHOTS_EDIT_BULK_FULFILLED":
      return {
        ...state,
        bulkEdit: false,
        bulkEditSuccess: true,
        shots: state.shots.map((shot) => {
          const foundShot = action.data.shots.find((i) => shot.id === i.id);
          return foundShot ? { ...shot, ...foundShot } : shot;
        }),
      };

    case "SHOTS_EDIT_BULK_REJECTED":
      return {
        ...state,
        bulkEdit: false,
        bulkEditSuccess: false,
        bulkEditError: action.data,
      };

    case "RTC_GET_ENTITY_STATE_UPDATE": {
      const data = action.payload.state[Object.keys(action.payload.state)[0]];

      if (data.state.type === "shotUpdate") {
        return handleEntityStateShotUpdate(action, state, data);
      }

      if (data.state.type === "shotUpdateUpdate") {
        return handleEntityStateShotUpdateUpdate(action, state, data);
      }

      return state;
    }

    case "SHOT_NOTE_FETCH":
      return {
        ...state,
        shotNoteFetch: true,
        shotNoteFetchSuccess: false,
        shotNoteFetchError: null,
        shotNoteFetchId: action.shotId,
      };

    case "SHOT_NOTE_FETCH_FULFILLED": {
      if (state.shots) {
        const shotNoteCreateIndex = state.shots.findIndex(
          (i) => i.id === action.data.shotId
        );
        const updatedCreateShots =
          shotNoteCreateIndex > -1
            ? state.shots.map((shot, index) => {
                if (index !== shotNoteCreateIndex) {
                  return shot;
                }

                return {
                  ...shot,
                  shot_notes: action.data.responseData.shot_notes,
                };
              })
            : state.shots;

        return {
          ...state,
          shotNoteFetch: false,
          shotNoteFetchSuccess: true,
          shots: updatedCreateShots,
        };
      }
      return {
        ...state,
        shotNoteFetch: false,
        shotNoteFetchSuccess: true,
      };
    }

    case "SHOT_NOTE_CREATE": {
      return {
        ...state,
        shotNoteCreateId: action.shotId,
        shotNoteCreate: true,
        shotNoteCreateSuccess: false,
        shotNoteCreateError: null,
      };
    }

    case "SHOT_NOTE_CREATE_FULFILLED": {
      if (state.shots) {
        const shotNoteCreateIndex = state.shots.findIndex(
          (i) => i.id === action.data.shotId
        );
        const updatedCreateShots =
          shotNoteCreateIndex > -1
            ? state.shots.map((shot, index) => {
                if (index !== shotNoteCreateIndex) {
                  return shot;
                }

                return {
                  ...shot,
                  shot_notes: action.data.responseData.shot_notes,
                };
              })
            : state.shots;

        return {
          ...state,
          shotNoteCreate: false,
          shotNoteCreateSuccess: true,
          shots: updatedCreateShots,
        };
      }
      return {
        ...state,
        shotNoteCreate: false,
        shotNoteCreateSuccess: true,
      };
    }

    case "SHOT_NOTE_CREATE_REJECTED": {
      return {
        ...state,
        shotNoteCreate: false,
        shotNoteCreateSuccess: false,
        shotNoteCreateError: action.data,
      };
    }

    case "SHOT_NOTE_EDIT":
      return {
        ...state,
        shotNoteEditId: action.shotId,
        shotNoteEdit: true,
        shotNoteEditSuccess: false,
        shotNoteEditError: null,
      };

    case "SHOT_NOTE_EDIT_FULFILLED":
      if (state.shots) {
        let shotNoteEditIndex = -1;
        const shotEditIndex = state.shots
          .filter((i) => i.shot_notes)
          .findIndex((shot) => {
            shotNoteEditIndex = shot.shot_notes.findIndex(
              (shotNote) => shotNote.id === action.data.shotNoteId
            );
            return shotNoteEditIndex > -1;
          });
        let updatedEditShots = state.shots.slice();
        if (shotNoteEditIndex > -1 && shotEditIndex > -1) {
          updatedEditShots[shotEditIndex].shot_notes =
            action.data.responseData.shot_notes;
        }

        return {
          ...state,
          shotNoteEdit: false,
          shotNoteEditSuccess: true,
          shots: updatedEditShots,
        };
      }
      return {
        ...state,
        shotNoteEdit: false,
        shotNoteEditSuccess: true,
      };
    case "SHOT_NOTE_EDIT_REJECTED":
      return {
        ...state,
        shotNoteEdit: false,
        shotNoteEditSuccess: false,
        shotNoteEditError: action.data,
      };

    case "SHOT_NOTE_DELETE":
      return {
        ...state,
        shotNoteDeleteId: action.shotId,
        shotNoteDelete: true,
        shotNoteDeleteSuccess: false,
        shotNoteDeleteError: null,
      };
    case "SHOT_NOTE_DELETE_FULFILLED":
      if (state.shots) {
        let shotNoteDeleteIndex = -1;
        const shotDeleteIndex = state.shots
          .filter((i) => i.shot_notes)
          .findIndex((shot) => {
            shotNoteDeleteIndex = shot.shot_notes.findIndex(
              (shotNote) => shotNote.id === action.data.shotNoteId
            );
            return shotNoteDeleteIndex > -1;
          });

        const isDeleted =
          !!action.data.responseData.success &&
          shotNoteDeleteIndex > -1 &&
          shotDeleteIndex > -1;

        const updatedDeleteShots = isDeleted
          ? state.shots.slice().map((shot, index) => {
              if (index === shotDeleteIndex) {
                shot.shot_notes.splice(shotNoteDeleteIndex, 1);
                if (!shot.shot_notes.length) {
                  shot.recent_shot_note = null;
                }
              }

              return shot;
            })
          : state.shots;

        return {
          ...state,
          shotNoteDelete: false,
          shotNoteDeleteSuccess: true,
          shots: updatedDeleteShots,
        };
      }
      return {
        ...state,
        shotNoteDelete: false,
        shotNoteDeleteSuccess: true,
      };
    case "SHOT_NOTE_DELETE_REJECTED":
      return {
        ...state,
        shotNoteDelete: false,
        shotNoteDeleteSuccess: false,
        shotNoteDeleteError: action.data,
      };

    case "TASK_NOTE_EDIT_FULFILLED":
    case "TASK_NOTE_CREATE_FULFILLED":
      return {
        ...state,
        shots: state.shots.map((shot) => {
          if (shot.id === action.data.responseData.shot.id) {
            return {
              ...shot,
              tasks: shot.tasks.map((shotTask) => {
                if (shotTask.id === action.data.responseData.id) {
                  return action.data.responseData;
                }
                return shotTask;
              }),
            };
          }
          return shot;
        }),
      };

    case "TASK_NOTE_DELETE_FULFILLED":
      let taskNoteDeleteIndex = -1;
      let taskDeleteIndex = -1;
      const shotTaskNoteDeleteIndex = state.shots.findIndex((shot) => {
        taskDeleteIndex = shot.tasks.findIndex((shotTask) => {
          taskNoteDeleteIndex = shotTask.tasks_notes.findIndex(
            (shotTaskNote) => shotTaskNote.id === action.data.taskNoteId
          );
          return taskNoteDeleteIndex >= 0;
        });
        return taskDeleteIndex >= 0;
      });
      const taskNoteDeleted =
        !!action.data.responseData.success &&
        shotTaskNoteDeleteIndex >= 0 &&
        taskNoteDeleteIndex >= 0 &&
        taskDeleteIndex >= 0;

      return {
        ...state,
        shots: state.shots.map((shot, i) => {
          if (taskNoteDeleted && i === shotTaskNoteDeleteIndex) {
            return {
              ...shot,
              tasks: shot.tasks.map((task, j) => {
                if (j === taskDeleteIndex) {
                  task.tasks_notes.splice(taskNoteDeleteIndex, 1);
                }

                return task;
              }),
            };
          }
          return shot;
        }),
      };

    case "TASK_EDIT_FULFILLED":
      return {
        ...state,
        shots: state.shots.map((shot) => {
          if (shot.id === action.data.shot.id) {
            return {
              ...shot,
              tasks: shot.tasks
                ? shot.tasks.map((shotTask) => {
                    if (shotTask.id === action.data.id) {
                      return action.data;
                    }
                    return shotTask;
                  })
                : null,
            };
          }
          return shot;
        }),
      };

    case "USER_EDIT_FULFILLED":
    case "USER_PROFILE_EDIT_FULFILLED":
      return {
        ...state,
        shots: state.shots.map((shot) => {
          return {
            ...shot,
            tasks: shot.tasks.map((shotTask) => {
              if (
                shotTask.assignee &&
                shotTask.assignee.id === action.data.id
              ) {
                return {
                  ...shotTask,
                  assignee: {
                    ...shotTask.assignee,
                    avatar_color: action.data.avatar_color,
                  },
                };
              }
              return shotTask;
            }),
          };
        }),
      };

    case "SHOT_VERSION_CREATE":
      return {
        ...state,
        createShotVersion: true,
        createShotVersionSuccess: false,
        createShotVersionError: null,
      };

    case "SHOT_VERSION_CREATE_FULFILLED":
      return {
        ...state,
        createShotVersion: false,
        createShotVersionSuccess: true,

        shots: [...state.shots].map((item) => {
          return action.data.shot_id === item.id
            ? { ...item, current_version: action.data }
            : item;
        }),
      };

    case "SHOT_VERSION_CREATE_REJECTED":
      return {
        ...state,
        createShotVersion: false,
        createShotVersionSuccess: false,
        createShotVersionError: action.data,
      };

    case "SHOT_VERSION_EDIT":
      return {
        ...state,
        editShotVersion: true,
        editShotVersionSuccess: false,
        editShotVersionError: null,
      };

    case "SHOT_VERSION_EDIT_FULFILLED":
      return {
        ...state,
        editShotVersion: false,
        editShotVersionSuccess: true,

        shots: [...state.shots].map((item) => {
          return action.data.shot_id === item.id
            ? { ...item, current_version: action.data }
            : item;
        }),
      };

    case "SHOT_VERSION_EDIT_REJECTED":
      return {
        ...state,
        editShotVersion: false,
        editShotVersionSuccess: false,
        editShotVersionError: action.data,
      };

    // case "BID_VERSION_UPDATE_FULFILLED":
    //   const hasNewBidVersion =
    //     state.model.bid.create_order !== action.data.create_order;
    //
    //   let newStatus = state.model.status;
    //   let bids = state.model.bids;
    //
    //   if (!(state.model.bid.is_approved * 1) && action.data.is_approved * 1) {
    //     newStatus = "working";
    //   }
    //
    //   if (hasNewBidVersion) {
    //     bids = [...state.model.bids];
    //     bids.push(action.data);
    //   } else {
    //     bids = state.model.bids.map((item) =>
    //       item.id === action.data.id ? action.data : item
    //     );
    //   }
    //
    //   return {
    //     ...state,
    //     model: {
    //       ...(state.model || {}),
    //       bid: action.data,
    //       bids: bids,
    //       status: newStatus,
    //     },
    //   };

    case "BID_VERSION_APPROVE_FULFILLED":
      let newBidsWithApproved = state.model
        ? state.model.bids.map((value) => {
            return value.id === action.data.id ? action.data : { ...value };
          })
        : [action.data];
      return {
        ...state,
        model: {
          ...state.model,
          bids: newBidsWithApproved,
        },
      };

    case "LINK_PROJECT_WITH_TSHEETS":
      return {
        ...state,
        linkWithTSheets: true,
        linkWithTSheetsSuccess: false,
        linkWithTSheetsError: null,
      };

    case "LINK_PROJECT_WITH_TSHEETS_FULFILLED":
      return {
        ...state,
        linkWithTSheets: false,
        linkWithTSheetsSuccess: true,

        model: {
          ...state.model,
          tsheets_job_id: action.data.tsheets_job_id,
        },
      };

    case "LINK_PROJECT_WITH_TSHEETS_REJECTED":
      return {
        ...state,
        linkWithTSheets: false,
        linkWithTSheetsSuccess: false,
        linkWithTSheetsError: action.data,
      };

    case "UNLINK_PROJECT_WITH_TSHEETS":
      return {
        ...state,
        unlinkWithTSheets: true,
        unlinkWithTSheetsSuccess: false,
        unlinkWithTSheetsError: null,
      };

    case "UNLINK_PROJECT_WITH_TSHEETS_FULFILLED":
      return {
        ...state,
        unlinkWithTSheets: false,
        unlinkWithTSheetsSuccess: true,

        model: {
          ...state.model,
          tsheets_job_id: action.data.tsheets_job_id,
        },
      };

    case "UNLINK_PROJECT_WITH_TSHEETS_REJECTED":
      return {
        ...state,
        unlinkWithTSheets: false,
        unlinkWithTSheetsSuccess: false,
        unlinkWithTSheetsError: action.data,
      };

    case "TASKS_CHANGE_ASSIGNEE_FULFILLED":
      const updatedShots = state.shots.map((shot) => {
        const tasks = shot.tasks.map((task) => {
          const updatedTask = action.data.success.find(
            (updatedTask) => updatedTask.id === task.id
          );
          return updatedTask || task;
        });

        return {
          ...shot,
          tasks,
        };
      });

      return {
        ...state,
        shots: updatedShots,
        bulkEdit: false,
        bulkEditSuccess: true,
      };

    case "CLEAR_ERRORS":
      return {
        ...initialState,
        model: state.model,
        shots: state.shots,
      };

    case "PROJECT_CLEAR":
      return {
        ...initialState,
      };

    case "LOGOUT":
      return {
        ...initialState,
      };

    default:
      return state;
  }
}

/**
 *
 * @param action
 * @param state
 * @param data
 * @returns {*&{shots}}
 */
const handleEntityStateShotUpdate = (action, state, data) => {
  if (
    action.payload.room.name === "project" &&
    action.payload.room.id === state.model.id &&
    data &&
    data.state &&
    data.state.type === "shotUpdate"
  ) {
    return {
      ...state,
      shots: state.shots
        .map((item) => {
          return item.id === data.state.data.shotId
            ? {
                ...item,
                [data.state.data.fieldName]: data.state.data.value,
              }
            : item;
        })
        .sort(shotsSortFn)
        .reverse(),
    };
  }
};

/**
 *
 * @param action
 * @param state
 * @param data
 * @returns {*&{shots}}
 */
const handleEntityStateShotUpdateUpdate = (action, state, data) => {
  if (
    action.payload.room.name === "project" &&
    action.payload.room.id === state.model.id &&
    data &&
    data.state &&
    data.state.type === "shotUpdateUpdate"
  ) {
    return {
      ...state,
      shots: state.shots
        .map((item) => {
          return item.id === data.state.data.shotId
            ? {
                ...item,
                last_shot_update: data.state.data.value,
              }
            : item;
        })
        .sort(shotsSortFn)
        .reverse(),
    };
  }
};
