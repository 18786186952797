export const pdfBidFilenamesVariablesHint = `<div class='text-left'>
  <div>Available variables are:</div>
  <div><b>project_name</b> - project name,</div>
  <div><b>bid_name</b> - bid name,</div>
  <div><b>code</b> - project code,</div>
  <div><b>version</b> - current bid version,</div>
  <div><b>time</b> - creation time.</div>
  <div><div>Usage example:</div><div>BLP_[code]_Bid_[version].</div></div>
  </div>`;

export const pdfInvoiceFilenamesVariablesHint = `<div class='text-left'>
  <div>Available variables are:</div>
  <div><b>project_name</b> - project name,</div>
  <div><b>bid_name</b> - bid name,</div>
  <div><b>code</b> - project code,</div>
  <div><b>version</b> - current invoice #,</div>
  <div><b>time</b> - creation time.</div>
  <div><div>Usage example:</div><div>BLP_[code]_Bid_[version].</div></div>
  </div>`;

export const defaultTaskTypeHint = "This is a default VFX task";
