import React  from "react";
import PropTypes from "prop-types";
import UserAvatar from "./UserAvatar";
import classNames from "classnames";

const ArtistsList = (props) => {
  const listOfArtists = props.artists.length
    ? props.artists.map((item, index) => {
        return (
          <div className="d-inline-block cursor-default mr-2 mb-2" key={index}>
            <UserAvatar user={item} showTitle={true} key={index} />
          </div>
        );
      })
    : "--";

  const className = classNames("card", props.className);

  return (
    <div className={className}>
      <div className="card-header bg-primary text-white header-elements-inline">
        <h6 className="card-title">Artists in this shot</h6>
        <div className="header-elements" />
      </div>

      <div className="card-body">
        <div className="mb-n2 mr-n2">{listOfArtists}</div>
      </div>
    </div>
  );
};

ArtistsList.propTypes = {
  artists: PropTypes.array.isRequired,
  className: PropTypes.string
};

export default ArtistsList;
