import React, { useEffect } from "react";

import { history } from "../configureStore";

import UnauthorizedPageTemplate from "./UnauthorizedPageTemplate";
import * as userActions from "../actions/user";
import connect from "react-redux/es/connect/connect";

import LoadingIndicator from "../components/LoadingIndicator";
import ApiError from "../components/ApiError";
import SeoBlock from "../components/SeoBlock";

const PasswordResetPage = (props) => {
  useEffect(() => {
    if (props.user.passwordResetSuccess) {
      // console.log(props.user);
      props.user.model.verified
        ? history.push("/")
        : history.push("/not-approved");
    }
  }, [props.user.passwordResetSuccess]);
  /**
   *
   * @param event
   * @returns {Promise<void>}
   */
  const onResetPasswordPress = async (event) => {
    event.preventDefault();

    await props.passwordReset(
      document.querySelector(".js-user-password-reset-email").value,
      document.querySelector(".js-user-password-reset-token").value,
      document.querySelector(".js-user-password-reset-password").value
    );
  };

  const user = props.user;
  const loadingIndicator = user.passwordReset ? <LoadingIndicator /> : null;

  let locationAr = window.location.pathname.split("/");
  let resetPasswordToken = locationAr[locationAr.length - 1];
  let email = decodeURIComponent(locationAr[locationAr.length - 2]);

  return (
    <UnauthorizedPageTemplate>
      <SeoBlock title={"Password reset"}/>
      <form className="login-form">
        <div className="card mb-0">
          <div className="card-body">
            <div className="text-center mb-3">
              <i className="icon-spinner11 icon-2x text-warning border-warning border-3 rounded-round p-3 mb-3 mt-1" />
              <h5 className="mb-0">Password reset</h5>
              <span className="d-block text-muted">Type your new password</span>
            </div>

            {loadingIndicator}

            <div className="form-group form-group-feedback form-group-feedback-right">
              <input
                type="email"
                className="form-control js-user-password-reset-email"
                placeholder="Your email"
                defaultValue={email}
                disabled
              />
              <div className="form-control-feedback">
                <i className="icon-mail5 text-muted" />
              </div>
            </div>

            <div className="form-group form-group-feedback form-group-feedback-right">
              <input
                type="password"
                className="form-control js-user-password-reset-password"
                placeholder="Your new password"
              />
              <div className="form-control-feedback">
                <i className="icon-user-lock text-muted" />
              </div>
              <ApiError error={user.passwordResetError} />
            </div>

            <input
              type="hidden"
              className="js-user-password-reset-token"
              defaultValue={resetPasswordToken}
            />

            <button
              type="submit"
              className="btn bg-blue btn-block"
              onClick={onResetPasswordPress}
            >
              <i className="icon-spinner11 mr-2" /> Reset password
            </button>
          </div>
        </div>
      </form>
    </UnauthorizedPageTemplate>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  passwordReset: async (email, reset_password_token, password) => {
    await dispatch(
      userActions.passwordReset(email, reset_password_token, password)
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordResetPage);
