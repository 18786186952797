/**
 * Created by piotr.pozniak@thebeaverhead.com on 24/11/2021.
 */

import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as taskNotesActions from "../actions/taskNotes";

export const useTaskNotes = () => {
  const dispatch = useDispatch();
  const _taskNotes = useSelector((store) => store.taskNotes);

  const createTaskNote = useCallback(
    (taskId, note) => dispatch(taskNotesActions.createTaskNote(taskId, note)),
    [dispatch]
  );

  const editTaskNote = useCallback(
    (taskNoteId, note) => dispatch(taskNotesActions.editTaskNote(taskNoteId, note)),
    [dispatch]
  );


  const deleteTaskNote = useCallback(
    (taskNoteId) => dispatch(taskNotesActions.deleteTaskNote(taskNoteId)),
    [dispatch]
  );


  const fetchTaskNotes = useCallback(
    (taskId) => dispatch(taskNotesActions.fetchTaskNotes(taskId)),
    [dispatch]
  );


  return {
    rtc: _taskNotes,
    createTaskNote,
    editTaskNote,
    deleteTaskNote,
    fetchTaskNotes
  };
};
