import React from "react";
import PropTypes from "prop-types";

import ReactEcharts from "echarts-for-react";

const DonutChart = (props) => {
  const { colors, title, seriesName, data, isDarkThemed } = props;

  const style = {
    height: "406px",
    width: "100%",
  };

  let option = {
    color: colors,

    textStyle: {
      fontFamily: "Roboto, Arial, Verdana, sans-serif",
      fontSize: 13,
      color: isDarkThemed ? "#fff" : "#4D4D4D",
    },

    title: {
      text: title,
      left: "center",
      top: "30px",
      textStyle: {
        fontSize: 17,
        fontWeight: 500,
        color: isDarkThemed ? "#fff" : "#4D4D4D"
      },
    },

    tooltip: {
      trigger: "item",
      backgroundColor: "rgba(0,0,0,0.75)",
      padding: [10, 15],
      textStyle: {
        fontSize: 13,
        fontFamily: "Roboto, sans-serif",
        color: "#fff",
      },
      formatter: "{a} <br/>{b}: {c} ({d}%)",
    },

    legend: {
      orient: "vertical",
      top: "top",
      left: 0,
      data: data.map((item) => item.name),
      itemHeight: 8,
      itemWidth: 8,
      textStyle: {
        fontSize: 13,
        fontFamily: "Roboto, sans-serif",
        color: isDarkThemed ? "#fff" : "#4D4D4D"
      },
      inactiveColor: isDarkThemed ? "#626262" : "#CCCCCC"
    },

    series: [
      {
        name: seriesName,
        type: "pie",
        radius: ["50%", "70%"],
        center: ["50%", "57.5%"],
        itemStyle: {
          normal: {
            borderWidth: 1,
            borderColor: "#fff",
          },
        },
        label: {
          textBorderWidth: 0,
          color: isDarkThemed ? "#fff" : "#4D4D4D"
        },
        data,
      },
    ],
  };

  return colors.length && data.length ? (
    <ReactEcharts option={option} style={style} />
  ) : (
    <div />
  );
};

DonutChart.defaultProps = {
  colors: [],
  title: "",
  seriesName: "",
  data: [],
};

DonutChart.propTypes = {
  colors: PropTypes.array,
  title: PropTypes.string,
  seriesName: PropTypes.string,
  data: PropTypes.array,
  isDarkThemed: PropTypes.bool
};

export default DonutChart;
