import axios from 'axios';
import AxiosConfig from '../AxiosConfig';

/**
 *
 * @param page
 * @param limit
 * @param filters
 * @param type
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export const fetchProjects = (page = 1, limit = 20, filters, type) => {

  return dispatch => {

    dispatch({
      type: "PROJECTS_FETCH",
      data: { page, type, filters },
    });

    const apiFilters = filters || {};
    if (filters && filters.status) {
      apiFilters.status = filters.status.map(x => x.value || x);
    }
    if (filters && filters.clients) {
      apiFilters.clients = filters.clients.map(x => x.value || x);
    }

    const url = AxiosConfig.getEndpointAddress() + '/api/projects.json?' +
      AxiosConfig.objectToURLQuery({page, limit, ...apiFilters});

    return axios.get(
      url,
      AxiosConfig.getAuthConfig(),
    )
      .then((response) => {

        dispatch({
          type: "PROJECTS_FETCH_FULFILLED",
          data: response.data,
        });

      })
      .catch(function (error) {

        dispatch({
          type: "PROJECTS_FETCH_REJECTED",
          data: error,
        });

      });
  };
};

/**
 *
 * @param id
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export const deleteProject = (id) => {

  return async dispatch => {

    dispatch({type: "PROJECT_DELETE"});

    const url = AxiosConfig.getEndpointAddress() + "/api/projects/" + id + ".json";

    await axios.delete(
      url,
      {...AxiosConfig.getAuthConfig()},
    )
      .then((response) => {

        dispatch({
          type: "PROJECT_DELETE_FULFILLED",
          data: {
            responseData: response.data,
            id,
          },
        });

      })
      .catch(function (error) {

        dispatch({
          type: "PROJECT_DELETE_REJECTED",
          data: error,
        });

      });
  };
};
