import React from "react";
import PropTypes from "prop-types";
import ProjectSelect from "../../../components/Selects/ProjectSelect";
import CustomerSelect from "../../../components/Selects/CustomerSelect";
import Select from "../../../components/Select";
import * as tooltips from "../../../consts/tooltips";
import FormValidationError from "../../../components/FormValidationError";
import ApiError from "../../../components/ApiError";

const ProjectForm = (props) => {
  const {
    project,
    bidLink,
    isLoading,
    locationState,
    statusDefaultValue,
    statuses,
    formValidationErrorsResult,
    isEditMode,
    entityTypeMode,
    disabled,
    useExistingProject
  } = props;

  const projectName = (
    <div
      className="form-group"
      style={{
        display: isEditMode || entityTypeMode === "project" ? "block" : "none",
      }}
    >
      <label className="form-label">
        Project Name <span className="text-danger">*</span>
      </label>
      <input
        type="text"
        className="form-control js-project-edit-name"
        placeholder="Project Name"
        disabled={isLoading || disabled || useExistingProject}
        onBlur={props.onProjectNameBlur}
      />
    </div>
  );

  const projectSelect = !(isEditMode || entityTypeMode === "project") ? (
    <div className="form-group">
      <label className="form-label">
        Project Name <span className="text-danger">*</span>
      </label>
      <ProjectSelect
        onUpdate={props.onProjectSelectChange}
        creatable={true}
        reactSelectProps={{
          classNamePrefix:
            locationState && locationState.theme === "dark" ? "bg-dark" : "",
          isDisabled: disabled,
        }}
        allStatuses={true}
      />
    </div>
  ) : null;

  const tSheetsLink =
    isEditMode &&
    (project.model && project.model.code ? (
      <div>
        {project.model && project.model.tsheets_job_id ? (
          <div>
            <div className="mb-1">This project is linked with TSheets</div>
            <button
              disabled={isLoading || disabled || useExistingProject}
              type="button"
              className="btn bg-primary"
              onClick={props.onUnlinkWithTSheets}
            >
              Unlink it now
            </button>
          </div>
        ) : (
          <div>
            <div className="mb-1">This project is not linked with TSheets</div>
            <button
              disabled={isLoading || disabled || useExistingProject}
              type="button"
              className="btn bg-primary"
              onClick={props.onLinkWithTSheets}
            >
              Link it now
            </button>
          </div>
        )}
      </div>
    ) : (
      "This project is not linked with TSheets. Add project code to link with TSheets."
    ));

  return (
    <form noValidate>
      {projectSelect}

      {projectName}

      <div className="row">
        <div className="form-group col-6">
          <label className="form-label">
            Code&nbsp;
            {project.model && project.model.tsheets_job_id && (
              <span className="text-danger">*</span>
            )}
          </label>
          <input
            type="text"
            className="form-control js-project-edit-code"
            placeholder="Project Code"
            maxLength={16}
            disabled={isLoading || disabled || useExistingProject}
          />
        </div>

        <div className="form-group col-6">
          <label className="form-label">Due Date</label>
          <input
            type="date"
            className="form-control js-project-edit-due-date"
            disabled={isLoading || disabled || useExistingProject}
          />
        </div>
      </div>

      {!isLoading ? (
        <div className="form-group">
          <label className="form-label">
            Client <span className="text-danger">*</span>
          </label>
          <CustomerSelect
            defaultValue={project.model ? project.model.client : null}
            onUpdate={props.handleCustomerSelect}
            creatable={true}
            reactSelectProps={{
              classNamePrefix:
                locationState && locationState.theme === "dark"
                  ? "bg-dark"
                  : "",
              isDisabled: disabled || useExistingProject,
            }}
          />
        </div>
      ) : null}

      <div className="row">
        <div className="form-group col-6 select-minh-36">
          <label className="form-label">Status</label>
          <Select
            data={statuses}
            initClassName="js-select2"
            className="js-project-edit-status"
            defaultValue={statusDefaultValue}
            disabled={isLoading || disabled || useExistingProject}
          />
        </div>
      </div>

      <div className="row">
        <div className="form-group col-12">
          <div className="form-check">
            <label className="form-check-label">
              <input
                type="checkbox"
                className="form-check-input-styled js-project-edit-is-open"
                data-fouc
                defaultChecked={true}
                disabled={isLoading || disabled || useExistingProject}
              />
              Public
            </label>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="form-group col-6">
          <label className="form-label">
            BID PDF Filename <span className="text-danger">*</span>
            <i
              className="icon-question4 ml-2 fs-14"
              data-popup="tooltip"
              data-html="true"
              data-original-title={tooltips.pdfBidFilenamesVariablesHint}
            />
          </label>
          <input
            type="text"
            className="form-control js-project-edit-bid-pdf-filename"
            placeholder="BID PDF Filename"
            disabled={isLoading || disabled || useExistingProject}
          />
        </div>

        <div className="form-group col-6">
          <label className="form-label">
            Invoice PDF filename <span className="text-danger">*</span>
            <i
              className="icon-question4 ml-2 fs-14"
              data-popup="tooltip"
              data-html="true"
              data-original-title={tooltips.pdfInvoiceFilenamesVariablesHint}
            />
          </label>
          <input
            type="text"
            className="form-control js-project-edit-invoice-pdf-filename"
            placeholder="Invoice PDF filename"
            disabled={isLoading || disabled || useExistingProject}
          />
        </div>
      </div>

      {!isLoading && bidLink}

      <div className="form-group">
        <label className="form-label">Description</label>
        <textarea
          className="form-control js-project-edit-description"
          placeholder="Description"
          disabled={isLoading || disabled || useExistingProject}
        />
      </div>

      {tSheetsLink}

      <FormValidationError errors={formValidationErrorsResult} />
      <ApiError
        error={
          project.fetchError ||
          project.createError ||
          project.editError ||
          project.linkWithTSheetsError ||
          project.unlinkWithTSheetsError
        }
      />
    </form>
  );
};

ProjectForm.propTypes = {
  project: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  onProjectNameBlur: PropTypes.func.isRequired,
  handleCustomerSelect: PropTypes.func.isRequired,
  locationState: PropTypes.object,
  statusDefaultValue: PropTypes.string,
  bidLink: PropTypes.element,
  statuses: PropTypes.array,
  formValidationErrorsResult: PropTypes.object,
  onProjectSelectChange: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool,
  entityTypeMode: PropTypes.string,
  disabled: PropTypes.bool,
  onLinkWithTSheets: PropTypes.func.isRequired,
  onUnlinkWithTSheets: PropTypes.func.isRequired,
  useExistingProject: PropTypes.bool,
};

export default ProjectForm;
