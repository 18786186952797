import io from "socket.io-client";
import WSConfig from '../WSConfig';
import AxiosConfig from "../AxiosConfig";
import axios from "axios";

const socket = io(WSConfig.getEndpointAddress("activities"));

/**
 *
 * @param data
 * @returns {Function}
 */
export const connectActivitiesSocket = (data) => {
  return async dispatch => {

    dispatch({type: "ACTIVITIES_SOCKET_CONNECTION"});

    socket.off("connect");
    socket.off("authenticated");
    socket.off("initial_activity");
    socket.off("activity");
    socket.off("error");

    if (socket.connected) {
      socket.once("disconnect", () => {
        socket.open();
      });
      socket.close();
    }

    if (socket.disconnected) {
      socket.open();
    }

    socket.on("connect", () => {
      dispatch({type: "ACTIVITIES_SOCKET_CONNECTION_FULFILLED"});
      socket.emit(
        "authenticate",
        { token: AxiosConfig.getAuthToken() }
      );
    });

    socket.on("authenticated", () => {
      socket.on("initial_activity", (data) => {
        dispatch({type: "ACTIVITIES_INITIAL_FETCH_FULFILLED", data});
      });

      socket.on("activity", (data) => {
        dispatch({type: "ACTIVITY_FETCH_FULFILLED", data});
      });

      socket.emit("set_settings", data);

    });

    socket.on("error", (error) => {
      dispatch({type: "ACTIVITIES_SOCKET_CONNECTION_REJECTED", data: error});
    });

  }
};

/**
 *
 * @returns {Function}
 */
export const disconnectActivitiesSocket = () => {
  return async dispatch => {

    dispatch({type: "ACTIVITIES_SOCKET_DISCONNECTION"});

    socket.once("disconnect", () => {
      dispatch({type: "ACTIVITIES_SOCKET_DISCONNECTION_FULFILLED"});
    });

    socket.close();

  }
};

/**
 *
 * @param start_from_activity_id
 * @param data
 * @returns {function(*): Promise<AxiosResponse<any>>}
 */
export const getPreviousActivities = (start_from_activity_id, data = {}) => {
  return dispatch => {

    dispatch({type: "ACTIVITIES_FETCH_PREVIOUS"});

    const url = WSConfig.getEndpointAddress("activities") + '/api/Activities?'
      + AxiosConfig.objectToURLQuery({
          ...data,
          start_from_activity_id
        });

    const headers = {
      Authorization: AxiosConfig.getAuthToken()
    };

    return axios
      .get(url, {headers})
      .then((response) => {
        dispatch({
          type: "ACTIVITIES_FETCH_PREVIOUS_FULFILLED",
          data: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "ACTIVITIES_FETCH_PREVIOUS_REJECTED",
          data: error,
        });
      });
  }
};