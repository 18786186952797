/**
 * Created by piotr.pozniak@thebeaverhead.com on 24/11/2021.
 */

import React, { useEffect } from "react";
import HasRights from "../../components/HasRights";
import { useAdditionalCostsStore } from "../../storeHooks/additionalCosts";
import DropdownMenu from "../../components/DropdownMenu";
import { Link } from "react-router-dom";
import { history } from "../../configureStore";
import ApiError from "../../components/ApiError";

const AdditionalCosts = (props) => {
  const { additionalCosts, fetchAdditionalCosts, deleteAdditionalCost } =
    useAdditionalCostsStore();

  useEffect(() => {
    fetchAdditionalCosts();
  }, []);



  const onEditClick = (item) => (e) => {
    history.push(`/platform-settings/additional-costs/${item.id}`);
  };

  const onDeleteClick = (item) => (e) => {
    if (
      window.confirm(
        `Are you sure you want to delelete ${item.label} from additional costs?`
      )
    ) {
      deleteAdditionalCost(item.id);
    }
  };

  const records = additionalCosts.collection.map((item, idx) => {
    return (
      <tr key={`acr-${item.id}`}>
        <td>{item.label}</td>
        <td>${item.default_value}</td>
        <td>{item.is_rate_based * 1 ? <i className={"mi-done"} /> : null}</td>
        <td>{item.available * 1 ? <i className={"mi-done"} /> : null}</td>
        <td className="text-center">
          <DropdownMenu
            buttons={[
              {
                icon: "mi-mode-edit",
                action: onEditClick(item),
                data: item,
                label: "Edit details",
              },
              {
                icon: "mi-delete-forever",
                action: onDeleteClick(item),
                data: item,
                label: "Delete",
              },
            ]}
          />
        </td>
      </tr>
    );
  });

  return (
    <div>
      <hr />
      <div className="card-header header-elements-inline mt-3">
        <h6 className="card-title">Additional Costs</h6>
        <div className="header-elements">
          <HasRights
            allowedRoles={["admin", "producer"]}
            user={props.userModel}
          >
            <Link to={"/platform-settings/additional-costs/new"}>
              <i
                className="icon-stack-plus text-primary"
                data-popup="tooltip"
                data-original-title="Create a new additional cost"
                style={{ cursor: "pointer" }}
              />
            </Link>
          </HasRights>
        </div>
      </div>
      <div>
        <table className="table datatable-basic">
          <thead>
            <tr>
              <th>Label</th>
              <th>Default Rate</th>
              <th>Rate Based</th>
              <th>Enabled</th>
              <HasRights
                allowedRoles={["admin", "producer"]}
                user={props.userModel}
              >
                <th className="text-center">Actions</th>
              </HasRights>
            </tr>
          </thead>
          <tbody>{records}</tbody>
        </table>
        <ApiError error={additionalCosts.deleteError} />
      </div>
    </div>
  );
};

export default AdditionalCosts;
