export const initialState = {
  fetch: false,
  fetchSuccess: false,
  fetchError: null,

  create: false,
  createSuccess: false,
  createError: null,

  edit: false,
  editSuccess: false,
  editError: null,

  delete: false,
  deleteSuccess: false,
  deleteError: null,

  editDepositInvoice: false,
  editDepositInvoiceSuccess: false,
  editDepositInvoiceError: null,

  createPdf: false,
  createPdfSuccess: false,
  createPdfError: null,

  collection: null,
};

export default function reducer(
  state = {
    ...initialState,
  },
  action
) {
  switch (action.type) {
    case "PROJECT_INVOICES_FETCH":
      return {
        ...state,
        fetch: true,
        fetchSuccess: false,
        fetchError: null,
      };

    case "PROJECT_INVOICES_FETCH_FULFILLED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: true,

        collection: action.data.invoices,
      };

    case "PROJECT_INVOICES_FETCH_REJECTED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: false,
        fetchError: action.data,
      };

    case "PROJECT_INVOICE_CREATE":
      return {
        ...state,
        create: true,
        createSuccess: false,
        createError: null,
      };

    case "PROJECT_INVOICE_CREATE_FULFILLED":
      const withNewInvoiceCollection = [...state.collection];
      withNewInvoiceCollection.push(action.data);

      return {
        ...state,
        create: false,
        createSuccess: true,

        collection: withNewInvoiceCollection,
      };

    case "PROJECT_INVOICE_CREATE_REJECTED":
      return {
        ...state,
        create: false,
        createSuccess: false,
        createError: action.data,
      };

    case "PROJECT_INVOICE_EDIT":
      return {
        ...state,
        edit: true,
        editSuccess: false,
        editError: null,
      };

    case "PROJECT_INVOICE_EDIT_FULFILLED":
      let withEditedInvoiceCollection = state.collection.map((value) => {
        return value.id === action.data.id ? action.data : { ...value };
      });

      return {
        ...state,
        edit: false,
        editSuccess: true,

        collection: withEditedInvoiceCollection,
      };

    case "PROJECT_INVOICE_EDIT_REJECTED":
      return {
        ...state,
        edit: false,
        editSuccess: false,
        editError: action.data,
      };

    case "PROJECT_INVOICES_DELETE":
      return {
        ...state,
        delete: true,
        deleteSuccess: false,
        deleteError: null,
      };

    case "PROJECT_INVOICES_DELETE_FULFILLED":
      let isDeleted = !!action.data.responseData.success;
      let newCollectionWithDeleted = isDeleted
        ? state.collection.filter((value) => value.id !== action.data.invoiceId)
        : state.collection;
      return {
        ...state,
        delete: false,
        deleteSuccess: isDeleted,

        collection: newCollectionWithDeleted,
      };

    case "PROJECT_INVOICES_DELETE_REJECTED":
      return {
        ...state,
        delete: false,
        deleteSuccess: false,
        deleteError: action.data,
      };

    case "DEPOSIT_INVOICE_STATUS_EDIT":
      return {
        ...state,
        editDepositInvoice: true,
        editDepositInvoiceSuccess: false,
        editDepositInvoiceError: null,
      };

    case "DEPOSIT_INVOICE_STATUS_EDIT_FULFILLED":
      return {
        ...state,
        collection: state.collection
          ? state.collection.map((item) =>
              item.bid.id === action.data.id
                ? {
                    ...item,
                    bid: {
                      ...item.bid,
                      deposit_invoice_status:
                        action.data.deposit_invoice_status,
                    },
                  }
                : item
            )
          : state.collection,

        editDepositInvoice: false,
        editDepositInvoiceSuccess: true,
      };

    case "BID_EDIT_FULFILLED":
      return {
        ...state,
        collection: state.collection
          ? state.collection.map((item) =>
              item.bid.id === action.data.id
                ? {
                    ...item,
                    bid: {
                      ...item.bid,
                      ...action.data,
                    },
                  }
                : item
            )
          : state.collection,
      };

    case "DEPOSIT_INVOICE_STATUS_EDIT_REJECTED":
      return {
        ...state,
        editDepositInvoice: false,
        editDepositInvoiceSuccess: false,
        editDepositInvoiceError: action.data,
      };

    case "PROJECT_INVOICE_PDF":
      return {
        ...state,
        createPdf: true,
        createPdfSuccess: false,
        createPdfError: null,
      };

    case "PROJECT_INVOICE_PDF_FULFILLED":
      return {
        ...state,
        createPdf: false,
        createPdfSuccess: true,
      };

    case "PROJECT_INVOICE_PDF_REJECTED":
      return {
        ...state,
        createPdf: false,
        createPdfSuccess: false,
        createPdfError: action.data,
      };

    case "PROJECT_DEPOSIT_INVOICE_PDF":
      return {
        ...state,
        createPdf: true,
        createPdfSuccess: false,
        createPdfError: null,
      };

    case "PROJECT_DEPOSIT_INVOICE_PDF_FULFILLED":
      return {
        ...state,
        createPdf: false,
        createPdfSuccess: true,
      };

    case "PROJECT_DEPOSIT_INVOICE_PDF_REJECTED":
      return {
        ...state,
        createPdf: false,
        createPdfSuccess: false,
        createPdfError: action.data,
      };

    case "CLEAR_ERRORS":
      return {
        ...initialState,
        collection: state.collection,
      };

    case "LOGOUT":
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
