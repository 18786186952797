import React, {Component} from 'react';
import PropTypes from 'prop-types';
import LoadingIndicator from "./LoadingIndicator";

class EditNote extends Component {

  constructor(props) {
    super(props);

    this.state = {
      note: (this.props.noteModel && this.props.noteModel.note) || "",
    };
  }

  /**
   *
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevNote = prevProps.noteModel ? prevProps.noteModel.note : "";
    const note = this.props.noteModel ? this.props.noteModel.note : "";
    if (prevNote !== note) {
      this.setState({note});
    }
  }

  /**
   *
   * @returns {boolean}
   */
  get editMode () {
    return this.props.noteModel && this.props.noteModel.id;
  }

  /**
   *
   * @param evt
   */
  handleNoteChange = (evt) => {
    this.setState({note: evt.target.value});
  };

  /**
   *
   */
  handleCreate = () => {
    const note = this.state.note;

    if (this.editMode) {
      this.props.handleEditNote(note);
    } else {
      this.props.handleCreateNote(note);
    }
  };

  /**
   *
   * @returns {*}
   */
  render() {
    const buttonLabel = this.editMode ? "Edit Note" : "Add Note";
    const loading = this.props.noteCreate || this.props.noteEdit;
    const submitDisable = this.state.note.length === 0 || loading;
    const loadingIndicator = loading ? <LoadingIndicator/> : null;

    return (
      <div className="mt-2">
        <textarea rows="6" className="form-control border-primary border-1" value={this.state.note}
                  onChange={this.handleNoteChange} autoFocus disabled={loading} />
        <div className="d-flex justify-content-between align-items-center mt-2">
          <button type="button" className="btn btn-link mb-3" onClick={this.props.handleCancel}
                  disabled={loading}>Cancel</button>
          <div className="d-flex justify-content-between align-items-center">
            {loadingIndicator}
            <button type="button" className="btn btn-primary mb-3 ml-3" disabled={submitDisable}
                    onClick={this.handleCreate}>
              {buttonLabel}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

EditNote.propTypes = {
  noteModel: PropTypes.shape({
    id: PropTypes.string,
    note: PropTypes.string,
  }),
  handleCancel: PropTypes.func.isRequired,
  handleCreateNote: PropTypes.func.isRequired,
  handleEditNote: PropTypes.func.isRequired,
  noteEdit: PropTypes.bool,
  noteCreate: PropTypes.bool,
};

export default EditNote;