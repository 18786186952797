import PropTypes from "prop-types";

const ClientFormat = (props) => {
  const { project } = props;

  if (!project) {
    return null;
  }

  return project && project.client ? project.client.name : null;
};

ClientFormat.propTypes = {
  project: PropTypes.object,
};

export default ClientFormat;
