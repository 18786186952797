import React, { Component } from "react";
import { history } from "../../configureStore";
import StaticAlert from "../../components/StaticAlert";
import PageTemplate from "../PageTemplate";
import * as projectsActions from "../../actions/projects";
import * as projectActions from "../../actions/project";
import connect from "react-redux/es/connect/connect";
import { withRouter } from "react-router-dom";
import DataGridPagination from "../../components/DataGridPagination";
import LoadingIndicator from "../../components/LoadingIndicator";
import DropdownMenu from "../../components/DropdownMenu";
import HasRights from "../../components/HasRights";
import {
  filterAllowedRoles,
  filterDataTableOptionsByRole,
  hasRights,
} from "../../helpers/tools";
import CustomizableDataTable from "../../components/CustomizableDataTable";
import * as appActions from "../../actions/app";
import ProjectNameFormat from "./components/ProjectNameFormat";
import ClientFormat from "./components/ClientFormat";
import TimeSpentFormat from "./components/TimeSpentFormat";
import * as moment from "moment";
import { editProject } from "../../actions/project";
import ApiError from "../../components/ApiError";
import { deleteProject } from "../../actions/projects";
import StatusEditable from "../../components/StatusEditable";
import SeoBlock from "../../components/SeoBlock";

const staticAlertHeader = "No records to display";
const staticAlertBody = "Either create a new item or update your filters.";

const trashAllowedRoles = ["admin", "producer", "accountant", "artist"];

class ProjectsPage extends Component {
  constructor(props) {
    super(props);
    const filterProps = this.filters;

    const filter = Object.keys(filterProps).map((key) => ({
      fieldName: key,
      value: filterProps[key],
    }));
    this.state = {
      filter,
      isFiltersApplied: !!filter.filter((i) => i && i.value).length,
      sortBy: props.filtersReducer.projectsSortBy,
      orderBy: props.filtersReducer.projectsOrderBy,
    };
  }

  /**
   * get filter from router
   * @return {{}}
   */
  get routerFilters() {
    const filters = {};
    if (this.props.match.params.slug === "active") {
      filters.status = "working";
    }
    if (this.props.match.params.slug === "archive") {
      filters.is_archived = 1;
    }
    if (this.props.match.params.slug === "trash") {
      filters.is_trash = 1;
    }

    return filters;
  }

  /**
   *
   * @return {{}}
   */
  get filters() {
    return { ...this.savedFilters, ...this.routerFilters };
  }

  get statuses() {
    return this.props.statuses.collection.filter((i) => i.type === "Projects");
  }

  /**
   * get filter from reducer
   * @return {{}}
   */
  get savedFilters() {
    const filters = {};

    if (
      Array.isArray(this.props.filtersReducer.projectsStatus) &&
      this.props.filtersReducer.projectsStatus.length === 1
    ) {
      let status = this.props.filtersReducer.projectsStatus[0];
      if (status === "working") {
        status = null;
      }
      filters.status = status;
    }

    if (
      Array.isArray(this.props.filtersReducer.projectsClients) &&
      this.props.filtersReducer.projectsClients.length
    ) {
      filters.clientFilter = this.props.filtersReducer.projectsClients[0];
    }

    filters.name = this.props.filtersReducer.projectsQuery;
    filters.fromDate = this.props.filtersReducer.projectsFrom;
    filters.toDate = this.props.filtersReducer.projectsTo;

    return filters;
  }

  /**
   *
   * @returns {{perPage: number, columns: *[], filters: *[]}}
   */
  get projectsDataTableConfig() {
    const statusFilter = this.state.filter.find(
      (x) => x.fieldName === "status"
    );
    const status = statusFilter && statusFilter.value ? statusFilter.value : "";

    const nameFilter = this.state.filter.find((x) => x.fieldName === "name");
    const name = nameFilter ? nameFilter.value : "";

    const fromDateFilter = this.state.filter.find(
      (x) => x.fieldName === "fromDate"
    );
    const fromDate = fromDateFilter ? fromDateFilter.value : "";

    const toDateFilter = this.state.filter.find(
      (x) => x.fieldName === "toDate"
    );
    const toDate = toDateFilter ? toDateFilter.value : "";

    const clientFilter = this.state.filter.find(
      (x) => x.fieldName === "clientFilter"
    );
    const clients = clientFilter ? clientFilter.value : null;

    return {
      perPage: 30,
      columns: [
        {
          name: "Name",
          selector: "name",
          format: (row) => (
            <ProjectNameFormat
              project={row}
              onClickProject={this.onClickProject}
            />
          ),
          sortable: true,
        },
        {
          name: "Status",
          selector: "status",
          format: (row) => {
            return (
              <div className="">
                <StatusEditable
                  status={row.status}
                  statuses={this.statuses}
                  onChange={this.onStatusChange(row)}
                  loading={
                    this.props.project.edit &&
                    this.state.editProjectId === row.id
                  }
                  disabled={
                    !["admin", "producer", "accountant"].includes(
                      this.props.user.model.role
                    ) ||
                    (this.props.project.edit &&
                      this.state.editProjectId !== row.id)
                  }
                />
              </div>
            );
            /* const statuses = this.props.statuses.collection.filter(
              (i) => i.type === "Projects"
            );
            return <StatusBadge status={row.status} statuses={statuses} />;*/
          },
          sortable: true,
        },
        {
          name: "Due Date",
          selector: "due_date",
          format: (
            row // Should be updated to use DateUSSlash after this component is merged to develop
          ) =>
            !row.due_date ? "--" : moment.unix(row.due_date).format("MM/DD/YY"),
          sortable: true,
          roleOptions: {
            client: {
              center: true,
            },
          },
        },
        {
          name: "Client",
          selector: "client",
          format: (row) => <ClientFormat project={row} />,
          sortable: true,
          allowedRoles: ["admin", "producer", "artist", "accountant"],
        },
        // {
        //   name: "Code",
        //   selector: "code"
        // },
        // {
        //   name: "Is open",
        //   selector: "is_open",
        //   format: row =>
        //     row.is_open
        //       ? <i className="icon-checkmark3" style={{marginLeft: 15}}/>
        //       : null,
        //   width: "120px",
        // },
        {
          name: "Time spent [h]",
          selector: "time_spent",
          format: (row) => <TimeSpentFormat project={row} />,
          allowedRoles: ["admin", "producer", "artist", "accountant"],
        },
        {
          name: "Actions",
          right: true,
          cell: (row) => {
            const buttons = [];
            if (!row.is_archived && !row.is_trash) {
              buttons.push({
                icon: "mi-mode-edit",
                action: this.onEditProjectDetails,
                data: row,
                label: "Edit details",
              });
              buttons.push({
                icon: "mi-archive",
                action: this.onArchiveProject(1),
                data: row,
                label: "Archive",
              });
              buttons.push({
                icon: "mi-delete",
                action: this.onTrashProject(1),
                data: row,
                label: "Move to Trash",
              });
            } else if (row.is_archived) {
              buttons.push({
                icon: "mi-unarchive",
                action: this.onArchiveProject(0),
                data: row,
                label: "Unarchive",
              });
            } else if (row.is_trash) {
              buttons.push({
                icon: "mi-restore",
                action: this.onTrashProject(0),
                data: row,
                label: "Restore",
              });
              buttons.push({
                icon: "mi-money-off",
                action: this.onDeleteProject,
                data: row,
                label: "Permanently delete",
              });
            }
            return (
              <DropdownMenu dropdownMenuClassName="bg-dark" buttons={buttons} />
            );
          },
          allowedRoles: ["admin", "producer"],
          width: "70px",
        },
      ],
      filters: [
        {
          placeholder: "Client",
          fieldName: "clients",
          type: "customerSelect",
          onUpdate: this.clientsFilterOnUpdate,
          reactSelectProps: {
            isMulti: false,
            value: clients,
            isClearable: true,
            classNamePrefix: "bg-dark",
          },
          allowedRoles: ["admin", "producer"],
        },
        {
          placeholder: "Status",
          data: this.props.statuses.collection
            .filter((i) => i.type === "Projects")
            .filter((i) => i.value !== "bid_in_progress"),
          fieldName: "status",
          type: "select",
          value: status,
        },
        {
          placeholder: "Project name or a code",
          defaultValue: name,
          fieldName: "name",
          type: "text",
          tooltipMessage:
            "This field should be at least 3 symbols to filter by a name or a code",
        },
        {
          placeholderFrom: "Delivery date (from)",
          placeholderTo: "Delivery date (to)",
          fieldNameFrom: "fromDate",
          fieldNameTo: "toDate",
          fieldName: "dateRange",
          defaultValueFrom: fromDate,
          defaultValueTo: toDate,
          type: "dateRange",
        },
      ],
    };
  }

  /**
   *
   */
  componentDidMount() {
    if (this.props.user.token && this.props.user.model) {
      const page = this.props.filtersReducer.projectsPage;
      let slug = this.props.match.params.slug;
      if (
        slug === "trash" &&
        !hasRights(this.props.user.model, {}, trashAllowedRoles)
      ) {
        slug = undefined;
        history.replace(`/projects/${page}`);
      }

      // go to the page saved in store
      if (
        this.props.filtersReducer.projectsPage !== this.props.match.params.page
      ) {
        const state = this.props.router.location.state;
        if (slug) {
          history.replace(`/projects/${slug}/${page}`, state);
        } else {
          history.replace(`/projects/${page}`, state);
        }
      } else {
        this.handleFilter();
      }
    }
  }

  /**
   *
   * @param prevProps
   */
  componentDidUpdate(prevProps) {
    // update filters
    if (this.props.match.params.slug !== prevProps.match.params.slug) {
      const filterProps = this.filters;
      const filter = Object.keys(filterProps).map((key) => ({
        fieldName: key,
        value: filterProps[key],
      }));
      this.setState(
        {
          filter,
          sortBy: null,
          orderBy: null,
        },
        () => {
          document.querySelector(".js-projects-filter") &&
            document.querySelector(".js-projects-filter").reset();
          this.handleFilter();
        }
      );
    }

    // paginate
    if (
      prevProps.match.params.page !== this.props.match.params.page &&
      prevProps.match.params.slug === this.props.match.params.slug
    ) {
      this.handleFilter();
    }

    if (
      prevProps.projects.fetchError !== this.props.projects.fetchError &&
      this.props.projects.fetchError
    ) {
      const page = Number(this.props.match.params.page);
      if (page > 1) {
        const state = this.props.router.location.state;
        const slug = this.props.match.params.slug;
        if (slug) {
          history.replace(`/projects/${slug}/1`, state);
        } else {
          history.replace(`/projects/1`, state);
        }
      }
    }
  }

  /**
   *
   * @param page
   */
  onChangePage = (page) => {
    const state = this.props.router.location.state;
    const slug = this.props.match.params.slug;
    if (slug) {
      history.push(`/projects/${slug}/${page}`, state);
    } else {
      history.push(`/projects/${page}`, state);
    }
  };

  /**
   *
   * @param project
   */
  onEditProjectDetails = (project) => {
    this.props.setEditProject(project);
    history.push(`/project/edit/${project.id}`, { theme: "dark" });
  };

  /**
   *
   * @param value
   */
  onArchiveProject = (value) => async (project) => {
    await this.props.editProject(project.id, { is_archived: value });
    this.handleFilter();
  };

  /**
   *
   * @param value
   */
  onTrashProject = (value) => async (project) => {
    await this.props.editProject(project.id, { is_trash: value });
    this.handleFilter();
  };

  /**
   *
   * @param project
   */
  onDeleteProject = async (project) => {
    if (
      window.confirm("Are you sure you want to delete " + project.name + "?")
    ) {
      await this.props.deleteProject(project.id);
      this.handleFilter();
    }
  };

  /**
   *
   * @param project
   * @returns {Function}
   */
  onClickProject = (project) => () => {
    this.props.setEditProject(project);
  };

  /**
   *
   * @param status
   */
  statusFilterOnChange = (status) => {
    this.setState({
      filter: { ...this.state.filter, status: status },
    });
  };

  /**
   *
   * @param value
   */
  clientsFilterOnUpdate = (value) => {
    const filter = [...this.state.filter];
    let filterField = {
      fieldName: "clientFilter",
      value: null,
    };
    if (value) {
      filterField = {
        fieldName: "clientFilter",
        value: {
          label: value.email ? `${value.name} (${value.email})` : value.name,
          value: value.id,
        },
      };
    }
    const clientFilterIndex = filter.findIndex(
      (x) => x.fieldName === "clientFilter"
    );
    if (clientFilterIndex > -1) {
      if (value) {
        filter[clientFilterIndex] = filterField;
      } else {
        filter.splice(clientFilterIndex, 1);
      }
    } else if (value) {
      filter.push(filterField);
    }
    this.setState({ filter });
  };

  /**
   *
   * @param filter
   * @returns {Promise<void>}
   */
  onFilter = (filter = []) => {
    // special case, because clientFilter is not handled by jQuery on CustomizableDataTable
    const stateFilter = [...this.state.filter];
    const clientFilterIndex = stateFilter.findIndex(
      (x) => x.fieldName === "clientFilter"
    );
    if (clientFilterIndex > -1) {
      filter.push(stateFilter[clientFilterIndex]);
    }
    // special case 2, apply filters from the router
    const routerFilters = this.routerFilters;
    Object.keys(routerFilters).forEach((key) => {
      filter.push({
        fieldName: key,
        value: routerFilters[key],
      });
    });
    //
    this.setState({ filter, isFiltersApplied: !!filter.length }, () => {
      if (this.props.match.params.page !== "1") {
        const state = this.props.router.location.state;
        const slug = this.props.match.params.slug;
        if (slug) {
          history.replace(`/projects/${slug}/1`, state);
        } else {
          history.replace("/projects/1", state);
        }
      } else {
        this.handleFilter();
      }
    });
  };

  /**
   *
   */
  handleFilter = async () => {
    const statusFilter = this.state.filter.find(
      (x) => x.fieldName === "status"
    );
    const status =
      statusFilter && statusFilter.value ? [statusFilter.value] : null;

    const nameFilter = this.state.filter.find((x) => x.fieldName === "name");
    const name = nameFilter ? nameFilter.value : null;

    const fromDateFilter = this.state.filter.find(
      (x) => x.fieldName === "fromDate"
    );
    const fromDate = fromDateFilter ? fromDateFilter.value : null;

    const toDateFilter = this.state.filter.find(
      (x) => x.fieldName === "toDate"
    );
    const toDate = toDateFilter ? toDateFilter.value : null;

    const clientFilter = this.state.filter.find(
      (x) => x.fieldName === "clientFilter"
    );
    const clients = clientFilter ? [clientFilter.value] : null;

    const is_archivedFilter = this.state.filter.find(
      (x) => x.fieldName === "is_archived"
    );
    const is_archived = is_archivedFilter ? is_archivedFilter.value : null;

    const is_trashFilter = this.state.filter.find(
      (x) => x.fieldName === "is_trash"
    );
    const is_trash = is_trashFilter ? is_trashFilter.value : null;

    await this.props.fetchProjects(
      this.props.match.params.page,
      this.projectsDataTableConfig.perPage,
      {
        status,
        clients,
        q: name,
        from: fromDate,
        to: toDate,
        sort_by: this.state.sortBy,
        order_by: this.state.orderBy,
        is_archived,
        is_trash,
      }
    );

    window.updateJQuery();
  };

  /**
   *
   */
  onClearAll = () => {
    const filter = [];
    this.setState(
      {
        filter,
        sortBy: null,
        orderBy: null,
        isFiltersApplied: false,
      },
      () => {
        this.onFilter(filter);
      }
    );
  };

  /**
   *
   * @param column
   * @param orderBy
   */
  onTableSort = (column, orderBy) => {
    this.setState(
      {
        sortBy: column.selector,
        orderBy,
      },
      this.handleFilter
    );
  };

  /**
   *
   * @param collapse
   */
  onToggleFilters = (collapse) => {
    this.props.collapseFilter("ProjectsPage", collapse);
  };

  /**
   *
   * @param row
   * @returns {(function(*): void)|*}
   */
  onStatusChange = (row) => (value) => {
    if (value) {
      this.props.clearErrors();
      this.setState(
        {
          editProjectId: row.id,
        },
        async () => {
          await this.props.editProject(row.id, { status: value });
          this.setState({
            editProjectId: null,
          });
          window.updateJQuery();
        }
      );
    }
  };

  /**
   *
   * @returns {XML}
   */
  render() {
    const project = this.props.project;
    const projects = this.props.projects;
    const collection = projects.collection;

    const paginationIndicator =
      projects.fetch && collection ? <LoadingIndicator isModal /> : null;

    const pagination =
      collection && collection.length && projects && projects.pagination ? (
        <DataGridPagination
          onChangePage={this.onChangePage}
          pagination={projects.pagination}
        />
      ) : null;

    const tableColumnsFilteredRoles = filterAllowedRoles(
      this.props.user.model,
      this.projectsDataTableConfig.columns
    );

    const tableColumnsFilteredOptions = filterDataTableOptionsByRole(
      this.props.user.model,
      tableColumnsFilteredRoles
    );

    const allowedFilters = filterAllowedRoles(
      this.props.user.model,
      this.projectsDataTableConfig.filters
    );

    const dataTable = collection ? (
      <div className="data-table-themed_cells-nowrap">
        <CustomizableDataTable
          columns={tableColumnsFilteredOptions}
          collection={collection}
          showFilters={true}
          collapseFilters={this.props.app.projectsPageFilterCollapsed}
          onToggleFilters={this.onToggleFilters}
          filters={allowedFilters}
          filterSelectorHtmlClass="js-projects-filter"
          responsive={false}
          className={collection.length ? null : "data-table-empty"}
          onFilter={this.onFilter}
          onClearAll={this.onClearAll}
          isFiltersApplied={this.state.isFiltersApplied}
          footerComponent={pagination}
          onSort={this.onTableSort}
          sortServer
          defaultSortField={this.state.sortBy}
          defaultSortAsc={this.state.orderBy === "asc"}
          filtersClassName="bg-dark"
        />
      </div>
    ) : null;

    const staticAlert =
      collection && !collection.length ? (
        <HasRights
          allowedRoles={["admin", "producer", "artist"]}
          user={this.props.user.model}
        >
          <StaticAlert
            header={staticAlertHeader}
            body={staticAlertBody}
            className="bg-dark"
          />
        </HasRights>
      ) : null;

    const loadingIndicator =
      projects.delete || project.edit ? <LoadingIndicator isModal /> : null;

    let pageTitle = "Projects";
    if (
      this.state.filter.find((x) => x.fieldName === "is_archived") &&
      this.state.filter.find((x) => x.fieldName === "is_archived").value
    ) {
      pageTitle = "Archived Projects";
    } else if (
      this.state.filter.find((x) => x.fieldName === "is_trash") &&
      this.state.filter.find((x) => x.fieldName === "is_trash").value
    ) {
      pageTitle = "Trash Projects";
    } else if (
      this.state.filter.find((x) => x.fieldName === "status") &&
      this.state.filter.find((x) => x.fieldName === "status").value ===
        "working"
    ) {
      pageTitle = "Active Projects";
    }

    const backArrowPath = "/dashboard";

    return (
      <PageTemplate
        header={pageTitle}
        pageWrapperClassName="bg-dark page-header-dark-theme"
        pageFooterClassName="navbar-dark"
        breadcrumbClassName="breadcrumb-line-dark"
        backArrowPath={backArrowPath}
      >
        <SeoBlock title={pageTitle} />
        {collection === null && <LoadingIndicator />}
        <ApiError error={project.editError || projects.deleteError} />
        {dataTable}
        {staticAlert}
        {paginationIndicator}
        {loadingIndicator}
      </PageTemplate>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
  user: state.user,
  statuses: state.statuses,
  project: state.project,
  projects: state.projects,
  filtersReducer: state.filtersReducer,
  router: state.router,
});

const mapDispatchToProps = (dispatch) => ({
  fetchProjects: async (page, limit, filters, type = "ProjectsPage") =>
    await dispatch(projectsActions.fetchProjects(page, limit, filters, type)),
  setEditProject: (project) => dispatch(projectActions.setEditProject(project)),
  collapseFilter: (type, collapse) =>
    dispatch(appActions.collapseFilter(type, collapse)),
  editProject: (id, data) => dispatch(editProject(id, data)),
  deleteProject: (id) => dispatch(deleteProject(id)),
  clearErrors: () => dispatch(appActions.clearErrors()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProjectsPage));
