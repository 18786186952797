import React, { Component } from "react";

import { history } from "../configureStore";
import { Link } from "react-router-dom";

import UnauthorizedPageTemplate from "./UnauthorizedPageTemplate";
import * as userActions from "../actions/user";
import * as tasksTypesActions from "../actions/tasksTypes";
import * as statusesActions from "../actions/statuses";
import connect from "react-redux/es/connect/connect";

import LoadingIndicator from "../components/LoadingIndicator";
import ApiError from "../components/ApiError";
import SeoBlock from "../components/SeoBlock";

class LoginPage extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  /**
   *
   * @param event
   * @returns {Promise<void>}
   */
  onLoginPress = async (event) => {
    event.preventDefault();
    await this.props.login(
      document.querySelector(".js-user-login-email").value,
      document.querySelector(".js-user-login-password").value
    );
    if (this.props.user.loginSuccess) {
      this.props.fetchTasksTypes();
      this.props.fetchStatuses();
      history.push("/");
    }
  };

  /**
   *
   * @returns {XML}
   */
  render() {
    const user = this.props.user;
    const loadingIndicator = user.login ? <LoadingIndicator /> : null;

    const isPasswordResetRequired =
      user.loginError &&
      user.loginError.response &&
      user.loginError.response.status === 426;

    const loginError = isPasswordResetRequired ? null : user.loginError;

    const passwordResetEnforcement = isPasswordResetRequired ? (
      <div className="validation-invalid-label">
        <div className="mt-2">
          Your password has expired. Please reset it by{" "}
          <Link to="/password-reminder">following this link</Link>.
        </div>
      </div>
    ) : null;

    return (
      <UnauthorizedPageTemplate>
        <SeoBlock title={"Login"} />
        <form className="login-form">
          <div className="card mb-0">
            <div className="card-body">
              <div className="text-center mb-3">
                <i className="icon-reading icon-2x text-slate-300 border-slate-300 border-3 rounded-round p-3 mb-3 mt-1" />
                <h5 className="mb-0">Login to your account</h5>
                <span className="d-block text-muted">
                  Enter your credentials below
                </span>
              </div>

              {loadingIndicator}

              <div className="form-group form-group-feedback form-group-feedback-left">
                <input
                  type="text"
                  className="form-control js-user-login-email"
                  placeholder="Email"
                />
                <div className="form-control-feedback">
                  <i className="icon-user text-muted" />
                </div>
              </div>

              <div className="form-group form-group-feedback form-group-feedback-left">
                <input
                  type="password"
                  className="form-control js-user-login-password"
                  placeholder="Password"
                />
                <div className="form-control-feedback">
                  <i className="icon-lock2 text-muted" />
                </div>

                <ApiError error={loginError} />
                {passwordResetEnforcement}
              </div>

              <div className="form-group">
                <button
                  type="submit"
                  className="btn btn-primary btn-block"
                  onClick={this.onLoginPress}
                >
                  Sign in <i className="icon-circle-right2 ml-2" />
                </button>
              </div>

              <div className="text-center">
                <Link to="/password-reminder">Forgot password?</Link>
              </div>

              <div className="text-center mt-2">
                <Link to="/register">Request account</Link>
              </div>
            </div>
          </div>
        </form>
      </UnauthorizedPageTemplate>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  login: async (email, password) =>
    await dispatch(userActions.login(email, password)),
  fetchTasksTypes: async () =>
    await dispatch(tasksTypesActions.fetchTasksTypes()),
  fetchStatuses: () => dispatch(statusesActions.fetchStatuses()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
