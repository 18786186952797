import React, { memo } from "react";
import PropTypes from "prop-types";

const SuccessMessage = (props) => {
  const isSuccess = props.isSuccess;
  const heading = props.heading;
  const message = props.message;

  return isSuccess ? (
    <div className="validation-valid-label">
      <strong>{heading}</strong>
      <div>{message}</div>
    </div>
  ) : null;
};

SuccessMessage.defaultProps = {
  heading: "Success",
  message: "Action was successful",
};

SuccessMessage.propTypes = {
  isSuccess: PropTypes.bool,
  heading: PropTypes.string,
  message: PropTypes.string,
};

export default memo(SuccessMessage);
