import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import DateUSSlash from "../../../components/DateUSSlash";
import MoneyUSFormat from "../../../components/MoneyUSFormat";
import InvoicesIssuedList from "../../InvoicesPage/components/InvoicesIssuedList";
import { useProjectStore } from "../../../storeHooks/project";
import useEditStatus from "./useEditStatus";
import StatusEditable from "../../../components/StatusEditable";

const GroupedProjectsRow = (props) => {
  const { data, user = {}, statuses } = props;

  const { setEditProject } = useProjectStore();
  const { project, editProject } = useProjectStore();
  const { loading, onChangeStatus } = useEditStatus(
    data.id,
    project,
    editProject
  );

  /**
   *
   */
  const onBeforeGoWithRowLink = useCallback(() => {
    setEditProject(null);
  }, []);

  const projectUrl = data && data.id ? `/project/${data.id}` : "";
  const bidUrl = () =>
    data && data.recent_bid && data.recent_bid.id
      ? `/bid/${data.recent_bid.id}`
      : "";

  const invoiceUrl = data && data.id ? `/invoice/${data.id}` : "";

  const goToProjectUrl = useMemo(
    () => (["accountant"].includes(user.role) ? bidUrl : projectUrl),
    [user.role, projectUrl, bidUrl]
  );

  const goToInvoiceUrl = ["accountant"].includes(user.role)
    ? invoiceUrl
    : projectUrl;

  const quoteTotal = useMemo(
    () => data.bids.map((i) => i.total).reduce((sum, x) => sum + x),
    [data.bids]
  );

  const depositTotal = useMemo(
    () => data.bids.map((i) => i.deposit).reduce((sum, x) => sum + x),
    [data.bids]
  );

  const name = data.name;
  const quote = <MoneyUSFormat amount={quoteTotal} />;
  const deposit = <MoneyUSFormat amount={depositTotal} />;

  return (
    <tr>
      <td className="text-center">
        <div className="font-size-sm line-height-1">
          <DateUSSlash date={data.due_date} />
        </div>
      </td>
      <td>
        <span className="text-default">
          {goToInvoiceUrl ? (
            <Link onClick={onBeforeGoWithRowLink} to={goToInvoiceUrl}>
              {name}
            </Link>
          ) : (
            name
          )}
        </span>
      </td>
      <td>
        <StatusEditable
          status={data.status}
          statuses={statuses}
          onChange={onChangeStatus}
          loading={loading}
          isFixedPosition={false}
        />
      </td>
      <td>
        <span className="text-default">
          {goToProjectUrl ? (
            <Link onClick={onBeforeGoWithRowLink} to={goToProjectUrl}>
              {quote}
            </Link>
          ) : (
            quote
          )}
        </span>
      </td>
      <td>
        <span className="text-default">
          {goToProjectUrl ? (
            <Link onClick={onBeforeGoWithRowLink} to={goToProjectUrl}>
              {deposit}
            </Link>
          ) : (
            deposit
          )}
        </span>
      </td>
      <td>
        <span className="text-default">
          <Link to={invoiceUrl}>
            <InvoicesIssuedList invoice={data} filler="--" />
          </Link>
        </span>
      </td>
    </tr>
  );
};

GroupedProjectsRow.propTypes = {
  data: PropTypes.object.isRequired,
  user: PropTypes.object,
  statuses: PropTypes.array.isRequired,
};

export default GroupedProjectsRow;
