import PropTypes from "prop-types";
import { formatter } from "../../../helpers/tools";

const InvoiceTotalFormat = (props) => {
  const { invoice } = props;

  if (!invoice) {
    return "--";
  }

  const shotsQuoteSubtotal = invoice.shots_quote_subtotal_for_issued_shots
    ? invoice.shots_quote_subtotal_for_issued_shots
    : 0;
  const additionalCostsSubtotal = invoice.additional_costs_subtotal_for_issued_shots
    ? invoice.additional_costs_subtotal_for_issued_shots
    : 0;
  const discount = invoice.discount_for_issued_shots
    ? invoice.discount_for_issued_shots
    : 0;
  const rush = invoice.rush_for_issued_shots
    ? invoice.rush_for_issued_shots
    : 0;

  return formatter.format(
    shotsQuoteSubtotal + additionalCostsSubtotal - discount + rush
  );
};

InvoiceTotalFormat.propTypes = {
  invoice: PropTypes.object,
};

export default InvoiceTotalFormat;
