/**
 * Created by piotr.pozniak@thebeaverhead.com on 13/12/2021.
 */
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as bidVersionActions from "../actions/bidVersion";

export function useBidVersionStore() {
  const dispatch = useDispatch();
  const _bidVersion = useSelector((store) => store.bidVersion);

  const fetchBidVersion = useCallback(
    async (id) => await dispatch(bidVersionActions.fetchBidVersion(id)),
    [dispatch]
  );

  const fetchBidFullInfoByBidSlug = useCallback(
    async (slug) =>
      await dispatch(bidVersionActions.fetchBidFullInfoByBidSlug(slug)),
    [dispatch]
  );

  const bidVersionUpdate = useCallback(
    async (data, id) =>
      await dispatch(bidVersionActions.bidVersionUpdate(data, id)),
    [dispatch]
  );

  const bidVersionApprove = useCallback(
    async (id) => await dispatch(bidVersionActions.bidVersionApprove(id)),
    [dispatch]
  );

  const fetchShotsByBidVersion = useCallback(
    async (bidVersionId, page, limit) =>
      await dispatch(
        bidVersionActions.fetchShotsByBidVersion(bidVersionId, page, limit)
      ),
    [dispatch]
  );

  const setBidVersion = useCallback(
    async (bid) => await dispatch(bidVersionActions.setBidVersion(bid)),
    [dispatch]
  );

  return {
    bidVersion: _bidVersion,
    fetchBidVersion,
    fetchBidFullInfoByBidSlug,
    bidVersionUpdate,
    bidVersionApprove,
    fetchShotsByBidVersion,
    setBidVersion,
  };
}
