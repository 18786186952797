import React, { Component } from "react";
import * as appActions from "../actions/app";
import * as userActions from "../actions/user";
import connect from "react-redux/es/connect/connect";
import Footer from "../components/Footer";

class UnauthorizedPageTemplate extends Component {
  constructor(props) {
    super(props);

    this.state = {};

  }

  /**
   *
   * @returns {XML}
   */
  render() {
    const header = (
      <div className="navbar navbar-expand-md navbar-dark">
        <div className="navbar-brand">
          <a href="/" className="navbar-brand-text d-inline-block d-logo">
            BLiP
          </a>
        </div>
      </div>
    );

    const content = this.props.children;

    return (
      <div className="page-wrapper">
        {header}

        {/*<!-- Page content -->*/}
        <div className="page-content">
          {/*<!-- Main content -->*/}
          <div className="content-wrapper">
            {/*<!-- Content area -->*/}
            <div className="content d-flex justify-content-center align-items-center">
              {content}
            </div>
            {/*<!-- /content area -->*/}

            <Footer />
          </div>
          {/*<!-- /main content -->*/}
        </div>
        {/*<!-- /page content -->*/}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  clearErrors: () => dispatch(appActions.clearErrors()),
  checkSession: async isLogged =>
    await dispatch(userActions.checkSession(isLogged))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnauthorizedPageTemplate);
