import PropTypes from "prop-types";
import { formatter } from "../../../helpers/tools";

const ProjectInvoiceTotalFormat = (props) => {
  const { invoice } = props;

  if (!invoice) {
    return "--";
  }
  if (invoice.type === "deposit_invoice" && !invoice) {
    return "--";
  }

  /**
   *
   * @returns {string}
   */
  const countTotal = () => {
    const shotsQuoteSubtotal = invoice.shots_quote_subtotal_from_latest_versions
      ? invoice.shots_quote_subtotal_from_latest_versions
      : 0;
    const additionalCostsSubtotal = invoice.additional_costs_subtotal_from_recent_bid_version
      ? invoice.additional_costs_subtotal_from_recent_bid_version
      : 0;
    const discount = invoice.discount_from_recent_bid_version
      ? invoice.discount_from_recent_bid_version
      : 0;
    const rush = invoice.rush_from_recent_bid_version
      ? invoice.rush_from_recent_bid_version
      : 0;
    const depositPaid = invoice.deposit_paid ? invoice.deposit_paid : 0;

    return formatter.format(
      shotsQuoteSubtotal +
        additionalCostsSubtotal -
        discount +
        rush -
        depositPaid
    );
  };

  /**
   *
   * @returns {string}
   */
  const countDeposit = () => {
    return invoice && invoice.deposit
      ? formatter.format(invoice.deposit)
      : "--";
  };

  return invoice.type !== "deposit_invoice" ? countTotal() : countDeposit();
};

ProjectInvoiceTotalFormat.propTypes = {
  invoice: PropTypes.object,
};

export default ProjectInvoiceTotalFormat;
