import React, { Component } from "react";
import PropTypes from "prop-types";
import DataTable from "react-data-table-component";
import SearchButton from "./SearchButton";
import Select from "./Select";
import LoadingIndicator from "./LoadingIndicator";
import classNames from "classnames";
import CustomerSelect from "./Selects/CustomerSelect";
import UserSelect from "./Selects/UserSelect";
import connect from "react-redux/es/connect/connect";
import Filters from "./DataTable/Filters";
import BulkActions from "./DataTable/BulkActions";

const RowHighlightStyle = {
  backgroundColor: "rgb(0 0 0 / 13%)",
  "&:hover": {
    backgroundColor: "rgb(0 0 0 / 15%)",
  },
};

class CustomizableDataTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRowsData: {},
      isFiltersVisible: false,
      toggledClearRows: false,

      showFixedBulkUpdates: false,
    };
  }

  /**
   *
   */
  componentDidMount() {
    setTimeout(this.highlightRows, 200);
  }

  /**
   *
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.bulkActionsLoading && !this.props.bulkActionsLoading) {
      this.setState({
        toggledClearRows: !this.state.toggledClearRows,
        showFixedBulkUpdates: false,
      });
    }

    if (prevProps.collection.length !== this.props.collection.length) {
      this.highlightRows();
    }

    if (
      prevState.isFiltersVisible !== this.state.isFiltersVisible &&
      this.state.isFiltersVisible
    ) {
      window.updateJQuery();
    }

    if (
      prevProps.collapseFilters !== this.props.collapseFilters &&
      !this.props.collapseFilters
    ) {
      window.updateJQuery();
    }
  }

  /**
   *
   * @param selectedRowsData
   */
  onCheckRow = (selectedRowsData) => {
    if (
      JSON.stringify(this.state.selectedRowsData) !==
      JSON.stringify(selectedRowsData)
    ) {
      this.setState({ selectedRowsData }, window.updateJQuery);
      if (typeof this.props.onCheckRow === "function") {
        this.props.onCheckRow(selectedRowsData);
      }
    }
  };

  /**
   *
   * @param event
   */
  toggleFilters = (event) => {
    event.preventDefault();

    if (
      this.props.collapseFilters !== undefined &&
      typeof this.props.onToggleFilters === "function"
    ) {
      const collapse = !this.props.collapseFilters;
      this.props.onToggleFilters(collapse);
    } else {
      this.setState({ isFiltersVisible: !this.state.isFiltersVisible });
    }
  };

  /**
   *
   * @param e
   */
  toggleFixedBulkActions = (e) => {
    e.preventDefault();

    this.setState({ showFixedBulkUpdates: !this.state.showFixedBulkUpdates });
  };

  /**
   *
   * @param search
   */
  onSearch = (search) => {
    alert(search);
  };

  /**
   *
   */
  highlightRows = () => {
    if (this.props.id) {
      const rows = document.querySelectorAll(
        "#" + this.props.id + " .rdt_TableRow"
      );
      if (rows) {
        rows.forEach((item) => {
          const cellWithClassNames = item.querySelector(
            ".js-customizable-table-to-highlight"
          );
          const classNames = cellWithClassNames.className;
          const closestRow = cellWithClassNames.closest(".rdt_TableRow");
          closestRow.className = closestRow.className + " " + classNames;
        });
      }
    }
  };

  /**
   *
   * @param row
   * @returns {function(*): void}
   */
  onCheckboxReplacerChange = (row) => (event) => {
    const originalDataTableCheckbox = event.target
      .closest(".rdt_TableRow")
      .querySelector(".rdt_TableCell:first-child input[type='checkbox']");
    if (originalDataTableCheckbox) {
      originalDataTableCheckbox.click();
    }
    this.handleShiftSelection(row, event.target, event.nativeEvent.shiftKey);
  };

  /**
   *
   * @param row
   * @param target
   * @param isShiftPressed
   */
  handleShiftSelection = (row, target, isShiftPressed) => {
    if (target.checked) {
      if (isShiftPressed && this.state.lastCheckedRow) {
        let startRowId = this.state.lastCheckedRow.id;
        let finishRowId = row.id;
        const tableSelector = this.props.id
          ? `#${this.props.id}`
          : ".customizable-data-table";
        const tableDOMRows = Array.from(
          target.closest(tableSelector).querySelectorAll(".rdt_TableRow")
        );
        const rowsIds = tableDOMRows.map((item) => item.id);

        if (
          rowsIds.indexOf(`row-${startRowId}`) >
          rowsIds.indexOf(`row-${finishRowId}`)
        ) {
          [startRowId, finishRowId] = [finishRowId, startRowId];
        }

        const rowsToSelect = tableDOMRows.filter(
          (item, index) =>
            index > rowsIds.indexOf(`row-${startRowId}`) &&
            index < rowsIds.indexOf(`row-${finishRowId}`)
        );

        rowsToSelect.forEach((item) => {
          const checkbox = item.querySelector(
            ".rdt_TableCell:first-child input[type='checkbox']"
          );
          if (!checkbox.checked) {
            checkbox.click();
          }
        });
      }
      this.setState({ lastCheckedRow: { ...row } });
    } else {
      this.setState({ lastCheckedRow: null });
    }
  };

  /**
   *
   * @returns {{format: (function(*=): *), width: string, selector: string}}
   */
  getCheckboxReplaceData = () => ({
    selector: "event_checkboxes",
    format: (row) => (
      <input
        className="shift-selection-checkbox mt-2"
        type="checkbox"
        onChange={this.onCheckboxReplacerChange(row)}
        checked={
          !!(
            this.state.selectedRowsData.selectedRows &&
            this.state.selectedRowsData.selectedRows.find(
              (item) => item.id === row.id
            )
          )
        }
      />
    ),
    width: "50px",
  });

  /**
   *
   * @returns {XML}
   */
  render() {
    const collection = this.props.collection || [];
    const mockIfEmpty = [{}];

    const shiftSelection = this.props.shiftSelection && this.props.bulkActions;
    const title = this.props.title ? <h5>{this.props.title}</h5> : null;

    const isAnyRowSelected = !!(
      this.state.selectedRowsData.selectedRows &&
      this.state.selectedRowsData.selectedRows.length
    );

    const showBulkActionsControls =
      collection.length &&
      this.props.bulkActions &&
      this.props.bulkActions.length &&
      (this.props.bulkActionsLoading ||
        (this.props.isBulkUpdatesFixed && this.state.showFixedBulkUpdates) ||
        (!this.props.isBulkUpdatesFixed && isAnyRowSelected));

    const bulkActionsControls = (this.props.bulkActions || []).length ? (
      <BulkActions
        wrapperClassName={this.props.bulkActionsClassName}
        showControls={showBulkActionsControls}
        isAnyRowSelected={isAnyRowSelected}
        selectedRowsData={this.state.selectedRowsData}
        bulkActions={this.props.bulkActions}
        isLoading={this.props.bulkActionsLoading}
        selectorHtmlClass={this.props.bulkActionSelectorHtmlClass}
      />
    ) : null;

    const search = this.props.showSearch ? (
      <div className="d-inline-block vtop">
        <SearchButton onSearch={this.onSearch} id={this.props.searchId} />
      </div>
    ) : null;

    const collapse =
      this.props.collapseFilters === undefined
        ? !this.state.isFiltersVisible
        : this.props.collapseFilters;
    const isFiltersApplied = this.props.isFiltersApplied;

    const filterButtonClassName = classNames(
      "btn",
      "vtop",
      "mr-2",
      { "btn-link": collapse },
      { "btn-outline-primary": !collapse }
    );

    const filtersActiveIndicator =
      collapse && isFiltersApplied ? (
        <span className="badge badge-pill bg-danger-400 ml-auto ml-md-0">
          !
        </span>
      ) : null;

    const filterButton = this.props.showFilters ? (
      <React.Fragment>
        <button
          type="button"
          className={filterButtonClassName}
          onClick={this.toggleFilters}
        >
          <i className="mi-filter-list mr-1" />
          Filter
        </button>
        {filtersActiveIndicator}
      </React.Fragment>
    ) : null;

    const filters =
      !showBulkActionsControls && this.props.showFilters && !collapse ? (
        this.props.filters ? (
          <Filters
            filters={this.props.filters}
            wrapperClassName={this.props.filtersClassName}
            filterSelectorHtmlClass={this.props.filterSelectorHtmlClass}
            onFilter={this.props.onFilter}
            onClearAll={this.props.onClearAll}
          />
        ) : (
          "Please add prop.filters"
        )
      ) : null;

    const settingsButton = this.props.showSettings ? (
      <button type="button" className="btn btn-link mr-2 vtop">
        <i className="mi-settings mr-2" /> Settings
      </button>
    ) : null;

    const bulkUpdateButtonClassName = classNames(
      "btn",
      "vtop",
      "mr-2",
      { "btn-link": !this.state.showFixedBulkUpdates },
      { "btn-outline-primary": this.state.showFixedBulkUpdates }
    );

    const bulkUpdateButton =
      this.props.isBulkUpdatesFixed && this.props.bulkActions ? (
        <div className="control-line mb-3 float-right">
          <button
            type="button"
            className={bulkUpdateButtonClassName}
            onClick={this.toggleFixedBulkActions}
          >
            <i className="mi-refresh mr-1" />
            Bulk Update
          </button>
        </div>
      ) : null;

    const showControlLine =
      (!showBulkActionsControls ||
        (showBulkActionsControls && this.props.isBulkUpdatesFixed)) &&
      (this.props.showSearch ||
        this.props.showFilters ||
        this.props.showSettings);

    const controlLine = showControlLine ? (
      <div className="control-line mb-3">
        {this.props.showSearch && !showBulkActionsControls ? search : null}
        {this.props.showFilters && !showBulkActionsControls
          ? filterButton
          : null}
        {this.props.showSettings && !showBulkActionsControls
          ? settingsButton
          : null}
        {bulkUpdateButton}
      </div>
    ) : null;

    let columns = this.props.columns;
    if (shiftSelection) {
      columns = [this.getCheckboxReplaceData(), ...columns];
    }

    const conditionalRowStyles = [
      {
        when: (row) => {
          return (
            this.state.selectedRowsData.selectedCount &&
            this.state.selectedRowsData.selectedCount > 0 &&
            this.state.selectedRowsData.selectedRows.findIndex(
              (i) => row.id === i.id
            ) >= 0
          );
        },
        style: RowHighlightStyle,
      },
    ];

    return (
      <div className="customizable-data-table" id={this.props.id || null}>
        {title}
        {controlLine}
        {filters}
        {showBulkActionsControls ? bulkActionsControls : null}
        <div
          className={classNames(
            "data-table-themed data-table-themed_not-stretch data-table-themed_short",
            { "data-table-themed_hide-select-all": !this.props.canSelectAll },
            { "data-table-themed_shift-selection": shiftSelection }
          )}
        >
          {this.props.headerComponent && (
            <div className="card-header">{this.props.headerComponent}</div>
          )}
          <DataTable
            key={this.props.defaultSortField || this.props.id}
            noHeader
            responsive={false}
            columns={columns}
            data={collection && collection.length ? collection : mockIfEmpty}
            selectableRows={
              this.props.bulkActions.length || this.props.canSelectAll
                ? true
                : false
            }
            onSelectedRowsChange={this.onCheckRow}
            defaultSortField={this.props.defaultSortField}
            sortFunction={this.props.sortFunction}
            expandableRows={!!collection.length && this.props.expandableRows}
            expandOnRowClicked={this.props.expandOnRowClicked}
            expandableRowsComponent={this.props.expandableRowsComponent}
            className={collection.length ? null : "data-table-empty"}
            clearSelectedRows={this.state.toggledClearRows}
            sortServer={this.props.sortServer}
            onSort={this.props.onSort}
            defaultSortAsc={this.props.defaultSortAsc}
            conditionalRowStyles={conditionalRowStyles}
          />
          {this.props.footerComponent}
        </div>
      </div>
    );
  }
}

CustomizableDataTable.defaultProps = {
  filterSelectorHtmlClass: "js-filter-form",
  searchId: "cdt",
  sortServer: false,
  bulkActions: [],
};

CustomizableDataTable.propTypes = {
  collection: PropTypes.array,
  canSelectAll: PropTypes.bool,
  columns: PropTypes.array.isRequired,
  bulkActions: PropTypes.array,
  bulkActionSelectorHtmlClass: PropTypes.string,
  bulkActionsLoading: PropTypes.bool,
  onCheckRow: PropTypes.func,
  title: PropTypes.string,
  showSearch: PropTypes.bool,
  searchId: PropTypes.string,
  showFilters: PropTypes.bool,
  showSettings: PropTypes.bool,
  filters: PropTypes.array,
  filterSelectorHtmlClass: PropTypes.string,
  onFilter: PropTypes.func,
  onClearAll: PropTypes.func,
  defaultSortField: PropTypes.string,
  sortFunction: PropTypes.func,
  expandableRows: PropTypes.bool,
  expandOnRowClicked: PropTypes.bool,
  expandableRowsComponent: PropTypes.element,
  footerComponent: PropTypes.element,
  id: PropTypes.string,
  headerComponent: PropTypes.element,
  sortServer: PropTypes.bool,
  onSort: PropTypes.func,
  collapseFilters: PropTypes.bool,
  onToggleFilters: PropTypes.func,
  isFiltersApplied: PropTypes.bool,
  // shiftSelection hides original row select checkboxes and canSelectAll checkboxes by default. This behaviour can be
  // changed via CSS by wrapping the CustomizableDataTable to a .class in a place where this component
  // is used (outside from this) for each particular table due to each of them can have it's unique structure.
  shiftSelection: PropTypes.bool,
  user: PropTypes.object,
  isBulkUpdatesFixed: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(CustomizableDataTable);
