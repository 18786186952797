import PropTypes from "prop-types";
import { formatter } from "../helpers/tools";

const MoneyUSFormat = (props) => {
  const { amount, filler } = props;

  return amount ? formatter.format(amount) : filler || "--";
};

MoneyUSFormat.propTypes = {
  amount: PropTypes.number,
  filler: PropTypes.string,
};

export default MoneyUSFormat;
