/**
 * Created by piotr.pozniak@thebeaverhead.com on 17/04/2023
 */

import React, { useCallback } from "react";
import PropTypes from "prop-types";

const DescriptionFormat = (props) => {
  const { shot, isExpanded } = props;

  const onClick = useCallback(
    (event) => {
      if (props.onClick) {
        props.onClick(event, shot.id);
      }
    },
    [isExpanded, props.onClick]
  );

  const description = shot.current_version
    ? shot.current_version.description
    : "";

  return (
    <span className={"d-inline-block cursor-pointer w-100"} onClick={onClick}>
      {description}
    </span>
  );
};

DescriptionFormat.defaultProps = {};

DescriptionFormat.propTypes = {};

export default DescriptionFormat;
