/**
 * Created by piotr.pozniak@thebeaverhead.com on 17/04/2023
 */

import React, { useCallback } from "react";
import PropTypes from "prop-types";

const HoursFormat = (props) => {
  const { shot, isExpanded } = props;

  const onClick = useCallback(
    (event) => {
      console.log("HoursFormat.onClick", shot.id);
      if (props.onClick) {
        props.onClick(event, shot.id);
      }
    },
    [isExpanded, props.onClick]
  );

  const hours =
    Math.round(
      ((shot.current_version && shot.current_version.hours) || 0) * 100
    ) / 100;

  return (
    <div className={"d-inline-block cursor-pointer w-100"} onClick={onClick}>
      {hours}
    </div>
  );
};

HoursFormat.defaultProps = {};

HoursFormat.propTypes = {};

export default HoursFormat;
