import AxiosConfig from "../AxiosConfig";
import axios from "axios";

/**
 *
 * @param fromDate
 * @param toDate
 * @param status
 * @param clients
 * @param type
 * @returns {function(*): Promise<T>}
 */
export const fetchInvoicesReports = (
  fromDate,
  toDate,
  status = [],
  clients = [],
  type
) => {
  return (dispatch) => {
    dispatch({
      type: "INVOICES_REPORTS_FETCH",
      data: {
        fromDate,
        toDate,
        status,
        clients: clients.filter((x) => typeof x !== "string"),
        type,
      },
    });

    const query = {};
    if (fromDate) {
      query.start_date = fromDate;
    }
    if (toDate) {
      query.end_date = toDate;
    }
    if (status && status.length) {
      query.invoice_statuses = status.map((i) => i.value);
    }
    if (clients && clients.length) {
      query.clients = clients.map((i) => {
        if (typeof i === "string") {
          return i;
        }
        return i.value;
      });
    }

    const url =
      AxiosConfig.getEndpointAddress() +
      "/api/statistics/bids.json?" +
      AxiosConfig.objectToURLQuery(query);

    return axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "INVOICES_REPORTS_FETCH_FULFILLED",
          data: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "INVOICES_REPORTS_FETCH_REJECTED",
          data: error,
        });
      });
  };
};
