import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import LastShotUpdateVersion, {
  getShortVersionFromShotModel,
} from "./LastShotUpdateVersion";
import DateUSSlash from "../../../components/DateUSSlash";
import StatusEditable from "../../../components/StatusEditable";
import { useShotStore } from "../../../storeHooks/shot";
import useEditStatus from "./useEditStatus";
import HoveredEdit from "../../../components/HoveredEdit";
import classNames from "classnames";
import { store } from "../../../configureStore";
import withRTC from "../../../hocs/withRTC";

const GroupedShotsRow = (props) => {
  const { data, statuses } = props;

  const { shot, editShot, createShotUpdate } = useShotStore();
  const { loading, onChangeStatus } = useEditStatus(data.id, shot, editShot);

  const [isVersionEdit, setIsVersionEdit] = useState(false);
  const shotVersionRef = useRef();

  useEffect(() => {
    if (
      !loading &&
      shot.editSuccess &&
      shot.model &&
      shot.model.id === data.id
    ) {
      props.onChangeEntity("shotUpdate", {
        fieldName: "status",
        shotId: data.id,
        projectId: data.project_id,
        value: shot.model.status,
      });
    }
  }, []);
  /**
   *
   * @type {(function(): void)|*}
   */
  const onVersionChange = useCallback(async () => {
    const value = shotVersionRef.current.value;
    setIsVersionEdit(true);

    await createShotUpdate(data.id, {
      version: value,
    });
    setIsVersionEdit(false);

    if (store.getState().shot.shotUpdateCreateSuccess) {
      props.onChangeEntity("shotUpdateUpdate", {
        shotId: data.id,
        projectId: data.project_id,
        value: {
          id: `${data.id}_${+new Date()}`,
          version: value,
          shot_id: data.id,
          description: null,
        },
      });
    }
  }, [isVersionEdit, shotVersionRef.current]);

  /**
   *
   * @type {(function(): void)|*}
   */
  const onVersionFocus = useCallback(() => {
    shotVersionRef.current.focus();
  }, [shotVersionRef.current]);

  const shotEdit = isVersionEdit ? (
    <i className="icon-spinner2 spinner fs-086rem" />
  ) : (
    <HoveredEdit
      className={classNames(
        "hovered-edit_for-dark-theme",
        "overflow-visible",
        //"hovered-edit_left-on-padding-size",
        "hovered-edit_white-space-normal-in-table"
      )}
      preview={<LastShotUpdateVersion shot={data} />}
      previewTitle={"Last Shot Update Version"}
      handleConfirm={onVersionChange}
      handleFocus={onVersionFocus}
      cutPreviewLength="50px"
      disabled={shot.shotUpdateCreate}
    >
      <input
        ref={shotVersionRef}
        defaultValue={getShortVersionFromShotModel(data)}
        type="text"
        maxLength={16}
        className="input-pure maxw-90px"
        placeholder="None"
      />
    </HoveredEdit>
  );

  return (
    <tr>
      <td className="text-center">
        <div className="font-size-sm line-height-1">
          <DateUSSlash date={data.project.due_date} />
        </div>
      </td>
      <td>
        <span className="text-default">
          <Link to={`/project/${data.project.id}`}>{data.project.name}</Link>
        </span>
      </td>
      <td>
        <span className="text-default">
          <Link to={`/project/${data.project.id}/shot/${data.id}`}>
            {data.shot_number}
          </Link>
        </span>
      </td>
      <td>{shotEdit}</td>
      <td>
        <span className="text-default">
          {/*<StatusBadge status={data.status} statuses={statuses} />*/}
          <StatusEditable
            status={data.status}
            statuses={statuses}
            onChange={onChangeStatus}
            loading={loading}
            isFixedPosition={false}
          />
        </span>
      </td>
    </tr>
  );
};

GroupedShotsRow.propTypes = {
  data: PropTypes.object.isRequired,
  statuses: PropTypes.array.isRequired,
};

export default withRTC(GroupedShotsRow);
