export const initialState = {
  connecting: false,
  connectingSuccess: false,
  connectingError: null,

  isConnected: false,

  fetchPrevious: false,
  fetchPreviousSuccess: false,
  fetchPreviousError: null,

  collection: [],
};

export default function reducer(
  state = {
    ...initialState
  },
  action
) {
  switch (action.type) {

    case "ACTIVITIES_SOCKET_CONNECTION":
      return {
        ...state,
        connecting: true,
        connectingSuccess: false,
        connectingError: null,
      };

    case "ACTIVITIES_SOCKET_CONNECTION_FULFILLED":
      return {
        ...state,
        connecting: false,
        connectingSuccess: true,
        isConnected: true,
      };

    case "ACTIVITIES_SOCKET_CONNECTION_REJECTED":
      return {
        ...state,
        connecting: false,
        connectingSuccess: false,
        connectingError: action.data,
      };

    case "ACTIVITIES_INITIAL_FETCH_FULFILLED":
      return {
        ...state,
        collection: [
          ...action.data.activities,
        ],
      };

    case "ACTIVITY_FETCH_FULFILLED":
      return {
        ...state,
        collection: [
          action.data,
          ...state.collection
        ],
      };

    case "ACTIVITIES_SOCKET_DISCONNECTION":
      return {
        ...state,
        connecting: false,
      };

    case "ACTIVITIES_SOCKET_DISCONNECTION_FULFILLED":
      return {
        ...state,
        connectingSuccess: false,
        isConnected: false,
      };

    case "ACTIVITIES_FETCH_PREVIOUS":
      return {
        ...state,
        fetchPrevious: true,
        fetchPreviousSuccess: false,
        fetchPreviousError: null,
      };

    case "ACTIVITIES_FETCH_PREVIOUS_FULFILLED":
      return {
        ...state,
        fetchPrevious: false,
        fetchPreviousSuccess: true,
        collection: [
          ...state.collection,
          ...action.data,
        ],
      };

    case "ACTIVITIES_FETCH_PREVIOUS_REJECTED":
      return {
        ...state,
        fetchPrevious: false,
        fetchPreviousSuccess: false,
        fetchPreviousError: action.data,
      };

    case "CLEAR_ERRORS":
      return {
        ...initialState,
        isConnected: state.isConnected,
        collection: state.collection,
      };

    case "LOGOUT":
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
