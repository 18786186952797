/**
 * Created by piotr.pozniak@thebeaverhead.com on 17/11/2021.
 */
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as userActions from "../actions/user";
import {checkSession, editProfile, passwordReminder, passwordReset} from "../actions/user";

export function useUserStore() {

  const dispatch = useDispatch();
  const _user = useSelector((store) => store.user);

  const login = useCallback(
    async (email, password) =>
      await dispatch(userActions.login(email, password)),
    [dispatch]
  );

  const logout = useCallback(async () => await dispatch(userActions.logout()), [
    dispatch,
  ]);

  const createProfile = useCallback(
    async (name, email, password) =>
      await dispatch(userActions.createProfile(name, email, password)),
    [dispatch]
  );

  const passwordReminder = useCallback(
    async (email) => await dispatch(userActions.passwordReminder(email)),
    [dispatch]
  );

  const passwordReset = useCallback(
    async (email, reset_password_token, password) =>
      await dispatch(userActions.passwordReset(email, reset_password_token, password)),
    [dispatch]
  );

  const editProfile = useCallback(
    async (id, data) =>
      await dispatch(userActions.editProfile(id, data)),
    [dispatch]
  );

  const checkSession = useCallback(
    async () => await dispatch(userActions.checkSession()),
    [dispatch]
  );


  return {
    user: _user,
    login,
    logout,
    createProfile,
    passwordReminder,
    passwordReset,
    editProfile,
    checkSession,
  };
}
