/**
 * Created by piotr.pozniak@thebeaverhead.com on 20/04/2023
 */

import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { generatePDFFilename } from "../../../helpers/bid";
import { useBidStore } from "../../../storeHooks/bid";
import { useBidVersionStore } from "../../../storeHooks/bidVersion";

const BidPreviewPDF = ({ isLoading }) => {
  const { bid, bidInvoicePDF, previewBidInvoice } = useBidStore();
  const { bidVersion } = useBidVersionStore();
  /**
   *
   * @returns {Promise<void>}
   */
  const onBidDownload = useCallback(async () => {
    const filename = generatePDFFilename(bid.model, bidVersion.model);
    await bidInvoicePDF(bidVersion.model.id, filename);
  }, [bidVersion.model, bid.model]);

  /**
   *
   */
  const onBidPreview = () => {
    previewBidInvoice(bidVersion.model.id, bid.model, bid.model.project);
  };

  return (
    <div className="col-lg-5 d-flex">
      <button
        type="button"
        className="btn btn-link ml-n1 ml-lg-0 mr-2"
        onClick={onBidPreview}
        disabled={isLoading}
      >
        <i className="fas fa-file-invoice-dollar mr-1 fs-1-4rem" /> Preview bid
      </button>
      <button
        type="button"
        className="btn btn-link ml-n1 ml-lg-0 mr-2"
        onClick={onBidDownload}
        disabled={isLoading}
      >
        <i className="mi-picture-as-pdf mr-1 fs-1-4rem" /> Download PDF
      </button>
    </div>
  );
};

BidPreviewPDF.defaultProps = {};

BidPreviewPDF.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export default BidPreviewPDF;
