import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Checkbox = (props) => {
  const {
    className,
    inputClassName,
    disabled,
    defaultChecked,
    checked,
    onChange,
  } = props;

  /**
   *
   * @param event
   */
  const onCheckboxChange = (event) => {
    if (onChange) {
      onChange(event);
    }
  };

  const checkboxClassName = classNames("form-check", className);
  const inputCN = classNames("form-check-input-styled", inputClassName);

  return (
    <div className={checkboxClassName}>
      <label className="form-check-label">
        <input
          type="checkbox"
          className={inputCN}
          data-fouc
          onChange={onCheckboxChange}
          checked={checked}
          defaultChecked={defaultChecked}
          disabled={disabled}
        />
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  disabled: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Checkbox;
