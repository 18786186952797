import React, {Component} from 'react';

import ReportsPageTemplate from './ReportsPageTemplate';
import connect from "react-redux/es/connect/connect";
import * as artistsActions from "../actions/reportsArtists";
import CustomizableDataTable from '../components/CustomizableDataTable';
import moment from 'moment';
import StaticAlert from "../components/StaticAlert";
import {initialState} from "../reducers/filtersReducer";
import {Link} from "react-router-dom";
import {secondsToHm} from "../helpers/tools";

export const staticAlertHeader = "There is no data for selected filters";
export const staticAlertBody = (
  <>
    Change filters to pull the time logs.
  </>
);

class TimeLogsPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      diagramData: undefined,
      filter: {
        fromDate: this.props.filtersReducer.timeLogsPageFromDate,
        toDate: this.props.filtersReducer.timeLogsPageToDate,
        projects: this.props.filtersReducer.timeLogsPageProjects,
      }
    };
  }

  /**
   *
   * @returns {Promise<void>}
   */
  async componentDidMount() {
    if (this.props.user.token && this.props.user.model) {
      await this.fetchArtistsReports();
      this.setState({diagramData: this.getDiagramData()});
    }
  };

  /**
   *
   * @param filter
   */
  onFilter = async filter => {
    let fromDate;
    let toDate;

    filter.forEach(i => {
      if (i.fieldName === "fromDate" && i.value) {
        fromDate = moment(i.value, "YYYY-M-D");
      } else if (i.fieldName === "toDate" && i.value) {
        toDate = moment(i.value, "YYYY-M-D");
      }
    });

    this.setState({
      filter: {
        ...this.state.filter,
        fromDate: fromDate,
        toDate: toDate,
      },
      diagramData: undefined // clear the old chart
    }, async () => {
      await this.fetchArtistsReports();
      this.setState({diagramData: this.getDiagramData()});
    });
  };

  /**
   *
   * @returns {Promise<*|Promise<*>>}
   */
  fetchArtistsReports = async () => {
    return this.props.reportsArtistsFetch(
      this.state.filter.fromDate ? this.state.filter.fromDate.format('YYYY-MM-DD') : null,
      this.state.filter.toDate ? this.state.filter.toDate.format('YYYY-MM-DD') : null,
      [{value: this.props.user.model.id}],
      [],
      this.state.filter.projects ? this.state.filter.projects.map(i => i) : [],
    );
  };

  /**
   *
   */
  onClearAll = () => {
    this.setState({
      filter: {
        projects: [],
      }
    }, () => {
      this.onFilter([
        {fieldName: "fromDate", value: initialState.timeLogsPageFromDate.format('YYYY-M-D')},
        {fieldName: "toDate", value: initialState.timeLogsPageToDate.format('YYYY-M-D')},
      ]);
    });
  };

  /**
   *
   */
  getDiagramData = () => {

    const artists = this.props.reportsArtists.collection && this.props.reportsArtists.collection.artists
      ? this.props.reportsArtists.collection.artists
      : [];

    if (artists.length === 0) {
      return null;
    }

    // get all dates as keys
    const keys = [];
    artists.forEach(i => {
      i.timelogs.forEach(j => {
        if (!keys.includes(j.date)) {
          keys.push(j.date);
        }
      });
    });
    // sort the keys
    const sortedKeys = keys.sort((a, b) => {
      return moment(a, "YYYY-MM-DD").format("x")
        - moment(b, "YYYY-MM-DD").format('x');
    });
    // prepare columns
    const columns = [];
    const columnsNames = [];
    artists.forEach((i, index) => {
      columnsNames.push(i.name);
      columns[index] = [i.name];
      sortedKeys.forEach(key => {
        const currKeyTimelog = i.timelogs.filter(j => j.date === key);
        let currKeyTimelogCounter = 0;
        currKeyTimelog.forEach(t => {
          if (t && t.time_logged) {
            currKeyTimelogCounter += parseFloat(t.time_logged);
          }
        });
        columns[index].push(parseFloat(currKeyTimelogCounter / 60 / 60));
      });
    });


    return {
      //new diagram types can be added in public_html_dev/js/app.js, see App.initBarDiagram(settings ? settings.barDiagram : settings);
      barDiagram: [
        {
          bindto: document.getElementById('jsReportsPageDiagram'),
          size: {height: 300},
          data: {
            columns: columns,
            type: 'bar'
          },
          bar: {
            width: {
              ratio: 0.5
            }
          },
          grid: {
            y: {
              show: true
            }
          },
          axis: {
            x: {
              type: 'category',
              categories: sortedKeys
            },
          },
          tooltip: {
            format: {
              value: (value) => secondsToHm(value * 60 * 60),
            }
          }
        }
      ],
    };
  };

  // /**
  //  *
  //  * @param val
  //  */
  // artistsFilterOnUpdate = (val) => {
  //   const artists = val || [];
  //   this.setState({
  //     filter: {...this.state.filter, artists}
  //   });
  // };

  /**
   *
   * @param val
   */
  projectsFilterOnUpdate = val => {
    const projects = val || [];
    this.setState({
      filter: {...this.state.filter, projects}
    });
  };

  /**
   *
   * @returns {XML}
   */
  render() {
    const isLoading = this.props.reportsArtists.fetch;

    // prepare data table collection
    const artists = this.props.reportsArtists.collection && this.props.reportsArtists.collection.artists
      ? this.props.reportsArtists.collection.artists
      : [];
    const dataTableCollection = [];
    artists.forEach(i => {
      i.timelogs.forEach(j => {
        dataTableCollection.push({
          date: j.date,
          artist: i.name,
          project_name: j.project && j.project.name ? j.project.name : `--`,
          project_href: j.project && j.project.id ? `/project/${j.project.id}` : `#`,
          time_logged: secondsToHm(j.time_logged),
          notes: j.notes ? j.notes : `--`
        })
      });
    });
    dataTableCollection.sort((a, b) => {
      return moment(a.date, "YYYY-MM-DD").format("x")
        - moment(b.date, "YYYY-MM-DD").format("x");
    });

    const content = dataTableCollection.length > 0 && (
      <CustomizableDataTable
        collection={dataTableCollection}
        columns={[
          {
            name: 'Date',
            selector: 'date',
            sortable: true,
          },
          {
            name: 'Project name',
            selector: 'project_name',
            center: true,
            cell: row => row && row.project_href ?
              <Link to={row.project_href} className='relative'>{row.project_name}</Link> :
              <div>{row.project_name}</div>,
          },
          {
            name: 'Time logged',
            selector: 'time_logged',
            center: true,
          },
          {
            name: 'Notes',
            selector: 'notes',
            center: true,
          },
        ]}
      />
    );

    // empty table alert
    const staticAlert = !dataTableCollection || !dataTableCollection.length ? (
      <StaticAlert
        header={staticAlertHeader}
        body={staticAlertBody}
      />) : null;
    //

    return (
      <ReportsPageTemplate
        header={this.props.user.model ? `Time logs for ${this.props.user.model.name}` : 'Time logs'}
        filters={[
          {
            placeholderFrom: "From date",
            placeholderTo: "To date",
            fieldNameFrom: "fromDate",
            fieldNameTo: "toDate",
            fieldName: "dateRange",
            defaultValueFrom: this.state.filter.fromDate ? this.state.filter.fromDate.format("YYYY-MM-DD") : "",
            defaultValueTo: this.state.filter.toDate ? this.state.filter.toDate.format("YYYY-MM-DD") : "",
            type: 'dateRange',
            wrapperClassName: 'col-sm-6',
          },
          {
            placeholder: "Projects",
            fieldName: "projects",
            type: "projectSelect",
            onUpdate: this.projectsFilterOnUpdate,
            wrapperClassName: 'col-sm-6',
            reactSelectProps: {
              isMulti: true,
              value: this.state.filter.projects,
            }
          },
        ]}
        onFilter={this.onFilter}
        onClearAll={this.onClearAll}
        diagramData={this.state.diagramData}
        isLoading={isLoading}
      >
        {content}
        {staticAlert}
      </ReportsPageTemplate>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  reportsArtists: state.reportsArtists,
  filtersReducer: state.filtersReducer,
});

const mapDispatchToProps = dispatch => ({
  reportsArtistsFetch: async (dateFrom, dateTo, artists, clients, projects, type = 'TimeLogsPage') =>
    await dispatch(artistsActions.reportsArtistsFetch(dateFrom, dateTo, artists, clients, projects, type)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TimeLogsPage);