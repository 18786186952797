import React from "react";
import PropTypes from "prop-types";
import UserAvatar from "../../../components/UserAvatar";
import { filterArrayUniqueByKey } from "../../../helpers/tools";

const TasksAssigneesFormat = (props) => {
  const { project, user } = props;

  if (!project || !project.tasks) {
    return "--";
  }

  const taskAssignees = project.tasks
    .filter((task) => task.assignee && task.assignee.name)
    .map((task) => task.assignee);

  let taskAssigneesUnique = filterArrayUniqueByKey(taskAssignees, "id");

  const currentUserFromAssignees = taskAssigneesUnique.find(
    (assignee) => assignee.id === user.id
  );

  if (currentUserFromAssignees) {
    taskAssigneesUnique = [
      currentUserFromAssignees,
      ...taskAssigneesUnique.filter(
        (assignee) => assignee.id !== currentUserFromAssignees.id
      ),
    ];
  }

  if (taskAssigneesUnique.length) {
    const names = taskAssigneesUnique
      .map((assignee) => assignee.name)
      .join("<br/>");
    const tooltipContent = '<div class="text-right">' + names + "</div>";

    return (
      <div
        className={"py-1 d-flex align-items-center"}
        data-popup="tooltip"
        data-placement="left"
        data-html="true"
        data-original-title={tooltipContent}
      >
        <div className="mr-2">
          <UserAvatar
            user={taskAssigneesUnique[0]}
            showTitle={true}
          />
        </div>
        {taskAssigneesUnique.length !== 1
          ? "+" + (taskAssigneesUnique.length - 1)
          : null}
      </div>
    );
  }
  return "--";
};

TasksAssigneesFormat.propTypes = {
  project: PropTypes.object,
  user: PropTypes.object,
};

export default TasksAssigneesFormat;
