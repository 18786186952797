import React, {createRef} from 'react';
import {withRouter} from 'react-router-dom';
import ModalTemplate from './ModalTemplate';
import * as clientActions from "../actions/client";
import connect from "react-redux/es/connect/connect";
import LoadingIndicator from '../components/LoadingIndicator';
import ClientsEditForm from './ClientsEditForm';

class ClientsEditModal extends React.Component {
  constructor(props) {
    super(props);
    this.child = createRef();
  }

  /**
   *
   * @param event
   */
  handleSubmit = event => {
    this.child.current.handleSubmit(event);
  };

  render() {
    const {client} = this.props;
    const isLoading = client.fetch || client.edit || client.create;
    const loadingIndicator = isLoading ? <LoadingIndicator/> : null;
    const modalTitle =
      this.props.client.model &&
      this.props.client.model.id
        ? "Edit Client" : "Add Client";

    return (
      <ModalTemplate
        title={modalTitle}
        onClose={this.props.onClose}
        confirmButtonLabel="Submit"
        onConfirm={this.handleSubmit}
        cancelButtonLabel="Cancel"
        onCancel={this.props.onClose}
        loadingIndicator={loadingIndicator}
        disableConfirmButton={isLoading}
      >
        <ClientsEditForm ref={this.child} {...this.props} />
      </ModalTemplate>
    );
  }
}

const
  mapStateToProps = state => ({
    client: state.client,
  });

const
  mapDispatchToProps = dispatch => ({
    createClient: async (data) => await dispatch(clientActions.createClient(data)),
    editClient: async (id, data) => await dispatch(clientActions.editClient(id, data)),
    fetchClient: async (id) => await dispatch(clientActions.fetchClient(id)),
  });


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ClientsEditModal));
