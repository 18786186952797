import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import LoadingIndicator from "./LoadingIndicator";

const InfiniteScroll = (props) => {
  const contentHeight = useMemo(() => {
    const domNode = document.getElementById(props.id);

    return domNode ? domNode.clientHeight : 0;
  }, [props.loading]);

  useEffect(() => {
    let ticking = false;

    const domNode = document.getElementById(props.id);

    const onScroll = (e) => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          const isFullyScrolled =
            Math.abs(
              e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight
            ) <= 10;

          if (isFullyScrolled && typeof props.onEndReached === "function") {
            props.onEndReached();
          }
          ticking = false;
        });

        ticking = true;
      }
    };

    domNode.addEventListener("scroll", onScroll);

    return () => domNode.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    const domNode = document.getElementById(props.id);

    if (domNode.scrollHeight <= domNode.clientHeight && !props.loading) {
      // content fits within screen
      //props.onEndReached();
      if (typeof props.onEndReached === "function") {
        props.onEndReached();
        //console.log('props.onEndReached();')
      }
    }
  }, [contentHeight]);

  useEffect(() => {
    if (props.loading) {
      const domNode = document.getElementById(props.id);
      domNode.scrollTop = domNode.scrollHeight;
    }
  }, [props.loading]);

  const loadingIndicator = props.loading && (
    <LoadingIndicator className="mt-3" />
  );

  return (
    <div id={props.id} className={props.className}>
      {props.children}
      {loadingIndicator}
    </div>
  );
};

InfiniteScroll.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onEndReached: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default InfiniteScroll;
