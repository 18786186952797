import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class SearchButton extends Component {
  constructor(props) {
    super(props);

    this.state = {isOpen: false};
  }

  /**
   *
   */
  openSearch = () => {
    this.setState({isOpen: true});
  };

  /**
   *
   */
  closeSearch = () => {
    this.setState({isOpen: false});
  };

  /**
   *
   */
  onSearch = () => {
    const id = this.props.id || '';
    this.props.onSearch(document.querySelector('.js-search-input-' + id).value);
  };

  /**
   *
   * @returns {XML}
   */
  render() {
    const id = this.props.id || '';
    return (
      <div>
        {this.state.isOpen ?
          <div className="form-group-feedback form-group-feedback-left small-input-text mr-2">
            <input type="text"
                   className={"form-control form-control-lg pr-4 js-search-input-" + id}
                   placeholder="Search"/>
            <div className="form-control-feedback form-control-feedback-lg">
              <i className="icon-cancel-circle2 pointer" onClick={this.closeSearch}/>
            </div>
            <div className="form-control-feedback form-control-feedback-right-few">
              <i className="mi-search pointer" onClick={this.onSearch}/>
            </div>
          </div> :
          <button type="button" className="btn btn-link mr-2 vtop" onClick={this.openSearch} disabled={this.props.disabled}>
            <i className="mi-search mr-2"/> Search
          </button>}
      </div>
    );
  }
}

SearchButton.propTypes = {
  onSearch: PropTypes.func.isRequired,
  id: PropTypes.string,
  disabled: PropTypes.bool
};