export const initialState = {
  fetch: false,
  fetchSuccess: false,
  fetchError: null,

  collection: [],
  pagination: null,
};

export default function reducer(
  state = {
    ...initialState,
  },
  action
) {
  switch (action.type) {
    case "INVOICES_FETCH":
      return {
        ...state,
        fetch: true,
        fetchSuccess: false,
        fetchError: null,
      };

    case "INVOICES_FETCH_FULFILLED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: true,

        collection: action.data.bids,
        pagination: action.data.pagination,
      };

    case "INVOICES_FETCH_REJECTED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: false,
        fetchError: action.data,
      };

    case "BID_EDIT_FULFILLED":
      let withEditedInvoiceCollection = state.collection.map((value) =>
        value.id === action.data.id ? action.data : { ...value }
      );
      return {
        ...state,
        collection: withEditedInvoiceCollection,
      };

    case "CLEAR_ERRORS":
      return {
        ...initialState,
        collection: state.collection,
        pagination: state.pagination,
      };

    case "LOGOUT":
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
