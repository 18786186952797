import axios from 'axios';
import AxiosConfig from '../AxiosConfig';

/**
 *
 * @param data
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export const createClient = (data) => {

  return dispatch => {

    dispatch({type: "CLIENT_CREATE"});

    const url = AxiosConfig.getEndpointAddress() + "/api/clients.json";

    return axios.post(
      url,
      data,
      AxiosConfig.getAuthConfig(),
    )
      .then((response) => {

        dispatch({
          type: "CLIENT_CREATE_FULFILLED",
          data: response.data,
        });

      })
      .catch(function (error) {

        dispatch({
          type: "CLIENT_CREATE_REJECTED",
          data: error,
        });

      });
  }
};

/**
 *
 * @param id
 * @param data
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export const editClient = (id, data) => {

  return dispatch => {

    dispatch({type: "CLIENT_EDIT"});

    const url = AxiosConfig.getEndpointAddress() + "/api/clients/" + id + ".json";

    return axios.put(
      url,
      data,
      AxiosConfig.getAuthConfig(),
    )
      .then((response) => {

        dispatch({
          type: "CLIENT_EDIT_FULFILLED",
          data: response.data,
        });

      })
      .catch(function (error) {

        dispatch({
          type: "CLIENT_EDIT_REJECTED",
          data: error,
        });

      });
  }
};

/**
 *
 * @param id
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export const fetchClient = (id) => {

  return dispatch => {

    dispatch({type: "CLIENT_FETCH"});

    const url = AxiosConfig.getEndpointAddress() + '/api/clients/' + id + '.json';

    return axios.get(
      url,
      AxiosConfig.getAuthConfig(),
    )
      .then((response) => {

        dispatch({
          type: "CLIENT_FETCH_FULFILLED",
          data: response.data,
        });

      })
      .catch(function (error) {

        dispatch({
          type: "CLIENT_FETCH_REJECTED",
          data: error,
        });

      });
  }
};

/**
 *
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export const getClientId = () => {

  return dispatch => {

    dispatch({type: "CLIENT_GET_ID"});

    const url = AxiosConfig.getEndpointAddress() + '/api/clients.json';

    return axios.get(
      url,
      AxiosConfig.getAuthConfig(),
    )
      .then((response) => {

        dispatch({
          type: "CLIENT_GET_ID_FULFILLED",
          data: response.data,
        });

      })
      .catch(function (error) {

        dispatch({
          type: "CLIENT_GET_ID_REJECTED",
          data: error,
        });

      });
  }
};

/**
 *
 * @param data
 */
export const setEditClient = (data) => {

  return dispatch => {

    dispatch({type: "SET_CLIENT_EDIT", data});
  };
};
