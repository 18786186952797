import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import app from "./app";
import bidVersion from "./bidVersion";
import bid from "./bid";
import bids from "./bids";
import user from "./user";
import users from "./users";
import pendingUsers from "./pendingUsers";
import client from "./client";
import clients from "./clients";
import project from "./project";
import projects from "./projects";
import shot from "./shot";
import tasks from "./tasks";
import commonDashboard from "./commonDashboard";
import clientDashboard from "./clientDashboard";
import supplier from "./supplier";
import tasksTypes from "./tasksTypes";
import invoices from "./invoices";
import projectInvoices from "./projectInvoices";
import projectsInvoices from "./projectsInvoices";
import reportsArtists from "./reportsArtists";
import reportsArtistsPerformance from "./reportsArtistsPerformance";
import reportsInvoices from "./reportsInvoices";
import reportsGainLoss from "./reportsGainLoss";
import reportsPredictions from "./reportsPredictions";
import reportsProjectsHours from "./reportsProjectsHours";
import statuses from "./statuses";
import filtersReducer from "./filtersReducer";
import clientProjects from "./clientProjects";
import activities from "./activities";
import integrations from "./integrations";
import rtc from "./rtc";
import taskNotes from "./taskNotes";
import additionalCosts from "./additionalCosts";
import bidInvoices from "./bidInvoices";
import bidsInvoices from "./bidsInvoices";
import workloadDashboard from "./workloadDashboard";
import invoicesStore from "./invoicesStore";

/**
 *
 * @param history
 * @returns {Reducer<any> | Reducer<any, AnyAction>}
 */
export default (history) =>
  combineReducers({
    router: connectRouter(history),
    app,
    bidVersion,
    bid,
    bids,
    user,
    users,
    pendingUsers,
    client,
    clients,
    project,
    projects,
    shot,
    tasks,
    commonDashboard,
    clientDashboard,
    supplier,
    tasksTypes,
    invoices,
    projectInvoices,
    projectsInvoices,
    bidInvoices,
    bidsInvoices,
    reportsArtists,
    reportsArtistsPerformance,
    reportsInvoices,
    reportsPredictions,
    reportsGainLoss,
    reportsProjectsHours,
    statuses,
    filtersReducer,
    clientProjects,
    activities,
    integrations,
    rtc,
    taskNotes,
    additionalCosts,
    workloadDashboard,
    invoicesStore,
  });
