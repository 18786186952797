import React from "react";
import {activityUpdate} from "../helpers/activities";
import NewUpdates from "./NewUpdates";
import * as activitiesActions from "../actions/activities";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";

class RecentActivity extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activitiesEndReached: false,
      connectActivitiesSocketTimeout: false,
    };
  }

  /**
   *
   */
  componentDidMount() {
    if (
      this.props.user.token &&
      this.props.user.model &&
      typeof this.props.onConnect === "function"
    ) {
      this.props.onConnect();
    }
  }

  /**
   *
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.activities.fetchPrevious !== this.props.activities.fetchPrevious &&
      this.props.activities.fetchPreviousSuccess &&
      prevProps.activities.collection.length === this.props.activities.collection.length
    ) {
      this.setState({activitiesEndReached: true});
    }

    if (
      !prevProps.activities.connecting &&
      this.props.activities.connecting
    ) {
      setTimeout(() => {
        if (this.props.activities.connecting) {
          this.props.disconnectActivitiesSocket();
          this.setState({
            connectActivitiesSocketTimeout: true
          });
        }
      }, 10000);
    }
  }

  /**
   *
   */
  componentWillUnmount() {
    this.props.disconnectActivitiesSocket();
  }

  /**
   *
   */
  connectActivitiesSocket = () => {
    this.setState({
      connectActivitiesSocketTimeout: false
    }, this.props.onConnect);
  };

  /**
   *
   */
  onActivitiesEndReached = () => {
    if (
      this.props.activities.collection.length &&
      !this.props.activities.fetchPrevious &&
      !this.state.activitiesEndReached
    ) {
      const lastActivity =
        this.props.activities.collection
          .slice()
          .pop();

      this.props.onFetchPrevious(lastActivity.id);
    }
  };

  /**
   *
   * @returns {*}
   */
  render() {
    const recentActivityEmpty =
      this.state.connectActivitiesSocketTimeout
        ? (
          <div className={"text-center"}>
            <p className="text-muted my-3">
              Activities cannot be loaded. Please try again.
            </p>
            <button type="button" className={"btn btn-outline-primary"} onClick={this.connectActivitiesSocket}>
              Reconnect
            </button>
          </div>
        )
        : (
          <div className={"text-center text-muted my-3"}>
            <i className="mi-notifications-off fs-3rem mb-2"/>
            <br/>
            No activity yet...
          </div>
        );

    const recentActivityData =
      this.state.connectActivitiesSocketTimeout
        ? []
        : this.props.activities.collection.map(activityUpdate);

    const recentActivityLoading =
      this.props.activities.connecting &&
      !this.state.connectActivitiesSocketTimeout &&
      !this.props.tasksTypes.collection.length;

    const infiniteScrollProps = {
      id: this.props.id || "recent-activity-scroll-container",
      loading: this.props.activities.fetchPrevious,
      onEndReached: this.onActivitiesEndReached,
    };


    return (
      <NewUpdates
        title="Recent activity"
        data={recentActivityData}
        user={this.props.user}
        listEmptyComponent={recentActivityEmpty}
        loading={recentActivityLoading}
        infiniteScrollProps={infiniteScrollProps}
        className={this.props.className}
      />
    );
  }
}

RecentActivity.propTypes = {
  id: PropTypes.string,
  onConnect: PropTypes.func.isRequired,
  onFetchPrevious: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.user,
  activities: state.activities,
  tasksTypes: state.tasksTypes
});

const mapDispatchToProps = dispatch => ({
  disconnectActivitiesSocket: () =>
    dispatch(activitiesActions.disconnectActivitiesSocket()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RecentActivity);
