export const initialState = {
  fetch: false,
  fetchSuccess: false,
  fetchError: null,

  delete: false,
  deleteSuccess: false,
  deleteError: null,

  collection: [],
  pagination: null,
};

export default function reducer(
  state = {
    ...initialState
  },
  action
) {
  switch (action.type) {
    case "CLIENTS_FETCH":
      return {
        ...state,
        fetch: true,
        fetchSuccess: false,
        fetchError: null,
      };

    case "CLIENTS_FETCH_FULFILLED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: true,

        collection: action.data.clients,
        pagination: action.data.pagination,
      };

    case "CLIENTS_FETCH_REJECTED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: false,
        fetchError: action.data,
      };

    case "CLIENT_CREATE_FULFILLED":
      let withNewClientCollection = state.collection ? [...state.collection] : state.collection;
      if (withNewClientCollection) {
        withNewClientCollection.unshift(action.data);
      }
      return {
        ...state,
        collection: withNewClientCollection,
      };

    case "CLIENT_EDIT_FULFILLED":
      let withEditedClientCollection = state.collection
        ? state.collection.map((value) => {
          return value.id === action.data.id ? action.data : {...value};
        })
        : state.collection;
      return {
        ...state,
        collection: withEditedClientCollection,
      };

    case "CLIENT_DELETE":
      return {
        ...state,
        delete: true,
        deleteSuccess: false,
        deleteError: null,
      };

    case "CLIENT_DELETE_FULFILLED":
      let isDeleted = !!action.data.responseData.success;
      let collectionDeleted = isDeleted && state.collection
        ? state.collection.filter(value => value.id !== action.data.id)
        : state.collection;
      return {
        ...state,
        delete: false,
        deleteSuccess: isDeleted,

        collection: collectionDeleted,
      };

    case "CLIENT_DELETE_REJECTED":
      return {
        ...state,
        delete: false,
        deleteSuccess: false,
        deleteError: action.data,
      };

    case "CLEAR_ERRORS":
      return {
        ...initialState,
        collection: state.collection,
        pagination: state.pagination,
      };

    case "LOGOUT":
      return {
        ...initialState,
      };

    default:
      return state;
  }
}