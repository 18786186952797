import React, { useEffect } from "react";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { useBidStore } from "../../../storeHooks/bid";
import { useBidsStore } from "../../../storeHooks/bids";
import { useBidInvoiceStore } from "../../../storeHooks/bidInvoice";
import BidInvoicesTable from "../../BidInvoicesPage/components/BidInvoicesTable";
import { useInvoicesStoreStore } from "../../../storeHooks/invoicesStore";

const BidInvoicesTableExpandable = (props) => {
  const { data } = props;

  const { invoicesStore } = useInvoicesStoreStore();
  const { fetchBid } = useBidStore();

  const bid = invoicesStore.data[data.id]
    ? invoicesStore.data[data.id].bid
    : {};

  const bidInvoices =
    invoicesStore.data[data.id] && invoicesStore.data[data.id].bidInvoices
      ? invoicesStore.data[data.id].bidInvoices
      : { collection: [] };

  useEffect(() => {
    fetchBid(data.id);
  }, [data.id]);

  //const bidInvoicesCollection = findBidInvoicesCollection();
  //const passedBidInvoices = { ...bidsInvoices, collection: bidInvoicesCollection };

  //const { shots: shotsData, ...modelData } = findBidModel();

  const hiddenColumns = ["actions", "shots"];

  return bid.model ? (
    <div className="data-table-themed_project-invoice-expandable">
      <BidInvoicesTable
        bidInvoices={bidInvoices}
        bid={bid}
        allowStatusEdit={true}
        fitLoaderInBox={true}
        hiddenColumns={hiddenColumns}
      />
    </div>
  ) : (
    <div className="m-3">
      <LoadingIndicator />
    </div>
  );
};

BidInvoicesTableExpandable.propTypes = {
  data: PropTypes.object,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BidInvoicesTableExpandable);
