import React, {Component, useEffect, useState} from "react";
import PropTypes from "prop-types";
import { formatter } from "../../../helpers/tools";
import classNames from "classnames";

const SumRowsEdit = (props) => {
  const [state, setState] = useState({
    changed: false,
  });

  useEffect(() => {
    setValuesToForm();
  }, []);

  /**
   *
   */
  const setValuesToForm = () => {
    const rateFields = document.querySelectorAll(
      "." + props.wrapperClassName + " .js-sum-rows-rate"
    );
    const amountFields = document.querySelectorAll(
      "." + props.wrapperClassName + " .js-sum-rows-amount"
    );
    props.rowsAr.forEach((item, index) => {
      rateFields[index].value = item.rate;
      amountFields[index].value = item.amount;
    });
  };

  /**
   *
   */
  const getRowsResult = () => {
    const rateFields = document.querySelectorAll(
      "." + props.wrapperClassName + " .js-sum-rows-rate"
    );
    const amountFields = document.querySelectorAll(
      "." + props.wrapperClassName + " .js-sum-rows-amount"
    );
    const result = {};
    props.rowsAr.forEach((item, index) => {
      result[item.name] = {
        rate: rateFields[index].value,
        amount: amountFields[index].value,
      };
    });
    return result;
  };

  /**
   *
   */
  const onChange = () => {
    props.onChange(getRowsResult());
    setState({ ...state, changed: true });
  };

  /**
   *
   * @param event
   */
  const onKeyPress = (event) => {
    if (event.key === "-") {
      event.preventDefault();
    }
  };

  const wrapperClassName = props.wrapperClassName;
  const resultWrapperClassName = classNames("sum-rows-edit", wrapperClassName);
  const disabled = props.disabled;
  return (
    <div className={resultWrapperClassName}>
      <table>
        {props.titles && (
          <thead>
            <tr>
              {props.titles.map((item, index) => {
                return <td key={index}>{item}</td>;
              })}
            </tr>
          </thead>
        )}
        <tbody>
          {props.rowsAr.map((item, index) => {
            const resultValue = document.querySelectorAll(
              "." + wrapperClassName + " .js-sum-rows-rate"
            ).length
              ? formatter.format(
                  Number(
                    document.querySelectorAll(
                      "." + wrapperClassName + " .js-sum-rows-rate"
                    )[index].value
                  ) *
                    Number(
                      document.querySelectorAll(
                        "." + wrapperClassName + " .js-sum-rows-amount"
                      )[index].value
                    )
                )
              : 0;

            return (
              <React.Fragment key={index}>
                <tr>
                  <td colSpan={3} className="sum-rows-edit__label">
                    {item.label}
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="sum-rows-edit__input-cell">
                      {item.measure && (
                        <div className="sum-rows-edit__measure">
                          {item.measure}
                        </div>
                      )}
                      <input
                        type="number"
                        className="form-control sum-rows-edit__input js-sum-rows-rate"
                        onChange={onChange}
                        onKeyPress={onKeyPress}
                        min={0}
                        disabled={disabled}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="sum-rows-edit__input-cell">
                      <input
                        type="number"
                        className="form-control sum-rows-edit__input js-sum-rows-amount"
                        onChange={onChange}
                        onKeyPress={onKeyPress}
                        min={0}
                        disabled={disabled}
                      />
                    </div>
                  </td>
                  <td>
                    {state.changed
                      ? resultValue
                      : item.measure + item.total}
                  </td>
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

SumRowsEdit.defaultProps = {
  wrapperClassName: "sum-rows-edit-default",
};

SumRowsEdit.propTypes = {
  onChange: PropTypes.func.isRequired,
  rowsAr: PropTypes.array.isRequired,
  titles: PropTypes.array,
  wrapperClassName: PropTypes.string,
  disabled: PropTypes.bool,
};

export default SumRowsEdit;
