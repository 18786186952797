import React, { Component } from "react";

import { history } from "../configureStore";

import UnauthorizedPageTemplate from "./UnauthorizedPageTemplate";
import * as userActions from "../actions/user";
import connect from "react-redux/es/connect/connect";

import LoadingIndicator from "../components/LoadingIndicator";
import ApiError from "../components/ApiError";

class RegisterPage extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  /**
   *
   * @param event
   * @returns {Promise<void>}
   */
  onRegisterPress = async event => {
    event.preventDefault();
    await this.props.createProfile(
      document.querySelector(".js-user-reg-name").value,
      document.querySelector(".js-user-reg-email").value,
      document.querySelector(".js-user-reg-password").value
    );
    if (!this.props.user.registerError) {
      history.push("/not-approved");
    }
  };

  /**
   *
   * @returns {XML}
   */
  render() {
    const user = this.props.user;
    const loadingIndicator = user.register ? <LoadingIndicator /> : null;

    return (
      <UnauthorizedPageTemplate>
        <form className="login-form">
          <div className="card mb-0">
            <div className="card-body">
              <div className="text-center mb-3">
                <i className="icon-plus3 icon-2x text-success border-success border-3 rounded-round p-3 mb-3 mt-1" />
                <h5 className="mb-0">Request account</h5>
                <span className="d-block text-muted">
                  All fields are required
                </span>
              </div>

              <div className="form-group text-center text-muted content-divider">
                <span className="px-2">Your credentials</span>
              </div>

              {loadingIndicator}

              <div className="form-group form-group-feedback form-group-feedback-left">
                <input
                  type="email"
                  className="form-control js-user-reg-email"
                  placeholder="Your email"
                />
                <div className="form-control-feedback">
                  <i className="icon-mention text-muted" />
                </div>
                <ApiError error={user.registerError} />
              </div>

              <div className="form-group form-group-feedback form-group-feedback-left">
                <input
                  type="text"
                  className="form-control js-user-reg-name"
                  placeholder="Name"
                />
                <div className="form-control-feedback">
                  <i className="icon-user-check text-muted" />
                </div>
              </div>

              <div className="form-group form-group-feedback form-group-feedback-left">
                <input
                  type="password"
                  className="form-control js-user-reg-password"
                  placeholder="Password"
                />
                <div className="form-control-feedback">
                  <i className="icon-user-lock text-muted" />
                </div>
              </div>

              <button
                type="submit"
                className="btn bg-teal-400 btn-block"
                onClick={this.onRegisterPress}
              >
                Register <i className="icon-circle-right2 ml-2" />
              </button>
            </div>
          </div>
        </form>
      </UnauthorizedPageTemplate>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  createProfile: async (name, email, password) =>
    await dispatch(userActions.createProfile(name, email, password))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterPage);
