/**
 * Created by piotr.pozniak@thebeaverhead.com on 17/11/2021.
 */
import React, { createRef, useState } from "react";
import PropTypes from "prop-types";
import HoveredEdit from "../../../components/HoveredEdit";
import HasRights from "../../../components/HasRights";

const HeaderInput = (props) => {
  const {
    userModel,
    isLoading,
    defaultValue,
    value,
    placeholder,
    onUpdateValue,
    valueFormatter,
    inputType,
  } = props;

  const inputRef = createRef();

  /**
   *
   */
  const onInputConfirm = () => {
    let value = inputRef.current.value;

    if (props.onFieldUpdate) {
      value = props.onFieldUpdate(inputRef);
    }
    onUpdateValue(value);
  };

  /**
   *
   */
  const onFocus = () => {

    if (props.onFocus) {
      props.onFocus(inputRef);
    } else {
      inputRef.current.focus();
    }
  };

  const inputValue =
    valueFormatter(value) || valueFormatter(defaultValue) || placeholder;

  const editorialFormatPlaceholder = <div className="p-1">{inputValue}</div>;

  return (
    <HasRights
      allowedRoles={["admin", "producer"]}
      user={userModel}
      placeholder={editorialFormatPlaceholder}
    >
      <HoveredEdit
        className="hovered-edit_for-dark-theme text-primary w-auto"
        preview={inputValue}
        handleConfirm={onInputConfirm}
        handleFocus={onFocus}
        disabled={isLoading}
      >
        <input
          defaultValue={defaultValue}
          ref={inputRef}
          type={inputType}
          className="input-pure"
          placeholder={"Format not specified"}
        />
      </HoveredEdit>
    </HasRights>
  );
};

HeaderInput.defaultProps = {
  inputType: "text",
  valueFormatter: (val) => val,
};

HeaderInput.propTypes = {
  userModel: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onUpdateValue: PropTypes.func,
  inputType: PropTypes.string,
  valueFormatter: PropTypes.func,
  onFocus: PropTypes.func,
  onFieldUpdate: PropTypes.func,
};

export default HeaderInput;
