import React, { memo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const LoadingIndicator = (props) => {
  const spinner = <i className="icon-spinner2 spinner" />;

  const modalLoadingClassName = classNames("modal-spinner", props.className);

  const loadingClassName = classNames("mb-3", props.className);

  return props.isModal ? (
    <div className={modalLoadingClassName}>{spinner}</div>
  ) : (
    <div className={loadingClassName}>
      {spinner} <span className="ml-2">{props.label}</span>
    </div>
  );
};

LoadingIndicator.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
};

LoadingIndicator.defaultProps = {
  className: "",
  label: "Processing...",
};

export default memo(LoadingIndicator);
