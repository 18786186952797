import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";

import DonutChart from "../../../components/charts/DonutChart";
import HasRights from "../../../components/HasRights";
import UserSelect from "../../../components/Selects/UserSelect";
import LoadingIndicator from "../../../components/LoadingIndicator";

import { prettifyProperty, ucFirst } from "../../../helpers/error";
import { stringToColour } from "../../../helpers/tools";
import useStatusHook from "../../../hooks/useStatusHook";
import { useUserStore } from "../../../storeHooks/user";
import { useCommonDashboardStore } from "../../../storeHooks/commonDashboard";

const OverviewChart = (props) => {
  const { isLoading, isRefreshing, data, type, title, onUserSelectUpdate } =
    props;

  const chartAr = (data || {}).chart || {};
  const { user } = useUserStore();
  const { statuses: statusesFiltered } = useStatusHook(ucFirst(data.type));

  /**
   *
   * @returns {{data: *[], colors: *[]}}
   */
  const options = useMemo(() => {
    let [data, colors] = [[], []];
    for (let key in chartAr) {
      if (!!chartAr[key]) {
        const status = statusesFiltered.find((item) => key === item.value);
        data.push({
          name: status ? status.label : prettifyProperty(key),
          value: chartAr[key],
        });
        colors.push(status ? `#${status.color}` : stringToColour(key));
      }
    }

    return {
      colors,
      data,
    };
  }, [chartAr, statusesFiltered]);

  /**
   *
   * @param value
   */
  const onUserSelectChange = (value) => {
    if (onUserSelectUpdate) {
      onUserSelectUpdate(value);
    }
  };

  const loadingIndicator = (
    <div className="text-center">
      <LoadingIndicator className={"my-3"} />
    </div>
  );

  if (isLoading) {
    return loadingIndicator;
  }

  let chart = <div />;

  switch (type) {
    case "shots":
      chart = (
        <DonutChart
          colors={options.colors}
          title="Statuses"
          seriesName="Status"
          data={options.data}
          isDarkThemed={props.isDarkThemed}
        />
      );
      break;

    case "projects":
      chart = (
        <DonutChart
          colors={options.colors}
          title="Statuses"
          seriesName="Status"
          data={options.data}
          isDarkThemed={props.isDarkThemed}
        />
      );
      break;

    default:
      break;
  }

  const assignees = (
    <HasRights allowedRoles={["admin", "producer"]} user={user.model}>
      <div className="header-elements w-50">
        {isRefreshing ? <LoadingIndicator className="mr-2" /> : null}
        <div className="flex-1">
          <UserSelect
            onUpdate={onUserSelectChange}
            reactSelectProps={{ isClearable: true }}
            disabled={isRefreshing}
          />
        </div>
      </div>
    </HasRights>
  );

  const emptyBlock = (
    <div className="row text-center justify-content-around align-items-center flex-1">
      --
    </div>
  );

  return data.chart ? (
    <div className="card pb-3">
      <div className="card-header header-elements-inline border-bottom-0">
        <h6 className="card-title">{title}</h6>
        {onUserSelectUpdate ? assignees : null}
      </div>
      <div className="card-body py-0 d-flex">
        {options.data.length ? chart : emptyBlock}
      </div>
    </div>
  ) : null;
};

OverviewChart.propTypes = {
  isLoading: PropTypes.bool,
  isRefreshing: PropTypes.bool,
  isDarkThemed: PropTypes.bool,
  data: PropTypes.object,
  type: PropTypes.string,
  title: PropTypes.string,
  onUserSelectUpdate: PropTypes.func,
};

export default OverviewChart;
