/**
 * Created by piotr.pozniak@thebeaverhead.com on 24/01/2022
 */

import React, { useState } from "react";
import PropTypes from "prop-types";
import HasRights from "../../../components/HasRights";
import LoadingIndicator from "../../../components/LoadingIndicator";
import classNames from "classnames";
import DropdownMenu from "../../../components/DropdownMenu";
import { useUserStore } from "../../../storeHooks/user";
import { history } from "../../../configureStore";
import { useAppStore } from "../../../storeHooks/app";
const hasStatusGroup = ["Projects", "Bids", "Shots"];

const StatusesTable = (props) => {
  const { type, items } = props;

  return (
    <table className="table datatable-basic">
      <thead>
        <tr>
          <th>Value</th>
          <th>Label</th>
          <th>Color</th>
          {hasStatusGroup.includes(type) && <th>Group</th>}
          <th>Order</th>
          {props.hasEditPermissions ? (
            <th className="text-center">Actions</th>
          ) : null}
        </tr>
      </thead>

      <tbody>
        {items.map((item) => {
          if (props.deletingRowId === item.id) {
            return (
              <tr key={item.value}>
                <td>
                  <LoadingIndicator />
                </td>
              </tr>
            );
          }
          const buttons = [
            {
              icon: "mi-mode-edit",
              action: props.onEditStatus(item),
              data: item,
              label: "Edit details",
            },
          ];
          if (!item.protected) {
            buttons.push({
              icon: "mi-delete-forever",
              action: props.onDeleteStatus(item.id),
              data: item,
              label: "Delete",
            });
          }

          const trClassName = classNames({
            "tr-warning":
              props.deleteErrorId === item.id,
          });

          return (
            <tr key={item.value} className={trClassName}>
              <td>{item.value}</td>
              <td>{item.label}</td>
              <td>
                <div
                  style={{
                    width: 20,
                    height: 20,
                    borderRadius: 10,
                    marginRight: 7,
                    backgroundColor: `#${item.color}`,
                  }}
                />
              </td>
              {hasStatusGroup.includes(item.type) && (
                <td>{item.status_group}</td>
              )}
              <td>{item.status_order}</td>
              {props.hasEditPermissions ? (
                <td className="text-center">
                  <DropdownMenu buttons={buttons} />
                </td>
              ) : null}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

StatusesTable.defaultProps = {};

StatusesTable.propTypes = {
  type: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  onEditStatus: PropTypes.func.isRequired,
  onDeleteStatus: PropTypes.func.isRequired,
  hasEditPermissions: PropTypes.bool,
  deleteErrorId: PropTypes.string
};

export default StatusesTable;
