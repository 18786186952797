/**
 * Created by piotr.pozniak@thebeaverhead.com on 31/01/2022.
 */
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as shotActions from "../actions/shot";

export function useShotStore() {
  const dispatch = useDispatch();
  const _shot = useSelector((store) => store.shot);

  const fetchShot = useCallback(
    async (id) => await dispatch(shotActions.fetchShot(id)),
    [dispatch]
  );

  const createShot = useCallback(
    async (project_id, data) =>
      await dispatch(shotActions.createShot(project_id, data)),
    [dispatch]
  );

  const editShot = useCallback(
    async (id, data) => await dispatch(shotActions.editShot(id, data)),
    [dispatch]
  );

  const createShotUpdate = useCallback(
    async (id, data) => await dispatch(shotActions.createShotUpdate(id, data)),
    [dispatch]
  );

  const deleteShotUpdate = useCallback(
    async (id) => await dispatch(shotActions.deleteShotUpdate(id)),
    [dispatch]
  );

  const editShotUpdate = useCallback(
    async (id, data) => await dispatch(shotActions.editShotUpdate(id, data)),
    [dispatch]
  );

  const fetchShotNotes = useCallback(
    async (id) => await dispatch(shotActions.fetchShotNotes(id)),
    [dispatch]
  );

  const createShotNote = useCallback(
    async (id, note) => await dispatch(shotActions.createShotNote(id, note)),
    [dispatch]
  );

  const editShotNote = useCallback(
    async (shotNoteId, note, shotId) =>
      await dispatch(shotActions.editShotNote(shotNoteId, note, shotId)),
    [dispatch]
  );

  const deleteShotNote = useCallback(
    async (shotNoteId, shotId) =>
      await dispatch(shotActions.deleteShotNote(shotNoteId, shotId)),
    [dispatch]
  );

  const moveShot = useCallback(
    async (shotId, bidId) =>
      await dispatch(shotActions.moveShot(shotId, bidId)),
    [dispatch]
  );

  return {
    shot: _shot,
    fetchShot,
    createShot,
    editShot,
    createShotUpdate,
    deleteShotUpdate,
    editShotUpdate,
    createShotNote,
    editShotNote,
    deleteShotNote,
    moveShot,
    fetchShotNotes
  };
}
