export const initialState = {
  fetch: false,
  fetchSuccess: false,
  fetchError: null,

  collection: [],
};

export default function reducer(
  state = {
    ...initialState
  },
  action
) {
  switch (action.type) {
    case "INVOICES_REPORTS_FETCH":
      return {
        ...state,
        fetch: true,
        fetchSuccess: false,
        fetchError: null,
      };

    case "INVOICES_REPORTS_FETCH_FULFILLED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: true,
        fetchError: null,

        collection: action.data.clients,
      };

    case "INVOICES_REPORTS_FETCH_REJECTED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: false,
        fetchError: action.data,

        collection: [],
      };

    case "CLEAR_ERRORS":
      return {
        ...initialState,

        collection: state.collection,
      };

    case "LOGOUT":
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
