import React from "react";
import PropTypes from "prop-types";

const BidNotes = (props) => {
  const { bid } = props;

  return (
    <div className="form-group">
      <label className="form-label font-weight-bold">Bid Notes:</label>
      <div className="pre-simple">
        {bid && bid.bid_notes ? bid.bid_notes : "--"}
      </div>
    </div>
  );
};

BidNotes.propTypes = {
  bid: PropTypes.object,
};

export default BidNotes;
