/**
 * Created by piotr.pozniak@thebeaverhead.com on 20/04/2023
 */

import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useBidStore } from "../../storeHooks/bid";
import LoadingIndicator from "../../components/LoadingIndicator";

const dateFormatter = new Intl.DateTimeFormat("en-US", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
});
const PreviousVersions = (props) => {
  const [expanded, setExpanded] = useState(false);
  const { bid, fetchBidVersionsByBid } = useBidStore();

  const loadingIndicator = bid.fetchBidVersionsByBid ? (
    <LoadingIndicator />
  ) : null;

  const onShowMore = useCallback(
    (e) => {
      e.preventDefault();
      setExpanded(true);
      fetchBidVersionsByBid(bid.model.id);
    },
    [expanded, bid.model]
  );

  const versions = useMemo(() => {
    if (!expanded && bid.model.recent_bid_version) {
      return [bid.model.recent_bid_version];
    } else if (expanded && bid.versions) {
      return bid.versions;
    }

    return [];
  }, [expanded, bid.model, bid.versions]);

  const rows = useMemo(
    () =>
      versions.map((version) => {
        return (
          <tr key={version.id}>
            <td>{dateFormatter.format(new Date(version.created * 1000))}</td>
            <td>{version.create_order}</td>
            <td>{version.version_name || "-"}</td>
          </tr>
        );
      }),
    [versions]
  );

  const showMoreButton = useMemo(() => {
    return !expanded ? (
      <a href={"#"} onClick={onShowMore}>
        Show more
      </a>
    ) : null;
  }, [expanded, bid.model]);

  return (
    <div className="table-responsive ">
      <label className="form-label">Previous versions</label>
      <div className={" overflow-auto maxh-200"}>
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th>Created At</th>
              <th>Number</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
      {loadingIndicator}
      {showMoreButton}
    </div>
  );
};

PreviousVersions.defaultProps = {};

PreviousVersions.propTypes = {};

export default PreviousVersions;
