import axios from "axios";
import AxiosConfig from "../AxiosConfig";

/**
 *
 * @param bidId
 * @returns {Function}
 */
export const fetchBidInvoices = (bidId) => {
  return async (dispatch) => {
    dispatch({ type: "BID_INVOICES_FETCH", bidId });

    const url =
      AxiosConfig.getEndpointAddress() +
      "/api/invoices/bid/" +
      bidId +
      ".json";

    await axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "BID_INVOICES_FETCH_FULFILLED",
          data: response.data,
          bidId,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "BID_INVOICES_FETCH_REJECTED",
          data: error,
          bidId
        });
      });
  };
};

/**
 *
 * @param bidId
 * @param data
 * @param shots
 * @returns {Function}
 */
export const createInvoice = (bidId, data, shots = []) => {
  return async (dispatch) => {
    dispatch({ type: "BID_INVOICE_CREATE" });

    const url = AxiosConfig.getEndpointAddress() + "/api/invoices.json";

    await axios
      .post(
        url,
        {
          bid_id: bidId,
          ...data,
          shots,
        },
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "BID_INVOICE_CREATE_FULFILLED",
          data: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "BID_INVOICE_CREATE_REJECTED",
          data: error,
        });
      });
  };
};

/**
 *
 * @param invoiceId
 * @param data
 * @param shots
 * @returns {Function}
 */
export const editInvoice = (invoiceId, data, shots) => {
  return async (dispatch) => {
    const bidId = data.bidId || null;
    dispatch({ type: "BID_INVOICE_EDIT", invoiceId, bidId });

    const url =
      AxiosConfig.getEndpointAddress() + "/api/invoices/" + invoiceId + ".json";

    const dataToSend = {
      ...data,
    };

    if (shots) {
      dataToSend.shots = shots;
    }

    await axios
      .put(url, dataToSend, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "BID_INVOICE_EDIT_FULFILLED",
          data: response.data,
          invoiceId,
          bidId
        });
      })
      .catch(function (error) {
        dispatch({
          type: "BID_INVOICE_EDIT_REJECTED",
          data: error,
          invoiceId,
          bidId
        });
      });
  };
};

/**
 *
 * @param invoiceId
 * @returns {Function}
 */
export const deleteInvoice = (invoiceId) => {
  return async (dispatch) => {
    dispatch({ type: "BID_INVOICES_DELETE" });

    const url =
      AxiosConfig.getEndpointAddress() + "/api/invoices/" + invoiceId + ".json";

    await axios
      .delete(url, { ...AxiosConfig.getAuthConfig() })
      .then((response) => {
        dispatch({
          type: "BID_INVOICES_DELETE_FULFILLED",
          data: {
            responseData: response.data,
            invoiceId,
          },
        });
      })
      .catch(function (error) {
        dispatch({
          type: "BID_INVOICES_DELETE_REJECTED",
          data: error,
        });
      });
  };
};

/**
 *
 * @param uuid
 * @param fileName
 * @returns {Function}
 */
export const invoicePDF = (uuid, fileName) => {
  return async (dispatch) => {
    dispatch({ type: "BID_INVOICE_PDF" });

    const url =
      AxiosConfig.getEndpointAddress() +
      "/invoices/generate_pdf/" +
      uuid +
      ".pdf";

    await axios
      .get(url, {
        ...AxiosConfig.getAuthConfig(),
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName + ".pdf");
        document.body.appendChild(link);
        link.click();

        dispatch({ type: "BID_INVOICE_PDF_FULFILLED" });
      })
      .catch(function (error) {
        dispatch({ type: "BID_INVOICE_PDF_REJECTED", data: error });
      });
  };
};

/**
 *
 * @param bidId
 * @param status
 * @returns {function(...[*]=)}
 */
export const editDepositInvoiceStatus = (bidId, status) => {
  return async (dispatch) => {
    dispatch({ type: "DEPOSIT_INVOICE_STATUS_EDIT", bidId });

    const url =
      AxiosConfig.getEndpointAddress() + "/api/bids/" + bidId + ".json";

    await axios
      .put(url, { deposit_invoice_status: status }, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "DEPOSIT_INVOICE_STATUS_EDIT_FULFILLED",
          data: response.data,
          bidId
        });
      })
      .catch(function (error) {
        dispatch({
          type: "DEPOSIT_INVOICE_STATUS_EDIT_REJECTED",
          data: error,
          bidId
        });
      });
  };
};

/**
 *
 * @param uuid
 * @param fileName
 * @param deposit
 * @returns {Function}
 */
export const depositInvoicePDF = (uuid, fileName, deposit) => {
  return async (dispatch) => {
    dispatch({ type: "BID_DEPOSIT_INVOICE_PDF" });

    const url =
      AxiosConfig.getEndpointAddress() +
      "/deposits/generate_pdf/" +
      uuid +
      ".pdf?" +
      AxiosConfig.objectToURLQuery({ deposit });

    await axios
      .get(url, {
        ...AxiosConfig.getAuthConfig(),
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName + ".pdf");
        document.body.appendChild(link);
        link.click();

        dispatch({ type: "BID_DEPOSIT_INVOICE_PDF_FULFILLED" });
      })
      .catch(function (error) {
        dispatch({ type: "BID_DEPOSIT_INVOICE_PDF_REJECTED", data: error });
      });
  };
};
