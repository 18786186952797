/**
 * Created by piotr.pozniak@thebeaverhead.com on 20/04/2023
 */

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { getStatus } from "../../../helpers/tools";
import BidVersionName from "../../../components/BidVersionName";
import { useStatusesStore } from "../../../storeHooks/statuses";
import { useBidStore } from "../../../storeHooks/bid";
import { useBidVersionStore } from "../../../storeHooks/bidVersion";

const BidSummary = (props) => {
  const { statuses } = useStatusesStore();
  const { bid } = useBidStore();
  const { bidVersion } = useBidVersionStore();

  const bidModel = bid.model;
  const projectModel = bidModel.project || {};
  const clientModel = projectModel ? projectModel.client : {};
  const bidVersionModel = bidVersion.model;

  const projectStatuses = useMemo(
    () => statuses.collection.filter((i) => i.type === "Projects"),
    [statuses.collection]
  );

  const updatedTime = useMemo(
    () =>
      bidVersionModel &&
      (bidVersionModel.create_order !== 1
        ? new Date(bidVersionModel.created * 1000).toLocaleDateString()
        : "--"),
    [bidVersionModel]
  );

  const status = useMemo(
    () => bid.model && getStatus(bid.model.status, projectStatuses),
    [bid.model, projectStatuses]
  );

  return (
    <div className="table-responsive">
      <table className="table table_white table-bordered table-striped mb-3">
        <tbody>
          <tr>
            <td>
              <i className="icon-crown mr-1 vsub" />
              Customer
              <br />
              <b>{clientModel && clientModel.name}</b>
            </td>
            <td>
              <i className="icon-film mr-1 vsub" />
              Project
              <br />
              <b>{projectModel && projectModel.name}</b>
            </td>
            <td>
              <i className="icon-pulse2 mr-1 vsub" />
              Status
              <br />
              <b>{status}</b>
            </td>
            <td>
              <i className="icon-versions mr-1 vsub" />
              Current version
              <br />
              <b>
                <BidVersionName bidVersion={bidVersionModel} />
              </b>
            </td>
            <td>
              <i className="icon-diff-added mr-1 vsub" />
              Created by
              <br />
              <b>{bidModel && bidModel.creator && bidModel.creator.name}</b>
            </td>
            <td>
              <i className="icon-diff-modified mr-1 vsub" />
              Updated
              <br />
              <b>{updatedTime}</b>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

BidSummary.defaultProps = {};

BidSummary.propTypes = {};

export default BidSummary;
