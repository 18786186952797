/**
 * Created by piotr.pozniak@thebeaverhead.com on 09/07/2024.
 */
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as clientProjectsActions from "../actions/clientProjects";

export function useClientProjectsStore() {
  const dispatch = useDispatch();
  const _clientProjects = useSelector((store) => store.clientProjects);

  const fetchClientProjects = useCallback(
    async (page, limit, filters) =>
      await dispatch(clientProjectsActions.fetchProjects(page, limit, filters)),
    [dispatch]
  );

  return {
    clientProjects: _clientProjects,
    fetchClientProjects,
  };
}
