/**
 * Created by piotr.pozniak@thebeaverhead.com on 09/07/2024
 */

import React from "react";
import PropTypes from "prop-types";
import { formatter } from "../../../helpers/tools";

const AdditionalCostsSubtotalFormatter = (row) => {
  if (row.type === "deposit_invoice") {
    return "--";
  }
  return formatter.format(
    row.additional_costs_subtotal_from_recent_bid_version
      ? row.additional_costs_subtotal_from_recent_bid_version
      : 0
  );
};

AdditionalCostsSubtotalFormatter.defaultProps = {};

AdditionalCostsSubtotalFormatter.propTypes = {};

export default AdditionalCostsSubtotalFormatter;
