/**
 * Created by piotr.pozniak@thebeaverhead.com on 24/01/2022
 */

import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { history } from "../../../configureStore";
import { useStatusesStore } from "../../../storeHooks/statuses";
import StatusGroupPanel from "./StatusGroupPanel";

const styles = {
  cardHeader: {
    borderBottomWidth: 0,
  },
};

const StatusesGroups = [
  {
    type: "Projects",
    label: "Project",
  },
  {
    type: "Bids",
    label: "Bid",
  },
  {
    type: "Shots",
    label: "Shot",
  },
  {
    type: "Tasks",
    label: "Task",
  },
  {
    type: "Invoices",
    label: "Invoice",
  },
];

const PlatformStatuses = (props) => {
  const { isLoading } = props;

  return (
    <div className="col-md-12" id="supplier-statuses-pane">
      <div className="card">
        <div className="card-header" style={styles.cardHeader}>
          <h6 className="card-title">Statuses</h6>
        </div>
        <div className="card-body">
          <ul className="nav nav-tabs nav-tabs-highlight">
            {StatusesGroups.map((i, idx) => {
              return (
                <li className="nav-item" key={`#tab-${i.type}-${idx}`}>
                  <a
                    href={`#tab-${i.type}`}
                    className={classNames("nav-link", { active: !idx })}
                    data-toggle="tab"
                  >
                    {i.label}
                  </a>
                </li>
              );
            })}
          </ul>

          <div className="tab-content">
            {StatusesGroups.map((i, idx) => {
              return (
                <StatusGroupPanel
                  key={`#tab-${i.type}-${idx}`}
                  type={i.type}
                  tabId={`tab-${i.type}`}
                  isLoading={isLoading}
                  defaultActive={!idx}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

PlatformStatuses.defaultProps = {};

PlatformStatuses.propTypes = {
  isLoading: PropTypes.bool,
};

export default PlatformStatuses;
