import React from "react";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import ImportantItemsHeader from "./ImportantItemsHeader";
import GroupedBidRow from "./GroupedBidRow";
import StatusBadge from "../../../components/StatusBadge";
import useStatusHook from "../../../hooks/useStatusHook";

const GroupedBids = (props) => {
  const { data, user = {}, statuses } = props;

  const collection = data.collection || [];

  const { statuses: bidsStatuses } = useStatusHook("Bids");

  let lastStatus = "";

  return (
    <div className="card mb-0">
      <ImportantItemsHeader title={data.title} />

      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th width="11%">Due</th>
              <th width="15%">Bid</th>
              <th width="19%">Bid Status</th>
              <th width="17%">Quote</th>
              <th width="17%">Deposit</th>
              <th width="20%">Invoice #</th>
            </tr>
          </thead>
          <tbody>
            {collection.map((bid) => {
              if (bid.status !== lastStatus) {
                lastStatus = bid.status;
                return (
                  <React.Fragment key={`grouped-shot-${bid.id}`}>
                    <tr className="table-active table-border-double">
                      <td colSpan="6">
                        <StatusBadge
                          status={bid.status}
                          statuses={bidsStatuses}
                        />
                      </td>
                    </tr>
                    <GroupedBidRow
                      data={bid}
                      user={user}
                      statuses={bidsStatuses}
                    />
                  </React.Fragment>
                );
              } else {
                return (
                  <GroupedBidRow
                    data={bid}
                    user={user}
                    statuses={bidsStatuses}
                    key={`grouped-shot-${bid.id}`}
                  />
                );
              }
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

GroupedBids.propTypes = {
  data: PropTypes.object.isRequired,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  statuses: state.statuses,
});

export default connect(mapStateToProps, null)(GroupedBids);
