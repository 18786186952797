import axios from 'axios';
import AxiosConfig from '../AxiosConfig';

/**
 *
 * @param page
 * @param limit
 * @param verified
 * @param role
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export const fetch = (page = 1, limit = 20, verified = null, role = null) => {

  return dispatch => {

    dispatch({
      type: "USERS_FETCH",
      data: {page, role}
    });

    const url = AxiosConfig.getEndpointAddress() + '/api/users.json?'
      + AxiosConfig.objectToURLQuery({ page, limit, verified, role });

    return axios.get(
      url,
      AxiosConfig.getAuthConfig(),
    )
      .then((response) => {

        dispatch({
          type: "USERS_FETCH_FULFILLED",
          data: response.data,
        });

      })
      .catch(function (error) {

        dispatch({
          type: "USERS_FETCH_REJECTED",
          data: error,
        });

      });
  }
};

/**
 *
 * @param userId
 * @returns {function(*): Promise<AxiosResponse<T> | never>}
 */
export const fetchUser = userId => {

  return async dispatch => {

    dispatch({type: "USER_FETCH"});

    const url = AxiosConfig.getEndpointAddress() + '/api/users/' + userId + '.json';

    await axios.get(
      url,
      AxiosConfig.getAuthConfig(),
    )
      .then((response) => {

        dispatch({
          type: "USER_FETCH_FULFILLED",
          data: response.data,
        });

      })
      .catch(function (error) {

        dispatch({
          type: "USER_FETCH_REJECTED",
          data: error,
        });

      });
  }
};

/**
 *
 * @param email
 * @param verify_token
 * @param role
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export const approve = (email, verify_token, role) => {

  return dispatch => {

    dispatch({ type: "USERS_APPROVE" });

    const url = AxiosConfig.getEndpointAddress() + "/api/users/accept.json";

    return axios.put(
      url,
      {
        email,
        verify_token,
        role,
      },
      AxiosConfig.getAuthConfig(),
    )
      .then((response) => {

        dispatch({
          type: "USERS_APPROVE_FULFILLED",
          data: {
            responseData: response.data,
            email,
            role,
          },
        });

      })
      .catch(function (error) {

        dispatch({
          type: "USERS_APPROVE_REJECTED",
          data: error,
        });

      });
  }
};

/**
 *
 * @param email
 * @param verify_token
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export const decline = (email, verify_token) => {

  return dispatch => {

    dispatch({ type: "USERS_DECLINE" });

    const url = AxiosConfig.getEndpointAddress() + "/api/users/reject.json";

    return axios.delete(
      url,
      {
        ...AxiosConfig.getAuthConfig(),
        data: {
          email: email,
          verify_token: verify_token,
        },
      },
    )
      .then((response) => {

        dispatch({
          type: "USERS_DECLINE_FULFILLED",
          data: {
            responseData: response.data,
            email,
          },
        });

      })
      .catch(function (error) {

        dispatch({
          type: "USERS_DECLINE_REJECTED",
          data: error,
        });

      });
  }
};

/**
 *
 * @param id
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export const deleteUser = (id) => {

  return dispatch => {

    dispatch({ type: "USERS_DELETE" });

    const url = AxiosConfig.getEndpointAddress() + "/api/users/" + id + ".json";

    return axios.delete(
      url,
      {//because of CORS error in another way
        ...AxiosConfig.getAuthConfig(),
      },
    )
      .then((response) => {

        dispatch({
          type: "USERS_DELETE_FULFILLED",
          data: {
            responseData: response.data,
            id,
          },
        });

      })
      .catch(function (error) {

        dispatch({
          type: "USERS_DELETE_REJECTED",
          data: error,
        });

      });
  }
};


/**
 *
 * @param id
 * @param data
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export const editUser = (id, data) => {

  return dispatch => {

    dispatch({type: "USER_EDIT"});

    const url = AxiosConfig.getEndpointAddress() + "/api/users/" + id + ".json";

    return axios.put(
      url,
      data,
      AxiosConfig.getAuthConfig(),
    )
      .then((response) => {

        dispatch({
          type: "USER_EDIT_FULFILLED",
          data: response.data,
        });

      })
      .catch(function (error) {

        dispatch({
          type: "USER_EDIT_REJECTED",
          data: error,
        });

      });
  }
};