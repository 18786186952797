import React, {Component} from 'react';
import {history} from "../configureStore";

import * as bidActions from "../actions/bid";
import connect from "react-redux/es/connect/connect";
import {withRouter} from "react-router-dom";

import {
  incrementShotNumber,
  extractDigits,
} from "../helpers/tools";

import ModalTemplate from './ModalTemplate';

import classNames from "classnames";
import CSVReader from 'react-csv-reader';
import csvHandler from '../helpers/csvImportHandler';
import CustomizableDataTable from "../components/CustomizableDataTable";
import FormValidationError from "../components/FormValidationError";

import LoadingIndicator from "../components/LoadingIndicator";

const formatError = {formatError: ["Please check file format is corresponding the table with example"]};

class CSVImportModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      parsedCSV: [],
      errors: null,
      formatError: null,
    };

    this.csvFormat = [
      {
        title: "Shot Name",
        key: "shot_number",
        pure: value => (value || '').trim(),
      },
      {
        title: "Description",
        key: "description",
        pure: value => (value || '').trim(),
      },
      {
        title: "VFX Process",
        key: "task_types",
        pure: value => {
          let trimmed =
            (value || '')
              .split(',')
              .map(item => (item || '').trim())
              .filter(item => !!item);
          const existingTasksTypes = this.props.tasksTypes.collection.map(item => item.name.toLowerCase());
          return trimmed.length ? trimmed.filter(item => existingTasksTypes.includes(item.toLocaleLowerCase())) : null;
        },
      },
      {
        title: "Notes",
        key: "notes",
        pure: value => (value || '').trim(),
      },
      {
        title: "Rate",
        key: "rate",
        pure: value => {
          let trimmed = (value || '').trim();
          return !isNaN(trimmed) ? trimmed : 0;
        },
        // validate: value => !isNaN(value),
        // errorMessage: 'Please check values in Rate column',
      },
      {
        title: "Hours",
        key: "hours",
        pure: value => {
          let trimmed = (value || '').trim();
          return !isNaN(trimmed) ? trimmed : 0;
        },
        // validate: value => !isNaN(value),
        // errorMessage: 'Please check values in Hours column',
      },
    ];

    this.csvDataTableConfig = {
      columns: [
        {
          name: this.csvFormat[0].title,
          selector: "shot_number",
        },
        {
          name: this.csvFormat[1].title,
          selector: "description",
        },
        {
          name: this.csvFormat[2].title,
          selector: "task_types",
          format: row => row.task_types ? row.task_types.join(', ') : null,
        },
        {
          name: this.csvFormat[3].title,
          selector: "notes",
        },
        {
          name: this.csvFormat[4].title,
          selector: "rate",
        },
        {
          name: this.csvFormat[5].title,
          selector: "hours",
          width: '100px',
        },
        {
          name: "",
          selector: "actions",
          cell: row => <i className="mi-close pointer" title="Remove row" onClick={this.onRemoveRowPress(row)}/>,
          center: true,
          width: '40px',
        }
      ],
    };
  }

  /**
   *
   */
  componentDidMount() {
    if (
      this.props.user.token &&
      this.props.user.model &&
      this.props.match.params.bidId &&
      !this.props.project.model
    ) {
      this.props.fetchBid(this.props.match.params.bidId);// before it loaded project and bid version
    }
  };

  /**
   *
   * @param data
   */
  onFileUpload = data => {
    this.clearErrors();
    if (csvHandler.validateHeader(data, this.csvFormat)) {
      let CSVRowsAsObjects = csvHandler.formatRowsToObjects(data.slice(1, data.length), this.csvFormat);
      let puredCSVRows = csvHandler.pureRows(CSVRowsAsObjects, this.csvFormat);
      const errors = csvHandler.validateRows(puredCSVRows, this.csvFormat);
      if (!errors) {
        let rowsWithoutEmpty = csvHandler.removeEmptyRows(puredCSVRows, this.csvFormat);
        let rowWithIndexes = this.setIndexes(rowsWithoutEmpty);
        this.setState({parsedCSV: this.fillShotNumbers(rowWithIndexes)});
      } else {
        this.setState({errors});
        this.clearFileInput();
      }
    } else {
      this.setState({formatError: formatError});
      this.clearFileInput();
    }
  };

  /**
   *
   * @param data
   * @returns {*}
   */
  setIndexes = data => {
    return data.map((item, index) => ({...item, index}));
  };
  
  /**
   *
   */
  clearFileInput = () => {
    document.querySelector('.js-csv-reader .dropzone-input-file').value = "";
  };

  /**
   *
   */
  clearErrors = () => {
    this.setState({
      errors: null,
      formatError: null,
    });
  };

  /**
   *
   */
  onConfirmPress = () => {
    history.push(`/bid/${this.props.match.params.bidId}`, {csvShots: [...this.state.parsedCSV]});
  };

  /**
   *
   * @param shot
   * @returns {Function}
   */
  onRemoveRowPress = shot => () => {
    this.setState({parsedCSV: this.state.parsedCSV.filter(item => item.index !== shot.index)});
  };

  /**
   *
   * @param data
   * @returns {*}
   */
  fillShotNumbers = data => {
    const shots = [...this.props.project.shots, ...data];
    const shotsNumbers = shots.map(item => item.shot_number);

    const shotsDigitsArr = shotsNumbers
      .map(i => {
        const digits = extractDigits(i).join("");
        return Number(digits);
      });

    let lastIncreasedShotNumber = '00000';

    return data.map((item, index) => {
      const shotToIncrement = {...item};
      if (shotToIncrement.shot_number) {
        return shotToIncrement;
      }

        if (!index) {
          const i = shotsDigitsArr.indexOf(Math.max(...shotsDigitsArr));
          const initialNumber =
            shots[i].shot_number || `${(this.props.project.model && this.props.project.model.code) || ''}_000`;
          shotToIncrement.shot_number = incrementShotNumber(initialNumber);
        } else {
          shotToIncrement.shot_number = incrementShotNumber(lastIncreasedShotNumber);
        }

      lastIncreasedShotNumber = shotToIncrement.shot_number;

      return shotToIncrement;
    });
  };

  /**
   *
   * @returns {XML}
   */
  render() {
    const isLoading = this.props.project.fetch || this.props.bid.fetch;
    const loadingIndicator = isLoading ? <LoadingIndicator/> : null;

    let csvDataTableColumns = this.csvDataTableConfig.columns;
    if (!this.state.parsedCSV.length) {
      csvDataTableColumns = this.csvDataTableConfig.columns.filter(item => item.selector !== 'actions');
    }

    const csvDataTable = (
      <CustomizableDataTable
        title={!this.state.parsedCSV.length ? "Proper CSV file format" : null}
        collection={this.state.parsedCSV}
        columns={csvDataTableColumns}
      />
    );

    const dropZone = !this.state.parsedCSV.length ? (
      <div className="dropzone js-csv-reader">
        <div className="dz-default dz-message">
          <span>Drop a file to upload <span>or CLICK</span></span>
        </div>
        <CSVReader
          cssInputClass="dropzone-input-file"
          onFileLoaded={this.onFileUpload}/>
      </div>
    ) : null;

    const modalClassName = classNames(
      {"modal-xl": this.state.parsedCSV.length},
      {"modal-lg": !this.state.parsedCSV.length},
    );

    return (
      <ModalTemplate
        title="CSV Import"
        onClose={this.props.onClose}
        confirmButtonLabel="Import Selected"
        disableConfirmButton={!this.state.parsedCSV.length || isLoading}
        onConfirm={this.onConfirmPress}
        cancelButtonLabel="Cancel"
        onCancel={this.props.onClose}
        className={modalClassName}
        loadingIndicator={loadingIndicator}
      >
        {!this.state.parsedCSV.length &&
          <p>
            Import the CSV file with bid shots. Refer to table below. It states the format of columns required to make the
            import successful.
          </p>
        }
        {csvDataTable}
        <FormValidationError errors={this.state.formatError || this.state.errors}/>
        {dropZone}
      </ModalTemplate>
    );
  }
}


const mapStateToProps = state => ({
  tasksTypes: state.tasksTypes,
  user: state.user,
  project: state.project,
  bid: state.bidVersion,
});

const mapDispatchToProps = dispatch => ({
  fetchBid: id => dispatch(bidActions.fetchBid(id)),// before it loaded project and bid version
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(CSVImportModal)
);
