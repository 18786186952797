/**
 * Created by piotr.pozniak@thebeaverhead.com on 19/05/2022
 */

import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const PanelTabs = (props) => {
  /**
   *
   * @param id
   * @returns {(function(*): void)|*}
   */
  const onTabClick = (id) => (e) => {
    if (typeof props.onTabClick === "function") {
      props.onTabClick(id);
    }
  };


  const tabsContent = useMemo(
    () =>
      props.tabs.map((i, idx) => {
        return (
          <li className="nav-item" key={`tp-${i.id}-${idx}`}>
            <a
              href={`#${i.id}`}
              className={classNames("nav-link", {
                active: i.id === props.defaultActiveTabId,
              })}
              data-toggle="tab"
              onClick={onTabClick(i.id)}
            >
              {i.label}
            </a>
          </li>
        );
      }),
    [props.tabs]
  );

  return <ul className="nav nav-tabs nav-tabs-highlight">{tabsContent}</ul>;
};

PanelTabs.defaultProps = {};

PanelTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  defaultActiveTabId: PropTypes.string,
  onTabClick: PropTypes.func,
};

export default PanelTabs;
