import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import Validator from 'validatorjs';

import ModalTemplate from './ModalTemplate';

import * as tasksTypesActions from "../actions/tasksTypes";
import connect from "react-redux/es/connect/connect";
import LoadingIndicator from '../components/LoadingIndicator';
import ApiError from '../components/ApiError';
import FormValidationError from '../components/FormValidationError';

const isDefaultDescription = 'When VFX task is marked as default,  it will be automatically added to every newly created shot on the bid page';

class TaskTypeEditModal extends Component {

  constructor(props) {
    super(props);

    this.state = {formValidationErrors: null};
  }

  /**
   *
   */
  componentDidMount() {
    if (this.isEditMode && this.props.tasksTypes.collection.length) {
      this.handleUpdateForm();
    }
  }

  /**
   *
   */
  componentDidUpdate() {
    // if (this.state.formValidationErrors === null) {
    //   this.handleUpdateForm();
    // }
  }

  /**
   *
   */
  get isEditMode() {
    return this.props.match.params.taskTypeId !== "new";
  }

  /**
   *
   */
  handleUpdateForm() {
    if (this.props.tasksTypes.collection.length) {
      document.querySelector('.js-task-type-edit-name').value = this.getCurrentTasksType().name;
      document.querySelector('.js-task-type-edit-is-default').checked = !!this.getCurrentTasksType().is_default;
    }
  }

  /**
   *
   * @returns {*}
   */
  getCurrentTasksType = () => {
    const tasksTypesCollection = this.props.tasksTypes.collection;
    const taskTypeId = this.props.match.params.taskTypeId;
    const editingTasksTypeIndex = tasksTypesCollection.findIndex(item => item.id === taskTypeId);
    return tasksTypesCollection[editingTasksTypeIndex];
  };

  /**
   *
   */
  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({formValidationErrors: null});

    const data = {
      name: document.querySelector('.js-task-type-edit-name').value,
      is_default: document.querySelector('.js-task-type-edit-is-default').checked ? 1 : 0,
    };

    const rules = {
      name: 'required|min:2',
    };
    const validation = new Validator(data, rules);

    this.setState({formValidationErrors: validation.errors.all()});

    if (validation.passes()) {
      if (this.isEditMode) {
        const {match: {params: {taskTypeId}}} = this.props;
        await this.props.editTaskType(taskTypeId, data);
      } else {
        await this.props.createTaskType(data);
      }

      if ((!this.isEditMode && this.props.tasksTypes.createSuccess) || (this.isEditMode && this.props.tasksTypes.editSuccess)) {
        this.props.onClose();
      }
    }

  };


  /**
   *
   * @returns {XML}
   */
  render() {
    const {formValidationErrors} = this.state;
    const {tasksTypes} = this.props;

    const isLoading = tasksTypes.create || tasksTypes.edit;
    const loadingIndicator = isLoading ? <LoadingIndicator/> : null;

    const currentTasksType = this.getCurrentTasksType();

    const modalTitle = this.isEditMode ? `Edit VFX task "${currentTasksType.name}"` : "Create new VFX task";
    const confirmButtonLabel = this.isEditMode ? "Update" : "Create";

    return (
      <ModalTemplate
        title={modalTitle}
        onClose={this.props.onClose}
        confirmButtonLabel={confirmButtonLabel}
        onConfirm={this.handleSubmit}
        cancelButtonLabel="Cancel"
        onCancel={this.props.onClose}
        loadingIndicator={loadingIndicator}
        disableConfirmButton={isLoading}
      >
        <form noValidate>

          <div className="form-group">
            <label className="form-label">
              VFX task name <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control js-task-type-edit-name"
              placeholder="VFX task name"
              disabled={isLoading}
            />
          </div>

          <div className="form-group">
            <div className="form-check">
              <label className="form-check-label">
                <input
                  type="checkbox"
                  className="form-check-input-styled js-task-type-edit-is-default"
                  data-fouc
                />
                Is default
              </label>
              <i
                className="icon-question4 ml-2 fs-14"
                data-popup="tooltip"
                data-original-title={isDefaultDescription}/>
            </div>
          </div>

          <FormValidationError errors={formValidationErrors}/>
          <ApiError error={tasksTypes.createError || tasksTypes.editError}/>

        </form>
      </ModalTemplate>
    );
  }
}

const mapStateToProps = state => ({
  tasksTypes: state.tasksTypes,
});

const mapDispatchToProps = dispatch => ({
  createTaskType: data => dispatch(tasksTypesActions.createTaskType(data)),
  editTaskType: (id, data) => dispatch(tasksTypesActions.editTaskType(id, data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(TaskTypeEditModal)
);
