export const initialState = {
  data: {},
};

/**
 *
 * @param store
 * @param id
 * @param data
 */
const updateRecord = (store, id, data) => {
  const storeData = store.data[id]
    ? {
        ...store.data[id],
        ...data,
      }
    : { ...data };

  const _store = {
    ...store,
  };

  _store.data[id] = storeData;

  return _store;
};

export default function reducer(
  state = {
    ...initialState,
  },
  action
) {
  switch (action.type) {
    case "BID_FETCH":
      return updateRecord(state, action.bidId, {
        bid: {
          fetch: true,
          fetchSuccess: false,
          fetchError: null,
        },
      });

    case "BID_FETCH_FULFILLED":
      return updateRecord(state, action.bidId, {
        bid: {
          model: action.data,
          fetch: false,
          fetchSuccess: true,
        },
      });

    case "BID_FETCH_REJECTED":
      return updateRecord(state, action.bidId, {
        bid: {
          fetch: false,
          fetchError: action.data,
        },
      });

    case "BID_INVOICES_FETCH":
      return updateRecord(state, action.bidId, {
        bidInvoices: {
          fetch: true,
          fetchSuccess: false,
          fetchError: null,
        },
      });

    case "BID_INVOICES_FETCH_FULFILLED":
      return updateRecord(state, action.bidId, {
        bidInvoices: {
          fetch: false,
          fetchSuccess: true,
          collection: action.data.invoices,
        },
      });

    case "BID_INVOICES_FETCH_REJECTED":
      return updateRecord(state, action.bidId, {
        bidInvoices: {
          fetch: false,
          fetchError: action.data,
        },
      });

    case "DEPOSIT_INVOICE_STATUS_EDIT":
      return updateRecord(state, action.bidId, {
        bidInvoices: {
          ...state.data[action.bidId].bidInvoices,
          editDepositInvoice: true,
          editDepositInvoiceSuccess: false,
          editDepositInvoiceError: null,
        },
      });

    case "DEPOSIT_INVOICE_STATUS_EDIT_FULFILLED":
      return updateRecord(state, action.bidId, {
        bidInvoices: {
          ...state.data[action.bidId].bidInvoices,
          editDepositInvoice: false,
          editDepositInvoiceSuccess: true,
        },
        bid: {
          model: {
            ...state.data[action.bidId].bid.model,
            ...action.data,
          },
        },
      });

    case "DEPOSIT_INVOICE_STATUS_EDIT_REJECTED":
      return updateRecord(state, action.bidId, {
        bidInvoices: {
          ...state.data[action.bidId].bidInvoices,
          editDepositInvoice: false,
          editDepositInvoiceSuccess: false,
          editDepositInvoiceError: action.data,
        },
      });

    case "BID_INVOICE_EDIT":
      return updateRecord(state, action.bidId, {
        bidInvoices: {
          ...state.data[action.bidId].bidInvoices,

          edit: true,
          editSuccess: false,
          editError: null,
        },
      });

    case "BID_INVOICE_EDIT_FULFILLED":
      return updateRecord(state, action.bidId, {
        bidInvoices: {
          collection: state.data[action.bidId].bidInvoices.collection.map(
            (i) => {
              return i.id === action.invoiceId ? action.data : i;
            }
          ),
          edit: false,
          editSuccess: true
        },
      });

    case "BID_INVOICE_EDIT_REJECTED":
      return updateRecord(state, action.bidId, {
        bidInvoices: {
          ...state.data[action.bidId].bidInvoices,
          edit: false,
          editError: action.data,
        },
      });

    /*


    case "INVOICES_FETCH":
      return {
        ...state,

        ...updateRecord(state, action.bidId, {
          fetch: true,
          fetchSuccess: false,
          fetchError: null,
        }),
      };

    case "INVOICES_FETCH_FULFILLED":
      return {
        ...state,

        ...updateRecord(state, action.bidId, {
          fetch: false,
          fetchSuccess: true,
          collection: action.data.bids,
          pagination: action.data.pagination,
        }),
      };

    case "INVOICES_FETCH_REJECTED":
      return {
        ...state,

        ...updateRecord(state, action.bidId, {
          fetch: false,
          fetchSuccess: false,
          fetchError: action.data,
        }),
      };
*/

    /*
    case "BID_EDIT_FULFILLED":
      let withEditedInvoiceCollection = state.collection.map((value) =>
        value.id === action.data.id ? action.data : { ...value }
      );
      return {
        ...state,
        collection: withEditedInvoiceCollection,
      };

    case "CLEAR_ERRORS":
      return {
        ...initialState,
        collection: state.collection,
        pagination: state.pagination,
      };*/

    case "LOGOUT":
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
