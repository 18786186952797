import React, { useMemo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { lightOrDark } from "../helpers/tools";
import { prettifyProperty } from "../helpers/error";
import { useStatusesStore } from "../storeHooks/statuses";

const StatusBadge = (props) => {
  const { statuses: _storeStatuses } = useStatusesStore();

  const statuses = props.statuses || _storeStatuses.collection;

  const status = useMemo(
    () => props.status && statuses.find((i) => i.value === props.status),
    [props.status, statuses]
  );

  let statusText = prettifyProperty(props.status || "");
  let statusStyle = {};
  let statusClassName = "badge badge-pill m-1";

  if (status && status.color && status.label) {
    const statusColor = "#" + status.color;
    statusText = status.label;
    statusStyle = { backgroundColor: statusColor };
    statusClassName = classNames(statusClassName, {
      "badge-light": lightOrDark(statusColor) === "light",
      "badge-dark": lightOrDark(statusColor) === "dark",
    });
  }

  return (
    <span className={statusClassName} style={statusStyle}>
      {statusText}
    </span>
  );
};

export const propTypes = {
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  statuses: PropTypes.arrayOf(PropTypes.object),
};

StatusBadge.propTypes = propTypes;

export default StatusBadge;
