import axios from "axios";
import AxiosConfig from "../AxiosConfig";
import allSettled from "@ungap/promise-all-settled";

/**
 * polyfill allSettled
 */
if (!Promise.allSettled) {
  Promise.allSettled = allSettled;
}

/**
 *
 * @param shotId
 * @param data
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export const fetchTasks = (shotId) => {
  return async (dispatch) => {
    dispatch({ type: "TASK_FETCH", shotId });

    const url =
      AxiosConfig.getEndpointAddress() + `/api/tasks/shot/${shotId}.json`;

    await axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "TASK_FETCH_FULFILLED",
          data: response.data,
          shotId,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "TASK_FETCH_REJECTED",
          data: error,
          shotId,
        });
      });
  };
};

/**
 *
 * @param shotId
 * @param data
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export const createTask = (shotId, data) => {
  return async (dispatch) => {
    dispatch({ type: "TASK_CREATE" });

    const url = AxiosConfig.getEndpointAddress() + "/api/tasks.json";

    data.shot_id = shotId;

    await axios
      .post(url, data, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "TASK_CREATE_FULFILLED",
          data: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "TASK_CREATE_REJECTED",
          data: error,
        });
      });
  };
};

/**
 *
 * @param taskId
 * @param data
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export const editTask = (taskId, data) => {
  return async (dispatch) => {
    dispatch({ type: "TASK_EDIT" });

    const url =
      AxiosConfig.getEndpointAddress() + "/api/tasks/" + taskId + ".json";

    await axios
      .put(url, data, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "TASK_EDIT_FULFILLED",
          data: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "TASK_EDIT_REJECTED",
          data: error,
        });
      });
  };
};

/**
 *
 * @param taskId
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export const deleteTask = (taskId) => {
  return (dispatch) => {
    dispatch({ type: "TASK_DELETE" });

    const url =
      AxiosConfig.getEndpointAddress() + "/api/tasks/" + taskId + ".json";

    return axios
      .delete(url, { ...AxiosConfig.getAuthConfig() })
      .then((response) => {
        dispatch({
          type: "TASK_DELETE_FULFILLED",
          data: {
            responseData: response.data,
            taskId,
          },
        });
      })
      .catch(function (error) {
        dispatch({
          type: "TASK_DELETE_REJECTED",
          data: error,
        });
      });
  };
};


/**
 *
 * @param tasks
 * @param assigneeId
 * @returns {function(*): Promise<AxiosResponse<any>>}
 */
export const changeAssignee = (tasks, assigneeId) => {
  return (dispatch) => {
    dispatch({ type: "TASKS_CHANGE_ASSIGNEE" });

    const url =
      AxiosConfig.getEndpointAddress() + "/api/tasks/change_assignee.json";

    return axios
      .put(
        url,
        { assignee_id: assigneeId, tasks },
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "TASKS_CHANGE_ASSIGNEE_FULFILLED",
          data: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "TASKS_CHANGE_ASSIGNEE_REJECTED",
          data: error,
        });
      });
  };
};
