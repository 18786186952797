/**
 * Created by piotr.pozniak@thebeaverhead.com on 09/07/2024
 */

import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import DataGridPagination from "../../components/DataGridPagination";
import CustomizableDataTable from "../../components/CustomizableDataTable";
import StaticAlert from "../../components/StaticAlert";
import { useBidsStore } from "../../storeHooks/bids";
import StatusBadge from "../../components/StatusBadge";
import { filterComparisonAND, formatter } from "../../helpers/tools";
import BidVersionName from "../../components/BidVersionName";
import DropdownMenu from "../../components/DropdownMenu";
import { useStatusesStore } from "../../storeHooks/statuses";
import { useBidStore } from "../../storeHooks/bid";
import { useBidVersionStore } from "../../storeHooks/bidVersion";
import { useProjectStore } from "../../storeHooks/project";
import { useParams } from "react-router-dom";
import { history } from "../../configureStore";
import TotalFormat from "../BidsPage/TotalFormat";

const clientBidsDataTableConfig = ({
  projectsStatuses,
  bidsStatuses,
  handleBidsDataTableRowClick,
  onEditBidDetails,
}) => ({
  perPage: 10,
  columns: [
    {
      name: "Project - Bid",
      selector: "name",
      sortable: true,
      format: (row) =>
        row && row.recent_bid_version && row.project ? (
          <a
            href="!#"
            className="relative"
            onClick={handleBidsDataTableRowClick(row)}
          >
            {row.project.name} - {row.name}
          </a>
        ) : (
          <span>{row.name}</span>
        ),
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      format: (row) =>
        row.status ? (
          <StatusBadge status={row.status} statuses={bidsStatuses} />
        ) : (
          "--"
        ),
    },
    {
      name: "Required hours",
      selector: "project.required_hours",
      format: (row) =>
        row.project && row.project.required_hours
          ? Math.round((row.project.required_hours || 0) * 100) / 100
          : "--",
      center: true,
    },
    {
      name: "Total",
      selector: "total",
      sortable: true,
      format: TotalFormat,
      center: true,
    },
    {
      name: "Current version",
      selector: "recent_bid_version.create_order",
      format: (row) => (
        <div className="text-center">
          <BidVersionName bidVersion={row.recent_bid_version} />
        </div>
      ),
      maxWidth: "100px",
      center: true,
    },
    {
      name: "Actions",
      right: true,
      width: "70px",
      cell: (row) => {
        let actionButtons = [
          {
            icon: "mi-mode-edit",
            action: onEditBidDetails,
            data: row,
            label: "Edit details",
          },
        ];
        if (row && row.status === "new") {
          actionButtons.push({
            icon: "mi-money-off",
            action: this.onDeleteBid,
            data: row,
            label: "Delete",
          });
        }
        return <DropdownMenu buttons={actionButtons} />;
      },
    },
  ],
  filters: [
    {
      placeholder: "Project name",
      defaultValue: "",
      fieldName: "name",
      type: "text",
    },
    {
      data: projectsStatuses,
      placeholder: "Choose status",
      fieldName: "status",
      type: "select",
    },
  ],
});

const BidsTable = (props) => {
  const { bids, fetchBids } = useBidsStore();
  const { setEditBid } = useBidStore();
  const { setBidVersion } = useBidVersionStore();
  const { statuses } = useStatusesStore();
  const { setEditProject } = useProjectStore();
  const { deleteProject } = useProjectStore();
  const params = useParams();

  const [filteredBidsCollection, setFilteredBidsCollection] = useState(null);
  const [bidsFilter, setBidsFilter] = useState([]);

  const pureBidsCollection = bids.collection;

  /**
   *
   * @param bid
   * @returns {function(...[*]=)}
   */
  const handleBidsDataTableRowClick = (bid) => async (event) => {
    event.preventDefault();
    const recentBidVersion = bid.recent_bid_version;
    setEditProject(bid.project);
    setBidVersion(recentBidVersion);

    const url = "/bid/" + recentBidVersion.id;
    history.push(url);
  };

  /**
   *
   * @param bid
   */
  const onEditBidDetails = (bid) => {
    setEditBid(bid);
    setEditProject(bid.project);
    history.push(`/project-bid/edit/${bid.id}`);
  };

  /**
   *
   * @param project
   * @returns {Promise<void>}
   */
  const onDeleteBid = async (project) => {
    if (
      window.confirm("Are you sure you want to delete " + project.name + "?")
    ) {
      deleteProject(project.id);
      onBidTableFilter(bidsFilter);
    }
  };

  const tableConfig = useMemo(
    () =>
      clientBidsDataTableConfig({
        handleBidsDataTableRowClick,
        onEditBidDetails,
        projectsStatuses: statuses.collection.filter(
          (i) => i.type === "Projects"
        ),
        bidsStatuses: statuses.collection.filter((i) => i.type === "Bids"),
      }),

    []
  );

  useEffect(() => {
    fetchBids(1, tableConfig.perPage, {
      clients: [params.id],
      sort_by: "name",
    });
  }, []);

  /**
   *
   * @param filter
   */
  const onBidTableFilter = (filter) => {
    const collection = this.props.bids.collection;
    let arResult = collection
      ? collection.filter((item) => {
          return filterComparisonAND(item, filter);
        })
      : collection;
    setBidsFilter(filter);
    setFilteredBidsCollection(arResult);
  };

  /**
   *
   * @param page
   */
  const onChangeBidTablePage = (page) => {
    fetchBids(page, tableConfig.perPage, {
      clients: [params.id],
    });
  };

  /**
   *
   */
  const onBidsTableClearFilter = () => {
    setBidsFilter([]);
    setFilteredBidsCollection(null);
  };

  const bidsCollection = filteredBidsCollection || pureBidsCollection;

  const bidsTablePagination =
    pureBidsCollection && pureBidsCollection.length ? (
      <DataGridPagination
        onChangePage={onChangeBidTablePage}
        pagination={bids.pagination}
      />
    ) : null;

  const bidsTable = bidsCollection ? (
    <CustomizableDataTable
      headerComponent={<h5 className={"card-title"}>Bids</h5>}
      collection={bidsCollection}
      columns={tableConfig.columns}
      showFilters={false}
      filters={tableConfig.filters}
      onFilter={onBidTableFilter}
      onClearAll={onBidsTableClearFilter}
      footerComponent={bidsTablePagination}
      defaultSortField={"name"}
    />
  ) : null;

  const bidsTableAlert = pureBidsCollection.length === 0 && (
    <StaticAlert
      header={"No records to display"}
      body={"This table lists all the bids."}
    />
  );

  return (
    <div className="row mb-3">
      <div className="col">
        {bidsTable}
        {bidsTableAlert}
      </div>
    </div>
  );
};

BidsTable.defaultProps = {};

BidsTable.propTypes = {};

export default BidsTable;
