import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const colorsList = [
  "bg-danger",
  "bg-success",
  "bg-info",
  "bg-warning",
  "bg-primary",
];

const ProgressBar = (props) => {
  const progressClassNames = classNames("progress", props.progressClassNames);

  const isTextHidden = props.isTextHidden;

  return (
    <div className={progressClassNames}>
      {props.progressAr.map((item, index) => {
        const progressBarItemClassName = classNames(
          "progress-bar",
          "progress-bar_ellipsis",
          item.color
            ? item.color
            : colorsList[
                index -
                  colorsList.length * Math.floor(index / colorsList.length)
              ]
        );

        const progressBarItemStyle = {
          width: item.percent,
        };

        if (item.color && !colorsList.includes(item.color)) {
          progressBarItemStyle.background = "#" + item.color;
        }

        return (
          <div
            className={progressBarItemClassName}
            style={progressBarItemStyle}
            key={index}
            title={item.text}
          >
            {!isTextHidden && item.text}
            <span className="sr-only" />
          </div>
        );
      })}
    </div>
  );
};

ProgressBar.propTypes = {
  progressAr: PropTypes.array.isRequired,
  progressClassNames: PropTypes.string,
  isTextHidden: PropTypes.bool,
};

export default ProgressBar;
