import React, { Component, useEffect, useMemo, useState } from "react";
import { withRouter } from "react-router-dom";
import Validator from "validatorjs";

import ModalTemplate from "./ModalTemplate";

import connect from "react-redux/es/connect/connect";
import LoadingIndicator from "../components/LoadingIndicator";
import ApiError from "../components/ApiError";
import FormValidationError from "../components/FormValidationError";
import { useBidStore } from "../storeHooks/bid";
import { useBidInvoiceStore } from "../storeHooks/bidInvoice";
import { useBidsInvoiceStore } from "../storeHooks/bidsInvoice";
import { store } from "../configureStore";
import { useStatusesStore } from "../storeHooks/statuses";

const DepositInvoiceEditModal = (props) => {
  const [state, setState] = useState({
    formValidationErrors: null,
  });

  const { bid, fetchBid, editBid } = useBidStore();
  const { bidInvoices } = useBidInvoiceStore();
  const { depositInvoicePDF } = useBidsInvoiceStore();
  const { statuses } = useStatusesStore();

  const invoiceStatuses = useMemo(
    () => statuses.collection.filter((i) => i.type === "Invoices"),
    [statuses.collection]
  );

  useEffect(() => {
    const {
      match: {
        params: { bidId },
      },
    } = props;

    if (!bid.model.id) {
      fetchBid(bidId);
    }
  }, []);

  /**
   *
   */
  const handleSubmit = async (event) => {
    event.preventDefault();

    setState({ ...state, formValidationErrors: null });

    const data = {
      deposit_po_number: document.querySelector(
        ".js-deposit-invoice-edit-po-number"
      ).value,
      deposit_invoice_status: invoiceStatuses[0].value,
    };

    const rules = {
      deposit_po_number: "required",
    };
    const validation = new Validator(data, rules);

    setState({ ...state, formValidationErrors: validation.errors.all() });

    if (validation.passes()) {
      if (props.match.params.mode === "create") {
        await editBid(bid.model.id, data);
        await downloadDepositInvoicePDF();
      }

      const bidInvoicesStore = store.getState().bidInvoices;
      const bidStore = store.getState().bid;
      if (
        props.match.params.mode === "create" &&
        bidStore.editSuccess &&
        bidInvoicesStore.createPdfSuccess
      ) {
        props.onClose();
      }
    }
  };

  /**
   *
   */
  const downloadDepositInvoicePDF = async () => {
    const bidModel = bid.model;
    const fileName = `Deposit invoice ${bidModel.project.code} - ${bidModel.name}`;
    await depositInvoicePDF(bidModel.id, fileName, bidModel.deposit);
  };

  const { formValidationErrors } = state;
  const bidModel = bid.model;

  const isLoading = bid.fetch || bid.edit || bidInvoices.createPdf;
  const loadingIndicator = isLoading ? <LoadingIndicator /> : null;

  const modalTitle =
    "Issue deposit Invoice " +
    (bidModel.project ? `for ${bidModel.project.name} - ${bidModel.name}` : "");
  const confirmButtonLabel =
    bid && bid.model.deposit_po_number && !isLoading
      ? null
      : "Issue deposit Invoice";

  const hasDepositWarning =
    bid && bid.model.deposit_po_number && !isLoading ? (
      <span>
        <div className="font-weight-semibold">Deposit invoice is issued.</div>
        To issue new one - delete current deposit invoice and issue a new
        deposit invoice.
      </span>
    ) : null;

  const bidHasNoDeposit =
    bid && !bid.model.deposit && !isLoading ? (
      <span>
        <div className="font-weight-semibold">No deposit for selected bid.</div>
        To issue a deposit invoice, first add deposit to the bid.
      </span>
    ) : null;

  const formFields = bid.model ? (
    hasDepositWarning || bidHasNoDeposit ? (
      <>
        {hasDepositWarning}
        {bidHasNoDeposit}
      </>
    ) : (
      <div className="form-group row">
        <label className="col-form-label col-3">PO Number</label>
        <div className="col-9">
          <input
            type="text"
            className="form-control js-deposit-invoice-edit-po-number"
            disabled={isLoading}
          />
        </div>
      </div>
    )
  ) : null;

  return (
    <ModalTemplate
      title={modalTitle}
      onClose={props.onClose}
      confirmButtonLabel={confirmButtonLabel}
      onConfirm={handleSubmit}
      cancelButtonLabel="Cancel"
      onCancel={props.onClose}
      loadingIndicator={loadingIndicator}
      disableConfirmButton={isLoading || !bid.model}
      className="modal-md"
    >
      <form noValidate>
        {formFields}

        <FormValidationError errors={formValidationErrors} />
        <ApiError
          error={bid.fetchError || bid.editError || bidInvoices.createPdfError}
        />
      </form>
    </ModalTemplate>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DepositInvoiceEditModal));
