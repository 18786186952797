export const initialState = {
  fetch: false,
  fetchSuccess: false,
  fetchError: null,

  collection: [],
  pagination: null,
};

export default function reducer(
  state = {
    ...initialState
  },
  action
) {
  switch (action.type) {
    case "CLIENT_PROJECTS_FETCH":
      return {
        ...state,
        fetch: true,
        fetchSuccess: false,
        fetchError: null,
      };

    case "CLIENT_PROJECTS_FETCH_FULFILLED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: true,

        collection: action.data.projects,
        pagination: action.data.pagination,
      };

    case "CLIENT_PROJECTS_FETCH_REJECTED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: false,
        fetchError: action.data,
      };

    case "PROJECT_EDIT_FULFILLED":
      let withEditedProjectCollection = state.collection.map((value) => {
        return value.id === action.data.id ? action.data : {...value};
      });
      return {
        ...state,
        collection: withEditedProjectCollection,
      };

    case "CLEAR_ERRORS":
      return {
        ...initialState,
        collection: state.collection,
        pagination: state.pagination,
      };

    case "LOGOUT":
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
