/**
 * Created by piotr.pozniak@thebeaverhead.com on 29/10/2018.
 */

import React, {useEffect, useState} from "react";

import { SketchPicker } from "react-color";
import PropTypes from "prop-types";

const ColorSelector = (props) => {
  const [showPicker, setShowPicker] = useState(false);

  const id = "color-picker-" + props.id;

  useEffect(() => {
    const handleClickOutsidePicker = (event) => {
      const pickerContainer = document.querySelector(`#${id}`);

      if (!pickerContainer.contains(event.target)) {
        setShowPicker(false);
      }
    };

    document.addEventListener("click", handleClickOutsidePicker);

    return () => {
      document.removeEventListener("click", handleClickOutsidePicker);
    };
  }, []);
  /**
   *
   */
  const onShowPicker = () => {
    setShowPicker(true);
  };

  const colorPicker =
    showPicker && !props.disabled ? (
      <div className="color-picker-popover">
        <div className="color-picker-cover">
          <SketchPicker
            onChange={props.onColorChange}
            color={props.color}
            disableAlpha={false}
          />
        </div>
      </div>
    ) : null;

  return (
    <div className="color-picker-container" id={id}>
      <div
        className={
          "color-picker-preview " + (props.disabled ? "disabled" : "")
        }
        onClick={onShowPicker}
      >
        <div
          className="color-picker-preview-color"
          style={{ background: props.color }}
        />
      </div>
      {colorPicker}
    </div>
  );
};

ColorSelector.propTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
  onColorChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default ColorSelector;
