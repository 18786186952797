import axios from "axios";
import AxiosConfig from "../AxiosConfig";
import allSettled from "@ungap/promise-all-settled";

/**
 * polyfill allSettled
 */
if (!Promise.allSettled) {
  Promise.allSettled = allSettled;
}

/**
 *
 * @param type
 * @param quickStatsCommonParams
 * @param dashboardCommonParams
 * @returns {function(*): Promise<*|*|undefined>}
 */
export const fetchDashboard = (
  type,
  quickStatsCommonParams = {},
  dashboardCommonParams = {}
) => {
  return async (dispatch) => {
    switch (type) {
      case "client":
        return await dispatch(fetchClientDashboard());

      case "common": {
        const promises = [
          dispatch(fetchCommonDashboards(dashboardCommonParams)),
          dispatch(fetchCommonQuickStats(quickStatsCommonParams)),
        ];
        return await Promise.allSettled(promises);
      }

      default:
        return;
    }
  };
};

/**
 *
 * @param type
 * @param quickStatsCommonParams
 * @returns {function(*): Promise<*|*|undefined>}
 */
export const fetchQuickStats = (type, quickStatsCommonParams = {}) => {
  return async (dispatch) => {
    switch (type) {
      case "client":
        return await dispatch(fetchClientDashboard());

      case "common": {
        return await dispatch(fetchCommonQuickStats(quickStatsCommonParams));
      }

      default:
        return;
    }
  };
};

/**
 *
 * @param params
 * @returns {function(*): void}
 */
export const fetchCommonDashboards = (params = {}) => {
  return async (dispatch) => {
    dispatch({ type: "FETCH_COMMON_DASHBOARDS" });

    const { tablesTypes, ...sentParams } = params;

    let promises = [];

    for (let type in tablesTypes) {
      if (tablesTypes[type]) {
        promises.push(
          dispatch(fetchCommonDashboard({ ...sentParams, type: type }))
        );
      }
    }
    return await Promise.allSettled(promises).then(() => {
      dispatch({ type: "FETCH_COMMON_DASHBOARDS_FULFILLED" });
    });
  };
};

/**
 *
 * @param params
 * @returns {function(*): Promise<void>}
 */
export const fetchCommonDashboard = (params = {}) => {
  return async (dispatch) => {
    dispatch({ type: "FETCH_COMMON_DASHBOARD" });

    let sentParams = { ...params };
    if (!sentParams.page) {
      sentParams.page = 1;
    }
    if (!sentParams.limit) {
      sentParams.limit = 20;
    }

    const url =
      AxiosConfig.getEndpointAddress() +
      "/api/dashboard.json?" +
      AxiosConfig.objectToURLQuery(params);

    await axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "FETCH_COMMON_DASHBOARD_FULFILLED",
          data: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "FETCH_COMMON_DASHBOARD_REJECTED",
          data: error,
        });
      });
  };
};

/**
 *
 * @returns {Function}
 */
export const fetchClientDashboard = () => {
  return async (dispatch) => {
    dispatch({ type: "FETCH_CLIENT_DASHBOARD" });

    const url = AxiosConfig.getEndpointAddress() + "/api/dashboard.json";

    await axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "FETCH_CLIENT_DASHBOARD_FULFILLED",
          data: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "FETCH_CLIENT_DASHBOARD_REJECTED",
          data: error,
        });
      });
  };
};

/**
 *
 * @param params
 * @returns {function(*): Promise<void>}
 */
export const fetchCommonQuickStats = (params) => {
  return async (dispatch) => {
    dispatch({ type: "FETCH_QUICK_STATS" });

    const url =
      AxiosConfig.getEndpointAddress() +
      "/api/dashboard/quick_stats.json?" +
      AxiosConfig.objectToURLQuery(params);

    await axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "FETCH_QUICK_STATS_FULFILLED",
          data: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "FETCH_QUICK_STATS_REJECTED",
          data: error,
        });
      });
  };
};

/**
 *
 * @param artist_id
 * @returns {function(...[*]=)}
 */
export const fetchParticularUserChart = (artist_id) => {
  return async (dispatch) => {
    dispatch({ type: "FETCH_PARTICULAR_USER_CHART" });

    const sent = {};
    if (artist_id) {
      sent.artist_id = artist_id;
    }

    const url =
      AxiosConfig.getEndpointAddress() +
      "/api/dashboard/quick_stats.json?" +
      AxiosConfig.objectToURLQuery(sent);

    await axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "FETCH_PARTICULAR_USER_CHART_FULFILLED",
          data: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "FETCH_PARTICULAR_USER_CHART_REJECTED",
          data: error,
        });
      });
  };
};

/**
 *
 * @returns {function(*): void}
 */
export const resetCommonDashboard = () => {
  return (dispatch) => {
    dispatch({ type: "RESET_COMMON_DASHBOARD" });
  };
};

/**
 *
 * @param artist_id
 * @returns {function(...[*]=)}
 */
export const fetchWorkloadDashboard = (
  type,
  endpoint,
  artistId = null,
  status = null,
  clientId = null,
  projectId = null
) => {
  return async (dispatch) => {
    dispatch({ type: `${type}_FETCH` });

    const params = {
      artist_id: artistId,
      shot_status: status,
      client_id: clientId,
      project_id: projectId,
    };

    const url =
      AxiosConfig.getEndpointAddress() +
      `/api/dashboard/${endpoint}.json?` +
      AxiosConfig.objectToURLQuery(params);

    await axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: `${type}_FETCH_FULFILLED`,
          data: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: `${type}_FETCH_REJECTED`,
          data: error,
        });
      });
  };
};

/**
 *
 * @param artist_id
 * @returns {function(...[*]=)}
 */
export const fetchArtistsWorkload = (
  artistId = null,
  status = null,
  clientId = null,
  projectId = null
) => {
  return fetchWorkloadDashboard(
    "ARTISTS_STATISTICS",
    "artists_statistics",
    artistId,
    status,
    clientId,
    projectId
  );
};

/**
 *
 * @param artistId
 * @param status
 * @param clientId
 * @param projectId
 * @returns {(function(*): Promise<void>)|*}
 */
export const fetchProjectsWorkload = (
  artistId = null,
  status = null,
  clientId = null,
  projectId = null
) => {
  return fetchWorkloadDashboard(
    "PROJECT_STATISTICS",
    "projects_statistics",
    artistId,
    status,
    clientId,
    projectId
  );
};
