import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Validator from "validatorjs";
import {
  bidPDFFilenameVariables,
  invoicePDFFilenameVariables,
} from "../../consts/pdfFilenamesVariables";
import ModalTemplate from "../ModalTemplate";
import * as projectActions from "../../actions/project";
import * as bidActions from "../../actions/bid";
import * as clientActions from "../../actions/client";
import * as supplierActions from "../../actions/supplier";
import * as appActions from "../../actions/app";
import connect from "react-redux/es/connect/connect";
import { store } from "../../configureStore";
import ProjectForm from "./components/ProjectForm";
import BidForm from "./components/BidForm";
import LoadingIndicator from "../../components/LoadingIndicator";
import {
  inputSetDateAsValue,
  preformProjectCode,
  getSettingsByNames,
  validateVariablesString,
} from "../../helpers/tools";
import { fetchAdditionalCosts } from "../../actions/additionalCosts";

const statusCreatingValue = "bid_in_progress";

class BidEditModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      projectFormValidationErrors: null,
      projectFormValidationCustomErrors: null,
      bidFormValidationErrors: null,
      useExistingProject: false,
    };
  }

  /**
   *
   */
  async componentDidMount() {
    const {
      project,
      bid,
      match: {
        params: { id },
      },
    } = this.props;

    if (this.entityTypeMode === "project") {
      if (
        (this.isEditMode && !project.model) ||
        (this.isEditMode && project.model && project.model.id !== id)
      ) {
        this.props.fetchProject(id);
      } else if (project.model && project.model.id !== id) {
        this.props.clearProject();
      } else {
        this.handleUpdateForm();
      }
    } else {
      if (
        (this.isEditMode && !bid.model) ||
        (this.isEditMode && bid.model && bid.model.id !== id)
      ) {
        await this.props.fetchBid(id);
        this.props.fetchProject(store.getState().bid.model.project.id);
      } else if (bid.model && bid.model.id !== id) {
        this.props.setEditBid({});
        this.props.clearProject();
      } else {
        this.handleUpdateForm();
      }
    }

    this.props.fetchAdditionalCosts();

    if (!this.isEditMode) {
      this.fetchSupplierSettings();
    }
  }

  /**
   *
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.state.projectFormValidationErrors === null &&
      this.state.projectFormValidationCustomErrors === null &&
      this.state.bidFormValidationErrors === null
    ) {
      this.handleUpdateForm();
      if (!this.isEditMode) {
        this.setDefaultPDFFilenames();
      }
    }
  }

  /**
   *
   */
  fetchSupplierSettings = () => {
    if (this.props.user.token && this.props.user.model) {
      this.props.fetchSupplierSettings();
    }
  };

  /**
   *
   * @param namesAr
   * @returns {*}
   */
  getSettingsByNames = (namesAr) => {
    return this.props.supplier.model && this.props.supplier.model.settings
      ? getSettingsByNames(namesAr, this.props.supplier.model.settings)
      : {};
  };

  /**
   *
   * @param rates
   * @returns {{rate: *, additional_costs_setting_id: *, days: number}[]}
   */
  convertVfxDefaultRates = (additionalCosts) => {
    return additionalCosts
      .filter((i) => i.available)
      .map((i) => ({
        additional_costs_setting_id: i.id,
        rate: i.default_value,
        days: 0,
      }));
  };

  /**
   *
   */
  setDefaultPDFFilenames = () => {
    const defaultPDFFilenames = this.getSettingsByNames([
      "supplier.default_bid_pdf_filename",
      "invoices.default_invoice_pdf_filename",
    ]);

    document.querySelector(".js-project-edit-bid-pdf-filename").value =
      defaultPDFFilenames.default_bid_pdf_filename || "";
    document.querySelector(".js-project-edit-invoice-pdf-filename").value =
      defaultPDFFilenames.default_invoice_pdf_filename || "";
  };

  /**
   *
   * @returns {boolean}
   */
  get isEditMode() {
    return this.props.match.params.id !== "new";
  }

  /**
   *
   * @returns {string}
   */
  get entityTypeMode() {
    return this.props.match.params.editEntity === "project" ? "project" : "bid";
  }

  /**
   *
   */
  handleUpdateForm() {
    if (this.props.project.model !== null) {
      const {
        name,
        code,
        due_date,
        status,
        description,
        is_open,
        bid_pdf_filename,
        invoice_pdf_filename,
      } = this.props.project.model;

      document.querySelector(".js-project-edit-name").value = name;
      document.querySelector(".js-project-edit-code").value = code;

      const input = document.querySelector(".js-project-edit-due-date");
      inputSetDateAsValue(input, due_date);

      document.querySelector(".js-project-edit-status").value =
        this.isEditMode || this.state.useExistingProject
          ? status
          : statusCreatingValue;
      document.querySelector(".js-project-edit-description").value =
        description;

      document.querySelector(".js-project-edit-is-open").checked =
        Boolean(is_open);

      if (this.isEditMode) {
        document.querySelector(".js-project-edit-bid-pdf-filename").value =
          bid_pdf_filename;

        document.querySelector(".js-project-edit-invoice-pdf-filename").value =
          invoice_pdf_filename;
      }

      window.updateJQuery();
    }

    if (this.props.bid.model !== null) {
      const { name, use_shots_as_hours_bid, show_rate_in_bid_pdf } =
        this.props.bid.model;

      const nameInput = document.querySelector(".js-bid-edit-name");
      if (nameInput) {
        nameInput.value = name || "";
      }

      const useShotsAsHoursInput = document.querySelector(
        ".js-bid-edit-use-shots-as-hours-bid"
      );
      if (useShotsAsHoursInput) {
        useShotsAsHoursInput.checked = Boolean(use_shots_as_hours_bid);
      }

      const showRateInBidPdf = document.querySelector(
        ".js-bid-edit-show-rate-in-bid-pdf"
      );
      if (showRateInBidPdf) {
        showRateInBidPdf.checked = Boolean(show_rate_in_bid_pdf);
      }

      window.updateJQuery();
    }
  }

  /**
   *
   */
  handleSubmit = async (event) => {
    /** preparations */
    event.preventDefault();

    const createMode = !this.isEditMode;
    const projectEditMode =
      this.isEditMode && this.entityTypeMode === "project";
    const bidEditMode = this.isEditMode && this.entityTypeMode === "bid";

    this.setState({
      projectFormValidationErrors: null,
      bidFormValidationErrors: null,
    });

    /** project data */
    const projectData =
      createMode || projectEditMode ? this.getProjectData() : null;

    /** bid data */
    const bidData = createMode || bidEditMode ? this.getBidData() : null;

    /** project validation rules */
    const isNewCustomer = !!this._newCustomerName;

    const projectRules =
      createMode || projectEditMode
        ? {
            name: "required",
            invoice_pdf_filename: "required",
            bid_pdf_filename: "required",
          }
        : {};
    if (!isNewCustomer && (createMode || projectEditMode)) {
      projectRules.client_id = "required";
    }
    if (
      projectEditMode &&
      this.props.project.model &&
      this.props.project.model.tsheets_job_id
    ) {
      projectRules.code = "required";
    }
    const projectValidation = new Validator(projectData, projectRules);

    let customProjectErrors =
      createMode || projectEditMode
        ? this.validateVariablesStrings(projectData, null)
        : null;

    /** bid validation rules */
    const bidRules =
      createMode || bidEditMode
        ? {
            name: "required",
          }
        : {};
    const bidValidation = new Validator(bidData, bidRules);

    /** set validation */
    this.setState({
      projectFormValidationErrors: projectValidation.errors.all(),
      projectFormValidationCustomErrors: customProjectErrors,
      bidFormValidationErrors: bidValidation.errors.all(),
    });

    /** handle update */
    if (
      projectValidation.passes() &&
      !customProjectErrors &&
      bidValidation.passes()
    ) {
      if (isNewCustomer) {
        await this.props.createClient({ name: this._newCustomerName });
      }
      if (
        isNewCustomer &&
        this.props.client.model &&
        this.props.client.model.id
      ) {
        projectData.client_id = this.props.client.model.id;
      }

      if (this.isEditMode && this.entityTypeMode === "project") {
        /** edit project mode */
        await this.props.editProject(this.props.match.params.id, projectData);
      } else if (this.isEditMode && this.entityTypeMode === "bid") {
        /** edit bid mode */
        await this.props.editBid(this.props.match.params.id, bidData);
      } else {
        /** common create mode */

        /** create project */
        if (!this.state.useExistingProject) {
          await this.props.createProject(projectData);
          if (!this.isEditMode && this.props.project.createError) {
            return;
          }
        }


        if (this.props.project) {

          await this.props.createBid(this.props.project.model.id, {
            ...bidData,
            bid_version: {

              bid_versions_additional_costs: this.convertVfxDefaultRates(
                this.props.additionalCosts.collection
              ),
            },
          });
        }
      }

      this.closeModalIfNoErrors();
    }
  };

  /**
   *
   */
  closeModalIfNoErrors = () => {
    if (
      (!this.isEditMode &&
        !this.props.project.createError &&
        !this.props.bid.createError) ||
      (this.isEditMode &&
        this.entityTypeMode === "project" &&
        !this.props.project.editError) ||
      (this.isEditMode &&
        this.entityTypeMode === "bid" &&
        !this.props.bid.editError)
    ) {
      this.props.onClose();
    }
  };

  /**
   *
   * @returns {{code: *, invoice_pdf_filename: *, bid_pdf_filename: *, is_open: number, name: *, due_date: (number|null), description: *, client_id: *, status: *}}
   */
  getProjectData = () => ({
    name: document.querySelector(".js-project-edit-name").value,
    code: document.querySelector(".js-project-edit-code").value,
    due_date:
      +new Date(document.querySelector(".js-project-edit-due-date").value) /
        1000 || null,
    client_id:
      this._selectedCustomerId ||
      (this.props.project.model && this.props.project.model.client.id),
    status: document.querySelector(".js-project-edit-status").value,
    description: document.querySelector(".js-project-edit-description").value,
    is_open: document.querySelector(".js-project-edit-is-open").checked ? 1 : 0,
    bid_pdf_filename: document.querySelector(
      ".js-project-edit-bid-pdf-filename"
    ).value,
    invoice_pdf_filename: document.querySelector(
      ".js-project-edit-invoice-pdf-filename"
    ).value,
  });

  /**
   *
   * @returns {{use_shots_as_hours_bid: number, name: *, show_rate_in_bid_pdf: number}}
   */
  getBidData = () => ({
    name: document.querySelector(".js-bid-edit-name").value,
    use_shots_as_hours_bid: document.querySelector(
      ".js-bid-edit-use-shots-as-hours-bid"
    ).checked
      ? 1
      : 0,
    show_rate_in_bid_pdf: document.querySelector(
      ".js-bid-edit-show-rate-in-bid-pdf"
    ).checked
      ? 1
      : 0,
  });

  /**
   *
   * @param data
   * @param previousChainObject
   * @returns {{bid_pdf_filename: [string]}|{invoice_pdf_filename: [string]}}
   */
  validateVariablesStrings = (data, previousChainObject) => {
    const bidPDFFilenameError = validateVariablesString(
      data.bid_pdf_filename,
      bidPDFFilenameVariables
    );
    const invoicePDFFilenameError = validateVariablesString(
      data.invoice_pdf_filename,
      invoicePDFFilenameVariables
    );

    let formValidationCustomErrors = previousChainObject;

    if (bidPDFFilenameError) {
      formValidationCustomErrors = {
        ...(formValidationCustomErrors || {}),
        bid_pdf_filename: [bidPDFFilenameError.text],
      };
    }

    if (invoicePDFFilenameError) {
      formValidationCustomErrors = {
        ...(formValidationCustomErrors || {}),
        invoice_pdf_filename: [invoicePDFFilenameError.text],
      };
    }

    return formValidationCustomErrors;
  };

  /**
   *
   * @param project
   */
  onProjectSelectChange = (project) => {
    if (project.id) {
      this.props.fetchProject(project.id);
      this.setState({ useExistingProject: true });
    } else {
      document.querySelector(".js-project-edit-name").value = project.label;
      this.setState({ useExistingProject: false });
      const projectCodeInput = document.querySelector(".js-project-edit-code");
      if (!projectCodeInput.value) {
        projectCodeInput.value = preformProjectCode(project.label).substring(
          0,
          16
        );
      }
    }
  };

  /**
   *
   */
  handleCustomerSelect = (customer) => {
    if (customer.id) {
      this._selectedCustomerId = customer.id;
      this._newCustomerName = null;
    } else {
      this._newCustomerName = customer.value;
      this._selectedCustomerId = null;
    }
  };

  /**
   *
   * @param event
   */
  onProjectNameBlur = (event) => {
    if (!document.querySelector(".js-project-edit-code").value) {
      document.querySelector(".js-project-edit-code").value =
        preformProjectCode(event.target.value).substring(0, 16);
    }
  };

  /**
   *
   */
  onLinkWithTSheets = () => {
    this.props.clearErrors();
    this.props.linkProjectWithTSheets({ code: this.props.project.model.code });
  };

  /**
   *
   */
  onUnlinkWithTSheets = () => {
    if (
      window.confirm(
        "Are you sure you want to unlink this project? It will remove all time entries from the platform."
      )
    ) {
      this.props.clearErrors();
      this.props.unlinkProjectWithTSheets({
        code: this.props.project.model.code,
      });
    }
  };

  /**
   *
   * @param formValidationErrors
   * @param formValidationCustomErrors
   * @returns {{}}
   */
  getFormValidationErrorsResult = (
    formValidationErrors,
    formValidationCustomErrors
  ) => {
    let formValidationErrorsResult = {};
    if (formValidationErrors) {
      formValidationErrorsResult = {
        ...formValidationErrorsResult,
        ...formValidationErrors,
      };
    }
    if (formValidationCustomErrors) {
      formValidationErrorsResult = {
        ...formValidationErrorsResult,
        ...formValidationCustomErrors,
      };
    }
    return formValidationErrorsResult;
  };

  /**
   *
   * @returns {XML}
   */
  render() {
    const projectFormValidationErrorsResult =
      this.getFormValidationErrorsResult(
        this.state.projectFormValidationErrors,
        this.state.projectFormValidationCustomErrors
      );
    const bidFormValidationErrorsResult = this.state.bidFormValidationErrors;
    const { project, bid, bidVersion, supplier } = this.props;

    const projectsStatuses = this.props.statuses.collection.filter(
      (i) => i.type === "Projects"
    );

    const isLoading =
      project.fetch ||
      project.create ||
      project.edit ||
      project.linkWithTSheets ||
      project.unlinkWithTSheets ||
      bid.fetch ||
      bid.create ||
      bid.edit ||
      bidVersion.update ||
      supplier.fetch;
    const loadingIndicator = isLoading ? <LoadingIndicator /> : null;

    const projectName = project.model ? project.model.name : "";

    const modalTitle = this.isEditMode
      ? `Edit ${projectName}`
      : "Create new bid";
    const confirmButtonLabel = this.isEditMode ? "Update" : "Create";

    const statusDefaultValue = this.isEditMode ? null : statusCreatingValue;

    const projectBid =
      project.model &&
      bid.model &&
      bid.model.recent_bid_version &&
      this.isEditMode ? (
        <div className="form-group">
          <label className="form-label">Bid</label>
          <div>
            <Link to={"/bid/" + bid.model.recent_bid_version.id}>
              Go to project’s bid page
            </Link>
          </div>
        </div>
      ) : null;

    const projectForm = (
      <ProjectForm
        project={project}
        onProjectNameBlur={this.onProjectNameBlur}
        handleCustomerSelect={this.handleCustomerSelect}
        locationState={this.props.location.state}
        statusDefaultValue={statusDefaultValue}
        bidLink={projectBid}
        statuses={projectsStatuses}
        formValidationErrorsResult={projectFormValidationErrorsResult}
        onProjectSelectChange={this.onProjectSelectChange}
        isLoading={isLoading}
        isEditMode={this.isEditMode}
        entityTypeMode={this.entityTypeMode}
        disabled={this.isEditMode && this.entityTypeMode === "bid"}
        useExistingProject={this.state.useExistingProject}
        onLinkWithTSheets={this.onLinkWithTSheets}
        onUnlinkWithTSheets={this.onUnlinkWithTSheets}
      />
    );

    const bidForm = !(this.isEditMode && this.entityTypeMode === "project") ? (
      <>
        <hr className="mt-4" />
        <BidForm
          bid={bid}
          isLoading={isLoading}
          formValidationErrorsResult={bidFormValidationErrorsResult}
        />
      </>
    ) : null;

    return (
      <ModalTemplate
        title={modalTitle}
        onClose={this.props.onClose}
        confirmButtonLabel={confirmButtonLabel}
        onConfirm={this.handleSubmit}
        cancelButtonLabel="Cancel"
        onCancel={this.props.onClose}
        loadingIndicator={loadingIndicator}
        disableConfirmButton={isLoading}
        modalContentClassName={
          this.props.location.state &&
          this.props.location.state.theme === "dark"
            ? "bg-dark"
            : ""
        }
      >
        {projectForm}
        {bidForm}
      </ModalTemplate>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  project: state.project,
  bid: state.bid,
  bidVersion: state.bidVersion,
  client: state.client,
  statuses: state.statuses,
  supplier: state.supplier,
  additionalCosts: state.additionalCosts,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAdditionalCosts: () => dispatch(fetchAdditionalCosts()),
  fetchProject: (id) => dispatch(projectActions.fetchProject(id)),
  createProject: async (data) =>
    await dispatch(projectActions.createProject(data)),
  editProject: async (id, data) =>
    await dispatch(projectActions.editProject(id, data)),
  fetchBid: (id) => dispatch(bidActions.fetchBid(id)),
  createBid: async (projectId, data) =>
    await dispatch(bidActions.createBid(projectId, data)),
  editBid: async (id, data) => await dispatch(bidActions.editBid(id, data)),
  createClient: async (data) =>
    await dispatch(clientActions.createClient(data)),
  linkProjectWithTSheets: async (data) =>
    await dispatch(projectActions.linkProjectWithTSheets(data)),
  unlinkProjectWithTSheets: async (data) =>
    await dispatch(projectActions.unlinkProjectWithTSheets(data)),
  clearProject: () => dispatch(projectActions.clearProject()),
  setEditBid: (data) => dispatch(bidActions.setEditBid(data)),
  fetchSupplierSettings: () =>
    dispatch(supplierActions.fetchSupplierSettings()),
  clearErrors: () => dispatch(appActions.clearErrors()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BidEditModal));
