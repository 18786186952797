import axios from "axios";
import AxiosConfig from "../AxiosConfig";

/**
 *
 * @returns {Function}
 */
export const fetchStatuses = () => {
  return async (dispatch) => {
    dispatch({ type: "STATUSES_FETCH" });
    const url = AxiosConfig.getEndpointAddress() + "/api/statuses.json";
    axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "STATUSES_FETCH_FULFILLED",
          data: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "STATUSES_FETCH_REJECTED",
          data: error,
        });
      });
  };
};

/**
 *
 * @param data
 * @returns {function(...[*]=)}
 */
export const createStatus = (data) => {
  return async (dispatch) => {
    dispatch({ type: "STATUSES_CREATE" });
    const url = AxiosConfig.getEndpointAddress() + "/api/statuses.json";
    axios
      .post(url, data, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "STATUSES_CREATE_FULFILLED",
          data: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "STATUSES_CREATE_REJECTED",
          data: error,
        });
      });
  };
};

/**
 *
 * @param status_id
 * @param data
 * @returns {function(...[*]=)}
 */
export const editStatus = (status_id, data) => {
  return async (dispatch) => {
    dispatch({ type: "STATUSES_EDIT" });
    const url = `${AxiosConfig.getEndpointAddress()}/api/statuses/${status_id}.json`;
    const putData = { ...data };
    if (!data.value) {
      delete putData.value;
    }
    axios
      .put(url, putData, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "STATUSES_EDIT_FULFILLED",
          data: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "STATUSES_EDIT_REJECTED",
          data: error,
        });
      });
  };
};

/**
 *
 * @param status_id
 * @returns {Function}
 */
export const deleteStatus = (status_id) => {
  return (dispatch) => {
    dispatch({ type: "STATUSES_DELETE", id: status_id });
    const url = `${AxiosConfig.getEndpointAddress()}/api/statuses/${status_id}.json`;
    axios
      .delete(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "STATUSES_DELETE_FULFILLED",
          data: response.data,
          id: status_id,
        });
      })
      .catch((error) => {
        dispatch({
          type: "STATUSES_DELETE_REJECTED",
          data: error,
        });
      });
  };
};
