import React, { useEffect } from "react";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";

import LoadingIndicator from "../../../components/LoadingIndicator";
import StatusBadge from "../../../components/StatusBadge";
import { useShotStore } from "../../../storeHooks/shot";
import useStatusHook from "../../../hooks/useStatusHook";

const ShortStats = (props) => {
  const { time, isLoading, data, onRefresh, isRefreshing } = props;

  const stats = data.stats || {};
  const { statuses: statusesFiltered } = useStatusHook(data.type);
  const statsKeys = Object.keys(stats);

  const { shot } = useShotStore();

  const loadingIndicator = (
    <div className="text-center">
      <LoadingIndicator className={"my-3"} />
    </div>
  );

  useEffect(() => {
    if (shot.editSuccess) {
      onRefresh();
    }
  }, [shot.editSuccess]);

  return (
    <div className="card quick-stats">
      <div className="card-header header-elements-inline">
        <h6 className="card-title">Quick stats</h6>
        <div className="header-elements d-flex">
          <button
            type="button"
            className="btn btn-outline bg-warning text-warning mr-2 btn-icon rounded-round"
            onClick={onRefresh}
            disabled={isRefreshing || isLoading}
          >
            <i className="icon-history" />
          </button>
          {isRefreshing ? <LoadingIndicator className="mb-n3 mt-n3" /> : time}
        </div>
      </div>

      {!isLoading ? (
        <div className="card-body py-0 d-flex">
          {statsKeys.length ? (
            <div className="row text-center flex-1">
              {statsKeys.map((statKey, index) => {
                return (
                  <div className="col-lg-3" key={index}>
                    <div className="my-3">
                      <h5 className="font-weight-semibold mb-0">
                        {stats[statKey]}
                      </h5>
                      <span className="text-muted font-size-sm">
                        <StatusBadge
                          statuses={statusesFiltered}
                          status={statKey}
                        />
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="row text-center justify-content-around align-items-center flex-1 pt-3 pb-3">
              --
            </div>
          )}
        </div>
      ) : (
        loadingIndicator
      )}
    </div>
  );
};

ShortStats.propTypes = {
  time: PropTypes.string,
  isLoading: PropTypes.bool,
  data: PropTypes.object.isRequired,
  onRefresh: PropTypes.func,
  isRefreshing: PropTypes.bool,
};

export default ShortStats;
