import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import LastShotUpdateVersion from "./LastShotUpdateVersion";
import DateUSSlash from "../../../components/DateUSSlash";
import StatusEditable from "../../../components/StatusEditable";
import { useShotStore } from "../../../storeHooks/shot";
import useEditStatus from "./useEditStatus";
import { useTasks } from "../../../storeHooks/useTasks";

const GroupedTasksRow = (props) => {
  const { data, statuses } = props;

  const { tasks, editTask } = useTasks();
  const { loading, onChangeStatus } = useEditStatus(data.id, tasks, editTask);

  return (
    <tr>
      <td>
        <span className="text-default">
          <Link to={`/project/${data.project_id}/shot/${data.shot_id}`}>
            {data.shot_number}
          </Link>
        </span>
      </td>
      <td>{data.task_type_name}</td>
      <td>{data.description}</td>
      <td>
        <span className="text-default">
          {/*<StatusBadge status={data.status} statuses={statuses} />*/}
          <StatusEditable
            status={data.status}
            statuses={statuses}
            onChange={onChangeStatus}
            loading={loading}
            isFixedPosition={false}
          />
        </span>
      </td>
    </tr>
  );
};

GroupedTasksRow.propTypes = {
  data: PropTypes.object.isRequired,
  statuses: PropTypes.array.isRequired,
};

export default GroupedTasksRow;
