/**
 * Created by piotr.pozniak@thebeaverhead.com on 24/06/2022
 */

import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Select from "../Select";
import UserSelect from "../Selects/UserSelect";
import { useUserStore } from "../../storeHooks/user";
import LoadingIndicator from "../LoadingIndicator";

const BulkActions = (props) => {
  const { user } = useUserStore();

  const [userSelectBulkValue, setUserSelectBulkValue] = useState(null);

  useEffect(() => {
    if (props.showControls) {
      window.updateJQuery();
    }
  }, [props.showControls]);

  /**
   *
   * @param action
   * @returns {Function}
   */
  const onBulkActionClick = useCallback(
    (action) => () => {
      let bulkActions = [];
      document
        .querySelectorAll(
          "." +
            props.selectorHtmlClass +
            " select" +
            ", ." +
            props.selectorHtmlClass +
            " input" +
            ", ." +
            props.selectorHtmlClass +
            " textarea"
        )
        .forEach((item) => {
          if (item.value) {
            bulkActions.push({ fieldName: item.name, value: item.value });
          }
        });
      if (userSelectBulkValue !== null) {
        bulkActions.push(userSelectBulkValue);
      }
      action(props.selectedRowsData, bulkActions);
      setUserSelectBulkValue(null);
    },
    [props.selectedRowsData, userSelectBulkValue]
  );

  /**
   *
   * @param item
   * @returns {Function}
   */
  const onUserSelectBulkUpdate = useCallback(
    (item) => (value) => {
      setUserSelectBulkValue({ fieldName: item.fieldName, value: value });
      if (typeof item.onUpdate === "function") {
        item.onUpdate(value);
      }
    },
    [setUserSelectBulkValue]
  );

  const bulkActions = useMemo(
    () =>
      props.bulkActions && props.bulkActions.length
        ? props.bulkActions.filter(
            (item) =>
              !item.allowedRoles || item.allowedRoles.includes(user.model.role)
          )
        : [],
    [props.bulkActions]
  );

  const wrapperClassName = classNames(
    "card card-body mt-3 mb-n1px",
    props.wrapperClassName
  );

  const bulkActionsButtons = props.showControls
    ? bulkActions
        .filter((item) => item.label && item.action)
        .map((item) => {
          return (
            <button
              key={item.label}
              type="button"
              className="btn btn-primary mr-2"
              onClick={onBulkActionClick(item.action)}
              disabled={props.isLoading || !props.isAnyRowSelected}
            >
              {item.label}
            </button>
          );
        })
    : null;

  const isLoading = props.isLoading ? (
    <div className="mb-n3">
      <LoadingIndicator />
    </div>
  ) : null;

  const filteredBulkActionsControls = (bulkActions || []).filter((item) =>
    Object.keys(item).includes("type")
  );

  const bulkActionControls = props.showControls
    ? filteredBulkActionsControls.map((item, index) => {
        return (
          <div
            key={index}
            className={classNames("mb-3", {
              "col-sm-4": item.type !== "textarea",
              "col-sm-8": item.type === "textarea",
              "d-none d-sm-block": item.type === "spacer",
            })}
          >
            <label className="form-label">{item.placeholder}</label>
            {item.type === "select" && (
              <Select
                data={[{ value: "", label: "-" }, ...item.data]}
                initClassName="js-select2"
                name={item.fieldName}
                disabled={item.disabled}
                defaultValue={""}
              />
            )}
            {item.type === "textinput" && (
              <input
                type="text"
                className={"form-control h-38"}
                maxLength={item.maxLength}
                name={item.fieldName}
                onChange={item.onChange}
              />
            )}
            {item.type === "userSelect" ? (
              <UserSelect
                onUpdate={onUserSelectBulkUpdate(item)}
                reactSelectProps={item.reactSelectProps}
                addEmptyItem={item.addEmptyItem}
                emptyItemLabel={item.emptyItemLabel}
                emptyItemValue={item.emptyItemValue}
              />
            ) : null}
            {item.type === "textarea" && (
              <textarea
                rows={item.rows || "6"}
                className="form-control"
                maxLength={item.maxLength}
                name={item.fieldName}
                disabled={item.disabled}
              />
            )}
            {item.type === "spacer" && <div />}
          </div>
        );
      })
    : null;

  return (
    <div className={"bulk-actions-container"}>
      <div className={wrapperClassName}>
        <form className={"clearfix " + props.selectorHtmlClass}>
          <div className="row">{bulkActionControls}</div>
        </form>
        <div className="d-flex align-items-center">
          {bulkActionsButtons}
          {isLoading}
        </div>
      </div>
    </div>
  );
};

BulkActions.defaultProps = {
  selectorHtmlClass: "js-bulk-action-form",
};

BulkActions.propTypes = {
  showControls: PropTypes.bool,
  isAnyRowSelected: PropTypes.bool,

  wrapperClassName: PropTypes.string,

  selectedRowsData: PropTypes.array,

  bulkActions: PropTypes.arrayOf(
    PropTypes.shape({
      rows: PropTypes.number,
      maxLength: PropTypes.number,
      fieldName: PropTypes.string,
      placeholder: PropTypes.string,
      disabled: PropTypes.bool,
      type: PropTypes.string.isRequired,
      onChange: PropTypes.func,
      data: PropTypes.any,
      addEmptyItem: PropTypes.bool,
      emptyItemLabel: PropTypes.string,
      emptyItemValue: PropTypes.string,
      reactSelectProps: PropTypes.object,
      selectorHtmlClass: PropTypes.string,
      isLoading: PropTypes.bool,
    })
  ),
};

export default BulkActions;
