/**
 * Created by piotr.pozniak@thebeaverhead.com on 24/11/2021.
 */

import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as tasksActions from "../actions/tasks";

export const useTasks = () => {
  const dispatch = useDispatch();
  const _tasks = useSelector((store) => store.tasks);


  const fetchTasks = useCallback(
    (shotId) => dispatch(tasksActions.fetchTasks(shotId)),
    [dispatch]
  );

  const createTask = useCallback(
    (shotId, data) => dispatch(tasksActions.createTask(shotId, data)),
    [dispatch]
  );

  const editTask = useCallback(
    (taskId, data) => dispatch(tasksActions.editTask(taskId, data)),
    [dispatch]
  );

  const deleteTask = useCallback(
    (taskId) => dispatch(tasksActions.deleteTask(taskId)),
    [dispatch]
  );

  const changeAssginee = useCallback(
    (tasks, assigneeId) => dispatch(tasksActions.changeAssignee(tasks, assigneeId)),
    [dispatch]
  );

  return {
    tasks: _tasks,
    createTask,
    editTask,
    deleteTask,
    changeAssginee
  };
};
