import React, { Component } from "react";
import PropTypes from "prop-types";

import { history } from "../configureStore";

import classNames from "classnames";

import * as appActions from "../actions/app";
import connect from "react-redux/es/connect/connect";
import { Link, NavLink, withRouter } from "react-router-dom";

import LoginPage from "./LoginPage";

import HeaderForAuthorized from "../components/HeaderForAuthorized";
import Footer from "../components/Footer";
import { filterAllowedRoles } from "../helpers/tools";
import SeoBlock from "../components/SeoBlock";

const defaultHeader = "BLiP";

class PageTemplate extends Component {
  static menuOptions = [
    {
      url: "/",
      urlRoot: ["/", "/dashboard"],
      label: "Dashboard",
      icon: "home4",
      submenu: [
        {
          url: "/dashboard/producer",
          urlRoot: ["/", "/dashboard", "/dashboard/producer"],
          label: "Producer",
          allowedRoles: ["admin", "producer"],
        },
        {
          url: "/dashboard/artist",
          urlRoot: ["/dashboard/artist"],
          label: "Artist",
          allowedRoles: ["admin", "producer"],
        },
        {
          url: "/dashboard/accountant",
          urlRoot: ["/dashboard/accountant"],
          label: "Accountant",
          allowedRoles: ["admin", "producer"],
        },
        {
          url: "/dashboard/workload",
          urlRoot: ["/dashboard/workload"],
          label: "Workload",
          allowedRoles: ["admin", "producer"],
        },
      ],
    },
    {
      url: "/bids",
      urlRoot: ["/bids", "/bid"],
      label: "Bids",
      icon: "cash",
      submenu: [
        {
          url: "/bids/1",
          urlRoot: ["/bids"],
          label: "All",
        },
        {
          url: "/bids/archive/1",
          urlRoot: ["/bids/archive"],
          label: "Archived",
        },
        {
          url: "/bids/trash/1",
          urlRoot: ["/bids/trash"],
          label: "Trash",
          allowedRoles: ["admin", "producer", "accountant"],
        },
      ],
      allowedRoles: ["admin", "producer", "accountant", "client"],
    },
    {
      url: "/projects",
      urlRoot: ["/projects", "/project"],
      label: "Projects",
      icon: "film",
      allowedRoles: ["admin", "producer", "accountant", "artist", "client"],
      submenu: [
        {
          url: "/projects/1",
          urlRoot: ["/projects"],
          label: "All",
        },
        {
          url: "/projects/active/1",
          urlRoot: ["/projects/active"],
          label: "Active",
        },
        {
          url: "/projects/archive/1",
          urlRoot: ["/projects/archive"],
          label: "Archived",
        },
        {
          url: "/projects/trash/1",
          urlRoot: ["/projects/trash"],
          label: "Trash",
          allowedRoles: ["admin", "producer", "accountant", "artist"],
        },
      ],
    },
    {
      url: "/clients",
      urlRoot: ["/clients", "/client"],
      label: "Clients",
      icon: "crown",
      allowedRoles: ["admin", "producer", "accountant"],
    },
    {
      url: "/invoices/1",
      urlRoot: ["/invoices", "/invoice"],
      label: "Invoices",
      icon: "coin-dollar",
      allowedRoles: ["admin", "producer", "accountant", "client"],
      submenu: [
        {
          url: "/invoices/1",
          urlRoot: ["/invoices"],
          label: "All",
        },
        {
          url: "/invoices/archive/1",
          urlRoot: ["/invoices/archive"],
          label: "Archived",
        },
        {
          url: "/invoices/trash/1",
          urlRoot: ["/invoices/trash"],
          label: "Trash",
          allowedRoles: ["admin", "producer", "accountant"],
        },
      ],
    },
    {
      label: "Reports",
      icon: "clipboard3",
      url: "/reports",
      urlRoot: ["/reports"],
      allowedRoles: ["admin", "producer", "accountant"],
      submenu: [
        {
          url: "/reports/artists",
          urlRoot: ["/reports/artists"],
          label: "Artists",
        },
        {
          url: "/reports/artists_performance",
          urlRoot: ["/reports/artists_performance"],
          label: "Artists performance",
        },
        {
          url: "/reports/invoices",
          urlRoot: ["/reports/invoices"],
          label: "Invoices",
        },
        {
          url: "/reports/predictions",
          urlRoot: ["/reports/predictions"],
          label: "Predictions",
        },
        {
          url: "/reports/gain_loss",
          urlRoot: ["/reports/gain_loss"],
          label: "Gain/Loss",
        },
        {
          url: "/reports/projects_hours",
          urlRoot: ["/reports/projects_hours"],
          label: "Projects hours",
        },
      ],
    },
    {
      label: "Users",
      icon: "people",
      url: "/users",
      urlRoot: ["/users"],
      allowedRoles: ["admin", "producer"],
      submenu: [
        {
          url: "/users/user/1/0",
          urlRoot: ["/users/user"],
          label: "Pending approval",
        },
        {
          url: "/users/client/1",
          urlRoot: ["/users/client"],
          label: "Clients",
        },
        {
          url: "/users/producer/1",
          urlRoot: ["/users/producer"],
          label: "Producers",
        },
        {
          url: "/users/artist/1",
          urlRoot: ["/users/artist"],
          label: "Artists",
        },
        {
          url: "/users/accountant/1",
          urlRoot: ["/users/accountant"],
          label: "Accountants",
        },
        {
          url: "/users/admin/1",
          urlRoot: ["/users/admin"],
          label: "Admins",
        },
      ],
    },
    {
      url: "/timelogs",
      urlRoot: ["/timelogs"],
      label: "Time logs",
      icon: "watch2",
      allowedRoles: ["artist"],
    },
  ];

  constructor(props) {
    super(props);

    this.state = {};

    this.previousPathExist = false;
  }

  /**
   *
   */
  componentWillUnmount() {
    this.props.clearErrors();
  }

  /**
   *
   */
  componentDidMount() {
    window.updateJQuery();
  }

  /**
   *
   * @param prevProps
   */
  componentDidUpdate(prevProps) {
    this.previousPathExist = !!prevProps.location.key;
  }

  /**
   *
   * @param urlRoot
   * @returns {function(*, *): boolean}
   */
  checkWithRootIsActive = (urlRoot) => (match, location) => {
    let isMatch = false;
    urlRoot.forEach((item) => {
      let pathnameAr = location.pathname.split(item);
      if (
        location.pathname.indexOf(item) === 0 &&
        ((pathnameAr[1].charAt(0) === "/" &&
          !isNaN(parseInt(pathnameAr[1].charAt(1)))) ||
          pathnameAr[1] === "")
      ) {
        isMatch = true;
      }
    });
    return isMatch;
  };

  /**
   *
   */
  onBackArrowClick = () => {
    if (this.props.backArrowPath) {
      history.push(this.props.backArrowPath);
    } else if (this.previousPathExist) {
      history.goBack();
    }
  };

  /**
   *
   * @returns {XML}
   */
  render() {
    const isAuthorized = !!(this.props.user.token && this.props.user.model);

    const header = React.isValidElement(this.props.header) ? (
      this.props.header
    ) : (
      <h1>{this.props.header || defaultHeader}</h1>
    );
    const subheader = this.props.subheader ? (
      <span className={"h4"}>
        <small className="d-block opacity-75 ml-0">
          {this.props.subheader}
        </small>
      </span>
    ) : null;

    const rightLine = this.props.rightLine || null;
    const rightTopMenu = this.props.rightTopMenu || null;

    const subNavigation = (subNavOptions, key) => {
      return (
        <li className="nav-item nav-item-submenu" key={key}>
          <NavLink
            to={subNavOptions.url}
            className="nav-link"
            activeClassName="active"
            isActive={this.checkWithRootIsActive(subNavOptions.urlRoot)}
          >
            <i className={"icon-" + subNavOptions.icon} />
            <span>{subNavOptions.label}</span>
          </NavLink>
          <ul
            className="nav nav-group-sub"
            data-submenu-title={subNavOptions.label}
          >
            {subNavOptions.submenu.map((item, index) => {
              return (
                <li className="nav-item" key={index}>
                  <NavLink
                    exact
                    to={item.url}
                    className="nav-link"
                    activeClassName="active"
                    isActive={this.checkWithRootIsActive(item.urlRoot)}
                  >
                    {item.label}
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </li>
      );
    };

    let menuOptions = filterAllowedRoles(
      this.props.user.model,
      PageTemplate.menuOptions
    );
    menuOptions = menuOptions.map((option) => {
      if (!option.submenu) {
        return option;
      }
      return {
        ...option,
        submenu: filterAllowedRoles(this.props.user.model, option.submenu),
      };
    });
    const navigation = (
      <div className="card card-sidebar-mobile">
        <ul
          className="nav nav-sidebar nav-sidebar_first-child-pt-0"
          data-nav-type="accordion"
        >
          {menuOptions.map((item, index) => {
            if (!(item.submenu && item.submenu.length)) {
              return (
                <li className="nav-item" key={index}>
                  <NavLink
                    exact
                    to={item.url}
                    className="nav-link"
                    activeClassName="active"
                    isActive={this.checkWithRootIsActive(item.urlRoot)}
                  >
                    <i className={"icon-" + item.icon} />
                    <span>{item.label}</span>
                  </NavLink>
                </li>
              );
            } else {
              return subNavigation(item, index);
            }
          })}
        </ul>
      </div>
    );

    const breadcrumbs = React.isValidElement(this.props.breadcrumbs) ? (
      this.props.breadcrumbs
    ) : (
      <div className="d-flex">
        <div className="breadcrumb">
          {(this.props.breadcrumbs || []).map((item, index) => {
            if (item.url) {
              return (
                <Link to={item.url} className="breadcrumb-item" key={index}>
                  <i className="icon-home2 mr-2" /> {item.label}
                </Link>
              );
            } else {
              return (
                <span className="breadcrumb-item active" key={index}>
                  {item.label}
                </span>
              );
            }
          })}
        </div>
      </div>
    );

    const sidebarMobileToggler = (
      <div className="sidebar-mobile-toggler text-center">
        <a href="!#" className="sidebar-mobile-main-toggle">
          <i className="icon-arrow-left8" />
        </a>
        Navigation
        <a href="!#" className="sidebar-mobile-expand">
          <i className="icon-screen-full" />
          <i className="icon-screen-normal" />
        </a>
      </div>
    );

    const pageTitleClassName =
      classNames("page-title", "d-flex") +
      " " +
      (this.props.pageTitleClassName || "");

    const breadcrumbClassName = classNames(
      "breadcrumb-line header-elements-md-inline breadcrumb-line-light",
      this.props.breadcrumbClassName
    );

    const pageHeader = (
      <div className="page-header page-header-light">
        <div className="page-header-content header-elements-md-inline">
          <div className={pageTitleClassName}>
            <i
              className={classNames(
                "h1 mr-2 pointer",
                this.props.backButtonArrowIcon
              )}
              onClick={this.onBackArrowClick}
            />
            <span className={"font-weight-semibold"}>
              {header}
              {subheader}
            </span>
            <a
              href="!#"
              className="header-elements-toggle text-default d-md-none"
            >
              <i className="icon-more" />
            </a>
          </div>
          <div className="header-elements d-none">
            <div className="d-flex justify-content-center">{rightTopMenu}</div>
          </div>
        </div>

        <div className={breadcrumbClassName}>
          <div className="d-flex align-items-center">
            {breadcrumbs}

            <a
              href="!#"
              className="header-elements-toggle text-default d-md-none d-block pt-2 pb-2"
            >
              <i className="icon-more" />
            </a>
          </div>

          <div className="header-elements d-none text-center">{rightLine}</div>
        </div>
      </div>
    );

    const content = this.props.children;

    const pageWrapperClassNameProps = this.props.pageWrapperClassName || "";

    const pageWrapperClassName =
      classNames("page-wrapper", {
        "sidebar-xs": this.props.app.sidebarCollapsed,
      }) + ` ${pageWrapperClassNameProps}`;

    return isAuthorized ? (
      <div className={pageWrapperClassName}>
        <HeaderForAuthorized />
        <SeoBlock title={this.props.header || defaultHeader} />
        {/*<!-- Page content -->*/}
        <div className="page-content">
          {/*<!-- Main sidebar -->*/}
          <div className="sidebar sidebar-dark sidebar-main sidebar-expand-md">
            {/*<!-- Sidebar mobile toggler -->*/}
            {sidebarMobileToggler}
            {/*<!-- /sidebar mobile toggler -->*/}

            {/*<!-- Sidebar content -->*/}
            <div className="sidebar-content">
              {/*<!-- Main navigation -->*/}
              {navigation}
              {/*<!-- /main navigation -->*/}
            </div>
            {/*<!-- /sidebar content -->*/}
          </div>
          {/*<!-- /main sidebar -->*/}

          {/*<!-- Main content -->*/}
          <div className="content-wrapper">
            {/*<!-- Page header -->*/}
            {pageHeader}
            {/*<!-- /page header -->*/}

            {/*<!-- Content area -->*/}
            <div className="content">{content}</div>
            {/*<!-- /content area -->*/}

            {/*<!-- Footer -->*/}
            <Footer className={this.props.pageFooterClassName} />
            {/*<!-- /footer -->*/}
          </div>
          {/*<!-- /main content -->*/}
        </div>
        {/*<!-- /page content -->*/}
      </div>
    ) : (
      <LoginPage />
    );
  }
}

PageTemplate.defaultProps = {
  backButtonArrowIcon: "icon-arrow-left52",
};

PageTemplate.propTypes = {
  breadcrumbs: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
  header: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  subheader: PropTypes.string,
  pageWrapperClassName: PropTypes.string,
  backArrowPath: PropTypes.string,
  pageTitleClassName: PropTypes.string,
  pageFooterClassName: PropTypes.string,
  breadcrumbClassName: PropTypes.string,
  backButtonArrowIcon: PropTypes.string,
};

const mapStateToProps = (state) => ({
  user: state.user,
  app: state.app,
});

const mapDispatchToProps = (dispatch) => ({
  clearErrors: () => dispatch(appActions.clearErrors()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PageTemplate));
