import React, { Component } from "react";
import PropTypes from "prop-types";

import AsyncCreatableSelect from "react-select/async-creatable";
import AsyncSelect from "react-select/async";
import AxiosConfig from "../../AxiosConfig";
import axios from "axios";
import GenericAsyncSelector from "./GenericAsyncSelector";

const page = 1;
const limit = 10;

/**
 *
 * @param item
 * @returns {{data, label: string, value}}
 */
const formatItem = (item) => {
  return {
    label: `${item.project.name} - ${item.name}`,
    value: item.id,
    data: item,
  };
};

const BidSelect = (props) => {
  /**
   *
   * @param q
   * @returns {Promise<T>}
   */
  const fetchItems = (q) => {
    let params = {
      page,
      limit,
      q,
    };

    if (props.projectId) {
      params.project_id = props.projectId;
    }
    if (props.status) {
      params.status = props.status;
    }

    const url =
      AxiosConfig.getEndpointAddress() +
      "/api/bids.json?" +
      AxiosConfig.objectToURLQuery(params);

    return axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        return response.data.bids;
      })
      .catch(function (error) {
        return null;
      });
  };

  return (
    <GenericAsyncSelector
      {...props}
      fetchItems={fetchItems}
      formatItem={formatItem}
      placeholder={"Select bid..."}
    />
  );
};

BidSelect.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  projectId: PropTypes.string,
  status: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  reactSelectProps: PropTypes.object,
};

export default BidSelect;
