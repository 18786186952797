/**
 *
 * @param data
 * @param columns
 * @returns {*}
 */
export const generateCSVExportData = (data, columns) => {
  return data.map((dataItem) => {
    let convertedDataItem = {};
    columns.forEach((column) => {
      if (typeof column.csvFormat !== "function") {
        return;
      }
      convertedDataItem[getSelector(column.selector)] = column.csvFormat(
        dataItem
      );
    });
    return convertedDataItem;
  });
};

/**
 *
 * @param columns
 * @returns {*}
 */
export const generateCSVExportHeaders = (columns) => {
  return columns
    .filter((column) => typeof column.csvFormat === "function")
    .map((column) => {
      return {
        label: column.csvName || column.name,
        key: getSelector(column.selector),
      };
    });
};

/**
 *
 * @param selectorString
 * @returns {*}
 */
export const getSelector = (selectorString) => {
  const selectorAr = selectorString.split(".");
  return selectorAr.length > 1
    ? selectorAr[selectorAr.length - 1]
    : selectorAr[0];
};
