import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import Validator from 'validatorjs';

import ModalTemplate from './ModalTemplate';

import * as shotActions from "../actions/shot";
import connect from "react-redux/es/connect/connect";
import LoadingIndicator from '../components/LoadingIndicator';
import ApiError from '../components/ApiError';
import FormValidationError from '../components/FormValidationError';

class ShotUpdateEditModal extends Component {

  constructor(props) {
    super(props);

    this.state = {formValidationErrors: null};
  }

  /**
   *
   */
  componentDidMount() {
    if (!this.props.shot.model) {
      this.props.fetchShot(this.props.match.params.shotId);
    } else {
      this.handleUpdateForm();
    }
  }

  /**
   *
   */
  componentDidUpdate() {
    if (this.state.formValidationErrors === null) {
      this.handleUpdateForm();
    }
  }

  /**
   *
   */
  get isEditMode() {
    return this.props.match.params.shotUpdateId !== "new";
  }

  /**
   *
   */
  handleUpdateForm() {
    const currentShotUpdate = this.getCurrentShotUpdate();
    if (!!currentShotUpdate) {
      document.querySelector('.js-shot-update-edit-shot-version').value = this.getCurrentShotUpdate().version;
      // document.querySelector('.js-shot-update-edit-summary').value = this.getCurrentShotUpdate().summary;
      document.querySelector('.js-shot-update-edit-description').value = this.getCurrentShotUpdate().description;
    }
  }

  /**
   *
   * @returns {*}
   */
  getCurrentShotUpdate = () => {
    const shotUpdates = this.props.shot.model ? this.props.shot.model.shot_updates : [];
    const shotUpdateId = this.props.match.params.shotUpdateId;
    const editingShotUpdateIndex = shotUpdates.findIndex(item => item.id === shotUpdateId);
    return editingShotUpdateIndex !== -1 ? shotUpdates[editingShotUpdateIndex] : null;
  };

  /**
   *
   */
  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({formValidationErrors: null});

    const data = {
      version: document.querySelector('.js-shot-update-edit-shot-version').value,
      // summary: document.querySelector('.js-shot-update-edit-summary').value,
      description: document.querySelector('.js-shot-update-edit-description').value,
    };

    const rules = {
      version: 'required',
    };
    const validation = new Validator(data, rules);

    this.setState({formValidationErrors: validation.errors.all()});

    if (validation.passes()) {
      if (this.isEditMode) {
        const {match: {params: {shotUpdateId}}} = this.props;
        await this.props.editShotUpdate(shotUpdateId, data);
      } else {
        const {match: {params: {shotId}}} = this.props;
        await this.props.createShotUpdate(shotId, data);
      }

      if ((!this.isEditMode && this.props.shot.shotUpdateCreateSuccess) || (this.isEditMode && this.props.shot.shotUpdateEditSuccess)) {
        this.props.onClose();
      }
    }

  };


  /**
   *
   * @returns {XML}
   */
  render() {
    const {formValidationErrors} = this.state;
    const shot = this.props.shot;
    const shotModel = shot.model || {};

    const isLoading = shot.shotUpdateCreate || shot.shotUpdateEdit;
    const loadingIndicator = isLoading ? <LoadingIndicator/> : null;

    const modalTitle = this.isEditMode ?
      `Edit update of the shot ${shotModel.shot_number || ''}` :
      `Add a new update of the shot ${shotModel.shot_number || ''}`;
    const confirmButtonLabel = this.isEditMode ? "Update" : "Create";

    return (
      <ModalTemplate
        title={modalTitle}
        onClose={this.props.onClose}
        confirmButtonLabel={confirmButtonLabel}
        onConfirm={this.handleSubmit}
        cancelButtonLabel="Cancel"
        onCancel={this.props.onClose}
        loadingIndicator={loadingIndicator}
        disableConfirmButton={isLoading}
        modalContentClassName="bg-dark"
      >
        <form noValidate>

          <div className="form-group">
            <label className="form-label">
              Shot Version <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control js-shot-update-edit-shot-version"
              placeholder="Shot Version"
              disabled={isLoading}
            />
          </div>

          {/*<div className="form-group">*/}
            {/*<label className="form-label">*/}
              {/*Summary*/}
            {/*</label>*/}
            {/*<input*/}
              {/*type="text"*/}
              {/*className="form-control js-shot-update-edit-summary"*/}
              {/*placeholder="Summary"*/}
              {/*disabled={isLoading}*/}
            {/*/>*/}
          {/*</div>*/}

          <div className="form-group">
            <label className="form-label">
              Description
            </label>
            <textarea
              className="form-control js-shot-update-edit-description"
              placeholder="Description"
              disabled={isLoading}
            />
          </div>

          <FormValidationError errors={formValidationErrors}/>
          <ApiError error={shot.shotUpdateCreateError || shot.shotUpdateEditError}/>

        </form>
      </ModalTemplate>
    );
  }
}

const mapStateToProps = state => ({
  shot: state.shot,
});

const mapDispatchToProps = dispatch => ({
  fetchShot: id => dispatch(shotActions.fetchShot(id)),
  createShotUpdate: async (shotId, data) => await dispatch(shotActions.createShotUpdate(shotId, data)),
  editShotUpdate: async (id, data) => await dispatch(shotActions.editShotUpdate(id, data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(ShotUpdateEditModal)
);