import React, {memo, useMemo} from "react";

import { Link } from "react-router-dom";

import UserAvatar from "../components/UserAvatar";
import { filterAllowedRoles } from "../helpers/tools";
import { useUserStore } from "../storeHooks/user";
import { useAppStore } from "../storeHooks/app";
import { useActivitiesStore } from "../storeHooks/activities";

/**
 *
 * @type {function(*): function(*): void}
 */
const onItemClick = (action) => (e) => {
  if (typeof action === "function") {
    action();
  }
};

const HeaderForAuthorized = () => {
  const { user, logout } = useUserStore();
  const { app, collapseSideNav } = useAppStore();
  const { disconnectActivitiesSocket } = useActivitiesStore();
  /**
   *
   */
  const onCollapseSideNavPress = (event) => {
    event.preventDefault();
    collapseSideNav(!app.sidebarCollapsed);
  };

  /**
   *
   */
  const _logout = () => {
    logout();
    disconnectActivitiesSocket();
  };

  const userDropdownMenuOptions = useMemo(
    () => [
      {
        url: "/profile-settings",
        label: "Edit Profile",
        icon: "user",
      },
      {
        url: "/platform-settings",
        label: "Settings",
        icon: "reading",
        allowedRoles: ["admin", "producer", "accountant"],
      },
      {
        url: "/client-settings",
        label: "Client Settings",
        icon: "reading",
        allowedRoles: ["client"],
      },
      {
        url: "/",
        label: "Logout",
        icon: "switch2",
        action: _logout,
      },
    ],
    []
  );

  const filteredUserDropdownMenuOptions = useMemo(
    () => filterAllowedRoles(user.model, userDropdownMenuOptions),
    [user.model]
  );

  const userDropdownMenu = (
    <div className="dropdown-menu dropdown-menu-right">
      {filteredUserDropdownMenuOptions.map((item, index) => {
        return (
          <Link
            to={item.url}
            className="dropdown-item"
            key={index}
            onClick={onItemClick(item.action)}
          >
            <i className={"icon-" + item.icon} /> {item.label}
          </Link>
        );
      })}
    </div>
  );

  return (
    <div className="navbar navbar-expand-md navbar-dark">
      <div className="navbar-brand">
        <a href="/" className="navbar-brand-text d-inline-block d-logo">
          BLiP
        </a>
      </div>

      <div className="d-md-none">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbar-mobile"
        >
          <i className="icon-tree5" />
        </button>
        <button
          className="navbar-toggler sidebar-mobile-main-toggle"
          type="button"
        >
          <i className="icon-paragraph-justify3" />
        </button>
      </div>

      <div className="collapse navbar-collapse" id="navbar-mobile">
        <ul className="navbar-nav">
          <li className="nav-item">
            <a
              href="!#"
              className="navbar-nav-link d-none d-md-block"
              onClick={onCollapseSideNavPress}
            >
              <i className="icon-paragraph-justify3 mt-n01rem" />
            </a>
          </li>
        </ul>

        <span className="ml-md-3 mr-md-auto">&nbsp;</span>

        <ul className="navbar-nav">
          <li className="nav-item dropdown dropdown-user">
            <a
              href="!#"
              className="navbar-nav-link d-flex align-items-center dropdown-toggle"
              data-toggle="dropdown"
            >
              <UserAvatar user={user.model} className="mr-2" />
              <span>{user.model.name}</span>
            </a>

            {userDropdownMenu}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default memo(HeaderForAuthorized);
