import React, { Component, useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import moment from "moment";
import { hasRights } from "../helpers/tools";
import connect from "react-redux/es/connect/connect";
import EditNote from "./EditNote";
import LoadingIndicator from "./LoadingIndicator";
import ApiError from "./ApiError";
import UserAvatar from "./UserAvatar";
import {useUserStore} from "../storeHooks/user";

const Notes = (props) => {
  const [state, setState] = useState({
    noteModel: null,
    noteEditError: props.noteEditError,
    noteCreateError: props.noteCreateError,
    noteDeleteError: props.noteCreateError,
  });


  const {user} = useUserStore();

  /**
   *
   * @returns {Function}
   * @param noteModel
   */
  const onPressEditNote = (noteModel) => () => {
    setState({
      ...state,
      noteModel,
      noteEditError: null,
      noteCreateError: null,
      noteDeleteError: null,
    });
  };

  /**
   *
   * @returns {Promise<void>}
   * @param note
   */
  const handleCreateNote = async (note) => {
    await props.handleCreateNote(note);
    if (!props.noteCreateError) {
      setState({ ...state, noteModel: null });
    } else {
      setState({ ...state, noteCreateError: props.noteCreateError });
    }
  };

  /**
   *
   * @returns {Promise<void>}
   * @param note
   */
  const handleEditNote = async (note) => {
    if (state.noteModel && state.noteModel.id) {
      await props.handleEditNote(state.noteModel.id, note, state.noteModel);
      if (!props.noteEditError) {
        setState({ ...state, noteModel: null });
      } else {
        setState({ ...state, noteEditError: props.noteEditError });
      }
    }
  };

  /**
   *
   * @returns {Function}
   * @param noteModel
   */
  const onPressDeleteNote = (noteModel) => async () => {
    if (window.confirm("Are you sure you want to delete this note?")) {
      await props.handleDeleteNote(noteModel.id, noteModel);
      if (props.noteDeleteError) {
        setState({ ...state, noteDeleteError: props.noteDeleteError });
      }
    }
  };

  /**
   *
   */
  const handleEditNoteCancel = () => {
    setState({
      ...state,
      noteModel: null,
      noteEditError: null,
      noteCreateError: null,
      noteDeleteError: null,
    });
    setTimeout(window.updateJQuery, 100);
  };

  /**
   *
   */
  const onPressAddNote = (event) => {
    event.preventDefault();
    const noteModel = { note: "" };
    setState({
      ...state,
      noteModel,
      noteEditError: null,
      noteCreateError: null,
      noteDeleteError: null,
    });
  };

  const notesItems = props.notes.map((noteModel) => {
    const noteLeftFullDate = String(
      moment((noteModel.modified || noteModel.created) * 1000)
        .format("MM/DD/YY HH:mm")
        .toUpperCase()
    );

    const noteHeader = (
      <span
      >
        {noteLeftFullDate}
      </span>
    );

    const editBtn = hasRights(user.model, noteModel, "admin") && (
      <button
        type="button"
        className="btn btn-link btn-icon rounded-round text-muted align-baseline p-0 mr-1"
        onClick={onPressEditNote(noteModel)}
        disabled={props.noteDelete}
      >
        <i className="mi-mode-edit fs-1-1rem" />
      </button>
    );

    const deleteBtn = user.model.role === "admin" && (
      <button
        type="button"
        className="btn btn-link btn-icon rounded-round text-muted align-baseline p-0"
        onClick={onPressDeleteNote(noteModel)}
        disabled={props.noteDelete}
      >
        <i className="mi-delete-forever fs-1-1rem" />
      </button>
    );

    const noteBody = noteModel.note;

    return (
      <React.Fragment key={noteModel.id}>
        <div className="media">
          <div className="mr-3">
            <UserAvatar user={noteModel.user} showTitle={true} />
          </div>

          <div className="media-body">
            <div className="media-chat-item media-chat-item_small break-spaces">
              {noteBody}
            </div>
            <div className="font-size-sm text-muted mt-7px">
              {noteHeader}
              {editBtn}
              {deleteBtn}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  });

  const showEditForm =
    state.noteModel !== null ||
    Boolean(state.noteEditError) ||
    Boolean(state.noteCreateError);
  const editForm = showEditForm ? (
    <EditNote
      noteModel={state.noteModel}
      handleCancel={handleEditNoteCancel}
      handleCreateNote={handleCreateNote}
      handleEditNote={handleEditNote}
      noteEdit={props.noteEdit}
      noteCreate={props.noteCreate}
    />
  ) : null;

  const noteEditError = state.noteEditError ? (
    <ApiError error={props.noteEditError} />
  ) : null;
  const noteCreateError = state.noteCreateError ? (
    <ApiError error={props.noteCreateError} />
  ) : null;
  const noteDeleteError = state.noteDeleteError ? (
    <ApiError error={props.noteDeleteError} />
  ) : null;

  const loadingIndicator =
    props.noteCreate || props.noteEdit || props.noteDelete ? (
      <LoadingIndicator className="mb-r mr-2" />
    ) : null;

  const notesList = showEditForm ? null : props.notes.length > 0 ? (
    <div
      style={{
        overflowY: "auto",
        marginRight: "-1.25rem",
        paddingRight: "1.25rem",
      }}
    >
      {notesItems}
    </div>
  ) : (
    <div className="pt-3 pb-2">--</div>
  );

  const notesClassName = classNames(
    "card bg-dark",
    props.notesClassNames.className
  );

  const headerClassName = classNames(
    "card-header header-elements-inline no-border",
    props.notesClassNames.headerClassName
  );

  const bodyClassName = classNames(
    "card-body overflow-auto",
    {
      "pt-0 pb-0": !props.notes.length,
    },
    props.notesClassNames.bodyClassName
  );

  return (
    <div className={notesClassName}>
      <div className={headerClassName}>
        <h6 className="card-title">Notes</h6>
        <div className="header-elements">
          {loadingIndicator}
          <div className="list-icons">
            <button
              disabled={props.noteDelete}
              className="list-icons-item list-icons-item_plus input-pure none-bg text-white"
              data-popup="tooltip"
              data-placement="top"
              data-original-title="Add note"
              onClick={onPressAddNote}
            />
          </div>
        </div>
      </div>

      <div
        className={bodyClassName}
        style={{ maxHeight: props.contentMaxHeight }}
      >
        {editForm}
        {noteEditError}
        {noteCreateError}
        {noteDeleteError}
        {notesList}
      </div>
    </div>
  );
};

Notes.defaultProps = {
  notesClassNames: {},
  contentMaxHeight: 250,
};

Notes.propTypes = {
  notes: PropTypes.array.isRequired,
  handleCreateNote: PropTypes.func.isRequired,
  handleEditNote: PropTypes.func.isRequired,
  handleDeleteNote: PropTypes.func.isRequired,
  noteEdit: PropTypes.bool,
  noteEditError: PropTypes.object,
  noteCreate: PropTypes.bool,
  noteCreateError: PropTypes.object,
  noteDelete: PropTypes.bool,
  noteDeleteError: PropTypes.object,
  notesClassNames: PropTypes.object,
  contentMaxHeight: PropTypes.number,
};

export default Notes;
