import React, { useCallback } from "react";

import { useRTCStore } from "../storeHooks/useRtcStore";

const withRTC = (Component) => (props) => {
  const { rtc, setEntityUpdate } = useRTCStore();

  /**
   *
   * @param type
   * @param data
   */
  const onChangeEntity = useCallback(
    (type, data) => {
      setEntityUpdate({
        type,
        data,
      });
    },
    [rtc]
  );

  return <Component {...props} onChangeEntity={onChangeEntity} rtc={rtc} />;
};

export default withRTC;
