/**
 * Created by piotr.pozniak@thebeaverhead.com on 31/01/2022.
 *
 * NOTE: This suppose to be a hook that returns a modal where user can select
 * what tasks they would like the assignee to be overwritten.
 * Because some of pages components are classes, they don't support hooks. This function
 * is a fallback to support both arrow function and class components.
 */
import axios from "axios";
import AxiosConfig from "../AxiosConfig";
import { UserRole } from "../consts/user";

/**
 * Pulls fresh tasks data from the backend and shows a modal if selected tasks are assigned already.
 * If so, it will show list of tasks with assignee and 3 options to choose:
 * 1. Cancel - cancels the operation
 * 2. No - will assign tasks that are unclaimed.
 * 3. Yes - will assign all tasks.
 *
 * @param tasks array of tasks objects that are the subject of assignation
 * @param assignee user who is about to be asssigned to these tasks
 * @param authUser session user
 * @param submitAction a callback function to be called when no/yes action selected.
 * @returns {Promise<boolean>}
 */
export const updateTasksAssignees = async (
  tasks,
  assignee,
  authUser,
  submitAction
) => {
  // pull all tasks info about tasks
  const actualTasks = await axios
    .post(
      AxiosConfig.getEndpointAddress() + "/api/tasks/bulk_details.json",
      { tasks_ids: tasks.map((i) => i.id) },
      AxiosConfig.getAuthConfig()
    )
    .then((result) => {
      return result.data;
    });

  if (!actualTasks.tasks || !actualTasks.tasks.length) {
    window.alert(
      "Could not pull tasks details. Retry to change task's assignee."
    );
    return false;
  }

  const assigneeMismatchTasks = actualTasks.tasks.filter((actualTask) => {
    const oldTask = tasks.find((i) => i.id === actualTask.id);

    if (!actualTask.assignee) {
      return false;
    }

    // allow to unassign auth user from task they are assigned
    if (
      actualTask.assignee &&
      oldTask.assignee &&
      actualTask.assignee.id === authUser.id
    ) {
      return false;
    }

    return true;
  });

  /** @var Array allowedTasks tasks that are not assigned */
  const allowedTasks = actualTasks.tasks
    .filter((actualTask) => {
      const oldTask = tasks.find((i) => i.id === actualTask.id);

      return (
        !actualTask.assignee ||
        (actualTask.assignee &&
          oldTask.assignee &&
          oldTask.assignee.id === authUser.id)
      );
    })
    .map((i) => ({
      id: i.id,
    }));

  // display popup if at least one assignee mismatches
  if (assigneeMismatchTasks.length) {
    let tasksList = assigneeMismatchTasks
      .map((i) => {
        return `${i.shot.current_version.name} - ${i.task_type.name} has been ${
          i.assignee ? "assigned to " : "unassigned"
        } ${i.assignee ? i.assignee.name : ""}.`;
      })
      .join("<br/>");

    const plural = assigneeMismatchTasks.length > 1;
    let message = `Following task${
      plural ? "s are" : " is "
    } already assigned:<br/><br/>${tasksList}`;

    const onCancel = async () => {
      submitAction(null);
    };


    // only producer and admin can unassign anyone from a task
    if (
      !assignee &&
      ![UserRole.admin, UserRole.producer].includes(authUser.role)
    ) {
      window.bootbox.alert({
        title: "You are not assigned to this task",
        message: `${message}<br/><br/>Refresh page to see latest updates.`,
        callback: onCancel
      });
      return false;
    }

    message += `<br/><br/>Would you like to overwrite all (Yes) or assign only unclaimed (No)?`;


    const onNo = async () => {
      submitAction(allowedTasks);
    };

    const onYes = async () => {
      submitAction(
        allowedTasks.concat(assigneeMismatchTasks.map((i) => ({ id: i.id })))
      );
    };

    window.bootbox.dialog({
      title: "Overwrite all assigned tasks?",
      message: message,
      buttons: {
        cancel: {
          label: "Cancel",
          className: "btn-link",
          callback: onCancel,
        },
        no: {
          label: "No",
          className: "btn-secondary",
          callback: onNo,
        },
        yes: {
          label: "Yes",
          className: "btn-primary",
          callback: onYes,
        },
      },
    });

    //return window.confirm(message);
  } else if (allowedTasks.length) {
    submitAction(allowedTasks);
  }
  //
};

