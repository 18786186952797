/**
 *
 * @param shot
 * @returns {*}
 */
export const setShotVersionsAsApprovedIfOneIsApproved = (shot) => {
  const isApproved = shot.versions.find((item) => item.is_approved);
  return isApproved
    ? {
        ...shot,
        versions: shot.versions.map((item) => ({ ...item, is_approved: 1 })),
      }
    : { ...shot };
};
