import React from "react";
import PropTypes from "prop-types";

export default class ClientInvoiceAddressForm extends React.Component {
  componentDidMount() {
    if (this.props.client !== null) {
      this.updateForm();
    }
  }

  /**
   *
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.client !== this.props.client && this.props.client !== null) {
      this.updateForm();
    }
  }

  /**
   *
   */
  updateForm = () => {
    const {
      billing_name,
      billing_email,
      billing_address_line_1,
      billing_address_line_2,
      billing_city,
      billing_state,
      billing_country,
      billing_zip
    } = this.props.client;
    document.querySelector('.js-client-billing-name').value = billing_name || '';
    document.querySelector('.js-client-billing-email').value = billing_email || '';
    document.querySelector('.js-client-billing-address-1').value = billing_address_line_1 || '';
    document.querySelector('.js-client-billing-address-2').value = billing_address_line_2 || '';
    document.querySelector('.js-client-billing-city').value = billing_city || '';
    document.querySelector('.js-client-billing-state').value = billing_state || '';
    document.querySelector('.js-client-billing-country').value = billing_country || '';
    document.querySelector('.js-client-billing-zip').value = billing_zip || '';
  };

  /**
   *
   * @returns {*}
   */
  render() {
    return (
      <>
        <div className="form-group">
          <label className="form-label">
            Billing contact name
          </label>
          <input
            type="text"
            className="form-control js-client-billing-name"
            placeholder="Name"
            disabled={this.props.disabled}
          />
        </div>
        <div className="form-group">
          <label className="form-label">
            Billing contact email
          </label>
          <input
            type="text"
            className="form-control js-client-billing-email"
            placeholder="Email"
            disabled={this.props.disabled}
          />
        </div>

        <legend className="text-uppercase font-size-sm font-weight-bold">Billing address</legend>

        <div className="form-group">
          <label className="form-label">Address line 1</label>
          <input
            type="text"
            className="form-control js-client-billing-address-1"
            placeholder={this.props.showPlaceholder ? "Address line 1" : null}
            disabled={this.props.disabled}
          />
        </div>

        <div className="form-group">
          <label className="form-label">Address line 2</label>
          <input
            type="text"
            className="form-control js-client-billing-address-2"
            placeholder={this.props.showPlaceholder ? "Address line 2" : null}
            disabled={this.props.disabled}
          />
        </div>

        <div className="row">

          <div className="col-sm-3">
            <div className="form-group">
              <label className="form-label">City</label>
              <input
                type="text"
                className="form-control js-client-billing-city"
                placeholder={this.props.showPlaceholder ? "City" : null}
                disabled={this.props.disabled}
              />
            </div>
          </div>

          <div className="col-sm-3">
            <div className="form-group">
              <label className="form-label">State</label>
              <input
                type="text"
                className="form-control js-client-billing-state"
                placeholder={this.props.showPlaceholder ? "State" : null}
                disabled={this.props.disabled}
              />
            </div>
          </div>

          <div className="col-sm-3">
            <div className="form-group">
              <label className="form-label">Country</label>
              <input
                type="text"
                defaultValue="USA"
                className="form-control js-client-billing-country"
                placeholder={this.props.showPlaceholder ? "Country" : null}
                disabled={this.props.disabled}
              />
            </div>
          </div>

          <div className="col-sm-3">
            <div className="form-group">
              <label className="form-label">Zip Code</label>
              <input
                type="tel"
                className="form-control js-client-billing-zip"
                placeholder="Zip Code"
                disabled={this.props.disabled}
              />
            </div>
          </div>
        </div>

      </>
    );
  }
}

ClientInvoiceAddressForm.defaultProps = {
  showPlaceholder: true,
};

ClientInvoiceAddressForm.propTypes = {
  disabled: PropTypes.bool,
  showPlaceholder: PropTypes.bool,
  client: PropTypes.object,
};
