/**
 * Created by piotr.pozniak@thebeaverhead.com on 13/12/2021.
 */
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as bidActions from "../actions/bid";
import {
  bidInvoicePDF,
  bulkCreateShots,
  createBid,
  editBid,
  fetchBidVersionsByBid,
  previewBidInvoice, setEditBid
} from "../actions/bid";

export function useBidStore() {
  const dispatch = useDispatch();
  const _bid = useSelector((store) => store.bid);

  const fetchBid = useCallback(
    async (id) => await dispatch(bidActions.fetchBid(id)),
    [dispatch]
  );

  const createBid = useCallback(
    async (projectId, data) =>
      await dispatch(bidActions.createBid(projectId, data)),
    [dispatch]
  );

  const editBid = useCallback(
    async (id, data) => await dispatch(bidActions.editBid(id, data)),
    [dispatch]
  );

  const fetchBidVersionsByBid = useCallback(
    async (bidId, page, limit) =>
      await dispatch(bidActions.fetchBidVersionsByBid(bidId, page, limit)),
    [dispatch]
  );

  const bulkCreateShots = useCallback(
    async (shots) =>
      await dispatch(bidActions.bulkCreateShots(shots)),
    [dispatch]
  );

  const previewBidInvoice = useCallback(
    async (bidVersionId, bid, project) =>
      await dispatch(bidActions.previewBidInvoice(bidVersionId, bid, project)),
    [dispatch]
  );

  const bidInvoicePDF = useCallback(
    async (bidVersionId, fileName) =>
      await dispatch(bidActions.bidInvoicePDF(bidVersionId, fileName)),
    [dispatch]
  );

  const setEditBid = useCallback(
    async (data) =>
      await dispatch(bidActions.setEditBid(data)),
    [dispatch]
  );

  return {
    bid: _bid,
    fetchBid,
    createBid,
    editBid,
    fetchBidVersionsByBid,
    bulkCreateShots,
    previewBidInvoice,
    bidInvoicePDF,
    setEditBid
  };
}
