import React, { Component } from "react";

import Validator from "validatorjs";
import { stringToColour } from "../helpers/tools";

import Select from "../components/Select";
import ColorSelector from "../components/ColorSelector";
import LoadingIndicator from "../components/LoadingIndicator";
import ApiError from "../components/ApiError";
import FormValidationError from "../components/FormValidationError";

export default class ProfileBasicForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      avatarColor: "",
      formValidationErrors: null,
    };
  }

  /**
   *
   * @param event
   */
  onSubmit = async (event) => {
    event.preventDefault();

    this.setState({ formValidationErrors: null });

    const profile = this.props.profile;
    const allowRoleEdit = this.props.allowRoleEdit;
    const allowEmailEdit = this.props.allowEmailEdit;
    const canSuspendAccount = this.props.canSuspendAccount;

    let updatedProfile = {
      name: document.querySelector(".js-profile-edit-name").value,
      location: document.querySelector(".js-profile-edit-location").value,
      description: document.querySelector(".js-profile-edit-description").value,
      avatar_color: this.state.avatarColor || profile.avatar_color,
    };

    const tsheetsUsername = document.querySelector(
      ".js-profile-edit-tsheets-username"
    ).value;

    if (tsheetsUsername) {
      updatedProfile.tsheets_username = tsheetsUsername;
    }

    if (allowRoleEdit) {
      updatedProfile.role = document.querySelector(
        ".js-profile-edit-role"
      ).value;
    }

    if (allowEmailEdit) {
      updatedProfile.email = document.querySelector(
        ".js-profile-edit-email"
      ).value;
    }

    if (canSuspendAccount) {
      updatedProfile.suspended = document.querySelector(
        "#js-profile-edit-suspended"
      ).checked ? 1 : 0;
    }

    const rules = {
      name: "required|min:2",
      email: allowEmailEdit ? "required|email" : "",
    };
    const validation = new Validator(updatedProfile, rules);

    this.setState({ formValidationErrors: validation.errors.all() });

    if (validation.passes()) {
      await this.props.onSubmit(profile.id, updatedProfile);
      if (this.props.updateSuccess) {
        this.props.onClose();
      }
    }
  };

  /**
   *
   * @param e
   */
  onChangeColor = (e) => {
    this.setState({ avatarColor: e.hex });
  };

  /**
   *
   * @returns {XML}
   */
  render() {
    const profile = this.props.profile || {};
    const allowRoleEdit = this.props.allowRoleEdit;
    const allowEmailEdit = this.props.allowEmailEdit;
    const isLoading = this.props.isLoading;
    const error = this.props.error || null;

    const roles = [
      { value: "artist", label: "Artist" },
      { value: "producer", label: "Producer" },
      { value: "accountant", label: "Accountant" },
      { value: "client", label: "Client" },
      { value: "admin", label: "Admin" },
    ];

    const loadingIndicator = isLoading ? <LoadingIndicator /> : <span />;

    const accountSuspsendCheckbox = this.props.canSuspendAccount ? (
      <div className="form-group">
        <div className={"form-check"}>
          <label className="form-check-label">
            <input
              id={"js-profile-edit-suspended"}
              type="checkbox"
              className="form-check-input-styled"
              disabled={!allowEmailEdit}
              defaultChecked={profile.suspended ? true : false}
            />
            Suspend account
          </label>
        </div>
      </div>
    ) : null;

    return (
      <form noValidate>
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label className="form-label">
                Name <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control js-profile-edit-name"
                placeholder="Name"
                defaultValue={profile.name}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <label className="form-label">Email</label>
              <input
                type="email"
                className="form-control js-profile-edit-email"
                placeholder="Email"
                defaultValue={profile.email}
                disabled={!allowEmailEdit}
              />
            </div>
          </div>
        </div>

        {accountSuspsendCheckbox}

        <div className="form-group">
          <label>Role</label>
          <Select
            data={roles}
            initClassName="js-select2"
            className="js-profile-edit-role"
            defaultValue={profile.role}
            disabled={!allowRoleEdit}
          />
        </div>

        <div className="form-group">
          <label className="form-label">TSheets Username</label>
          <input
            type="text"
            className="form-control js-profile-edit-tsheets-username"
            placeholder="TSheets Username"
            defaultValue={profile.tsheets_username}
          />
        </div>

        <div className="form-group">
          <label className="form-label">Location</label>
          <input
            type="text"
            className="form-control js-profile-edit-location"
            placeholder="Location"
            defaultValue={profile.location}
          />
        </div>

        <div className="form-group">
          <label className="form-label">Description</label>
          <input
            type="text"
            className="form-control js-profile-edit-description"
            placeholder="Description"
            defaultValue={profile.description}
          />
        </div>

        <div className="form-group">
          <label className="form-label">Avatar Color</label>
          <ColorSelector
            onColorChange={this.onChangeColor}
            id="js-profile-edit-avatar-color"
            color={
              this.state.avatarColor ||
              profile.avatar_color ||
              stringToColour(profile.name)
            }
          />
        </div>

        <FormValidationError errors={this.state.formValidationErrors} />
        <ApiError error={error} />

        <div className="d-flex justify-content-between align-items-center">
          {loadingIndicator}
          <div>
            <button
              disabled={isLoading}
              type="button"
              className="btn btn-link"
              onClick={this.props.onClose}
            >
              Cancel
            </button>
            <button
              disabled={isLoading}
              type="button"
              className="btn bg-primary"
              onClick={this.onSubmit}
            >
              Update <i className="mi-done ml-2" />
            </button>
          </div>
        </div>
      </form>
    );
  }
}
