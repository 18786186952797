import React, { Component } from "react";

import ReportsPageTemplate, {
  staticAlertHeader,
  staticAlertBody
} from "./ReportsPageTemplate";
import connect from "react-redux/es/connect/connect";
import * as gainLossActions from "../actions/reportsGainLoss";
import CustomizableDataTable from "../components/CustomizableDataTable";
import moment from "moment";
import StaticAlert from "../components/StaticAlert";
import { formatter, getBidVersionName } from "../helpers/tools";
import { initialState } from "../reducers/filtersReducer";
import { Link } from "react-router-dom";
import StatusBadge from "../components/StatusBadge";

class GainLossReportsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      diagramData: undefined,
      tableData: [],
      currentDataIsPerShot: this.props.filtersReducer.reportsGainLossIsPerShot,
      filter: {
        fromDate: this.props.filtersReducer.reportsGainLossFromDate,
        toDate: this.props.filtersReducer.reportsGainLossToDate,
        isPerShot: this.props.filtersReducer.reportsGainLossIsPerShot
      }
    };

    this.statuses = this.props.statuses.collection.filter(
      i => i.type === "Projects"
    );

    this.bidsColumns = [
      {
        name: "Client name",
        selector: "client_name",
        sortable: true
      },
      {
        name: "Project - Bid",
        selector: "name",
        sortable: true,
        cell: row =>
          row && row.bid_href ? (
            <Link to={row.bid_href} className="relative">
              {row.name}
            </Link>
          ) : (
            <div>{row.name}</div>
          )
      },
      {
        name: "Status",
        selector: "status",
        sortable: true,
        cell: row =>
          <StatusBadge status={row.status} statuses={this.statuses}/>
      },
      {
        name: "Required hours",
        selector: "required_hours",
        format: row => !isNaN(row.required_hours) ?
          (Math.round(row.required_hours * 100) / 100) :
          row.required_hours,
        center: true
      },
      {
        name: "Quote",
        selector: "quote",
        sortable: true,
        center: true,
        cell: row =>
          row.quote || row.quote === 0 ? formatter.format(row.quote) : "--"
      },
      {
        name: "Current version",
        selector: "recent_version",
        maxWidth: "100px",
        center: true
      }
    ];
  }

  /**
   *
   * @returns {Promise<void>}
   */
  async componentDidMount() {
    await this.fetchGainLossReports();
    this.setState({
      diagramData: this.getDiagramData(),
      tableData: this.getTableData(this.state.filter.isPerShot),
      currentDataIsPerShot: this.state.filter.isPerShot
    });
  }

  /**
   *
   * @param filter
   */
  onFilter = async filter => {
    let fromDate;
    let toDate;

    filter.forEach(i => {
      if (i.fieldName === "fromDate" && i.value) {
        fromDate = moment(i.value, "YYYY-M-D");
      } else if (i.fieldName === "toDate" && i.value) {
        toDate = moment(i.value, "YYYY-M-D");
      }
    });

    this.setState(
      {
        filter: {
          ...this.state.filter,
          fromDate: fromDate,
          toDate: toDate
        },
        diagramData: undefined, // clear the old chart
        tableData: [] // clear old table
      },
      async () => {
        await this.fetchGainLossReports();
        this.setState({
          diagramData: this.getDiagramData(),
          tableData: this.getTableData(this.state.filter.isPerShot),
          currentDataIsPerShot: this.state.filter.isPerShot
        });
      }
    );
  };

  /**
   *
   * @returns {Promise<*|Promise<*>>}
   */
  fetchGainLossReports = async () => {
    return this.props.reportsGainLossFetch(
      this.state.filter.fromDate
        ? this.state.filter.fromDate.format("YYYY-MM-DD")
        : null,
      this.state.filter.toDate
        ? this.state.filter.toDate.format("YYYY-MM-DD")
        : null,
      this.state.filter.isPerShot ? 1 : 0
    );
  };

  /**
   *
   */
  onClearAll = () => {
    this.setState(
      {
        filter: {
          isPerShot: false
        }
      },
      () => {
        this.onFilter([
          {
            fieldName: "fromDate",
            value: initialState.reportsGainLossFromDate.format("YYYY-M-D")
          },
          { fieldName: "toDate", value: initialState.reportsGainLossToDate.format("YYYY-M-D") }
        ]);
      }
    );
  };

  /**
   *
   * @returns {Object}
   */
  getDiagramData = () => {
    // per shot data
    const lossShots =
      this.state.filter.isPerShot && this.props.reportsGainLoss.collection
        ? this.props.reportsGainLoss.collection
        : {};
    //
    const lossShotsColumnsKeys = [];
    const lossShotsColumns = ["Loss shots"];
    const lossShotsCostColumns = ["Cost"];

    for (const key in lossShots) {
      lossShotsColumnsKeys.push(key);
      lossShotsColumns.push(lossShots[key].count);
      lossShotsCostColumns.push(lossShots[key].total_cost);
    }

    // per bid data
    let lossBids =
      !this.state.filter.isPerShot && this.props.reportsGainLoss.collection
        ? this.props.reportsGainLoss.collection
        : {};
    //
    const lossBidsColumnsKeys = [];
    const lossBidsColumns = ["Loss bids"];
    const lossBidsCostColumns = ["Cost"];
    for (const key in lossBids) {
      lossBidsColumnsKeys.push(key);
      lossBidsColumns.push(lossBids[key].count);
      lossBidsCostColumns.push(lossBids[key].total_cost);
    }

    if (
      Object.keys(lossShots).length === 0 &&
      Object.keys(lossBids).length === 0
    ) {
      return null;
    }

    // display data according to the state filter
    const columns = this.state.filter.isPerShot
      ? [lossShotsColumns, lossShotsCostColumns]
      : [lossBidsColumns, lossBidsCostColumns];
    const columnsKeys = this.state.filter.isPerShot
      ? lossShotsColumnsKeys
      : lossBidsColumnsKeys;
    // yMax used for the y axis labels
    let yMax = 1;
    columns.forEach(i => {
      i.forEach(j => {
        if (!isNaN(j) && j > yMax) {
          yMax = j;
        }
      });
    });
    yMax += 1; // arrays start at 0
    //
    return {
      //new diagram types can be added in public_html_dev/js/app.js, see App.initBarDiagram(settings ? settings.barDiagram : settings);
      barDiagram: [
        {
          bindto: document.getElementById("jsReportsPageDiagram"),
          size: { height: 300 },
          data: {
            columns: columns,
            type: "bar",
            labels: {
              format: {
                Cost: (v, id) => formatter.format(v)
              }
            },
            axes: {
              Cost: "y2"
            }
          },
          color: {
            pattern: ["#2196F3", "#FF9800", "#4CAF50"]
          },
          bar: {
            width: {
              ratio: 0.5
            }
          },
          grid: {
            y: {
              show: true
            }
          },
          axis: {
            x: {
              type: "category",
              categories: columnsKeys
            },
            y: {
              tick: {
                format: function(x) {
                  return x % 1 === 0 ? x : "";
                }
              }
            },
            y2: {
              show: true
            }
          },
          tooltip: {
            format: {
              value: (v, ratio, id, index) => {
                if (id === "Cost") {
                  return formatter.format(v);
                }
                return v;
              }
            }
          }
        }
      ]
    };
  };

  /**
   * @param filterPerShot
   * @returns {[]}
   */
  getTableData = filterPerShot => {
    // per shot data
    const lossShots =
      filterPerShot && this.props.reportsGainLoss.collection
        ? this.props.reportsGainLoss.collection
        : {};
    const shotsDataTableCollection = [];
    for (const key in lossShots) {
      if (!lossShots[key].loss_shots) {
        break;
      }
      lossShots[key].loss_shots.forEach(i => {
        const shotTasksTypesId =
          i.tasks && i.tasks.length ? i.tasks.map(i => i.task_type_id) : [];
        const shotTasksTypes = this.props.tasksTypes.collection.filter(i =>
          shotTasksTypesId.includes(i.id)
        );
        const shotTasks =
          shotTasksTypes && shotTasksTypes.length
            ? shotTasksTypes.map(i => i.name).join(", ")
            : "--";

        shotsDataTableCollection.push({
          shot_number: i.shot_number,
          shot_href:
            i.id && i.project_id
              ? `/project/${i.project_id}/shot/${i.id}`
              : "#",
          description: i.recent_version.description
            ? i.recent_version.description
            : "--",
          tasks: shotTasks,
          bid_note:
            i.recent_version && i.recent_version.notes
              ? i.recent_version.notes
              : "--",
          client_approval: i.recent_version.is_approved ? "Yes" : "No",
          rate: i.recent_version.rate,
          hour: i.recent_version.hours,
          cost:
            i.recent_version && !isNaN(i.recent_version.cost_summary)
              ? i.recent_version.cost_summary
              : 0
        });
      });
    }

    // per bid data
    let lossBids =
      !filterPerShot && this.props.reportsGainLoss.collection
        ? this.props.reportsGainLoss.collection
        : {};
    const bidsDataTableCollection = [];
    for (const key in lossBids) {
      if (!lossBids[key].loss_bids) {
        break;
      }
      lossBids[key].loss_bids.forEach(i => {
        bidsDataTableCollection.push({
          client_name:
            i.project &&
            i.project.client &&
            i.project.client.name
              ? i.project.client.name
              : "--",
          name:
            i.name && i.project && i.project.name
              ? `${i.project.name} - ${i.name}`
              : "--",
          bid_href: i.recent_bid_version && i.recent_bid_version.id ? `/bid/${i.recent_bid_version.id}` : "#",
          status: i.project && i.project.status ? i.project.status : null,
          required_hours:
            i.project && i.project.required_hours
              ? i.project.required_hours
              : "--",
          quote:
            i.total && !isNaN(i.total)
              ? i.total
              : 0,
          recent_version: i.recent_bid_version ? getBidVersionName(i.recent_bid_version) : "--",
        });
      });
    }

    return this.state.filter.isPerShot
      ? shotsDataTableCollection
      : bidsDataTableCollection;
  };

  /**
   *
   * @param e
   */
  perShotFilterOnChange = e => {
    this.setState({
      filter: {
        ...this.state.filter,
        isPerShot: e.target.name === "perShot"
      }
    });
  };

  /**
   *
   * @returns {XML}
   */
  render() {
    const isLoading = this.props.reportsGainLoss.fetch;

    const perShotDataTable =
      this.state.currentDataIsPerShot && this.state.tableData.length > 0 ? (
        <CustomizableDataTable
          collection={this.state.tableData}
          columns={shotsColumns}
        />
      ) : null;
    const perShotDataTableTop =
      this.state.currentDataIsPerShot && this.state.tableData.length > 0 ? (
        <div>
          <h3>Total number of loss shots: {this.state.tableData.length}</h3>
          <h3>
            Total cost:{" "}
            {formatter.format(
              this.state.tableData.reduce((acc, curr) => acc + curr.cost, 0)
            )}
          </h3>
        </div>
      ) : null;
    const perBidDataTable =
      !this.state.currentDataIsPerShot && this.state.tableData.length > 0 ? (
        <CustomizableDataTable
          collection={this.state.tableData}
          columns={this.bidsColumns}
        />
      ) : null;
    const perBidDataTableTop =
      !this.state.currentDataIsPerShot && this.state.tableData.length > 0 ? (
        <div>
          <h3>Total number of loss bids: {this.state.tableData.length}</h3>
          <h3>
            Total quote:{" "}
            {formatter.format(
              this.state.tableData.reduce((acc, curr) => acc + curr.quote, 0)
            )}
          </h3>
        </div>
      ) : null;

    // empty table alert
    let staticAlert = null;
    if (this.state.currentDataIsPerShot && !this.state.tableData.length) {
      staticAlert = (
        <StaticAlert header={staticAlertHeader} body={staticAlertBody} />
      );
    } else if (
      !this.state.currentDataIsPerShot &&
      !this.state.tableData.length
    ) {
      staticAlert = (
        <StaticAlert header={staticAlertHeader} body={staticAlertBody} />
      );
    }

    return (
      <ReportsPageTemplate
        header="Gain/Loss reports"
        filters={[
          {
            placeholderFrom: "From date",
            placeholderTo: "To date",
            fieldNameFrom: "fromDate",
            fieldNameTo: "toDate",
            fieldName: "dateRange",
            defaultValueFrom: this.state.filter.fromDate
              ? this.state.filter.fromDate.format("YYYY-MM-DD")
              : "",
            defaultValueTo: this.state.filter.toDate
              ? this.state.filter.toDate.format("YYYY-MM-DD")
              : "",
            type: "dateRange",
            wrapperClassName: "col-sm-6"
          },
          {
            placeholder: "Per shot/bid",
            type: "radio",
            onChange: this.perShotFilterOnChange,
            fieldName: "perShotRadio",
            radio: [
              {
                name: "perShot",
                label: "Per shot",
                checked: this.state.filter.isPerShot
              },
              {
                name: "perBid",
                label: "Per bid",
                checked: !this.state.filter.isPerShot
              }
            ],
            wrapperClassName: "col-sm-3"
          }
        ]}
        onFilter={this.onFilter}
        onClearAll={this.onClearAll}
        diagramData={this.state.diagramData}
        isLoading={isLoading}
      >
        {perShotDataTableTop}
        {perShotDataTable}
        {perBidDataTableTop}
        {perBidDataTable}
        {staticAlert}
      </ReportsPageTemplate>
    );
  }
}

const mapStateToProps = state => ({
  reportsGainLoss: state.reportsGainLoss,
  tasksTypes: state.tasksTypes,
  statuses: state.statuses,
  filtersReducer: state.filtersReducer,
});

const mapDispatchToProps = dispatch => ({
  reportsGainLossFetch: async (dateFrom, dateTo, isPerShot, type) =>
    await dispatch(
      gainLossActions.reportsGainLossFetch(dateFrom, dateTo, isPerShot, type)
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GainLossReportsPage);

const shotsColumns = [
  {
    name: "Shot number",
    selector: "shot_number",
    center: true,
    cell: row =>
      row && row.shot_href ? (
        <Link to={row.shot_href} className="relative">
          {row.shot_number}
        </Link>
      ) : (
        <div>{row.shot_number}</div>
      )
  },
  {
    name: "Description",
    selector: "description",
    center: true
  },
  {
    name: "Tasks",
    selector: "tasks",
    center: true,
    cell: row => <div style={{ flexWrap: "nowrap" }}>{row.tasks}</div>
  },
  {
    name: "Bid note",
    selector: "bid_note",
    center: true
  },
  {
    name: "Client approval",
    selector: "client_approval",
    center: true
  },
  {
    name: "Rate",
    selector: "rate",
    center: true,
    cell: row => <div>{formatter.format(row.rate)}</div>
  },
  {
    name: "Hour",
    selector: "hour",
    format: row => (Math.round((row.hour || 0) * 100) / 100),
    center: true
  },
  {
    name: "Cost",
    selector: "cost",
    center: true,
    cell: row => <div>{formatter.format(row.cost)}</div>
  }
];
