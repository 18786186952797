import React, {useMemo} from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

const ShotsTasksAssignees = (props) => {
  const listOfArtists = useMemo(
    () =>
      props.assignees.length ? (
        props.assignees.map((i, index) => {
          return (
            <tr key={index}>
              <td>{i.assignee.name}</td>
              <td>
                {i.tasks.length} Tasks in {i.shots.length} shots
              </td>
            </tr>
          );
        })
      ) : (
        <tr>
          <td>--</td>
          <td>--</td>
        </tr>
      ),
    [props.assignees]
  );

  const className = classNames("card", props.className);

  return (
    <div className={className}>
      <div className="card-header bg-primary text-white header-elements-inline">
        <h6 className="card-title">List of Artists</h6>
        <div className="header-elements" />
      </div>

      <div className="card-body">
        <table className="result-table result-table_pure-last">
          <tbody>{listOfArtists}</tbody>
        </table>
      </div>
    </div>
  );
};

ShotsTasksAssignees.propTypes = {
  className: PropTypes.string,
  assignees: PropTypes.arrayOf(
    PropTypes.shape({
      assignee: PropTypes.shape({
        name: PropTypes.string,
      }),
      tasks: PropTypes.array,
      shots: PropTypes.array,
    })
  ),
};

ShotsTasksAssignees.defaultProps = {
  assignees: [],
};

export default ShotsTasksAssignees;
