import React from "react";
import PropTypes from "prop-types";
import FormValidationError from "../../../components/FormValidationError";
import ApiError from "../../../components/ApiError";

const BidForm = (props) => {
  const { bid, isLoading, formValidationErrorsResult } = props;

  return (
    <form noValidate>
      <div className="form-group">
        <label className="form-label">
          Bid Name <span className="text-danger">*</span>
        </label>
        <input
          type="text"
          className="form-control js-bid-edit-name"
          placeholder="Bid Name"
          disabled={isLoading}
        />
      </div>

      <div className="row">
        <div className="form-group col-6">
          <div className="form-check">
            <label className="form-check-label">
              <input
                type="checkbox"
                className="form-check-input-styled js-bid-edit-use-shots-as-hours-bid"
                data-fouc
                defaultChecked={false}
                disabled={isLoading}
              />
              Switch Hours and Shots column
            </label>
          </div>
        </div>
        <div className="form-group col-6">
          <div className="form-check">
            <label className="form-check-label">
              <input
                type="checkbox"
                className="form-check-input-styled js-bid-edit-show-rate-in-bid-pdf"
                data-fouc
                defaultChecked={false}
                disabled={isLoading}
              />
              Show rate in bid PDF
            </label>
          </div>
        </div>
      </div>

      <FormValidationError errors={formValidationErrorsResult} />
      <ApiError error={bid.fetchError || bid.createError || bid.editError} />
    </form>
  );
};

BidForm.propTypes = {
  bid: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  formValidationErrorsResult: PropTypes.object,
};

export default BidForm;
