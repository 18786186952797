import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import connect from "react-redux/es/connect/connect";

class ModalTemplate extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  /**
   *
   */
  componentDidMount() {
    window.updateJQuery();
  };

  /**
   *
   * @returns {XML}
   */
  render() {
    const isAuthorized = !!(this.props.user.token && this.props.user.model);

    const content = this.props.children;

    const {
      cancelButtonLabel,
      confirmButtonLabel,
      onCancel,
      onConfirm,
      title,
      loadingIndicator,
      disableConfirmButton,
      disableCancelButton,
    } = this.props;

    const modalDialogClassName = classNames(
      "modal-dialog",
      this.props.className,
    );

    const modalContentClassName = classNames(
      "modal-content",
      this.props.modalContentClassName,
    );

    return (
      isAuthorized ?
        <>
          <div
            className="modal fade show"
            tabIndex={-1}
            style={{display: "block", overflowY: "auto"}}
          >
            <div className={modalDialogClassName}>
              <div className={modalContentClassName}>
                <div className="modal-header">
                  <h5 className="modal-title">{title}</h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={this.props.onClose}
                    disabled={disableCancelButton}
                  >&times;</button>
                </div>

                <div className="modal-body">
                  {content}
                </div>

                <div className="modal-footer" style={{justifyContent: "space-between"}}>
                  <div>
                    {loadingIndicator}
                  </div>
                  <div>
                    {cancelButtonLabel && (
                      <button
                        type="button"
                        className="btn btn-link"
                        data-dismiss="modal"
                        onClick={onCancel}
                        disabled={disableCancelButton}
                      >{cancelButtonLabel}</button>
                    )}
                    {confirmButtonLabel && (
                      <button
                        type="button"
                        className="btn bg-primary"
                        onClick={disableConfirmButton ? null : onConfirm}
                        disabled={disableConfirmButton}
                      >{confirmButtonLabel}</button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show"/>
        </> : null
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(
  mapStateToProps,
)(ModalTemplate);

ModalTemplate.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  onClose: PropTypes.func.isRequired,
  cancelButtonLabel: PropTypes.string,
  confirmButtonLabel: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  loadingIndicator: PropTypes.node,
  disableConfirmButton: PropTypes.bool,
  disableCancelButton: PropTypes.bool,
  className: PropTypes.string,
  modalContentClassName: PropTypes.string,
};
