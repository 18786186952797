import AxiosConfig from "../AxiosConfig";
import axios from "axios";

/**
 *
 * @param id
 * @returns {function(...[*]=)}
 */
export const fetchBid = (id) => {
  return async (dispatch) => {
    dispatch({ type: "BID_FETCH", bidId: id });

    const url = AxiosConfig.getEndpointAddress() + "/api/bids/" + id + ".json";

    await axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "BID_FETCH_FULFILLED",
          data: response.data,
          bidId: id
        });
      })
      .catch(function (error) {
        dispatch({
          type: "BID_FETCH_REJECTED",
          data: error,
          bidId: id
        });
      });
  };
};

/**
 *
 * @param projectId
 * @param data
 * @returns {function(...[*]=)}
 */
export const createBid = (projectId, data) => {
  return async (dispatch) => {
    dispatch({ type: "BID_CREATE" });

    const url = AxiosConfig.getEndpointAddress() + "/api/bids.json";

    await axios
      .post(
        url,
        { project_id: projectId, ...data },
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "BID_CREATE_FULFILLED",
          data: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "BID_CREATE_REJECTED",
          data: error,
        });
      });
  };
};

/**
 *
 * @param id
 * @param data
 * @returns {function(...[*]=)}
 */
export const editBid = (id, data) => {
  return async (dispatch) => {
    dispatch({ type: "BID_EDIT" });

    const url = AxiosConfig.getEndpointAddress() + "/api/bids/" + id + ".json";

    await axios
      .put(url, data, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "BID_EDIT_FULFILLED",
          data: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "BID_EDIT_REJECTED",
          data: error,
        });
      });
  };
};

/**
 *
 * @param bidId
 * @param page
 * @param limit
 * @returns {function(...[*]=)}
 */
export const fetchBidVersionsByBid = (bidId, page = 1, limit = 100) => {
  return async (dispatch) => {
    dispatch({ type: "BID_VERSIONS_BY_BID_FETCH" });

    const url =
      AxiosConfig.getEndpointAddress() +
      "/api/bid_versions.json?" +
      AxiosConfig.objectToURLQuery({ bid_id: bidId, page, limit });

    await axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "BID_VERSIONS_BY_BID_FETCH_FULFILLED",
          data: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "BID_VERSIONS_BY_BID_FETCH_REJECTED",
          data: error,
        });
      });
  };
};

/**
 *
 * @param shots
 * @returns {function(...[*]=)}
 */
export const bulkCreateShots = (shots) => {
  return async (dispatch) => {
    dispatch({ type: "SHOTS_CREATE_BULK" });

    const url =
      AxiosConfig.getEndpointAddress() + "/api/shots/create_bulk.json";

    await axios
      .post(url, { data: shots }, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "SHOTS_CREATE_BULK_FULFILLED",
          data: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "SHOTS_CREATE_BULK_REJECTED",
          data: error,
        });
      });
  };
};

/**
 *
 * @param bidVersionId
 * @param bid
 * @param project
 * @returns {function(...[*]=)}
 */
export const previewBidInvoice = (bidVersionId, bid, project) => {
  return async (dispatch) => {
    const url =
      AxiosConfig.getEndpointAddress() +
      "/bid_versions/generate_preview/" +
      bidVersionId;

    await axios
      .get(url, {
        headers: {
          ...AxiosConfig.getAuthConfig().headers,
          Accept: "text/html",
          "Content-Type": "text/html",
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "text/html" })
        );
        const pdfWindow = window.open(url);
        const currentDate = new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }).format(new Date());
        setTimeout(() => {
          if (pdfWindow && pdfWindow.document) {
            pdfWindow.document.title = `Bid - ${project.name} (${project.code}) - ${currentDate}`;
          }
        }, 200);
      })
      .catch(function (error) {});
  };
};

/**
 *
 * @param bidVersionId
 * @param fileName
 * @returns {function(...[*]=)}
 */
export const bidInvoicePDF = (bidVersionId, fileName) => {
  return async (dispatch) => {
    const url =
      AxiosConfig.getEndpointAddress() +
      "/bid_versions/generate_pdf/" +
      bidVersionId +
      ".pdf";

    await axios
      .get(url, {
        ...AxiosConfig.getAuthConfig(),
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName + ".pdf");
        document.body.appendChild(link);
        link.click();
      })
      .catch(function (error) {});
  };
};

/**
 *
 * @param data
 * @returns {function(...[*]=)}
 */
export const setEditBid = (data) => {
  return (dispatch) => {
    dispatch({ type: "SET_BID_EDIT", data });
  };
};
