/**
 * Created by piotr.pozniak@thebeaverhead.com on 09/07/2024
 */

import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import CustomizableDataTable from "../../components/CustomizableDataTable";
import StaticAlert from "../../components/StaticAlert";
import { Link, useParams } from "react-router-dom";
import StatusBadge from "../../components/StatusBadge";
import { formatter } from "../../helpers/tools";
import { useReportsInvoicesStore } from "../../storeHooks/reportsInvoicesStore";
import { useStatusesStore } from "../../storeHooks/statuses";
import InvoiceIssuedDateFormatter from "../../components/DataTable/Formatters/InvoiceIssuedDateFormatter";
import TotalFormat from "../BidsPage/TotalFormat";
import ShotsQuoteSubtotalFormatter from "../../components/DataTable/Formatters/ShotsQuoteSubtotalFormatter";
import AdditionalCostsSubtotalFormatter from "../../components/DataTable/Formatters/AdditionalCostsSubtotalFormatter";
import DiscountRushInvoiceFormatter from "../../components/DataTable/Formatters/DiscountRushInvoiceFormatter";
import ProjectInvoiceTotalFormat from "../ProjectInvoicesPage/components/ProjectInvoiceTotalFormat";

const reportsInvoicesDataTableConfig = ({ invoiceStatuses }) => ({
  columns: [
    {
      name: "Project",
      selector: "name",
      sortable: true,
      format: (row) => (
        <Link className="relative" to={`/invoice/${row.project_id}`}>
          {row.name}
        </Link>
      ),
    },
    {
      name: "Invoice",
      selector: "invoice",
      center: true,
      sortable: true,
    },
    {
      name: "Date issued",
      selector: "created",
      format: InvoiceIssuedDateFormatter,
      sortable: true,
    },
    {
      name: "Invoice status",
      selector: "status",
      center: true,
      sortable: true,
      format: (row) => (
        <StatusBadge status={row.status} statuses={invoiceStatuses} />
      ),
    },
    {
      name: "Shots quote subtotal",
      selector: "shots_quote_subtotal_from_latest_versions",
      center: true,
      sortable: true,
      format: ShotsQuoteSubtotalFormatter,
    },
    {
      name: "Additional costs subtotal",
      selector: "additional_costs_subtotal_from_recent_bid",
      center: true,
      sortable: true,
      format: AdditionalCostsSubtotalFormatter,
    },
    {
      name: "Discount/rush",
      selector: "discount_from_recent_bid",
      center: true,
      sortable: true,
      format: DiscountRushInvoiceFormatter,
    },
    {
      name: "Total",
      selector: "total",
      center: true,
      sortable: true,
      format: (row) => <ProjectInvoiceTotalFormat invoice={row} bid={row} />,
    },
    {
      name: "PO Number",
      selector: "po_number",
      center: true,
      sortable: true,
    },
  ],
});

const Invoices = (props) => {
  const params = useParams();
  const { reportsInvoices, fetchInvoicesReports } = useReportsInvoicesStore();
  const { statuses } = useStatusesStore();

  console.log(statuses);
  useEffect(() => {
    fetchInvoicesReports(null, null, null, [params.id]);
  }, []);

  const reportsInvoicesCollection = [];
  reportsInvoices.collection.forEach((client) => {
    client.invoices.forEach((invoice) => {
      reportsInvoicesCollection.push({
        name: invoice.bid.project.name,
        created: invoice.created,
        invoice: invoice.create_order,
        status: invoice.status,
        number_of_shots: invoice.shots.length,
        shots_quote_subtotal_from_latest_versions:
          invoice.shots_quote_subtotal_from_latest_versions,
        additional_costs_subtotal_from_recent_bid_version:
          invoice.additional_costs_subtotal_from_recent_bid,
        total: invoice.total,
        additional_costs: invoice.additional_costs,
        notes: "--",
        project_id: invoice.bid.project.id,
        po_number: invoice.po_number,
        type: invoice.bid.deposit_po_number ? "deposit_invoice" : "invoice",
      });
    });
  });

  const tableConfig = useMemo(
    () =>
      reportsInvoicesDataTableConfig({
        invoicesStatuses: statuses.collection.filter(
          (i) => i.type === "Invoices"
        ),
      }),
    []
  );

  const reportsInvoicesDatatable =
    reportsInvoicesCollection && reportsInvoicesCollection.length ? (
      <CustomizableDataTable
        collection={reportsInvoicesCollection}
        columns={tableConfig.columns}
      />
    ) : null;

  const reportsInvoicesDataTableAlert = reportsInvoicesCollection &&
    reportsInvoicesCollection.length === 0 && (
      <StaticAlert
        header={"No records to display"}
        body={"This table lists all invoices reports."}
      />
    );

  return (
    <div className="row mb-3">
      <div className="col">
        <div
          className={
            "data-table-themed data-table-themed_not-stretch data-table-themed_short"
          }
        >
          <div className="card-header">
            <h5 className={"card-title"}>Invoices</h5>
          </div>
          {reportsInvoicesDatatable}
        </div>
        {reportsInvoicesDataTableAlert}
      </div>
    </div>
  );
};

Invoices.defaultProps = {};

Invoices.propTypes = {};

export default Invoices;
