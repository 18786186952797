/**
 * Created by piotr.pozniak@thebeaverhead.com on 24/01/2022.
 */
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as statusesActions from "../actions/statuses";

export function useStatusesStore() {

  const dispatch = useDispatch();
  const _statuses = useSelector((store) => store.statuses);

  const fetchStatuses = useCallback(
    async () =>
      await dispatch(statusesActions.fetchStatuses()),
    [dispatch]
  );

  const createStatus = useCallback(
    async (data) =>
      await dispatch(statusesActions.createStatus(data)),
    [dispatch]
  );

  const editStatus = useCallback(
    async (status_id, data) =>
      await dispatch(statusesActions.editStatus(status_id, data)),
    [dispatch]
  );
    const deleteStatus = useCallback(
    async (status_id) =>
      await dispatch(statusesActions.deleteStatus(status_id)),
    [dispatch]
  );



  return {
    statuses: _statuses,
    fetchStatuses,
    createStatus,
    editStatus,
    deleteStatus
  };
}
