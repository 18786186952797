import axios from 'axios';
import AxiosConfig from '../AxiosConfig';

/**
 *
 * @param page
 * @param limit
 * @param filters
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export const fetchProjects = (page = 1, limit = 20, filters) => {

  return dispatch => {

    dispatch({type: "CLIENT_PROJECTS_FETCH"});

    const url = AxiosConfig.getEndpointAddress() + '/api/projects.json?' +
      AxiosConfig.objectToURLQuery({page, limit, ...filters});

    return axios.get(
      url,
      AxiosConfig.getAuthConfig(),
    )
      .then((response) => {

        dispatch({
          type: "CLIENT_PROJECTS_FETCH_FULFILLED",
          data: response.data,
        });

      })
      .catch(function (error) {

        dispatch({
          type: "CLIENT_PROJECTS_FETCH_REJECTED",
          data: error,
        });

      });
  }
};