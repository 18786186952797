/**
 * Created by piotr.pozniak@thebeaverhead.com on 09/07/2024
 */

import React from "react";
import PropTypes from "prop-types";
import { formatter } from "../../../helpers/tools";

const ShotsQuoteSubtotalFormatter = (row) => {
  if (row.type && row.type === "deposit_invoice") {
    return "--";
  }

  console.log(row);

  return formatter.format(
    row.shots_quote_subtotal_from_latest_versions
      ? row.shots_quote_subtotal_from_latest_versions
      : 0
  );
};

ShotsQuoteSubtotalFormatter.defaultProps = {};

ShotsQuoteSubtotalFormatter.propTypes = {};

export default ShotsQuoteSubtotalFormatter;
