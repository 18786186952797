import AxiosConfig from "../AxiosConfig";
import axios from "axios";

/**
 *
 * @returns {Function}
 */
export const fetchIntegrations = () => {

  return async dispatch => {

    dispatch({type: "INTEGRATIONS_FETCH"});

    const url = AxiosConfig.getEndpointAddress() + '/api/settings/read/App.json';

    await axios.get(
      url,
      AxiosConfig.getAuthConfig(),
    )
      .then((response) => {

        dispatch({
          type: "INTEGRATIONS_FETCH_FULFILLED",
          data: response.data,
        });

      })
      .catch(function (error) {

        dispatch({
          type: "INTEGRATIONS_FETCH_REJECTED",
          data: error,
        });

      });
  }
};

/**
 *
 * @param data
 * @returns {Function}
 */
export const updateIntegrations = data => {

  return async dispatch => {

    dispatch({type: "INTEGRATIONS_UPDATE"});

    const url = AxiosConfig.getEndpointAddress() + '/api/settings/write.json';

    await axios.post(
      url,
      {settings: data},
      AxiosConfig.getAuthConfig(),
    )
      .then((response) => {

        dispatch({
          type: "INTEGRATIONS_UPDATE_FULFILLED",
          data: response.data,
        });

      })
      .catch(function (error) {

        dispatch({
          type: "INTEGRATIONS_UPDATE_REJECTED",
          data: error,
        });

      });
  }
};