import AxiosConfig from "../AxiosConfig";
import axios from "axios";

/**
 *
 * @param fromDate
 * @param toDate
 * @param artists
 * @param clients
 * @param projects
 * @param type
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export const reportsArtistsFetch = (fromDate, toDate, artists = [], clients = [], projects = [], type) => {

  return dispatch => {

    dispatch({
      type: "ARTISTS_REPORTS_FETCH",
      data: {
        fromDate,
        toDate,
        artists: [...artists],
        clients: [...clients],
        projects: [...projects],
        type,
      }
    });

    const query = {};
    if (fromDate) {
      query.start_date = fromDate;
    }
    if (toDate) {
      query.end_date = toDate;
    }
    if (artists && artists.length) {
      query.artists = artists.map(i => i.value);
    }
    if (clients && clients.length) {
      query.clients = clients.map(i => i.value);
    }
    if (projects && projects.length) {
      query.projects = projects.map(i => i.value);
    }

    const url = AxiosConfig.getEndpointAddress() + '/api/statistics/artist.json?'
      + AxiosConfig.objectToURLQuery(query);

    return axios.get(
      url,
      AxiosConfig.getAuthConfig(),
    )
      .then((response) => {

        dispatch({
          type: "ARTISTS_REPORTS_FETCH_FULFILLED",
          data: response.data,
        });

      })
      .catch(function (error) {

        dispatch({
          type: "ARTISTS_REPORTS_FETCH_REJECTED",
          data: error,
        });

      });

  }
};
