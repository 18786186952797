import React, { Component, useEffect, useState } from "react";
import PropTypes from "prop-types";
import VariablesString from "../../../helpers/VariablesString";
import StaticAlert from "../../../components/StaticAlert";
import connect from "react-redux/es/connect/connect";
import * as appActions from "../../../actions/app";
import * as bidActions from "../../../actions/bid";
import { Link, withRouter } from "react-router-dom";
import CustomizableDataTable from "../../../components/CustomizableDataTable";
import LoadingIndicator from "../../../components/LoadingIndicator";
import DropdownMenu from "../../../components/DropdownMenu";
import BidInvoiceTotalFormat from "./BidInvoiceTotalFormat";
import BidInvoiceStatusFormat from "./BidInvoiceStatusFormat";
import {
  getDepositInvoice,
  filterArrayUniqueByKey,
  filterAllowedRoles,
  formatter,
} from "../../../helpers/tools";
import { history } from "../../../configureStore";
import ApiError from "../../../components/ApiError";
import classNames from "classnames";
import * as moment from "moment";
import { useBidsInvoiceStore } from "../../../storeHooks/bidsInvoice";
import { useBidStore } from "../../../storeHooks/bid";
import { useUserStore } from "../../../storeHooks/user";
import { useBidInvoiceStore } from "../../../storeHooks/bidInvoice";

const staticBidInvoicesAlertHeader = "No records to display";
const staticBidInvoicesAlertBody = <>This table lists project's invoices.</>;

const BidInvoicesTable = (props) => {
  const [state, setState] = useState({
    editInvoiceId: null,
    bulkUpdate: false,
    isLoaded: false,
  });

  const statuses = props.statuses.collection.filter(
    (i) => i.type === "Invoices"
  );

  const {
    fetchBidInvoices,
    depositInvoicePDF,
    editInvoice,
    invoicePDF: _invoicePDF,
    editDepositInvoiceStatus,
    deleteInvoice,
  } = useBidsInvoiceStore();

  const { bidInvoices } = props;

  const bid = props.bid;
  const { editBid } = useBidStore();
  const { user } = useUserStore();

  /**
   *
   */
  useEffect(() => {
    if (user.token && user.model) {
      fetchBidInvoices(getBidId()).then(() => {
        setState({ ...state, isLoaded: true });
        window.updateJQuery();
      });
    }
  }, []);

  useEffect(() => {
    if (!bidInvoices.edit) {
      setState({ ...state, bulkUpdate: false, editInvoiceId: null });
    }

    if (bid.fetchSuccess || bidInvoices.fetchSuccess) {
      window.updateJQuery();
    }
  }, [bidInvoices.edit, bid.fetchSuccess, bidInvoices.fetchSuccess]);

  /**
   *
   * @returns {*|null}
   */
  const getBidId = () => {
    if (props.match.params.bidId) {
      return props.match.params.bidId;
    }
    if (bid && bid.model && bid.model.id) {
      return bid.model.id;
    }
    return null;
  };

  /**
   *
   * @param data
   * @param bulkActions
   */
  const bulkUpdateAction = async (data, bulkActions) => {
    const statusObj = bulkActions.find((i) => i.fieldName === "status");

    const statusValue = statusObj ? statusObj.value : null;

    for (let i = 0; i < data.selectedRows.length; i++) {
      setState({ ...state, bulkUpdate: true });
      window.updateJQuery();
      const row = data.selectedRows[i];
      await editInvoice(row.id, { status: statusValue, bidId: row.bid.id });
    }
  };

  let dataTableColumns = [
    {
      name: "Invoice number",
      selector: "create_order",
      format: (row) => {
        const cellClassName = classNames("js-customizable-table-to-highlight", {
          disabled: row.type === "deposit_invoice",
        });
        return (
          <div className={cellClassName}>
            {row.create_order ? row.create_order : "--"}
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Number of shots",
      selector: "shots",
      format: (row) => {
        if (row.type === "deposit_invoice") {
          return "--";
        }
        return row.shots ? row.shots.length : "0";
      },
    },
    {
      name: "Date issued",
      selector: "created",
      format: (row) =>
        row.created ? moment.unix(row.created).format("DD/MM/YY, HH:mm") : "--",
    },
    {
      name: "Invoice status",
      selector: "status",
      cell: (row) => (
        <BidInvoiceStatusFormat
          invoice={row}
          onChange={onStatusChange}
          editInvoiceId={state.editInvoiceId}
          allowStatusEdit={props.allowStatusEdit}
          projectInvoices={bidInvoices}
        />
      ),
      width: "162px",
    },
    {
      name: "Shots quote subtotal",
      selector: "shots_quote_subtotal_from_latest_versions",
      center: true,
      format: (row) => {
        if (row.type === "deposit_invoice") {
          return "--";
        }
        return formatter.format(
          row.shots_quote_subtotal_from_latest_versions
            ? row.shots_quote_subtotal_from_latest_versions
            : 0
        );
      },
    },
    {
      name: "Additional costs subtotal",
      selector: "additional_costs_subtotal_from_recent_bid",
      center: true,
      format: (row) => {
        if (row.type === "deposit_invoice") {
          return "--";
        }
        return formatter.format(
          row.additional_costs_subtotal_from_recent_bid_version
            ? row.additional_costs_subtotal_from_recent_bid_version
            : 0
        );
      },
    },
    {
      name: "Discount/rush",
      selector: "discount_from_recent_bid",
      center: true,
      format: (row) => {
        if (row.type === "deposit_invoice") {
          return "--";
        }
        let discount = row.discount_from_recent_bid_version || 0;
        discount *= -1;
        const rush = row.rush_from_recent_bid_version || 0;
        return formatter.format(discount + rush);
      },
    },
    {
      name: "Total",
      selector: "total",
      center: true,
      format: (row) => <BidInvoiceTotalFormat invoice={row} bid={row} />,
    },
    {
      name: "PO Number",
      selector: "po_number",
      center: true,
    },
    {
      name: "Actions",
      selector: "actions",
      right: true,
      cell: (row) => {
        let buttons = [];
        if (row.type !== "deposit_invoice") {
          buttons.unshift({
            icon: "mi-money-off",
            action: onInvoiceDelete,
            data: row,
            label: "Delete invoice",
            allowedRoles: ["admin", "producer", "accountant"],
          });
        }
        if (row.type === "deposit_invoice") {
          buttons.unshift({
            icon: "mi-money-off",
            action: onDepositInvoiceDelete,
            data: row,
            label: "Delete deposit invoice",
            allowedRoles: ["admin", "producer", "accountant"],
          });
        }
        if (row.type !== "deposit_invoice") {
          buttons.unshift({
            icon: "mi-picture-as-pdf",
            action: invoicePDF,
            data: row,
            label:
              user.model && user.model.role !== "client"
                ? "Generate PDF"
                : "Download invoice PDF",
          });
        }
        if (row.type === "deposit_invoice") {
          buttons.unshift({
            icon: "mi-picture-as-pdf",
            action: downloadDepositInvoicePDF,
            data: row,
            label: "Download deposit invoice PDF",
          });
        }
        if (row.status === "work_completed") {
          buttons.unshift({
            icon: "mi-mode-edit",
            action: onInvoiceEdit,
            data: row,
            label: "Edit invoice",
            allowedRoles: ["admin", "producer"],
          });
        }
        return (
          <DropdownMenu buttons={filterAllowedRoles(user.model, buttons)} />
        );
      },
      allowedRoles: ["admin", "producer", "accountant", "client"],
      width: "70px",
    },
  ];

  const dataTableBulkActions = [
    {
      data: statuses.map((i) =>
        i.value === "work_completed" ? { ...i, disabled: true } : i
      ),
      placeholder: "Choose status",
      fieldName: "status",
      type: "select",
      disabled: bidInvoices.edit,
      allowedRoles: ["admin", "producer"],
    },
    {
      label: "Bulk update",
      action: bulkUpdateAction,
      disabled: bidInvoices.edit,
      allowedRoles: ["admin", "producer"],
    },
  ];

  const hiddenColumns = props.hiddenColumns;
  if (hiddenColumns) {
    dataTableColumns = dataTableColumns.filter(
      (item) => !hiddenColumns.includes(item.selector.toLowerCase())
    );
  }

  /**
   *
   * @returns {Promise<void>}
   */
  const downloadDepositInvoicePDF = async (e, a) => {
    const bidModel = bid.model;

    const fileName = `Deposit invoice ${
      bidModel.project.code
    } - ${bidModel.name.replace(" ", "")}`;
    await depositInvoicePDF(bidModel.id, fileName, bidModel.deposit);
  };

  /**
   *
   * @param row
   */
  const invoicePDF = (row) => {
    const bidModel = bid.model;
    const fileNameCreator = new VariablesString({
      string: bidModel.project.invoice_pdf_filename,
      variablesWithDataToReplace: [
        {
          name: "time",
          value: moment().format("MM-DD-YY HH-mm"),
        },
        { name: "code", value: bidModel.project.code },
        { name: "project_name", value: bidModel.project.name.replace(" ", "") },
        { name: "bid_name", value: row.bid.name.replace(" ", "") },
        { name: "version", value: row.create_order },
      ],
    });
    let fileNameByVariables = fileNameCreator.createResultString();
    fileNameByVariables =
      typeof fileNameByVariables === "string" ? fileNameByVariables : "";
    const defaultFileName = `Invoice ${bidModel.code} - ${row.create_order}`;
    _invoicePDF(row.id, fileNameByVariables || defaultFileName);
  };

  /**
   *
   * @param data
   * @returns {function(...[*]=)}
   */
  const onStatusChange = (data) => (value) => {
    data.type === "deposit_invoice"
      ? onDepositInvoiceStatusChange(value, data.invoice)
      : onInvoiceStatusChange(value, data.invoice);
  };

  /**
   *
   * @param event
   * @param row
   */
  const onDepositInvoiceStatusChange = async (value, row) => {
    if (value) {
      props.clearErrors();
      setState({ ...state, editInvoiceId: row.id });
      await editDepositInvoiceStatus(row.bid_id, value);
    }
  };

  /**
   *
   * @param event
   * @param row
   */
  const onInvoiceStatusChange = async (value, row) => {
    if (value) {
      props.clearErrors();
      setState({ ...state, editInvoiceId: row.id });
      await editInvoice(row.id, { status: value, bidId: row.bid.id });
    }
  };

  /**
   *
   * @returns {*}
   */
  const getUsedShotsInInvoicesTotal = () => {
    return (bidInvoices.collection || []).reduce((accumulator, item) => {
      return accumulator + item.count_shots;
    }, 0);
  };

  /**
   * @returns {*}
   */
  const getApprovedShotsAmount = () => {
    return props.project.shots.filter((item) => !!+item.has_approved_version)
      .length;
  };

  /**
   * @param invoice
   */
  const onInvoiceEdit = (invoice) => {
    props.clearErrors();
    history.push(`/invoice/${bid.model.id}/edit/${invoice.id}`, {
      invoice,
    });
  };

  /**
   *
   * @param invoice
   */
  const onInvoiceDelete = (invoice) => {
    if (
      window.confirm(
        "Are you sure you want to delete invoice " + invoice.create_order + "?"
      )
    ) {
      deleteInvoice(invoice.id);
    }
  };

  /**
   *
   * @param row
   */
  const onDepositInvoiceDelete = (row) => {
    editBid(row.bid_id, {
      deposit_po_number: null,
    });
  };

  // TODO
  const bidModel = bid.model || {};
  const project = bidModel.project ? bidModel.project : {};
  const projectModel = project.model;

  const bidInvoicesCollection = bidInvoices.collection || [];

  const isLoaded = state.isLoaded;

  const allowBulkActions = props.allowBulkActions;

  const role = user && user.model ? user.model.role : "";

  const linkToBidURL =
    projectModel && projectModel.bid ? "/bid/" + projectModel.bid.id : null;

  const projectName = projectModel ? projectModel.name : null;

  const linkToBid =
    linkToBidURL &&
    ["admin", "producer", "client"].includes(user.model.role) ? (
      <Link to={linkToBidURL}>
        <span
          data-popup="tooltip"
          data-placement="right"
          data-original-title="Go to related bid's page"
        >
          {projectName}
        </span>
      </Link>
    ) : (
      projectName
    );

  const dataTableTitle = props.showHeader && projectModel && (
    <h5 className={"card-title"}>
      {linkToBid}{" "}
      {`invoices (${getUsedShotsInInvoicesTotal()} of ${getApprovedShotsAmount()} 
        approved shots are invoiced)`}
    </h5>
  );

  const depositInvoices = getDepositInvoice(bid.model);

  let dataTableCollection = depositInvoices
    ? [...[depositInvoices], ...bidInvoicesCollection]
    : bidInvoicesCollection;

  const dataTableClassName = classNames(
    "data-table-themed-short-cell-with-expander-btn",
    "data-table-themed_cells-nowrap",
    props.dataTableClassName
  );

  const dataTablebidInvoices = dataTableCollection ? (
    <div className={dataTableClassName}>
      <CustomizableDataTable
        id="bidInvoices"
        headerComponent={dataTableTitle}
        collection={dataTableCollection}
        columns={filterAllowedRoles(user.model, dataTableColumns)}
        canSelectAll
        bulkActions={
          allowBulkActions && role !== "client"
            ? filterAllowedRoles(user.model, dataTableBulkActions)
            : []
        }
        bulkActionsLoading={state.bulkUpdate}
      />
      <ApiError error={bidInvoices.editError || bid.editError} />
    </div>
  ) : null;

  const staticAlertbidInvoices =
    isLoaded && dataTableCollection && !dataTableCollection.length ? (
      <StaticAlert
        header={staticBidInvoicesAlertHeader}
        body={staticBidInvoicesAlertBody}
      />
    ) : null;

  const loadingIndicatorClassName = classNames({
    absolute: props.fitLoaderInBox,
  });

  const loadingIndicator =
    !isLoaded &&
    (project.fetch || bidInvoices.fetch || bidInvoices.createPdf) ? (
      <LoadingIndicator className={loadingIndicatorClassName} isModal />
    ) : null;

  return (
    <div className="relative">
      {loadingIndicator}
      <div className="row">
        <div className="col-xl-12">
          {/*<ApiError error={shot.editError}/>*/}
          {dataTablebidInvoices}
          {staticAlertbidInvoices}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  statuses: state.statuses,
});

const mapDispatchToProps = (dispatch) => ({
  clearErrors: () => dispatch(appActions.clearErrors()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BidInvoicesTable));

BidInvoicesTable.propTypes = {
  projectInvoices: PropTypes.object,
  project: PropTypes.object,
  fitLoaderInBox: PropTypes.bool,
  hiddenColumns: PropTypes.array,
  showHeader: PropTypes.bool,
  allowStatusEdit: PropTypes.bool,
  allowBulkActions: PropTypes.bool,
  dataTableClassName: PropTypes.string,
};
