/**
 * Created by piotr.pozniak@thebeaverhead.com on 09/07/2024
 */

import React from "react";
import PropTypes from "prop-types";
import * as moment from "moment/moment";

const InvoiceIssuedDateFormatter = (row) =>
  row.created ? moment.unix(row.created).format("DD/MM/YY, HH:mm") : "--";

InvoiceIssuedDateFormatter.defaultProps = {};

InvoiceIssuedDateFormatter.propTypes = {
  row: PropTypes.shape({
    created: PropTypes.number,
  }),
};

export default InvoiceIssuedDateFormatter;
