import React, {Component} from 'react';

import ReportsPageTemplate, {staticAlertHeader, staticAlertBody} from './ReportsPageTemplate';
import connect from "react-redux/es/connect/connect";
import * as reportsProjectsHoursActions from "../actions/reportsProjectsHours";
import CustomizableDataTable from '../components/CustomizableDataTable';
import moment from 'moment';
import StaticAlert from "../components/StaticAlert";
import {initialState} from "../reducers/filtersReducer";
import {Link} from "react-router-dom";
import {secondsToHm} from "../helpers/tools";

class ProjectsHoursReportsPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      diagramData: undefined,
      filter: {
        fromDate: this.props.filtersReducer.reportsProjectsHoursFromDate,
        toDate: this.props.filtersReducer.reportsProjectsHoursToDate,
        artists: this.props.filtersReducer.reportsProjectsHoursArtists,
        clients: this.props.filtersReducer.reportsProjectsHoursClients,
        projects: this.props.filtersReducer.reportsProjectsHoursProjects,
      }
    };
  }

  /**
   *
   * @returns {Promise<void>}
   */
  async componentDidMount() {
    await this.reportsProjectsHoursFetch();
    this.setState({diagramData: this.getDiagramData()});
  };

  /**
   *
   * @param filter
   */
  onFilter = async filter => {
    let fromDate;
    let toDate;

    filter.forEach(i => {
      if (i.fieldName === "fromDate" && i.value) {
        fromDate = moment(i.value, "YYYY-M-D");
      } else if (i.fieldName === "toDate" && i.value) {
        toDate = moment(i.value, "YYYY-M-D");
      }
    });

    this.setState({
      filter: {
        ...this.state.filter,
        fromDate: fromDate,
        toDate: toDate,
      },
      diagramData: undefined // clear the old chart
    }, async () => {
      await this.reportsProjectsHoursFetch();
      this.setState({diagramData: this.getDiagramData()});
    });
  };

  /**
   *
   * @returns {Promise<*|Promise<*>>}
   */
  reportsProjectsHoursFetch = async () => {
    return this.props.reportsProjectsHoursFetch(
      this.state.filter.fromDate ? this.state.filter.fromDate.format('YYYY-MM-DD') : null,
      this.state.filter.toDate ? this.state.filter.toDate.format('YYYY-MM-DD') : null,
      this.state.filter.artists ? this.state.filter.artists.map(i => i) : [],
      this.state.filter.clients ? this.state.filter.clients.map(i => i) : [],
      this.state.filter.projects ? this.state.filter.projects.map(i => i) : [],
    );
  };

  /**
   *
   */
  onClearAll = () => {
    this.setState({
      filter: {
        artists: [],
        clients: [],
        projects: [],
      }
    }, () => {
      this.onFilter([
        {fieldName: "fromDate", value: initialState.reportsProjectsHoursFromDate.format('YYYY-M-D')},
        {fieldName: "toDate", value: initialState.reportsProjectsHoursToDate.format('YYYY-M-D')},
      ]);
    });
  };

  /**
   *
   */
  getDiagramData = () => {

    const projects = this.props.reportsProjectsHours.collection;

    if (projects.length === 0) {
      return null;
    }

    // prepare columns
    let columns = [
      ['Required hours'],
      ['Time spent'],
      ['Time left'],
    ];
    let categories = [];
    projects.forEach((item, index) => {
      columns[0].push(item.required_hours * 60 * 60);
      columns[1].push(item.time_spent);
      columns[2].push(item.required_hours * 60 * 60 - item.time_spent);

      categories.push(item.name);
    });

    return {
      //new diagram types can be added in public_html_dev/js/app.js, see App.initBarDiagram(settings ? settings.barDiagram : settings);
      barDiagram: [
        {
          bindto: document.getElementById('jsReportsPageDiagram'),
          size: {height: 300},
          data: {
            columns: columns,
            type: 'bar'
          },
          bar: {
            width: {
              ratio: 0.5
            }
          },
          grid: {
            y: {
              show: true
            }
          },
          axis: {
            x: {
              type: 'category',
              categories,
            },
          },
          tooltip: {
            format: {
              value: value => {
                if (!value) {
                  return 0;
                }
                return value > 0 ? secondsToHm(value) : '- ' + secondsToHm(-value);
              },
            },
          }
        }
      ],
    };
  };

  /**
   *
   * @param val
   */
  artistsFilterOnUpdate = (val) => {
    const artists = val || [];
    this.setState({
      filter: {...this.state.filter, artists}
    });
  };

  /**
   *
   * @param val
   */
  clientsFilterOnUpdate = val => {
    const clients = val || [];
    this.setState({
      filter: {...this.state.filter, clients}
    });
  };

  /**
   *
   * @param val
   */
  projectsFilterOnUpdate = val => {
    const projects = val || [];
    this.setState({
      filter: {...this.state.filter, projects}
    });
  };

  /**
   *
   * @returns {XML}
   */
  render() {
    const isLoading = this.props.reportsProjectsHours.fetch;

    // prepare data table collection
    const projects = this.props.reportsProjectsHours.collection;

    const dataTableCollection = [];
    projects.forEach(item => {
      dataTableCollection.push({
        project_name: item.name ? item.name : `--`,
        project_href: item.id ? `/project/${item.id}` : `#`,
        due_date: item.due_date,
        client: item.client ? item.client.name : `--`,
        required_hours: secondsToHm(item.required_hours * 60 * 60),
        time_spent: secondsToHm(item.time_spent),
        diff: item.required_hours * 60 * 60 - item.time_spent,
      })
    });
    dataTableCollection.sort((a, b) => {
      return moment(a.due_date, "YYYY-MM-DD").format("x")
        - moment(b.due_date, "YYYY-MM-DD").format("x");
    });

    const content = dataTableCollection.length > 0 && (
      <CustomizableDataTable
        collection={dataTableCollection}
        columns={[
          {
            name: 'Project name',
            selector: 'project_name',
            center: true,
            cell: row => row && row.project_href ?
              <Link to={row.project_href} className='relative'>{row.project_name}</Link> :
              <div>{row.project_name}</div>,
          },
          {
            name: 'Due date',
            selector: 'due_date',
            center: true,
            sortable: true,
            format: row =>
              !row.due_date
                ? '--'
                : moment.unix(row.due_date).format('DD/MM/YY')
          },
          {
            name: 'Client',
            selector: 'client',
            center: true,
          },
          {
            name: 'Required hours',
            selector: 'required_hours',
            center: true,
          },
          {
            name: 'Time spent',
            selector: 'time_spent',
            center: true,
          },
          {
            name: 'Time left',
            selector: 'diff',
            center: true,
            format: row => {
              if (!row.diff) {
                return 0;
              }
              return row.diff > 0 ? secondsToHm(row.diff) : '- ' + secondsToHm(-row.diff);
            },
          },
        ]}
      />
    );

    // empty table alert
    const staticAlert = !dataTableCollection || !dataTableCollection.length ? (
      <StaticAlert
        header={staticAlertHeader}
        body={staticAlertBody}
      />) : null;
    //

    return (
      <ReportsPageTemplate
        header="Projects hours reports"
        filters={[
          {
            placeholderFrom: "From date (due date of the project)",
            placeholderTo: "To date (due date of the project)",
            fieldNameFrom: "fromDate",
            fieldNameTo: "toDate",
            fieldName: "dateRange",
            defaultValueFrom: this.state.filter.fromDate ? this.state.filter.fromDate.format("YYYY-MM-DD") : "",
            defaultValueTo: this.state.filter.toDate ? this.state.filter.toDate.format("YYYY-MM-DD") : "",
            type: 'dateRange',
            wrapperClassName: 'col-sm-6',
          },
          {
            placeholder: "Artists",
            fieldName: "artists",
            type: 'userSelect',
            reactSelectProps: {
              isMulti: true,
              value: this.state.filter.artists,
            },
            onUpdate: this.artistsFilterOnUpdate,
            wrapperClassName: 'col-sm-6',
          },
          {
            placeholder: "Clients",
            fieldName: "clients",
            type: "customerSelect",
            onUpdate: this.clientsFilterOnUpdate,
            wrapperClassName: 'col-sm-6',
            reactSelectProps: {
              isMulti: true,
              value: this.state.filter.clients,

            }
          },
          {
            placeholder: "Projects",
            fieldName: "projects",
            type: "projectSelect",
            onUpdate: this.projectsFilterOnUpdate,
            wrapperClassName: 'col-sm-6',
            reactSelectProps: {
              isMulti: true,
              value: this.state.filter.projects,
            }
          },
        ]}
        onFilter={this.onFilter}
        onClearAll={this.onClearAll}
        diagramData={this.state.diagramData}
        isLoading={isLoading}
      >
        {content}
        {staticAlert}
      </ReportsPageTemplate>
    );
  }
}

const mapStateToProps = state => ({
  reportsProjectsHours: state.reportsProjectsHours,
  filtersReducer: state.filtersReducer,
});

const mapDispatchToProps = dispatch => ({
  reportsProjectsHoursFetch: async (dateFrom, dateTo, artists, clients, projects, type = 'ProjectsHoursReportsPage') =>
    await dispatch(reportsProjectsHoursActions.reportsProjectsHoursFetch(dateFrom, dateTo, artists, clients, projects, type)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectsHoursReportsPage);
