import axios from "axios";
import AxiosConfig from "../AxiosConfig";

/**
 *
 * @param data
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export const createProject = (data) => {
  return async (dispatch) => {
    dispatch({ type: "PROJECT_CREATE" });

    const url = AxiosConfig.getEndpointAddress() + "/api/projects.json";

    await axios
      .post(url, data, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "PROJECT_CREATE_FULFILLED",
          data: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "PROJECT_CREATE_REJECTED",
          data: error,
        });
      });
  };
};

/**
 *
 * @param id
 * @param data
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export const editProject = (id, data) => {
  return async (dispatch) => {
    dispatch({ type: "PROJECT_EDIT" });

    const url =
      AxiosConfig.getEndpointAddress() + "/api/projects/" + id + ".json";

    await axios
      .put(url, data, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "PROJECT_EDIT_FULFILLED",
          data: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "PROJECT_EDIT_REJECTED",
          data: error,
        });
      });
  };
};

/**
 *
 * @param id
 * @param approved_shots
 * @param shots_for_project_page
 * @returns {(function(*): Promise<void>)|*}
 */
export const fetchProject = (
  id,
  approved_shots = null,
  shots_for_project_page = null
) => {
  return async (dispatch) => {
    dispatch({ type: "PROJECT_FETCH", projectId: id });

    const url =
      AxiosConfig.getEndpointAddress() +
      "/api/projects/" +
      id +
      ".json?" +
      AxiosConfig.objectToURLQuery({ approved_shots, shots_for_project_page });

    await axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "PROJECT_FETCH_FULFILLED",
          data: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "PROJECT_FETCH_REJECTED",
          data: error,
        });
      });
  };
};

/**
 *
 * @param id
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export const deleteShot = (id) => {
  return async (dispatch) => {
    dispatch({ type: "SHOT_DELETE" });

    const url = AxiosConfig.getEndpointAddress() + "/api/shots/" + id + ".json";

    await axios
      .delete(url, { ...AxiosConfig.getAuthConfig() })
      .then((response) => {
        dispatch({
          type: "SHOT_DELETE_FULFILLED",
          data: {
            responseData: response.data,
            id,
          },
        });
      })
      .catch(function (error) {
        dispatch({
          type: "SHOT_DELETE_REJECTED",
          data: error,
        });
      });
  };
};

/**
 *
 * @param data
 */
export const setEditProject = (data) => {
  return (dispatch) => {
    dispatch({ type: "SET_PROJECT_EDIT", data });
  };
};

export const bulkEditShots = (data) => {
  return async (dispatch) => {
    dispatch({ type: "SHOTS_EDIT_BULK" });

    const url = AxiosConfig.getEndpointAddress() + "/api/shots/bulk.json";

    await axios
      .put(url, { data }, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "SHOTS_EDIT_BULK_FULFILLED",
          data: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "SHOTS_EDIT_BULK_REJECTED",
          data: error,
        });
      });
  };
};

/**
 *
 * @param data
 * @returns {Function}
 */
export const linkProjectWithTSheets = (data) => {
  return async (dispatch) => {
    dispatch({ type: "LINK_PROJECT_WITH_TSHEETS" });

    const url =
      AxiosConfig.getEndpointAddress() +
      "/api/projects/link_tsheets_project.json";

    await axios
      .put(url, data, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "LINK_PROJECT_WITH_TSHEETS_FULFILLED",
          data: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "LINK_PROJECT_WITH_TSHEETS_REJECTED",
          data: error,
        });
      });
  };
};

/**
 *
 * @param data
 * @returns {Function}
 */
export const unlinkProjectWithTSheets = (data) => {
  return async (dispatch) => {
    dispatch({ type: "UNLINK_PROJECT_WITH_TSHEETS" });

    const url =
      AxiosConfig.getEndpointAddress() +
      "/api/projects/unlink_tsheets_project.json";

    await axios
      .put(url, data, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "UNLINK_PROJECT_WITH_TSHEETS_FULFILLED",
          data: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "UNLINK_PROJECT_WITH_TSHEETS_REJECTED",
          data: error,
        });
      });
  };
};

/**
 *
 * @return {Function}
 */
export const clearProject = () => {
  return async (dispatch) => {
    dispatch({ type: "PROJECT_CLEAR" });
  };
};
