export const initialState = {
  fetch: false,
  fetchSuccess: false,
  fetchError: null,

  collection: null,
  pagination: null,
};

export default function reducer(
  state = {
    ...initialState
  },
  action
) {
  switch (action.type) {
    case "PENDING_USERS_FETCH":
      return {
        ...state,
        fetch: true,
        fetchSuccess: false,
        fetchError: null,
      };

    case "PENDING_USERS_FETCH_FULFILLED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: true,

        collection: action.data.users,
        pagination: action.data.pagination,
      };

    case "PENDING_USERS_FETCH_REJECTED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: false,
        fetchError: action.data,
      };

    default:
      return state;
  }
}
