import React from "react";
import PropTypes from "prop-types";

export default class ClientAddressForm extends React.Component {

  componentDidMount() {
    if (this.props.client !== null) {
      this.updateForm();
    }
  }

  /**
   *
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.client !== this.props.client && this.props.client !== null) {
      this.updateForm();
    }
  }

  /**
   *
   */
  updateForm = () => {
    const {
      contact_line_1,
      contact_line_2,
      contact_city,
      contact_state,
      contact_country,
      contact_zip,
    } = this.props.client;
    document.querySelector('.js-client-contact-address-1').value = contact_line_1 || '';
    document.querySelector('.js-client-contact-address-2').value = contact_line_2 || '';
    document.querySelector('.js-client-contact-city').value = contact_city || '';
    document.querySelector('.js-client-contact-state').value = contact_state || '';
    document.querySelector('.js-client-contact-country').value = contact_country || '';
    document.querySelector('.js-client-contact-zip').value = contact_zip || '';
  };

  /**
   *
   * @returns {*}
   */
  render() {
    return (
      <>
        <div className="form-group">
          <label className="form-label">Address line 1</label>
          <input
            type="text"
            className="form-control js-client-contact-address-1"
            placeholder={this.props.showPlaceholder ? "Address line 1" : null}
            disabled={this.props.disabled}
          />
        </div>

        <div className="form-group">
          <label className="form-label">Address line 2</label>
          <input
            type="text"
            className="form-control js-client-contact-address-2"
            placeholder={this.props.showPlaceholder ? "Address line 2" : null}
            disabled={this.props.disabled}
          />
        </div>

        <div className="row">

          <div className="col-sm-3">
            <div className="form-group">
              <label className="form-label">City</label>
              <input
                type="text"
                className="form-control js-client-contact-city"
                placeholder={this.props.showPlaceholder ? "City" : null}
                disabled={this.props.disabled}
              />
            </div>
          </div>

          <div className="col-sm-3">
            <div className="form-group">
              <label className="form-label">State</label>
              <input
                type="text"
                className="form-control js-client-contact-state"
                placeholder={this.props.showPlaceholder ? "State" : null}
                disabled={this.props.disabled}
              />
            </div>
          </div>

          <div className="col-sm-3">
            <div className="form-group">
              <label className="form-label">Country</label>
              <input
                type="text"
                defaultValue="USA"
                className="form-control js-client-contact-country"
                placeholder={this.props.showPlaceholder ? "Country" : null}
                disabled={this.props.disabled}
              />
            </div>
          </div>

          <div className="col-sm-3">
            <div className="form-group">
              <label className="form-label">Zip Code</label>
              <input
                type="tel"
                className="form-control js-client-contact-zip"
                placeholder="Zip Code"
                disabled={this.props.disabled}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

ClientAddressForm.defaultProps = {
  showPlaceholder: true,
};

ClientAddressForm.propTypes = {
  disabled: PropTypes.bool,
  showPlaceholder: PropTypes.bool,
  client: PropTypes.object,
};
