/**
 * Created by piotr.pozniak@thebeaverhead.com on 24/01/2022
 */

import React, { useState } from "react";
import PropTypes from "prop-types";
import ApiError from "../../../components/ApiError";
import HasRights from "../../../components/HasRights";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { history } from "../../../configureStore";
import { useAppStore } from "../../../storeHooks/app";
import StatusesTable from "./StatusesTable";
import { useUserStore } from "../../../storeHooks/user";
import { hasRights } from "../../../helpers/tools";
import {useStatusesStore} from "../../../storeHooks/statuses";
import classNames from "classnames";

const StatusGroupPanel = (props) => {
  const [deleteStatusId, setDeleteStatusId] = useState({
    deleteStatusId: null,
  });

  const { isLoading } = props;
  const { user } = useUserStore();
  const {statuses, deleteStatus} = useStatusesStore();

  const { clearErrors } = useAppStore();

  const loadingIndicator = isLoading ? (
    <div className="mb-n3">
      <LoadingIndicator />
    </div>
  ) : null;

  /**
   * @param type
   */
  const onCreateStatusClick = (type) => () => {
    clearErrors();
    history.push(`/status/edit/${type}/new`);
  };

  /**
   *
   * @param id
   * @returns {Function}
   */
  const onDeleteStatusModal = (id) => () => {
    if (window.confirm("Do you really want to delete this status?")) {
      setDeleteStatusId(id);

      deleteStatus(id);
    }
  };

  /**
   *
   * @param status
   * @returns {Function}
   */
  const onEditStatusModal = (status) => () => {
    clearErrors();
    history.push(`/status/edit/${status.type}/${status.id}`);
  };

  return (
    <div className={classNames("tab-pane fade", {
      show: props.defaultActive,
      active: props.defaultActive,
    })} id={props.tabId}>
      <div className="row mb-2">
        <div className="col">
          <ApiError error={statuses.deleteError} />
        </div>
        <div className="col text-right">
          <HasRights allowedRoles={["admin", "producer"]} user={user.model}>
            <button
              onClick={onCreateStatusClick(props.type)}
              disabled={isLoading}
              type="submit"
              className="btn btn-primary"
            >
              {`Create new ${props.type.toLowerCase()} status`}
            </button>
          </HasRights>
        </div>
      </div>
      <div className="overflow-auto">
        <StatusesTable
          type={props.type}
          items={statuses.collection.filter((i) => i.type === props.type)}
          onEditStatus={onEditStatusModal}
          onDeleteStatus={onDeleteStatusModal}
          hasEditPermissions={hasRights(user.model, {}, ["admin", "producer"])}
          deleteErrorId={deleteStatusId}
          deletingRowId={statuses.delete}
        />
      </div>

      <div className="mt-2">{loadingIndicator}</div>
    </div>
  );
};

StatusGroupPanel.defaultProps = {};

StatusGroupPanel.propTypes = {
  tabId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  defaultActive: PropTypes.bool,
};

export default StatusGroupPanel;
