/**
 * Created by piotr.pozniak@thebeaverhead.com on 18/04/2023
 */

import React from "react";
import PropTypes from "prop-types";
import { formatter } from "../../helpers/tools";

const TotalFormat = (props) => {
  const { total, additional_costs } = props;

  if (total === undefined || additional_costs === undefined) {
    return <>{formatter.format(0)}</>;
  }
  const { rush, discount } = props.recent_bid_version;
  const grandTotal = total + additional_costs + rush - discount;

  return <>{grandTotal ? formatter.format(grandTotal) : formatter.format(0)}</>;
};

TotalFormat.defaultProps = {};

TotalFormat.propTypes = {};

export default TotalFormat;
