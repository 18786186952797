export const initialState = {
  collectionBy: {},
};

export default function reducer(
  state = {
    ...initialState,
  },
  action
) {
  switch (action.type) {
    case "PROJECT_INVOICES_FETCH_FULFILLED":
      return {
        ...state,
        collectionBy: {
          ...state.collectionBy,
          [action.projectId]: action.data.invoices,
        },
      };

    case "PROJECT_INVOICE_EDIT_FULFILLED": {
      const collectionByKeys = Object.keys(state.collectionBy);
      let projectIdOfUpdatedInvoice;
      collectionByKeys.forEach((collectionKey) => {
        if (
          state.collectionBy[collectionKey].find(
            (invoice) => invoice.id === action.data.id
          )
        ) {
          projectIdOfUpdatedInvoice = collectionKey;
        }
      });

      return {
        ...state,
        collectionBy: projectIdOfUpdatedInvoice
          ? {
              ...state.collectionBy,
              [projectIdOfUpdatedInvoice]: state.collectionBy[
                projectIdOfUpdatedInvoice
              ].map((invoice) =>
                invoice.id === action.data.id ? action.data : { ...invoice }
              ),
            }
          : state.collectionBy,
      };
    }

    case "DEPOSIT_INVOICE_STATUS_EDIT_FULFILLED": {
      let collectionBy = state.collectionBy;
      if (collectionBy) {
        let collectionByResult = { ...collectionBy };
        for (let key in collectionByResult) {
          collectionByResult[key] = collectionByResult[key].map((item) =>
            item.bid.id === action.data.id
              ? {
                  ...item,
                  bid: {
                    ...item.bid,
                    deposit_invoice_status: action.data.deposit_invoice_status,
                  },
                }
              : item
          );
        }
        collectionBy = collectionByResult;
      }

      return {
        ...state,
        collectionBy,
      };
    }

    // case "BID_EDIT_FULFILLED": {
    //   let collectionBy = state.collectionBy;
    //   if (collectionBy) {
    //     let collectionByResult = { ...collectionBy };
    //     for (let key in collectionByResult) {
    //       collectionByResult[key] = collectionByResult[key].map((item) =>
    //         item.bid.id === action.data.id
    //           ? {
    //               ...item,
    //               bid: {
    //                 ...item.bid,
    //                 ...action.data,
    //               },
    //             }
    //           : item
    //       );
    //     }
    //     collectionBy = collectionByResult;
    //   }
    //
    //   return {
    //     ...state,
    //     collectionBy,
    //   };
    // }

    case "CLEAR_ERRORS":
      return {
        ...initialState,
        collectionBy: state.collectionBy,
      };

    case "LOGOUT":
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
