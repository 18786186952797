import React, { useCallback, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import LoadingIndicator from "../../../components/LoadingIndicator";
import Notes from "./Notes";
import { useBidStore } from "../../../storeHooks/bid";
import { useBidVersionStore } from "../../../storeHooks/bidVersion";

const footerNotesPlaceholder = "Add footer notes";
const bidNotesPlaceholder = "Add bid notes";

const BidNotes = (props) => {
  const {
    isLoading,
    isDisabled,
    onAccountingNotesChange,
    onFooterNotesChange,
  } = props;

  const { bid } = useBidStore();
  const { bidVersion } = useBidVersionStore();

  const bidModel = bid.model;
  const bidVersionModel = bidVersion.model;

  /**
   *
   */
  const onConfirmAccountantNotes = useCallback(
    (value) => {
      if (onAccountingNotesChange) {
        onAccountingNotesChange(value);
      }
    },
    [bid.model, onAccountingNotesChange]
  );

  /**
   *
   */
  const onConfirmFooterNotes = useCallback(
    (value) => {
      if (onFooterNotesChange) {
        onFooterNotesChange(value);
      }
    },
    [bid.model]
  );

  const footerNotesValue = useMemo(() => {
    return bidVersionModel ? bidVersionModel.footer_notes : "";
  }, [bidVersionModel]);

  return (
    <div className={"row"}>
      <div className={"col-lg-12"}>
        <div className="card">
          <div className="card-header bg-primary text-white header-elements-inline">
            <h6 className="card-title">Notes</h6>
          </div>
          <div className="card-body">
            <div className="col mb-3">
              <h5>
                Footer Note:
                {bidVersion.update ? (
                  <LoadingIndicator className="d-inline-block fs-089rem ml-3 mb-n3" />
                ) : null}
              </h5>
              <div className="mb-3 ml-n1">
                <Notes
                  allowedRoles={["admin", "producer"]}
                  value={footerNotesValue}
                  previewValue={footerNotesValue || footerNotesPlaceholder}
                  isDisabled={isDisabled || bidVersion.update}
                  onChange={onConfirmFooterNotes}
                />
              </div>
            </div>
            <div className="col mb-3">
              <h5>
                Accounting Notes:
                {bid.edit ? (
                  <LoadingIndicator className="d-inline-block fs-089rem ml-3 mb-n3" />
                ) : null}
              </h5>
              <div className="mb-3 ml-n1">
                <Notes
                  allowedRoles={["admin", "producer", "accountant"]}
                  value={bidModel.bid_notes}
                  previewValue={bidModel.bid_notes || bidNotesPlaceholder}
                  isDisabled={isDisabled || bid.edit}
                  onChange={onConfirmAccountantNotes}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

BidNotes.propTypes = {
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onAccountingNotesChange: PropTypes.func,
  onFooterNotesChange: PropTypes.func,
};

export default BidNotes;
