import { useStatusesStore } from "../storeHooks/statuses";
import { useMemo } from "react";

const useStatusHook = (statusCode) => {
  const { statuses: _statuses } = useStatusesStore();

  const statuses = useMemo(
    () => _statuses.collection.filter((i) => i.type === statusCode),
    [_statuses.collection, statusCode]
  );

  return { statuses };
};

export default useStatusHook;
