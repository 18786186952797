import React, { useMemo } from "react";

const DataGridPagination = (props) => {
  const { count, pageCount, page, start, end } = props.pagination;

  /**
   *
   * @param page
   * @returns {(function(*): void)|*}
   */
  const onPageChange = (page) => (event) => {
    event.preventDefault();
    props.onChangePage(page);
  };

  const prevPageLink =
    page > 1 ? (
      <a
        href="!#"
        className="paginate_button previous"
        onClick={onPageChange(page - 1)}
      >
        ←
      </a>
    ) : (
      <span className="paginate_button previous disabled">←</span>
    );

  const nextPageLink =
    page < pageCount ? (
      <a
        href="!#"
        className="paginate_button next"
        onClick={onPageChange(page + 1)}
      >
        →
      </a>
    ) : (
      <span className="paginate_button next disabled">→</span>
    );

  const buttons = useMemo(
    () =>
      [...Array(pageCount).keys()].map((item) => {
        item++;
        return (
          <a
            href="!#"
            className={"paginate_button " + (item === page ? "current" : "")}
            onClick={onPageChange(item)}
            key={item}
          >
            {item}
          </a>
        );
      }),
    [pageCount, page]
  );

  return (
    <div className="dataTables_paginate paging_simple_numbers data-table-themed__pagination">
      <div className="data-table-themed__pagination__info">
        Showing {start} to {end} of {count}
      </div>
      <div>
        {prevPageLink}

        {buttons}

        {nextPageLink}
      </div>
    </div>
  );
};

DataGridPagination.defaultProps = {};
DataGridPagination.propTypes = {};

export default DataGridPagination;
