import React, { Component } from 'react';
import { Route, Switch, withRouter, matchPath } from 'react-router-dom';
import { history } from "./configureStore";

import ClientsEditModal from './modals/ClientsEditModal';
import ProfileEditModal from './modals/ProfileEditModal';
import UsersEditModal from './modals/UsersEditModal';
import ShotEditModal from './modals/ShotEditModal';
import BidEditModal from './modals/BidEditModal/BidEditModal';
import TaskEditModal from './modals/TaskEditModal';
import ArtistTasksModal from './modals/ArtistTasksModal';
import ShotUpdateEditModal from './modals/ShotUpdateEditModal';
import BidVersionEditModal from './modals/BidVersionEditModal';
import BidRejectModal from './modals/BidRejectModal';
import ProjectBidVersionsModal from './modals/ProjectBidVersionsModal/ProjectBidVersionsModal';
import MoveShotsModal from './modals/MoveShotsModal/MoveShotsModal';
import TaskTypeEditModal from './modals/TaskTypeEditModal';
import InvoiceEditModal from './modals/InvoiceEditModal/InvoiceEditModal';
import DepositInvoiceEditModal from './modals/DepositInvoiceEditModal';
import StatusEditModal from "./modals/StatusEditModal/StatusEditModal";
import ProjectProgressModal from "./modals/ProjectProgressModal";
import BidShareModal from "./modals/BidShareModal";
import CSVImportModal from "./modals/CSVImportModal";
import AdditionalCostsEditModal from "./modals/AdditionalCostsEditModal";
import CopyShotsInfoModal from "./modals/CopyShotsInfoModal/CopyShotsInfoModal";

export default withRouter(class ModalRoutesComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modalClosePath: null
    };
  }

  /**
   *
   */
  componentDidMount() {
    this.updateModalClosePath();
  }

  /**
   *
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({
        modalClosePath: null
      });
    }
  }

  /**
   *
   */
  updateModalClosePath = () => {
    const modalLinkIndex = this.props.modalLinks.findIndex((modalLink) => {
      const match = matchPath(this.props.location.pathname, {
        path: modalLink,
        exact: true,
        strict: false
      });
      return match !== null;
    });

    if (modalLinkIndex >= 0) {
      this.setState({
        modalClosePath: Object.values( this.props.prevModalLocation[modalLinkIndex] )[0],
      });
    }
  };

  /**
   *
   */
  onCloseAction = () => {
    if (this.state.modalClosePath) {
      history.push(this.state.modalClosePath);
    } else {
      history.goBack();
    }
  };

  /**
   *
   * @returns {*}
   */
  render() {
    const ClientsEditModalComponent = () => (
      <ClientsEditModal onClose={this.onCloseAction} />
    );

    const ProfileEditModalComponent = () => (
      <ProfileEditModal onClose={this.onCloseAction}/>
    );

    const UsersEditModalComponent = () => (
      <UsersEditModal onClose={this.onCloseAction}/>
    );

    const ShotEditModalComponent = () => (
      <ShotEditModal onClose={this.onCloseAction}/>
    );

    const BidEditModalComponent = () => (
      <BidEditModal onClose={this.onCloseAction} />
    );

    const TaskEditModalComponent = () => (
      <TaskEditModal onClose={this.onCloseAction} />
    );

    const ArtistTasksModalComponent = () => (
      <ArtistTasksModal onClose={this.onCloseAction} />
    );

    const ShotUpdateEditModalComponent = () => (
      <ShotUpdateEditModal onClose={this.onCloseAction} />
    );

    const BidVersionEditModalComponent = () => (
      <BidVersionEditModal onClose={this.onCloseAction} />
    );

    const BidRejectModalComponent = () => (
      <BidRejectModal onClose={this.onCloseAction} />
    );

    const ProjectBidVersionsModalComponent = () => (
      <ProjectBidVersionsModal onClose={this.onCloseAction} />
    );

    const MoveShotsModalComponent = () => (
      <MoveShotsModal onClose={this.onCloseAction} />
    );

    const CopyShotsDataComponent = () => (
      <CopyShotsInfoModal onClose={this.onCloseAction} />
    );

    const TaskTypeEditModalComponent = () => (
      <TaskTypeEditModal onClose={this.onCloseAction} />
    );

    const InvoiceEditModalComponent = () => (
      <InvoiceEditModal onClose={this.onCloseAction} />
    );

    const DepositInvoiceEditModalComponent = () => (
      <DepositInvoiceEditModal onClose={this.onCloseAction} />
    );

    const StatusEditModalComponent = () => (
      <StatusEditModal onClose={this.onCloseAction} />
    );

    const ProjectProgressModalComponent = () => (
      <ProjectProgressModal onClose={this.onCloseAction} />
    );

    const BidShareModalComponent = () => (
      <BidShareModal onClose={this.onCloseAction} />
    );

    const CSVImportModalComponent = () => (
      <CSVImportModal onClose={this.onCloseAction} />
    );

    const AdditionalCostsEditModalComponent = () => (
      <AdditionalCostsEditModal onClose={this.onCloseAction} />
    );
    return (
      <Switch>
        <Route exact path="/client/edit/:id" component={ClientsEditModalComponent} />
        <Route exact path="/user/:id" component={UsersEditModalComponent} />
        <Route exact path="/profile-settings" component={ProfileEditModalComponent} />
        <Route exact path="/project/:id/create-shot" component={ShotEditModalComponent} />
        <Route exact path="/:editEntity/edit/:id" component={BidEditModalComponent} />
        <Route exact path="/project/:id/shot/:shotId/task/:taskId" component={TaskEditModalComponent} />
        <Route exact path="/project/:projectId/artist_tasks/:artistId" component={ArtistTasksModalComponent} />
        <Route exact path="/project/:id/shot/:shotId/update/:shotUpdateId" component={ShotUpdateEditModalComponent} />
        <Route exact path="/project/:projectId/progress" component={ProjectProgressModalComponent} />
        <Route exact path="/bid/:bidVersionId/create-bid-version" component={BidVersionEditModalComponent} />
        <Route exact path="/bid/:bidVersionId/reject" component={BidRejectModalComponent} />
        <Route exact path="/bid/:bidVersionId/bid-versions" component={ProjectBidVersionsModalComponent} />
        <Route exact path="/bid/:bidId/move-shots" component={MoveShotsModalComponent} />
        <Route exact path="/bid/:bidId/copy-shots-data" component={CopyShotsInfoModal} />
        <Route exact path="/project/:bidId/copy-shots-data" component={CopyShotsInfoModal} />
        <Route exact path="/vfx-task/:taskTypeId" component={TaskTypeEditModalComponent} />
        <Route exact path="/invoice/:bidId/edit/:invoiceId" component={InvoiceEditModalComponent} />
        <Route exact path="/invoice/:bidId/deposit-invoice/:mode" component={DepositInvoiceEditModalComponent} />
        <Route exact path="/status/edit/:type/:statusId" component={StatusEditModalComponent} />
        <Route exact path="/bids/share/:id" component={BidShareModalComponent} />
        <Route exact path="/bid/:bidId/csv-import" component={CSVImportModalComponent} />
        <Route exact path="/platform-settings/additional-costs/:additionalCostId" component={AdditionalCostsEditModalComponent} />
      </Switch>
    );
  }
});
