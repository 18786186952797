/**
 * Created by piotr.pozniak@thebeaverhead.com on 11/05/2022
 */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import HasRights from "../../../components/HasRights";
import { useUserStore } from "../../../storeHooks/user";
import Checkbox from "../../../components/Checkbox";
import classNames from "classnames";

const ShotsTableHeader = (props) => {
  const { user } = useUserStore();

  useEffect(() => {
    window.$(".form-check-input-styled").uniform();
  }, [props.selectedRowsCount]);


  return (
    <thead>
      <tr>
        <td>
          <div className="row">
            <div className="col-1">
              <Checkbox
                className={classNames("table-with-inputs-navigation_select", {
                  "checkbox-partially-checked":
                    props.selectedRowsCount > 0 &&
                    props.selectedRowsCount < props.rowsCount,
                })}
                onChange={props.onSelectAll}
                checked={props.selectedRowsCount == props.rowsCount}
                disabled={
                  props.isLoading ||
                  !["admin", "producer", "client"].includes(user.model.role) ||
                  props.isPreview
                }
              />
            </div>
            <div className="col-2">Shot Number</div>
            <HasRights
              allowedRoles={["admin", "producer", "accountant"]}
              user={user.model}
              isForcedAllow={props.isPreview}
              placeholder={<div className="col-1" />}
            />
            <div className="col-3">Description</div>
            <div className="col-2">Bid Note</div>
            <div className="col-1">Client Approval</div>
            {/*<div className="col-1">Client Notes</div>*/}
            <HasRights
              allowedRoles={["admin", "producer", "accountant"]}
              user={user.model}
              isForcedAllow={props.isPreview}
              placeholder={<div className="col-1" />}
            >
              <div className="col-1">Rate ($)</div>
              <div className="col-1">
                {!props.useShotsAsHoursBid ? "Hour" : "Shots"}
              </div>
            </HasRights>
            <div className="col-1">Cost</div>
          </div>
        </td>
      </tr>
    </thead>
  );
};

ShotsTableHeader.defaultProps = {};

ShotsTableHeader.propTypes = {
  isPreview: PropTypes.bool,
  useShotsAsHoursBid: PropTypes.bool,
  isLoading: PropTypes.bool,
  onSelectAll: PropTypes.func,

  rowsCount: PropTypes.number,
  selectedRowsCount: PropTypes.number,
};

export default ShotsTableHeader;
