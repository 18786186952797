import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { setEditProject } from "../../../actions/project";
import { Link } from "react-router-dom";
import StatusBadge from "../../../components/StatusBadge";
import DateUSSlash from "../../../components/DateUSSlash";

const GroupedInvoicesRow = (props) => {
  const { data, statuses } = props;
  const dispatch = useDispatch();
  let actions = bindActionCreators({ setEditProject }, dispatch);

  /**
   *
   */
  const onBeforeGoWithRowLink = () => {
    actions.setEditProject(null);
  };

  const invoiceUrl =
    data && data.bid ? `/invoice/${data.bid.project_id}` : "";

  const name = data.bid ? (data.bid.project.name + " - " + data.bid.name) : "--";

  return (
    <tr>
      <td className="text-center">
        <div className="font-size-sm line-height-1">
          <DateUSSlash date={data.due_date} />
        </div>
      </td>
      <td>
        <span className="text-default">
          <Link onClick={onBeforeGoWithRowLink} to={invoiceUrl}>
            {name}
          </Link>
        </span>
      </td>
      <td>
        <span className="text-default">
          <div>
            <StatusBadge status={data.status} statuses={statuses} />
          </div>
        </span>
      </td>
    </tr>
  );
};

GroupedInvoicesRow.propTypes = {
  data: PropTypes.object.isRequired,
  statuses: PropTypes.array.isRequired,
};

export default GroupedInvoicesRow;
