import React, { Component } from "react";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import * as bidVersionActions from "../../../actions/bidVersion";

import {
  filterAllowedRoles,
  extractObjectWithMaxPropertyValue,
  getBidVersionName,
  formatter,
} from "../../../helpers/tools";
import * as csvHandler from "../../../helpers/csvExportHandler";

import { CSVLink } from "react-csv";
import CustomizableDataTable from "../../../components/CustomizableDataTable";
import StaticAlert from "../../../components/StaticAlert";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { Link, withRouter } from "react-router-dom";
import AxiosConfig from "../../../AxiosConfig";
import axios from "axios";

const staticShotsAlertHeader = "No records to display";
const staticShotsAlertBody = <>No shots related with this bid version.</>;

class ShotsTableExpandable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
    };


    const openBidByBidId = bidId => e=> {

      e.preventDefault();

      const url =
        AxiosConfig.getEndpointAddress() +
        `/api/bids/${bidId}.json?`;

      return axios
        .get(url, AxiosConfig.getAuthConfig())
        .then((response) => {

          console.log(response);
          window.open(`/bid/${response.data.recent_bid_version.id}`, bidId)
        })
        .catch(function (error) {
          console.log(error);
        });

    }

    this.shotsDataTableConfig = {
      columns: [
        {
          name: "Shot Number",
          csvName: "Shot Name",
          selector: "shot_number",
          format: (row) => (
            <div className="pl-2">
              <Link
                className="relative"
                to={`/project/${row.project_id}/shot/${row.id}`}
              >
                {row.shot_number || "--"}
              </Link>
              {row.moved_to_bid_id ? (
                <>
                  {" "}
                  <a
                    href={"#"}
                    target={row.moved_to_bid_id}
                    onClick={openBidByBidId(row.moved_to_bid_id)}
                  >
                    <i className="mi-subdirectory-arrow-right" />
                  </a>
                </>
              ) : (
                ""
              )}
            </div>
          ),
          csvFormat: (row) => row.shot_number || "",
        },
        {
          name: "Description",
          selector: "versions.description",
          format: (row) =>
            row.id
              ? this.getRecentShotVersionForParticularBidVersion(row.versions)
                  .description || "--"
              : null,
          csvFormat: (row) =>
            row.id
              ? this.getRecentShotVersionForParticularBidVersion(row.versions)
                  .description || ""
              : "",
        },
        {
          name: "Tasks",
          csvName: "VFX Process",
          selector: "versions.task_types",
          format: (row) => {
            if (row.id) {
              return (
                this.getRecentShotVersionForParticularBidVersion(row.versions)
                  .task_types || []
              )
                .map((item) => item.name)
                .join(", ");
            }
            return null;
          },
          csvFormat: (row) =>
            row.id
              ? (
                  this.getRecentShotVersionForParticularBidVersion(row.versions)
                    .task_types || []
                )
                  .map((item) => item.name)
                  .join(", ")
              : "",
          allowedRoles: ["admin", "producer", "accountant"],
        },
        {
          name: "Bid Note",
          csvName: "Notes",
          selector: "versions.notes",
          format: (row) =>
            row.id ? (
              <div className="white-space-pre">
                {this.getRecentShotVersionForParticularBidVersion(row.versions)
                  .notes || "--"}
              </div>
            ) : null,
          csvFormat: (row) =>
            row.id
              ? this.getRecentShotVersionForParticularBidVersion(row.versions)
                  .notes || ""
              : "",
        },
        {
          name: "Rate",
          selector: "versions.rate",
          format: (row) =>
            row.id
              ? formatter.format(
                  this.getRecentShotVersionForParticularBidVersion(row.versions)
                    .rate || 0
                )
              : null,
          csvFormat: (row) =>
            row.id
              ? this.getRecentShotVersionForParticularBidVersion(row.versions)
                  .rate || 0
              : 0,
          allowedRoles: ["admin", "producer", "accountant"],
          width: "100px",
        },
        {
          name: "Est. Hours",
          csvName: "Hours",
          selector: "versions.hours",
          format: (row) =>
            row.id
              ? Math.round(
                  (this.getRecentShotVersionForParticularBidVersion(
                    row.versions
                  ).hours || 0) * 100
                ) / 100
              : null,
          csvFormat: (row) =>
            row.id
              ? Math.round(
                  (this.getRecentShotVersionForParticularBidVersion(
                    row.versions
                  ).hours || 0) * 100
                ) / 100
              : 0,
          allowedRoles: ["admin", "producer", "accountant"],
          width: "90px",
        },
        {
          name: "Cost",
          selector: "versions.cost_summary",
          format: (row) =>
            row.id
              ? formatter.format(
                  this.getRecentShotVersionForParticularBidVersion(row.versions)
                    .cost_summary || 0
                )
              : null,
          csvFormat: (row) =>
            row.id
              ? this.getRecentShotVersionForParticularBidVersion(row.versions)
                  .cost_summary || 0
              : 0,
          width: "100px",
          right: true,
        },
      ],
    };
  }

  /**
   *
   */
  componentDidMount = () => {
    this.props.fetchShotsByBidVersion(this.props.data.id);
  };

  /**
   *
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (
      !prevProps.bidVersion.fetchShotsSuccess &&
      this.props.bidVersion.fetchShotsSuccess
    ) {
      this.setState({ isLoaded: true });
    }
  };

  /**
   *
   * @param versions
   * @returns {*}
   */
  getRecentShotVersionForParticularBidVersion = (versions) => {
    const passedBidVersion = this.props.data;
    return extractObjectWithMaxPropertyValue(
      versions.filter((item) => item.bid_version_id === passedBidVersion.id),
      "create_order"
    );
  };

  /**
   *
   * @returns {*}
   */
  render() {
    const bid = this.props.bid;
    const bidModel = bid.model;
    const bidVersion = this.props.bidVersion;
    const passedBidVersion = this.props.data;
    const shots = passedBidVersion.shots;

    const isLoading = bidVersion.fetchShots;

    let dataTableColumns = this.shotsDataTableConfig.columns;
    dataTableColumns = filterAllowedRoles(
      this.props.user.model,
      dataTableColumns
    );

    const bidVersionName = getBidVersionName(passedBidVersion);

    const csvFileName = `${
      bidModel && bidModel.project && bidModel.project.name
        ? bidModel.project.name
        : ""
    }_${bidVersionName}.csv`;

    const csvDownloadButton = (
      <CSVLink
        headers={csvHandler.generateCSVExportHeaders(dataTableColumns)}
        data={csvHandler.generateCSVExportData(shots, dataTableColumns)}
        filename={csvFileName}
        className="csv-download-button-version"
        target="_blank"
      >
        Download CSV <i className="mi-cloud-download" />
      </CSVLink>
    );

    const dataTable =
      shots && shots.length ? (
        <div className="data-table-themed_light_back mb-n19px relative">
          <CustomizableDataTable
            collection={shots}
            columns={dataTableColumns}
            footerComponent={csvDownloadButton}
          />
        </div>
      ) : null;

    const staticAlertShots =
      !shots || !shots.length ? (
        <StaticAlert
          header={staticShotsAlertHeader}
          body={staticShotsAlertBody}
          className="mb-r"
        />
      ) : null;

    return !isLoading || this.state.isLoaded ? (
      <div>
        {dataTable}
        {staticAlertShots}
      </div>
    ) : (
      <LoadingIndicator className="version-history-line-loader" />
    );
  }
}

ShotsTableExpandable.propTypes = {
  data: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  bid: state.bid,
  bidVersion: state.bidVersion,
});

const mapDispatchToProps = (dispatch) => ({
  fetchShotsByBidVersion: async (id) =>
    await dispatch(bidVersionActions.fetchShotsByBidVersion(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ShotsTableExpandable));
