import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import ModalTemplate from './ModalTemplate';
import LoadingIndicator from "../components/LoadingIndicator";
import TasksCustomizableDataTable from "../components/TasksCustomizableDataTable";
import * as projectActions from "../actions/project";
import connect from "react-redux/es/connect/connect";
import {filterComparisonAND} from "../helpers/tools";
import ApiError from "../components/ApiError";
import {history} from "../configureStore";

class ArtistTasksModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      pureTasksCollection: [],
      filteredTasksCollection: null,
      tasksFilter: [],
      isFiltersApplied: false,
    };
  }


  /**
   *
   */
  componentDidMount() {
    if (this.props.user.token && this.props.user.model && !this.props.project.fetch) {
      this.props.fetchProject(this.props.match.params.projectId);
    }
  }

  /**
   *
   * @returns {Array}
   */
  get pureTasksCollection() {
    const artistId = this.props.match.params.artistId;
    let collection = [];
    this.props.project.shots.forEach(shot => {
      collection = collection.concat(
        shot.tasks.filter(shotTask => shotTask.assignee !== null && shotTask.assignee.id === artistId)
      );
    });
    return collection.map(task => ({
      ...task,
      name: task.task_type.name,
    }));
  }

  /**
   *
   * @param tasksFilter
   */
  onTasksTableFilter = tasksFilter => {
    this.setState({tasksFilter, isFiltersApplied: !!tasksFilter.length});

    let arResult = this.pureTasksCollection ?
      this.pureTasksCollection.filter(item => {
        return filterComparisonAND(item, tasksFilter);
      }) :
      this.pureTasksCollection;
    this.setState({filteredTasksCollection: arResult});
  };

  /**
   *
   */
  onTasksTableClearFilter = () => {
    this.setState({
      filteredTasksCollection: null,
      tasksFilter: [],
      isFiltersApplied: false,
    });
  };

  /**
   *
   * @param task
   */
  onEditTask = task => {
    const {projectId} = this.props.match.params;
    history.push(`/project/${projectId}/shot/${task.shot_id}/task/${task.id}`);
  };

  /**
   *
   * @returns {*}
   */
  render() {
    const loadingIndicator = this.props.project.fetch ? <LoadingIndicator/> : null;
    const tasks = this.state.filteredTasksCollection || this.pureTasksCollection;

    const projectName = this.props.project.model ? this.props.project.model.name : "";
    const artistName = this.pureTasksCollection.length ? this.pureTasksCollection[0].assignee.name : "";
    const modalTitle = `${artistName} Tasks in project: ${projectName}`;

    return (
      <ModalTemplate
        className="modal-lg"
        title={modalTitle}
        onClose={this.props.onClose}
        cancelButtonLabel="Cancel"
        onCancel={this.props.onClose}
        loadingIndicator={loadingIndicator}
      >
        <div className="mb-5">
          <TasksCustomizableDataTable
            pureTasks={this.pureTasksCollection}
            filteredTasks={tasks}
            onFilter={this.onTasksTableFilter}
            onClearAll={this.onTasksTableClearFilter}
            isFiltersApplied={this.state.isFiltersApplied}
            onEditTask={this.onEditTask}
          />
        </div>
        <ApiError error={this.props.project.fetchError}/>
      </ModalTemplate>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  project: state.project,
});

const mapDispatchToProps = dispatch => ({
  fetchProject: (id) => dispatch(projectActions.fetchProject(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(ArtistTasksModal)
);
