import PropTypes from "prop-types";

const TimeSpentFormat = (props) => {
  const { project } = props;

  if (!project) {
    return "--";
  }

  return !project.time_spent && project.time_spent !== 0
    ? "--"
    : (project.time_spent / 60 / 60).toFixed(2);
};

TimeSpentFormat.propTypes = {
  project: PropTypes.object,
};

export default TimeSpentFormat;
