import React, { Component } from "react";
import { history } from "../../../configureStore";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import Validator from "validatorjs";
import {
  getObjValueWithDotNotation,
  tasksTypesToOptions,
  hasRights,
} from "../../../helpers/tools";
import Checkbox from "../../../components/Checkbox";
import Select from "react-select";
import ShotEditRow from "../../../components/ShotEditRow";
import FormValidationError from "../../../components/FormValidationError";
import * as bidActions from "../../../actions/bid";
import * as projectActions from "../../../actions/project";
import * as shotVersionActions from "../../../actions/shotVersion";
import connect from "react-redux/es/connect/connect";
import LoadingIndicator from "../../../components/LoadingIndicator";
import HasRights from "../../../components/HasRights";
import DropdownMenu from "../../../components/DropdownMenu";
import StatusEditable from "../../../components/StatusEditable";
import ShotsTableHeader from "./ShotsTableHeader";

const ov = getObjValueWithDotNotation;

const topArrowKeyCode = 38;
const bottomArrowKeyCode = 40;
const leftArrowKeyCode = 37;
const rightArrowKeyCode = 39;

const shotsOutstandingText =
  "This shot has been added after bid has been approved by the client";

class ShotsTableWithInputsNavigation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      focusedRowIndex: null,
      changedRows: [],
      toRemoveRows: [],
      newShotsLength: 0,
      tasksTypes: {},
      formValidationErrors: {},
      shotToDuplicate: null,
      pureShotWithDefaultTaskTypes: null,
      costs: {},
      statuses: {},
      csvShots: [],
      expandedShots: [],
      selectedShots: [],
    };

    this.statuses = this.props.statuses.collection.filter(
      (i) => i.type === "Shots"
    );
  }

  /**
   *
   */
  componentDidMount() {
    const role =
      this.props.user && this.props.user.model
        ? this.props.user.model.role
        : "";

    setTimeout(window.updateJQuery, 100);
    // setTimeout(() => {
    this.setTasksTypesDefaultValuesToState();
    // }, 3000);
    if (
      !this.props.collection.length &&
      ["admin", "producer"].includes(role) &&
      !this.props.isPreview
    ) {
      //this.addNewShot();
    }
  }

  /**
   *
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    const csvShots =
      (this.props.history.location.state &&
        this.props.history.location.state.csvShots) ||
      [];

    if (csvShots.length > 0) {
      setTimeout(() => {
        this.setState(
          {
            csvShots: csvShots.map((item) =>
              this.convertCSVShotsToShotsTableFormat(item)
            ),
          },
          () => {
            this.setTasksTypesDefaultValuesToState();
            this.markGroupAsChanged(
              this.getCSVParsedShotsLineStartIndex(),
              csvShots.length,
              this.clearCSVShotsHistoryState
            );
            this.setState({ toRemoveRows: [] });
          }
        );
      }, 0);
    }
  }

  /**
   *
   */
  updateValues = () => {
    document
      .querySelectorAll(".js-shots-table-with-inputs-navigation input")
      .forEach((item) => {
        if (item.attributes.value && item.attributes.value.value) {
          item.value = item.attributes.value.value;
        }
      });
  };

  /**
   *
   * @param item
   * @param index
   * @returns {function(...[*]=)}
   */
  handleSelect = (item, index) => (event) => {
    if (event.target.checked) {
      const shotsSelected = [...this.state.selectedShots, item.id];

      // if user holds shift key pressed, find a selected shot listed before selected one,
      // then select all shots in between selected one at the top and the one currently selected
      if (event.nativeEvent.shiftKey) {
        let processingInBetweenShots = false;
        [...this.props.collection].reverse().map((i) => {
          if (!processingInBetweenShots && i.id === item.id) {
            processingInBetweenShots = true;
          } else if (processingInBetweenShots) {
            if (this.state.selectedShots.indexOf(i.id) >= 0) {
              processingInBetweenShots = false;
            } else {
              shotsSelected.push(i.id);
            }
          }
        });
      }

      this.setState(
        {
          selectedShots: shotsSelected,
        },
        () => {
          if (typeof this.props.onSelectShot === "function") {
            this.props.onSelectShot(shotsSelected);
          }
        }
      );
    } else {
      this.setState(
        {
          selectedShots: this.state.selectedShots.filter(
            (shotId) => shotId !== item.id
          ),
        },
        () => {
          if (typeof this.props.onSelectShot === "function") {
            this.props.onSelectShot([...this.state.selectedShots]);
          }
        }
      );
    }
  };

  /**
   *
   * @param index
   * @returns {Function}
   */
  handleChange = (index) => (event) => {
    const changedRows = [...this.state.changedRows];
    if (changedRows.indexOf(index) === -1) {
      changedRows.push(index);
      this.setState({ changedRows });
    }
  };

  /**
   *
   * @param index
   * @returns {Function}
   */
  handleFocus = (index) => (event) => {
    this.selectText(event.target, [
      "js-shot-in-table-edit-row-rate",
      "js-shot-in-table-edit-row-hour",
    ]);
    this.setState({ focusedRowIndex: index });
  };

  /**
   *
   * @param field
   * @param classesToSelect
   */
  selectText = (field, classesToSelect) => {
    const shouldBeSelected = classesToSelect.filter(
      (item) => field.className.indexOf(item) > -1
    ).length;
    if (shouldBeSelected) {
      field.select();
    }
  };

  /**
   *
   * @param index
   * @returns {Function}
   */
  handleBlur = (index) => (event) => {
    this.setState({ focusedRowIndex: null });
  };

  /**
   *
   * @param index
   * @returns {Function}
   */
  handleRemove = (index) => (event) => {
    const toRemoveRows = [...this.state.toRemoveRows];
    const indexInToRemoveAr = toRemoveRows.indexOf(index);
    if (indexInToRemoveAr === -1) {
      toRemoveRows.push(index);
    } else {
      toRemoveRows.splice(indexInToRemoveAr, 1);
    }
    this.setState({ toRemoveRows });
  };

  /**
   *
   * @param index
   * @param className
   * @param type
   * @returns {Function}
   */
  handleArrows = (index, className, type) => (event) => {
    if (event.keyCode === topArrowKeyCode) {
      this.handleOnTopArrowPress(event, index, className, type);
    } else if (event.keyCode === bottomArrowKeyCode) {
      this.handleOnBottomArrowPress(event, index, className, type);
    } else if (event.keyCode === leftArrowKeyCode) {
      this.handleOnLeftArrowPress(event, index, className, type);
    } else if (event.keyCode === rightArrowKeyCode) {
      this.handleOnRightArrowPress(event, index, className, type);
    }
  };

  /**
   *
   * @param event
   * @param index
   * @param className
   * @param type
   */
  handleOnTopArrowPress = (event, index, className, type) => {
    event.preventDefault();
    const prevLine = this.isFieldEnabledWhenShotIsApproved(event.target, type)
      ? event.target.closest("tr").previousElementSibling
      : this.findEnabledSibling(event.target.closest("tr"), "prev");
    if (prevLine) {
      const inputAbove =
        type !== "select"
          ? prevLine.querySelector("." + className)
          : prevLine.querySelector("." + className + " input");
      inputAbove.focus();
    }
  };

  /**
   *
   * @param event
   * @param index
   * @param className
   * @param type
   */
  handleOnBottomArrowPress = (event, index, className, type) => {
    event.preventDefault();
    const nextLine = this.isFieldEnabledWhenShotIsApproved(event.target, type)
      ? event.target.closest("tr").nextElementSibling
      : this.findEnabledSibling(event.target.closest("tr"), "next");
    if (nextLine) {
      const inputBelow =
        type !== "select"
          ? nextLine.querySelector("." + className)
          : nextLine.querySelector("." + className + " input");
      inputBelow.focus();
    }
  };

  /**
   *
   * @param event
   * @param index
   * @param className
   * @param type
   */
  handleOnLeftArrowPress = (event, index, className, type) => {
    const prevSibling = this.findEnabledSibling(
      event.target.closest(".js-row-selection"),
      "prev"
    );
    const inputLeft = prevSibling.querySelector(
      'input:not([type="hidden"]):not([type="checkbox"]), textarea:not([type="hidden"])'
    );
    if (
      event.target.selectionStart === 0 ||
      event.target.selectionStart === null
    ) {
      if (inputLeft) {
        this.setSelectionRange(inputLeft, "end");
      } else {
        const prevLine = event.target.closest("tr").previousElementSibling;
        if (prevLine) {
          const prevInputs = prevLine.querySelectorAll(
            ".js-row-selection input.form-control:not([disabled])," +
              ".js-row-selection textarea .form-control:not([disabled])"
          );
          const prevInput = prevInputs[prevInputs.length - 1];
          this.setSelectionRange(prevInput, "end");
        }
      }
    }
  };

  /**
   *
   * @param event
   * @param index
   * @param className
   * @param type
   */
  handleOnRightArrowPress = (event, index, className, type) => {
    const nextSibling = this.findEnabledSibling(
      event.target.closest(".js-row-selection"),
      "next"
    );
    const inputRight = nextSibling.querySelector(
      'input:not([type="hidden"]):not([type="checkbox"]), textarea:not([type="hidden"])'
    );

    if (
      event.target.selectionStart === event.target.value.length ||
      event.target.selectionStart === null
    ) {
      if (inputRight) {
        this.setSelectionRange(inputRight, "start");
      } else {
        const nextLine = event.target.closest("tr").nextElementSibling;
        if (nextLine) {
          const nextInputs = nextLine.querySelectorAll(
            ".js-row-selection input.form-control:not([disabled])," +
              ".js-row-selection textarea.form-control:not([disabled])"
          );
          const nextInput = nextInputs[0];
          this.setSelectionRange(nextInput, "start");
        }
      }
    }
  };

  /**
   *
   * @param input
   * @param pos
   */
  setSelectionRange = (input, pos) => {
    setTimeout(() => {
      input.focus();
      if (input.type === "number") {
        return;
      }
      if (pos === "end") {
        input.setSelectionRange(input.value.length, input.value.length);
      } else {
        input.setSelectionRange(0, 0);
      }
    }, 0);
  };

  /**
   *
   * @param element
   * @param direction
   */
  findEnabledSibling = (element, direction) => {
    let sibling = null;
    if (direction === "prev") {
      sibling = element.previousElementSibling;
      if (sibling && sibling.attributes["disabled"]) {
        return this.findEnabledSibling(sibling, "prev");
      }
    } else if (direction === "next") {
      sibling = element.nextElementSibling;
      if (sibling && sibling.attributes["disabled"]) {
        return this.findEnabledSibling(sibling, "next");
      }
    }
    return sibling;
  };

  /**
   *
   * @param field
   * @param type
   * @returns {boolean}
   */
  isFieldEnabledWhenShotIsApproved = (field, type) => {
    return type !== "select"
      ? Array.from(field.classList).indexOf(
          "js-field-is-enabled-when-shot-is-approved"
        ) > -1
      : !!field.closest(".js-field-is-enabled-when-shot-is-approved");
  };

  /**
   *
   * @param event
   */
  onPressAddShot = (event) => {
    event.preventDefault();
    this.addNewShot();
  };

  /**
   *
   */
  addNewShot = () => {
    const pureShotWithDefaultValues = {
      task_types: this.props.tasksTypes.collection
        .filter((item) => !!item.is_default)
        .map((item) => {
          return {
            id: item.id,
            name: item.name,
          };
        }),
      current_version: {
        is_approved: 0,
        rate: this.getDefaultRate(),
      },
    };

    this.setState(
      { pureShotWithDefaultTaskTypes: pureShotWithDefaultValues },
      () => {
        this.setState({ newShotsLength: this.state.newShotsLength + 1 }, () => {
          this.setState({ pureShotWithDefaultTaskTypes: null });
        });
      }
    );
  };

  /**
   *
   * @returns {Promise<void>}
   */
  onPressApplyChanges = async () => {
    const saveData = this.prepareSaveData();
    const hasErrors = this.validateErrors(saveData);

    if (!hasErrors) {
      if (typeof this.props.onBeforeApplyChanges === "function") {
        await this.props.onBeforeApplyChanges();
      }
      await this.handleDataSend(saveData);
    }
  };

  /**
   *
   * @param data
   * @returns {boolean}
   */
  validateErrors = (data) => {
    const validatableDate = [...data.changed, ...data.new];

    const formValidationErrors = {};
    const rules = {
      shot_number: "required|min:2",
      rate: "numeric",
      hours: "numeric",
    };

    validatableDate.forEach((item) => {
      const validation = new Validator(item, rules);
      let errors = null;
      validation.fails(() => {
        errors = validation.errors.all();
        formValidationErrors[item.index] = errors;
      });
    });

    this.setState({ formValidationErrors });

    return !!Object.keys(formValidationErrors).length;
  };

  /**
   *
   * @param data
   * @returns {Promise<void>}
   */
  handleDataSend = async (data) => {
    this.setState({ isSaving: true });

    data = this.convertShotsToApiFormat(data);
    data = this.restrictSavingValuesByRights(data);

    if (data.changed.length) {
      let shotsWithoutShotVersion = data.changed.map((shot) => {
        let shotWithoutShotVersion = { ...shot };
        delete shotWithoutShotVersion.shot_version;
        return shotWithoutShotVersion;
      });

      await this.props.bulkEditShots(shotsWithoutShotVersion);
    }

    if (data.new.length) {
      await this.props.bulkCreateShots(data.new);
    }

    for (const item of data.changed) {
      await this.props.createShotVersion(item.id, item.shot_version);
    }
    for (const item of data.removed) {
      await this.props.deleteShot(item.id);
    }

    this.resetRowsData();
    this.updateValues();
    this.setTasksTypesDefaultValuesToState();
    this.setState({ isSaving: false });
    setTimeout(window.updateJQuery, 200);
  };

  /**
   *
   * @param data
   */
  restrictSavingValuesByRights = (data) => {
    let restrictedData = { ...data };

    if (
      !hasRights(this.props.user.model, {}, ["admin", "producer", "accountant"])
    ) {
      restrictedData.changed = restrictedData.changed.map((item) => {
        let { task_types, rate, hours, ...rest } = item.shot_version;
        return {
          ...item,
          shot_version: rest,
        };
      });
    }

    return restrictedData;
  };

  /**
   *
   * @param data
   */
  convertShotsToApiFormat = (data) => {
    let resultObj = {};
    for (let key in data) {
      if (key !== "removed") {
        resultObj[key] = data[key].map((item) => {
          let formattedObj = {
            shot_number: item.shot_number,
            hide_from_project_page: item.hidden_from_project_page,
            status: item.status,
            shot_version: {
              name: item.shot_number,
              description: item.description,
              notes: item.notes,
              // client_notes: item.client_notes,
              is_approved: item.is_approved === "Yes" ? 1 : 0,
              rate: item.rate || 0,
              hours: item.hours || 0,
            },
          };
          if (item.bid_id) {
            formattedObj.bid_id = item.bid_id;
          }
          if (item.delivery_date) {
            formattedObj.delivery_date = item.delivery_date;
          }
          if (item.id) {
            formattedObj.id = item.id;
          }
          if (item.order_index) {
            formattedObj.order_index = item.order_index;
          }
          if (item.task_types) {
            formattedObj.shot_version.task_types = item.task_types.map(
              (item) => {
                return { id: item };
              }
            );
          }
          return formattedObj;
        });
      } else {
        resultObj[key] = [...data[key]];
      }
    }
    return resultObj;
  };

  /**
   *
   * @returns {{changed: Array, new: Array, removed: Array}}
   */
  prepareSaveData = () => {
    const changedRows = document.querySelectorAll(
      ".js-shots-table-with-inputs-navigation " +
        ".table-with-inputs-navigation__row_changed:not(.table-with-inputs-navigation__row_to-remove)"
    );
    const removedRows = document.querySelectorAll(
      ".js-shots-table-with-inputs-navigation .table-with-inputs-navigation__row_to-remove"
    );

    const saveData = { changed: [], new: [], removed: [] };

    if (changedRows.length) {
      changedRows.forEach((item) => {
        const itemId = item.querySelector(
          ".js-shot-in-table-edit-row-id"
        ).value;
        const index = item.querySelector(
          ".js-shot-in-table-edit-row-index"
        ).value;
        if (itemId === "new") {
          saveData.new.push({
            ...this.getRowResult(item),
            bid_id: this.props.bid.model.id,
            delivery_date: this.props.project.model.due_date,
            index,
            order_index: index,
            task_types: this.state.tasksTypes[index],
          });
        } else {
          saveData.changed.push({
            ...this.getRowResult(item),
            id: itemId,
            index,
            task_types: this.state.tasksTypes[index],
          });
        }
      });
    }

    if (removedRows.length) {
      removedRows.forEach((item) => {
        const itemId = item.querySelector(
          ".js-shot-in-table-edit-row-id"
        ).value;
        if (itemId !== "new") {
          saveData.removed.push({ id: itemId });
        }
      });
    }

    return saveData;
  };

  /**
   *
   */
  resetRowsData = () => {
    this.setState({
      changedRows: [],
      toRemoveRows: [],
      tasksTypes: {},
      newShotsLength: 0,
      costs: {},
      statuses: {},
      csvShots: [],
      expandedShots: [],
      selectedShots: [],
    });
  };

  /**
   *
   * @param row
   * @returns {{shot_number: *, description: *, notes: *, is_approved: *, rate: *, hours: *}}
   */
  getRowResult = (row) => {
    const index = row.querySelector(".js-shot-in-table-edit-row-index").value;

    const shotNumberEl = row.querySelector(
      ".js-shot-in-table-edit-row-shot-number"
    );
    const descriptionEl = row.querySelector(
      ".js-shot-in-table-edit-row-description"
    );
    const notesEl = row.querySelector(".js-shot-in-table-edit-row-bid-note");
    const isApprovedEl = row.querySelectorAll(
      ".js-shot-in-table-edit-row-client-approval > div > div > div"
    )[0];
    const rateEl = row.querySelector(".js-shot-in-table-edit-row-rate");
    const hours = row.querySelector(".js-shot-in-table-edit-row-hour");

    const isHiddenFromProject = row.querySelector(
      ".js-shot-in-table-edit-row-is-hidden-from-project"
    ); /*.checked
      ? 1
      : 0;*/

    const data = {
      shot_number: shotNumberEl ? shotNumberEl.value : undefined,
      description: descriptionEl ? descriptionEl.value : undefined,
      notes: notesEl ? notesEl.value : undefined,
      // client_notes: row.querySelector('.js-shot-in-table-edit-row-client-notes').value,
      is_approved: isApprovedEl ? isApprovedEl.innerText : undefined,
      rate: rateEl ? rateEl.value : undefined,
      hours: hours ? hours.value : undefined,
    };

    if (isHiddenFromProject) {
      data.hidden_from_project_page = isHiddenFromProject.checked ? 1 : 0;
    }

    if (this.state.statuses[index]) {
      data.status = this.state.statuses[index];
    }

    return data;
  };

  /**
   *
   * @param shot
   * @returns {function(...[*]=)}
   */
  onPressDuplicate = (shot) => () => {
    const collection = this.props.collection;

    const shotDuplicate = { ...shot };

    shotDuplicate.shot_number = shot.shot_number;

    const recentVersion = shot.versions[shot.versions.length - 1];

    shotDuplicate.task_types = [...recentVersion.task_types];

    shotDuplicate.current_version = {
      is_approved: false,
      rate: recentVersion.rate,
      hours: recentVersion.hours,
      notes: recentVersion.notes,
      description: recentVersion.description,
    };

    this.setState({ shotToDuplicate: shotDuplicate }, () => {
      this.setState({ newShotsLength: this.state.newShotsLength + 1 }, () => {
        this.setState({ shotToDuplicate: null });
        const lastCollectionIndex = collection.length
          ? collection[collection.length - 1].order_index
          : this.props.collection.length;
        this.handleChange(lastCollectionIndex + this.state.newShotsLength)(
          null
        );
      });
    });
  };

  /**
   *
   * @returns {*}
   */
  getDefaultRate = () => {
    if (this.props.supplier.model) {
      const defaultRateSetting = this.props.supplier.model.settings.find(
        (i) => i.name === "supplier.default_rate"
      );
      return defaultRateSetting && defaultRateSetting.value
        ? defaultRateSetting.value
        : 0;
    }
    return 0;
  };

  /**
   *
   */
  onRateChange = (index) => (event) => {
    let costs = { ...this.state.costs };
    costs[index] = this.calculateCost(index);
    this.setState({ costs });
    this.handleChange(index)(null);
  };

  /**
   *
   */
  onHourChange = (index) => (event) => {
    let costs = { ...this.state.costs };
    costs[index] = this.calculateCost(index);
    this.setState({ costs });
    this.handleChange(index)(null);
  };

  /**
   *
   */
  handleStatusChange = (index) => (value) => {
    const statuses = { ...this.state.statuses };
    statuses[index] = value;
    this.setState({ statuses });
    this.handleChange(index)(null);
  };

  /**
   *
   * @returns {number}
   */
  calculateCost = (index) => {
    const rootElement = document.querySelector(
      ".js-shots-table-with-inputs-navigation .table-with-inputs-navigation__row-" +
        index
    );
    return (
      (rootElement.querySelector(".js-shot-in-table-edit-row-rate").value ||
        0) *
      (rootElement.querySelector(".js-shot-in-table-edit-row-hour").value || 0)
    );
  };

  /**
   *
   * @param index
   * @returns {Function}
   */
  onTasksTypesChange = (index) => (event) => {
    let tasksTypes = { ...this.state.tasksTypes };
    tasksTypes[index] = (event || []).map((item) => item.value.split("|")[0]);
    this.setState({ tasksTypes });
    if (!this.state.pureShotWithDefaultTaskTypes) {
      this.handleChange(index)(null);
    }
  };

  /**
   *
   */
  setTasksTypesDefaultValuesToState = () => {
    let tasksTypes = {};
    let csvParsedShotsLineStartIndex = this.getCSVParsedShotsLineStartIndex();

    this.props.collection.forEach((shot, index) => {
      tasksTypes[index + "_fromBackend"] = (
        shot.versions[shot.versions.length - 1].task_types || []
      ).map((taskType) => taskType.id);
    });

    this.state.csvShots.forEach((shot, index) => {
      tasksTypes[csvParsedShotsLineStartIndex + index] = (
        shot.current_version.task_types || []
      ).map((taskType) => taskType.id);
    });
    this.setState({ tasksTypes });
  };

  /**
   *
   * @returns {number}
   */
  getCSVParsedShotsLineStartIndex = () => {
    return this.props.collection.length
      ? this.props.collection[this.props.collection.length - 1].order_index + 1
      : 0;
  };

  /**
   *
   * @param shot
   * @returns {Promise<void>}
   */
  onMarkOmittedShot = async (shot) => {
    await this.props.bulkEditShots([{ id: shot.id, status: "omit" }]);
    await this.props.createShotVersion(shot.id, {
      description: shot.versions[shot.versions.length - 1].description,
      hours: shot.versions[shot.versions.length - 1].hours,
      is_approved: shot.versions[shot.versions.length - 1].is_approved,
      name: shot.shot_number,
      notes: shot.versions[shot.versions.length - 1].notes,
      rate: "0",
      task_types: [...shot.versions[shot.versions.length - 1].task_types],
    });
    document
      .querySelector(
        ".js-shots-table-with-inputs-navigation .js-shot-in-table-edit-row-id[value='" +
          shot.id +
          "']"
      )
      .closest(".js-row-selection")
      .querySelector(".js-shot-in-table-edit-row-rate").value = 0;
  };

  /**
   *
   */
  convertCSVShotsToShotsTableFormat = (item) => {
    let tasks = (item.task_types || []).map((task_type) => ({
      task_type: {
        id: this.props.tasksTypes.collection.find(
          (taskTypeOriginal) =>
            taskTypeOriginal.name.toLowerCase() === task_type.toLowerCase()
        ).id,
        name: task_type,
      },
    }));
    return {
      shot_number: item.shot_number,
      current_version: {
        description: item.description,
        name: item.shot_number,
        notes: item.notes,
        rate: item.rate,
        hours: item.hours,
        task_types: tasks.map((item) => item.task_type),
      },
      tasks,
    };
  };

  /**
   *
   * @param startIndex
   * @param length
   * @param callback
   */
  markGroupAsChanged = (startIndex, length, callback) => {
    if (length) {
      let changedRows = [...this.state.changedRows];
      [...Array(length + 1)].forEach((item, index) => {
        if (changedRows.indexOf(startIndex + index) === -1) {
          changedRows.push(startIndex + index);
        }
      });
      this.setState({ changedRows }, () => {
        if (typeof callback === "function") {
          callback();
        }
      });
    }
  };

  /**
   *
   */
  clearCSVShotsHistoryState = () => {
    if (history.location.state && history.location.state.csvShots) {
      let state = { ...history.location.state };
      delete state.csvShots;
      history.replace({ ...history.location, state });
    }
  };

  /**
   *
   * @param index
   * @returns {function(): void}
   */
  handleExpand = (index) => () => {
    if (this.state.expandedShots.includes(index)) {
      this.setState({
        expandedShots: this.state.expandedShots.filter(
          (item) => item !== index
        ),
      });
    } else {
      this.setState({ expandedShots: [...this.state.expandedShots, index] });
    }
  };

  /**
   *
   */
  onSelectAllShots = () => {
    const newState = {};
    if (this.state.selectedShots.length == this.props.collection.length) {
      newState.selectedShots = [];
    } else {
      newState.selectedShots = this.props.collection.map((i) => i.id);
    }

    if (typeof this.props.onSelectShot === "function") {
      this.props.onSelectShot([...newState.selectedShots]);
    }
    this.setState(newState);
  };

  /**
   *
   * @returns {XML}
   */
  render() {
    const role =
      this.props.user && this.props.user.model
        ? this.props.user.model.role
        : "";
    const { isPreview } = this.props;

    const collection = this.props.collection;

    const useShotsAsHoursBid =
      this.props.bid.model && this.props.bid.model.use_shots_as_hours_bid
        ? true
        : false;

    const isLoading =
      this.props.bidVersion.update ||
      this.props.bid.createShotsBulk ||
      this.props.bid.edit ||
      this.props.project.delete ||
      this.props.project.createShotVersion ||
      this.props.shot.edit ||
      this.state.isSaving;

    const loadingIndicator = isLoading ? (
      <div className="float-right mr-3 mt-7px mb-n7px">
        <LoadingIndicator />
      </div>
    ) : null;

    const clientApprovalSelectValues = [
      { label: "Yes", value: "1" },
      { label: "No", value: "0" },
    ];

    const tasksTypesOptions = tasksTypesToOptions(
      this.props.tasksTypes.collection
    ).map((item) => {
      //allows to choose the same values in multiple select
      return {
        value: item.value + "|" + +new Date(),
        label: item.label,
      };
    });

    let csvParsedShotsLineStartIndex = this.getCSVParsedShotsLineStartIndex();
    let csvShots = this.state.csvShots;
    const csvParsedShotsLines = csvShots.map((item, index) => {
      const rowIndex = csvParsedShotsLineStartIndex + index;

      item.task_types = item.tasks.map((itemTasks) => itemTasks.task_type);
      const isExpanded = this.state.expandedShots.includes(rowIndex);

      const rowClassName = classNames(
        "table-with-inputs-navigation__row-" + rowIndex,
        {
          "table-with-inputs-navigation__row_changed":
            this.state.changedRows.indexOf(rowIndex) >= 0,
        },
        {
          "table-with-inputs-navigation__row_focused":
            rowIndex === this.state.focusedRowIndex,
        },
        {
          "table-with-inputs-navigation__row_to-remove":
            this.state.toRemoveRows.indexOf(rowIndex) >= 0,
        }
      );

      const formValidationErrors = this.state.formValidationErrors;
      const errors = formValidationErrors[rowIndex]
        ? formValidationErrors[rowIndex]
        : null;

      return (
        <tr key={index} className={rowClassName}>
          <td>
            <ShotEditRow
              shotData={item}
              tasksTypesOptions={tasksTypesOptions}
              onTasksTypesChange={this.onTasksTypesChange}
              hideCancel={true}
              hideSave={true}
              hideLoadingIndicator={true}
              rowIndex={rowIndex}
              isExpanded={isExpanded}
              handleChange={this.handleChange}
              handleFocus={this.handleFocus}
              handleBlur={this.handleBlur}
              handleExpand={this.handleExpand}
              handleArrows={this.handleArrows}
              handleRemove={this.handleRemove}
              validationErrors={errors}
              isLoading={isLoading}
            />
          </td>
        </tr>
      );
    });

    const newShotsLines = [...Array(this.state.newShotsLength)].map(
      (item, index) => {
        const rowIndex =
          (collection.length
            ? collection[collection.length - 1].order_index + index + 1
            : index) + this.state.csvShots.length;

        const isExpanded = this.state.expandedShots.includes(rowIndex);

        const rowClassName = classNames(
          "table-with-inputs-navigation__row-" + rowIndex,
          {
            "table-with-inputs-navigation__row_changed":
              this.state.changedRows.indexOf(rowIndex) >= 0,
          },
          {
            "table-with-inputs-navigation__row_focused":
              rowIndex === this.state.focusedRowIndex,
          },
          {
            "table-with-inputs-navigation__row_to-remove":
              this.state.toRemoveRows.indexOf(rowIndex) >= 0,
          }
        );

        const formValidationErrors = this.state.formValidationErrors;
        const errors = formValidationErrors[rowIndex]
          ? formValidationErrors[rowIndex]
          : null;

        return (
          <tr key={index} className={rowClassName}>
            <td>
              <ShotEditRow
                shotData={
                  this.state.shotToDuplicate ||
                  this.state.pureShotWithDefaultTaskTypes
                }
                tasksTypesOptions={tasksTypesOptions}
                onTasksTypesChange={this.onTasksTypesChange}
                hideCancel={true}
                hideSave={true}
                hideLoadingIndicator={true}
                rowIndex={rowIndex}
                isExpanded={isExpanded}
                handleChange={this.handleChange}
                handleFocus={this.handleFocus}
                handleBlur={this.handleBlur}
                handleExpand={this.handleExpand}
                handleArrows={this.handleArrows}
                handleRemove={this.handleRemove}
                validationErrors={errors}
                isLoading={isLoading}
              />
            </td>
          </tr>
        );
      }
    );

    const addNewShotBtn = ["admin", "producer"].includes(role) ? (
      <HasRights
        allowedRoles={["admin", "producer"]}
        user={isPreview ? null : this.props.user.model}
      >
        <button
          type="button"
          className="btn bg-primary"
          onClick={this.onPressAddShot}
          disabled={isLoading}
        >
          <i className="mi-add" /> Add new shot
        </button>
      </HasRights>
    ) : null;

    const applyChangesBtn =
      (this.props.collection.length ||
        this.state.newShotsLength ||
        this.state.csvShots.length) &&
      ["admin", "producer", "client"].includes(role) &&
      !isPreview ? (
        <button
          type="button"
          className="btn bg-primary float-right"
          onClick={this.onPressApplyChanges}
          disabled={isLoading}
        >
          <i className="mi-check" /> Apply changes
        </button>
      ) : null;

    return (
      <>
        <div className="d-flex justify-content-between mb-2">
          <h5>Shots</h5>
          {addNewShotBtn}
        </div>
        <div className="table-with-inputs-navigatio-w js-shots-table-with-inputs-navigation">
          <div className="table-with-inputs-navigatio-w__inner">
            <table className="table-with-inputs-navigation">
              <ShotsTableHeader
                isPreview={isPreview}
                useShotsAsHoursBid={useShotsAsHoursBid}
                isLoading={isLoading}
                rowsCount={this.props.collection.length}
                selectedRowsCount={this.state.selectedShots.length}
                onSelectAll={this.onSelectAllShots}
              />
              <tbody>
                {newShotsLines}
                {this.props.collection.map((item, index) => {
                  const isSelected =
                    this.state.selectedShots.indexOf(item.id) > -1;

                  index = index + "_fromBackend";

                  const isExpanded = this.state.expandedShots.includes(index);

                  const isChanged = this.state.changedRows.indexOf(index) >= 0;
                  const isFocused = index === this.state.focusedRowIndex;
                  const isSelectedToRemove =
                    this.state.toRemoveRows.indexOf(index) >= 0;

                  const rowClassName = classNames(
                    "table-with-inputs-navigation__row-saved",
                    "table-with-inputs-navigation__row-" + index,
                    {
                      "table-with-inputs-navigation__row_changed": isChanged,
                    },
                    {
                      "table-with-inputs-navigation__row_focused": isFocused,
                    },
                    {
                      "table-with-inputs-navigation__row_to-remove":
                        isSelectedToRemove,
                    },
                    {
                      "table-with-inputs-navigation__row-selected":
                        !isChanged &&
                        !isSelectedToRemove &&
                        !isFocused &&
                        isSelected,
                    }
                  );

                  const expandBtnClassName = classNames(
                    {
                      "icon-arrow-down32": !isExpanded,
                    },
                    {
                      "icon-arrow-up32": isExpanded,
                    },
                    "table-with-inputs-navigation_expand",
                    "pointer"
                  );

                  const expandableClassName = classNames(
                    "row",
                    "table-with-inputs-navigation__expanded",
                    {
                      "d-none": !isExpanded,
                    }
                  );

                  const isApproved =
                    (item.versions
                      ? !!item.versions[item.versions.length - 1].is_approved
                      : false) || !!+item.has_approved_version;

                  // const tasksTypesDefaultValues = (item.tasks || []).map(task => {
                  //   return {
                  //     value: task.task_type.id + "|" + Math.random(),
                  //     label: task.task_type.name
                  //   };
                  // });

                  const tasksTypesDefaultValues = (
                    item.versions[item.versions.length - 1].task_types || []
                  ).map((task) => {
                    return {
                      value: task.id + "|" + Math.random(),
                      label: task.name,
                    };
                  });

                  const formValidationErrors = this.state.formValidationErrors;
                  const errors = formValidationErrors[index] ? (
                    <div className="clearfix mr-4 mt-2">
                      <div className="float-right mr-2">
                        <FormValidationError
                          errors={formValidationErrors[index]}
                        />
                      </div>
                    </div>
                  ) : null;

                  return (
                    <tr
                      key={item.id}
                      className={rowClassName}
                      disabled={isApproved}
                    >
                      <td>
                        <div className="row js-row-selection">
                          <div className="col-1 relative d-flex justify-content-start align-items-baseline controls">
                            <HasRights
                              key={`cs-${item.id}-${isSelected ? 1 : 0}`}
                              allowedRoles={["admin", "producer"]}
                              user={isPreview ? null : this.props.user.model}
                            >
                              <Checkbox
                                className="table-with-inputs-navigation_select js-select-shot-checkbox"
                                onChange={this.handleSelect(item, index)}
                                checked={isSelected}
                                disabled={
                                  isLoading ||
                                  !["admin", "producer", "client"].includes(
                                    role
                                  ) ||
                                  isPreview
                                }
                              />
                            </HasRights>
                            <HasRights
                              allowedRoles={["admin", "producer"]}
                              user={isPreview ? null : this.props.user.model}
                            >
                              <i
                                className="mi-close pointer table-with-inputs-navigation_remove"
                                title="Remove shot"
                                onClick={this.handleRemove(index)}
                              />
                            </HasRights>
                            <HasRights
                              allowedRoles={["admin", "producer"]}
                              user={isPreview ? null : this.props.user.model}
                            >
                              <i
                                className="mi-control-point-duplicate pointer table-with-inputs-navigation_duplicate"
                                title="Duplicate shot"
                                onClick={this.onPressDuplicate(item)}
                              />
                            </HasRights>
                            {item.is_outstanding && !isApproved ? (
                              <i
                                className="icon-exclamation table-with-inputs-navigation_approval-exclamation"
                                data-popup="tooltip"
                                data-placement="right"
                                data-original-title={shotsOutstandingText}
                              />
                            ) : (
                              <span />
                            )}
                            <input
                              type="hidden"
                              value={ov(item, "id")}
                              className="js-shot-in-table-edit-row-id"
                            />
                            <input
                              type="hidden"
                              value={index}
                              className="js-shot-in-table-edit-row-index"
                            />
                          </div>

                          <div className="col-2 js-row-selection">
                            <div className="row">
                              <div className="col-12">
                                <input
                                  type="text"
                                  className="form-control js-shot-in-table-edit-row-shot-number
                                                                              js-field-is-enabled-when-shot-is-approved"
                                  placeholder="Shot Number"
                                  defaultValue={ov(item, "shot_number")}
                                  onChange={this.handleChange(index)}
                                  onFocus={this.handleFocus(index)}
                                  onBlur={this.handleBlur(index)}
                                  onKeyDown={this.handleArrows(
                                    index,
                                    "js-shot-in-table-edit-row-shot-number"
                                  )}
                                  disabled={
                                    isLoading ||
                                    !["admin", "producer"].includes(role) ||
                                    isPreview
                                  }
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col">
                                <i
                                  className={expandBtnClassName}
                                  onClick={this.handleExpand(index)}
                                />
                              </div>
                            </div>
                          </div>

                          <HasRights
                            allowedRoles={["admin", "producer", "accountant"]}
                            user={this.props.user.model}
                            isForcedAllow={isPreview}
                            placeholder={<div className="col-1" />}
                          />

                          <div className="col-3 js-row-selection">
                            <input
                              type="text"
                              className="form-control js-shot-in-table-edit-row-description
                                                                              js-field-is-enabled-when-shot-is-approved"
                              placeholder="Description"
                              defaultValue={
                                item.versions[item.versions.length - 1]
                                  .description
                              }
                              onChange={this.handleChange(index)}
                              onFocus={this.handleFocus(index)}
                              onBlur={this.handleBlur(index)}
                              onKeyDown={this.handleArrows(
                                index,
                                "js-shot-in-table-edit-row-description"
                              )}
                              disabled={
                                isLoading ||
                                !["admin", "producer"].includes(role) ||
                                isPreview
                              }
                            />
                          </div>

                          <div className="col-2 js-row-selection">
                            <textarea
                              className="form-control js-shot-in-table-edit-row-bid-note
                                                                              js-field-is-enabled-when-shot-is-approved"
                              placeholder="Bid Note"
                              defaultValue={
                                item.versions[item.versions.length - 1].notes
                              }
                              onChange={this.handleChange(index)}
                              onFocus={this.handleFocus(index)}
                              onBlur={this.handleBlur(index)}
                              onKeyDown={this.handleArrows(
                                index,
                                "js-shot-in-table-edit-row-bid-note"
                              )}
                              disabled={
                                isLoading ||
                                !["admin", "producer", "client"].includes(
                                  role
                                ) ||
                                isPreview
                              }
                            />
                          </div>

                          <div
                            className="form-group col-1 js-row-selection"
                            disabled={isApproved}
                          >
                            <div>
                              <Select
                                className="js-shot-in-table-edit-row-client-approval
                                                                                shot-in-table-edit-row-client-approval"
                                defaultValue={
                                  isApproved
                                    ? clientApprovalSelectValues[0]
                                    : clientApprovalSelectValues[1]
                                }
                                onChange={this.handleChange(index)}
                                onFocus={this.handleFocus(index)}
                                onBlur={this.handleBlur(index)}
                                options={clientApprovalSelectValues}
                                onKeyDown={this.handleArrows(
                                  index,
                                  "js-shot-in-table-edit-row-client-approval",
                                  "select"
                                )}
                                isClearable={false}
                                isDisabled={
                                  isLoading ||
                                  isApproved ||
                                  !["admin", "producer", "client"].includes(
                                    role
                                  ) ||
                                  isPreview
                                }
                              />
                            </div>
                          </div>

                          <HasRights
                            allowedRoles={["admin", "producer", "accountant"]}
                            user={this.props.user.model}
                            isForcedAllow={isPreview}
                            placeholder={<div className="col-1" />}
                          >
                            <div className="col-1 js-row-selection">
                              <input
                                type="text"
                                className="form-control js-shot-in-table-edit-row-rate
                                                                              js-field-is-enabled-when-shot-is-approved"
                                placeholder="0.00"
                                defaultValue={
                                  item.versions[item.versions.length - 1].rate
                                }
                                onChange={this.onRateChange(index)}
                                onFocus={this.handleFocus(index)}
                                onBlur={this.handleBlur(index)}
                                onKeyDown={this.handleArrows(
                                  index,
                                  "js-shot-in-table-edit-row-rate"
                                )}
                                disabled={
                                  isLoading ||
                                  !["admin", "producer"].includes(role) ||
                                  isPreview
                                }
                              />
                            </div>

                            <div className="col-1 js-row-selection">
                              <input
                                type="text"
                                className="form-control js-shot-in-table-edit-row-hour
                                                                              js-field-is-enabled-when-shot-is-approved"
                                placeholder="Hour"
                                defaultValue={
                                  item.versions[item.versions.length - 1].hours
                                }
                                onChange={this.onHourChange(index)}
                                onFocus={this.handleFocus(index)}
                                onBlur={this.handleBlur(index)}
                                onKeyDown={this.handleArrows(
                                  index,
                                  "js-shot-in-table-edit-row-hour"
                                )}
                                disabled={
                                  isLoading ||
                                  !["admin", "producer"].includes(role) ||
                                  isPreview
                                }
                              />
                            </div>
                          </HasRights>

                          <div className="col-1">
                            <div className="row mt-1 mr-1">
                              <div className="col-8">
                                $
                                {this.state.costs[index] ||
                                  item.versions[item.versions.length - 1]
                                    .cost_summary}
                              </div>
                              <HasRights
                                allowedRoles={["admin", "producer"]}
                                user={isPreview ? null : this.props.user.model}
                              >
                                <div className="col-4">
                                  <DropdownMenu
                                    buttons={[
                                      {
                                        action: this.onMarkOmittedShot,
                                        data: item,
                                        label: "Mark as omitted",
                                        disabled: item.status === "omit",
                                      },
                                    ]}
                                  />
                                </div>
                              </HasRights>
                            </div>
                          </div>
                        </div>

                        <div className={expandableClassName}>
                          <div className="col-1" />

                          <div className="form-group col-2">
                            <label className="form-label">Status</label>
                            <div className="m-n1">
                              <StatusEditable
                                status={
                                  this.state.statuses[index] || item.status
                                }
                                statuses={this.statuses}
                                onChange={this.handleStatusChange(index)}
                                loading={false}
                                disabled={
                                  !["admin", "producer", "accountant"].includes(
                                    this.props.user.model.role
                                  ) || this.props.bid.edit
                                }
                              />
                            </div>
                          </div>

                          <HasRights
                            allowedRoles={["admin", "producer", "accountant"]}
                            user={this.props.user.model}
                            placeholder={<div className="col-1" />}
                            isForcedAllow={isPreview}
                          >
                            <div className="form-group col-3">
                              <label className="form-label">Tasks</label>
                              <Select
                                isMulti
                                hideSelectedOptions={false}
                                className="js-shot-in-table-edit-row-tasks shot-in-table-edit-row-client-approval
                                                                              js-field-is-enabled-when-shot-is-approved"
                                defaultValue={tasksTypesDefaultValues}
                                onChange={this.onTasksTypesChange(index)}
                                onFocus={this.handleFocus(index)}
                                onBlur={this.handleBlur(index)}
                                options={tasksTypesOptions}
                                isClearable={false}
                                isDisabled={
                                  isLoading ||
                                  !["admin", "producer"].includes(role) ||
                                  isPreview
                                }
                                menuPortalTarget={document.querySelector(
                                  "body"
                                )}
                              />
                            </div>
                          </HasRights>

                          <HasRights
                            allowedRoles={["admin", "producer", "accountant"]}
                            user={this.props.user.model}
                            placeholder={<div className="col-1" />}
                            isForcedAllow={isPreview}
                          >
                            <div className="form-group">
                              <div className={"form-check"}>
                                <label className="form-check-label">
                                  <input
                                    type={"checkbox"}
                                    className={
                                      "form-check-input-styled js-task-type-edit-is-default js-shot-in-table-edit-row-is-hidden-from-project"
                                    }
                                    onChange={this.handleChange(index)}
                                    onFocus={this.handleFocus(index)}
                                    onBlur={this.handleBlur(index)}
                                    value={1}
                                    defaultChecked={
                                      item.hide_from_project_page * 1
                                    }
                                    disabled={
                                      isLoading ||
                                      !["admin", "producer"].includes(role) ||
                                      isPreview
                                    }
                                  />
                                  Hidden from project
                                </label>
                              </div>
                            </div>
                          </HasRights>
                        </div>

                        {errors}
                      </td>
                    </tr>
                  );
                })}
                {csvParsedShotsLines}
              </tbody>
            </table>
            {(applyChangesBtn || loadingIndicator) && !isPreview && (
              <div className="new-item-line clearfix">
                {applyChangesBtn}
                {loadingIndicator}
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  bid: state.bid,
  bidVersion: state.bidVersion,
  project: state.project,
  shot: state.shot,
  user: state.user,
  tasksTypes: state.tasksTypes,
  statuses: state.statuses,
  supplier: state.supplier,
});

const mapDispatchToProps = (dispatch) => ({
  bulkCreateShots: async (shots) =>
    await dispatch(bidActions.bulkCreateShots(shots)),
  bulkEditShots: async (data) =>
    await dispatch(projectActions.bulkEditShots(data)),
  deleteShot: async (id) => await dispatch(projectActions.deleteShot(id)),
  createShotVersion: async (shot_id, data) =>
    await dispatch(shotVersionActions.createShotVersion(shot_id, data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ShotsTableWithInputsNavigation));

ShotsTableWithInputsNavigation.propTypes = {
  collection: PropTypes.array.isRequired,
  onBeforeApplyChanges: PropTypes.func,
  isPreview: PropTypes.bool,
  onSelectShot: PropTypes.func,
};
