import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ModalTemplate from "./ModalTemplate";
import connect from "react-redux/es/connect/connect";
import LoadingIndicator from "../components/LoadingIndicator";
import ApiError from "../components/ApiError";
import SuccessMessage from "../components/SuccessMessage";
import { onCopyText } from "../helpers/tools";
import * as bidActions from "../actions/bid";
import classNames from "classnames";

class BidShareModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      share: false,
      showCopiedSuccess: false,
    };
    this.showCopiedSuccessTimer = null;
  }

  /**
   *
   * @returns {Promise<void>}
   */
  async componentDidMount() {
    await this.props.fetchBid(this.props.match.params.id);
    if (this.props.bid.fetchSuccess) {
      this.setState(
        {
          share: Boolean(this.props.bid.model.is_shared),
        },
        this.updateShareUrl
      );
    }
  }

  /**
   *
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    const bidModel = this.props.bid.model;
    const prevBidModel = prevProps.bid.model;

    const bidSlug = bidModel ? bidModel.slug : "";

    const prevBidSlug = prevBidModel ? prevBidModel.slug : "";

    if (prevBidSlug !== bidSlug) {
      this.updateShareUrl();
    }
  }

  /**
   *
   */
  componentWillUnmount() {
    if (this.showCopiedSuccessTimer) {
      clearTimeout(this.showCopiedSuccessTimer);
    }
  }

  /**
   *
   */
  updateShareUrl = () => {
    const bidModel = this.props.bid.model;
    const bidSlug = bidModel ? bidModel.slug : "";

    const urlAddr =
      bidModel && bidModel.is_shared
        ? window.location.protocol +
          "//" +
          window.location.host +
          "/b/" +
          bidSlug
        : "";

    document.querySelector(".js-bid-share-url").value = urlAddr;

    window.updateJQuery();
  };

  /**
   *
   */
  onToggleShare = () => {
    this.setState({ share: !this.state.share }, async () => {
      await this.props.editBid(this.props.match.params.id, {
        is_shared: this.state.share ? 1 : 0,
      });
      if (this.props.bid.editSuccess) {
        this.setState({
          share: Boolean(this.props.bid.model.is_shared),
        });
      }
    });
  };

  /**
   *
   * @param id
   * @returns {function(...[*]=)}
   */
  onCopyLink = (id) => () => {
    if (onCopyText(`#${id}`)) {
      this.setState(
        {
          showCopiedSuccess: true,
        },
        () => {
          this.showCopiedSuccessTimer = setTimeout(
            () => this.setState({ showCopiedSuccess: false }),
            3000
          );
        }
      );
    }
  };

  /**
   *
   * @returns {*}
   */
  render() {
    const isLoading = this.props.bid.fetch || this.props.bid.edit;
    const loadingIndicator = isLoading ? <LoadingIndicator /> : null;

    const bidModel = this.props.bid.model;
    const bidName = bidModel ? bidModel.name : "";
    const bidSlug = bidModel ? bidModel.slug : "";

    const project = bidModel.project;
    const projectName = project ? project.name : "";

    return (
      <ModalTemplate
        title={`Share ${projectName} - ${bidName} bid`}
        onClose={this.props.onClose}
        loadingIndicator={loadingIndicator}
      >
        <form noValidate>
          <div className="form-group">
            <p>
              Use this form to create a shareable URL, which you can pass any
              interested party. This bid will be only accessible if the sharing
              is turned on
            </p>
            <div className="form-check">
              <label className="form-check-label">
                <input
                  type="checkbox"
                  className="form-check-input-styled"
                  data-fouc
                  onChange={this.onToggleShare}
                  disabled={isLoading}
                  checked={this.state.share}
                />
                <span className={classNames({ "text-muted": isLoading })}>
                  Share bid via URL
                </span>
              </label>
            </div>
            <div className="form-group row">
              <label className="col-form-label col-1">Link</label>
              <div className="col-10">
                <input
                  id={bidSlug}
                  type="text"
                  className="form-control js-bid-share-url"
                  contentEditable={false}
                  readOnly
                />
              </div>
              <button
                type="button"
                onClick={this.onCopyLink(bidSlug)}
                className="col-1 btn btn-primary btn-icon"
                disabled={!this.state.share}
              >
                <i className="icon-link" />
              </button>
            </div>
            {this.state.showCopiedSuccess && (
              <SuccessMessage isSuccess message="Copied!" />
            )}
            <ApiError error={this.props.bid.editError} />
          </div>
        </form>
      </ModalTemplate>
    );
  }
}

const mapStateToProps = (state) => ({
  bid: state.bid,
});

const mapDispatchToProps = (dispatch) => ({
  fetchBid: async (id) => await dispatch(bidActions.fetchBid(id)),
  editBid: async (id, data) => await dispatch(bidActions.editBid(id, data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BidShareModal));
