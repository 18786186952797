import React from "react";
import { Helmet } from "react-helmet";
import AxiosConfig from "../AxiosConfig";
import PropTypes from "prop-types";

const SeoBlock = (props) => {
  /*****
   *
   * Title
   */

  const title = props.title ? (
    <title>{props.title + " - BLiP"}</title>
  ) : (
    <title>BLiP</title>
  );

  const ogTitle = props.title ? (
    <meta property="og:title" content={props.title + " - BLiP"} />
  ) : (
    <meta property="og:title" content="BLiP" />
  );

  /*****
   *
   * URL
   */

  const link = props.link ? (
    <link rel="canonical" href={props.link} />
  ) : (
    <link rel="canonical" href={window.location.href} />
  );

  const ogLink = props.link ? (
    <meta property="og:url" content={props.link} />
  ) : (
    <meta property="og:url" content={window.location.href} />
  );

  /*****
   *
   * Image
   */

  const icon = props.icon ? (
    <link
      rel="icon"
      type="image/png"
      href={AxiosConfig.getResourceUrl(props.icon)}
    />
  ) : (
    <link
      rel="icon"
      type="image/png"
      href={AxiosConfig.getResourceUrl("/blp_logo.png")}
    />
  );

  const ogImage = props.image ? (
    <meta
      property="og:image"
      content={AxiosConfig.getResourceUrl(props.image)}
    />
  ) : (
    <meta
      property="og:image"
      content={AxiosConfig.getResourceUrl("/blp_logo.png")}
    />
  );

  /*****
   *
   * Description
   */

  const description = props.description ? (
    <meta name="description" content={props.description} />
  ) : (
    <meta name="description" content="BLiP" />
  );

  const ogDescription = props.description ? (
    <meta property="og:description" content={props.description} />
  ) : (
    <meta property="og:description" content="BLiP" />
  );

  /*****
   *
   * Author & keywords
   */

  const author = props.author ? (
    <meta name="author" content={props.author} />
  ) : (
    <meta name="author" content="it@blpvfx.com" />
  );

  /*****
   *
   * Other OG tags
   */

  const ogLocale = props.locale ? (
    <meta property="og:locale" content={props.locale} />
  ) : (
    <meta property="og:locale" content="en_US" />
  );

  const ogType = props.type ? (
    <meta property="og:type" content={props.type} />
  ) : (
    <meta property="og:type" content="website" />
  );

  const ogSite = props.site ? (
    <meta property="og:site_name" content={props.site} />
  ) : (
    <meta property="og:site_name" content="BLP VFX" />
  );

  const html = props.htmlClass ? (
    <html lang="uk-US" className={props.htmlClass} />
  ) : (
    <html lang="uk-US" />
  );

  return (
    <Helmet>
      {title}
      {link}
      {icon}
      {description}
      {author}
      {ogTitle}
      {ogLink}
      {ogImage}
      {ogDescription}
      {ogLocale}
      {ogType}
      {ogSite}

      {html}
    </Helmet>
  );
};

SeoBlock.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
  icon: PropTypes.string,
  description: PropTypes.string,
  site: PropTypes.string,
  type: PropTypes.string,
  image: PropTypes.string,
};

export default SeoBlock;
