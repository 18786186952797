import AxiosConfig from "../AxiosConfig";
import axios from "axios";


/**
 *
 * @returns {Function}
 */
export const fetchTasksTypes = () => {

  return async dispatch => {

    dispatch({type: "TASKS_TYPES_FETCH"});

    const url = AxiosConfig.getEndpointAddress() + '/api/task_types.json';

    await axios.get(
      url,
      AxiosConfig.getAuthConfig(),
    )
      .then((response) => {

        dispatch({
          type: "TASKS_TYPES_FETCH_FULFILLED",
          data: response.data,
        });

      })
      .catch(function (error) {

        dispatch({
          type: "TASKS_TYPES_FETCH_REJECTED",
          data: error,
        });

      });
  }
};

/**
 *
 * @param data
 * @returns {Function}
 */
export const createTaskType = data => {

  return async dispatch => {

    dispatch({type: "TASKS_TYPE_CREATE"});

    const url = AxiosConfig.getEndpointAddress() + "/api/task_types.json";

    await axios.post(
      url,
      data,
      AxiosConfig.getAuthConfig(),
    )
      .then((response) => {

        dispatch({
          type: "TASKS_TYPE_CREATE_FULFILLED",
          data: response.data,
        });

      })
      .catch(function (error) {

        dispatch({
          type: "TASKS_TYPE_CREATE_REJECTED",
          data: error,
        });

      });
  }
};

/**
 *
 * @param id
 * @param data
 * @returns {Function}
 */
export const editTaskType = (id, data) => {

  return async dispatch => {

    dispatch({type: "TASKS_TYPE_EDIT"});

    const url = AxiosConfig.getEndpointAddress() + "/api/task_types/" + id + ".json";

    await axios.put(
      url,
      data,
      AxiosConfig.getAuthConfig(),
    )
      .then((response) => {

        dispatch({
          type: "TASKS_TYPE_EDIT_FULFILLED",
          data: response.data,
        });

      })
      .catch(function (error) {

        dispatch({
          type: "TASKS_TYPE_EDIT_REJECTED",
          data: error,
        });

      });
  }
};

/**
 *
 * @param id
 * @returns {Function}
 */
export const deleteTaskType = id => {

  return async dispatch => {

    dispatch({type: "TASKS_TYPE_DELETE"});

    const url = AxiosConfig.getEndpointAddress() + "/api/task_types/" + id + ".json";

    await axios.delete(
      url,
      {...AxiosConfig.getAuthConfig()},
    )
      .then((response) => {

        dispatch({
          type: "TASKS_TYPE_DELETE_FULFILLED",
          data: {
            responseData: response.data,
            id,
          },
        });

      })
      .catch(function (error) {

        dispatch({
          type: "TASKS_TYPE_DELETE_REJECTED",
          data: error,
        });

      });
  };
};