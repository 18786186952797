import axios from "axios";
import AxiosConfig from "../AxiosConfig";

/**
 *
 * @param page
 * @param limit
 * @param filters
 * @param type
 * @returns {function(*): Promise<T>}
 */
export const fetchBids = (page = 1, limit = 20, filters, type) => {
  return (dispatch) => {
    dispatch({
      type: "BIDS_FETCH",
      data: { page, type, filters },
    });

    const apiFilters = filters || {};
    if (filters && filters.status) {
      apiFilters.status = filters.status.map((x) => x.value || x);
    }
    if (filters && filters.clients) {
      apiFilters.clients = filters.clients.map((x) => x.value || x);
    }

    if (filters && filters.project_id) {
      apiFilters.project_id = filters.project_id;
    }

    const url =
      AxiosConfig.getEndpointAddress() +
      "/api/bids.json?" +
      AxiosConfig.objectToURLQuery({ page, limit, ...apiFilters });

    return axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "BIDS_FETCH_FULFILLED",
          data: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "BIDS_FETCH_REJECTED",
          data: error,
        });
      });
  };
};

/**
 *
 * @param id
 * @returns {function(*): Promise<T>}
 */
export const deleteBid = (id) => {
  return async (dispatch) => {
    dispatch({ type: "BID_DELETE" });

    const url = AxiosConfig.getEndpointAddress() + "/api/bids/" + id + ".json";

    await axios
      .delete(url, { ...AxiosConfig.getAuthConfig() })
      .then((response) => {
        dispatch({
          type: "BID_DELETE_FULFILLED",
          data: {
            responseData: response.data,
            id,
          },
        });
      })
      .catch(function (error) {
        dispatch({
          type: "BID_DELETE_REJECTED",
          data: error,
        });
      });
  };
};
