import React, { Component } from "react";

import ModalTemplate from "./ModalTemplate";
import EditableListTemplate from "../components/EditableListTemplate";

import * as tasksActions from "../actions/tasks";
import * as shotActions from "../actions/shot";
import connect from "react-redux/es/connect/connect";
import { withRouter } from "react-router-dom";
import Validator from "validatorjs";

import LoadingIndicator from "../components/LoadingIndicator";
import TasksEditLine from "../components/TasksEditLine";

class TaskEditModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formValidationErrors: [],
      tasks: [],
    };

    this.taskFields = {
      assignee_id: null,
      notes: "",
      task_type_id: null,
    };
  }

  /**
   *
   */
  async componentDidMount() {
    const shotId = this.props.match.params.shotId;
    if (
      this.isEditMode &&
      (!this.props.shot.model ||
        (this.props.shot.model && this.props.shot.model.id !== shotId))
    ) {
      await this.props.fetchShot(shotId);
    }
  }

  /**
   *
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.shot.fetch === true && this.props.shot.fetchSuccess) {
      this.setEditingByIds();
    }
  }

  /**
   *
   * @returns {boolean}
   */
  get isEditMode() {
    return this.props.match.params.taskId !== "new";
  }

  /**
   *
   */
  addTask = () => {
    this.setSelectsValues(() => {
      this.setState({ tasks: [...this.state.tasks, this.taskFields] }, () => {
        this.setState({ formValidationErrors: [] });
        setTimeout(window.updateJQuery, 100);
      });
    });
  };

  /**
   *
   */
  setEditingByIds = () => {
    const ids = [this.props.match.params.taskId];
    if (this.props.shot.model) {
      const editingAr = this.props.shot.model.tasks.filter(
        (item) => ids.indexOf(item.id) >= 0
      );
      this.setState({ tasks: editingAr }, () =>
        setTimeout(window.updateJQuery, 100)
      );
    }
  };

  /**
   *
   */
  onConfirmPress = async (event) => {
    event.preventDefault();

    this.setSelectsValues(() => {
      const data = [...this.state.tasks];
      this.handleSubmit(data);
    });
  };

  /**
   *
   * @param data
   * @returns {Promise<void>}
   */
  handleSubmit = (data) => {
    const formValidationErrors = [];
    const rules = {};

    let hasErrors = false;
    data.forEach((item) => {
      const validation = new Validator(item, rules);
      let errors = null;
      validation.fails(() => {
        errors = validation.errors.all();
        formValidationErrors.push(errors);
        hasErrors = true;
      });
      validation.passes(() => {
        formValidationErrors.push(null);
      });
    });
    this.setState({ formValidationErrors });

    if (!hasErrors) {
      this.handleDataSend(data);
    }
  };

  /**
   *
   * @param data
   * @returns {Promise<void>}
   */
  handleDataSend = async (data) => {
    if (!this.isEditMode) {
      const dataLength = data.length;
      const shotId = this.props.match.params.shotId;
      let index = 0;

      for (const newTaskData of data) {
        await this.props.createTask(shotId, newTaskData);
        if (this.props.tasks.createError) {
          this.removeHandledTasks(index);
          break;
        }
        index++;
      }

      if (index === dataLength) {
        this.props.onClose();
      }
    } else {
      const editingTaskData = data[0];
      await this.props.editTask(editingTaskData.id, editingTaskData);
      if (this.props.tasks.editError) {
        return;
      }

      if (
        editingTaskData.assignee_id ||
        editingTaskData.assignee_id === "unassigned"
      ) {
        const assigneeId =
          editingTaskData.assignee_id !== "unassigned"
            ? editingTaskData.assignee_id
            : null;
        const tasksIds = [{ id: editingTaskData.id }];
        await this.props.changeAssignee(tasksIds, assigneeId);
      }
      this.props.onClose();
    }
  };

  /**
   *
   * @param deleteToIndex
   */
  removeHandledTasks = (deleteToIndex) => {
    this.setSelectsValues(() => {
      let newTasks = [...this.state.tasks];
      newTasks.splice(0, deleteToIndex);
      this.setState({ tasks: newTasks }, () => {
        this.setState({ formValidationErrors: [] });
        setTimeout(window.updateJQuery, 100);
      });
    });
  };

  /**
   *
   * @param fieldName
   * @param index
   * @param value
   * @param callback
   */
  handleFiled = (fieldName, index, value, callback) => {
    this.setSelectsValues(() => {
      let newTasks = [...this.state.tasks];
      newTasks[index] = { ...newTasks[index], [fieldName]: value };
      this.setState({ tasks: newTasks }, () => {
        if (typeof callback === "function") {
          callback();
        }
      });
    });
  };

  /**
   *
   * @param callback
   */
  setSelectsValues = (callback) => {
    let newTasks = [...this.state.tasks];
    newTasks = newTasks.map((item) => {
      return { ...item };
    });
    document.querySelectorAll(".js-task-type-select").forEach((item, index) => {
      newTasks[index] = { ...newTasks[index], task_type_id: item.value };
    });
    this.setState({ tasks: newTasks }, () => {
      if (typeof callback === "function") {
        callback();
      }
    });
  };

  /**
   *
   * @param index
   * @returns {Function}
   */
  onRemoveBtnPress = (index) => {
    this.setSelectsValues(() => {
      let newTasks = [...this.state.tasks];
      newTasks.splice(index, 1);
      this.setState({ tasks: newTasks }, () => {
        this.setState({ formValidationErrors: [] });
        setTimeout(window.updateJQuery, 100);
      });
    });
  };

  /**
   *
   * @param index
   * @param fieldName
   * @returns {Function}
   */
  formLineOnUpdate = (index, fieldName) => (event) => {
    if (fieldName === "assignee_id") {
      this.handleFiled("assignee", index, event, () => {
        this.handleFiled("assignee_id", index, event.id);
      });
    } else {
      this.handleFiled(fieldName, index, event.target.value);
    }
  };

  /**
   *
   * @returns {XML}
   */
  render() {
    const shot = this.props.shot;
    const tasks = this.props.tasks;
    const shotNumber = shot.model ? shot.model.shot_number : "";
    const shotDescription = shot.model ? shot.model.description : "";

    const isLoading = tasks.create || tasks.edit || shot.fetch || tasks.assign;
    const loadingIndicator = isLoading ? <LoadingIndicator /> : null;

    const modalTitle = !this.isEditMode
      ? `New task for shot "${shotNumber}"`
      : `Edit a task for shot "${shotNumber}"`;

    return (
      <ModalTemplate
        title={modalTitle}
        onClose={this.props.onClose}
        confirmButtonLabel={"Submit"}
        onConfirm={this.onConfirmPress}
        cancelButtonLabel="Cancel"
        onCancel={this.props.onClose}
        loadingIndicator={loadingIndicator}
        disableConfirmButton={isLoading}
        disableCancelButton={isLoading}
        className="modal-lg"
        modalContentClassName="bg-dark"
      >
        <h6 className="mt-n3 mb-3">{shotDescription}</h6>
        <EditableListTemplate
          setEditing={this.setEditingByIds}
          isEditMode={this.isEditMode}
          onAddItem={this.addTask}
          onRemoveBtnPress={this.onRemoveBtnPress}
          items={this.state.tasks}
          formLine={TasksEditLine}
          formLineOnUpdate={this.formLineOnUpdate}
          isLoading={isLoading}
          formValidationErrors={this.state.formValidationErrors}
          apiError={
            this.props.tasks.createError ||
            this.props.tasks.editError ||
            this.props.tasks.assignError
          }
          addItemLabel={"Add another task"}
        />
      </ModalTemplate>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  tasks: state.tasks,
  shot: state.shot,
});

const mapDispatchToProps = (dispatch) => ({
  createTask: async (shotId, data) =>
    await dispatch(tasksActions.createTask(shotId, data)),
  editTask: async (taskId, data) =>
    await dispatch(tasksActions.editTask(taskId, data)),
  changeAssignee: async (tasksIds, assigneeId) =>
    await dispatch(tasksActions.changeAssignee(tasksIds, assigneeId)),
  fetchShot: (shotId) => dispatch(shotActions.fetchShot(shotId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TaskEditModal));
