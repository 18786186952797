import React, { memo, useMemo } from "react";

import { ucFirst } from "../helpers/error";
import PropTypes from "prop-types";

const FormValidationError = (props) => {
  const errorObj = props.errors || {};

  const errors = useMemo(() => {

    if (!Object.keys(errorObj).length) {
      return null
    }

    return (
      <div className="validation-invalid-label">
        <strong>Form validation errors</strong>
        <div>
          {Object.keys(errorObj).map((key, index) => (
            <React.Fragment key={index}>
              {`${ucFirst(key)} - ${errorObj[key][0]}`}
              <br />
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  }, [errorObj]);

  return errors;
};

FormValidationError.defaultProps = {
  errors: {},
};

FormValidationError.propTypes = {
  errors: PropTypes.array,
};

export default memo(FormValidationError);
