import React, { memo } from "react";
import PropTypes from "prop-types";

import classNames from "classnames";

const StaticAlert = (props) => {
  const { header, body, type } = props;

  const className = classNames(
    "alert",
    `alert-${type}`,
    "bg-white",
    "alert-styled-left",
    "alert-arrow-left",
    props.className
  );
  return (
    <div className={className}>
      <h6 className="alert-heading font-weight-semibold mb-1">{header}</h6>
      {body}
    </div>
  );
};

StaticAlert.defaultProps = {
  type: "info",
};

StaticAlert.propTypes = {
  className: PropTypes.string,
  header: PropTypes.string.isRequired,
  body: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  type: PropTypes.oneOf(["info", "danger", "success", "warning", "primary"]),
};

export default memo(StaticAlert);
