import React, { useCallback, useMemo } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

const DropdownMenu = (props) => {

  const handleItem = useCallback(
    (item) => (event) => {
      event.preventDefault();
      item.action(item.data);
    },
    []
  );

  const dropdownMenuClassName = classNames(
    "dropdown-menu dropdown-menu-right",
    props.dropdownMenuClassName
  );

  const buttons = useMemo(
    () =>
      props.buttons.map((item, index) => {
        const dropdownItemClassName = classNames("dropdown-item", {
          disabled: item.disabled,
        });
        return (
          <a
            href="!#"
            className={dropdownItemClassName}
            key={index}
            onClick={handleItem(item)}
          >
            <i className={item.icon} />
            {item.label}
          </a>
        );
      }),
    [props.buttons]
  );

  return (
    <div className="dropdown">
      {props.openButton ? (
        props.openButton
      ) : (
        <a href="!#" className="list-icons-item" data-toggle="dropdown">
          <i className="icon-menu9" />
        </a>
      )}

      <div className={dropdownMenuClassName}>{buttons}</div>
    </div>
  );
};

DropdownMenu.propTypes = {
  dropdownMenuClassName: PropTypes.string,
  openButton: PropTypes.object,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      label: PropTypes.string,
      disabled: PropTypes.bool,
      action: PropTypes.func,
      data: PropTypes.object,
    })
  ),
};

export default DropdownMenu;
