/**
 * Created by piotr.pozniak@thebeaverhead.com on 24/11/2021.
 */

import AxiosConfig from "../AxiosConfig";
import axios from "axios";

/**
 *
 * @param taskId
 * @param note
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export const createTaskNote = (taskId, note) => {
  return (dispatch) => {
    //dispatch({ type: "TASK_NOTE_CREATE" });

    const url = AxiosConfig.getEndpointAddress() + "/api/tasks_notes.json";
    const data = { task_id: taskId, note };

    return axios
      .post(url, data, AxiosConfig.getAuthConfig())
      /*.then((response) => {
        dispatch({
          type: "TASK_NOTE_CREATE_FULFILLED",
          data: {
            responseData: response.data,
            taskId,
          },
        });
      })
      .catch(function (error) {
        dispatch({
          type: "TASK_NOTE_CREATE_REJECTED",
          data: error,
        });
      });*/
  };
};

/**
 *
 * @param taskNoteId
 * @param note
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export const editTaskNote = (taskNoteId, note) => {
  return (dispatch) => {
    dispatch({ type: "TASK_NOTE_EDIT" });

    const url =
      AxiosConfig.getEndpointAddress() +
      "/api/tasks_notes/" +
      taskNoteId +
      ".json";
    const data = { note };

    return axios
      .put(url, data, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "TASK_NOTE_EDIT_FULFILLED",
          data: {
            responseData: response.data,
            taskNoteId,
          },
        });
      })
      .catch(function (error) {
        dispatch({
          type: "TASK_NOTE_EDIT_REJECTED",
          data: error,
        });
      });
  };
};

/**
 *
 * @param taskNoteId
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export const deleteTaskNote = (taskNoteId) => {
  return (dispatch) => {
    dispatch({ type: "TASK_NOTE_DELETE" });

    const url =
      AxiosConfig.getEndpointAddress() +
      "/api/tasks_notes/" +
      taskNoteId +
      ".json";

    return axios
      .delete(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "TASK_NOTE_DELETE_FULFILLED",
          data: {
            responseData: response.data,
            taskNoteId,
          },
        });
      })
      .catch(function (error) {
        dispatch({
          type: "TASK_NOTE_DELETE_REJECTED",
          data: error,
        });
      });
  };
};

/**
 *
 * @param taskId
 * @returns {function(*): Promise<AxiosResponse<any>>}
 */
export const fetchTaskNotes = (taskId) => {
  return (dispatch) => {
    //dispatch({ type: "TASK_NOTES_FETCH", taskId });

    const url =
      AxiosConfig.getEndpointAddress() +
      "/api/tasks_notes.json?task_id=" +
      taskId;

    return axios
      .get(url, AxiosConfig.getAuthConfig())
      /*.then((response) => {

        dispatch({
          type: "TASK_NOTES_FETCH_FULFILLED",
          data: response.data,
          taskId,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "TASK_NOTES_FETCH_REJECTED",
          data: error,
          taskId,
        });
      });*/
  };
};
