import React from "react";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";

import StatusEditable from "../../../components/StatusEditable";

const BidInvoiceStatusFormat = (props) => {
  const {
    invoice,
    onChange,
    editInvoiceId,
    allowStatusEdit,
    user,
    statuses,
    projectInvoices,
  } = props;

  if (!invoice) {
    return <div className="pl-2">--</div>;
  }

  const statusesInvoices = statuses.collection.filter(
    (i) => i.type === "Invoices"
  );

  const role = user && user.model ? user.model.role : "";

  return invoice.type === "deposit_invoice" ? (
    <StatusEditable
      status={invoice.status}
      statuses={statusesInvoices}
      disabledStatuses={["work_completed"]}
      onChange={onChange({ invoice, type: "deposit_invoice" })}
      loading={
        projectInvoices.editDepositInvoice && editInvoiceId === invoice.id
      }
      disabled={
        !allowStatusEdit ||
        !["admin", "producer", "accountant"].includes(role) ||
        (projectInvoices.editDepositInvoice && editInvoiceId !== invoice.id)
      }
    />
  ) : (
    <StatusEditable
      status={invoice.status}
      statuses={statusesInvoices}
      disabledStatuses={["work_completed"]}
      onChange={onChange({ invoice, type: "invoice" })}
      loading={projectInvoices.edit && editInvoiceId === invoice.id}
      disabled={
        !allowStatusEdit ||
        !["admin", "producer", "accountant"].includes(role) ||
        (projectInvoices.edit && editInvoiceId !== invoice.id)
      }
    />
  );
};

BidInvoiceStatusFormat.propTypes = {
  invoice: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  editInvoiceId: PropTypes.string,
  allowStatusEdit: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  user: state.user,
  statuses: state.statuses,
  //projectInvoices: state.projectInvoices,
});

export default connect(mapStateToProps, null)(BidInvoiceStatusFormat);
