/**
 * Created by piotr.pozniak@thebeaverhead.com on 13/12/2021.
 */
import { useDispatch, useSelector } from "react-redux";
import * as bidInvoicesActions from "../actions/bidInvoices";
import {useCallback} from "react";

export function useBidsInvoiceStore() {
  const dispatch = useDispatch();
  const _bidsInvoices = useSelector((store) => store.bidsInvoices);


  const fetchBidInvoices = useCallback(
    async (bidId) =>
      await dispatch(bidInvoicesActions.fetchBidInvoices(bidId)),
    [dispatch]
  );

  const createInvoice = useCallback(
    async (bidId, data, shots) =>
      await dispatch(bidInvoicesActions.createInvoice(bidId, data, shots)),
    [dispatch]
  );

  const editInvoice = useCallback(
    async (invoiceId, data, shots) =>
      await dispatch(bidInvoicesActions.editInvoice(invoiceId, data, shots)),
    [dispatch]
  );

  const deleteInvoice = useCallback(
    async (invoiceId) =>
      await dispatch(bidInvoicesActions.deleteInvoice(invoiceId)),
    [dispatch]
  );


  const invoicePDF = useCallback(
    async (uuid, fileName) =>
      await dispatch(bidInvoicesActions.invoicePDF(uuid, fileName)),
    [dispatch]
  );


  const editDepositInvoiceStatus = useCallback(
    async (bidId, status) =>
      await dispatch(bidInvoicesActions.editDepositInvoiceStatus(bidId, status)),
    [dispatch]
  );


  const depositInvoicePDF = useCallback(
    async (uuid, fileName, deposit) =>
      await dispatch(bidInvoicesActions.depositInvoicePDF(uuid, fileName, deposit)),
    [dispatch]
  );

  return {
    bidsInvoices: _bidsInvoices,
    fetchBidInvoices,
    createInvoice,
    editInvoice,
    deleteInvoice,
    invoicePDF,
    editDepositInvoiceStatus,
    depositInvoicePDF

  };
}
