/**
 * Created by piotr.pozniak@thebeaverhead.com on 09/07/2024.
 */
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as clientActions from "../actions/client";
import {
  createClient,
  editClient,
  getClientId,
  setEditClient,
} from "../actions/client";

export function useClientStore() {
  const dispatch = useDispatch();
  const _client = useSelector((store) => store.client);

  const createClient = useCallback(
    async (data) => await dispatch(clientActions.createClient(data)),
    [dispatch]
  );

  const editClient = useCallback(
    async (id, data) => await dispatch(clientActions.editClient(id, data)),
    [dispatch]
  );

  const fetchClient = useCallback(
    async (id) => await dispatch(clientActions.fetchClient(id)),
    [dispatch]
  );

  const getClientId = useCallback(
    async () => await dispatch(clientActions.getClientId()),
    [dispatch]
  );

  const setEditClient = useCallback(
    async (data) => await dispatch(clientActions.setEditClient(data)),
    [dispatch]
  );

  return {
    client: _client,
    fetchClient,
    createClient,
    editClient,
    getClientId,
    setEditClient,
  };
}
