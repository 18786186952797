const initialState = {
  sidebarCollapsed: false,
  projectsPageFilterCollapsed: false,
  bidsPageFilterCollapsed: false,
  invoicesPageFilterCollapsed: false,
  projectPageSidebarCollapsed: false,
  shotPageSidebarCollapsed: false,
};

export default function reducer(
  state = {
    ...initialState
  },
  action
) {
  switch (action.type) {
    case "COLLAPSE_SIDE_NAV":
      return {
        ...state,
        sidebarCollapsed: action.data,
      };

    case "COLLAPSE_FILTER":
      return {
        ...state,

        projectsPageFilterCollapsed:
          action.data.type === "ProjectsPage"
            ? action.data.collapse
            : state.projectsPageFilterCollapsed,

        bidsPageFilterCollapsed:
          action.data.type === "BidsPage"
            ? action.data.collapse
            : state.bidsPageFilterCollapsed,

        invoicesPageFilterCollapsed:
          action.data.type === "InvoicesPage"
            ? action.data.collapse
            : state.invoicesPageFilterCollapsed,
      };

    case "COLLAPSE_SIDEBAR":
      return {
        ...state,

        projectPageSidebarCollapsed:
          action.data.type === "ProjectPage"
            ? action.data.collapse
            : state.projectPageSidebarCollapsed,

        shotPageSidebarCollapsed:
          action.data.type === "ShotPage"
            ? action.data.collapse
            : state.shotPageSidebarCollapsed,
      };

    case "LOGOUT":
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
