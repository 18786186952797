import React, { useCallback } from "react";
import PropTypes from "prop-types";
import AxiosConfig from "../../AxiosConfig";
import axios from "axios";
import GenericAsyncSelector from "./GenericAsyncSelector";

const page = 1;
const limit = 1000;

/**
 *
 * @param item
 * @returns {{data: ({email}|*), label: *, value}}
 */
const formatItem = (item) => {
  const email = item.email ? ` (${item.email})` : "";
  return {
    label: item.name + email,
    value: item.id,
    data: item,
  };
};

const UserSelect = (props) => {
  /**
   *
   * @param search
   * @param role
   * @returns {Promise<AxiosResponse<any> | never>}
   */
  const fetchItems = useCallback(
    (search) => {
      const url =
        AxiosConfig.getEndpointAddress() +
        "/api/users.json?" +
        AxiosConfig.objectToURLQuery({
          page,
          limit,
          search,
          role: props.userRole || null,
          active: 1,
        });

      return axios
        .get(url, AxiosConfig.getAuthConfig())
        .then((response) => {
          return response.data.users;
        })
        .catch(function (error) {
          return null;
        });
    },
    [props.userRole]
  );

  const defaultValue = props.user ? formatItem(props.user) : null;

  const value = props.value ? formatItem(props.value) : undefined;

  return (
    <GenericAsyncSelector
      {...props}
      fetchItems={fetchItems}
      formatItem={formatItem}
      value={value}
      defaultValue={defaultValue}
      isDisabled={props.disabled}
    />
  );
};

UserSelect.defaultProps = {
  reactSelectProps: {},
};

UserSelect.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  userRole: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  user: PropTypes.object,
  value: PropTypes.object,
  disabled: PropTypes.bool,
  addEmptyItem: PropTypes.bool,
  emptyItemLabel: PropTypes.string,
  emptyItemValue: PropTypes.string,
  reactSelectProps: PropTypes.object,
};

export default UserSelect;
