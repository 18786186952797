import VariablesString from "./VariablesString";
import moment from "moment/moment";
import { getBidVersionName } from "./tools";

/**
 * Generate PDF filename.
 * @param bidModel
 * @param bidVersionModel
 * @returns {*|string}
 */
export function generatePDFFilename(bidModel, bidVersionModel) {
  const project = bidModel.project;
  const fileNameCreator = new VariablesString({
    string: project.bid_pdf_filename,
    variablesWithDataToReplace: [
      {
        name: "time",
        value: moment().format("MM-DD-YY HH-mm"),
      },
      { name: "code", value: project.code },
      { name: "project_name", value: project.name.replace(" ", "") },
      { name: "bid_name", value: bidModel.name.replace(" ", "") },
      {
        name: "version",
        value: getBidVersionName(bidVersionModel),
      },
    ],
  });
  let fileNameByVariables = fileNameCreator.createResultString();
  fileNameByVariables =
    typeof fileNameByVariables === "string" ? fileNameByVariables : "";
  const defaultFileName = `Bid - ${project.code} - ${bidVersionModel.create_order}`;
  return fileNameByVariables || defaultFileName;
}
