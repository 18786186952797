import React, { useMemo } from "react";
import PropTypes from "prop-types";

const HasRights = (props) => {
  const content = useMemo(() => {
    if (
      (Array.isArray(props.allowedRoles) &&
        props.user &&
        props.user.role &&
        props.allowedRoles.includes(props.user.role)) ||
      props.isForcedAllow
    ) {
      return props.children || null;
    }
    return props.placeholder || null;
  }, [props.user, props.children]);

  return content;
};

HasRights.propTypes = {
  allowedRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  user: PropTypes.shape({ role: PropTypes.string }),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  placeholder: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  isForcedAllow: PropTypes.bool,
};

export default HasRights;
