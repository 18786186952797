export const initialState = {
  fetch: false,
  fetchSuccess: false,
  fetchError: null,

  create: false,
  createSuccess: false,
  createError: null,

  edit: false,
  editSuccess: false,
  editError: null,

  delete: false,
  deleteSuccess: false,
  deleteError: null,

  collection: []
};

/**
 *
 * @param state
 * @param action
 * @returns {{}}
 */
export default function reducer(
  state = {
    ...initialState
  },
  action
) {
  switch (action.type) {
    case "STATUSES_FETCH":
      return {
        ...state,
        fetch: true,
        fetchSuccess: false,
        fetchError: null
      };

    case "STATUSES_FETCH_FULFILLED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: true,
        fetchError: null,

        collection: action.data.statuses
      };

    case "STATUSES_FETCH_REJECTED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: false,
        fetchError: action.data,

        collection: []
      };

    case "STATUSES_CREATE":
      return {
        ...state,
        create: true,
        createSuccess: false,
        createError: null
      };

    case "STATUSES_CREATE_FULFILLED":
      return {
        ...state,
        create: false,
        createSuccess: true,
        createError: null,

        collection: [...state.collection, action.data]
      };

    case "STATUSES_CREATE_REJECTED":
      return {
        ...state,
        create: false,
        createSuccess: false,
        createError: action.data
      };

    case "STATUSES_EDIT":
      return {
        ...state,
        edit: true,
        editSuccess: false,
        editError: null
      };

    case "STATUSES_EDIT_FULFILLED":
      const newCollection = [...state.collection];
      const updatedIndex = state.collection.findIndex(
        i => i.id === action.data.id
      );
      if (updatedIndex > -1) {
        newCollection.splice(updatedIndex, 1, action.data);
      }
      return {
        ...state,
        edit: false,
        editSuccess: true,
        editError: null,

        collection: newCollection
      };

    case "STATUSES_EDIT_REJECTED":
      return {
        ...state,
        edit: false,
        editSuccess: false,
        editError: action.data
      };

    case "STATUSES_DELETE":
      return {
        ...state,
        delete: action.id,
        deleteSuccess: false,
        deleteError: null
      };

    case "STATUSES_DELETE_FULFILLED":
      return {
        ...state,
        delete: false,
        deleteSuccess: true,
        deleteError: null,

        collection: state.collection.filter(s => s.id !== action.id)
      };

    case "STATUSES_DELETE_REJECTED":
      return {
        ...state,
        delete: false,
        deleteSuccess: false,
        deleteError: action.data
      };

    case "CLEAR_ERRORS":
      return {
        ...initialState,
        collection: state.collection
      };

    case "LOGOUT":
      return {
        ...initialState
      };

    default:
      return state;
  }
}
