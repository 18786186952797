/**
 * Created by piotr.pozniak@thebeaverhead.com on 24/11/2021.
 */

import React, { useEffect, useMemo, useState } from "react";
import { withRouter } from "react-router-dom";
import Validator from "validatorjs";

import ModalTemplate from "./ModalTemplate";

import connect from "react-redux/es/connect/connect";
import LoadingIndicator from "../components/LoadingIndicator";
import ApiError from "../components/ApiError";
import FormValidationError from "../components/FormValidationError";
import { useAdditionalCostsStore } from "../storeHooks/additionalCosts";
import {store} from "../configureStore";

const isRateBasedDescription =
  "When additional cost is rate based, it will render as a fixed-fee field.";
const isAvailableDescription =
  "When additional cost is available, it will be displayed on all historical and newly created bid version.";

const AdditionalCostsEditModal = (props) => {
  const [state, setState] = useState({
    formValidationErrors: null,
  });

  const {
    additionalCosts,
    fetchAdditionalCosts,
    createAdditionalCost,
    editAdditionalCost,
  } = useAdditionalCostsStore();
  const isEditMode = props.match.params.additionalCostId !== "new";

  const additionalCostModel = useMemo(() => {
    if (!isEditMode) {
      return {};
    }

    if (additionalCosts.collection.length) {
      return additionalCosts.collection.find(
        (i) => i.id === props.match.params.additionalCostId
      );
    }
    return null;
  }, [additionalCosts.collection]);

  useEffect(() => {
    if (isEditMode) {
      if (additionalCosts.collection.length) {
        handleUpdateForm();
      } else {
        fetchAdditionalCosts();
      }
    }
  }, []);

  useEffect(() => {
    if (additionalCostModel) {
      handleUpdateForm();
    }
  }, [additionalCostModel]);

  /**
   *
   */
  const handleUpdateForm = () => {
    if (additionalCostModel) {
      document.querySelector(".js-ac-edit-label").value =
        additionalCostModel.label || "";
      document.querySelector(".js-ac-edit-default-rate").value =
        additionalCostModel.default_value;

      document.querySelector(".js-ac-edit-is-rate-based").checked =
        !!additionalCostModel.is_rate_based;

      document.querySelector(".js-ac-edit-available").checked =
        !!additionalCostModel.available;
    }
  };

  /**
   *
   */
  const handleSubmit = async (event) => {
    event.preventDefault();

    setState({ formValidationErrors: null });

    const data = {
      label: document.querySelector(".js-ac-edit-label").value,
      default_value: document.querySelector(".js-ac-edit-default-rate").value,
      is_rate_based: document.querySelector(".js-ac-edit-is-rate-based").checked
        ? 1
        : 0,
      available: document.querySelector(".js-ac-edit-available").checked
        ? 1
        : 0,
    };

    const rules = {
      label: "required|min:2",
    };
    const validation = new Validator(data, rules);

    setState({ formValidationErrors: validation.errors.all() });

    if (validation.passes()) {
      if (isEditMode) {
        const {
          match: {
            params: { additionalCostId },
          },
        } = props;
        await editAdditionalCost(additionalCostId, data);
      } else {
        await createAdditionalCost(data);
      }

      const _store = store.getState();
      if (
        (!isEditMode && _store.additionalCosts.createSuccess) ||
        (isEditMode && _store.additionalCosts.editSuccess)
      ) {
        props.onClose();
      }
    }
  };

  const { formValidationErrors } = state;

  const isLoading = additionalCosts.create || additionalCosts.edit;
  const loadingIndicator = isLoading ? <LoadingIndicator /> : null;

  const modalTitle = isEditMode
    ? `Edit "${additionalCostModel ? additionalCostModel.label : ""}"`
    : "New additional cost";
  const confirmButtonLabel = isEditMode ? "Update" : "Create";

  return (
    <ModalTemplate
      title={modalTitle}
      onClose={props.onClose}
      confirmButtonLabel={confirmButtonLabel}
      onConfirm={handleSubmit}
      cancelButtonLabel="Cancel"
      onCancel={props.onClose}
      loadingIndicator={loadingIndicator}
      disableConfirmButton={isLoading}
    >
      <form noValidate>
        <div className="form-group">
          <label className="form-label">
            Label <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control js-ac-edit-label"
            placeholder="Additional cost label"
            disabled={isLoading}
          />
        </div>

        <div className="form-group">
          <label className="form-label">
            Default rate <span className="text-danger">*</span>
          </label>
          <input
            type="number"
            className="form-control js-ac-edit-default-rate col-sm-4"
            placeholder="Additional cost's default rate"
            disabled={isLoading}
          />
        </div>

        <div className="form-group">
          <div className="form-check">
            <label className="form-check-label">
              <input
                type="checkbox"
                className="form-check-input-styled js-ac-edit-is-rate-based"
                data-fouc
              />
              Is rate based
            </label>
            <i
              className="icon-question4 ml-2 fs-14"
              data-popup="tooltip"
              data-original-title={isRateBasedDescription}
            />
          </div>
        </div>

        <div className="form-group">
          <div className="form-check">
            <label className="form-check-label">
              <input
                type="checkbox"
                className="form-check-input-styled js-ac-edit-available"
                data-fouc
              />
              Is enabled
            </label>
            <i
              className="icon-question4 ml-2 fs-14"
              data-popup="tooltip"
              data-original-title={isAvailableDescription}
            />
          </div>
        </div>

        <FormValidationError errors={formValidationErrors} />
        <ApiError
          error={additionalCosts.createError || additionalCosts.editError}
        />
      </form>
    </ModalTemplate>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AdditionalCostsEditModal));
