import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ShotsTableWithInputsNavigation from "./ShotsTableWithInputsNavigation";
import TotalShotsCount from "./TotalShotsCount";
import BidNotes from "./BidNotes";
import StaticAlert from "../../../components/StaticAlert";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { formatter } from "../../../helpers/tools";
import { setShotVersionsAsApprovedIfOneIsApproved } from "../../../helpers/shots";
import ApiError from "../../../components/ApiError";
import HasRights from "../../../components/HasRights";
import HoveredEdit from "../../../components/HoveredEdit";
import {
  useLocation,
  useHistory,
  useRouteMatch,
  matchPath,
} from "react-router-dom";
import SumRowsEdit from "./SumRowsEdit";
import { store } from "../../../configureStore";
import AdditionalCostsRow, { sumAdditionalCosts } from "./AdditionalCostsRow";
import BidSummary from "./BidSummary";
import BidVersionControls from "./BidVersionControls";
import BidPreviewPDF from "./BidPreviewPDF";
import { useBidStore } from "../../../storeHooks/bid";
import { useBidVersionStore } from "../../../storeHooks/bidVersion";
import { useUserStore } from "../../../storeHooks/user";
import { useSupplierStore } from "../../../storeHooks/supplier";

const staticAlertHeader = "No records to display";
const staticAlertBody = <>This table lists shots.</>;

/**
 *
 */
const generateCostDiscountRushObjUpdatedOnly = (discountsRushEdited) => {
  let sentObj = {};

  if (discountsRushEdited) {
    sentObj.discount = discountsRushEdited.discountsRush.discount || 0;
    sentObj.rush = discountsRushEdited.discountsRush.rush || 0;
    sentObj.is_discount_percentage =
      discountsRushEdited.discountsRush.isDiscountPercentage * 1;
  }

  return sentObj;
};

/**
 *
 * @param bidVersion
 * @param isDiscountsRushEdited
 * @returns {*|{}}
 */
const generateCostDiscountRushObjForNewVersion = (
  bidVersion,
  isDiscountsRushEdited,
  additionalCosts
) => {
  const sentObj = isDiscountsRushEdited
    ? generateCostDiscountRushObjUpdatedOnly(isDiscountsRushEdited)
    : {};

  if (!isDiscountsRushEdited) {
    sentObj.discount = bidVersion.discount;
    sentObj.rush = bidVersion.rush;
    sentObj.is_discount_percentage = bidVersion.is_discount_percentage * 1;
  }

  sentObj.bid_versions_additional_costs = additionalCosts.map((i) => ({
    rate: i.rate,
    days: i.days,
    additional_costs_setting_id: i.additional_costs_setting_id,
  }));
  return sentObj;
};

/**
 *
 * @returns {{}}
 */
const generateCostDiscountRushObjFromCurrentVersion = (bidVersion) => {
  const sentObj = {};

  sentObj.discount = bidVersion.discount;
  sentObj.rush = bidVersion.rush;
  sentObj.is_discount_percentage = bidVersion.is_discount_percentage * 1;

  sentObj.bid_versions_additional_costs =
    bidVersion.bid_versions_additional_costs.map((i) => ({
      rate: i.rate,
      days: i.days,
      additional_costs_setting_id: i.additional_costs_setting.id,
    }));

  return sentObj;
};

const BidPageContent = (props) => {
  const [state, setState] = useState({
    filteredShotsCollection: null,
    filter: [],
    footerNotes: "",
    bidNotesLastValue: "",
    isAdditionalCostEdited: null,
    isDiscountsRushEdited: null,
    isDiscountsRushOpen: false,
    additionalCosts: [],
  });

  const { bid, setEditBid, fetchBid, editBid } = useBidStore();
  const {
    bidVersion,
    fetchBidVersion,
    fetchShotsByBidVersion,
    fetchBidFullInfoByBidSlug,
    bidVersionUpdate,
  } = useBidVersionStore();
  const { user } = useUserStore();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  const { supplier, fetchSupplierSettings: _fetchSupplierSettings } =
    useSupplierStore();
  const [previousLocationPathname, setPreviousLocationPathname] = useState(
    location.pathname
  );
  const [isRecentBidVersion, setIsRecentBidVersion] = useState(
    props.isHistorical
  );
  const [
    additionalAttemptToLoadBidIsUsed,
    setAdditionalAttemptToLoadBidIsUsed,
  ] = useState(false);
  const requiredDepositInput = useRef();

  useEffect(() => {
    getBid();
    fetchSupplierSettings();

    return () => {
      setEditBid({});
    };
  }, []);

  useEffect(() => {
    if (previousLocationPathname !== location.pathname) {
      setPreviousLocationPathname(location.pathname);
      getBid();
    }
  }, [location.pathname]);

  useEffect(() => {
    // check if state flag is different than props flag
    const _fetchBid = async () => {
      await fetchBid(bid.model.id);
    };

    // TODO
    if (
      !props.isHistorical &&
      isRecentBidVersion &&
      !props.isRecentBidVersion
    ) {
      setIsRecentBidVersion(props.isRecentBidVersion);
      history.replace(
        `/bid/${store.getState().bid.model.recent_bid_version.id}`
      );
    }

    /*if (
      !props.isHistorical &&
      //prevProps.bidVersion.fetch &&
      bidVersion.fetchError &&
      // todo
      !additionalAttemptToLoadBidIsUsed
    ) {
      setAdditionalAttemptToLoadBidIsUsed(true);
      console.log("additionalAttemptToLoadBidIsUsed");
      _fetchBid();
      history.replace(
        `/bid/${store.getState().bid.model.recent_bid_version.id}`
      );
    }*/
  }, [props.isHistorical, props.isRecentBidVersion]);

  useEffect(() => {
    window.updateJQuery();
  }, [state.isDiscountsRushOpen]);

  useEffect(() => {
    if (state.footerNotes) {
      const saveFooterNotes = async () => {
        await saveUnnamedVersionConfigurable({
          saveWithCostDiscountRush: false,
          redirectToCurrentBid: true,
        });
      };

      saveFooterNotes();
    }
  }, [state.footerNotes]);

  const fetchSupplierSettings = () => {
    if (user.token && user.model && !supplier.model) {
      _fetchSupplierSettings();
    }
  };

  /**
   *
   */
  const getBid = async () => {
    if (user.token && user.model && match.params.id) {
      await fetchBidVersion(match.params.id);
      const bidVersion = store.getState().bidVersion;

      if (!bidVersion.model) {
        history.push(`/bids/1`);
        return;
      }

      setState({
        ...state,
        additionalCosts: bidVersion.model.bid_versions_additional_costs.map(
          (i) => ({
            ...i,
            additional_costs_setting_id: i.additional_costs_setting.id,
          })
        ),
        footerNotes: bidVersion.model.footer_notes,
      });

      if (bidVersion && bidVersion.model) {
        await fetchBid(bidVersion.model.bid_id);
      }
      fetchShotsByBidVersion(match.params.id);
    } else if (match.params.slug) {
      await fetchBidFullInfoByBidSlug(match.params.slug);
    }
  };

  // /**
  //  *
  //  * @param shot
  //  * @returns {Function}
  //  */
  // onPressDuplicate = (shot) => () => {
  //   this.setState({shotToDuplicate: shot});
  // };

  /**
   *
   */
  const onPressCancel = useCallback(() => {
    history.push(`/bids/1`);
  }, [history]);

  /**
   *
   */
  const onPressSaveAsNewVersion = useCallback(() => {
    history.push(
      "/bid/" + bidVersion.model.id + "/create-bid-version",
      generateCostDiscountRushObjForNewVersion(
        bidVersion.model,
        state.isDiscountsRushEdited,
        state.additionalCosts
      )
    );
  }, [history, bidVersion.model, state]);

  /**
   *
   * @returns {Promise<void>}
   */
  const onPressSaveChanges = async () => {
    if (state.isAdditionalCostEdited || state.isDiscountsRushEdited) {
      await saveUnnamedVersionConfigurable({
        saveWithCostDiscountRush: true,
        redirectToCurrentBid: true,
      });

      if (bidVersion.updateSuccess) {
        setState({
          ...state,
          isAdditionalCostEdited: null,
          isDiscountsRushEdited: null,
        });
      }
    }
  };

  /**
   *
   * @returns {Promise<void>}
   */
  const onBeforeShotsTableApplyChanges = async () => {
    await saveUnnamedVersionConfigurable({
      saveWithCostDiscountRush: false,
      redirectToCurrentBid: true,
    });
  };

  /**
   *
   * @param saveWithCostDiscountRush
   * @returns {Promise<void>}
   */
  const saveUnnamedVersion = async (saveWithCostDiscountRush = false) => {
    if (bid.model && bid.model.id) {
      const sentObj = saveWithCostDiscountRush
        ? generateCostDiscountRushObjForNewVersion(
            bidVersion.model,
            state.isDiscountsRushEdited,
            state.additionalCosts
          )
        : generateCostDiscountRushObjFromCurrentVersion(bidVersion.model);

      await bidVersionUpdate({
        ...sentObj,
        footer_notes: state.footerNotes,
        bid_id: bid.model.id,
      });
    }
  };

  /**
   *
   */
  const redirectToCurrentBidWithoutRerender = () => {
    window.localStorage.setItem("preventUrlReplace", "preventUrlReplace");
    history.push(`/bid/${store.getState().bidVersion.model.id}`);
    window.localStorage.removeItem("preventUrlReplace");
  };

  /**
   *
   * @param config
   * @returns {Promise<void>}
   */
  const saveUnnamedVersionConfigurable = async (config = {}) => {
    await saveUnnamedVersion(config.saveWithCostDiscountRush);
    if (config.redirectToCurrentBid) {
      redirectToCurrentBidWithoutRerender();
    }
  };

  /**
   *
   * @param event
   */
  const onPressShowDiscounts = (event) => {
    event.preventDefault();
    if (state.isDiscountsRushOpen) {
      setState({ ...state, isDiscountsRushEdited: null });
    }

    setState({ ...state, isDiscountsRushOpen: !state.isDiscountsRushOpen });
  };

  /**
   *
   * @returns {Promise<void>}
   */
  const onFooterNotesChange = async (value) => {
    setState({ ...state, footerNotes: value });
  };

  /**
   *
   * @param value
   * @returns {Promise<void>}
   */
  const onAccountingNotesChange = useCallback(
    async (value) => {
      await editBid(bid.model.id, {
        bid_notes: value,
      });
    },
    [bid.model]
  );

  /**
   *
   * @returns {Promise<void>}
   */
  const requiredDepositHandleConfirm = async () => {
    const val = requiredDepositInput.current.value;
    await editBid(bid.model.id, {
      deposit: val,
    });
  };

  /**
   *
   * @returns {Promise<void>}
   */
  const requiredDepositHandleFocus = () => {
    requiredDepositInput.current.focus();
  };

  /**
   *
   * @param summarySubTotal
   * @returns {function(*=): void}
   */
  const onDiscountsRushEditChange = (summarySubTotal) => (result) => {
    let discounts = {};
    const bidVersionModel = bidVersion.model;
    const isDiscountsRushEdited = state.isDiscountsRushEdited;
    if (result.discountsRush) {
      discounts = getDiscountsOnValuesChange(
        result,
        bidVersionModel,
        summarySubTotal,
        isDiscountsRushEdited
      );
    } else if (!result.discountsRush) {
      onPercentageUsageSwitch(
        result,
        bidVersionModel,
        summarySubTotal,
        isDiscountsRushEdited
      );
      discounts = getDiscountsOnPercentagesUsageChange(
        result,
        bidVersionModel,
        summarySubTotal,
        isDiscountsRushEdited
      );
    }
    setState({ ...state, isDiscountsRushEdited: discounts });
  };

  /**
   *
   * @param result
   * @param bidVersionModel
   * @param summarySubTotal
   * @param isDiscountsRushEdited
   * @returns {{discountsRush: {discount: (number|*), isDiscountPercentage: *, rush: (number|*)}}}
   */
  const getDiscountsOnValuesChange = (
    result,
    bidVersionModel,
    summarySubTotal,
    isDiscountsRushEdited
  ) => {
    const isDiscountPercentage = isDiscountsRushEdited
      ? isDiscountsRushEdited.discountsRush.isDiscountPercentage
      : bidVersionModel.is_discount_percentage;
    return {
      discountsRush: {
        discount: isDiscountPercentage
          ? (summarySubTotal / 100) * result.discountsRush.amount
          : result.discountsRush.amount,
        rush: isDiscountPercentage
          ? (summarySubTotal / 100) * result.discountsRush.rate
          : result.discountsRush.rate,
        isDiscountPercentage,
      },
    };
  };

  /**
   *
   * @param result
   * @param bidVersionModel
   * @param summarySubTotal
   * @param isDiscountsRushEdited
   * @returns {{discountsRush: {discount: *, isDiscountPercentage, rush: *}}}
   */
  const getDiscountsOnPercentagesUsageChange = (
    result,
    bidVersionModel,
    summarySubTotal,
    isDiscountsRushEdited
  ) => {
    const isDiscountPercentage = result.target.checked;
    const discount = isDiscountsRushEdited
      ? isDiscountsRushEdited.discountsRush.discount
      : bidVersionModel.discount;
    const rush = isDiscountsRushEdited
      ? isDiscountsRushEdited.discountsRush.rush
      : bidVersionModel.rush;
    return {
      discountsRush: {
        discount: discount,
        rush: rush,
        isDiscountPercentage: isDiscountPercentage,
      },
    };
  };

  /**
   *
   * @param result
   * @param bidVersionModel
   * @param summarySubTotal
   * @param isDiscountsRushEdited
   */
  const onPercentageUsageSwitch = (
    result,
    bidVersionModel,
    summarySubTotal,
    isDiscountsRushEdited
  ) => {
    const isDiscountPercentage = result.target.checked;
    const discount = isDiscountsRushEdited
      ? isDiscountsRushEdited.discountsRush.discount
      : bidVersionModel.discount;
    const rush = isDiscountsRushEdited
      ? isDiscountsRushEdited.discountsRush.rush
      : bidVersionModel.rush;
    const discountInput = document.querySelector(
      ".js-discount-rush .js-sum-rows-amount"
    );
    const rushInput = document.querySelector(
      ".js-discount-rush .js-sum-rows-rate"
    );
    discountInput.value = isDiscountPercentage
      ? +(discount / (summarySubTotal / 100)).toFixed(2)
      : discount;
    rushInput.value = isDiscountPercentage
      ? +(rush / (summarySubTotal / 100)).toFixed(2)
      : rush;
  };

  // /**
  //  *
  //  */
  // onAddClientNotesClick = () => {
  //   alert("onAddClientNotesClick");
  // };

  /**
   *
   * @param collection
   */
  const sortCollectionByOrderIndex = (collection) => {
    let sorted = [];

    Object.keys(collection)
      .sort(function (a, b) {
        return collection[b].order_index - collection[a].order_index;
      })
      .forEach(function (key) {
        sorted.push(collection[key]);
      });

    return sorted.reverse();
  };

  /**
   *
   * @param costs
   */
  const onAdditionalCostsChange = (costs) => {
    setState({
      ...state,
      additionalCosts: costs,
      isAdditionalCostEdited: true,
    });
  };

  const role = user && user.model ? user.model.role : "";

  const { isPreview } = props;

  const bidModel = bid.model;
  const bidVersionModel = bidVersion.model;
  const projectModel = bidModel.project || {};
  const useShotsAsHoursBid = bidModel.use_shots_as_hours_bid;

  // const pureShots = project.shots;
  const pureShots = bidVersion.shots.map((item) =>
    setShotVersionsAsApprovedIfOneIsApproved(item)
  );
  const filteredShots = state.filteredShotsCollection;
  const shotsResult = filteredShots || pureShots;

  const loading =
    bidVersion.fetch ||
    bid.fetch ||
    bid.fetchBidVersionsByBid ||
    bidVersion.fetchShots ||
    supplier.fetch;
  const bidVersionIsUpdating = bidVersion.update || bidVersion.approve;
  const bidIsUpdating = bid.edit;

  const dataTableWithInputsNavigation =
    shotsResult && !loading && projectModel.id ? (
      <ShotsTableWithInputsNavigation
        collection={shotsResult}
        onBeforeApplyChanges={onBeforeShotsTableApplyChanges}
        isPreview={isPreview}
        onSelectShot={props.onSelectShot}
      />
    ) : null;

  // const dataTable = shotsResult && !loading ? (
  //   <div className="mb-5">
  //     <CustomizableDataTable
  //       collection={shotsResult}
  //       columns={this.shotsDataTableConfig.columns}
  //       showFilters={true}
  //       filters={this.shotsDataTableConfig.filters}
  //       onFilter={this.onTableFilter}
  //       onClearAll={this.onTableClearFilter}
  //       showSearch={true}
  //       showSettings={true}
  //     />
  //   </div>
  // ) : null;

  const staticAlert =
    pureShots && !pureShots.length ? (
      <StaticAlert header={staticAlertHeader} body={staticAlertBody} />
    ) : null;

  // const anchorButtonClassName = classNames(
  //   "btn btn-link btn-float text-default",
  //   {disabled: loading}
  // );

  // const shotsDropdownButton = (
  //   <a href="!#" className={anchorButtonClassName} data-toggle="dropdown">
  //     <i className="mi-view-carousel text-primary"/><span>Shot's options</span>
  //   </a>
  // );

  const approveError = (
    <div className="mt-n2 mb-2 d-flex justify-content-end">
      <ApiError error={bidVersion.approveError} />
    </div>
  );

  const requiredDeposit = (
    <HasRights
      allowedRoles={["admin", "producer"]}
      user={user.model}
      placeholder={
        <div className="ml-1">
          <span>{bidModel.deposit}</span>
        </div>
      }
    >
      <HoveredEdit
        preview={
          <pre className="hovered-edit-pre">
            {bidModel.deposit
              ? formatter.format(bidModel.deposit)
              : "Deposit Amount"}
          </pre>
        }
        handleConfirm={requiredDepositHandleConfirm}
        handleFocus={requiredDepositHandleFocus}
        disabled={loading || bidIsUpdating || isPreview}
      >
        <input
          ref={requiredDepositInput}
          type="number"
          defaultValue={bidModel.deposit}
        />
      </HoveredEdit>
    </HasRights>
  );

  const totalShotsCount = (
    <TotalShotsCount
      shots={pureShots}
      useShotsAsHoursBid={useShotsAsHoursBid}
      isLoading={loading}
    />
  );

  const shotsCosts = (pureShots || [])
    // .filter((i) => i.current_version !== null)
    .map((i) => i.versions[i.versions.length - 1].cost_summary)
    .reduce((prevVal, currVal) => prevVal + Number(currVal), 0);

  const additionalCosts = sumAdditionalCosts(state.additionalCosts);

  const discounts = useMemo(() => {
    if (state.isDiscountsRushEdited) {
      return (
        -Number(state.isDiscountsRushEdited.discountsRush.discount) +
        Number(state.isDiscountsRushEdited.discountsRush.rush)
      );
    } else if (bidVersionModel) {
      return -Number(bidVersionModel.discount) + Number(bidVersionModel.rush);
    }

    return null;
  }, [state.isDiscountsRushEdited, bidVersionModel]);

  const isDiscountPercentage = state.isDiscountsRushEdited
    ? state.isDiscountsRushEdited.discountsRush.isDiscountPercentage
    : bidVersionModel
    ? bidVersionModel.is_discount_percentage * 1
    : false;

  const summarySubTotal = shotsCosts + additionalCosts;
  const discountsPercentages = discounts / (summarySubTotal / 100);
  const summaryTotal = shotsCosts + additionalCosts + discounts;

  const discountRushRows = bidVersionModel
    ? [
        {
          name: "discountsRush",
          rate: isDiscountPercentage
            ? +(bidVersionModel.rush / (summarySubTotal / 100)).toFixed(2)
            : bidVersionModel.rush,
          amount: isDiscountPercentage
            ? +(bidVersionModel.discount / (summarySubTotal / 100)).toFixed(2)
            : bidVersionModel.discount,
          total: 0,
          measure: "",
        },
      ]
    : [];

  const discountRushMeasure = isDiscountPercentage ? "%" : "$";

  const discountRushRowsEdit =
    bidVersionModel && state.isDiscountsRushOpen ? (
      <>
        <tr>
          <td colSpan={2} className="js-discount-rush">
            <SumRowsEdit
              rowsAr={discountRushRows}
              titles={[
                `Rush/OT (${discountRushMeasure})`,
                `Discount (${discountRushMeasure})`,
              ]}
              onChange={onDiscountsRushEditChange(summarySubTotal)}
              wrapperClassName="sum-rows-edit-discounts-rush"
              disabled={
                bidVersionIsUpdating ||
                !["admin", "producer"].includes(role) ||
                isPreview
              }
            />
          </td>
        </tr>
        {!isPreview ? (
          <tr>
            <td colSpan={2} className="text-left">
              <div className="form-group">
                <div className="form-check">
                  <label className="form-check-label">
                    <input
                      type="checkbox"
                      className="form-check-input-styled"
                      data-fouc
                      defaultChecked={isDiscountPercentage}
                      onChange={onDiscountsRushEditChange(summarySubTotal)}
                    />
                    Use percentages
                  </label>
                </div>
              </div>
            </td>
          </tr>
        ) : null}
      </>
    ) : null;

  const depositContent = loading ? (
    <div className="col-xl-8" />
  ) : (
    <div className="card">
      <div className="card-header bg-primary text-white header-elements-inline">
        <h6 className="card-title">Required Deposit</h6>
      </div>
      <div className="card-body">
        <div className="mb-3 ml-n1">{requiredDeposit}</div>
      </div>
    </div>
  );

  return (
    <div>
      {approveError}

      <BidSummary />

      {!dataTableWithInputsNavigation && <h5>Shots</h5>}
      {loading && <LoadingIndicator />}
      {dataTableWithInputsNavigation}
      {/*{dataTable}*/}
      {!loading && staticAlert}

      <div className={"row"}>
        <div className={"col-lg-6"}>
          <BidNotes
            isLoading={loading}
            isDisabled={loading || bidIsUpdating || isPreview}
            onAccountingNotesChange={onAccountingNotesChange}
            onFooterNotesChange={onFooterNotesChange}
          />
          {depositContent}
          <BidPreviewPDF isLoading={loading} />
        </div>

        <div className="col-xl-6">
          <div className={"row"}>
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header bg-primary text-white header-elements-inline">
                  <h6 className="card-title">Summary</h6>
                  <div className="header-elements">
                    Total shots: {totalShotsCount}
                  </div>
                </div>

                <div className="card-body">
                  <table className="result-table">
                    <tbody>
                      <tr>
                        <td>Shots Costs:</td>
                        <td>{formatter.format(shotsCosts)}</td>
                      </tr>
                      <AdditionalCostsRow
                        bidVersionModel={bidVersionModel}
                        loading={loading}
                        onChange={onAdditionalCostsChange}
                      />

                      <tr>
                        <td>Subtotal:</td>
                        <td>{formatter.format(summarySubTotal)}</td>
                      </tr>
                      <tr>
                        <td>
                          Discounts & Rush/OT:&nbsp;
                          <a
                            href="!#"
                            className={loading ? "btn-link disabled" : ""}
                            onClick={onPressShowDiscounts}
                          >
                            {state.isDiscountsRushOpen ? "hide" : "show"}
                          </a>
                        </td>
                        <td>
                          {discounts >= 0 && "+"}
                          {isDiscountPercentage
                            ? `${+discountsPercentages.toFixed(
                                2
                              )}${discountRushMeasure}`
                            : formatter.format(discounts)}
                        </td>
                      </tr>
                      {discountRushRowsEdit}
                      <tr>
                        <td>Total:</td>
                        <td>{formatter.format(summaryTotal)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              {bidVersionIsUpdating && <LoadingIndicator />}

              <ApiError error={bidVersion.updateError} />
            </div>
            <BidVersionControls
              isPreview={isPreview}
              userModel={user.model}
              isLoading={loading}
              isUpdating={bidVersionIsUpdating}
              isEdited={
                state.isAdditionalCostEdited || state.isDiscountsRushEdited
              }
              onCancel={onPressCancel}
              onSaveChanges={onPressSaveChanges}
              onSaveNewVersion={onPressSaveAsNewVersion}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(BidPageContent, (prevProps, nextProps) => {
  const matchBidHistoryModal = matchPath(window.location.pathname, {
    path: "/bid/:bidId/bid-versions",
    exact: true,
    strict: false,
  });
  const isPreventUrlReplace = window.localStorage.getItem("preventUrlReplace");

  if (matchBidHistoryModal || isPreventUrlReplace) {
    return false;
  }
  return true;
});
