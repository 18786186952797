import React, { useEffect } from "react";
import PageTemplate from "../PageTemplate";
import connect from "react-redux/es/connect/connect";
import * as appActions from "../../actions/app";
import { Link, withRouter } from "react-router-dom";
import BidInvoicesTable from "./components/BidInvoicesTable";
import LoadingIndicator from "../../components/LoadingIndicator";
import { history, store } from "../../configureStore";
import classNames from "classnames";
import { useBidStore } from "../../storeHooks/bid";
import { useBidInvoiceStore } from "../../storeHooks/bidInvoice";
import { useBidsStore } from "../../storeHooks/bids";
import { useUserStore } from "../../storeHooks/user";
import { useProjectStore } from "../../storeHooks/project";
import SeoBlock from "../../components/SeoBlock";

const BidInvoicesPage = (props) => {
  const { bids } = useBidsStore();
  const { bid, fetchBid } = useBidStore();
  const { project, fetchProject } = useProjectStore();
  const { bidInvoices } = useBidInvoiceStore();
  const { user } = useUserStore();

  useEffect(() => {
    if (user.token && user.model) {
      const fetchData = async () => {
        await fetchBid(props.match.params.bidId);

        const bidStore = store.getState().bid;
        fetchProject(bidStore.model.project_id);
      };

      fetchData();
      /*props.fetchBids(1, 20, {
        project_id: props.match.params.bidId,
      });

      props.fetchProject(props.match.params.bidId);*/
    }
  }, []);

  /**
   *
   * @returns {Array}
   */
  const getShotsUsedInAllInvoicesIds = () => {
    const usedShots = [];
    (bidInvoices.collection || []).forEach((item) =>
      usedShots.push(...item.shots.map((shot) => shot.id))
    );
    return usedShots;
  };

  /**
   * TODO
   */
  const onIssueDepositInvoicePDF = (event) => {
    event.preventDefault();
    history.push(`/invoice/${bid.model.id}/deposit-invoice/create`);
  };

  /**
   * TODO
   */
  const onIssueInvoicePress = (e) => {
    e.preventDefault();
    props.clearErrors();
    history.push(`/invoice/${bid.model.id}/edit/new`);
  };

  /**
   *
   */
  const hasNotIssuedDepositInvoices = () => {
    return bid.model.deposit && !bid.model.deposit_invoice_created;
  };

  /**
   *
   * @returns {boolean}
   */
  const getIsAllShotsUsedInInvoices = () => {
    if (!project.shots || !bid.model.recent_bid_version) {
      return true;
    }

    const pureShots = project.shots;
    const approvedShots = pureShots
      .filter(
        (item) =>
          item.current_version.bid_version_id ===
            bid.model.recent_bid_version.id &&
          Number.parseInt(item.has_approved_version) === 1
      )
      .map((i) => i.id);

    const invoiceShots = getShotsUsedInAllInvoicesIds();
    return (
      approvedShots.length === 0 ||
      approvedShots.every((i) => invoiceShots.includes(i))
    );
  };

  const projectModel = bid.model && bid.model.project ? bid.model.project : {};

  const role = user.model ? user.model.role : "";

  const isInvoiceDeleting = bidInvoices.delete;
  const isInvoiceEditing = bidInvoices.edit;
  const isBidEditing = bid.edit;

  const headerLoadingIndicator =
    isInvoiceDeleting || isInvoiceEditing || isBidEditing ? (
      <div className="h-0 fs-089rem">
        <LoadingIndicator />
      </div>
    ) : null;

  const headerTitle = (
    <div className={"row"} style={{ minWidth: "45vw" }}>
      <div className={"col-12 col-lg-12 col-xl-6 pr-0 pr-xl-5 mb-3 mb-xl-0"}>
        <span className={"h1"}>
          {projectModel ? `Invoices for bid "${bid.model.name}"` : null}
          {headerLoadingIndicator}
        </span>
      </div>
    </div>
  );

  const issueInvoiceBtnTo = bid.model
    ? `/invoice/${bid.model.id}/edit/new`
    : "!#";

  const issueInvoiceBtnClassName = classNames("btn btn-link btn-float", {
    disabled: getIsAllShotsUsedInInvoices(),
  });

  const issueDepositInvoicePDF =
    // !getDepositInvoice(projectModel) &&
    hasNotIssuedDepositInvoices() ? (
      <Link
        to="#"
        className="btn btn-link btn-float"
        onClick={onIssueDepositInvoicePDF}
      >
        <i className="icon-file-pdf" />
        <span>Issue Deposit Invoice</span>
      </Link>
    ) : null;

  const issueInvoiceBtn =
    role === "client" ? null : (
      <Link
        to={issueInvoiceBtnTo}
        className={issueInvoiceBtnClassName}
        onClick={onIssueInvoicePress}
        disabled={getIsAllShotsUsedInInvoices}
      >
        <i className="icon-coin-dollar" />
        <span>Issue invoice</span>
      </Link>
    );

  const rightTopMenu = (
    <>
      {issueDepositInvoicePDF}
      {issueInvoiceBtn}
    </>
  );

  return (
    <PageTemplate
      header={headerTitle}
      rightTopMenu={rightTopMenu}
      pageTitleClassName="flex-1 font-weight-semibold-child-full-width"
    >
      <SeoBlock
        title={projectModel ? `Invoices for bid "${bid.model.name}"` : null}
      />
      <BidInvoicesTable
        bid={bid}
        showHeader={true}
        allowStatusEdit={true}
        allowBulkActions={true}
        bids={bids.collection}
        bidInvoices={bidInvoices}
      />
    </PageTemplate>
  );
};

const mapStateToProps = (state) => ({
  statuses: state.statuses,
});

const mapDispatchToProps = (dispatch) => ({
  clearErrors: () => dispatch(appActions.clearErrors()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BidInvoicesPage));
