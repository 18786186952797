import React from "react";
import PropTypes from "prop-types";
import Checkbox from "../../../components/Checkbox";

const DashboardStatusRow = (props) => {
  const { rolesData, title, type, onChange, isLoading } = props;

  /**
   *
   * @param role
   * @param type
   * @returns {function(*): void}
   */
  const onStatusesDashboardChange = (role, type) => (event) => {
    if (onChange) {
      onChange(role, type, event.target.checked);
    }
  };

  return (
    <tr>
      <td className="pl-0">{title}</td>
      <td className="text-center">
        {rolesData.admin.isAllowed ? (
          <Checkbox
            className="checkbox-d-inline"
            onChange={onStatusesDashboardChange("admin", type)}
            checked={rolesData.admin[type] || false}
            disabled={isLoading}
          />
        ) : (
          "--"
        )}
      </td>
      <td className="text-center">
        {rolesData.producer.isAllowed ? (
          <Checkbox
            className="checkbox-d-inline"
            onChange={onStatusesDashboardChange("producer", type)}
            checked={rolesData.producer[type] || false}
            disabled={isLoading}
          />
        ) : (
          "--"
        )}
      </td>
      <td className="text-center">
        {rolesData.accountant.isAllowed ? (
          <Checkbox
            className="checkbox-d-inline"
            onChange={onStatusesDashboardChange("accountant", type)}
            checked={rolesData.accountant[type] || false}
            disabled={isLoading}
          />
        ) : (
          "--"
        )}
      </td>
      <td className="text-center">
        {rolesData.artist.isAllowed ? (
          <Checkbox
            className="checkbox-d-inline"
            onChange={onStatusesDashboardChange("artist", type)}
            checked={rolesData.artist[type] || false}
            disabled={isLoading}
          />
        ) : (
          "--"
        )}
      </td>
      <td className="text-center">
        {rolesData.client.isAllowed ? (
          <Checkbox
            className="checkbox-d-inline"
            onChange={onStatusesDashboardChange("client", type)}
            checked={rolesData.client[type] || false}
            disabled={isLoading}
          />
        ) : (
          "--"
        )}
      </td>
    </tr>
  );
};

DashboardStatusRow.propTypes = {
  rolesData: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default DashboardStatusRow;
