import { useCallback, useEffect, useState } from "react";
import { useAppStore } from "../../../storeHooks/app";

const useEditStatus = (id, model, editCallback) => {
  const [loading, setIsLoading] = useState(false);
  const { clearErrors } = useAppStore();

  const onChangeStatus = useCallback(
    (value) => {
      clearErrors();
      setIsLoading(true);
      editCallback(id, { status: value });
    },
    [model]
  );

  useEffect(() => {
    if (
      (!model.edit &&
        (model.model || model.collection) &&
        loading &&
        model.editSuccess) ||
      model.editError
    ) {
      setIsLoading(null);
    }
  }, [model]);

  return {
    loading,
    onChangeStatus,
  };
};

export default useEditStatus;
