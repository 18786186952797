import React, {
  Component,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import PropTypes from "prop-types";

import Select from "react-select";
import LoadingIndicator from "./LoadingIndicator";
import ApiError from "./ApiError";
import FormValidationError from "../components/FormValidationError";
import classNames from "classnames";
import StatusBadge from "./StatusBadge";
import connect from "react-redux/es/connect/connect";
import HasRights from "./HasRights";
import {formatter} from "../helpers/tools";

const ShotEditRow = (props) => {
  const statuses = useMemo(
    () => props.statuses.collection.filter((i) => i.type === "Shots"),
    [props.statuses]
  );

  const [state, setState] = useState({
    cost: props.shotData
      ? props.shotData.current_version.hours *
        props.shotData.current_version.rate
      : null,
  });

  useEffect(() => {
    focusOnNewRow();
    if (props.shotData) {
      handleGotShotData();
    }
    setTimeout(window.updateJQuery, 100);
  }, []);

  /**
   *
   * @returns {null}
   */
  const tasksTypesDefaultValues = useMemo(() => {
    return props.shotData
      ? props.shotData.task_types.map((taskType) => {
          return {
            value: taskType.id + "|" + Math.random(),
            label: taskType.name,
          };
        })
      : null;
  }, [props.shotData]);

  /**
   *
   */
  const focusOnNewRow = () => {
    const numberInputs = document.querySelectorAll(
      ".js-shot-edit-row-shot-number"
    );
    numberInputs[numberInputs.length - 1].focus();
  };

  /**
   *
   */
  const handleGotShotData = () => {
    const shot = props.shotData;
    const rootElement = document.querySelector(
      ".js-shot-edit-row-" + props.rowIndex
    );

    rootElement.querySelector(".js-shot-edit-row-shot-number").value =
      shot.shot_number || "";
    rootElement.querySelector(".js-shot-edit-row-description").value =
      shot.current_version.description || "";
    rootElement.querySelector(".js-shot-edit-row-bid-note").value =
      shot.current_version.notes || "";
    // rootElement.querySelector('.js-shot-edit-row-client-notes').value = shot.current_version.client_notes || '';
    rootElement.querySelector(".js-shot-edit-row-client-approval").value =
      shot.current_version.is_approved || "0";
    rootElement.querySelector(".js-shot-edit-row-rate").value =
      shot.current_version.rate || "";
    rootElement.querySelector(".js-shot-edit-row-hour").value =
      shot.current_version.hours || "";

    props.onTasksTypesChange(props.rowIndex)(tasksTypesDefaultValues);
  };

  /**
   *
   */
  const getRowResult = () => {
    return {
      shot_number: document.querySelector(".js-shot-edit-row-shot-number")
        .value,
      description: document.querySelector(".js-shot-edit-row-description")
        .value,
      notes: document.querySelector(".js-shot-edit-row-bid-note").value,
      // client_notes: document.querySelector('.js-shot-edit-row-client-notes').value,
      is_approved: document.querySelector(".js-shot-edit-row-client-approval")
        .value,
      rate: document.querySelector(".js-shot-edit-row-rate").value,
      hours: document.querySelector(".js-shot-edit-row-hour").value,
    };
  };

  /**
   *
   */
  const onPressSave = () => {
    if (typeof props.onPressSave === "function") {
      props.onPressSave(getRowResult());
    }
  };

  /**
   *
   */
  const onPressCancel = useCallback(() => {
    if (typeof props.onPressCancel === "function") {
      props.onPressCancel();
    }
  }, []);

  /**
   *
   */
  const onRateChange = useCallback(() => {
    setState({ cost: calculateCost() });
    props.handleChange(props.rowIndex)(null);
  }, []);

  /**
   *
   */
  const onHourChange = useCallback(() => {
    setState({ cost: calculateCost() });
    props.handleChange(props.rowIndex)(null);
  }, []);

  /**
   *
   * @returns {number}
   */
  const calculateCost = () => {
    const rootElement = document.querySelector(
      ".js-shot-edit-row-" + props.rowIndex
    );
    return (
      (rootElement.querySelector(".js-shot-edit-row-rate").value || 0) *
      (rootElement.querySelector(".js-shot-edit-row-hour").value || 0)
    );
  };

  const role = useMemo(
    () => (props.user && props.user.model ? props.user.model.role : ""),
    [props.user]
  );

  const clientApprovalSelectValues = [
    { label: "Yes", value: "1" },
    { label: "No", value: "0" },
  ];

  const isLoading = props.isLoading;
  const loadingIndicator =
    isLoading && !props.hideLoadingIndicator ? (
      <div className="float-right mt-2 mb-n3 mr-3">
        <LoadingIndicator />
      </div>
    ) : null;

  const validationErrors = props.validationErrors;

  const errors =
    props.error || validationErrors ? (
      <div className="clearfix mr-4 mt-2">
        <div className="float-right mr-2">
          <ApiError error={props.error} />
          <FormValidationError errors={validationErrors} />
        </div>
      </div>
    ) : null;

  const index = props.rowIndex;

  const isExpanded = props.isExpanded;

  const rootClassName = classNames("js-shot-edit-row-" + index);

  const expandBtnClassName = classNames(
    {
      "icon-arrow-down32": !isExpanded,
    },
    {
      "icon-arrow-up32": isExpanded,
    },
    "table-with-inputs-navigation_expand",
    "pointer"
  );

  const expandableClassName = classNames(
    "row",
    "table-with-inputs-navigation__expanded",
    {
      "d-none": !isExpanded,
    }
  );

  const isInputDisabled = isLoading || role === "client";

  return (
    <div className={rootClassName}>
      <div className="row">
        <HasRights allowedRoles={["admin", "producer"]} user={props.user.model}>
          <div className="col-1 relative js-row-selection">
            <i
              className="mi-close pointer table-with-inputs-navigation_remove"
              title="Remove shot"
              onClick={props.handleRemove(index)}
            />
            <input
              type="hidden"
              value="new"
              className="js-shot-in-table-edit-row-id"
            />
            <input
              type="hidden"
              value={index}
              className="js-shot-in-table-edit-row-index"
            />
          </div>
        </HasRights>

        <div className="col-2 js-row-selection">
          <div className="row">
            <div className="col-12">
              <input
                type="text"
                className="form-control js-shot-edit-row-shot-number js-shot-in-table-edit-row-shot-number"
                placeholder="Shot Number"
                disabled={isInputDisabled}
                onChange={props.handleChange(index)}
                onFocus={props.handleFocus(index)}
                onBlur={props.handleBlur(index)}
                onKeyDown={props.handleArrows(
                  index,
                  "js-shot-in-table-edit-row-shot-number"
                )}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <i
                className={expandBtnClassName}
                onClick={props.handleExpand(index)}
              />
            </div>
          </div>
        </div>

        <div className="col-3 js-row-selection">
          <input
            type="text"
            className="form-control js-shot-edit-row-description js-shot-in-table-edit-row-description"
            placeholder="Description"
            disabled={isInputDisabled}
            onChange={props.handleChange(index)}
            onFocus={props.handleFocus(index)}
            onBlur={props.handleBlur(index)}
            onKeyDown={props.handleArrows(
              index,
              "js-shot-in-table-edit-row-description"
            )}
          />
        </div>

        <div className="col-2 js-row-selection">
          <textarea
            className="form-control js-shot-edit-row-bid-note js-shot-in-table-edit-row-bid-note"
            placeholder="Bid Note"
            disabled={isLoading}
            onChange={props.handleChange(index)}
            onFocus={props.handleFocus(index)}
            onBlur={props.handleBlur(index)}
            onKeyDown={props.handleArrows(
              index,
              "js-shot-in-table-edit-row-bid-note"
            )}
          />
        </div>

        <div className="form-group col-1">
          <div>
            <Select
              className="js-shot-edit-row-client-approval js-shot-in-table-edit-row-client-approval
                                                                                shot-in-table-edit-row-client-approval"
              defaultValue={
                clientApprovalSelectValues[
                  props.shotData
                    ? +!props.shotData.current_version.is_approved
                    : 1
                ]
              }
              isDisabled={isLoading}
              options={clientApprovalSelectValues}
              isClearable={false}
              onChange={props.handleChange(index)}
              onFocus={props.handleFocus(index)}
              onBlur={props.handleBlur(index)}
            />
          </div>
        </div>

        <div className="col-1 js-row-selection">
          <input
            type="text"
            className="form-control js-shot-edit-row-rate js-shot-in-table-edit-row-rate"
            placeholder="0.00"
            disabled={isInputDisabled}
            onChange={onRateChange}
            onFocus={props.handleFocus(index)}
            onBlur={props.handleBlur(index)}
            onKeyDown={props.handleArrows(
              index,
              "js-shot-in-table-edit-row-rate"
            )}
          />
        </div>

        <div className="col-1 js-row-selection">
          <input
            type="text"
            className="form-control js-shot-edit-row-hour js-shot-in-table-edit-row-hour"
            placeholder="Hour"
            disabled={isInputDisabled}
            onChange={onHourChange}
            onFocus={props.handleFocus(index)}
            onBlur={props.handleBlur(index)}
            onKeyDown={props.handleArrows(
              index,
              "js-shot-in-table-edit-row-hour"
            )}
          />
        </div>

        <div className="col-1 js-row-selection">
          <div className="mt-1">{formatter.format(state.cost || 0)}</div>
        </div>
      </div>

      <div className={expandableClassName}>
        <div className="col-1" />

        <div className="form-group col-2">
          <label className="form-label">Status</label>
          <div className="m-n1">
            <StatusBadge status="not_started" statuses={statuses} />
          </div>
        </div>

        <div className="form-group col-3">
          <label className="form-label">Tasks</label>
          <Select
            isMulti
            hideSelectedOptions={false}
            className="js-shot-edit-row-tasks js-shot-in-table-edit-row-tasks shot-in-table-edit-row-client-approval"
            defaultValue={tasksTypesDefaultValues}
            isDisabled={isInputDisabled}
            options={props.tasksTypesOptions}
            isClearable={false}
            onChange={props.onTasksTypesChange(index)}
            onFocus={props.handleFocus(index)}
            onBlur={props.handleBlur(index)}
            menuPortalTarget={document.querySelector("body")}
          />
        </div>

        <div className="form-group col-3">
          <div className="form-group">
            <div className={"form-check"}>
              <label className="form-check-label">
                <input
                  type={"checkbox"}
                  className={
                    "form-check-input-styled js-task-type-edit-is-default js-shot-in-table-edit-row-is-hidden-from-project"
                  }
                  onChange={props.handleChange(index)}
                  onFocus={props.handleFocus(index)}
                  onBlur={props.handleBlur(index)}
                  value={1}
                  defaultChecked={0}
                  disabled={isInputDisabled}
                />
                Hidden from project
              </label>
            </div>
          </div>
        </div>
      </div>

      {errors}

      <div className="clearfix mr-2">
        {!props.hideSave && (
          <button
            type="button"
            className="btn bg-primary float-right mr-4"
            disabled={isLoading || !!validationErrors}
            onClick={onPressSave}
          >
            Save
          </button>
        )}
        {!props.hideCancel && (
          <button
            type="button"
            className="btn btn-link float-right"
            disabled={isLoading}
            onClick={onPressCancel}
          >
            Cancel
          </button>
        )}
        {loadingIndicator}
      </div>
    </div>
  );
};

ShotEditRow.propTypes = {
  onPressSave: PropTypes.func,
  onPressCancel: PropTypes.func,
  isLoading: PropTypes.bool,
  error: PropTypes.object,
  validationErrors: PropTypes.object,
  shotData: PropTypes.object,
  hideCancel: PropTypes.bool,
  hideSave: PropTypes.bool,
  hideLoadingIndicator: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  isExpanded: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  handleFocus: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleExpand: PropTypes.func.isRequired,
  handleArrows: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  tasksTypesOptions: PropTypes.array.isRequired,
  onTasksTypesChange: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  statuses: state.statuses,
});

export default connect(mapStateToProps)(ShotEditRow);
