import PropTypes from "prop-types";
import { memo } from "react";

const BidVersionName = (props) => {
  const { bidVersion } = props;

  return bidVersion ? bidVersion.version_name || bidVersion.create_order : "";
};

BidVersionName.propTypes = {
  bidVersion: PropTypes.object,
};

export default memo(BidVersionName);
