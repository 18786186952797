import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { store } from "../configureStore";

import { getStatus } from "./tools";

import HasRights from "../components/HasRights";

class ActivityContext {
  setStrategy(strategy) {
    this.strategy = strategy;
  }

  executeStrategy(activity) {
    return this.strategy.execute(activity);
  }
}

class Strategy {
  creatorDidWhat = {};

  /**
   *
   * @param activity
   * @returns {{}}
   */
  updatedParamsDescriptionConfig(activity) {
    return {};
  }

  /**
   *
   * @param activity
   * @returns {string}
   */
  static getMuteLine(activity) {
    return moment.unix(activity.created).format("D MMM YYYY | HH:mm A");
  }

  /**
   *
   * @param activity
   * @returns {null|*}
   */
  getEntityLink(activity) {
    return null;
  }

  /**
   *
   * @param activity
   * @returns {*}
   */
  getEntityUpdatedParamsDescription(activity) {
    const descriptionConfig = this.updatedParamsDescriptionConfig(activity);
    const descriptionConfigForActivityKind =
      descriptionConfig[activity.kind_value];

    if (descriptionConfigForActivityKind) {
      const conditions = descriptionConfigForActivityKind.conditions;

      const firedCondition = conditions.find((item) => item.condition);

      return firedCondition ? firedCondition.description : null;
    }

    return null;
  }

  /**
   *
   * @param activity
   * @returns {null|*}
   */
  getDescription(activity) {
    if (!activity) return null;

    const activityCreator = activity.creator || activity.creator_state || {};

    let creatorDidWhat =
      activity.kind_value &&
      Object.keys(this.creatorDidWhat).includes(activity.kind_value)
        ? this.creatorDidWhat[activity.kind_value]
        : null;

    if (!creatorDidWhat) {
      return null;
    }

    let entityLink = this.getEntityLink(activity);

    const entityUpdatedParamsDescription = this.getEntityUpdatedParamsDescription(
      activity
    );

    if (creatorDidWhat && entityLink) {
      creatorDidWhat += ":";
    }

    if (creatorDidWhat && entityLink && entityUpdatedParamsDescription) {
      entityLink = (
        <>
          {entityLink}: {entityUpdatedParamsDescription}
        </>
      );
    } else if (
      creatorDidWhat &&
      !entityLink &&
      entityUpdatedParamsDescription
    ) {
      creatorDidWhat += ":";
    }

    return (
      <>
        <HasRights
          allowedRoles={["admin", "producer"]}
          user={store.getState().user.model}
          placeholder={
            <span className="text-primary">{activityCreator.name}</span>
          }
        >
          <Link to={`/user/${activityCreator.id}`}>{activityCreator.name}</Link>
        </HasRights>{" "}
        {creatorDidWhat} {entityLink}
      </>
    );
  }

  /**
   *
   * @param activity
   * @returns {{muteLine: string, description: *, id: *, userName: *}}
   */
  execute(activity) {
    return {
      id: activity.id,
      muteLine: Strategy.getMuteLine(activity),
      userName: activity.kind,
      description: this.getDescription(activity),
    };
  }
}

class StrategyProject extends Strategy {
  creatorDidWhat = {
    project_deleted: "deleted project",
    project_updated: "updated project",
    project_created: "created project",
    project_status_changed: "changed project status",
  };

  updatedParamsDescriptionConfig(activity) {
    return {
      project_updated: {
        conditions: [
          {
            condition: !!(
              activity.entity_state &&
              activity.entity_state.original_state &&
              "due_date" in activity.entity_state.original_state &&
              activity.entity_state.original_state.due_date !==
                activity.entity_state.due_date
            ),
            description: (
              <>
                changed due date to{" "}
                <span className="text-primary">
                  {activity.entity_state.due_date
                    ? moment
                        .unix(activity.entity_state.due_date)
                        .format("DD/MM/YY")
                    : "none"}
                </span>
              </>
            ),
          },
        ],
      },
    };
  }

  /**
   *
   * @param activity
   * @returns {*}
   */
  getEntityLink(activity) {
    if (activity.project && activity.kind_value.includes("project")) {
      return (
        <Link to={`/project/${activity.project.id}`}>
          {activity.project.name}
        </Link>
      );
    } else if (
      activity.project &&
      activity.entity_state.bid &&
      activity.entity_state.bid.id &&
      activity.kind_value.includes("bid")
    ) {
      return (
        <Link to={`/bid/${activity.entity_state.bid.id}`}>
          {activity.project.name}
        </Link>
      );
    } else if (
      activity.project &&
      activity.entity_state.id &&
      activity.kind_value.includes("bid")
    ) {
      return (
        <Link to={`/project/${activity.entity_state.id}`}>
          {activity.project.name}
        </Link>
      );
    }

    return <span className="text-muted">{activity.entity_state.name}</span>;
  }
}

class StrategyBid extends Strategy {
  creatorDidWhat = {
    bid_created: "created new bid",
    bid_updated: "updated bid",
  };
}

class StrategyBidVersion extends Strategy {
  creatorDidWhat = {
    bid_new_version_issued: "issued new bid version",
    bid_invoice_generated: "generated bid invoice",
  };

  getEntityLink(activity) {
    if (activity.bid_version_id) {
      return (
        <span>
          <Link to={`/bid/${activity.bid_version_id}`}>Bid</Link>
        </span>
      );
    }

    return null;
  }
}

class StrategyUser extends Strategy {
  creatorDidWhat = {
    user_logged_in: "logged in",
    profile_created: "created profile",
    profile_updated: "updated profile",
    profile_approved: "approved profile",
    profile_deleted: "deleted profile",
  };

  getEntityLink(activity) {
    if (activity.kind_value.includes("delete")) {
      return activity.entity_state.name;
    } else if (activity.kind_value.includes("approved")) {
      return (
        <>
          <HasRights
            allowedRoles={["admin", "producer"]}
            user={store.getState().user.model}
            placeholder={
              <span className="text-primary">{activity.entity_state.name}</span>
            }
          >
            <Link to={`/user/${activity.entity_state.id}`}>
              {activity.entity_state.name}
            </Link>
          </HasRights>
          <span>{` account as ${activity.entity_state.role}`}</span>
        </>
      );
    } else if (activity.kind_value.includes("user_logged_in")) {
      return null;
    }
    return (
      <>
        <HasRights
          allowedRoles={["admin", "producer"]}
          user={store.getState().user.model}
          placeholder={
            <span className="text-primary">{activity.entity_state.name}</span>
          }
        >
          <Link to={`/user/${activity.entity_state.id}`}>
            {activity.entity_state.name}
          </Link>
        </HasRights>
      </>
    );
  }
}

class StrategyInvoice extends Strategy {
  creatorDidWhat = {
    invoice_issued: "issued invoice",
    invoice_status_changed: "changed invoice status",
  };

  getEntityLink(activity) {
    return activity.entity_state &&
      activity.entity_state.bid &&
      activity.entity_state.bid.project_id ? (
      <Link to={`/invoice/${activity.entity_state.bid.project_id}`}>
        {activity.kind}
      </Link>
    ) : (
      activity.kind
    );
  }
}

class StrategyTask extends Strategy {
  creatorDidWhat = {
    task_assigned: "assigned task",
    task_updated: "updated task",
  };

  /**
   *
   * @param activity
   * @returns {{task_updated: {conditions: {condition: boolean, description: *}[]}}}
   */
  updatedParamsDescriptionConfig(activity) {
    const statuses = store
      .getState()
      .statuses.collection.filter((item) => item.type === "Tasks");
    return {
      task_updated: {
        conditions: [
          {
            condition: !!(
              activity.entity_state &&
              activity.entity_state.original_state &&
              "status" in activity.entity_state.original_state &&
              activity.entity_state.original_state.status !==
                activity.entity_state.status
            ),
            description: (
              <>
                changed the status to{" "}
                <span className="text-primary">
                  {getStatus(activity.entity_state.status, statuses)}
                </span>
              </>
            ),
          },
        ],
      },
    };
  }

  getEntityLink(activity) {
    const state = store.getState();
    const tasksTypes = state.tasksTypes;
    if (
      activity.kind_value.includes("assigned") &&
      activity.entity_state &&
      activity.entity_state.assignee &&
      activity.entity_state.project &&
      activity.entity_state.shot &&
      tasksTypes.collection.length
    ) {
      const task_type = tasksTypes.collection.find(
        (i) => i.id === activity.entity_state.task_type_id
      );
      return (
        <>
          <span>{`${task_type && task_type.name} task to `}</span>
          <HasRights
            allowedRoles={["admin", "producer"]}
            user={store.getState().user.model}
            placeholder={
              <span className="text-primary">
                {activity.entity_state.assignee.name}
              </span>
            }
          >
            <Link to={`/user/${activity.entity_state.assignee.id}`}>
              {activity.entity_state.assignee.name}
            </Link>
          </HasRights>
          <span>{` in shot `}</span>
          <Link
            to={`/project/${activity.entity_state.project.id}/shot/${activity.entity_state.shot.id}`}
          >
            {activity.entity_state.shot.shot_number}
          </Link>
          <span>{` in project `}</span>
          <Link to={`/project/${activity.entity_state.project.id}`}>
            {activity.entity_state.project.name}
          </Link>
        </>
      );
    } else if (
      activity.kind_value.includes("updated") &&
      activity.entity_state.project &&
      activity.entity_state.shot &&
      tasksTypes.collection.length
    ) {
      const task_type = tasksTypes.collection.find(
        (i) => i.id === activity.entity_state.task_type_id
      );
      return (
        <>
          <span>{`${task_type && task_type.name} task `}</span>
          <span>{`in shot `}</span>
          <Link
            to={`/project/${activity.entity_state.project.id}/shot/${activity.entity_state.shot.id}`}
          >
            {activity.entity_state.shot.shot_number}
          </Link>
          <span>{` in project `}</span>
          <Link to={`/project/${activity.entity_state.project.id}`}>
            {activity.entity_state.project.name}
          </Link>
        </>
      );
    } else if (
      activity.kind_value.includes("assigned") &&
      activity.entity_state &&
      activity.entity_state.project &&
      activity.entity_state.shot &&
      tasksTypes.collection.length
    ) {
      const task_type = tasksTypes.collection.find(
        (i) => i.id === activity.entity_state.task_type_id
      );
      return (
        <>
          <span>{`${task_type && task_type.name} task `}</span>
          <span>{` in shot `}</span>
          <Link
            to={`/project/${activity.entity_state.project.id}/shot/${activity.entity_state.shot.id}`}
          >
            {activity.entity_state.shot.shot_number}
          </Link>
          <span>{` in project `}</span>
          <Link to={`/project/${activity.entity_state.project.id}`}>
            {activity.entity_state.project.name}
          </Link>
        </>
      );
    }
    return null;
  }

  /**
   *
   * @param activity
   * @returns {null|*}
   */
  getDescription(activity) {
    if (!activity) return null;

    const activityCreator = activity.creator || activity.creator_state || {};

    let creatorDidWhat =
      activity.kind_value &&
      Object.keys(this.creatorDidWhat).includes(activity.kind_value)
        ? this.creatorDidWhat[activity.kind_value]
        : null;

    creatorDidWhat = !activity.entity_state.assignee
      ? "un" + creatorDidWhat
      : creatorDidWhat;

    if (!creatorDidWhat) {
      return null;
    }

    let entityLink = this.getEntityLink(activity);

    const entityUpdatedParamsDescription = this.getEntityUpdatedParamsDescription(
      activity
    );

    if (creatorDidWhat && entityLink) {
      creatorDidWhat += ":";
    }

    if (creatorDidWhat && entityLink && entityUpdatedParamsDescription) {
      entityLink = (
        <>
          {entityLink}: {entityUpdatedParamsDescription}
        </>
      );
    } else if (
      creatorDidWhat &&
      !entityLink &&
      entityUpdatedParamsDescription
    ) {
      creatorDidWhat += ":";
    }

    return (
      <>
        <HasRights
          allowedRoles={["admin", "producer"]}
          user={store.getState().user.model}
          placeholder={
            <span className="text-primary">{activityCreator.name}</span>
          }
        >
          <Link to={`/user/${activityCreator.id}`}>{activityCreator.name}</Link>
        </HasRights>{" "}
        {creatorDidWhat} {entityLink}
      </>
    );
  }
}

class StrategyShot extends Strategy {
  creatorDidWhat = {
    shot_created: "has added",
    shot_deleted: "deleted shot",
    shot_updated: "updated shot",
  };

  /**
   *
   * @param activity
   * @returns {{shot_updated: {conditions: {condition: boolean, description: *}}}}
   */
  updatedParamsDescriptionConfig(activity) {
    const statuses = store
      .getState()
      .statuses.collection.filter((item) => item.type === "Shots");
    return {
      shot_updated: {
        conditions: [
          {
            condition: !!(
              activity.entity_state &&
              activity.entity_state.original_state &&
              "status" in activity.entity_state.original_state &&
              activity.entity_state.original_state.status !==
                activity.entity_state.status
            ),
            description: (
              <>
                changed the status to{" "}
                <span className="text-primary">
                  {getStatus(activity.entity_state.status, statuses)}
                </span>
              </>
            ),
          },
        ],
      },
    };
  }

  /**
   *
   * @param activity
   * @returns {*}
   */
  getEntityLink(activity) {
    if (
      activity.entity_state &&
      activity.entity_state.project_id &&
      activity.entity_state.project &&
      activity.entity_state.project.name
    ) {
      return (
        <>
          <Link
            to={`/project/${activity.entity_state.project_id}/shot/${activity.entity_state.id}`}
          >
            {`${activity.entity_state.shot_number}`}
          </Link>
          <span>{" shot in "}</span>
          <Link to={`/project/${activity.entity_state.project_id}`}>
            {`${activity.entity_state.project.name}`}
          </Link>
        </>
      );
    }

    return <span>{activity.entity_state.shot_number}</span>;
  }
}

class StrategyShotUpdate extends Strategy {
  creatorDidWhat = {
    shot_update_created: "updated shot",
    shot_update_updated: "updated shot",
    shot_update_deleted: "updated shot",
  };

  /**
   *
   * @param activity
   * @returns {{shot_update_created: {conditions: {condition: boolean, description: *}[]}, shot_update_updated: {conditions: {condition: boolean, description: *}[]}, shot_update_deleted: {conditions: {condition: boolean, description: *}[]}}}
   */
  updatedParamsDescriptionConfig(activity) {
    return {
      shot_update_created: {
        conditions: [
          {
            condition: !!activity.entity_state,
            description: (
              <>
                changed the version to{" "}
                <span className="text-primary">
                  {activity.entity_state.version}
                </span>
              </>
            ),
          },
        ],
      },
      shot_update_updated: {
        conditions: [
          {
            condition: !!(
              activity.entity_state &&
              activity.entity_state.original_state &&
              "version" in activity.entity_state.original_state &&
              activity.entity_state.original_state.version !==
                activity.entity_state.version
            ),
            description: (
              <>
                changed the shot update version from{" "}
                <span className="text-primary">
                  {activity.entity_state.original_state &&
                    activity.entity_state.original_state.version}
                </span>
                {" to "}
                <span className="text-primary">
                  {activity.entity_state.version}
                </span>
              </>
            ),
          },
        ],
      },
      shot_update_deleted: {
        conditions: [
          {
            condition: !!activity.entity_state,
            description: (
              <>
                removed shot update version{" "}
                <span className="text-primary">
                  {activity.entity_state.version}
                </span>
              </>
            ),
          },
        ],
      },
    };
  }

  /**
   *
   * @param activity
   * @returns {*}
   */
  getEntityLink(activity) {
    if (
      (activity.kind_value.includes("created") ||
        activity.kind_value.includes("updated") ||
        activity.kind_value.includes("deleted")) &&
      activity.entity_state.project &&
      activity.entity_state.shot
    ) {
      return (
        <>
          <Link
            to={`/project/${activity.entity_state.project.id}/shot/${activity.entity_state.shot.id}`}
          >
            {activity.entity_state.shot.shot_number}
          </Link>
          <span>{` shot `}</span>
          <span>{` in `}</span>
          <Link to={`/project/${activity.entity_state.project.id}`}>
            {activity.entity_state.project.name}
          </Link>
        </>
      );
    }
    return null;
  }
}

class StrategyDefault {
  execute(activity) {
    return {
      id: +new Date(),
      muteLine: "Activity",
      userName: "Unknown",
      description: "Unknown activity type",
    };
  }
}

/**
 *
 * @param activity
 * @returns {*}
 */
export function activityUpdate(activity) {
  const context = new ActivityContext();

  switch (activity.entity_type) {
    case "project":
      context.setStrategy(new StrategyProject());
      break;

    case "bid":
      context.setStrategy(new StrategyBid());
      break;

    case "bid_version":
      context.setStrategy(new StrategyBidVersion());
      break;

    case "user":
      context.setStrategy(new StrategyUser());
      break;

    case "invoice":
      context.setStrategy(new StrategyInvoice());
      break;

    case "task":
      context.setStrategy(new StrategyTask());
      break;

    case "shot":
      context.setStrategy(new StrategyShot());
      break;

    case "shot_update":
      context.setStrategy(new StrategyShotUpdate());
      break;

    default:
      context.setStrategy(new StrategyDefault());
  }

  return context.executeStrategy(activity);
}
