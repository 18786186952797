import React, { useCallback, useState, useMemo, useEffect, memo } from "react";

import ModalTemplate from "../ModalTemplate";

import LoadingIndicator from "../../components/LoadingIndicator";
import Select from "react-select";
import { withRouter } from "react-router-dom";
import { formatter, shotsSortFn } from "../../helpers/tools";
import StaticAlert from "../../components/StaticAlert";
import { useProjectStore } from "../../storeHooks/project";
import { useBidVersionStore } from "../../storeHooks/bidVersion";
import axios from "axios";
import AxiosConfig from "../../AxiosConfig";

const TemplateOptions = [
  {
    value: "number",
    label: "Shot Number",
  },
  {
    value: "number_description",
    label: "Shot Number + Description",
  },
  {
    value: "number_description_cost",
    label: "Shot Number + Description + Cost",
  },
  {
    value: "number_description_notes_cost",
    label: "Shot Number + Description + Bid Notes + Cost",
  },
  {
    value: "number_description_notes_cost_previous",
    label: "Shot Number + Description + Bid Notes + Cost + Previous Cost",
  },
  {
    value: "number_version",
    label: "Shot Number + Version",
  },
  {
    value: "number_version_description",
    label: "Shot Number + Version + Description",
  },
];

/**
 *
 * @param shots
 * @param selectedFormat
 * @returns {string}
 */
const formatData = (shots, selectedFormat, project) => {
  const formattedData = [];
  const requestedInfo = selectedFormat.split("_");

  for (const shot of shots) {
    let shotInfo = "";
    const recentShotVersion = shot.versions[shot.versions.length - 1];
    const previousShotVersion =
      shot.versions.length > 2 ? shot.versions[shot.versions.length - 2] : null;

    const projectShot = project.shots.find((i) => i.id === shot.id);

    for (const info of requestedInfo) {
      switch (info) {
        case "number":
          shotInfo += shot.shot_number;
          break;

        case "description":
          shotInfo += ` (${recentShotVersion.description}) `;
          break;

        case "cost":
          shotInfo += formatter.format(recentShotVersion.cost_summary);
          break;

        case "notes":
          shotInfo += ` - ${recentShotVersion.notes} - `;
          break;

        case "previous":
          shotInfo += ` (previously ${
            previousShotVersion
              ? formatter.format(previousShotVersion.cost_summary)
              : "--"
          })`;
          break;

        case "version":
          if (projectShot.last_shot_update) {
            shotInfo += `_${projectShot.last_shot_update.version}`;
          }
          break;
      }
    }
    formattedData.push(shotInfo);
  }

  return formattedData.join("\n");
};

/**
 *
 * @param id
 * @returns {boolean}
 */
const fallbackCopyTextToClipboard = (id) => {
  const input = document.getElementById(id);

  input.readOnly = false;
  input.contentEditable = true;
  input.focus();
  input.select();

  const range = document.createRange();
  range.selectNodeContents(input);

  const s = window.getSelection();
  s.removeAllRanges();
  s.addRange(range);

  input.setSelectionRange(0, 999999);

  let successful = false;
  try {
    successful = document.execCommand("copy");
  } catch (err) {}

  s.removeAllRanges();
  s.addRange(range);
  input.blur();
  input.readOnly = true;
  input.contentEditable = false;

  return successful;
};

/**
 *
 */
const copyTextToClipboard = async (id) => {
  if (!navigator.clipboard) {
    console.log("Clipboard API not supported");
    return fallbackCopyTextToClipboard(id);
  }

  const input = document.getElementById(id);

  await navigator.clipboard.writeText(input.value);

  return true;
};

/**
 *
 * @param projectId
 * @returns {Promise<void>}
 */
const fetchProject = async (projectId) => {
  const url =
    AxiosConfig.getEndpointAddress() + "/api/projects/" + projectId + ".json?";

  return await axios
    .get(url, AxiosConfig.getAuthConfig())
    .then((result) => result.data);
};

/**
 *
 * @param projectId
 * @returns {Promise<void>}
 */
const fetchShotsByBidVersion = async (bidVersionIds) => {
  let shots = [];
  for (const bidVersionId of bidVersionIds) {
    const url =
      AxiosConfig.getEndpointAddress() +
      "/api/shots.json?" +
      AxiosConfig.objectToURLQuery({
        bid_version_id: bidVersionId,
        page: 1,
        limit: 200,
      });

    const bidShots = await axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((result) => result.data);

    shots = [...shots, ...bidShots.shots];
  }

  return shots;
};

/**
 *
 * @param projectId
 * @param bidVersionIds
 * @returns {Promise<void[]>}
 */
const fetchProjectAndShots = async (projectId, bidVersionIds) => {
  const project = await fetchProject(projectId);
  const bidShots = await fetchShotsByBidVersion(bidVersionIds);

  return [project, bidShots];
};

const CopyShotsInfoModal = (props) => {
  const [showSuccessNotification, setShowSuccessNotification] = useState(false);

  const [selectedTemplate, setSelectedTemplate] = useState(
    "number_description_cost"
  );

  const [state, setState] = useState({
    initialized: false,
    project: null,
    bidShots: [],
  });

  const isInitialized = useMemo(() => state.initialized, [state.initialized]);

  //const { fetchShotsByBidVersion, bidVersion } = useBidVersionStore();

  useEffect(() => {
    const shots =
      props.history.location.state && props.history.location.state.selectedShots
        ? props.history.location.state.selectedShots
        : null;

    if (shots.length && !isInitialized) {
      const projectId = shots[0].project_id;
      // access to this modal can be from project page (use current version) or bid page
      // use versions

      const bidVersionIds = shots.map((i) =>
        i.current_version
          ? i.current_version.bid_version_id
          : i.versions[i.versions.length - 1].bid_version_id
      );

      const callback = async () => {
        const [project, bidShots] = await fetchProjectAndShots(
          projectId,
          // unique bid version ids
          [...new Set(bidVersionIds)]
        );

        setState({
          initialized: true,
          project,
          bidShots,
        });
      };

      callback();
    }
  }, [isInitialized]);

  const isLoading = !state.initialized;
  const loadingIndicator = isLoading ? <LoadingIndicator /> : null;
  const modalTitle = "Copy Shot Data";

  useEffect(() => {
    if (showSuccessNotification) {
      setTimeout(() => {
        setShowSuccessNotification(false);
      }, 5000);
    }
  }, [showSuccessNotification]);

  const selectedShots = useMemo(
    () =>
      (props.history.location.state &&
        props.history.location.state.selectedShots) ||
      [],
    [props.history.location.state]
  );

  /**
   *
   * @param e
   */
  const onTemplateChange = (e) => {
    setSelectedTemplate(e.value);
  };

  // pull data from store, data from history state may not contain all required information
  const shots = !isLoading
    ? state.bidShots
        .filter((i) => selectedShots.findIndex((j) => j.id === i.id) >= 0)
        .sort(shotsSortFn)
        .reverse()
    : [];

  const shotsInfo = formatData(shots, selectedTemplate, state.project);
  const onCopyData = () => {
    if (copyTextToClipboard("shotsDataToCopy")) {
      setShowSuccessNotification(true);
    }
  };

  const successNotification = showSuccessNotification ? (
    <StaticAlert type="success" header={`Copied to clipboard!`} />
  ) : null;

  /**
   *
   */
  const onClose = () => {
    props.history.goBack();
  };

  return (
    <ModalTemplate
      title={modalTitle}
      onClose={onClose}
      confirmButtonLabel="Copy"
      onConfirm={onCopyData}
      cancelButtonLabel="Cancel"
      onCancel={onClose}
      loadingIndicator={loadingIndicator}
      disableConfirmButton={isLoading}
    >
      <form noValidate>
        <div className="row">
          <div className="form-group col-12">
            <label className="form-label">Formatting Template</label>
            <Select
              options={TemplateOptions}
              initClassName="js-select2"
              name={"format_template"}
              onChange={onTemplateChange}
              value={TemplateOptions.find((i) => i.value === selectedTemplate)}
            />
          </div>
          <div className={"form-group col-12"}>
            <textarea
              id={"shotsDataToCopy"}
              rows={10}
              className={"form-control"}
              readOnly={true}
              value={shotsInfo}
            />
          </div>
        </div>
      </form>
      {successNotification}
    </ModalTemplate>
  );
};

export default memo(withRouter(CopyShotsInfoModal));
