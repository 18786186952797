import React, { useCallback, useMemo, useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import moment from "moment";
import VariablesString from "../../helpers/VariablesString";
import DropdownMenu from "../../components/DropdownMenu";
import PageTemplate from "../PageTemplate";
import LoadingIndicator from "../../components/LoadingIndicator";
import { store } from "../../configureStore";
import { filterAllowedRoles, getBidVersionName } from "../../helpers/tools";
import BidPageContent from "./components/BidPageContent";
import { history } from "../../configureStore";
import SeoBlock from "../../components/SeoBlock";
import { useUserStore } from "../../storeHooks/user";
import { useBidVersionStore } from "../../storeHooks/bidVersion";
import { useBidStore } from "../../storeHooks/bid";
import { useProjectStore } from "../../storeHooks/project";
import TotalFormat from "../BidsPage/TotalFormat";
import { generatePDFFilename } from "../../helpers/bid";

const BidPage = (props) => {
  const match = useRouteMatch();
  const isHistorical = useMemo(() => match.params.type === "historical", []);
  const [state, setState] = useState({
    selectedShotsIds: [],
  });

  const { user } = useUserStore();
  const {
    bidVersion,
    fetchBidVersion,
    fetchShotsByBidVersion,
    bidVersionUpdate,
  } = useBidVersionStore();
  const { bid, fetchBid, setEditBid, bidInvoicePDF, editBid } = useBidStore();
  const { project, editProject, setEditProject } = useProjectStore();

  /**
   *
   * @returns {*}
   */
  const selectedShotsData = useMemo(() => {
    return bidVersion.shots.filter((item) =>
      state.selectedShotsIds.includes(item.id)
    );
  }, [bidVersion.shots, state.selectedShotsIds]);

  /**
   *
   * @returns {Promise<void>}
   */
  const getBid = async () => {
    if (user.token && user.model && match.params.id) {
      await fetchBidVersion(match.params.id);
      const bidVersion = store.getState().bidVersion;
      if (bidVersion && bidVersion.model) {
        await fetchBid(bidVersion.model.bid_id);
      }
      await fetchShotsByBidVersion(match.params.id);
    }
  };

  /**
   *
   * @returns {*}
   */
  const isOneOfBidVersionsApproved = useCallback(() => {
    return project.model && project.model.bids
      ? project.model.bids.findIndex((item) => item.is_approved) !== -1
      : null;
  }, [project.model]);

  /**
   *
   */
  const onEditBidDetails = useCallback(() => {
    setEditBid(bid.model);
    setEditProject(bid.model.project);
    history.push(`/project-bid/edit/${bid.model.id}`);
  }, [bid.model]);

  /**
   *
   */
  const onCSVImportPress = useCallback(() => {
    history.push(`/bid/${bidVersion.model.id}/csv-import`);
  }, [bidVersion.model]);

  /**
   *
   * @returns {Promise<void>}
   */
  const onDownloadBidPDFPress = useCallback(async () => {
    const filename = generatePDFFilename(bid.model, bidVersion.model);
    await bidInvoicePDF(bidVersion.model.id, filename);
  }, [bidVersion.model, bid.model]);

  /**
   *
   */
  const onMarkAsRejectedPress = useCallback(() => {
    history.push(`/bid/${bidVersion.model.id}/reject`);
  }, [bidVersion.model]);

  /**
   *
   * @returns {Promise<void>}
   */
  const onMarkAsApprovedPress = useCallback(async () => {
    console.log(bidVersion.model.id);
    if (
      window.confirm(
        "Are you sure to mark this bid version as approved? It make this bid visible on Projects page."
      )
    ) {
      await bidVersionUpdate({ is_approved: 1 }, bidVersion.model.id);
      if (!bidVersion.updateError) {
        await getBid();
      }
    }
  }, [bidVersion.model, bidVersion.updateError]);

  /**
   *
   */
  const onMarkAsWaitingApprovalPress = useCallback(() => {
    editBid(bid.model.id, {
      status: "bid_waiting_approval",
    });
    editProject(bid.model.project.id, {
      status: "bid_waiting_approval",
    });
  }, [bid.model, bid.model.project]);

  /**
   *
   */
  const onSeeVersionHistoryPress = useCallback(() => {
    history.push(`/bid/${bidVersion.model.id}/bid-versions`);
  }, [bidVersion.model]);

  /**
   *
   * @param selectedShotsIds
   */
  const onSelectShot = useCallback(
    (selectedShotsIds) => {
      setState({ selectedShotsIds });
    },
    [state.selectedShotsIds]
  );

  /**
   *
   */
  const onMovePress = useCallback(() => {
    history.push(`/bid/${bidVersion.model.id}/move-shots`, {
      moveShots: selectedShotsData,
    });
  }, [bidVersion.model, selectedShotsData]);

  /**
   *
   */
  const onCopyData = useCallback(() => {
    history.push(`/bid/${bidVersion.model.id}/copy-shots-data`, {
      selectedShots: selectedShotsData,
    });
  }, [bidVersion.model, selectedShotsData]);

  /**
   *
   * @returns {boolean}
   */
  const isRecentBidVersion = useMemo(() => {
    const isRecent = true;
    if (
      bidVersion.model &&
      bidVersion.model.id &&
      bid.model &&
      bid.model.recent_bid_version
    ) {
      return bidVersion.model.id === bid.model.recent_bid_version.id;
    }
    return isRecent;
  }, [bidVersion.model, bid.model]);

  const bidModel = bid.model;
  const bidVersionModel = bidVersion.model;
  const projectModel = bidModel.project || {};

  const loading = bidVersion.fetch || bid.fetch;

  const projectLink = useMemo(() => {
    let projectLink = `${projectModel ? projectModel.name : ""} - ${
      bidModel ? bidModel.name : ""
    }`;

    if (
      ["admin", "producer", "client"].includes(user.model.role) &&
      projectModel &&
      (projectModel.status !== "bid_in_progress" || bidVersion.is_approved)
    ) {
      projectLink = (
        <Link
          to={`/project/${projectModel.id}`}
          className="text-dark hover-underline"
        >
          <span
            data-popup="tooltip"
            data-placement="right"
            data-original-title="Go to related project's page"
          >
            {projectModel.name} - {bidModel.name}
          </span>
        </Link>
      );
    }

    return projectLink;
  }, [projectModel, bidModel, user.model.role, bidVersion.is_approved]);

  const headerTitle = (
    <div className={"d-flex flex-column"}>
      <span className={"h1"}>
        {projectLink}
        {loading && <LoadingIndicator className="d-inline ml-4" />}
      </span>
      {!loading && (
        <span>
          <strong>Total:</strong> <TotalFormat {...bidModel} />
        </span>
      )}
    </div>
  );

  const bidVersionIsUpdating = bidVersion.update || bidVersion.approve;
  const bidIsUpdating = bid.edit;

  const manageDropdownButton = (
    <button
      type="button"
      className="btn btn-link btn-link_black dropdown-toggle ml-2"
      data-toggle="dropdown"
      disabled={loading}
    >
      <i className="icon-gear mr-13px" />
      Manage
    </button>
  );

  const manageDropdownConfig = useMemo(() => {
    const options = [
      {
        icon: "mi-mode-edit",
        action: onEditBidDetails,
        data: null,
        label: "Edit Bid details",
        allowedRoles: ["admin", "producer"],
      },
      {
        icon: "mi-file-upload",
        action: onCSVImportPress,
        data: null,
        label: "Import from CSV",
        allowedRoles: ["admin", "producer"],
      },
      {
        icon: "mi-picture-as-pdf",
        action: onDownloadBidPDFPress,
        data: null,
        label: "Download Bid PDF",
        allowedRoles: ["admin", "producer", "client"],
      },
    ];

    if (
      bidVersionModel &&
      !bidVersionModel.is_rejected &&
      !bidVersionModel.is_approved
    ) {
      options.splice(2, 0, {
        icon: "mi-close",
        action: onMarkAsRejectedPress,
        data: null,
        label: "Mark as rejected",
        allowedRoles: ["admin", "producer", "client"],
      });
    }
    if (
      bidVersionModel &&
      !bidVersionModel.is_rejected &&
      !bidVersionModel.is_approved &&
      !isOneOfBidVersionsApproved()
    ) {
      options.splice(3, 0, {
        icon: "mi-check",
        action: onMarkAsApprovedPress,
        data: null,
        label: "Mark as approved",
        allowedRoles: ["admin", "producer", "client"],
      });
    }
    if (projectModel.status && projectModel.status === "bid_in_progress") {
      options.splice(3, 0, {
        icon: "mi-check",
        action: onMarkAsWaitingApprovalPress,
        data: null,
        label: "Mark as waiting approval",
        allowedRoles: ["admin", "producer", "accountant"],
      });
    }

    options.push({
      icon: "mi-line-weight",
      action: onSeeVersionHistoryPress,
      data: null,
      label: "See version history",
      allowedRoles: ["admin", "producer", "client"],
    });

    if (state.selectedShotsIds.length) {
      options.push({
        icon: "mi-content-copy",
        action: onCopyData,
        data: null,
        label: "Copy shot(s) data...",
        allowedRoles: ["admin", "producer"],
      });

      options.push({
        icon: "mi-arrow-forward",
        action: onMovePress,
        data: null,
        label: "Move...",
        allowedRoles: ["admin", "producer"],
      });
    }
    return options;
  }, [
    bidVersion.model,
    bid.model,
    bidVersion.model,
    selectedShotsData,
    projectModel.status,
    state.selectedShotsIds,
  ]);

  const manageDropdownMenu =
    isRecentBidVersion && !isHistorical ? (
      <DropdownMenu
        buttons={filterAllowedRoles(user.model, manageDropdownConfig)}
        openButton={manageDropdownButton}
      />
    ) : null;

  const rightLine = (
    <>
      {bidVersionIsUpdating || bidIsUpdating ? (
        <LoadingIndicator className="d-inline-block mb-r" />
      ) : null}
      {manageDropdownMenu}
    </>
  );

  return (
    <PageTemplate
      header={headerTitle}
      rightTopMenu={null}
      rightLine={rightLine}
    >
      <SeoBlock
        title={
          projectModel.name && bidModel.name
            ? `${projectModel.name} - ${bidModel.name}`
            : null
        }
      />
      <BidPageContent
        isRecentBidVersion={isRecentBidVersion}
        isHistorical={isHistorical}
        isPreview={!isRecentBidVersion || isHistorical}
        onSelectShot={onSelectShot}
      />
    </PageTemplate>
  );
};

export default BidPage;
