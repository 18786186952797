import React, { Component } from "react";
import { connect } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import * as appActions from "./actions/app";
import * as userActions from "./actions/user";
import * as tasksTypesActions from "./actions/tasksTypes";
import { history } from "./configureStore";

import AxiosConfig from "./AxiosConfig";

import MainRoutesComponent from "./MainRoutesComponent";
import ModalRoutesComponent from "./ModalRoutesComponent";
import * as statusesActions from "./actions/statuses";

class RouterClass extends Component {
  /**
   *
   * @param props
   */
  constructor(props) {
    super(props);

    this.state = {};

    if (this.props.user.token) {
      AxiosConfig.setAuthToken(this.props.user.token);
    }
    this.props.clearErrors();

    this.checkSessionInterval = null;
  }

  /**
   *
   */
  componentDidMount() {
    this.checkSession();
    this.checkSessionInterval = setInterval(this.checkSession, 3600000);
    this.fetchTasksTypesAndStatuses();
  }

  /**
   *
   * @param nextProps
   * @param nextState
   * @param nextContext
   * @return {boolean}
   */
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    // fix /profile-settings being unMounted on update action
    // there is no use for the user.edit prop in this component
    if (this.props.user.edit !== nextProps.user.edit) {
      return false;
    }
    return true;
  }

  /**
   *
   * @param prevProps
   */
  componentDidUpdate(prevProps) {
    if (
      prevProps.user.loginSuccess === false &&
      this.props.user.loginSuccess === true
    ) {
      this.fetchTasksTypesAndStatuses();
    }
  }

  /**
   *
   * @returns {Promise<void>}
   */
  checkSession = async () => {
    if (this.props.user.token) {
      const isVerified = this.props.user.model
        ? this.props.user.model.verified * 1
        : 0;

      await this.props.checkSession();
      if (!this.props.user.checkSessionSuccess && this.props.user.model) {
        if (this.props.user.isPasswordResetRequired) {
          alert(
            "Your session and password has expired. Please reset your password and log in again."
          );
          this.props.logout();
          history.push("/password-reminder");
        } else {
          alert("Your session has expired. Please log in again");
          this.props.logout();
        }
      } else if (
        this.props.user.checkSessionSuccess &&
        !isVerified &&
        this.props.user.model.verified * 1
      ) {
        history.push("/");
      }
    }
  };

  /**
   *
   */
  fetchTasksTypesAndStatuses = () => {
    if (this.props.user.token && this.props.user.model) {
      this.props.fetchTasksTypes();
      this.props.fetchStatuses();
    }
  };

  /**
   *
   * @returns {*}
   */
  render() {
    const modalLinks = [
      "/client/edit/:id",
      "/user/:id",
      "/profile-settings",
      "/project/:id/create-shot",
      "/project/edit/:id",
      "/project-bid/edit/:id",
      "/project/:projectId/artist_tasks/:artistId",
      "/project/:id/shot/:shotId/task/:taskId",
      "/project/:id/shot/:shotId/update/:shotUpdateId",
      "/project/:projectId/progress",
      "/bid/:bidId/create-bid-version",
      "/bid/:bidId/reject",
      "/bid/:bidId/bid-versions",
      "/bid/:bidId/move-shots",
      "/bid/:bidId/copy-shots-data",
      "/project/:bidId/copy-shots-data",
      "/vfx-task/:taskTypeId",
      "/invoice/:bidId/edit/:invoiceId",
      "/invoice/:bidId/deposit-invoice/:mode",
      "/status/edit/:type/:statusId",
      "/bids/share/:id",
      "/bid/:bidId/csv-import",
      "/platform-settings/additional-costs/:additionalCostId",
    ];
    const prevModalLocation = [
      { "/client/edit/:id": "/clients" },
      { "/user/:id": "/" },
      { "/profile-settings": "/" },
      { "/project/:id/create-shot": "/projects/1" },
      { "/project/edit/:id": "/projects/1" },
      { "/project-bid/edit/:id": "/bids/1" },
      { "/project/:projectId/artist_tasks/:artistId": "/projects/1" },
      { "/project/:id/shot/:shotId/task/:taskId": "/projects/1" },
      { "/project/:id/shot/:shotId/update/:shotUpdateId": "/projects/1" },
      { "/project/:projectId/progress": "/projects/1" },
      { "/bid/:bidId/create-bid-version": "/bids/1" },
      { "/bid/:bidId/reject": "/bids/1" },
      { "/bid/:bidId/bid-versions": "/bids/1" },
      { "/bid/:bidId/move-shots": "/bids/1" },
      { "/bid/:bidId/copy-shots-data": "/bids/1" },
      { "/project/:bidId/copy-shots-data": "/projects/1" },
      { "/vfx-task/:taskTypeId": "/platform-settings" },
      { "/invoice/:bidId/edit/:invoiceId": "/invoices/1" },
      { "/invoice/:bidId/deposit-invoice/:mode": "/invoices/1" },
      { "/status/edit/:type/:statusId": "/platform-settings" },
      { "/bids/share/:id": "/bids/1" },
      { "/bid/:bidId/csv-import": "/bids/1" },
      {
        "/platform-settings/additional-costs/:additionalCostId":
          "/platform-settings",
      },
    ];

    return (
      <ConnectedRouter history={history}>
        <MainRoutesComponent
          key={this.props.user.model ? this.props.user.model.id : "none"}
          modalLinks={modalLinks}
        />
        <ModalRoutesComponent
          modalLinks={modalLinks}
          prevModalLocation={prevModalLocation}
        />
      </ConnectedRouter>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  router: state.router,
});

const mapDispatchToProps = (dispatch) => ({
  clearErrors: () => dispatch(appActions.clearErrors()),
  checkSession: (isLogged) => dispatch(userActions.checkSession(isLogged)),
  fetchTasksTypes: () => dispatch(tasksTypesActions.fetchTasksTypes()),
  fetchStatuses: () => dispatch(statusesActions.fetchStatuses()),
  logout: () => dispatch(userActions.logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RouterClass);
