import React, { Component, useEffect, useState } from "react";
import { history } from "../configureStore";
import { withRouter } from "react-router-dom";
import Validator from "validatorjs";

import ModalTemplate from "./ModalTemplate";

import * as bidActions from "../actions/bid";
import * as bidVersionActions from "../actions/bidVersion";
import connect from "react-redux/es/connect/connect";
import { store } from "../configureStore";
import LoadingIndicator from "../components/LoadingIndicator";
import ApiError from "../components/ApiError";
import FormValidationError from "../components/FormValidationError";
import PreviousVersions from "./BidVersionEditModal/PreviousVersions";

const BidVersionEditModal = (props) => {
  const [state, setState] = useState({
    formValidationErrors: null,
    isFooterNotesNeeded: null,
    status: null,
  });

  /**
   *
   * @returns {Promise<void>}
   */
  useEffect(() => {
    if (props.bidVersion.model === null) {
      getBid();
      setTimeout(window.updateJQuery, 100);
    }
  }, []);

  useEffect(() => {
    if (props.bidVersion.model) {
      handleUpdateForm();
    }
  }, [props.bidVersion.model]);
  /**
   *
   * @returns {Promise<void>}
   */
  const getBid = async () => {
    if (
      props.user.token &&
      props.user.model &&
      props.match.params.bidVersionId &&
      !props.bidVersion.fetch
    ) {
      await props.fetchBidVersion(props.match.params.bidVersionId);
      const bidVersion = store.getState().bidVersion;
      if (bidVersion && bidVersion.model) {
        await props.fetchBid(bidVersion.model.bid_id);
      }
    }
  };

  /**
   *
   */
  const handleUpdateForm = () => {
    if (props.bidVersion.model !== null) {
      const { create_order, version_name } = props.bidVersion.model;

      document.querySelector(".js-bid-version-create-order").value =
        create_order + 1;

      document.querySelector(".js-bid-version-version-name").value =
        version_name;

      if (
        props.bidVersion.model.footer_notes &&
        state.isFooterNotesNeeded === null
      ) {
        setState({
          ...state,
          isFooterNotesNeeded: !!props.bidVersion.model.footer_notes,
        });
      }
    }
  };

  /**
   *
   * @param event
   * @returns {Promise<void>}
   */
  const handleSubmit = async (event) => {
    event.preventDefault();

    const {
      bidVersion,
      bid,
      bidVersionUpdate,
      editBid,
      location: { state: locationState },
    } = props;

    const { status } = bid.model;

    const data = {
      version_name: document.querySelector(".js-bid-version-version-name")
        .value,
      status: status, //document.querySelector(".js-bid-version-status").value
    };
    if (document.querySelector(".js-bid-version-footer-notes")) {
      data.footer_notes = document.querySelector(
        ".js-bid-version-footer-notes"
      ).value;
    }
    const rules = {
      version_name: "required",
    };
    const validation = new Validator(data, rules);

    setState({ ...state, formValidationErrors: validation.errors.all() });

    if (validation.passes()) {
      const costDiscountRushObj = locationState
        ? locationState
        : generateCostDiscountRushObj();
      let bidVersionUpdateData = {
        bid_id: bid.model.id,
        version_name: data.version_name,
        ...costDiscountRushObj,
      };

      if (state.isFooterNotesNeeded) {
        bidVersionUpdateData.footer_notes = data.footer_notes;
      }

      await bidVersionUpdate(bidVersionUpdateData);
      await editBid(bid.model.id, {
        status: data.status,
      });
      if (bidVersion.updateError === null && bid.editError === null) {
        history.push("/bid/" + props.bidVersion.model.id);
      }
    }
  };

  /**
   *
   * @returns {{}}
   */
  const generateCostDiscountRushObj = () => {
    const bidVersion = props.bidVersion.model;
    let result = {};

    result.bid_versions_additional_costs =
      bidVersion.bid_versions_additional_costs.map((i) => ({
        rate: i.rate,
        days: i.days,
        additional_costs_setting_id: i.additional_costs_setting.id,
      }));

    result.discount = bidVersion.discount;
    result.rush = bidVersion.rush;

    return result;
  };

  /**
   *
   * @param event
   */
  const onFooterNotesCheckboxChange = (event) => {
    setState({ ...state, isFooterNotesNeeded: event.target.checked });
  };

  const { formValidationErrors } = state;
  const { bidVersion, bid } = props;
  const bidVersionModel = bidVersion.model;
  const isLoading =
    bidVersion.fetch ||
    bid.fetch ||
    bidVersion.create ||
    bid.edit ||
    bidVersion.update;
  const loadingIndicator = isLoading ? <LoadingIndicator /> : null;
  const modalTitle =
    bid.model && bid.model.id
      ? `Create New Version of ${bid.model.project.name} - ${bid.model.name}`
      : "";

  return (
    <ModalTemplate
      title={modalTitle}
      onClose={props.onClose}
      confirmButtonLabel="Create new version"
      onConfirm={handleSubmit}
      cancelButtonLabel="Cancel"
      onCancel={props.onClose}
      loadingIndicator={loadingIndicator}
      disableConfirmButton={isLoading}
    >
      <form noValidate>
        <div className="row">
          <div className="form-group col-6">
            <label className="form-label">Name of the version</label>
            <input
              type="text"
              className="form-control js-bid-version-version-name"
              placeholder="Name of the version"
            />
          </div>

          <div className="form-group col-6">
            <label className="form-label">Version number</label>
            <input
              type="text"
              className="form-control js-bid-version-create-order"
              placeholder="Version number"
              disabled={true}
            />
          </div>
        </div>

        <div className="row">
          <div className="form-group col-12">
            <div className="form-check">
              <label className="form-check-label">
                <input
                  type="checkbox"
                  className="form-check-input-styled"
                  checked={state.isFooterNotesNeeded || false}
                  onChange={onFooterNotesCheckboxChange}
                  disabled={isLoading}
                  data-fouc
                />
                Add footer note
              </label>
            </div>
          </div>

          {state.isFooterNotesNeeded ? (
            <div className="form-group col-12">
              <label className="form-label">Footer notes</label>
              <textarea
                className="form-control js-bid-version-footer-notes h-100"
                placeholder="Footer notes"
                defaultValue={
                  bidVersionModel.footer_notes
                    ? bidVersionModel.footer_notes
                    : ""
                }
                disabled={isLoading}
                rows={6}
              />
            </div>
          ) : null}
        </div>

        <div className="row mt-4">
          <div className="form-group col-12">
            <PreviousVersions />
          </div>
        </div>

        <FormValidationError errors={formValidationErrors} />
        <ApiError
          error={
            bidVersion.fetchError ||
            bid.fetchError ||
            bidVersion.createError ||
            bid.editError
          }
        />
      </form>
    </ModalTemplate>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  bid: state.bid,
  bidVersion: state.bidVersion,
});

const mapDispatchToProps = (dispatch) => ({
  bidVersionUpdate: async (data, id) =>
    await dispatch(bidVersionActions.bidVersionUpdate(data, id)),
  editBid: async (id, data) => await dispatch(bidActions.editBid(id, data)),
  fetchBid: async (id) => await dispatch(bidActions.fetchBid(id)),
  fetchBidVersion: async (id) =>
    await dispatch(bidVersionActions.fetchBidVersion(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BidVersionEditModal));
