import React, {useCallback} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Radio = (props) => {
  const {
    className,
    inputClassName,
    disabled,
    defaultChecked,
    checked,
    onChange,
  } = props;

  /**
   *
   * @param event
   */
  const onCheckboxChange = useCallback((event) => {
    if (onChange) {
      onChange(event);
    }
  }, [onChange]);

  const checkboxClassName = classNames("form-check", className);
  const inputCN = classNames("form-check-input-styled", inputClassName);

  return (
    <div className={checkboxClassName}>
      <label className="form-check-label">
        <input
          type="radio"
          className={inputCN}
          data-fouc
          onChange={onCheckboxChange}
          checked={checked}
          defaultChecked={defaultChecked}
          disabled={disabled}
          name={props.name}
          value={props.value}
        /> {props.children}
      </label>
    </div>
  );
};

Radio.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  inputClassName: PropTypes.string,
  disabled: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Radio;
