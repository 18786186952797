import React, { Component } from "react";
import { history } from "../configureStore";
import { withRouter } from "react-router-dom";
import Validator from "validatorjs";

import ModalTemplate from "./ModalTemplate";

import * as bidActions from "../actions/bid";
import * as bidVersionActions from "../actions/bidVersion";
import connect from "react-redux/es/connect/connect";
import { store } from "../configureStore";
import BidVersionName from "../components/BidVersionName";
import LoadingIndicator from "../components/LoadingIndicator";
import ApiError from "../components/ApiError";
import FormValidationError from "../components/FormValidationError";

class BidRejectModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formValidationErrors: null,
      status: null,
    };
  }

  /**
   *
   * @returns {Promise<void>}
   */
  async componentDidMount() {
    if (this.props.bidVersion.model === null) {
      await this.getBid();
    }
  }

  /**
   *
   * @returns {Promise<void>}
   */
  async getBid() {
    if (
      this.props.user.token &&
      this.props.user.model &&
      this.props.match.params.bidVersionId &&
      !this.props.bidVersion.fetch
    ) {
      await this.props.fetchBidVersion(this.props.match.params.bidVersionId);
      const bidVersion = store.getState().bidVersion;
      if (bidVersion && bidVersion.model) {
        await this.props.fetchBid(bidVersion.model.bid_id);
      }
    }
  }

  /**
   *
   * @param event
   * @returns {Promise<void>}
   */
  handleSubmit = async (event) => {
    event.preventDefault();

    const { bidVersion, bidVersionUpdate } = this.props;
    const bidVersionModel = {
      ...bidVersion.model,
      bid_versions_additional_costs:
        bidVersion.model.bid_versions_additional_costs.map((item) => ({
          ...item,
          additional_costs_setting_id: item.additional_costs_setting.id,
        })),
    };
    delete bidVersionModel.is_approved;

    const rejectedBidUpdateData = {
      is_rejected: 1,
      rejection_notes: document.querySelector(".js-bid-reject-notes").value,
    };
    const rules = {};
    const validation = new Validator(rejectedBidUpdateData, rules);

    this.setState({ formValidationErrors: validation.errors.all() });

    if (validation.passes()) {
      await bidVersionUpdate(rejectedBidUpdateData, bidVersionModel.id);
      await bidVersionUpdate({
        ...bidVersionModel,
        is_rejected: 0,
        rejection_notes: null,
      });
      if (bidVersion.updateError === null) {
        history.push("/bid/" + this.props.bidVersion.model.id);
      }
    }
  };

  /**
   *
   * @returns {XML}
   */
  render() {
    const { formValidationErrors } = this.state;
    const { bidVersion } = this.props;
    const isLoading =
      bidVersion.fetch || bidVersion.create || bidVersion.update;
    const loadingIndicator = isLoading ? <LoadingIndicator /> : null;
    const bidVersionName = <BidVersionName bidVersion={bidVersion.model} />;
    const modalTitle = bidVersion.model ? (
      <>Reject bid version {bidVersionName}</>
    ) : (
      ""
    );

    return (
      <ModalTemplate
        title={modalTitle}
        onClose={this.props.onClose}
        confirmButtonLabel="Reject bid version"
        onConfirm={this.handleSubmit}
        cancelButtonLabel="Cancel"
        onCancel={this.props.onClose}
        loadingIndicator={loadingIndicator}
        disableConfirmButton={isLoading}
      >
        <form noValidate>
          <p className="mb-3">
            You are about to mark this version of the bid as rejected. Fill in
            the rejection notes for further reference. A new version of the bid
            will be created.
          </p>

          <div className="row">
            <div className="form-group col-12">
              <label className="form-label">Rejection notes</label>
              <input
                type="text"
                className="form-control js-bid-reject-notes"
                placeholder="Rejection notes"
                disabled={isLoading}
              />
            </div>
          </div>

          <FormValidationError errors={formValidationErrors} />
          <ApiError
            error={
              bidVersion.fetchError ||
              bidVersion.createError ||
              bidVersion.updateError
            }
          />
        </form>
      </ModalTemplate>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  bidVersion: state.bidVersion,
});

const mapDispatchToProps = (dispatch) => ({
  bidVersionUpdate: async (data, id) =>
    await dispatch(bidVersionActions.bidVersionUpdate(data, id)),
  fetchBid: async (id) => await dispatch(bidActions.fetchBid(id)),
  fetchBidVersion: async (id) =>
    await dispatch(bidVersionActions.fetchBidVersion(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BidRejectModal));
