import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import DashboardStatusRow from "./DashboardStatusRow";
import { typeOptions } from "../../../consts/statusesTypes";

const StatusRows = [
  {
    title: "Show in list",
    type: "is_list",
  },
  {
    title: "Show in quick stats",
    type: "is_quick_stats",
  },
  {
    title: "Show in chart",
    type: "is_chart",
  },
];

const findStatusesDashboard = (statusesDashboard, role) => {
  return statusesDashboard.find((item) => item.role === role) || {};
};

const DashboardStatusSettings = (props) => {
  const { status, onChange, isLoading } = props;
  const { type } = status;

  const statusOptions = useMemo(
    () => typeOptions.find((item) => item.value === type),
    [type]
  );
  const allowedRoles = statusOptions.dashboardAllowedRoles;

  const rolesData = useMemo(() => {
    const statusesDashboard = status.statuses_dashboard || [];
    let rolesData = {
      admin: findStatusesDashboard(statusesDashboard, "admin"),
      producer: findStatusesDashboard(statusesDashboard, "producer"),
      accountant: findStatusesDashboard(statusesDashboard, "accountant"),
      artist: findStatusesDashboard(statusesDashboard, "artist"),
      client: findStatusesDashboard(statusesDashboard, "client"),
    };
    for (let role in rolesData) {
      rolesData[role].isAllowed = allowedRoles.includes(role);
    }
    return rolesData;
  }, [status]);

  const rows = useMemo(
    () =>
      StatusRows.map((item) => {
        const { type, title } = item;

        if (!statusOptions.allowedIn.includes(type)) {
          return null;
        }

        return (
          <DashboardStatusRow
            key={`dashboard-status-row-${type}`}
            rolesData={rolesData}
            title={title}
            type={type}
            onChange={onChange}
            isLoading={isLoading}
          />
        );
      }),
    [status, statusOptions, rolesData, onChange, isLoading]
  );

  const settings = (
    <div className="form-group">
      <label className="form-label">
        Show this status in the following dashboards
      </label>

      <div className="overflow-auto">
        <table className="table">
          <thead>
            <tr>
              <th className="pl-0">Item</th>
              <th>Admin</th>
              <th>Producer</th>
              <th>Accountant</th>
              <th>Artist</th>
              <th>Client</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    </div>
  );

  return allowedRoles && allowedRoles.length ? settings : <div />;
};

DashboardStatusSettings.propTypes = {
  status: PropTypes.object,
  onChange: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default DashboardStatusSettings;
