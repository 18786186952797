import React, { Component, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Notes from "./Notes";
import connect from "react-redux/es/connect/connect";
import HoveredEdit from "./HoveredEdit";
import HasRights from "./HasRights";
import { useTaskNotes } from "../storeHooks/useTaskNotes";
import LoadingIndicator from "./LoadingIndicator";
import { useTasks } from "../storeHooks/useTasks";

const TasksExpandable = (props) => {
  const [state, setState] = useState({
    notesInputLastValue: "",
  });

  const [store, setStore] = useState({
    collection: [],
    fetch: false,
    fetchSuccess: false,
    fetchError: null,

    delete: false,
    deleteSuccess: false,
    deleteError: null,

    create: false,
    createSuccess: false,
    createError: null,
  });

  const { fetchTaskNotes, createTaskNote, editTaskNote, deleteTaskNote } = useTaskNotes();
  const { tasks, editTask } = useTasks();

  useEffect(() => {
    setStore({
      ...store,
      fetch: false,
      fetchSuccess: false,
      fetchError: false,
    });

    fetchTaskNotes(props.data.id)
      .then((response) => {
        setStore({
          collection: response.data,
          fetch: false,
          fetchSuccess: false,
          fetchError: null,
        });
      })
      .catch((error) => {
        setStore({
          ...store,
          fetch: false,
          fetchError: error,
        });
      });
  }, []);

  const notesInput = useRef();

  /**
   *
   */
  const handleCreateNote = (taskId) => async (note) => {
    setStore({
      ...store,
      create: true,
      createSuccess: false,
      createError: null,
    });

    createTaskNote(taskId, note)
      .then((response) => {
        const collection = [...store.collection];
        collection.unshift(response.data);

        setStore({
          ...store,
          collection,
          create: false,
          createSuccess: true,
          createError: null,
        });

        setTimeout(window.updateJQuery, 100);
      })
      .catch((error) => {
        setStore({
          ...store,
          create: false,
          createError: error,
        });
      });
  };

  /**
   *
   * @param taskNoteId
   * @param note
   */
  const handleEditNote = async (taskNoteId, note) => {
    setStore({
      ...store,
      edit: true,
      editSuccess: false,
      editError: null,
    });

    editTaskNote(taskNoteId, note)
      .then((response) => {
        const noteIdx = store.collection.findIndex((i) => i.id === taskNoteId);
        const collection = [...store.collection];
        collection.splice(noteIdx, 1, response.data);

        setStore({
          ...store,
          collection,
          edit: false,
          editSuccess: true,
          editError: null,
        });

        setTimeout(window.updateJQuery, 100);
      })
      .catch((error) => {
        setStore({
          ...store,
          edit: false,
          editError: error,
        });
      });
  };

  /**
   *
   * @param taskNoteId
   */
  const handleDeleteNote = async (taskNoteId) => {

    setStore({
      ...store,
      delete: true,
      deleteSuccess: false,
      deleteError: null,
    });

    deleteTaskNote(taskNoteId)
      .then((response) => {
        const noteIdx = store.collection.findIndex((i) => i.id === taskNoteId);
        const collection = [...store.collection];
        collection.splice(noteIdx, 1);

        setStore({
          ...store,
          collection,
          delete: false,
          deleteSuccess: true,
          deleteError: null,
        });

        setTimeout(window.updateJQuery, 100);
      })
      .catch((error) => {
        setStore({
          ...store,
          delete: false,
          deleteError: error,
        });
      });
  };

  /**
   *
   */
  const onNotesChange = async () => {
    if (notesInput.current.value) {
      setState({ ...state, notesInputLastValue: notesInput.current.value });
      if (typeof props.onEditTask === "function") {
        props.onEditTask(props.data);
      }
      await editTask(props.data.id, {
        notes: notesInput.current.value,
      });
      resetLastValues();
    }
  };

  /**
   *
   */
  const resetLastValues = () => {
    setState({
      ...state,
      notesInputLastValue: "",
    });
  };

  /**
   *
   */
  const notesHandleFocus = () => {
    notesInput.current.value = props.data.notes;
    notesInput.current.focus();
  };

  const descriptionPlaceholder = <div>{store.collection.length || "--"}</div>;

  const descriptionEdit = (
    <HasRights
      allowedRoles={["admin", "producer"]}
      user={props.user.model}
      placeholder={descriptionPlaceholder}
    >
      <HoveredEdit
        preview={!!props.data.notes ? props.data.notes : "Add description"}
        handleConfirm={onNotesChange}
        handleFocus={notesHandleFocus}
        disabled={tasks.edit}
        className="p-0 hovered-edit_w-full"
      >
        <textarea
          ref={notesInput}
          className="form-control"
          placeholder="Add description"
          rows={5}
        />
      </HoveredEdit>
    </HasRights>
  );

  const notes = store.fetch ? (
    <LoadingIndicator className={"text-white text-center"} />
  ) : (
    <Notes
      notes={store.collection}
      handleCreateNote={handleCreateNote(props.data.id)}
      handleEditNote={handleEditNote}
      handleDeleteNote={handleDeleteNote}
      noteEdit={props.shot.taskNoteEdit}
      noteCreate={store.create}
      noteDelete={store.delete}
      noteEditError={props.shot.taskNoteEditError}
      noteCreateError={props.shot.taskNoteCreateError}
      noteDeleteError={store.deleteError}
      notesClassNames={{
        className: "border-left-primary rounded-left-0",
      }}
    />
  );

  return (
    <div className="p-2">
      <div className="row">
        <div className="col-6">
          <div className="card bg-dark">
            <div className="card-body">
              <div>
                <div className="form-group mb-0">
                  <label className="font-weight-semibold">Description</label>
                  {descriptionEdit}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6">{notes}</div>
      </div>
    </div>
  );
};

TasksExpandable.propTypes = {
  data: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  shot: state.shot,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TasksExpandable);
