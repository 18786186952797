/**
 * Created by piotr.pozniak@thebeaverhead.com on 17/05/2022
 */

import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import Select from "./Select";
import CustomerSelect from "./Selects/CustomerSelect";
import UserSelect from "./Selects/UserSelect";
import ProjectSelect from "./Selects/ProjectSelect";

const Filters = (props) => {
  const { filtersClassName, filterSelectorHtmlClass } = props;

  /**
   *
   */
  const onFilter = useCallback(
    (e) => {
      // prevent form to be submitted
      e.preventDefault();

      const filter = [];
      document
        .querySelectorAll("." + filterSelectorHtmlClass + " select")
        .forEach((item) => {
          if (item.value) {
            filter.push({ fieldName: item.name, value: item.value });
          }
        });
      document
        .querySelectorAll("." + filterSelectorHtmlClass + " input")
        .forEach((item) => {
          if (item.value) {
            filter.push({ fieldName: item.name, value: item.value });
          }
        });

      if (typeof props.onFilter === "function") {
        props.onFilter(filter);
      }
    },
    [props.onFilter]
  );

  /**
   *
   * @param event
   */
  const onFilterFormSubmit = useCallback((event) => {
    event.preventDefault();
    onFilter();
  }, []);

  /**
   *
   */
  const onClearAll = useCallback(() => {
    setTimeout(() => {
      window.updateJQuery(() => {
        if (typeof props.onClearAll === "function") {
          props.onClearAll();
        }
      });
    }, 100);
  }, [props.onClearAll]);

  const filtersContent = useMemo(() => {
    return (
      <div className={filtersClassName}>
        <form
          className={"clearfix " + filterSelectorHtmlClass}
          onSubmit={onFilterFormSubmit}
        >
          <div className="row">
            {props.filters
              .filter((i) => i.type !== "dateRange")
              .map((item) => {
                return (
                  <div className="col-sm-4 mb-3" key={item.fieldName}>
                    {item.placeholder && (
                      <label className="form-label">{item.placeholder}</label>
                    )}
                    {item.tooltipMessage && (
                      <i
                        className="icon-question4 ml-2 fs-14"
                        data-popup="tooltip"
                        data-original-title={item.tooltipMessage}
                      />
                    )}
                    {item.type === "select" ? (
                      <Select
                        key={`ff-text-${item.fieldName}`}
                        data={[{ value: "", label: "-" }, ...item.data]}
                        initClassName="js-select2"
                        name={item.fieldName}
                        value={item.value}
                      />
                    ) : null}
                    {item.type === "text" ? (
                      <input
                        type="text"
                        className="form-control"
                        placeholder={item.placeholder}
                        defaultValue={item.defaultValue}
                        name={item.fieldName}
                        autoComplete="off"
                      />
                    ) : null}
                    {item.type === "customerSelect" ? (
                      <CustomerSelect
                        onUpdate={item.onUpdate}
                        reactSelectProps={item.reactSelectProps}
                      />
                    ) : null}
                    {item.type === "userSelect" ? (
                      <UserSelect
                        onUpdate={item.onUpdate}
                        reactSelectProps={item.reactSelectProps}
                        addEmptyItem={item.addEmptyItem}
                        emptyItemLabel={item.emptyItemLabel}
                        emptyItemValue={item.emptyItemValue}
                      />
                    ) : null}

                    {item.type === "projectSelect" ? (
                      <ProjectSelect
                        onUpdate={item.onUpdate}
                        reactSelectProps={item.reactSelectProps}
                      />
                    ) : null}
                  </div>
                );
              })}
            {props.filters
              .filter((i) => i.type === "dateRange")
              .map((item) => {
                return (
                  <React.Fragment key={item.fieldName}>
                    <div className="col-sm-4 mb-3">
                      <label className="form-label">
                        {item.placeholderFrom}
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder={item.placeholderFrom}
                        defaultValue={item.defaultValueFrom}
                        name={item.fieldNameFrom}
                        autoComplete="off"
                      />
                    </div>
                    <div className="col-sm-4 mb-3">
                      <label className="form-label">{item.placeholderTo}</label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder={item.placeholderTo}
                        defaultValue={item.defaultValueTo}
                        name={item.fieldNameTo}
                        autoComplete="off"
                      />
                    </div>
                  </React.Fragment>
                );
              })}
          </div>
          <button
            type="submit"
            className="btn btn-primary float-right"
            onClick={onFilter}
          >
            Filter
          </button>
          <button
            type="reset"
            className="btn btn-warning mr-2 float-right"
            onClick={onClearAll}
          >
            Clear all
          </button>
        </form>
      </div>
    );
  }, [props.filters]);

  return filtersContent;
};

Filters.defaultProps = {
  filters: [],
};

Filters.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      placeholder: PropTypes.string,
      fieldName: PropTypes.string.isRequired,
      type: PropTypes.oneOf([
        "select",
        "dateRange",
        "customerSelect",
        "text",
        "userSelect",
        "projectSelect",
      ]).isRequired,
      onUpdate: PropTypes.func,
      reactSelectProps: PropTypes.shape,
      allowedRoles: PropTypes.array,
    })
  ),
  onFilter: PropTypes.func.isRequired,
  onClearAll: PropTypes.func,
  filterSelectorHtmlClass: PropTypes.string,
  filtersClassName: PropTypes.string,
};

export default Filters;
