import React, {createRef} from "react";
import connect from "react-redux/es/connect/connect";
import PageTemplate from "./PageTemplate";
import ClientsEditForm from "../modals/ClientsEditForm";
import * as clientActions from "../actions/client";
import {getClientId} from "../actions/client";
import LoadingIndicator from "../components/LoadingIndicator";


class ClientSettingsPage extends React.Component {
  constructor(props) {
    super(props);
    this.child = createRef();
  }

  /**
   *@param event
   */
  handleSubmit = event => {
    this.child.current.handleSubmit(event);
  };


  /**
   *
   */
  async componentDidMount() {
    await this.props.getClientId();
    if (this.props.client && this.props.client.clientId) {
      await this.props.fetchClient(this.props.client.clientId);
    }
  }

  render() {
    const {client} = this.props;
    const isLoading = client.fetch || client.edit || !client.model;
    const loadingIndicator = isLoading ? <LoadingIndicator/> : <span/>;

    return <PageTemplate header="Client Settings">
      <div className="row">
        <div className="col-lg-6">
          <div className='card'>
            <div className="modal-header">
              <h6 className="modal-title">Client information</h6>
            </div>
            <div className="card-body">
              <ClientsEditForm ref={this.child} {...this.props}/>
              <div className="d-flex justify-content-between align-items-center">
                {loadingIndicator}
                <button
                  type="button"
                  className="btn bg-primary"
                  onClick={this.handleSubmit}
                  disabled={isLoading}
                >Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageTemplate>
  }
}


const mapStateToProps = state => ({
  user: state.user,
  client: state.client
});

const mapDispatchToProps = dispatch => ({
  editClient: async (id, data) => await dispatch(clientActions.editClient(id, data)),
  fetchClient: async (id) => await dispatch(clientActions.fetchClient(id)),
  getClientId: () => dispatch(getClientId()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientSettingsPage);
