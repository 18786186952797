export const initialState = {
  fetch: false,
  fetchSuccess: false,
  fetchError: null,

  delete: false,
  deleteSuccess: false,
  deleteError: null,

  create: false,
  createSuccess: false,
  createError: null,

  edit: false,
  editSuccess: false,
  editError: null,

  collection: [],
  pagination: null,
};

export default function reducer(
  state = {
    ...initialState,
  },
  action
) {
  switch (action.type) {
    case "ADDITIONAL_COSTS_FETCH":
      return {
        ...state,
        fetch: true,
        fetchSuccess: false,
        fetchError: null,
      };

    case "ADDITIONAL_COSTS_FETCH_FULFILLED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: true,

        collection: action.data.additional_costs,
        pagination: action.data.pagination,
      };

    case "ADDITIONAL_COSTS_FETCH_REJECTED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: false,
        fetchError: action.data,
      };

    case "ADDITIONAL_COST_DELETE":
      return {
        ...state,
        delete: true,
        deleteSuccess: false,
        deleteError: null,
      };

    case "ADDITIONAL_COST_DELETE_FULFILLED":
      const afterDeleteCollection = [...state.collection];
      afterDeleteCollection.splice(
        state.collection.findIndex((i) => i.id === action.data.id),
        1
      );
      return {
        ...state,
        delete: false,
        deleteSuccess: true,

        collection: afterDeleteCollection,
      };

    case "ADDITIONAL_COST_DELETE_REJECTED":
      return {
        ...state,
        delete: false,
        deleteSuccess: false,
        deleteError: action.data,
      };

    case "ADDITIONAL_COST_CREATE":
      return {
        ...state,
        create: true,
        createSuccess: false,
        createError: null,
      };

    case "ADDITIONAL_COST_CREATE_FULFILLED":
      const afterCreateCollection = [...state.collection];
      afterCreateCollection.push(action.data);

      return {
        ...state,
        create: false,
        createSuccess: true,

        collection: afterCreateCollection,
      };

    case "ADDITIONAL_COST_CREATE_REJECTED":
      return {
        ...state,
        create: false,
        createSuccess: false,
        createError: action.data,
      };

    case "ADDITIONAL_COST_EDIT":
      return {
        ...state,
        edit: true,
        editSuccess: false,
        editError: null,
      };

    case "ADDITIONAL_COST_EDIT_FULFILLED":
      const afterEditCollection = [...state.collection];
      afterEditCollection.splice(
        state.collection.findIndex((i) => i.id === action.data.id),
        1,
        action.data
      );

      return {
        ...state,
        edit: false,
        editSuccess: true,

        collection: afterEditCollection,
      };

    case "ADDITIONAL_COST_EDIT_REJECTED":
      return {
        ...state,
        edit: false,
        editSuccess: false,
        editError: action.data,
      };

    /*

    case "BID_CREATE_FULFILLED":
      return {
        ...state,
        collection: [...state.collection, action.data],
      };

    case "BID_EDIT_FULFILLED":
      return {
        ...state,
        collection: state.collection.map((bid) =>
          bid.id === action.data.id
            ? {
                ...action.data,
                project: action.data.project
                  ? action.data.project
                  : { ...bid.project },
              }
            : bid
        ),
      };

    // case "BID_UPDATE_FULFILLED":
    //   let isUpdate = false;
    //   let updatedCollection = state.collection.map((value) => {
    //     let isEqual = value.id === action.data.id;
    //     if (isEqual) {
    //       isUpdate = true;
    //     }
    //     return isEqual ? action.data : { ...value };
    //   });
    //   let isCreate = !isUpdate;
    //   if (isCreate && updatedCollection) {
    //     updatedCollection.unshift(action.data);
    //   }
    //   return {
    //     ...state,
    //     collection: updatedCollection,
    //   };

    case "BID_DELETE":
      return {
        ...state,
        delete: true,
        deleteSuccess: false,
        deleteError: null,
      };

    case "BID_DELETE_FULFILLED":
      let isDeleted = !!action.data.responseData.success;
      let collectionAfterDeletion = isDeleted
        ? state.collection.filter((value) => value.id !== action.data.id)
        : state.collection;
      return {
        ...state,
        delete: false,
        deleteSuccess: isDeleted,

        collection: collectionAfterDeletion,
      };

    case "BID_DELETE_REJECTED":
      return {
        ...state,
        delete: false,
        deleteSuccess: false,
        deleteError: action.data,
      };

    // case "PROJECT_EDIT_FULFILLED":
    //   const editedProject = action.data;
    //   return {
    //     ...state,
    //     collection: state.collection.map((bid) => {
    //       if (editedProject.id === bid.project_id) {
    //         return {
    //           ...bid,
    //           project: editedProject,
    //         };
    //       }
    //       return bid;
    //     }),
    //   };

    // case "PROJECT_CREATE_FULFILLED":
    //   return {
    //     ...state,
    //     collection: [
    //       ...state.collection,
    //       {
    //         project: action.data,
    //         ...action.data.bid,
    //       },
    //     ],
    //   };
*/

    case "CLEAR_ERRORS":
      return {
        ...initialState,
        collection: state.collection,
        pagination: state.pagination,
      };

    case "LOGOUT":
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
