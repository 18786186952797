import React from "react";
import PropTypes from "prop-types";

const ShotDescription = (props) => {
  const { shot } = props;

  return shot && shot.current_version && shot.current_version.description ? (
    <p className="mb-3">
      <b>Description: </b>
      {shot.current_version.description}
    </p>
  ) : (
    <div />
  );
};

ShotDescription.propTypes = {
  shot: PropTypes.object,
};

export default ShotDescription;
