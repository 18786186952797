/**
 * Created by piotr.pozniak@thebeaverhead.com on 18/05/2022
 */

const sortShotsByStatusOrder = (data, statuses) => {
  const shotStatuses = statuses.collection.filter((i) => i.type === "Shots");

  return data.map((i) => {
    const sortedShotsData = {};
    shotStatuses.map((status) => {
      if (i.shots[status.value]) {
        sortedShotsData[status.value] = i.shots[status.value];
      }
    });

    i.shots = sortedShotsData;
    return i;
  });
};

const WorkloadDashboardMiddleware = (storeAPI) => (next) => (action) => {
  const statuses = storeAPI.getState().statuses;

  switch (action.type) {
    case "ARTISTS_STATISTICS_FETCH_FULFILLED":
      action.data.artists =
        action.data.artists && action.data.artists.length
          ? sortShotsByStatusOrder(action.data.artists, statuses)
          : [];

      break;

    case "PROJECT_STATISTICS_FETCH_FULFILLED":
      action.data.projects =
        action.data.projects && action.data.projects.length
          ? sortShotsByStatusOrder(action.data.projects, statuses)
          : [];

      break;
  }

  next(action);
};

export default WorkloadDashboardMiddleware;
