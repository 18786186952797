/**
 * Created by piotr.pozniak@thebeaverhead.com on 24/06/2022
 */

import React, { useCallback } from "react";
import PropTypes from "prop-types";
import Select from "../Select";
import CustomerSelect from "../Selects/CustomerSelect";
import UserSelect from "../Selects/UserSelect";
import classNames from "classnames";

const Filters = (props) => {
  /**
   *
   */
  const onFilter = () => {
    const filter = [];

    document
      .querySelectorAll("." + props.filterSelectorHtmlClass + " select")
      .forEach((item) => {
        if (item.value) {
          filter.push({ fieldName: item.name, value: item.value });
        }
      });
    document
      .querySelectorAll("." + props.filterSelectorHtmlClass + " input")
      .forEach((item) => {
        if (item.value) {
          filter.push({ fieldName: item.name, value: item.value });
        }
      });

    if (typeof props.onFilter === "function") {
      props.onFilter(filter);
    }
  };

  /**
   *
   * @param event
   */
  const onFilterFormSubmit = useCallback((event) => {
    event.preventDefault();
    onFilter();
  }, []);

  /**
   *
   */
  const onClearAll = () => {
    setTimeout(() => {
      window.updateJQuery(() => {
        if (typeof props.onClearAll === "function") {
          props.onClearAll();
        }
      });
    }, 100);
  };

  const filtersClassName = classNames(
    "card card-body mt-3 mb-n1px",
    props.wrapperClassName
  );

  return (
    <div className={filtersClassName}>
      <form
        className={"clearfix " + props.filterSelectorHtmlClass}
        onSubmit={onFilterFormSubmit}
      >
        <div className="row">
          {props.filters
            .filter((i) => i.type !== "dateRange")
            .map((item) => {
              return (
                <div className="col-sm-4 mb-3" key={item.fieldName}>
                  {item.placeholder && (
                    <label className="form-label">{item.placeholder}</label>
                  )}
                  {item.tooltipMessage && (
                    <i
                      className="icon-question4 ml-2 fs-14"
                      data-popup="tooltip"
                      data-original-title={item.tooltipMessage}
                    />
                  )}
                  {item.type === "select" ? (
                    <Select
                      data={[{ value: "", label: "-" }, ...item.data]}
                      initClassName="js-select2"
                      name={item.fieldName}
                      defaultValue={item.value}
                    />
                  ) : null}
                  {item.type === "text" ? (
                    <input
                      type="text"
                      className="form-control"
                      placeholder={item.placeholder}
                      defaultValue={item.defaultValue}
                      name={item.fieldName}
                      autoComplete="off"
                    />
                  ) : null}
                  {item.type === "customerSelect" ? (
                    <CustomerSelect
                      onUpdate={item.onUpdate}
                      reactSelectProps={item.reactSelectProps}
                    />
                  ) : null}
                  {item.type === "userSelect" ? (
                    <UserSelect
                      onUpdate={item.onUpdate}
                      reactSelectProps={item.reactSelectProps}
                    />
                  ) : null}
                </div>
              );
            })}
          {props.filters
            .filter((i) => i.type === "dateRange")
            .map((item) => {
              return (
                <React.Fragment key={item.fieldName}>
                  <div className="col-sm-4 mb-3">
                    <label className="form-label">{item.placeholderFrom}</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder={item.placeholderFrom}
                      defaultValue={item.defaultValueFrom}
                      name={item.fieldNameFrom}
                      autoComplete="off"
                    />
                  </div>
                  <div className="col-sm-4 mb-3">
                    <label className="form-label">{item.placeholderTo}</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder={item.placeholderTo}
                      defaultValue={item.defaultValueTo}
                      name={item.fieldNameTo}
                      autoComplete="off"
                    />
                  </div>
                </React.Fragment>
              );
            })}
        </div>
        <button
          type="submit"
          className="btn btn-primary float-right"
          onClick={onFilter}
        >
          Filter
        </button>
        <button
          type="reset"
          className="btn btn-warning mr-2 float-right"
          onClick={onClearAll}
        >
          Clear all
        </button>
      </form>
    </div>
  );
};

Filters.defaultProps = {};

Filters.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      fieldName: PropTypes.string.isRequired,
      placeholder: PropTypes.string,
      reactSelectProps: PropTypes.object,
      data: PropTypes.any,
      tooltipMessage: PropTypes.string,
      value: PropTypes.string,
      defaultValue: PropTypes.string,
      onUpdate: PropTypes.func,

      fieldNameFrom: PropTypes.string,
      fieldNameTo: PropTypes.string,
      placeholderTo: PropTypes.string,
      placeholderFrom: PropTypes.string,
      defaultValueFrom: PropTypes.string,
      defaultValueTo: PropTypes.string,
    })
  ),
  onFilter: PropTypes.func.isRequired,
  onClearAll: PropTypes.func.isRequired,

  wrapperClassName: PropTypes.string,
  filterSelectorHtmlClass: PropTypes.string,
};

export default Filters;
