import { filterArrayUniqueByKey } from "../helpers/tools";
import { typeOptions } from "../consts/statusesTypes";

/**
 *
 * @returns {{}}
 */
const getInitialCollection = () => {
  let collection = {};
  typeOptions.forEach((item) => {
    collection[item.value.toLowerCase()] = {
      collection: [],
      pagination: null,
      maxPageLoaded: 0,
      nextPageExist: true,
    };
  });
  return collection;
};

const updateCollectionStatus = (state, kind, id, status) => {
  // find payload in collections and update its status
  const model = state.collection[kind].collection.find(
    (item) => item.id === id
  );

  if (!model) {
    return state.collection;
  }

  model.status = status;

  return {
    ...state.collection,
  };
};

export const initialState = {
  fetch: false,
  fetchSuccess: false,
  fetchError: null,

  fetchSingle: false,
  fetchSingleSuccess: false,
  fetchSingleError: null,

  fetchQuickStats: false,
  fetchQuickStatsSuccess: false,
  fetchQuickStatsError: null,

  fetchParticularUserChart: false,
  fetchParticularUserChartSuccess: false,
  fetchParticularUserChartError: null,

  collection: getInitialCollection(),
  quickStats: null,
};

export default function reducer(
  state = {
    ...initialState,
  },
  action
) {
  switch (action.type) {
    case "FETCH_COMMON_DASHBOARDS":
      return {
        ...state,
        fetch: true,
        fetchSuccess: false,
        fetchError: null,
      };

    case "FETCH_COMMON_DASHBOARDS_FULFILLED": {
      return {
        ...state,
        fetch: false,
        fetchSuccess: true,
      };
    }

    case "FETCH_COMMON_DASHBOARD":
      return {
        ...state,
        fetchSingle: true,
        fetchSingleSuccess: false,
        fetchSingleError: null,
      };

    case "FETCH_COMMON_DASHBOARD_FULFILLED": {
      const { pagination, ...data } = action.data;
      const collectionType = Object.keys(data)[0];
      const collection = data[collectionType];

      return {
        ...state,
        fetchSingle: false,
        fetchSingleSuccess: true,

        collection: {
          ...state.collection,
          [collectionType]: {
            collection: filterArrayUniqueByKey(
              [...state.collection[collectionType].collection, ...collection],
              "id"
            ),
            pagination: action.data.pagination,
            maxPageLoaded: Math.max(
              action.data.pagination.page,
              state.collection[collectionType].maxPageLoaded
            ),
            nextPageExist: state.collection[collectionType].nextPageExist
              ? action.data.pagination.nextPage
              : false,
          },
        },
      };
    }

    case "FETCH_COMMON_DASHBOARD_REJECTED":
      return {
        ...state,
        fetchSingle: false,
        fetchSingleSuccess: false,
        fetchSingleError: action.data,
      };

    case "FETCH_QUICK_STATS":
      return {
        ...state,
        fetchQuickStats: true,
        fetchQuickStatsSuccess: false,
        fetchQuickStatsError: null,
      };

    case "FETCH_QUICK_STATS_FULFILLED": {
      return {
        ...state,
        fetchQuickStats: false,
        fetchQuickStatsSuccess: true,

        quickStats: action.data,
      };
    }

    case "FETCH_QUICK_STATS_REJECTED":
      return {
        ...state,
        fetchQuickStats: false,
        fetchQuickStatsSuccess: false,
        fetchQuickStatsError: action.data,
      };

    case "FETCH_PARTICULAR_USER_CHART":
      return {
        ...state,
        fetchParticularUserChart: true,
        fetchParticularUserChartSuccess: false,
        fetchParticularUserChartError: null,
      };

    case "FETCH_PARTICULAR_USER_CHART_FULFILLED": {
      return {
        ...state,
        fetchParticularUserChart: false,
        fetchParticularUserChartSuccess: true,

        quickStats: state.quickStats
          ? { ...state.quickStats, chart: action.data.chart }
          : state.quickStats,
      };
    }

    case "FETCH_PARTICULAR_USER_CHART_REJECTED":
      return {
        ...state,
        fetchParticularUserChart: false,
        fetchParticularUserChartSuccess: false,
        fetchParticularUserChartError: action.data,
      };

    case "CLEAR_ERRORS":
      return {
        ...initialState,
        collection: state.collection,
        quickStats: state.quickStats,
      };

    case "SHOT_EDIT_FULFILLED":
      return {
        ...state,
        collection: updateCollectionStatus(
          state,
          "shots",
          action.data.shots[0].id,
          action.data.shots[0].status
        ),
      };

    case "BID_EDIT_FULFILLED":
      return {
        ...state,
        collection: updateCollectionStatus(
          state,
          "bids",
          action.data.id,
          action.data.status
        ),
      };

    case "PROJECT_EDIT_FULFILLED":
      return {
        ...state,
        collection: updateCollectionStatus(
          state,
          "projects",
          action.data.id,
          action.data.status
        ),
      };

    case "TASK_EDIT_FULFILLED":
      return {
        ...state,
        collection: updateCollectionStatus(
          state,
          "tasks",
          action.data.id,
          action.data.status
        ),
      };

    case "SHOT_UPDATE_CREATE_FULFILLED":
      const model = state.collection.shots.collection.find(
        (item) => item.id === action.data.shot_id
      );

      if (!model) {
        return state;
      }
      model.last_shot_update = {
        ...action.data,
      };

      return {
        ...state,
        collection: {
          ...state.collection,
        },
      };

    case "RESET_COMMON_DASHBOARD":
    case "LOGOUT":
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
