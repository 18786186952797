import React, {Component, useCallback, useEffect, useMemo} from "react";
import PropTypes from "prop-types";

const EditableListTemplate = (props) => {
  const isEditMode = !!props.isEditMode;

  useEffect(() => {
    if (!isEditMode) {
      props.onAddItem();
    } else {
      if (typeof props.setEditing === "function") {
        props.setEditing();
      }
    }
  }, []);

  /**
   *
   * @param index
   * @returns {Function}
   */
  const onRemoveBtnPress = useCallback(
    (index) => () => {
      props.onRemoveBtnPress(index);
    },
    []
  );

  const isLoading = props.isLoading;

  const FormLine = props.formLine;

  const formLines = useMemo(
    () =>
      props.items.map((item, index) => {
        const removeBtn =
          props.items.length > 1 ? (
            <div className="row mb-n1">
              <button
                type="button"
                className="btn btn-link"
                onClick={onRemoveBtnPress(index)}
                disabled={isLoading}
              >
                Remove line
              </button>
            </div>
          ) : null;

        return (
          <div key={index}>
            <FormLine
              data={{ item, index }}
              formLineOnUpdate={props.formLineOnUpdate}
              isLoading={isLoading}
              formValidationErrors={props.formValidationErrors}
              apiError={props.apiError}
              isEditMode={props.isEditMode}
              statuses={props.statuses}
              tasksTypes={props.tasksTypes}
              setShotTaskTypes={props.setShotTaskTypes}
            />

            {removeBtn}

            <hr />
          </div>
        );
      }),
    [props.items]
  );

  const addNextTaskBtn = !isEditMode ? (
    <button
      type="button"
      className="btn bg-primary"
      onClick={props.onAddItem}
      disabled={isLoading}
    >
      {props.addItemLabel}
    </button>
  ) : null;

  return (
    <div noValidate>
      {formLines}
      {addNextTaskBtn}
    </div>
  );
};

EditableListTemplate.propTypes = {
  setEditing: PropTypes.func,
  isEditMode: PropTypes.bool.isRequired,
  onAddItem: PropTypes.func.isRequired,
  onRemoveBtnPress: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  formLine: PropTypes.any.isRequired,
  isLoading: PropTypes.bool,
  addItemLabel: PropTypes.string.isRequired,
};

export default EditableListTemplate;
