export const initialState = {
  fetch: {},
  fetchSuccess: {},
  fetchError: {},

  collection: {}
};


//const updateTaskState(id, )




export default function reducer(
  state = {
    ...initialState,
  },
  action
) {
  switch (action.type) {
    /*case "TASK_NOTES_FETCH":

      return {
        ...state,
        fetch: [...state.fetch, ],
        connectionSuccess: false,
        connectionError: null,
      };

    case "RTC_CONNECTION_FULFILLED":
      return {
        ...state,
        connection: false,
        connectionSuccess: true,
      };

    case "RTC_GET_ENTITY_STATE_UPDATE":
      const roomName = action.payload.room.name;
      const data = action.payload.state[Object.keys(action.payload.state)[0]];
      return {
        ...state,
        entitiesStates: action.payload.state
          ? {
              ...state.entitiesStates,
              [roomName]: [...(state.entitiesStates[roomName] || []), data],
            }
          : state.entitiesStates,
      };

    // case "RTC_GET_ROOM_STATE_UPDATE":
    //   return {
    //     ...state,
    //     roomState: action.payload.state
    //       ? Object.keys(action.payload.state).map(
    //           (i) => action.payload.state[i]
    //         )
    //       : [],
    //   };

    case "RTC_CONNECTION_DISCONNECTED":
    case "RTC_DISCONNECT":
    case "USER_LOGOUT":
      return {
        ...initialState,
      };
*/
    default:
      return state;
  }
}
