/**
 * Created by piotr.pozniak@thebeaverhead.com on 17/11/2021.
 */
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as projectActions from "../actions/project";

export function useProjectsStore() {
  const dispatch = useDispatch();
  const _projects = useSelector((store) => store.projects);

  const createProject = useCallback(
    async (data) => await dispatch(projectActions.createProject(data)),
    [dispatch]
  );

  const editProject = useCallback(
    async (id, data) => await dispatch(projectActions.editProject(id, data)),
    [dispatch]
  );

  const fetchProject = useCallback(
    async (id, approved_shots) =>
      await dispatch(projectActions.fetchProject(id, approved_shots)),
    [dispatch]
  );

  const deleteShot = useCallback(
    async (id) => await dispatch(projectActions.deleteShot(id)),
    [dispatch]
  );

  const setEditProject = useCallback(
    async (data) => await dispatch(projectActions.setEditProject(data)),
    [dispatch]
  );

  const bulkEditShots = useCallback(
    async (data) => await dispatch(projectActions.bulkEditShots(data)),
    [dispatch]
  );

  const linkProjectWithTSheets = useCallback(
    async (data) => await dispatch(projectActions.linkProjectWithTSheets(data)),
    [dispatch]
  );

  const unlinkProjectWithTSheets = useCallback(
    async (data) =>
      await dispatch(projectActions.unlinkProjectWithTSheets(data)),
    [dispatch]
  );

  const clearProject = useCallback(
    async () => await dispatch(projectActions.clearProject()),
    [dispatch]
  );

  return {
    projects: _projects,
    createProject,
    editProject,
    fetchProject,
    deleteShot,
    setEditProject,
    bulkEditShots,
    linkProjectWithTSheets,
    unlinkProjectWithTSheets,
    clearProject,
  };
}
