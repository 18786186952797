import React, {Component} from 'react';

import UnauthorizedPageTemplate from './UnauthorizedPageTemplate';
import StaticAlert from "../components/StaticAlert";
import {history} from '../configureStore';
import {withRouter} from 'react-router-dom';

import connect from 'react-redux/es/connect/connect';

const staticAlertBody = "Your account is pending verification. You will be notified after it has " +
  "been approved by the platform administrator";
const staticAlertHeader = "Account not verified";

export class AwaitingVerificationPage extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }


  componentDidMount() {

    if (this.props.user.model && this.props.user.model.verified*1) {
      history.push('/');
    }
  }


  /**
   *
   * @returns {XML}
   */
  render() {
    return (
      <UnauthorizedPageTemplate>
        <StaticAlert body={staticAlertBody} header={staticAlertHeader} type="warning"/>
      </UnauthorizedPageTemplate>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(AwaitingVerificationPage)
);