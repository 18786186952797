import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const ProjectNameFormat = (props) => {
  const { project, onClickProject } = props;

  if (!project) {
    return "--";
  }

  return (
    <Link
      className="relative"
      onClick={onClickProject(project)}
      to={"/project/" + project.id}
    >
      {project.name}
    </Link>
  );
};

ProjectNameFormat.propTypes = {
  project: PropTypes.object,
  onClickProject: PropTypes.func.isRequired,
};

export default ProjectNameFormat;
