/**
 * Created by piotr.pozniak@thebeaverhead.com on 30/11/2021.
 */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { formatter } from "../../../helpers/tools";
import { useAdditionalCostsStore } from "../../../storeHooks/additionalCosts";

/**
 *
 * @param additionalCost
 * @param bidsCosts
 * @returns {number|boolean}
 */
const filterAllowedAdditionalCosts = (additionalCost, bidsCosts) => {
  return (
    additionalCost.available * 1 ||
    (!(additionalCost.available * 1) &&
      bidsCosts.findIndex(
        (j) => j.additional_costs_setting.id === additionalCost.id
      ) >= 0)
  );
};

/**
 *
 * @param Array additionalCosts [{
 *    days: Int
 *    rate: Int
 *    is_rate_based: Int
 *    default_value: Int
 * }]
 * @returns {*}
 */
export const sumAdditionalCosts = (additionalCosts) => {
  return additionalCosts.reduce((j, i) => {
    let isRateBased = false;

    if (i.is_rate_based) {
      isRateBased = i.is_rate_based === true || i.is_rate_based * 1;
    } else if (i.additional_costs_setting) {
      isRateBased = i.additional_costs_setting.is_rate_based * 1;
    }

    const rate = Number.parseFloat(i.rate || 0);

    const rateDay = Number.parseFloat(i.days) * rate;
    const cost = isRateBased ? rate : rateDay;
    return cost + j;
  }, 0);
};

const AdditionalCostsRow = (props) => {
  const [state, setState] = useState({
    isAdditionalCostOpen: false,
  });

  const [costs, setCosts] = useState([]);

  const { additionalCosts, fetchAdditionalCosts } = useAdditionalCostsStore();

  useEffect(() => {
    fetchAdditionalCosts();
  }, []);

  useEffect(() => {
    if (
      additionalCosts.fetchSuccess &&
      props.bidVersionModel &&
      props.bidVersionModel.bid_versions_additional_costs
    ) {
      const _costs = additionalCosts.collection
        // filter costs that are not available or are available but already in use in this bid version
        .filter((i) =>
          filterAllowedAdditionalCosts(
            i,
            props.bidVersionModel.bid_versions_additional_costs
          )
        )
        .map((i) => {
          const bidVersionCost =
            props.bidVersionModel &&
            props.bidVersionModel.bid_versions_additional_costs.find(
              (j) => j.additional_costs_setting.id === i.id
            );

          return {
            additional_costs_setting_id: i.id,
            days: bidVersionCost ? bidVersionCost.days : 0,
            rate: bidVersionCost ? bidVersionCost.rate : 0,
            label: i.label,
            is_rate_based: i.is_rate_based,
            default_value: i.default_value,
          };
        });

      setCosts(_costs);
    }
  }, [additionalCosts.fetchSuccess, props.bidVersionModel]);

  const { bidVersionModel } = props;
  const additionalCostsSubtotal = bidVersionModel
    ? sumAdditionalCosts(costs)
    : 0;

  /**
   *
   * @param event
   */
  const onKeyPress = (event) => {
    if (event.key === "-") {
      event.preventDefault();
    }
  };

  /**
   *
   */
  const onPressToggleAdditionalCost = (e) => {
    e.preventDefault();

    setState({
      ...state,
      isAdditionalCostOpen: !state.isAdditionalCostOpen,
    });
  };

  /**
   *
   * @param item
   * @param field
   * @returns {(function(*): void)|*}
   */
  const onChange = (item, field) => (e) => {
    const data = [...costs];
    const updateItem = data.find(
      (i) => i.additional_costs_setting_id === item.additional_costs_setting_id
    );
    updateItem[field] = e.target.value;

    setCosts(data);

    props.onChange(data);
  };

  /**
   *
   * @param rateFee
   * @param days
   * @param isRateBased
   * @returns {*|number}
   */
  const calculateCost = (rateFee, days, isRateBased) => {
    return isRateBased ? rateFee : rateFee * days;
  };

  /**
   *
   * @type {unknown[]}
   */
  const additionalCostsRows = costs
    .filter((i) => !(i.is_rate_based * 1))
    .map((i, idx) => {
      return (
        <React.Fragment key={`acd-sel-${idx}`}>
          <tr>
            <td colSpan={3} className={"sum-rows-edit__label"}>
              {i.label}
            </td>
          </tr>
          <tr>
            <td colSpan={1}>
              <div className="sum-rows-edit__input-cell">
                <div className="sum-rows-edit__measure">$</div>

                <input
                  type="number"
                  className="form-control sum-rows-edit__input js-sum-rows-rate"
                  onChange={onChange(i, "rate")}
                  onKeyPress={onKeyPress}
                  min={0}
                  value={i.rate}
                  disabled={props.loading}
                />
              </div>
            </td>
            <td>
              <div className="sum-rows-edit__input-cell">
                <input
                  type="number"
                  className="form-control sum-rows-edit__input js-sum-rows-days"
                  onChange={onChange(i, "days")}
                  onKeyPress={onKeyPress}
                  min={0}
                  value={i.days}
                  disabled={props.loading}
                />
              </div>
            </td>
            <td>{formatter.format(calculateCost(i.rate, i.days, false))}</td>
          </tr>
        </React.Fragment>
      );
    });

  const additionalRateBasedCostsRows = costs
    .filter((i) => i.is_rate_based * 1)
    .map((i, idx) => {
      return (
        <React.Fragment key={`acr-rb-${idx}`}>
          <tr>
            <td colSpan={3} className={"sum-rows-edit__label"}>
              {i.label}
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <div className="sum-rows-edit__input-cell">
                <div className="sum-rows-edit__measure">$</div>

                <input
                  type="number"
                  className="form-control sum-rows-edit__input js-sum-rows-rate"
                  onChange={onChange(i, "rate")}
                  onKeyPress={onKeyPress}
                  min={0}
                  value={i.rate}
                  disabled={props.loading}
                />
              </div>
            </td>
            <td>{formatter.format(calculateCost(i.rate, i.days, true))}</td>
          </tr>
        </React.Fragment>
      );
    });

  const sumRowsEdit =
    bidVersionModel && state.isAdditionalCostOpen ? (
      <tr>
        <td colSpan={2} style={{ padding: 0 }}>
          <div className={"sum-rows-edit sum-rows-edit-additional-cost"}>
            <table>
              <thead>
                <tr>
                  <td>Rate</td>
                  <td>Days</td>
                  <td>Cost</td>
                </tr>
                {additionalCostsRows}
              </thead>
            </table>
          </div>
        </td>
      </tr>
    ) : null;

  const sumRateBasedRowsEdit =
    bidVersionModel && state.isAdditionalCostOpen ? (
      <tr>
        <td colSpan={2} style={{ padding: 0 }}>
          <div className={"sum-rows-edit sum-rows-edit-additional-cost"}>
            <table>
              <thead>
                <tr>
                  <td colSpan={2}>Fee</td>
                  <td>Cost</td>
                </tr>
                {additionalRateBasedCostsRows}
              </thead>
            </table>
          </div>
        </td>
      </tr>
    ) : null;

  return bidVersionModel ? (
    <>
      <tr>
        <td>
          Additional Cost:&nbsp;
          <a
            href="!#"
            className={props.loading ? "btn-link disabled" : ""}
            onClick={onPressToggleAdditionalCost}
          >
            {state.isAdditionalCostOpen ? "hide" : "show"}
          </a>
        </td>
        <td>
          {additionalCostsSubtotal >= 0 && "+"}
          {formatter.format(additionalCostsSubtotal)}
        </td>
      </tr>
      {sumRowsEdit}
      {sumRateBasedRowsEdit}
    </>
  ) : null;
};

AdditionalCostsRow.propTypes = {
  additionalCosts: PropTypes.array,
  bidVersionModel: PropTypes.object,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
};

export default AdditionalCostsRow;
