import moment from "moment";
import { createTransform } from 'redux-persist';

export const initialState = {
  // artists reports
  reportsArtistsFromDate: moment().isoWeekday(1),
  reportsArtistsToDate: moment().isoWeekday(7),
  reportsArtistsArtists: [],
  reportsArtistsClients: [],
  reportsArtistsProjects: [],

  // artists performance reports
  reportsArtistsPerformanceFromDate: moment().subtract(1, 'month'),
  reportsArtistsPerformanceToDate: moment(),
  reportsArtistsPerformanceArtists: [],
  reportsArtistsPerformanceClients: [],

  // gain/loss reports
  reportsGainLossFromDate: moment().subtract(1, 'month'),
  reportsGainLossToDate: moment(),
  reportsGainLossIsPerShot: false,

  // invoices reports
  reportsInvoicesFromDate: moment().subtract(1, 'month'),
  reportsInvoicesToDate: moment(),
  reportsInvoicesStatus: [],
  reportsInvoicesClients: [],

  // projects hours reports
  reportsProjectsHoursFromDate: moment().isoWeekday(1),
  reportsProjectsHoursToDate: moment().isoWeekday(7),
  reportsProjectsHoursArtists: [],
  reportsProjectsHoursClients: [],
  reportsProjectsHoursProjects: [],

  //time logs page
  timeLogsPageFromDate: moment().subtract(1, 'month'),
  timeLogsPageToDate: moment(),
  timeLogsPageProjects: [],

  projectsPage: "1",
  projectsStatus: [],
  projectsClients: [],
  projectsQuery: null,
  projectsFrom: null,
  projectsTo: null,
  projectsSortBy: null,
  projectsOrderBy: null,

  bidsPage: "1",
  bidsStatus: [],
  bidsClients: [],
  bidsQuery: null,
  bidsFrom: null,
  bidsTo: null,
  bidsSortBy: 'modified',
  bidsOrderBy: 'desc',

  invoicesPage: "1",
  invoicesStatus: [],
  invoicesClients: [],
  invoicesQuery: null,
  // invoicesFrom: null,
  // invoicesTo: null,

  clientsPage: "1",
  clientsSortBy: null,
  clientsOrderBy: null,

  pendingUsersPage: "1",
  clientUsersPage: "1",
  artistUsersPage: "1",
  accountantUsersPage: "1",
  producerUsersPage: "1",
  adminUsersPage: "1",
};

export default function reducer(
  state = {
    ...initialState
  },
  action
) {
  switch (action.type) {
    case "ARTISTS_REPORTS_FETCH":
      return {
        ...state,

        reportsArtistsFromDate: action.data.type === 'ArtistsReportsPage'
          ? (action.data.fromDate ? moment(action.data.fromDate, "YYYY-MM-DD") : null)
          : state.reportsArtistsFromDate,
        reportsArtistsToDate: action.data.type === 'ArtistsReportsPage'
          ? (action.data.toDate ? moment(action.data.toDate, "YYYY-MM-DD") : null)
          : state.reportsArtistsToDate,
        reportsArtistsArtists: action.data.type === 'ArtistsReportsPage'
          ? action.data.artists
          : state.reportsArtistsArtists,
        reportsArtistsClients: action.data.type === 'ArtistsReportsPage'
          ? action.data.clients
          : state.reportsArtistsClients,
        reportsArtistsProjects: action.data.type === 'ArtistsReportsPage'
          ? action.data.projects
          : state.reportsArtistsProjects,

        timeLogsPageFromDate: action.data.type === 'TimeLogsPage'
          ? (action.data.fromDate ? moment(action.data.fromDate, "YYYY-MM-DD") : null)
          : state.timeLogsPageFromDate,
        timeLogsPageToDate: action.data.type === 'TimeLogsPage'
          ? (action.data.toDate ? moment(action.data.toDate, "YYYY-MM-DD") : null)
          : state.timeLogsPageToDate,
        timeLogsPageProjects: action.data.type === 'TimeLogsPage'
          ? action.data.projects
          : state.timeLogsPageProjects,
      };

    case "ARTISTS_PERFORMANCE_REPORTS_FETCH":
      return {
        ...state,

        reportsArtistsPerformanceFromDate: action.data.fromDate ? moment(action.data.fromDate, "YYYY-MM-DD") : null,
        reportsArtistsPerformanceToDate: action.data.toDate ? moment(action.data.toDate, "YYYY-MM-DD") : null,
        reportsArtistsPerformanceArtists: action.data.artists,
        reportsArtistsPerformanceClients: action.data.clients,
      };

    case "LOSS_REPORTS_FETCH":
      return {
        ...state,

        reportsGainLossFromDate: action.data.fromDate ? moment(action.data.fromDate, "YYYY-MM-DD") : null,
        reportsGainLossToDate: action.data.toDate ? moment(action.data.toDate, "YYYY-MM-DD") : null,
        reportsGainLossIsPerShot: !!action.data.isPerShot,
      };

    case "INVOICES_REPORTS_FETCH":
      return {
        ...state,

        reportsInvoicesFromDate: action.data.type === "reportsInvoices"
          ? (action.data.fromDate ? moment(action.data.fromDate, "YYYY-MM-DD") : null)
          : state.reportsInvoicesFromDate,
        reportsInvoicesToDate: action.data.type === "reportsInvoices"
          ? (action.data.toDate ? moment(action.data.toDate, "YYYY-MM-DD") : null)
          : state.reportsInvoicesToDate,
        reportsInvoicesStatus: action.data.type === "reportsInvoices"
          ? action.data.status : state.reportsInvoicesStatus,
        reportsInvoicesClients: action.data.type === "reportsInvoices"
          ? action.data.clients : state.reportsInvoicesClients,
      };

    case "PROJECTS_HOURS_REPORTS_FETCH":
      return {
        ...state,

        reportsProjectsHoursFromDate: action.data.type === 'ProjectsHoursReportsPage'
          ? (action.data.fromDate ? moment(action.data.fromDate, "YYYY-MM-DD") : null)
          : state.reportsProjectsHoursFromDate,
        reportsProjectsHoursToDate: action.data.type === 'ProjectsHoursReportsPage'
          ? (action.data.toDate ? moment(action.data.toDate, "YYYY-MM-DD") : null)
          : state.reportsProjectsHoursToDate,
        reportsProjectsHoursArtists: action.data.type === 'ProjectsHoursReportsPage'
          ? action.data.artists
          : state.reportsProjectsHoursArtists,
        reportsProjectsHoursClients: action.data.type === 'ProjectsHoursReportsPage'
          ? action.data.clients
          : state.reportsProjectsHoursClients,
        reportsProjectsHoursProjects: action.data.type === 'ProjectsHoursReportsPage'
          ? action.data.projects
          : state.reportsProjectsHoursProjects,
      };

    case "PROJECTS_FETCH":
      return {
        ...state,

        projectsPage: action.data.type === 'ProjectsPage' ? String(action.data.page) : state.projectsPage,
        projectsStatus: action.data.type === 'ProjectsPage' ? action.data.filters.status : state.projectsStatus,
        projectsClients: action.data.type === 'ProjectsPage' ? action.data.filters.clients : state.projectsClients,
        projectsQuery: action.data.type === 'ProjectsPage' ? action.data.filters.q : state.projectsQuery,
        projectsFrom: action.data.type === 'ProjectsPage' ? action.data.filters.from : state.projectsFrom,
        projectsTo: action.data.type === 'ProjectsPage' ? action.data.filters.to : state.projectsTo,
        projectsSortBy: action.data.type === 'ProjectsPage' ? action.data.filters.sort_by : state.projectsSortBy,
        projectsOrderBy: action.data.type === 'ProjectsPage' ? action.data.filters.order_by : state.projectsOrderBy,
      };

    case "BIDS_FETCH":
      return {
        ...state,

        bidsPage: action.data.type === 'BidsPage' ? String(action.data.page) : state.bidsPage,
        bidsStatus: action.data.type === 'BidsPage' ? action.data.filters.status : state.bidsStatus,
        bidsClients: action.data.type === 'BidsPage' ? action.data.filters.clients : state.bidsClients,
        bidsQuery: action.data.type === 'BidsPage' ? action.data.filters.q : state.bidsQuery,
        bidsFrom: action.data.type === 'BidsPage' ? action.data.filters.from : state.bidsFrom,
        bidsTo: action.data.type === 'BidsPage' ? action.data.filters.to : state.bidsTo,
        bidsSortBy: action.data.type === 'BidsPage' ? action.data.filters.sort_by : state.bidsSortBy,
        bidsOrderBy: action.data.type === 'BidsPage' ? action.data.filters.order_by : state.bidsOrderBy,
      };

    case "CLIENTS_FETCH":
      return {
        ...state,

        clientsPage: String(action.data.page),
        clientsOrderBy: action.data.filters.direction,
        clientsSortBy: action.data.filters.sort
      };

    case "INVOICES_FETCH":
      return {
        ...state,

        invoicesPage: action.data.type === 'InvoicesPage' ? String(action.data.page) : state.invoicesPage,
        invoicesStatus: action.data.type === 'InvoicesPage' ? action.data.filters.status : state.invoicesStatus,
        invoicesClients: action.data.type === 'InvoicesPage' ? action.data.filters.clients : state.invoicesClients,
        invoicesQuery: action.data.type === 'InvoicesPage' ? action.data.filters.q : state.invoicesQuery,
        // invoicesFrom: action.data.type === 'InvoicesPage' ? action.data.filters.from : state.invoicesFrom,
        // invoicesTo: action.data.type === 'InvoicesPage' ? action.data.filters.to : state.invoicesTo,
        invoicesSortBy: action.data.type === 'InvoicesPage' ? action.data.filters.sort_by : state.invoicesSortBy,
        invoicesOrderBy: action.data.type === 'InvoicesPage' ? action.data.filters.order_by : state.invoicesOrderBy,
      };

    case "USERS_FETCH":
      return {
        ...state,

        pendingUsersPage: action.data.role === "user" ? String(action.data.page) : state.pendingUsersPage,
        artistUsersPage: action.data.role === "artist" ? String(action.data.page) : state.artistUsersPage,
        accountantUsersPage: action.data.role === "accountant" ? String(action.data.page) : state.accountantUsersPage,
        producerUsersPage: action.data.role === "producer" ? String(action.data.page) : state.producerUsersPage,
        adminUsersPage: action.data.role === "admin" ? String(action.data.page) : state.adminUsersPage,
        clientUsersPage:
          action.data.role === "client"
            ? String(action.data.page)
            : state.clientUsersPage,
      };

    case "LOGOUT":
      return {
        ...initialState,
      };

    default:
      return state;
  }
}




export const filtersReducerTransform = createTransform(

  // transform state on its way to being serialized and persisted.
  (inboundState, key) => {
    return inboundState;
  },

  // transform state being rehydrated
  (outboundState, key) => {
    return {
      ...outboundState,

      reportsArtistsFromDate: outboundState.reportsArtistsFromDate
        ? moment(outboundState.reportsArtistsFromDate) : outboundState.reportsArtistsFromDate,
      reportsArtistsToDate: outboundState.reportsArtistsToDate
        ? moment(outboundState.reportsArtistsToDate) : outboundState.reportsArtistsToDate,

      reportsArtistsPerformanceFromDate: outboundState.reportsArtistsPerformanceFromDate
        ? moment(outboundState.reportsArtistsPerformanceFromDate) : outboundState.reportsArtistsPerformanceFromDate,
      reportsArtistsPerformanceToDate: outboundState.reportsArtistsPerformanceToDate
        ? moment(outboundState.reportsArtistsPerformanceToDate) : outboundState.reportsArtistsPerformanceToDate,

      reportsGainLossFromDate: outboundState.reportsGainLossFromDate
        ? moment(outboundState.reportsGainLossFromDate) : outboundState.reportsGainLossFromDate,
      reportsGainLossToDate: outboundState.reportsGainLossToDate
        ? moment(outboundState.reportsGainLossToDate) : outboundState.reportsGainLossToDate,

      reportsInvoicesFromDate: outboundState.reportsInvoicesFromDate
        ? moment(outboundState.reportsInvoicesFromDate) : outboundState.reportsInvoicesFromDate,
      reportsInvoicesToDate: outboundState.reportsInvoicesToDate
        ? moment(outboundState.reportsInvoicesToDate) : outboundState.reportsInvoicesToDate,

      reportsProjectsHoursFromDate: outboundState.reportsProjectsHoursFromDate
        ? moment(outboundState.reportsProjectsHoursFromDate) : outboundState.reportsProjectsHoursFromDate,
      reportsProjectsHoursToDate: outboundState.reportsProjectsHoursToDate
        ? moment(outboundState.reportsProjectsHoursToDate) : outboundState.reportsProjectsHoursToDate,

      timeLogsPageFromDate: outboundState.timeLogsPageFromDate
        ? moment(outboundState.timeLogsPageFromDate) : outboundState.timeLogsPageFromDate,
      timeLogsPageToDate: outboundState.timeLogsPageToDate
        ? moment(outboundState.timeLogsPageToDate) : outboundState.timeLogsPageToDate,
    };
  },

  // define which reducers this transform gets called for.
  { whitelist: ['filtersReducer'] }

);
