import React from "react";
import PropTypes from "prop-types";
import ShotNotes from "../../ShotPage/components/ShotNotes";

class ShotsExpandable extends React.Component {
  /**
   *
   * @return {*}
   */
  render() {
    const { data } = this.props;

    return (
      <div className="p-2">
        <div className="row">
          <div className="col-6" />
          <div className="col-6">
            <ShotNotes
              shotData={data}
              notesClassNames={{
                className: "border-left-primary rounded-left-0",
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

ShotsExpandable.defaultProps = {
  data: {},
};

ShotsExpandable.propTypes = {
  data: PropTypes.object,
};

export default ShotsExpandable;
