/**
 * Created by piotr.pozniak@thebeaverhead.com on 17/05/2022.
 */

export const initialState = {
  fetch: false,
  fetchSuccess: false,
  fetchError: null,

  artistsBreakdown: [],
  projectsBreakdown: [],
};

/**
 *
 * @param state
 * @param action
 * @returns {{}}
 */
export default function reducer(
  state = {
    ...initialState,
  },
  action
) {
  switch (action.type) {
    case "ARTISTS_STATISTICS_FETCH":
    case "PROJECT_STATISTICS_FETCH":
      return {
        ...state,
        fetch: true,
        fetchSuccess: false,
        fetchError: null,
      };

    case "ARTISTS_STATISTICS_FETCH_FULFILLED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: true,
        fetchError: null,

        artistsBreakdown: action.data.artists,
      };

    case "PROJECT_STATISTICS_FETCH_FULFILLED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: true,
        fetchError: null,

        projectsBreakdown: action.data.projects,
      };

    case "ARTISTS_STATISTICS_FETCH_REJECTED":
    case "PROJECT_STATISTICS_FETCH_REJECTED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: false,
        fetchError: action.data,
        artistsBreakdown: [],
      };

    default:
      return state;
  }
}
