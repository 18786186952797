import React, { Component } from "react";
import { history } from "../configureStore";
import StaticAlert from "../components/StaticAlert";
import PageTemplate from "./PageTemplate";
import * as clientsActions from "../actions/clients";
import * as clientActions from "../actions/client";
import connect from "react-redux/es/connect/connect";
import { Link, withRouter } from "react-router-dom";
import DataTable from "react-data-table-component";
import DataGridPagination from "../components/DataGridPagination";
import LoadingIndicator from "../components/LoadingIndicator";
import DropdownMenu from "../components/DropdownMenu";
import moment from "moment";
import * as appActions from "../actions/app";

const staticAlertHeader = "No records to display";
const staticAlertBody = (
  <>
    This page lists all the clients. Create your first client by using button at
    the top right corner of this page or by clicking{" "}
    <Link to="/client/edit/new">this link</Link>.
  </>
);

class ClientsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortBy: props.filtersReducer.clientsSortBy,
      orderBy: props.filtersReducer.clientsOrderBy,
    };

    this.clientsDataTableConfig = {
      perPage: 30,
      columns: [
        {
          name: "Name",
          selector: "name",
          format: (row) => (
            <Link className={"relative"} to={`/client/${row.id}`}>
              {row.name}
            </Link>
          ),
          sortable: true,
        },
        {
          name: "E-mail",
          selector: "email",
          grow: 2,
          format: (row) => (
            <a className="relative" href={"mailto:" + row.email}>
              {row.email}
            </a>
          ),
          sortable: true,
        },
        {
          name: "Phone",
          selector: "contact_phone",
          format: (row) => (
            <a className="relative" href={"tel:" + row.contact_phone}>
              {row.contact_phone}
            </a>
          ),
          sortable: true,
        },
        {
          name: "Created",
          selector: "created",
          format: (row) => moment.unix(row.created).format("DD/MM/YY, HH:mm"),
          sortable: true,
        },
        {
          name: "Actions",
          right: true,
          cell: (row) => (
            <DropdownMenu
              buttons={[
                {
                  icon: "icon-user-cancel",
                  action: this.onDeleteClient.bind(this),
                  data: row,
                  label: "Delete",
                },
                {
                  icon: "icon-users",
                  action: this.onDuplicateClient,
                  data: row,
                  label: "Duplicate",
                },
                {
                  icon: "icon-vcard",
                  action: this.onEditClient.bind(this),
                  data: row,
                  label: "Edit",
                },
              ]}
            />
          ),
        },
      ],
    };
  }

  /**
   *
   */
  componentDidMount() {
    if (this.props.user.token && this.props.user.model) {
      const page = this.props.filtersReducer.clientsPage;

      if (
        this.props.filtersReducer.clientsPage !== this.props.match.params.page
      ) {
        const state = this.props.router.location.state;

        history.replace(`/clients/${page}`, state);
      } else {
        this.fetchClients();
      }

      //const page = this.props.filtersReducer.clientsPage;
      //this.props.fetchClients(page, this.clientsDataTableConfig.perPage);
    }
  }

  /**
   *
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.clients.fetchError !== this.props.clients.fetchError &&
      this.props.clients.fetchError
    ) {
      const page = Number(this.props.filtersReducer.clientsPage);
      if (page > 1) {
        this.fetchClients();
        //this.props.fetchClients(1, this.clientsDataTableConfig.perPage);
      }
    }

    if (prevProps.match.params.page !== this.props.match.params.page) {
      this.fetchClients();
    }
  }

  /**
   *
   * @param page
   */
  onChangePage(page) {
    const state = this.props.router.location.state;

    history.push(`/clients/${page}`, state);
  }

  fetchClients(page) {

    this.props.fetchClients(
      this.props.match.params.page,
      this.clientsDataTableConfig.perPage,
      {
        sort: this.state.sortBy,
        direction: this.state.orderBy,
      }
    );
  }

  /**
   *
   * @param client
   */
  onDeleteClient(client) {
    if (
      window.confirm("Are you sure you want to delete " + client.name + "?")
    ) {
      this.props.deleteClient(client.id);
    }
  }

  /**
   *
   * @param client
   */
  onEditClient(client) {
    this.props.clearErrors();
    this.props.setEditClient(client);
    history.push("/client/edit/" + client.id);
  }

  /**
   *
   * @param client
   */
  onDuplicateClient = (client) => {
    this.props.clearErrors();
    const clientClone = { ...client, id: undefined };
    this.props.setEditClient(clientClone);
    history.push("/client/edit/new");
  };

  /**
   *
   * @param event
   */
  onPressAddClient(event) {
    event.preventDefault();
    this.props.clearErrors();
    this.props.setEditClient(null);
    history.push("/client/edit/new");
  }

  onTableSort = (column, orderBy) => {
    this.setState(
      {
        sortBy: column.selector,
        orderBy,
      },
      () => this.fetchClients(this.props.filtersReducer.clientsPage)
    );
  };

  /**
   *
   * @returns {XML}
   */
  render() {
    const clients = this.props.clients;
    const collection = clients.collection;
    const mock = [{}];

    const deleteIndicator = clients.delete ? (
      <LoadingIndicator isModal />
    ) : null;
    const loadingIndicator = clients.fetch ? (
      <LoadingIndicator isModal />
    ) : null;

    const pagination =
      collection && collection.length && clients.pagination ? (
        <DataGridPagination
          onChangePage={this.onChangePage.bind(this)}
          pagination={clients.pagination}
        />
      ) : null;

    const dataTable = (
      <div>
        <div
          className={
            "data-table-themed data-table-themed_not-stretch data-table-themed_short data-table-themed_short"
          }
        >
          <DataTable
            noHeader
            responsive={false}
            columns={this.clientsDataTableConfig.columns}
            data={collection.length ? collection : mock}
            className={collection.length ? null : "data-table-empty"}
            onSort={this.onTableSort}
            defaultSortField={this.state.sortBy}
            defaultSortAsc={this.state.orderBy === "asc"}
          />
          {pagination}
        </div>
      </div>
    );

    const staticAlert =
      collection.length === 0 ? (
        <StaticAlert header={staticAlertHeader} body={staticAlertBody} />
      ) : null;

    const newClientButton = (
      <a
        href="!#"
        className="btn btn-link btn-float text-default"
        onClick={this.onPressAddClient.bind(this)}
      >
        <i className="mi-person-add text-primary" />
        <span>Add client</span>
      </a>
    );

    return (
      <PageTemplate header="Clients" rightTopMenu={newClientButton}>
        {dataTable}
        {staticAlert}
        {deleteIndicator}
        {loadingIndicator}
      </PageTemplate>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  clients: state.clients,
  filtersReducer: state.filtersReducer,
  router: state.router,
});

const mapDispatchToProps = (dispatch) => ({
  fetchClients: (page, limit, search) =>
    dispatch(clientsActions.fetchClients(page, limit, search)),
  deleteClient: (id) => dispatch(clientsActions.deleteClient(id)),
  setEditClient: (client) => dispatch(clientActions.setEditClient(client)),
  clearErrors: () => dispatch(appActions.clearErrors()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ClientsPage));
