import React, {
  Component,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";

import AsyncCreatableSelect from "react-select/async-creatable";
import AsyncSelect from "react-select/async";
import AxiosConfig from "../../AxiosConfig";
import axios from "axios";
import GenericAsyncSelector from "./GenericAsyncSelector";

const page = 1;
const limit = 10;

const formatItem = (item) => {
  const clientName =
    item.client && item.client.name ? ` (${item.client.name})` : null;
  return {
    label: item.name + clientName,
    value: item.id,
    data: item,
  };
};

const ProjectSelect = (props) => {

  /**
   *
   * @type {function(*): Promise<T>}
   */
  const fetchItems = useCallback((q) => {
    let params = { page, limit, q };
    if (props.allStatuses) {
      params.all_statuses = 1;
    } else {
      params.status = props.status;
    }

    const url =
      AxiosConfig.getEndpointAddress() +
      "/api/projects.json?" +
      AxiosConfig.objectToURLQuery(params);

    return axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        return response.data.projects;
      })
      .catch((error) => {
        return null;
      });
  }, [props.status, props.allStatuses]);

  const defaultValue = props.defaultValue
    ? {
        label:
          props.defaultValue.name +
          (props.defaultValue.client && props.defaultValue.client.name
            ? ` (${props.defaultValue.client.name})`
            : null),
        value: props.defaultValue.id,
        data: props.defaultValue,
      }
    : null;

  return (
    <GenericAsyncSelector
      fetchItems={fetchItems}
      formatItem={formatItem}
      onUpdate={props.onUpdate}
      reactSelectProps={props.reactSelectProps}
      defaultValue={defaultValue}
      creatable={props.creatable}
    />
  );
};

ProjectSelect.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  status: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  allStatuses: PropTypes.bool,
  defaultValue: PropTypes.object,
  reactSelectProps: PropTypes.object,
};

export default ProjectSelect;
