import React, {Component} from 'react';

import * as userActions from "../actions/user";
import connect from "react-redux/es/connect/connect";

import ModalTemplate from './ModalTemplate';

import ProfileBasicForm from '../components/ProfileBasicForm';
import PasswordChangeForm from '../components/PasswordChangeForm';

class ProfileEditModal extends Component {
  /**
   *
   * @returns {XML}
   */
  render() {
    const user = this.props.user;
    const allowRoleEdit = user.model ? ['admin', 'producer'].indexOf(user.model.role) !== -1 : false;
    const allowEmailEdit = user.model && user.model.role === 'admin';
    const updateSuccess = user.editSuccess;

    return (
      <ModalTemplate
        title="Profile Settings"
        onClose={this.props.onClose}
      >
        <ProfileBasicForm
          profile={user.model}
          allowRoleEdit={allowRoleEdit}
          allowEmailEdit={allowEmailEdit}
          isLoading={user.edit}
          onSubmit={this.props.editProfile}
          updateSuccess={updateSuccess}
          onClose={this.props.onClose}
          error={user.editError}
        />

        <PasswordChangeForm
          editingProfile={user.model}
          isLoading={user.edit}
          error={user.editError}
          onSubmit={this.props.editProfile}/>
      </ModalTemplate>
    );
  }
}


const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  editProfile: (id, data) => dispatch(userActions.editProfile(id, data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileEditModal);
