import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Validator from "validatorjs";

import ModalTemplate from "./ModalTemplate";

import connect from "react-redux/es/connect/connect";
import * as projectActions from "../actions/project";
import * as bidActions from "../actions/bid";

import LoadingIndicator from "../components/LoadingIndicator";
import BidSelect from "../components/Selects/BidSelect";
import ShotsEditLine from "../components/ShotsEditLine";
import EditableListTemplate from "../components/EditableListTemplate";
import moment from "moment";

class ShotEditModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formValidationErrors: [],
      bid: null,
      shots: [],
    };

    this.shotFields = {
      shot_number: "",
      // shot_name: '',
      shot_description: "",
      vfx_overview: "",
      notes: "",
      status: "",
      delivery_date: "",
      // delivery_format: '',
      task_types: props.tasksTypes.collection
        .filter((item) => !!item.is_default)
        .map((item) => item.id),
    };
  }

  /**
   *
   */
  componentDidMount() {
    if (
      this.props.user.token &&
      this.props.user.model &&
      !this.props.project.model
    ) {
      this.props.fetchProject(this.props.match.params.id);
    }
    this.updateShotFields();
  }

  /**
   *
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    // project has been fetched
    if (
      this.props.project &&
      this.props.project.model &&
      (!prevProps.project || !prevProps.project.model)
    ) {
      this.updateShotFields();
    }
  }

  /**
   *
   */
  updateShotFields = () => {
    // update delivery_date
    if (
      this.props.project &&
      this.props.project.model &&
      this.props.project.model.due_date
    ) {
      this.shotFields.delivery_date = moment(
        this.props.project.model.due_date * 1000
      ).format("YYYY-MM-DD");
    }
  };

  /**
   *
   */
  addShot = () => {
    this.setSelectsValues(() => {
      this.setState({ shots: [...this.state.shots, this.shotFields] }, () => {
        this.setState({ formValidationErrors: [] });
        setTimeout(window.updateJQuery, 100);
      });
    });
  };

  /**
   *
   * @param event
   * @returns {Promise<void>}
   */
  onConfirmPress = async (event) => {
    event.preventDefault();

    this.setSelectsValues(() => {
      // task_types is like `${item.value}|${new Date().getTime()}`,
      // clean it up first
      const data = [...this.state.shots];
      data.forEach((d, index, array) => {
        array[index] = { ...d };
        array[index].task_types = array[index].task_types.map(
          (i) => i.split("|")[0]
        );
        array[index].bid_id = this.state.bid ? this.state.bid.id : null;
      });
      this.handleSubmit(data);
    });
  };

  /**
   *
   * @param shotsData
   * @returns {Promise<void>}
   */
  handleSubmit = async (shotsData) => {
    const formValidationErrors = [];
    const rules = {
      bid_id: "required",
      shot_number: "required|min:2",
      delivery_date: "required",
      // shot_name: 'required|min:2',
    };
    let hasErrors = false;
    shotsData.forEach((item) => {
      const validation = new Validator(item, rules);
      let errors = null;
      validation.fails(() => {
        errors = validation.errors.all();
        formValidationErrors.push(errors);
        hasErrors = true;
      });
      validation.passes(() => {
        formValidationErrors.push(null);
      });
    });
    this.setState({ formValidationErrors });

    if (!hasErrors) {
      this.handleDataSend(this.formatDataToSend(shotsData));
    }
  };

  /**
   *
   * @param data
   * @returns {*}
   */
  formatDataToSend = (data) => {
    return data.map((item) => {
      let shot = {
        bid_id: item.bid_id,
        shot_number: item.shot_number,
        vfx_overview: item.vfx_overview,
        status: item.status,
        shot_version: {
          name: item.shot_number,
          description: item.shot_description,
          notes: item.notes,
          task_types: item.task_types.map((i) => ({ id: i })),
        },
      };
      if (item.delivery_date) {
        shot.delivery_date = +new Date(item.delivery_date) / 1000;
      }
      return shot;
    });
  };

  /**
   *
   * @param data
   * @returns {Promise<void>}
   */
  handleDataSend = async (data) => {
    await this.props.bulkCreateShots(data);
    this.props.onClose();
  };

  /**
   *
   * @param fieldName
   * @param index
   * @param value
   * @param callback
   */
  handleFiled = (fieldName, index, value, callback) => {
    this.setSelectsValues(() => {
      let newShots = [...this.state.shots];
      newShots[index] = { ...newShots[index], [fieldName]: value };
      this.setState({ shots: newShots }, () => {
        if (typeof callback === "function") {
          callback();
        }
      });
    });
  };

  /**
   *
   * @param callback
   */
  setSelectsValues = (callback) => {
    let newShots = [...this.state.shots];
    document
      .querySelectorAll(".js-shot-create-status")
      .forEach((item, index) => {
        newShots[index] = { ...newShots[index], status: item.value };
      });
    this.setState({ shots: newShots }, () => {
      if (typeof callback === "function") {
        callback();
      }
    });
  };

  /**
   *
   * @param index
   */
  onRemoveBtnPress = (index) => {
    this.setSelectsValues(() => {
      let newShots = [...this.state.shots];
      newShots.splice(index, 1);
      this.setState({ shots: newShots }, () => {
        this.setState({ formValidationErrors: [] });
        setTimeout(window.updateJQuery, 100);
      });
    });
  };

  /**
   *
   * @param index
   * @param fieldName
   * @returns {Function}
   */
  formLineOnUpdate = (index, fieldName) => (event) => {
    this.handleFiled(fieldName, index, event.target.value);
  };

  /**
   *
   * @param index
   * @return {Function}
   */
  setShotTaskTypes = (index) => (items) => {
    const newShots = [...this.state.shots];
    const values = (items || []).map((i) => i.value);
    newShots[index] = { ...newShots[index], task_types: values };
    this.setState({
      shots: newShots,
    });
  };

  /**
   *
   * @param value
   */
  onChangeBid = (value) => {
    this.setState({ bid: value });
  };

  /**
   *
   * @returns {XML}
   */
  render() {
    const tasksTypes = this.props.tasksTypes;
    const project = this.props.project;
    const shot = this.props.shot;
    const projectName = project.model ? `"${project.model.name}"` : "";
    const projectDescription = project.model ? project.model.description : "";

    const isLoading = shot.create || project.fetch || tasksTypes.fetch;
    const loadingIndicator = isLoading ? <LoadingIndicator /> : null;

    const modalTitle = `New shot for project ${projectName}`;

    return (
      <ModalTemplate
        title={modalTitle}
        onClose={this.props.onClose}
        confirmButtonLabel="Create"
        onConfirm={this.onConfirmPress}
        cancelButtonLabel="Cancel"
        onCancel={this.props.onClose}
        loadingIndicator={loadingIndicator}
        disableConfirmButton={isLoading}
        className="modal-xl"
        modalContentClassName="bg-dark"
      >
        <h6 className="mt-n3 mb-3">{projectDescription}</h6>
        <div className="mb-3 row">
          <div className="col-6">
            <label className="form-label text-nowrap">
              Select new shot(s) bid
            </label>
            <BidSelect
              projectId={this.props.match.params.id}
              onUpdate={this.onChangeBid}
              reactSelectProps={{
                classNamePrefix: "bg-dark",
                isDisabled: isLoading,
              }}
            />
          </div>
        </div>
        <EditableListTemplate
          isEditMode={false}
          onAddItem={this.addShot}
          onRemoveBtnPress={this.onRemoveBtnPress}
          items={this.state.shots}
          formLine={ShotsEditLine}
          tasksTypes={tasksTypes}
          setShotTaskTypes={this.setShotTaskTypes}
          formLineOnUpdate={this.formLineOnUpdate}
          isLoading={isLoading}
          formValidationErrors={this.state.formValidationErrors}
          apiError={this.props.shot.createError}
          addItemLabel={"Add another shot"}
          statuses={this.props.statuses}
        />
      </ModalTemplate>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  shot: state.shot,
  project: state.project,
  statuses: state.statuses,
  tasksTypes: state.tasksTypes,
});

const mapDispatchToProps = (dispatch) => ({
  bulkCreateShots: async (shots) =>
    await dispatch(bidActions.bulkCreateShots(shots)),
  fetchProject: async (id) => await dispatch(projectActions.fetchProject(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ShotEditModal));
