import React, { Component } from "react";

import ReportsPageTemplate from "./ReportsPageTemplate";
import connect from "react-redux/es/connect/connect";
import CustomizableDataTable from "../components/CustomizableDataTable";
import StaticAlert from "../components/StaticAlert";
import * as reportsPredictionsActions from "../actions/reportsPredictions";
import { formatter } from "../helpers/tools";
import { Link } from "react-router-dom";

class PredictionsReportsPage extends Component {
  componentDidMount() {
    this.props.fetchPredictionReports();
  }

  /**
   *
   * @returns {XML}
   */
  render() {
    const isLoading = this.props.reportsPredictions.fetch;

    const dataTableCollection = [];
    this.props.reportsPredictions.collection.forEach((client) => {
      client.projects.forEach((project) => {
        project.bids.forEach((bid) => {
          dataTableCollection.push({
            name: `${project.name} - ${bid.name}`,
            number_of_shots: bid.number_of_shots,
            shots_quote_subtotal: bid.shots_quote_subtotal,
            additional_costs_subtotal: bid.additional_costs_subtotal,
            total: bid.total,
            bid_version_id: bid.recent_bid_version
              ? bid.recent_bid_version.id
              : null,
          });
        });
      });
    });

    const content = (
      <CustomizableDataTable
        collection={dataTableCollection}
        columns={[
          {
            name: "Project - Bid",
            selector: "name",
            sortable: true,
            format: (row) =>
              row.bid_version_id ? (
                <Link className="relative" to={`/bid/${row.bid_version_id}`}>
                  {row.name}
                </Link>
              ) : (
                row.name
              ),
          },
          {
            name: "Number of shots",
            selector: "number_of_shots",
            center: true,
          },
          {
            name: "Shots quote subtotal",
            selector: "shots_quote_subtotal",
            center: true,
            format: (row) => (
              <span>{formatter.format(row.shots_quote_subtotal)}</span>
            ),
          },
          {
            name: "Additional costs subtotal",
            selector: "additional_costs_subtotal",
            center: true,
            format: (row) => (
              <span>{formatter.format(row.additional_costs_subtotal)}</span>
            ),
          },
          {
            name: "Total",
            selector: "total",
            center: true,
            format: (row) => <span>{formatter.format(row.total)}</span>,
          },
        ]}
      />
    );

    // empty table alert
    const staticAlert = dataTableCollection.length === 0 && (
      <StaticAlert header={staticAlertHeader} body={staticAlertBody} />
    );
    //

    return (
      <ReportsPageTemplate header="Predictions reports" isLoading={isLoading}>
        {content}
        {staticAlert}
      </ReportsPageTemplate>
    );
  }
}

const mapStateToProps = (state) => ({
  reportsPredictions: state.reportsPredictions,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPredictionReports: (type) =>
    dispatch(reportsPredictionsActions.fetchPredictionReports(type)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PredictionsReportsPage);

const staticAlertHeader = "No records to display";
const staticAlertBody = <>This table lists predictions reports.</>;
