/**
 *
 * @param error
 * @param defaultMessage
 * @returns {*}
 */
export const formatError = (error, defaultMessage = "Error has occurred") => {
  const errors = [];

  if (error && error.response && error.response.data) {
    const data = error.response.data;

    if (data.error && Array.isArray(data.error)) {
      data.error.forEach((i) => {
        if (i.messages && i.messages.length) {
          const fieldName = ucFirst(i.field.replace("_", " "));

          i.messages.map((i) => errors.push(fieldName + " - " + i));
        }
      });

      return errors;
    }
    if (data.message) {
      return [error.response.data.message];
    }
  } else if (error && error.message) {
    return [error.message];
  }

  return [defaultMessage];
};

/**
 *
 * @param str
 * @returns {string}
 */
export const ucFirst = (str) => {
  if (!str) {
    return "";
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 *
 * @param str
 * @returns {string}
 */
export const prettifyProperty = (str) => {
  return ucFirst(str).split("_").join(" ");
};
