import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import StatusBadge, { propTypes as StatusBadgePropTypes } from "./StatusBadge";
import classNames from "classnames";

const StatusEditable = (props) => {
  const { loading, disabled } = props;
  const [showDropdown, setShowDropdown] = useState(false);

  const dropdownRef = React.useRef(null);

  const statuses = useMemo(
    () =>
      props.statuses.map((item, index) => {
        return (
          <option
            value={item.value}
            disabled={props.disabledStatuses.includes(item.value)}
            key={index}
          >
            {item.label}
          </option>
        );
      }),
    [props.statuses, props.disabledStatuses]
  );

  useEffect(() => {
    // if click outside dropdown, hide it

    if (showDropdown) {
      const onClick = (e) => {
        // is not closest to dropdown-status, or it is not this dropdown-status
        if (
          !e.target.closest(".dropdown-status") ||
          e.target.closest(".dropdown-status") !== e.currentTarget
        ) {
          setShowDropdown(false);
        }
      };
      document.addEventListener("click", onClick);

      const onScroll = () => {
        if (dropdownRef.current) {
          // update dropdown position to be under the status badge when window scroll
          dropdownRef.current.style.transform = `translateY(-${window.scrollY}px)`;
        }
      };

      if (props.isFixedPosition) {
        onScroll();
        window.addEventListener("scroll", onScroll);
      }

      return () => {
        document.removeEventListener("click", onClick);
        if (props.isFixedPosition) {
          window.removeEventListener("scroll", onScroll);
        }
      };
    }
  }, [showDropdown]);

  /**
   *
   */
  const onChange = useCallback(
    (e) => {
      props.onChange(e.target.value);
      setShowDropdown(false);
    },
    [props.statuses]
  );

  const onShowDropdown = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      setShowDropdown(true);
    },
    [showDropdown]
  );

  const spinner = loading ? (
    <span>
      <i className="icon-spinner2 spinner fs-086rem" />
    </span>
  ) : null;

  const editIcon =
    !disabled && !loading ? (
      <span className="btn-mode-edit border-transparent pointer">
        <i className="mi-mode-edit" />
      </span>
    ) : null;

  const dropdown = showDropdown ? (
    <div className={"position-relative"}>
      <select
        ref={dropdownRef}
        className={classNames("outline-0 vtop z-index-20", {
          "position-fixed": props.isFixedPosition,
          "position-absolute": !props.isFixedPosition,
        })}
        size={2}
        onChange={onChange}
      >
        {statuses}
      </select>
    </div>
  ) : null;

  const classNameWrapper = classNames("dropdown-status", {
    "p-1": disabled || loading,
  });

  return (
    <div className={classNameWrapper} onClick={onShowDropdown}>
      <div className={"dropdown-status-badge"}>
        <StatusBadge status={props.status} statuses={props.statuses} />
        {spinner}
        {editIcon}
      </div>
      {dropdown}
    </div>
  );
};

StatusEditable.defaultProps = {
  disabledStatuses: [],
  isFixedPosition: true,
};

StatusEditable.propTypes = {
  status: StatusBadgePropTypes.status,
  statuses: StatusBadgePropTypes.statuses,
  disabledStatuses: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  isFixedPosition: PropTypes.bool,
};

export default memo(StatusEditable);
