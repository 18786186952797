import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import HasRights from "../components/HasRights";
import LoadingIndicator from "./LoadingIndicator";
import InfiniteScroll from "./InfiniteScroll";

export default class NewUpdates extends Component {

  /**
   *
   * @param event
   */
  onAddButtonPress = event => {
    event.preventDefault();
    this.props.onAddButtonPress();
  };

  /**
   *
   * @returns {*}
   */
  render() {
    const editAllowedRoles = this.props.editAllowedRoles || [];
    const user = this.props.user;

    const addButton = this.props.onAddButtonPress ?
      (
        <HasRights
          allowedRoles={editAllowedRoles}
          user={user.model}
        >
          <div className="list-icons">
            <a href="!#" className="list-icons-item" onClick={this.onAddButtonPress}>
              <i className="icon-add"/>
            </a>
          </div>
        </HasRights>
      ) : null;

    const cardClassName = classNames(
      'card',
      'overflow-auto',
      'maxh-95vh-xl-less',
      {
        'minh-200':
          this.props.data.length ||
          !!this.props.listEmptyComponent
      },
      {
        'minh-100': !this.props.data.length
      },
      this.props.className
    );

    const listEmptyComponent =
      this.props.listEmptyComponent ||
      <span>--</span>;

    const loadingIndicator = (
      <div className="text-center">
        <LoadingIndicator className={"my-3"}/>
      </div>
    );

    const content =
      this.props.data.length ?
        this.props.data.map((item, index) => {
          const editBtn = this.props.onEditButtonPress ?
            (
              <button type="button"
                      className="btn btn-link btn-icon rounded-round text-muted align-baseline p-0 mr-1"
                      onClick={this.props.onEditButtonPress(item.id)} disabled={false}>
                <i className="mi-mode-edit fs-1-1rem"/>
              </button>
            ) : '';

          const deleteBtn = this.props.onDeleteButtonPress ?
            (
              <button type="button" className="btn btn-link btn-icon rounded-round text-muted align-baseline p-0"
                      onClick={this.props.onDeleteButtonPress(item.id)} disabled={false}>
                <i className="mi-delete-forever fs-1-1rem"/>
              </button>
            ) : '';

          return (
            <div className="list-feed-item pb-2" key={index}>
              <div className="text-muted">
                {item.muteLine} {item.muteLineBold ? <b>{item.muteLineBold}</b> : null}
              </div>

              <p className="mb-0">
                <span>
                  {item.description}
                </span>
              </p>

              <div className="mt-1 ml-n1px">
                <HasRights
                  allowedRoles={editAllowedRoles}
                  user={user.model}
                >
                  {editBtn}
                  {deleteBtn}
                </HasRights>
              </div>
            </div>
          );
        }) : listEmptyComponent;

    const cardBodyClassName = classNames(
      "card-body h-100",
      {
        "overflow-auto": this.props.data.length > 0,
        "overflow-hidden": this.props.data.length === 0
      }
    );

    const listFeed = (
      <div className="list-feed">
        {this.props.loading
          ? loadingIndicator
          : content
        }
      </div>
    );

    const infiniteScroll =
      this.props.infiniteScrollProps &&
      (
        <InfiniteScroll {...this.props.infiniteScrollProps} className={cardBodyClassName}>
          {listFeed}
        </InfiniteScroll>
      );

    const normalScroll = (
      <div className={cardBodyClassName}>
        {listFeed}
      </div>
    );

    const cardContent =
      this.props.infiniteScrollProps
        ? infiniteScroll
        : normalScroll;

    return (
      <div className={cardClassName} style={{maxHeight: "100vh"}}>
        <div className="card-header bg-primary text-white header-elements-inline">
          <h6 className="card-title">{this.props.title}</h6>
          <div className="header-elements">
            {addButton}
          </div>
        </div>

        {cardContent}
      </div>
    );
  }
}

NewUpdates.propTypes = {
  onAddButtonPress: PropTypes.func,
  onEditButtonPress: PropTypes.func,
  onDeleteButtonPress: PropTypes.func,
  data: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  editAllowedRoles: PropTypes.array,
  user: PropTypes.object.isRequired,
  listEmptyComponent: PropTypes.node,
  infiniteScrollProps: PropTypes.shape({
    id: PropTypes.string.isRequired,
    onEndReached: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
  })
};
