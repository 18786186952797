import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Select from "./Select";
import ReactSelect from "react-select";
import FormValidationError from "./FormValidationError";
import ApiError from "../components/ApiError";
import { tasksTypesToOptions } from "../helpers/tools";

const ShotsEditLine = (props) => {
  const data = props.data;
  const item = data.item;
  const index = data.index;
  const isLoading = props.isLoading;

  const statuses = useMemo(
    () => props.statuses.collection.filter((i) => i.type === "Shots"),
    [props.statuses.collection]
  );

  let tasksTypesValues = [];

  const tasksTypesOptions = useMemo(
    () =>
      tasksTypesToOptions(props.tasksTypes.collection).map((item) => {
        return {
          value: `${item.value}|${new Date().getTime()}`,
          label: item.label,
        };
      }),
    [props.tasksTypes.collection]
  );

  useMemo(() => {
    item.task_types.forEach((i) => {
      const selectedOption = {
        value: i,
        label: props.tasksTypes.collection.find((j) => j.id === i.split("|")[0])
          .name,
      };
      tasksTypesValues.push(selectedOption);
      tasksTypesOptions.push(selectedOption);
    });
  }, [item.task_types]);

  return (
    <div>
      <div className="row mb-n1">
        <div className="form-group col">
          <label className="form-label text-nowrap">
            Shot Number <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control js-shot-create-shot_number"
            placeholder="Shot Number"
            value={item.shot_number}
            onChange={props.formLineOnUpdate(index, "shot_number")}
            disabled={isLoading}
          />
        </div>

        <div className="form-group col">
          <label className="form-label text-nowrap">Description</label>
          <input
            type="text"
            className="form-control js-shot-create-description"
            placeholder="Description"
            value={item.shot_description}
            onChange={props.formLineOnUpdate(index, "shot_description")}
            disabled={isLoading}
          />
        </div>

        <div className="form-group col">
          <label className="form-label text-nowrap">Tasks</label>
          <ReactSelect
            isMulti
            className="js-shot-create-tasks"
            defaultValue={tasksTypesValues}
            value={tasksTypesValues}
            isDisabled={isLoading}
            options={tasksTypesOptions}
            isClearable={false}
            onChange={props.setShotTaskTypes(index)}
            classNamePrefix="bg-dark"
          />
        </div>

        <div className="form-group col">
          <label className="form-label text-nowrap">Notes</label>
          <textarea
            className="form-control h-86-e js-shot-create-notes"
            placeholder="Notes"
            value={item.notes}
            onChange={props.formLineOnUpdate(index, "notes")}
            disabled={isLoading}
          />
        </div>

        <div className="form-group col">
          <label className="form-label text-nowrap">Status</label>
          <Select
            data={statuses}
            value={item.status}
            initClassName="js-select2"
            className="js-shot-create-status"
            disabled={isLoading}
          />
        </div>

        <div className="form-group col">
          <label className="form-label text-nowrap">
            Due Date <span className="text-danger">*</span>
          </label>
          <input
            type="date"
            className="form-control js-shot-create-due-date"
            placeholder="Due date"
            value={item.delivery_date}
            onChange={props.formLineOnUpdate(index, "delivery_date")}
            disabled={isLoading}
          />
        </div>
      </div>

      <FormValidationError errors={props.formValidationErrors[index]} />
      {index === 0 ? <ApiError error={props.apiError} /> : null}
    </div>
  );
};

ShotsEditLine.propTypes = {
  data: PropTypes.object.isRequired,
  formLineOnUpdate: PropTypes.func.isRequired,
  formValidationErrors: PropTypes.array.isRequired,
  apiError: PropTypes.object,
  isLoading: PropTypes.bool,
  tasksTypes: PropTypes.object.isRequired,
  setShotTaskTypes: PropTypes.func.isRequired,
};

export default ShotsEditLine;
