import axios from 'axios';
import AxiosConfig from '../AxiosConfig';

/**
 *
 * @param page
 * @param limit
 * @param search
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export const fetchClients = (page = 1, limit = 20, filters = null) => {

  return dispatch => {

    dispatch({
      type: "CLIENTS_FETCH",
      data: {page, filters}
    });

    const url = AxiosConfig.getEndpointAddress() + '/api/clients.json?'
              + AxiosConfig.objectToURLQuery({page, limit, ...filters});

    return axios.get(
      url,
      AxiosConfig.getAuthConfig(),
    )
      .then((response) => {

        dispatch({
          type: "CLIENTS_FETCH_FULFILLED",
          data: response.data,
        });

      })
      .catch(function(error) {

        dispatch({
          type: "CLIENTS_FETCH_REJECTED",
          data: error,
        });

      });
  }
};

/**
 *
 * @param id
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export const deleteClient = (id) => {

  return dispatch => {

    dispatch({type: "CLIENT_DELETE"});

    const url = AxiosConfig.getEndpointAddress() + "/api/clients/" + id + ".json";

    return axios.delete(
      url,
      {...AxiosConfig.getAuthConfig()},
    )
      .then((response) => {

        dispatch({
          type: "CLIENT_DELETE_FULFILLED",
          data: {
            responseData: response.data,
            id,
          },
        });

      })
      .catch(function(error) {

        dispatch({
          type: "CLIENT_DELETE_REJECTED",
          data: error,
        });

      });
  }
};
