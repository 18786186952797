import React, { Component } from "react";
import { history } from "../../configureStore";
import classNames from "classnames";
import Validator from "validatorjs";
import { validateVariablesString, hasRights } from "../../helpers/tools";
import {
  bidPDFFilenameVariables,
  invoicePDFFilenameVariables,
} from "../../consts/pdfFilenamesVariables";
import PageTemplate from "../PageTemplate";
import * as supplierActions from "../../actions/supplier";
import * as tasksTypesActions from "../../actions/tasksTypes";
import connect from "react-redux/es/connect/connect";
import HasRights from "../../components/HasRights";
import LoadingIndicator from "../../components/LoadingIndicator";
import DropdownMenu from "../../components/DropdownMenu";
import ApiError from "../../components/ApiError";
import FormValidationError from "../../components/FormValidationError";
import * as statusesActions from "../../actions/statuses";
import * as appActions from "../../actions/app";
import * as integrationsActions from "../../actions/integrations";
import * as tooltips from "../../consts/tooltips";
import AdditionalCosts from "./AdditionalCosts";
import PlatformStatuses from "./components/PlatformStatuses";


class SupplierEditPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invoicingFormValidationErrors: [],
      paymentFormValidationErrors: [],

    };
  }

  /**
   *
   */
  componentDidMount() {
    if (!this.isPageAllowed()) {
      return;
    }

    if (this.props.user.token && this.props.user.model) {
      this.props.fetchSupplierSettings();
      this.props.fetchIntegrations();
    }

    window
      .jQuery('#supplier-statuses-pane a[data-toggle="tab"]')
      .on("shown.bs.tab", this.props.clearErrors);
  }

  /**
   *
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.isPageAllowed()) {
      return;
    }

    if (
      prevProps.integrations.fetch &&
      !this.props.integrations.fetch &&
      this.props.integrations.model &&
      this.props.integrations.model.settings
    ) {
      const tSheetsIntegratedSetting = this.props.integrations.model.settings.find(
        (i) => i.name === "App.TSheets.is_integrated"
      );

      if (tSheetsIntegratedSetting) {
        document.querySelector(
          ".js-supplier-tsheets-integrated"
        ).checked = Boolean(Number(tSheetsIntegratedSetting.value));

        window.updateJQuery();
      }
    }
  }

  /**
   *
   * @returns {boolean|boolean}
   */
  isPageAllowed = () => {
    return hasRights(this.props.user.model, {}, [
      "admin",
      "producer",
      "accountant",
    ]);
  };

  /**
   *
   * @param event
   */
  handleSubmitInvoicing = async (event) => {
    event.preventDefault();

    this.setState({ invoicingFormValidationErrors: null });
    let data = {
      business_name: document.querySelector(".js-supplier-business-name").value,
      address_line1: document.querySelector(".js-supplier-address-line-one")
        .value,
      address_line2: document.querySelector(".js-supplier-address-line-two")
        .value,
      city: document.querySelector(".js-supplier-city").value,
      state: document.querySelector(".js-supplier-state").value,
      zip_code: document.querySelector(".js-supplier-zip-code").value,
      phone: document.querySelector(".js-supplier-phone").value,
      www_address: document.querySelector(".js-supplier-www-address").value,
      email_address: document.querySelector(".js-supplier-email-address").value,
      contact_person: document.querySelector(".js-supplier-contact-person")
        .value,
      tax_number: document.querySelector(".js-supplier-tax-registration-number")
        .value,
    };

    const rules = {};
    const validation = new Validator(data, rules);

    this.setState({ invoicingFormValidationErrors: validation.errors.all() });

    if (validation.passes()) {
      const dataAr = this.convertSettingsToArray(data, "supplier");
      await this.props.updateSupplierSettings(dataAr);
      this.props.fetchSupplierSettings();
    }
  };

  /**
   *
   * @param event
   */
  handleSubmitPayment = async (event) => {
    event.preventDefault();

    this.setState({ paymentFormValidationErrors: null });
    let data = {
      payment_account_number: document.querySelector(
        ".js-supplier-bank-account-number"
      ).value,
      payment_bank_name: document.querySelector(".js-supplier-bank-name").value,
      payment__address: document.querySelector(".js-supplier-address").value,
      payment__address_line2: document.querySelector(
        ".js-supplier-payment-address-line-two"
      ).value,
      payment_business_name: document.querySelector(
        ".js-supplier-business-name-payment"
      ).value,
    };

    const rules = {};
    const validation = new Validator(data, rules);

    this.setState({ paymentFormValidationErrors: validation.errors.all() });

    if (validation.passes()) {
      const dataAr = this.convertSettingsToArray(data, "supplier");
      await this.props.updateSupplierSettings(dataAr);
      this.props.fetchSupplierSettings();
    }
  };

  /**
   *
   * @param event
   */
  handleSubmitBidsSettings = async (event) => {
    event.preventDefault();

    this.setState({ bidsSettingsFormValidationErrors: null });
    const data = {
      default_rate: document.querySelector(".js-supplier-default-rate").value,
      default_bid_pdf_filename: document.querySelector(
        ".js-supplier-default-bid-pdf-filename"
      ).value,
    };

    const rules = {};
    const validation = new Validator(data, rules);

    let validationErrors = validation.errors.all();
    this.setState({ bidsSettingsFormValidationErrors: validationErrors });
    const variablesStringError = validateVariablesString(
      data.default_bid_pdf_filename,
      bidPDFFilenameVariables
    );

    setTimeout(() => {
      if (variablesStringError) {
        this.setState({
          bidsSettingsFormValidationErrors: {
            ...this.state.bidsSettingsFormValidationErrors,
            default_bid_pdf_filename: [variablesStringError.text],
          },
        });
      }
    }, 0);

    if (validation.passes() && !variablesStringError) {
      const dataAr = this.convertSettingsToArray(data, "supplier");
      await this.props.updateSupplierSettings(dataAr);
      this.props.fetchSupplierSettings();
    }
  };

  /**
   *
   * @param event
   * @returns {Promise<void>}
   */
  handleSubmitInvoicesSettings = async (event) => {
    event.preventDefault();

    this.setState({ invoicesSettingsFormValidationErrors: null });
    const data = {
      current_invoice_number: document.querySelector(
        ".js-invoice-current-invoice-number"
      ).value,
      default_invoice_pdf_filename: document.querySelector(
        ".js-supplier-default-invoice-pdf-filename"
      ).value,
    };

    const rules = {
      current_invoice_number: "required|numeric|min:0",
    };
    const validation = new Validator(data, rules);

    let validationErrors = validation.errors.all();
    this.setState({ invoicesSettingsFormValidationErrors: validationErrors });
    const variablesStringError = validateVariablesString(
      data.default_invoice_pdf_filename,
      invoicePDFFilenameVariables
    );

    setTimeout(() => {
      if (variablesStringError) {
        this.setState({
          invoicesSettingsFormValidationErrors: {
            ...this.state.invoicesSettingsFormValidationErrors,
            default_invoice_pdf_filename: [variablesStringError.text],
          },
        });
      }
    }, 0);

    if (validation.passes() && !variablesStringError) {
      const dataAr = this.convertSettingsToArray(data, "invoices");
      await this.props.updateSupplierSettings(dataAr);
      this.props.fetchSupplierSettings();
    }
  };

  /**
   *
   */
  convertSettingsToObj = () => {
    const settings =
      (this.props.supplier.model && this.props.supplier.model.settings) || [];
    let settingsObj = {};
    if (!settings.length) {
      return settingsObj;
    }
    settings.forEach((item) => {
      settingsObj[item.name.split(".")[1]] = item.value;
    });
    return settingsObj;
  };

  /**
   *
   */
  convertIntegrationSettingsToObj = () => {
    const settings =
      (this.props.integrations.model &&
        this.props.integrations.model.settings) ||
      [];
    let settingsObj = {};
    if (!settings.length) {
      return settingsObj;
    }
    settings.forEach((i) => (settingsObj[i.name] = i.value));
    return settingsObj;
  };

  /**
   *
   * @param settingsObj
   * @param name
   * @returns {Array}
   */
  convertSettingsToArray = (settingsObj, name) => {
    let settingsAr = [];
    for (let key in settingsObj) {
      settingsAr.push({
        name: name + "." + key,
        value: settingsObj[key],
      });
    }
    return settingsAr;
  };

  /**
   *
   */
  onCreateTaskTypeClick = () => {
    history.push("/vfx-task/new");
  };

  /**
   *
   * @param taskType
   */
  onEditTaskTypeClick = (taskType) => {
    history.push(`/vfx-task/${taskType.id}`);
  };

  /**
   *
   * @param taskType
   */
  onDeleteTaskTypeClick = (taskType) => {
    if (window.confirm("Are you sure you want to delete this VFX task?")) {
      this.props.deleteTaskType(taskType.id);
    }
  };




  /**
   *
   */
  handleUpdateTSheets = async () => {
    const data = [
      {
        name: "App.TSheets.is_integrated",
        value: document.querySelector(".js-supplier-tsheets-integrated").checked
          ? 1
          : 0,
      },
      {
        name: "App.TSheets.api_token",
        value: document.querySelector(".js-supplier-tsheets-token").value,
      },
    ];

    this.props.updateIntegrations(data);
  };

  /**
   *
   * @returns {XML}
   */
  render() {
    if (!this.isPageAllowed()) {
      return null;
    }

    const userModel = this.props.user.model;
    const role = userModel ? userModel.role : "";
    const supplier = this.props.supplier;
    const tasksTypes = this.props.tasksTypes;
    const settings = this.convertSettingsToObj();
    const statuses = this.props.statuses;
    const integrationSettings = this.convertIntegrationSettingsToObj();

    const isLoading =
      supplier.fetch || supplier.update || tasksTypes.delete || statuses.fetch;
    const loadingIndicator = isLoading ? (
      <div className="mb-n3">
        <LoadingIndicator />
      </div>
    ) : null;

    const tasksTypesRows = (tasksTypes.collection || []).map((item, index) => {
      const taskTypeTRClassName = classNames({
        "bg-blue-mark-table": item.is_default,
      });
      return (
        <tr
          key={index}
          className={taskTypeTRClassName}
          title={item.is_default ? tooltips.defaultTaskTypeHint : null}
        >
          <td>{index + 1}</td>
          <td>{item.name}</td>
          <HasRights
            allowedRoles={["admin", "producer"]}
            user={userModel}
          >
            <td className="text-center">
              <DropdownMenu
                buttons={[
                  {
                    icon: "mi-mode-edit",
                    action: this.onEditTaskTypeClick,
                    data: item,
                    label: "Edit details",
                  },
                  {
                    icon: "mi-delete-forever",
                    action: this.onDeleteTaskTypeClick,
                    data: item,
                    label: "Delete",
                  },
                ]}
              />
            </td>
          </HasRights>
        </tr>
      );
    });

    const integrationsLoading =
      this.props.integrations.fetch || this.props.integrations.update;

    const integrationsLoadingIndicator = integrationsLoading && (
      <div className="mb-n3">
        <LoadingIndicator />
      </div>
    );

    const integrationsApiError =
      this.props.integrations.fetchError || this.props.integrations.updateError;

    const integrationsPanel = (
      <div className="card">
        <div
          className="card-header header-elements-inline"
          style={styles.cardHeader}
        >
          <h6 className="card-title">Integrations</h6>
        </div>
        <div className="card-body">
          <ul className="nav nav-tabs nav-tabs-highlight">
            <li className="nav-item">
              <a
                href="#integrations-tab1"
                className="nav-link active"
                data-toggle="tab"
              >
                TSheets
              </a>
            </li>
          </ul>

          <div className="tab-content">
            <div className="tab-pane fade show active" id="integrations-tab1">
              <div className="row">
                <div className="form-group col-12">
                  <div className="form-check">
                    <label className="form-check-label">
                      <input
                        type="checkbox"
                        className="form-check-input-styled js-supplier-tsheets-integrated"
                        data-fouc
                        disabled={integrationsLoading || !["admin", "producer"].includes(role)}
                      />
                      Integrate with TSheets
                    </label>
                  </div>
                </div>

                <div className="form-group col-12">
                  <label className="form-label">TSheets API Token</label>
                  <input
                    type="text"
                    className="form-control js-supplier-tsheets-token"
                    disabled={integrationsLoading || !["admin", "producer"].includes(role)}
                    defaultValue={integrationSettings["App.TSheets.api_token"]}
                  />
                </div>

                <div className="col-6">
                  {integrationsLoadingIndicator}
                  <div className="mt-n2">
                    <ApiError error={integrationsApiError} />
                  </div>
                </div>

                <div className="col-6 text-right">
                  <HasRights
                    allowedRoles={["admin", "producer"]}
                    user={userModel}
                  >
                    <button
                      type="button"
                      className="btn bg-primary"
                      onClick={this.handleUpdateTSheets}
                      disabled={isLoading}
                    >
                      Update
                    </button>
                  </HasRights>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    const invoicesPanel = (
      <div className="card">
        <div
          className="card-header header-elements-inline"
          style={styles.cardHeader}
        >
          <h6 className="card-title">Invoices</h6>
        </div>
        <div className="card-body">
          <div className="form-group row">
            <label className="col-lg-3 col-form-label">Current invoice #</label>
            <div className="col-lg-9">
              <input
                type="number"
                className="form-control js-invoice-current-invoice-number"
                placeholder="Current invoice #"
                defaultValue={settings.current_invoice_number}
                disabled={isLoading}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-lg-3 col-form-label">
              Default PDF filename
              <i
                className="icon-question4 ml-2 fs-14"
                data-popup="tooltip"
                data-html="true"
                data-original-title={tooltips.pdfInvoiceFilenamesVariablesHint}
              />
            </label>
            <div className="col-lg-9">
              <input
                type="text"
                className="form-control js-supplier-default-invoice-pdf-filename"
                placeholder="Default PDF filename"
                defaultValue={settings.default_invoice_pdf_filename}
                disabled={isLoading || !["admin", "producer"].includes(role)}
              />
            </div>
          </div>

          <FormValidationError
            errors={this.state.invoicesSettingsFormValidationErrors}
          />
          <ApiError error={supplier.fetchError || supplier.updateError} />

          <div className="d-flex justify-content-between align-items-center">
            {loadingIndicator}
            <span />
            <button
              onClick={this.handleSubmitInvoicesSettings}
              disabled={isLoading}
              type="submit"
              className="btn btn-primary"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    );

    return (
      <PageTemplate header="Platform Settings">
        <div className="row">
          <div className="col-md-6">
            <div className="card">
              <div className="card-header" style={styles.cardHeader}>
                <h6 className="card-title">Supplier information</h6>
              </div>
              <div className="card-body">
                <ul className="nav nav-tabs nav-tabs-highlight">
                  <li className="nav-item">
                    <a
                      href="#basic-tab1"
                      className="nav-link active"
                      data-toggle="tab"
                    >
                      Invoice addresses
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#basic-tab2"
                      className="nav-link"
                      data-toggle="tab"
                    >
                      Payment details
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#basic-tab3"
                      className="nav-link"
                      data-toggle="tab"
                    >
                      Bids settings
                    </a>
                  </li>
                </ul>

                <div className="tab-content">
                  <div className="tab-pane fade active show " id="basic-tab1">
                    <form action="#">
                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label">
                          Business name
                        </label>
                        <div className="col-lg-9">
                          <input
                            type="text"
                            className="form-control js-supplier-business-name"
                            placeholder="Business name"
                            defaultValue={settings.business_name}
                            disabled={isLoading || !["admin", "producer"].includes(role)}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label">
                          Address line 1
                        </label>
                        <div className="col-lg-9">
                          <input
                            type="text"
                            className="form-control js-supplier-address-line-one"
                            placeholder="Address line 1"
                            defaultValue={settings.address_line1}
                            disabled={isLoading || !["admin", "producer"].includes(role)}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label">
                          Address line 2
                        </label>
                        <div className="col-lg-9">
                          <input
                            type="text"
                            className="form-control js-supplier-address-line-two"
                            placeholder="Address line 2"
                            defaultValue={settings.address_line2}
                            disabled={isLoading || !["admin", "producer"].includes(role)}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label">City</label>
                        <div className="col-lg-9">
                          <input
                            type="text"
                            className="form-control js-supplier-city"
                            placeholder="City"
                            defaultValue={settings.city}
                            disabled={isLoading || !["admin", "producer"].includes(role)}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label">State</label>
                        <div className="col-lg-9">
                          <input
                            type="text"
                            className="form-control js-supplier-state"
                            placeholder="State"
                            defaultValue={settings.state}
                            disabled={isLoading || !["admin", "producer"].includes(role)}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label">
                          Zip code
                        </label>
                        <div className="col-lg-9">
                          <input
                            type="text"
                            className="form-control js-supplier-zip-code"
                            placeholder="Zip code"
                            defaultValue={settings.zip_code}
                            disabled={isLoading || !["admin", "producer"].includes(role)}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label">Phone</label>
                        <div className="col-lg-9">
                          <input
                            type="text"
                            className="form-control js-supplier-phone"
                            placeholder="Phone"
                            defaultValue={settings.phone}
                            disabled={isLoading || !["admin", "producer"].includes(role)}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label">
                          WWW address
                        </label>
                        <div className="col-lg-9">
                          <input
                            type="text"
                            className="form-control js-supplier-www-address"
                            placeholder="WWW address"
                            defaultValue={settings.www_address}
                            disabled={isLoading || !["admin", "producer"].includes(role)}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label">
                          Email address
                        </label>
                        <div className="col-lg-9">
                          <input
                            type="text"
                            className="form-control js-supplier-email-address"
                            placeholder="Email address"
                            defaultValue={settings.email_address}
                            disabled={isLoading || !["admin", "producer"].includes(role)}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label">
                          Contact person
                        </label>
                        <div className="col-lg-9">
                          <input
                            type="text"
                            className="form-control js-supplier-contact-person"
                            placeholder="Contact person"
                            defaultValue={settings.contact_person}
                            disabled={isLoading || !["admin", "producer"].includes(role)}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label">
                          Tax number/Registration number
                        </label>
                        <div className="col-lg-9">
                          <input
                            type="text"
                            className="form-control js-supplier-tax-registration-number"
                            placeholder="Tax number/Registration number"
                            defaultValue={settings.tax_number}
                            disabled={isLoading || !["admin", "producer"].includes(role)}
                          />
                        </div>
                      </div>

                      <FormValidationError
                        errors={this.state.invoicingFormValidationErrors}
                      />
                      <ApiError
                        error={supplier.fetchError || supplier.updateError}
                      />

                      <div className="d-flex justify-content-between align-items-center">
                        {loadingIndicator}
                        <HasRights
                          allowedRoles={["admin", "producer"]}
                          user={userModel}
                        >
                          <span />
                          <button
                            onClick={this.handleSubmitInvoicing}
                            disabled={isLoading}
                            type="submit"
                            className="btn btn-primary"
                          >
                            Update
                          </button>
                        </HasRights>
                      </div>
                    </form>
                  </div>

                  <div className="tab-pane fade" id="basic-tab2">
                    <form action="#">
                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label">
                          Bank account number
                        </label>
                        <div className="col-lg-9">
                          <input
                            type="text"
                            className="form-control js-supplier-bank-account-number"
                            placeholder="Bank account number"
                            defaultValue={settings.payment_account_number}
                            disabled={isLoading}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label">
                          Bank name
                        </label>
                        <div className="col-lg-9">
                          <input
                            type="text"
                            className="form-control js-supplier-bank-name"
                            placeholder="Bank name"
                            defaultValue={settings.payment_bank_name}
                            disabled={isLoading}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label">
                          Address
                        </label>
                        <div className="col-lg-9">
                          <input
                            type="text"
                            className="form-control js-supplier-address"
                            placeholder="Address"
                            defaultValue={settings.payment__address}
                            disabled={isLoading}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label">
                          Address line 2
                        </label>
                        <div className="col-lg-9">
                          <input
                            type="text"
                            className="form-control js-supplier-payment-address-line-two"
                            placeholder="Address line 2"
                            defaultValue={settings.payment__address_line2}
                            disabled={isLoading}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label">
                          Business name
                        </label>
                        <div className="col-lg-9">
                          <input
                            type="text"
                            className="form-control js-supplier-business-name-payment"
                            placeholder="Business name"
                            defaultValue={settings.payment_business_name}
                            disabled={isLoading}
                          />
                        </div>
                      </div>

                      <FormValidationError
                        errors={this.state.paymentFormValidationErrors}
                      />
                      <ApiError
                        error={supplier.fetchError || supplier.updateError}
                      />

                      <div className="d-flex justify-content-between align-items-center">
                        {loadingIndicator}
                        <span />
                        <button
                          onClick={this.handleSubmitPayment}
                          disabled={isLoading}
                          type="submit"
                          className="btn btn-primary"
                        >
                          Update
                        </button>
                      </div>
                    </form>
                  </div>

                  <div className="tab-pane fade" id="basic-tab3">
                    <form action="#">
                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label">
                          Default rate
                        </label>
                        <div className="col-lg-9">
                          <input
                            type="number"
                            className="form-control js-supplier-default-rate"
                            placeholder="Default rate"
                            defaultValue={settings.default_rate}
                            disabled={isLoading || !["admin", "producer"].includes(role)}
                          />
                        </div>
                      </div>


                      <div className="form-group row">
                        <label className="col-lg-3 col-form-label">
                          Default PDF filename
                          <i
                            className="icon-question4 ml-2 fs-14"
                            data-popup="tooltip"
                            data-html="true"
                            data-original-title={
                              tooltips.pdfBidFilenamesVariablesHint
                            }
                          />
                        </label>
                        <div className="col-lg-9">
                          <input
                            type="text"
                            className="form-control js-supplier-default-bid-pdf-filename"
                            placeholder="Default PDF filename"
                            defaultValue={settings.default_bid_pdf_filename}
                            disabled={isLoading || !["admin", "producer"].includes(role)}
                          />
                        </div>
                      </div>

                      <FormValidationError
                        errors={this.state.bidsSettingsFormValidationErrors}
                      />
                      <ApiError
                        error={supplier.fetchError || supplier.updateError}
                      />

                      <div className="d-flex justify-content-between align-items-center">
                        {loadingIndicator}
                        <HasRights
                          allowedRoles={["admin", "producer"]}
                          user={userModel}
                        >
                          <span />
                          <button
                            onClick={this.handleSubmitBidsSettings}
                            disabled={isLoading}
                            type="submit"
                            className="btn btn-primary"
                          >
                            Update
                          </button>
                        </HasRights>
                      </div>
                    </form>
                    <AdditionalCosts userModel={userModel}/>
                  </div>
                </div>
              </div>
            </div>

            {invoicesPanel}
          </div>

          <div className="col-md-6">
            <div className="card">
              <div
                className="card-header header-elements-inline"
                style={styles.cardHeader}
              >
                <h6 className="card-title">VFX tasks</h6>
                <div className="header-elements">
                  <HasRights
                    allowedRoles={["admin", "producer"]}
                    user={userModel}
                  >
                    <i
                      onClick={this.onCreateTaskTypeClick}
                      className="icon-stack-plus text-primary"
                      data-popup="tooltip"
                      data-original-title="Create new VFX task"
                      style={{ cursor: "pointer" }}
                    />
                  </HasRights>
                </div>
              </div>
              <div className="card-body">
                <div className="overflow-auto">
                  <table className="table datatable-basic">
                    <thead>
                      <tr>
                        <th>Number</th>
                        <th>VFX task Name</th>
                        <HasRights
                          allowedRoles={["admin", "producer"]}
                          user={userModel}
                        >
                          <th className="text-center">Actions</th>
                        </HasRights>
                      </tr>
                    </thead>
                    <tbody>{tasksTypesRows}</tbody>
                  </table>
                </div>
                <div className="mt-2">{loadingIndicator}</div>
              </div>
            </div>

            {integrationsPanel}
          </div>

          <PlatformStatuses/>
        </div>
      </PageTemplate>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  supplier: state.supplier,
  tasksTypes: state.tasksTypes,
  statuses: state.statuses,
  integrations: state.integrations,
});

const mapDispatchToProps = (dispatch) => ({
  fetchSupplierSettings: () =>
    dispatch(supplierActions.fetchSupplierSettings()),
  updateSupplierSettings: (data) =>
    dispatch(supplierActions.updateSupplierSettings(data)),
  fetchIntegrations: () => dispatch(integrationsActions.fetchIntegrations()),
  updateIntegrations: (data) =>
    dispatch(integrationsActions.updateIntegrations(data)),
  deleteTaskType: (id) => dispatch(tasksTypesActions.deleteTaskType(id)),
  clearErrors: () => dispatch(appActions.clearErrors()),
});

const styles = {
  cardHeader: {
    borderBottomWidth: 0,
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(SupplierEditPage);
