export const initialState = {
  fetch: false,
  fetchSuccess: false,
  fetchError: null,

  create: false,
  createSuccess: false,
  createError: null,

  edit: false,
  editSuccess: false,
  editError: null,

  taskNoteCreate: false,
  taskNoteCreateSuccess: false,
  taskNoteCreateError: null,

  taskNoteEdit: false,
  taskNoteEditSuccess: false,
  taskNoteEditError: null,

  taskNoteDelete: false,
  taskNoteDeleteSuccess: false,
  taskNoteDeleteError: null,

  shotUpdateCreate: false,
  shotUpdateCreateSuccess: false,
  shotUpdateCreateError: null,

  shotUpdateEdit: false,
  shotUpdateEditSuccess: false,
  shotUpdateEditError: null,

  shotUpdateDelete: false,
  shotUpdateDeleteSuccess: false,
  shotUpdateDeleteError: null,

  shotMove: false,
  shotMoveSuccess: false,
  shotMoveError: null,

  shotNoteFetch: true,
  shotNoteFetchSuccess: false,
  shotNoteFetchError: null,

  model: null,
};

export default function reducer(
  state = {
    ...initialState,
  },
  action
) {
  switch (action.type) {
    case "SHOT_FETCH":
      return {
        ...state,
        fetch: true,
        fetchSuccess: false,
        fetchError: null,
      };

    case "SHOT_FETCH_FULFILLED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: true,

        model: action.data,
      };

    case "SHOT_FETCH_REJECTED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: false,
        fetchError: action.data,
      };

    case "SHOT_CREATE":
      return {
        ...state,
        create: true,
        createSuccess: false,
        createError: null,
      };

    case "SHOT_CREATE_FULFILLED":
      return {
        ...state,
        create: false,
        createSuccess: true,

        model: action.data,
      };

    case "SHOT_CREATE_REJECTED":
      return {
        ...state,
        create: false,
        createSuccess: false,
        createError: action.data,
      };

    case "SHOT_EDIT":
      return {
        ...state,
        edit: true,
        editSuccess: false,
        editError: null,
      };

    case "SHOT_EDIT_FULFILLED":
      return {
        ...state,
        edit: false,
        editSuccess: true,

        model: {
          ...state.model,
          ...action.data.shots[0],
        },
      };

    case "SHOT_EDIT_REJECTED":
      return {
        ...state,
        edit: false,
        editSuccess: false,
        editError: action.data,
      };

    case "SHOT_NOTE_FETCH_FULFILLED":
      return {
        ...state,
        model:
          state.model && state.model.id === action.data.shotId
            ? {
                ...state.model,
                shot_notes: action.data.responseData.shot_notes,
              }
            : state.model,
      };

    case "SHOT_NOTE_CREATE_FULFILLED":
      return {
        ...state,
        model:
          state.model && state.model.id === action.data.shotId
            ? {
                ...state.model,
                shot_notes: action.data.responseData.shot_notes,
              }
            : state.model,
      };

    case "SHOT_NOTE_EDIT_FULFILLED":
      return {
        ...state,
        model:
          state.model && state.model.id === action.data.shotId
            ? {
                ...state.model,
                shot_notes: action.data.responseData.shot_notes,
              }
            : state.model,
      };

    case "SHOT_NOTE_DELETE_FULFILLED":
      if (state.model) {
        const updatedNotes = !!action.data.responseData.success
          ? state.model.shot_notes.filter(
              (note) => note.id !== action.data.shotNoteId
            )
          : state.model.shot_notes;

        return {
          ...state,
          model: {
            ...state.model,
            shot_notes: updatedNotes,
            recent_shot_note: updatedNotes.length ? updatedNotes[0] : null,
          },
        };
      }

      return state;

    case "TASK_FETCH_FULFILLED":
      if (state.model.id && action.shotId === state.model.id) {
        return {
          ...state,
          model: {
            ...state.model,
            tasks: action.data.tasks,
          },
        };
      }
      return state;

    case "TASK_EDIT_FULFILLED":
      if (
        state.model &&
        state.model.id &&
        action.data.shot.id === state.model.id
      ) {
        return {
          ...state,
          model: {
            ...state.model,
            tasks: state.model.tasks.map((i) => {
              if (i.id === action.data.id) {
                return action.data;
              }
              return i;
            }),
          },
        };
      }
      return state;

    case "TASK_CREATE_FULFILLED":
      if (state.model.id && action.data.shot.id === state.model.id) {
        return {
          ...state,
          model: {
            ...state.model,
            tasks: [...state.model.tasks, action.data],
          },
        };
      }
      break;

    case "USER_EDIT_FULFILLED":
    case "USER_PROFILE_EDIT_FULFILLED":
      return {
        ...state,
        model:
          state.model && state.model.tasks
            ? {
                ...state.model,
                tasks: state.model.tasks.map((shotTask) => {
                  if (
                    shotTask.assignee &&
                    shotTask.assignee.id === action.data.id
                  ) {
                    return {
                      ...shotTask,
                      assignee: {
                        ...shotTask.assignee,
                        avatar_color: action.data.avatar_color,
                      },
                    };
                  }
                  return shotTask;
                }),
              }
            : state.model,
      };

    case "SHOT_UPDATE_CREATE":
      return {
        ...state,
        shotUpdateCreate: true,
        shotUpdateCreateSuccess: false,
        shotUpdateCreateError: null,
      };

    case "SHOT_UPDATE_CREATE_FULFILLED":
      const newShotUpdatesWithCreated = state.model
        ? [...state.model.shot_updates]
        : [];
      newShotUpdatesWithCreated.unshift(action.data);
      return {
        ...state,
        shotUpdateCreate: false,
        shotUpdateCreateSuccess: true,

        model:
          state.model !== null
            ? {
                ...state.model,
                shot_updates: newShotUpdatesWithCreated,
              }
            : null,
      };

    case "SHOT_UPDATE_CREATE_REJECTED":
      return {
        ...state,
        shotUpdateCreate: false,
        shotUpdateCreateSuccess: false,
        shotUpdateCreateError: action.data,
      };

    case "SHOT_UPDATE_EDIT":
      return {
        ...state,
        shotUpdateEdit: true,
        shotUpdateEditSuccess: false,
        shotUpdateEditError: null,
      };

    case "SHOT_UPDATE_EDIT_FULFILLED":
      let newShotUpdatesWithEdited = state.model
        ? state.model.shot_updates.map((value) => {
            return value.id === action.data.id ? action.data : { ...value };
          })
        : [action.data];
      return {
        ...state,
        shotUpdateEdit: false,
        shotUpdateEditSuccess: true,

        model: {
          ...state.model,
          shot_updates: newShotUpdatesWithEdited,
        },
      };

    case "SHOT_UPDATE_EDIT_REJECTED":
      return {
        ...state,
        shotUpdateEdit: false,
        shotUpdateEditSuccess: false,
        shotUpdateEditError: action.data,
      };

    case "SHOT_UPDATE_DELETE":
      return {
        ...state,
        shotUpdateDelete: true,
        shotUpdateDeleteSuccess: false,
        shotUpdateDeleteError: null,
      };

    case "SHOT_UPDATE_DELETE_FULFILLED":
      let isDeleted = !!action.data.responseData.success;
      let newShotUpdateWithDeleted = state.model
        ? isDeleted
          ? state.model.shot_updates.filter(
              (value) => value.id !== action.data.id
            )
          : state.model.shot_updates
        : [];
      return {
        ...state,
        shotUpdateDelete: false,
        shotUpdateDeleteSuccess: isDeleted,

        model: {
          ...state.model,
          shot_updates: newShotUpdateWithDeleted,
        },
      };

    case "SHOT_UPDATE_DELETE_REJECTED":
      return {
        ...state,
        shotUpdateDelete: false,
        shotUpdateDeleteSuccess: false,
        shotUpdateDeleteError: action.data,
      };

    case "TASKS_CHANGE_ASSIGNEE_FULFILLED":
      if (!state.model) {
        return state;
      }

      return {
        ...state,

        model: {
          ...state.model,

          tasks: state.model.tasks.map((i) => {
            const successTasks = action.data.success.slice();
            const taskFound = successTasks.find((j) => j.id === i.id);
            if (taskFound) {
              return taskFound;
            }
            return i;
          }),
        },
      };

    case "SHOW_MOVE":
      return {
        ...state,
        shotMove: true,
        shotMoveSuccess: false,
        shotMoveError: null,
      };

    case "SHOW_MOVE_FULFILLED":
      return {
        ...state,
        shotMove: false,
        shotMoveSuccess: true,
      };

    case "SHOW_MOVE_REJECTED":
      return {
        ...state,
        shotMove: false,
        shotMoveError: action.data,
      };

    case "CLEAR_ERRORS":
      return {
        ...initialState,
        model: state.model,
      };

    case "LOGOUT":
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
