import React from "react";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import ImportantItemsHeader from "./ImportantItemsHeader";
import GroupedInvoicesRow from "./GroupedInvoicesRow";
import StatusBadge from "../../../components/StatusBadge";
import useStatusHook from "../../../hooks/useStatusHook";

const GroupedInvoices = (props) => {
  const { data, statuses } = props;

  const collection = data.collection || [];

  const { statuses: invoicesStatuses } = useStatusHook("Invoices");

  let lastStatus = "";

  return (
    <div className="card mb-0">
      <ImportantItemsHeader title={data.title} />

      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th width="22%">Due</th>
              <th width="39%">Invoice</th>
              <th width="39%">Invoice Status</th>
            </tr>
          </thead>
          <tbody>
            {collection.map((invoice) => {
              if (invoice.status !== lastStatus) {
                lastStatus = invoice.status;
                return (
                  <React.Fragment key={`grouped-invoice-${invoice.id}`}>
                    <tr className="table-active table-border-double">
                      <td colSpan="3">
                        <StatusBadge
                          status={invoice.status}
                          statuses={invoicesStatuses}
                        />
                      </td>
                    </tr>
                    <GroupedInvoicesRow
                      data={invoice}
                      statuses={invoicesStatuses}
                    />
                  </React.Fragment>
                );
              } else {
                return (
                  <GroupedInvoicesRow
                    data={invoice}
                    statuses={invoicesStatuses}
                    key={`grouped-invoice-${invoice.id}`}
                  />
                );
              }
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

GroupedInvoices.propTypes = {
  data: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  statuses: state.statuses,
});

export default connect(mapStateToProps, null)(GroupedInvoices);
