import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import LoadingIndicator from "../../../components/LoadingIndicator";
import ImportantItemsHeader from "../components/ImportantItemsHeader";
import UserAvatar from "../../../components/UserAvatar";
import DateUSSlash from "../../../components/DateUSSlash";

export default class ProjectsDealsSummary extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  /**
   *
   * @param project
   * @returns {*}
   */
  countTotalSummaryQuote = (project) => {
    const additionalCosts = project.bid
      ? Number(project.bid.vfx_supv_on_set_cost) +
        Number(project.bid.vfx_supv_post_cost) +
        Number(project.bid.vfx_supv_prep_cost)
      : 0;

    const discounts = project.bid
      ? -Number(project.bid.discount) + Number(project.bid.rush)
      : 0;

    return project.quote + additionalCosts + discounts;
  };

  /**
   *
   * @returns {XML}
   */
  render() {
    const isLoading = this.props.isLoading;
    const loadingIndicator = (
      <div className="text-center">
        <LoadingIndicator className={"my-3"} />
      </div>
    );

    const user = this.props.user;
    const data = this.props.data;


    return (
      <div className="card">
        <ImportantItemsHeader title={data.title} />

        {!isLoading ? (
          <div className="table-responsive">
            <table className="table text-nowrap">
              <thead>
                <tr>
                  <th style={{ width: "50px" }}>Due</th>
                  <th style={{ width: "300px" }}>User</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                {data.overdueInvoices ? (
                  <tr className="table-active table-border-double">
                    <td colSpan="3">Overdue Invoices</td>
                  </tr>
                ) : null}

                {(data.overdueInvoices || []).map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-center">
                        <div className="font-size-sm line-height-1">
                          <DateUSSlash date={item.due_date} />
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="mr-3">
                            <UserAvatar user={user} />
                          </div>
                          <div>
                            <span className="text-default font-weight-semibold letter-icon-title">
                              {user.name}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span className="text-default">
                          <div>
                            <Link to={`/invoice/${item.bid.project.id}`}>
                              {`${item.bid.project.name} - ${item.bid.name}`}
                            </Link>
                          </div>
                          <span className="text-muted">Overdue</span>
                        </span>
                      </td>
                    </tr>
                  );
                })}

                {data.bidsAwaitingPayments ? (
                  <tr className="table-active table-border-double">
                    <td colSpan="3">Bids Awaiting Payments</td>
                  </tr>
                ) : null}

                {(data.bidsAwaitingPayments || []).map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-center">
                        <div className="font-size-sm line-height-1">
                          <DateUSSlash date={item.due_date} />
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="mr-3">
                            <UserAvatar user={user} />
                          </div>
                          <div>
                            <span className="text-default font-weight-semibold letter-icon-title">
                              {user.name}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span className="text-default">
                          <div>
                            <Link to={`/bid/${item.recent_bid_version.id}`}>
                              {item.project.client.name} - {item.project.name}{" "}
                              {item.name}
                            </Link>
                          </div>
                          <span className="text-muted">Awaiting Payment</span>
                        </span>
                      </td>
                    </tr>
                  );
                })}

                {data.shotsPendingApproval ? (
                  <tr className="table-active table-border-double">
                    <td colSpan="3">Shots Awaiting Approval</td>
                  </tr>
                ) : null}

                {(data.shotsPendingApproval || []).map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-center">
                        <div className="font-size-sm line-height-1">
                          <DateUSSlash date={item.delivery_date} />
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="mr-3">
                            <UserAvatar user={user} />
                          </div>
                          <div>
                            <span className="text-default font-weight-semibold letter-icon-title">
                              {user.name}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span className="text-default">
                          <div>
                            <Link to={`/project/${item.project_id}`}>
                              {item.shot_number}
                            </Link>
                          </div>
                          <span className="text-muted">Awaiting Approval</span>
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          loadingIndicator
        )}
      </div>
    );
  }
}

ProjectsDealsSummary.propTypes = {
  isLoading: PropTypes.bool,
  data: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};
