/**
 * Created by piotr.pozniak@thebeaverhead.com on 28/06/2022
 */

import React, { useCallback, useRef } from "react";
import PropTypes from "prop-types";
import AsyncCreatableSelect from "react-select/async-creatable";
import AsyncSelect from "react-select/async";

let debounceRef = null
const GenericAsyncSelector = (props) => {

  /**
   *
   * @param inputValue
   * @returns {Promise<*>}
   * @private
   */
  const _promiseOptions = async (inputValue) => {
    const options = await props.fetchItems(inputValue);

    if (!options) {
      return [];
    }

    const selectableOptions = options.map(props.formatItem);


    if (props.addEmptyItem) {
      return [
        {
          label: props.emptyItemLabel,
          value: props.emptyItemValue,
          data: {
            name: props.emptyItemLabel,
            id: props.emptyItemValue,
          },
        },
        ...selectableOptions,
      ];
    }

    return selectableOptions;
  };

  /**
   *
   * @param inputValue
   * @returns {Promise<*>}
   */
  const promiseOptions = useCallback(
    (inputValue, callback) => {
      if (debounceRef) {
        clearTimeout(debounceRef);
      }

      debounceRef = setTimeout(async () => {
        const options = await _promiseOptions(inputValue);
        callback(options);
      }, 350);
    },
    [debounceRef]
  );

  /**
   *
   * @param selectedOption
   * @returns {*}
   */
  const onUpdate = (selectedOption) => {
    if (selectedOption && selectedOption.data) {
      props.onUpdate(selectedOption.data);
    } else {
      // in case of isMulti the value is array
      props.onUpdate(selectedOption);
    }
  };

  const ChosenAsyncSelect = props.creatable
    ? AsyncCreatableSelect
    : AsyncSelect;

  return (
    <ChosenAsyncSelect
      cacheOptions
      defaultOptions
      defaultValue={props.defaultValue}
      onChange={onUpdate}
      loadOptions={promiseOptions}
      value={props.value}
      {...props.reactSelectProps}
    />
  );
};

GenericAsyncSelector.defaultProps = {};

GenericAsyncSelector.propTypes = {
  fetchItems: PropTypes.func.isRequired,
  formatItem: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  creatable: PropTypes.bool,
  defaultValue: PropTypes.object,
  value: PropTypes.object,
  reactSelectProps: PropTypes.object,

  addEmptyItem: PropTypes.bool,
  emptyItemLabel: PropTypes.string,
  emptyItemValue: PropTypes.string,
};

export default GenericAsyncSelector;
