import React, { useCallback } from "react";
import PropTypes from "prop-types";

const NotesPreviewFormat = (props) => {
  const { shot, isExpanded, onClick } = props;

  /**
   *
   * @param id
   * @returns {function(...[*]=)}
   */
  const onNoteClick = useCallback(
    (id) => (event) => {
      if (onClick) {
        onClick(event, id);
      }
    },
    [isExpanded, onClick]
  );

  const arrow = isExpanded ? (
    <i className="mi-keyboard-arrow-down expander-button-icon-custom" />
  ) : (
    <i className="mi-keyboard-arrow-right expander-button-icon-custom" />
  );

  const lastNote =
    shot.shot_notes && shot.shot_notes.length
      ? shot.shot_notes[0].note
      : shot.recent_shot_note && shot.recent_shot_note.note;

  const notePreview = lastNote ? lastNote : "--";

  return (
    <div className="cursor-pointer" onClick={onNoteClick(shot.id)}>
      {arrow}
      {notePreview}
    </div>
  );
};

NotesPreviewFormat.propTypes = {
  shot: PropTypes.object,
  isExpanded: PropTypes.bool,
  onClick: PropTypes.func,
};

export default NotesPreviewFormat;
