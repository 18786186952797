/**
 * Created by piotr.pozniak@thebeaverhead.com on 17/05/2022.
 */
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as dashboardActions from "../actions/dashboard";

export function useWorkloadDashboardStore() {
  const dispatch = useDispatch();
  const _workloadDashboard = useSelector((store) => store.workloadDashboard);

  const fetchArtistsWorkload = useCallback(
    async (artistId, status, clientId, projectId) =>
      await dispatch(
        dashboardActions.fetchArtistsWorkload(
          artistId,
          status,
          clientId,
          projectId
        )
      ),
    [dispatch]
  );

  const fetchProjectsWorkload = useCallback(
    async (artistId, status, clientId, projectId) =>
      await dispatch(
        dashboardActions.fetchProjectsWorkload(
          artistId,
          status,
          clientId,
          projectId
        )
      ),
    [dispatch]
  );

  return {
    workloadDashboard: _workloadDashboard,
    fetchArtistsWorkload,
    fetchProjectsWorkload,
  };
}
