import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import DateUSSlash from "../../../components/DateUSSlash";
import MoneyUSFormat from "../../../components/MoneyUSFormat";
import InvoicesIssuedList from "../../InvoicesPage/components/InvoicesIssuedList";
import StatusEditable from "../../../components/StatusEditable";
import { useBidStore } from "../../../storeHooks/bid";
import { useAppStore } from "../../../storeHooks/app";
import { useProjectStore } from "../../../storeHooks/project";
import useEditStatus from "./useEditStatus";

const GroupedBidRow = (props) => {
  const { data, user = {}, statuses } = props;
  const dispatch = useDispatch();

  const { setEditProject } = useProjectStore();
  const { clearErrors } = useAppStore();
  const { bid, editBid } = useBidStore();
  const issuedInvoices = data.project.issued_invoices
    ? data.project.issued_invoices.filter((i) => i.bid_id === data.id)
    : null;

  const { loading, onChangeStatus } = useEditStatus(data.id, bid, editBid);
  /**
   *
   */
  const onBeforeGoWithRowLink = useCallback(() => {
    setEditProject(null);
  }, []);

  const projectUrl = data && data.id ? `/project/${data.project_id}` : "";
  const bidUrl =
    data && data.recent_bid && data.recent_bid.id
      ? `/bid/${data.recent_bid.id}`
      : "";
  const invoiceUrl = data && data.id ? `/invoice/${data.id}` : "";
  const goToProjectUrl = ["accountant"].includes(user.role)
    ? bidUrl
    : projectUrl;
  const goToInvoiceUrl = ["accountant"].includes(user.role)
    ? invoiceUrl
    : projectUrl;

  const quoteTotal = data.total;
  const depositTotal = data.deposit;

  const name = `${data.project.name} - ${data.name}`;
  const quote = <MoneyUSFormat amount={quoteTotal} />;
  const deposit = <MoneyUSFormat amount={depositTotal} />;

  return (
    <tr>
      <td className="text-center">
        <div className="font-size-sm line-height-1">
          <DateUSSlash date={data.project.due_date} />
        </div>
      </td>
      <td>
        <span className="text-default">
          {goToInvoiceUrl ? (
            <Link onClick={onBeforeGoWithRowLink} to={goToInvoiceUrl}>
              {name}
            </Link>
          ) : (
            name
          )}
        </span>
      </td>
      <td>
        <span className="text-default">
          <StatusEditable
            status={data.status}
            statuses={statuses}
            loading={loading}
            onChange={onChangeStatus}
            isFixedPosition={false}
          />
        </span>
      </td>
      <td>
        <span className="text-default">
          {goToProjectUrl ? (
            <Link onClick={onBeforeGoWithRowLink} to={goToProjectUrl}>
              {quote}
            </Link>
          ) : (
            quote
          )}
        </span>
      </td>
      <td>
        <span className="text-default">
          {goToProjectUrl ? (
            <Link onClick={onBeforeGoWithRowLink} to={goToProjectUrl}>
              {deposit}
            </Link>
          ) : (
            deposit
          )}
        </span>
      </td>
      <td>
        <span className="text-default">
          <Link to={invoiceUrl}>
            <InvoicesIssuedList
              invoice={{ issued_invoices: issuedInvoices }}
              filler="--"
            />
          </Link>
        </span>
      </td>
    </tr>
  );
};

GroupedBidRow.propTypes = {
  data: PropTypes.object.isRequired,
  user: PropTypes.object,
  statuses: PropTypes.array.isRequired,
};

export default GroupedBidRow;
