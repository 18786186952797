import axios from "axios";
import AxiosConfig from "../AxiosConfig";

/**
 *
 * @param name
 * @param email
 * @param password
 * @returns {Function}
 */
export const createProfile = (name, email, password) => {
  return async (dispatch) => {
    dispatch({ type: "USER_REGISTER" });

    const url = AxiosConfig.getEndpointAddress() + "/api/users.json";

    await axios
      .post(
        url,
        {
          name,
          email,
          password,
        },
        AxiosConfig.getConfig()
      )
      .then((response) => {
        AxiosConfig.setAuthToken(response.data.token);

        dispatch({
          type: "USER_REGISTER_FULFILLED",
          data: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "USER_REGISTER_REJECTED",
          data: error,
        });
      });
  };
};

/**
 *
 * @param email
 * @param password
 * @returns {Function}
 */
export const login = (email, password) => {
  return async (dispatch) => {
    dispatch({ type: "USER_LOGIN" });

    const url = AxiosConfig.getEndpointAddress() + "/api/users/login.json";

    await axios
      .post(
        url,
        {
          email,
          password,
        },
        AxiosConfig.getConfig()
      )
      .then((response) => {
        AxiosConfig.setAuthToken(response.data.token);

        dispatch({
          type: "USER_LOGIN_FULFILLED",
          data: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "USER_LOGIN_REJECTED",
          data: error,
        });
      });
  };
};

/**
 *
 * @param email
 * @returns {Function}
 */
export const passwordReminder = (email) => {
  return async (dispatch) => {
    dispatch({ type: "USER_PASSWORD_RESET" });

    const url =
      AxiosConfig.getEndpointAddress() + "/api/users/remind_password.json";

    await axios
      .post(
        url,
        {
          email,
        },
        AxiosConfig.getConfig()
      )
      .then((response) => {
        dispatch({
          type: "USER_PASSWORD_RESET_FULFILLED",
          data: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "USER_PASSWORD_RESET_REJECTED",
          data: error,
        });
      });
  };
};

/**
 *
 * @param email
 * @param reset_password_token
 * @param password
 * @returns {Function}
 */
export const passwordReset = (email, reset_password_token, password) => {
  return async (dispatch) => {
    dispatch({ type: "USER_PASSWORD_RESET" });

    const url =
      AxiosConfig.getEndpointAddress() + "/api/users/reset_password.json";

    await axios
      .put(
        url,
        {
          email,
          reset_password_token,
          password,
        },
        AxiosConfig.getConfig()
      )
      .then((response) => {
        if (response.data.token) {
          AxiosConfig.setAuthToken(response.data.token);
        }

        dispatch({
          type: "USER_PASSWORD_RESET_FULFILLED",
          data: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "USER_PASSWORD_RESET_REJECTED",
          data: error,
        });
      });
  };
};

/**
 *
 * @param id
 * @param data
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export const editProfile = (id, data) => {
  return (dispatch) => {
    dispatch({ type: "USER_PROFILE_EDIT" });

    const url = AxiosConfig.getEndpointAddress() + "/api/users/" + id + ".json";

    return axios
      .put(url, data, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "USER_PROFILE_EDIT_FULFILLED",
          data: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "USER_PROFILE_EDIT_REJECTED",
          data: error,
        });
      });
  };
};

/**
 *
 * @returns {Function}
 */
export const logout = () => {
  return (dispatch) => {
    dispatch({ type: "LOGOUT" });
    AxiosConfig.setAuthToken(null);
  };
};

export const checkSession = (isLogged) => {
  return async (dispatch) => {
    dispatch({ type: "CHECK_SESSION" });

    const url =
      AxiosConfig.getEndpointAddress() + "/api/users/check_session.json";

    await axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({ type: "CHECK_SESSION_FULFILLED", data: response.data });
      })
      .catch(function (error, a) {
        let isPasswordResetRequired = false;
        if (error.response && error.response.status === 426) {
          isPasswordResetRequired = true;
        }
        dispatch({
          type: "CHECK_SESSION_REJECTED",
          data: error,
          isPasswordResetRequired,
        });
      });
  };
};
