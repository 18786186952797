/**
 * Created by piotr.pozniak@thebeaverhead.com on 17/11/2021.
 */
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import DropdownMenu from "../../../components/DropdownMenu";
import { history } from "../../../configureStore";

const BidLink = (props) => {
  const { projectName, bidsCollection } = props;

  if (bidsCollection.length > 1) {
    const buttons = bidsCollection
      .filter(i => i.recent_bid_version)
      .map((i) => {
      return {
        label: i.name,
        action: () => history.push(`/bid/${i.recent_bid_version.id}`),
      };
    });

    buttons.unshift({
      label: "Go to bid:",
      disabled: true,
    });

    return (
      <DropdownMenu
        buttons={buttons}
        openButton={
          <span
            data-popup="tooltip"
            data-placement="right"
            data-original-title="Go to related bid's page"
            data-toggle="dropdown"
            className={"text-white hover-underline"}
          >
            {projectName}
          </span>
        }
      />
    );
  }

  return (
    <Link
      to={`/bid/${bidsCollection[0].recent_bid_version.id}`}
      className="text-white hover-underline"
    >
      <span
        data-popup="tooltip"
        data-placement="right"
        data-original-title="Go to related bid's page"
      >
        {projectName}
      </span>
    </Link>
  );
};

BidLink.propTypes = {
  projectName: PropTypes.string,
  bidsCollection: PropTypes.array,
};

export default BidLink;
