import AxiosConfig from "../AxiosConfig";
import axios from "axios";

/**
 *
 * @param fromDate
 * @param toDate
 * @param isPerShot
 * @param type
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export const reportsGainLossFetch = (fromDate, toDate, isPerShot = 0, type) => {

  return dispatch => {

    dispatch({
      type: "LOSS_REPORTS_FETCH",
      data: {
        fromDate,
        toDate,
        isPerShot,
        type,
      }
    });

    const query = {
      start_date: fromDate,
      end_date: toDate,
      is_per_shot: isPerShot,
    };

    const url = AxiosConfig.getEndpointAddress() + '/api/statistics/loss.json?'
      + AxiosConfig.objectToURLQuery(query);

    return axios.get(
      url,
      AxiosConfig.getAuthConfig(),
    )
      .then((response) => {

        dispatch({
          type: "LOSS_REPORTS_FETCH_FULFILLED",
          data: response.data,
        });

      })
      .catch(function (error) {

        dispatch({
          type: "LOSS_REPORTS_FETCH_REJECTED",
          data: error,
        });

      });

  }
};
