import io from "socket.io-client";
import WSConfig from "../WSConfig";
import AxiosConfig from "../AxiosConfig";

let socket = null;
let roomNameCommon = null;
let roomIdCommon = null;

/**
 *
 * @param roomName
 * @param roomId
 * @returns {(function(*): Promise<void>)|*}
 */
export const connectRTC = (roomName, roomId) => {
  roomNameCommon = roomName;
  roomIdCommon = roomId;
  return async (dispatch) => {
    dispatch({ type: "RTC_CONNECTION" });

    disconnect();

    socket = await io(WSConfig.getEndpointAddress("rtc"), {
      transportOptions: {
        polling: {
          extraHeaders: {
            "x-auth-token": AxiosConfig.getAuthToken(),
          },
        },
      },
    })
      .on("connect", () => {
        dispatch({ type: "RTC_CONNECTION_FULFILLED" });

        socket.emit(
          "joinRoom",
          { room: { name: roomNameCommon, id: roomIdCommon } },
          (result) => {}
        );
      })
      .on("getEntityState", (data) => {
        dispatch({ type: "RTC_GET_ENTITY_STATE_UPDATE", payload: data });
      })
      .on("disconnect", (e) => {
        dispatch({ type: "RTC_CONNECTION_DISCONNECTED", payload: e });
      })
      .on("error", (e) => {
        dispatch({ type: "RTC_CONNECTION_ERROR", payload: e });
      });
  };
};

/**
 *
 */
const disconnect = () => {
  if (socket && socket.connected) {
    socket.off("connect");
    socket.off("getEntityState");
    socket.off("error");

    socket.disconnect();
    socket = null;
  }
};

/**
 *
 * @param data
 * @returns {boolean}
 */
const updateEntity = (data) => {
  if (!socket || !socket.connected) {
    return false;
  }

  socket.emit(
    "setEntityUpdate",
    {
      room: { name: roomNameCommon, id: roomIdCommon },
      state: { state: { ...data } },
    },
    (result) => {}
  );
};

/**
 *
 * @returns {(function(*): Promise<void>)|*}
 */
export const disconnectRTC = () => {
  return async (dispatch) => {
    dispatch({ type: "RTC_DISCONNECT" });
    disconnect();
  };
};

/**
 *
 * @param data
 * @returns {(function(*): Promise<void>)|*}
 */
export const setEntityUpdate = (data) => {
  return async (dispatch) => {
    dispatch({
      type: "RTC_SET_ENTITY_STATE_UPDATE",
      payload: { room: roomNameCommon, id: roomIdCommon, data },
    });
    updateEntity(data);
  };
};
