/**
 * Created by piotr.pozniak@thebeaverhead.com on 20/04/2023
 */

import React, { useCallback } from "react";
import PropTypes from "prop-types";
import HasRights from "../../../components/HasRights";
import { generatePDFFilename } from "../../../helpers/bid";

const BidVersionControls = ({
  isPreview,
  userModel,
  isLoading,
  isEdited,
  isUpdating,
  onSaveNewVersion,
  onCancel,
  onSaveChanges,
}) => {
  return (
    <HasRights
      allowedRoles={["admin", "producer"]}
      user={isPreview ? null : userModel}
    >
      <div className="col-lg-12 mb-2 mb-lg-0 mr-2">
        <div className="d-flex justify-content-end">
          <button
            type="button"
            className="btn btn-link ml-2  mb-2 mb-lg-0"
            onClick={onCancel}
            disabled={isLoading || isUpdating}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-outline-primary ml-2  mb-2 mb-lg-0"
            onClick={onSaveNewVersion}
            disabled={isLoading || isUpdating}
          >
            Save as new version
          </button>

          <button
            type="button"
            className="btn bg-primary ml-lg-2 mb-2 mb-lg-0"
            onClick={onSaveChanges}
            disabled={isLoading || isUpdating || !isEdited}
          >
            Save changes
          </button>
        </div>
      </div>
    </HasRights>
  );
};

BidVersionControls.defaultProps = {};

BidVersionControls.propTypes = {
  isPreview: PropTypes.bool,
  userModel: PropTypes.object,
  isLoading: PropTypes.bool,
  isUpdating: PropTypes.bool,
  onCancel: PropTypes.func,
  onSaveNewVersion: PropTypes.func,
  onSaveChanges: PropTypes.func,
};

export default BidVersionControls;
