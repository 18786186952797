import React, { useCallback } from "react";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import ImportantItemsHeader from "./ImportantItemsHeader";
import GroupedShotsRow from "./GroupedShotsRow";
import StatusBadge from "../../../components/StatusBadge";
import useStatusHook from "../../../hooks/useStatusHook";
import StatusEditable from "../../../components/StatusEditable";
import { useStatusesStore } from "../../../storeHooks/statuses";
import GroupedTasksRow from "./GroupedTasksRow";

const GroupedTasks = (props) => {
  const { data } = props;

  const collection = data.collection || [];
  const { statuses } = useStatusesStore();
  const { statuses: tasksStatuses } = useStatusHook("Tasks");

  let lastStatus = "";

  return (
    <div className="card mb-0">
      <ImportantItemsHeader title={data.title} />

      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th width={"15%"}>Shot Name</th>
              <th width={"21%"}>Type</th>
              <th>Description</th>
              <th width={"24%"}>Status</th>
            </tr>
          </thead>
          <tbody>
            {collection.map((task) => {
              if (task.status !== lastStatus) {
                lastStatus = task.status;
                return (
                  <React.Fragment key={`grouped-task-${task.id}`}>
                    <tr className="table-active table-border-double">
                      <td colSpan="5">
                        <StatusBadge
                          status={task.status}
                          statuses={tasksStatuses}
                        />
                      </td>
                    </tr>
                    <GroupedTasksRow data={task} statuses={tasksStatuses} />
                  </React.Fragment>
                );
              } else {
                return (
                  <GroupedTasksRow
                    data={task}
                    statuses={tasksStatuses}
                    key={`grouped-task-${task.id}`}
                  />
                );
              }
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

GroupedTasks.propTypes = {
  data: PropTypes.object.isRequired,
};

export default GroupedTasks;
