import React, { Component } from 'react';

import UnauthorizedPageTemplate from './UnauthorizedPageTemplate';
import * as userActions from "../actions/user";
import connect from "react-redux/es/connect/connect";

import LoadingIndicator from '../components/LoadingIndicator';
import SuccessMessage from '../components/SuccessMessage';
import ApiError from '../components/ApiError';
import SeoBlock from "../components/SeoBlock";

class PasswordReminderPage extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  /**
   *
   * @param event
   * @returns {Promise<void>}
   */
  async onRemindPress(event) {
    event.preventDefault();
    await this.props.passwordReminder(
      document.querySelector('.js-user-password-reminder-email').value,
    );
    if (this.props.user.passwordResetSuccess) {
      document.querySelector('.js-user-password-reminder-email').value = null;
    }
  };

  /**
   *
   * @returns {XML}
   */
  render() {
    const user = this.props.user;
    const loadingIndicator = user.passwordReset ? <LoadingIndicator /> : null;

    return (
      <UnauthorizedPageTemplate>
        <SeoBlock title={"Password recovery"}/>
        <form className="login-form">
          <div className="card mb-0">
            <div className="card-body">
              <div className="text-center mb-3">
                <i className="icon-spinner11 icon-2x text-warning border-warning border-3 rounded-round p-3 mb-3 mt-1" />
                <h5 className="mb-0">Password recovery</h5>
                <span className="d-block text-muted">Reset password instructions will be sent to your email.</span>
              </div>

              {loadingIndicator}

              <div className="form-group form-group-feedback form-group-feedback-right">
                <input type="email" className="form-control js-user-password-reminder-email" placeholder="Your email" />
                <div className="form-control-feedback">
                  <i className="icon-mail5 text-muted" />
                </div>
                <ApiError error={user.passwordResetError} />
                <SuccessMessage isSuccess={user.passwordResetSuccess}
                  message="An email with password resent link has been sent. Please check your mailbox." />
              </div>

              <button type="submit" className="btn bg-blue btn-block" onClick={this.onRemindPress.bind(this)}>
                <i className="icon-spinner11 mr-2" /> Reset password
              </button>
            </div>
          </div>
        </form>
      </UnauthorizedPageTemplate>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  passwordReminder: (email) => dispatch(userActions.passwordReminder(email)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordReminderPage);
