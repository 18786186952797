import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import PageTemplate from "./PageTemplate";
import * as usersActions from "../actions/users";
import connect from "react-redux/es/connect/connect";
import { history } from "../configureStore";
import DataTable from "react-data-table-component";
import DataGridPagination from "../components/DataGridPagination";
import LoadingIndicator from "../components/LoadingIndicator";
import DropdownMenu from "../components/DropdownMenu";
import StaticAlert from "../components/StaticAlert";
import { ucFirst } from "../helpers/error";
import UserAvatar from "../components/UserAvatar";
import SeoBlock from "../components/SeoBlock";

const PendingApprovalStatus = ({ users, email, role }) => {
  if (users.approve || users.decline) {
    return <LoadingIndicator />;
  }

  if (users.approveSuccess || users.declineSuccess) {
    const approvalStatus = users.approveSuccess
      ? `approved as ${role}`
      : "declined";
    const staticAlertBody = (
      <>
        Profile with email <a href={`mailto:${email}`}>{email}</a> has been{" "}
        {approvalStatus}
      </>
    );
    return (
      <StaticAlert
        type="success"
        header={`Profile ${approvalStatus}!`}
        body={staticAlertBody}
      />
    );
  }

  if (users.approveError || users.declineError) {
    const approvalStatusA = users.approveError ? "Approval" : "Rejection";
    const approvalStatusB = users.approveError ? "approved" : "rejected";
    const staticAlertBody = `
    Could not perform this action. Please ensure this 
    user has been already ${approvalStatusB} by checking
    the user's list below
    `;
    return (
      <StaticAlert
        type="danger"
        header={`${approvalStatusA} failed`}
        body={staticAlertBody}
      />
    );
  }

  return null;
};

class UsersPage extends Component {
  constructor(props) {
    super(props);

    this.state = { pendingApprovalUser: undefined };

    this.updateDataTableConfig();
  }

  /**
   *
   */
  componentDidMount() {
    if (this.handleRedirectFromBackend()) {
      return;
    }

    this.tryPaginationRedirect();

    this.setState({ pendingApprovalUser: this.props.location.state });

    if (this.props.location.state) {
      const { token, email, role, approve, reject } = this.props.location.state;
      if (approve) {
        this.props.approveUser(email, token, role);
      }
      if (reject) {
        this.props.declineUser(email, token);
      }
      this.props.history.replace({ state: undefined });
    }
  }

  /**
   *
   */
  componentDidUpdate(prevProps) {
    if (prevProps.match.params.role !== this.props.match.params.role) {
      this.tryPaginationRedirect();
    } else if (prevProps.location.pathname !== this.props.location.pathname) {
      this.fetchUsers();
      this.updateDataTableConfig();
    }

    if (
      prevProps.users.fetchError !== this.props.users.fetchError &&
      this.props.users.fetchError
    ) {
      const role = this.props.match.params.role;
      const page = Number(this.props.match.params.page);
      const verified = this.props.match.params.verified || null;
      if (page > 1) {
        history.replace(`/users/${role}/1/${verified || ""}`);
      }
    }
  }

  /**
   *
   */
  tryPaginationRedirect = () => {
    const role = this.props.match.params.role;
    const verified = this.props.match.params.verified || null;
    const pages = {
      user: this.props.filtersReducer.pendingUsersPage,
      client: this.props.filtersReducer.clientUsersPage,
      artist: this.props.filtersReducer.artistUsersPage,
      accountant: this.props.filtersReducer.accountantUsersPage,
      producer: this.props.filtersReducer.producerUsersPage,
      admin: this.props.filtersReducer.adminUsersPage,
    };

    if (pages[role] !== this.props.match.params.page) {
      const page = pages[role] || 1;
      history.replace(`/users/${role}/${page}/${verified || ""}`);
    } else {
      this.fetchUsers();
    }
  };

  /**
   *
   * @returns {boolean}
   */
  handleRedirectFromBackend = () => {
    if (
      this.props.match.params.role === "accepted" &&
      decodeURIComponent(this.props.match.params.page).indexOf("@") !== -1
    ) {
      history.push("/users/user/1/0", {
        userApproving: {
          approved: true,
          email: decodeURIComponent(this.props.match.params.page),
        },
      });
      return true;
    } else if (
      this.props.match.params.role === "rejected" &&
      decodeURIComponent(this.props.match.params.page).indexOf("@") !== -1
    ) {
      history.push("/users/user/1/0", {
        userApproving: {
          approved: false,
          email: decodeURIComponent(this.props.match.params.page),
        },
      });
      return true;
    }
  };

  /**
   *
   */
  fetchUsers() {
    if (this.props.user.token && this.props.user.model) {
      const page = this.props.match.params.page || null;
      const role = this.props.match.params.role;
      const verified = this.props.match.params.verified || null;

      this.props.fetchUsers(
        page,
        this.usersDataTableConfig.perPage,
        verified,
        role
      );
    }
  }

  /**
   *
   * */
  updateDataTableConfig = () => {
    const isPendingApprovalPage =
      this.props.match.params.verified &&
      this.props.match.params.verified === "0";

    const pendingApprovalButton = (
      <button
        type="button"
        className="btn btn-primary dropdown-toggle"
        data-toggle="dropdown"
      >
        Accept
      </button>
    );

    const pendingApprovalActions = (row) => {
      const pendingApprovalDropdownOptions = [
        {
          label: "as Admin",
          data: row,
          action: (row) => this.onApproveUser(row, "admin"),
        },
        {
          label: "as Producer",
          data: row,
          action: (row) => this.onApproveUser(row, "producer"),
        },
        {
          label: "as Artist",
          data: row,
          action: (row) => this.onApproveUser(row, "artist"),
        },
        {
          label: "as Accountant",
          data: row,
          action: (row) => this.onApproveUser(row, "accountant"),
        },
        {
          label: "as Client",
          data: row,
          action: (row) => this.onApproveUser(row, "client"),
        },
      ];

      return (
        <div className="d-flex py-1">
          <button
            type="button"
            className="btn btn-danger mr-2"
            onClick={() => this.onDeclineUser(row)}
          >
            Decline
          </button>
          <DropdownMenu
            buttons={pendingApprovalDropdownOptions}
            openButton={pendingApprovalButton}
          />
        </div>
      );
    };

    const defaultActions = (row) => (
      <DropdownMenu
        buttons={[
          {
            icon: "icon-user-cancel",
            action: this.onDeleteUser,
            data: row,
            label: "Delete",
          },
          {
            icon: "icon-users2",
            action: this.onEditUser,
            data: row,
            label: "Edit User",
          },
        ]}
      />
    );

    this.usersDataTableConfig = {
      perPage: 10,
      columns: [
        {
          name: "",
          cell: (row) =>
            Object.keys(row).length ? (
              <div className="py-1">
                <UserAvatar user={row} showTitle={true} key={row.id} />
              </div>
            ) : null,
        },
        {
          name: "Name",
          selector: "name",
        },
        {
          name: "E-mail",
          selector: "email",
          grow: 2,
          format: (row) => (
            <a className="relative" href={"mailto:" + row.email}>
              {row.email}
            </a>
          ),
        },
        {
          name: "Last Login",
          selector: "last_login",
          format: (row) => {
            if (row.last_login === undefined) {
              return "--";
            }
            return row.last_login === null
              ? "Has never logged in"
              : new Date(row.last_login * 1000).toLocaleString();
          },
        },
        {
          name: "Verified",
          selector: "verified",
          format: (row) => {
            if (row.verified === undefined) {
              return "--";
            }
            return row.verified ? "Yes" : "No";
          },
        },
        {
          name: "Suspended",
          selector: "available",
          format: (row) => {
            return row.suspended ? "Yes" : "No";
          },
        },
        {
          name: "Actions",
          right: true,
          cell: (row) =>
            row.id
              ? isPendingApprovalPage
                ? pendingApprovalActions(row)
                : defaultActions(row)
              : "--",
        },
      ],
    };
  };

  /**
   *
   * @param page
   */
  onChangePage(page) {
    const role = this.props.match.params.role;
    const verified = this.props.match.params.verified || "";
    this.props.history.push(`/users/${role}/${page}/${verified}`);
  }

  /**
   *
   * @param user
   */
  onDeleteUser = (user) => {
    if (window.confirm("Are you sure you want to delete " + user.name + "?")) {
      this.props.deleteUser(user.id);
    }
  };

  /**
   *
   * @param user
   */
  onEditUser = (user) => {
    this.props.history.push(`/user/${user.id}`);
  };

  /**
   *
   * @param user
   * */
  onDeclineUser = (user) => {
    if (window.confirm("Do you really want to decline this user?")) {
      this.setState({ pendingApprovalUser: user });
      this.props.declineUser(user.email, user.verify_token);
    }
  };

  /**
   *
   * @param user
   * @param role
   */
  onApproveUser = (user, role) => {
    this.setState({ pendingApprovalUser: { email: user.email, role } });
    this.props.approveUser(user.email, user.verify_token, role);
  };

  /**
   *
   * @returns {XML}
   */
  render() {
    const users = this.props.users;
    const role = this.props.match.params.role;
    const verified = this.props.match.params.verified;
    const location = this.props.location;
    const collection = users.collection;
    const mock = [{}];

    const pagination =
      collection && collection.length ? (
        <DataGridPagination
          onChangePage={this.onChangePage.bind(this)}
          pagination={users.pagination}
        />
      ) : null;

    const verifiedText =
      verified === "0" && role === "user" ? "unverified" : "";
    const staticAlertText = `This page lists all the ${verifiedText} ${role}s.`;

    const dataTableClassNames = classNames(
      "data-table-themed",
      "data-table-themed_not-stretch",
      "data-table-themed_short"
    );

    const dataTable = collection ? (
      <div className={dataTableClassNames}>
        <DataTable
          noHeader
          responsive={false}
          columns={this.usersDataTableConfig.columns}
          data={collection.length ? collection : mock}
          className={collection.length ? null : "data-table-empty"}
        />
        {pagination}
      </div>
    ) : null;

    const staticAlert =
      collection && !collection.length ? (
        <StaticAlert header="No records to display" body={staticAlertText} />
      ) : null;

    const roleName =
      verified === "0" && role === "user" ? "Pending approval" : ucFirst(role);

    const pageHeader = (
      <>
        <span className={"h1"}>{`Users - ${roleName}`}</span>
        <span className={"h4"}>
          {this.props.users.delete && (
            <div className="h-0 fs-14 fs-089rem">
              <LoadingIndicator className="d-inline" />
            </div>
          )}
        </span>
      </>
    );

    const pendingApprovalStatus = this.state.pendingApprovalUser ? (
      <PendingApprovalStatus
        users={users}
        email={this.state.pendingApprovalUser.email}
        role={this.state.pendingApprovalUser.role}
      />
    ) : null;

    const backendUserAcception =
      location.state && location.state.userApproving ? (
        location.state.userApproving.approved ? (
          <StaticAlert
            type="success"
            header={location.state.userApproving.email}
            body={"Has been approved!"}
          />
        ) : (
          <StaticAlert
            type="danger"
            header={location.state.userApproving.email}
            body={"Has been declined."}
          />
        )
      ) : null;

    const loadingIndicator = users.fetch ? <LoadingIndicator isModal /> : null;

    return (
      <PageTemplate header={pageHeader}>
        <SeoBlock title={`Users - ${roleName}`}/>
        {pendingApprovalStatus}
        {backendUserAcception}
        {collection !== null && dataTable}
        {staticAlert}
        {loadingIndicator}
      </PageTemplate>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  users: state.users,
  filtersReducer: state.filtersReducer,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUsers: (page, limit, verified, role) =>
    dispatch(usersActions.fetch(page, limit, verified, role)),
  approveUser: (email, verify_token, role) =>
    dispatch(usersActions.approve(email, verify_token, role)),
  declineUser: (email, verify_token) =>
    dispatch(usersActions.decline(email, verify_token)),
  deleteUser: (id) => dispatch(usersActions.deleteUser(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UsersPage));
