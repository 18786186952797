/**
 * Created by piotr.pozniak@thebeaverhead.com on 26/01/2023
 */

import React, { useState, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { useShotStore } from "../../../storeHooks/shot";
import HasRights from "../../../components/HasRights";
import HoveredEdit from "../../../components/HoveredEdit";
import ShotDescription from "./ShotDescription";
import StatusEditable from "../../../components/StatusEditable";
import { hasRights, increaseNumberInString } from "../../../helpers/tools";
import { useAppStore } from "../../../storeHooks/app";
import { useUserStore } from "../../../storeHooks/user";
import { shotVersionMaxLength } from "../../../consts/shots";
import { useStatusesStore } from "../../../storeHooks/statuses";
import { useParams } from "react-router-dom";
import { useProjectStore } from "../../../storeHooks/project";

const PageHeader = (props) => {
  const { shot, editShot, createShotUpdate } = useShotStore();
  const { project } = useProjectStore();
  const { user } = useUserStore();
  const { statuses: storeStatuses } = useStatusesStore();
  const routerParams = useParams();

  const statuses = storeStatuses.collection.filter((i) => i.type === "Shots");
  const isEditing = shot.edit || shot.shotUpdateCreate;

  const isRefreshing =
    shot.fetch && shot.model && shot.model.id === routerParams.shotId;
  const isProjectRefreshing =
    project.fetch && project.model && project.model.id === routerParams.id;

  const shotModel = shot.model || {};
  const pureTasks = shotModel.tasks || [];

  const { clearErrors } = useAppStore();

  const versionInputRef = useRef(null);
  const aeProjectInputRef = useRef(null);
  const framesCountInputRef = useRef(null);
  const elementsInputRef = useRef(null);

  const [versionInputValue, setVersionInputValue] = useState("");
  const [aeProjectInputValue, setAeProjectInputValue] = useState("");
  const [framesCountInputValue, setFramesCountInputValue] = useState("");
  const [elementsInputValue, setElementsInputValue] = useState("");

  /**
   *
   * @param event
   */
  const onStatusChange = useCallback(
    (value) => {
      if (value) {
        clearErrors();
        editShot(shotModel.id, {
          status: value,
        }).then(() => {
          setTimeout(() => window.updateJQuery, 0);
        });
        window.updateJQuery();
      }
    },
    [shotModel]
  );

  /**
   *
   */
  const onAeProjectChange = useCallback(async () => {
    clearErrors();
    setAeProjectInputValue(aeProjectInputRef.current.value);

    await editShot(shotModel.id, {
      project_file: aeProjectInputRef.current.value,
    });
    resetLastValues();
  }, [shotModel, aeProjectInputValue]);

  /**
   *
   * @returns {Promise<void>}
   */
  const onFramesCountChange = useCallback(async () => {
    clearErrors();
    setFramesCountInputValue(framesCountInputRef.current.value);

    await editShot(shotModel.id, {
      frames_count: framesCountInputRef.current.value || 0,
    });
    resetLastValues();
  }, [shotModel, framesCountInputValue]);

  /**
   *
   * @returns {Promise<void>}
   */
  const onElementsChange = useCallback(async () => {
    clearErrors();
    setElementsInputValue(elementsInputRef.current.value);
    await editShot(shotModel.id, {
      elements: elementsInputRef.current.value,
    });
    resetLastValues();
  }, [shotModel, elementsInputValue]);

  /**
   *
   * @returns {Promise<void>}
   */
  const onVersionChange = useCallback(async () => {
    clearErrors();
    setVersionInputValue(versionInputRef.current.value);
    await createShotUpdate(shotModel.id, {
      version: versionInputRef.current.value,
    });
    resetLastValues();
  }, [shotModel, versionInputValue]);

  /**
   *
   * @param currentVersion
   * @returns {function(...[*]=)}
   */
  const onShotVersionIncreaseClick = useCallback(
    (currentVersion) => () => {
      const increasedVersion = increaseNumberInString(currentVersion, 1);
      if (increasedVersion.length <= shotVersionMaxLength) {
        clearErrors();
        createShotUpdate(shotModel.id, {
          version: increasedVersion,
        });
      }
    },
    [shotModel]
  );

  /**
   *
   * @type {function(*): function(*): void}
   */
  const onFocus = useCallback(
    (ref) => (event) => {
      ref.current.focus();
    },
    [versionInputRef]
  );

  /**
   *
   */
  const resetLastValues = () => {
    setVersionInputValue("");
    setAeProjectInputValue("");
    setFramesCountInputValue("");
    setElementsInputValue("");
  };

  const isCurrentUserAssignedToAnyTask = !!pureTasks.filter(
    (item) => user.model && item.assignee && item.assignee.id === user.model.id
  ).length;

  const aeProjectPlaceholder =
    aeProjectInputValue || shotModel.project_file || "None";

  const framesCountPlaceholder =
    framesCountInputValue ||
    (shotModel.frames_count || shotModel.frames_count === 0
      ? shotModel.frames_count.toString()
      : null) ||
    "0";

  const elementsPlaceholder =
    elementsInputValue || shotModel.elements || "None";

  const headerStats = (
    <div className="col-12 col-lg-7">
      <div className="row mb-xl-2 relative z-index-10">
        <div className="col-12 col-xl-8 relative z-index-10 mb-2 mb-xl-0">
          <div className="d-flex align-items-baseline">
            <div className="font-weight-bold text-nowrap">
              Project File:&nbsp;
            </div>
            <div className="ml-n5px">
              <b>
                <HasRights
                  allowedRoles={["admin", "producer"]}
                  user={user.model}
                  placeholder={
                    <span className="ml-1" style={{ wordBreak: "break-all" }}>
                      {aeProjectPlaceholder}
                    </span>
                  }
                >
                  <HoveredEdit
                    className="hovered-edit_for-dark-theme text-primary w-auto"
                    preview={aeProjectPlaceholder}
                    handleConfirm={onAeProjectChange}
                    handleFocus={onFocus(aeProjectInputRef)}
                    disabled={isEditing}
                  >
                    <input
                      defaultValue={shotModel.project_file}
                      ref={aeProjectInputRef}
                      type="text"
                      className="input-pure maxw-100p"
                      placeholder="None"
                    />
                  </HoveredEdit>
                </HasRights>
              </b>
            </div>
          </div>
        </div>
        <div className="col-12 col-xl-4 mb-2 mb-xl-0">
          <div className="d-flex align-items-baseline">
            <div className="font-weight-bold text-nowrap">
              Frames count:&nbsp;
            </div>
            <div className="ml-n5px">
              <b>
                <HasRights
                  allowedRoles={["admin", "producer"]}
                  user={user.model}
                  placeholder={
                    <span className="ml-1" style={{ wordBreak: "break-all" }}>
                      {framesCountPlaceholder}
                    </span>
                  }
                >
                  <HoveredEdit
                    className="hovered-edit_for-dark-theme text-primary w-auto text-nowrap"
                    preview={framesCountPlaceholder}
                    handleConfirm={onFramesCountChange}
                    handleFocus={onFocus(framesCountInputRef)}
                    disabled={isEditing}
                  >
                    <input
                      defaultValue={shotModel.frames_count}
                      ref={framesCountInputRef}
                      type="text"
                      className="input-pure maxw-100p"
                      placeholder="0"
                    />
                  </HoveredEdit>
                </HasRights>
              </b>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 mb-2 mb-lg-0">
          <div className="d-flex align-items-baseline">
            <div className="font-weight-bold text-nowrap">Elements:&nbsp;</div>
            <div className="ml-n5px">
              <b>
                <HasRights
                  allowedRoles={["admin", "producer"]}
                  user={user.model}
                  placeholder={
                    <span className="ml-1" style={{ wordBreak: "break-all" }}>
                      {elementsPlaceholder}
                    </span>
                  }
                >
                  <HoveredEdit
                    className="hovered-edit_for-dark-theme text-primary w-auto"
                    preview={elementsPlaceholder}
                    handleConfirm={onElementsChange}
                    handleFocus={onFocus(elementsInputRef)}
                    disabled={isEditing}
                  >
                    <input
                      defaultValue={shotModel.elements}
                      ref={elementsInputRef}
                      type="text"
                      className="input-pure maxw-100p"
                      placeholder="None"
                    />
                  </HoveredEdit>
                </HasRights>
              </b>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 mb-2 mb-lg-0">
          <div className="d-flex align-items-baseline">
            <ShotDescription shot={shotModel} />
          </div>
        </div>
      </div>
    </div>
  );

  let currentVersion = "";
  if (shotModel.shot_updates && shotModel.shot_updates.length > 0) {
    const recentShotUpdate = shotModel.shot_updates[0];
    currentVersion = recentShotUpdate.version;
  }

  const versionPlaceholder = versionInputValue || currentVersion || "--";

  const currentVersionHoveredEdit = (
    <HasRights
      allowedRoles={
        isCurrentUserAssignedToAnyTask
          ? ["admin", "producer", "artist"]
          : ["admin", "producer"]
      }
      user={user.model}
      placeholder={versionPlaceholder}
    >
      <HoveredEdit
        className="hovered-edit_for-dark-theme hovered-edit_inline"
        preview={versionPlaceholder}
        previewTitle={currentVersion}
        handleConfirm={onVersionChange}
        handleFocus={onFocus(versionInputRef)}
        cutPreviewLength="130px"
        disabled={isEditing}
      >
        <input
          defaultValue={currentVersion}
          ref={versionInputRef}
          type="text"
          className="input-pure"
          placeholder="None"
          maxLength={shotVersionMaxLength}
          style={{ maxWidth: 100 }}
        />
      </HoveredEdit>
    </HasRights>
  );

  const statsUnderTitle = (
    <div className="ml-n5px">
      <div className="ml-n2">
        <StatusEditable
          key={`shot-status-editable-${isEditing}`}
          status={shotModel.status}
          statuses={statuses}
          onChange={onStatusChange}
          loading={isEditing}
          disabled={
            !(isCurrentUserAssignedToAnyTask
              ? hasRights(user.model, {}, ["admin", "producer", "artist"])
              : hasRights(user.model, {}, ["admin", "producer"])) || isEditing
          }
        />
      </div>
    </div>
  );

  return (
    <>
      <div className={"header-stats-editable-shot row"}>
        <div
          className={
            "col-12 col-lg-5 pr-0 pr-lg-5 mb-3 mb-lg-0 relative z-index-20"
          }
        >
          <span className="h1" style={{ wordBreak: "break-all" }}>
            {shotModel &&
              shotModel.shot_number &&
              `Shot ${shotModel.shot_number}`}
          </span>
          <span className="h4 relative z-index-5">
            <small className="d-block ml-0 text-nowrap">
              <button
                className="btn-reset mr-1"
                onClick={onShotVersionIncreaseClick(currentVersion)}
                disabled={isEditing}
              >
                <i className="icon-arrow-up16" />
              </button>{" "}
              Version: <b>{currentVersionHoveredEdit}</b>
            </small>
          </span>
          {statsUnderTitle}
        </div>
        {headerStats}
      </div>
      {isEditing || isRefreshing || isProjectRefreshing ? (
        <div className="h-0 fs-089rem">
          <LoadingIndicator
            label={
              isRefreshing || isProjectRefreshing
                ? "Refreshing..."
                : "Processing..."
            }
          />
        </div>
      ) : null}
    </>
  );
};

PageHeader.defaultProps = {};

PageHeader.propTypes = {};

export default PageHeader;
