import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import Notes from "../../../components/Notes";
import { useProjectStore } from "../../../storeHooks/project";
import { useShotStore } from "../../../storeHooks/shot";
import LoadingIndicator from "../../../components/LoadingIndicator";

const ShotNotes = (props) => {
  const [initialized, setInitialized] = useState(false);
  const { project } = useProjectStore();

  const { shot, fetchShotNotes, createShotNote, editShotNote, deleteShotNote } =
    useShotStore();

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
    }

  }, []);


  useEffect(() => {

    fetchShotNotes(props.shotData.id);

  }, [props.shotData.id]);



  /**
   *
   * @param shotId
   * @returns {function(...[*]=)}
   */
  const handleCreateNote = (shotId) => (note, noteModel) => {
    createShotNote(shotId, note).then(() => {
      setTimeout(window.updateJQuery, 100);
    });
  };

  /**
   *
   * @param shotNoteId
   * @param note
   * @returns {Promise<void>}
   */
  const handleEditNote = async (shotNoteId, note, noteModel) => {

    editShotNote(shotNoteId, note, noteModel.shot_id).then(() => {
      setTimeout(window.updateJQuery, 100);
    });
  };

  /**
   *
   * @param shotNoteId
   */
  const handleDeleteNote = (shotNoteId, noteModel) => {
    deleteShotNote(shotNoteId, noteModel.shot_id);
  };

  const { shotData } = props;

  return !initialized || project.shotNoteFetch && project.shotNoteFetchId === shotData.id ? (
    <LoadingIndicator className={"text-white text-center"} />
  ) : (
    <Notes
      notes={shotData.shot_notes || []}
      handleCreateNote={handleCreateNote(shotData.id)}
      handleEditNote={handleEditNote}
      handleDeleteNote={handleDeleteNote}
      noteCreate={
        project.shotNoteCreate && project.shotNoteCreateId === shotData.id
      }
      noteCreateError={
        project.shotNoteCreateId === shotData.id
          ? project.shotNoteCreateError
          : null
      }
      noteEdit={project.shotNoteEdit && project.shotNoteEditId === shotData.id}
      noteEditError={
        project.shotNoteEditId === shotData.id
          ? project.shotNoteEditError
          : null
      }
      noteDelete={
        project.shotNoteDelete && project.shotNoteDeleteId === shotData.id
      }
      noteDeleteError={project.shotNoteDeleteError}
      notesClassNames={props.notesClassNames}
      contentMaxHeight={props.contentMaxHeight}
    />
  );
};

ShotNotes.defaultProps = {
  shotData: {},
  notesClassNames: {},
};

ShotNotes.propTypes = {
  shotData: PropTypes.object.isRequired,
  notesClassNames: PropTypes.object,
  contentMaxHeight: PropTypes.number,
};

export default ShotNotes;
