/**
 * Created by piotr.pozniak@thebeaverhead.com on 09/07/2024.
 */
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as reportsInvoicesActions from "../actions/reportsInvoices";

export function useReportsInvoicesStore() {
  const dispatch = useDispatch();
  const _reportsInvoices = useSelector((store) => store.reportsInvoices);

  const fetchInvoicesReports = useCallback(
    async (fromDate, toDate, status, clients, type) =>
      await dispatch(
        reportsInvoicesActions.fetchInvoicesReports(
          fromDate,
          toDate,
          status,
          clients,
          type
        )
      ),
    [dispatch]
  );
  return {
    reportsInvoices: _reportsInvoices,
    fetchInvoicesReports,
  };
}
