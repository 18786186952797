import React, { useCallback, useState, useMemo } from "react";
// import {history} from "../configureStore";
import { withRouter } from "react-router-dom";

import ModalTemplate from "../ModalTemplate";

// import * as bidActions from "../actions/bid";
import connect from "react-redux/es/connect/connect";
import BidSelect from "../../components/Selects/BidSelect";
import ShotsTable from "./components/ShotsTable";
import LoadingIndicator from "../../components/LoadingIndicator";
import ApiError from "../../components/ApiError";
import { moveShot } from "../../actions/shot";
import { history, store } from "../../configureStore";
import { bidVersionUpdate } from "../../actions/bidVersion";
import AxiosConfig from "../../AxiosConfig";
import axios from "axios";
import { useStatusesStore } from "../../storeHooks/statuses";

const successfullyMovedShots = [];

/**
 *
 * @param bidVersio
 * @param additionalCosts
 * @returns {*|*[]}
 */
const applyAdditionalCosts = (additionalCosts) => {
  return additionalCosts && additionalCosts.length
    ? additionalCosts.map((i) => ({
        additional_costs_setting_id: i.additional_costs_setting.id,
        ...i,
      }))
    : [];
};

const MoveShotsModal = (props) => {
  const [chosenBid, setChosenBid] = useState(null);

  const { statuses } = useStatusesStore();

  const isLoading = props.shot.shotMove;
  const loadingIndicator = isLoading ? <LoadingIndicator /> : null;
  const modalTitle = "Move shots to another bid";

  const isInitialized = useMemo(
    () => statuses.collection.length > 0,
    [statuses.collection]
  );

  const moveShots = useMemo(
    () =>
      (props.history.location.state &&
        props.history.location.state.moveShots) ||
      [],
    [props.history.location.state]
  );

  const movedShots = useMemo(() => [], [moveShots]);

  /**
   *
   * @type {(function(): Promise<void>)|*}
   */
  const handleSubmit = useCallback(async () => {
    const newTargetBidVersionData = {
      ...chosenBid.recent_bid_version,
      bid_versions_additional_costs: applyAdditionalCosts(
        chosenBid.recent_bid_version.bid_versions_additional_costs
      ),
    };
    delete newTargetBidVersionData.is_rejected;
    delete newTargetBidVersionData.is_approved;
    delete newTargetBidVersionData.rejection_notes;

    await props.bidVersionUpdate(newTargetBidVersionData, null);

    // update additional costs that are not coming with bid version

    const newBidVersionData = {
      ...props.bid.model.recent_bid_version,
      bid_versions_additional_costs: applyAdditionalCosts(
        props.bidVersion.model.bid_versions_additional_costs
      ),
    };
    delete newBidVersionData.is_rejected;
    delete newBidVersionData.is_approved;
    delete newBidVersionData.rejection_notes;

    await props.bidVersionUpdate(newBidVersionData, null);

    for (let x in moveShots) {
      const item = moveShots[x];

      console.log(`Moving shot ${item.id} to bid id ${chosenBid.id}...`);

      if (movedShots.indexOf(item.id) >= 0) {
        console.log(`Skipping shot ${item.id} as it has been already moved`);
        continue;
      }

      await props.moveShot(item.id, chosenBid.id);

      const shotFromStore = store.getState().shot;

      if (shotFromStore.shotMoveError) {
        console.log(
          `Could not move shot ${item.id} to bid id ${chosenBid.id}...`,
          shotFromStore.shotMoveError
        );
        break;
      } else {
        console.log(`Marking shot ${item.id} as already moved with success`);
        movedShots.push(item.id);
      }
    }

    if (!store.getState().shot.shotMoveError) {
      history.push("/bid/" + store.getState().bidVersion.model.id);
    }
    //move action using chosenBid and shots ids
    /*console.log(
      `Move to bid id ${chosenBid.id}, shots numbers: ${moveShots.map(
        (item) => item.shot_number
      )}`
    );*/
  }, [chosenBid, moveShots]);

  /**
   *
   * @param bid
   */
  const onChangeBid = async (bid) => {
    // bid returned by search endpoint does not return additional costs,
    // so these are pulled here.
    const url =
      AxiosConfig.getEndpointAddress() +
      `/api/bid_versions/${bid.recent_bid_version.id}.json`;

    await axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        bid.recent_bid_version = response.data;
      })
      .catch(function (error) {
        // ...
      });
    setChosenBid(bid);
  };

  const shotsTable = isInitialized ? (
    <ShotsTable shots={moveShots} />
  ) : (
    <LoadingIndicator/>
  );

  return (
    <ModalTemplate
      title={modalTitle}
      onClose={props.onClose}
      confirmButtonLabel="Move"
      onConfirm={handleSubmit}
      cancelButtonLabel="Cancel"
      onCancel={props.onClose}
      loadingIndicator={loadingIndicator}
      disableConfirmButton={isLoading || !chosenBid || !moveShots.length}
    >
      <form noValidate>
        <p className="mb-3">
          You are about to move these shots to a chosen bid.
        </p>

        <div className="row">
          <div className="form-group col-12">
            <label className="form-label">Target bid</label>
            <BidSelect
              onUpdate={onChangeBid}
              reactSelectProps={{
                isDisabled: isLoading,
              }}
            />
          </div>
        </div>

        {shotsTable}

        <ApiError error={props.shot.shotMoveError} />
      </form>
    </ModalTemplate>
  );
};

const mapStateToProps = (state) => ({
  bid: state.bid,
  shot: state.shot,
  bidVersion: state.bidVersion,
});

const mapDispatchToProps = (dispatch) => ({
  bidVersionUpdate: async (data, id) =>
    await dispatch(bidVersionUpdate(data, id)),
  moveShot: async (shotId, bidId) => await dispatch(moveShot(shotId, bidId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MoveShotsModal));
