/**
 * Created by piotr.pozniak@thebeaverhead.com on 09/07/2024
 */

import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import StatusBadge from "../../components/StatusBadge";
import DropdownMenu from "../../components/DropdownMenu";
import DataTable from "react-data-table-component";
import StaticAlert from "../../components/StaticAlert";
import DataGridPagination from "../../components/DataGridPagination";
import { useClientProjectsStore } from "../../storeHooks/clientProjects";
import { history } from "../../configureStore";
import { useProjectStore } from "../../storeHooks/project";
import { useStatusesStore } from "../../storeHooks/statuses";

const projectsDataTableConfig = ({
  onClickProject,
  onEditProjectDetails,
  projectsStatuses,
}) => ({
  perPage: 10,
  columns: [
    {
      name: "Project name",
      selector: "name",
      sortable: true,
      format: (row) => (
        <Link
          className="relative"
          onClick={onClickProject(row)}
          to={"/project/" + row.id}
        >
          {row.name}
        </Link>
      ),
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      format: (row) => (
        <StatusBadge status={row.status} statuses={projectsStatuses} />
      ),
    },
    {
      name: "Client name",
      selector: "client.name",
    },

    {
      name: "Due Date",
      selector: "due_date",
      sortable: true,
      format: (row) =>
        !row.due_date ? "--" : new Date(row.due_date * 1000).toLocaleString(),
    },

    {
      name: "Time spent [h]",
      selector: "time_spent",
      format: (row) => {
        return !row.time_spent && row.time_spent !== 0
          ? "--"
          : (row.time_spent / 60 / 60).toFixed(2);
      },
    },
    {
      name: "Actions",
      right: true,
      cell: (row) => (
        <DropdownMenu
          buttons={[
            {
              icon: "mi-mode-edit",
              action: onEditProjectDetails,
              data: row,
              label: "Edit details",
            },
          ]}
        />
      ),
      allowedRoles: ["admin", "producer"],
      width: "70px",
    },
  ],
});

const mock = [{}];

const ProjectsTable = (props) => {
  const { clientProjects, fetchClientProjects } = useClientProjectsStore();
  const { setEditProject } = useProjectStore();
  const { statuses } = useStatusesStore();
  const params = useParams();

  const tableConfig = useMemo(
    () =>
      projectsDataTableConfig({
        projectsStatuses: statuses.collection.filter(
          (i) => i.type === "Projects"
        ),
        /**
         *
         * @param project
         * @returns {Function}
         */
        onClickProject: (project) => () => {
          setEditProject(project);
        },

        onEditProjectDetails: (project) => {
          setEditProject(project);
          history.push(`/project/edit/${project.id}`);
        },
      }),
    [setEditProject, statuses.collection]
  );

  /**
   *
   * @param page
   */
  const onChangeProjectsTablePage = (page) => {
    fetchClientProjects(page, tableConfig.perPage, {
      clients: [params.id],
    });
  };

  useEffect(() => {
    fetchClientProjects(1, tableConfig.perPage, {
      clients: [params.id],
    });
  }, []);

  if (!clientProjects.collection.length) {
    return null;
  }

  const projectsTablePagination =
    clientProjects.collection && clientProjects.collection.length ? (
      <DataGridPagination
        onChangePage={onChangeProjectsTablePage}
        pagination={clientProjects.pagination}
      />
    ) : null;

  return (
    <div className="row mb-3">
      <div className="col">
        <div
          className={
            "data-table-themed data-table-themed_not-stretch data-table-themed_short"
          }
        >
          <div className="card-header">
            <h5 className={"card-title"}>Projects</h5>
          </div>

          <DataTable
            noHeader
            responsive={false}
            columns={tableConfig.columns}
            defaultSortField={"name"}
            data={
              clientProjects.collection.length
                ? clientProjects.collection
                : mock
            }
            className={
              clientProjects.collection.length ? null : "data-table-empty"
            }
          />
          {projectsTablePagination}
        </div>
        {clientProjects.collection &&
          clientProjects.collection.length === 0 && (
            <StaticAlert
              header={"No records to display"}
              body={"This table lists all the client's projects."}
            />
          )}
      </div>
    </div>
  );
};

ProjectsTable.defaultProps = {};

ProjectsTable.propTypes = {};

export default ProjectsTable;
