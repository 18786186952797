import PropTypes from "prop-types";
import moment from "moment";
import {memo} from "react";

const DateUSSlash = (props) => {
  const { date, filler } = props;

  return date ? moment(date * 1000).format("MM/DD/YY") : filler || "--";
};

DateUSSlash.propTypes = {
  date: PropTypes.number,
  filler: PropTypes.string,
};

export default memo(DateUSSlash);
