import React, { useCallback } from "react";
import PropTypes from "prop-types";

import InfiniteScroll from "../../../components/InfiniteScroll";
import GroupedShots from "./GroupedShots";
import GroupedProjects from "./GroupedProjects";
import GroupedInvoices from "./GroupedInvoices";
import ProjectsDealsSummary from "./ProjectsDealsSummary";
import LoadingIndicator from "../../../components/LoadingIndicator";
import GroupedBids from "./GroupedBids";
import GroupedTasks from "./GroupedTasks";

const ItemsMapping = {
  shots: GroupedShots,
  projects: GroupedProjects,
  bids: GroupedBids,
  invoices: GroupedInvoices,
  tasks: GroupedTasks,
  projectsSummary: ProjectsDealsSummary,
};
const ImportantItems = (props) => {
  const {
    isLoading,
    isLoadingNextPage,
    data,
    user,
    type,
    onContentEndReached,
  } = props;

  /**
   *
   */
  const onEndReached = useCallback(() => {
    if (onContentEndReached) {
      onContentEndReached();
    }
  }, [data]);

  const loadingIndicator = (
    <div className="text-center">
      <LoadingIndicator className={"my-3"} />
    </div>
  );

  if (isLoading) {
    return loadingIndicator;
  }

  if (type === "projectsSummary") {
    return (
      <ProjectsDealsSummary user={user} isLoading={isLoading} data={data} />
    );
  }

  const ItemComponent = ItemsMapping[type];

  if (!ItemComponent) {
    return <div />;
  }

  return (
    <InfiniteScroll
      id={`dashboard-important-items-${type}`}
      className="dashboard-important-items-table"
      loading={isLoadingNextPage}
      onEndReached={onEndReached}
    >
      <ItemComponent data={data} user={user} />
    </InfiniteScroll>
  );
};

ImportantItems.propTypes = {
  isLoading: PropTypes.bool,
  isLoadingNextPage: PropTypes.bool,
  data: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  type: PropTypes.string,
  onContentEndReached: PropTypes.func,
};

export default ImportantItems;
