/**
 * Created by piotr.pozniak@thebeaverhead.com on 13/12/2021.
 */
import { useDispatch, useSelector } from "react-redux";

export function useBidInvoiceStore() {
  const dispatch = useDispatch();
  const _bidInvoices = useSelector((store) => store.bidInvoices);


  return {
    bidInvoices: _bidInvoices,
  };
}
