import React, {Component} from 'react';
import Validator from "validatorjs";
import ClientAddressForm from "../components/ClientAddressForm";
import ClientInvoiceAddressForm from "../components/ClientInvoiceAddressForm";
import FormValidationError from "../components/FormValidationError";
import ApiError from "../components/ApiError";

export default class ClientsEditForm extends Component {
  static defaultProps = {
    onClose: () => {
    },
  };

  constructor(props) {
    super(props);

    this.state = {formValidationErrors: null};
  }

  /**
   *
   */
  async componentDidMount() {
    const {
      match: {params: {id}},
      client: {model},
      fetchClient
    } = this.props;

    if (model === null && id !== "new" && id) {
      await fetchClient(id);
    } else {
      this.handleUpdateForm();
    }
  }

  /**
   *
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.formValidationErrors === null) {
      this.handleUpdateForm();
    }

    if (
      prevProps.client.edit !== this.props.client.edit &&
      this.props.client.editSuccess
    ) {
      this.props.onClose();
    }

    if (
      prevProps.client.create !== this.props.client.create &&
      this.props.client.createSuccess
    ) {
      this.props.onClose();
    }
  }

  /**
   *
   */
  handleUpdateForm() {
    if (this.props.client.model !== null) {
      const {
        name,
        show_code,
        email,
        contact_phone,
        contact_additional_notes,
        default_footer_notes,
        billing_additional_notes,
        studio
      } = this.props.client.model;
      document.querySelector('.js-client-name').value = name || '';
      document.querySelector('.js-client-show-code').value = show_code || '';
      document.querySelector('.js-client-email').value = email || '';
      document.querySelector('.js-client-contact-phone-number').value = contact_phone || '';
      document.querySelector('.js-client-additional-notes').value = contact_additional_notes || '';
      document.querySelector('.js-client-default-footer').value = default_footer_notes || '';
      document.querySelector('.js-client-billing-additional-notes').value = billing_additional_notes || '';
      document.querySelector('.js-client-studio').value = studio || '';
    }
  }

  /**
   *
   */
  handleSubmit = async (event) => {
    event.preventDefault();

    const emailValue = document.querySelector('.js-client-email').value;
    const data = {
      name: document.querySelector('.js-client-name').value,
      show_code: document.querySelector('.js-client-show-code').value,
      email: emailValue ? emailValue : null,
      contact_phone: document.querySelector('.js-client-contact-phone-number').value,


      contact_line_1: document.querySelector('.js-client-contact-address-1').value,
      contact_line_2: document.querySelector('.js-client-contact-address-2').value,
      contact_city: document.querySelector('.js-client-contact-city').value,
      contact_state: document.querySelector('.js-client-contact-state').value,
      contact_country: document.querySelector('.js-client-contact-country').value,
      contact_zip: document.querySelector('.js-client-contact-zip').value,
      contact_additional_notes: document.querySelector('.js-client-additional-notes').value,
      default_footer_notes: document.querySelector('.js-client-default-footer').value,

      billing_name: document.querySelector('.js-client-billing-name').value,
      billing_email: document.querySelector('.js-client-billing-email').value,
      billing_address_line_1: document.querySelector('.js-client-billing-address-1').value,
      billing_address_line_2: document.querySelector('.js-client-billing-address-2').value,
      billing_city: document.querySelector('.js-client-billing-city').value,
      billing_state: document.querySelector('.js-client-billing-state').value,
      billing_country: document.querySelector('.js-client-billing-country').value,
      billing_zip: document.querySelector('.js-client-billing-zip').value,
      billing_additional_notes: document.querySelector('.js-client-billing-additional-notes').value,
      studio: document.querySelector('.js-client-studio').value,

    };
    const rules = {
      name: 'required',
      show_code: 'required',
      email: 'email',
      billing_email: 'email'
    };
    const validation = new Validator(data, rules);

    this.setState({formValidationErrors: validation.errors.all()});

    if (validation.passes()) {
      const id = this.clientId;
      if (id === "new") {
        this.props.createClient(data);
      } else {
        this.props.editClient(id, data);
      }
    }
  };

  /**
   *
   * @return {string|*}
   */
  get clientId() {
    if (this.props.match.params && this.props.match.params.id) {
      return this.props.match.params.id;
    } else if (this.props.client.model && this.props.client.model.id) {
      return this.props.client.model.id;
    }
    return 'new';
  }

  /**
   *
   * @returns {XML}
   */
  render() {
    const {formValidationErrors} = this.state;
    const {client} = this.props;
    const isLoading = client.fetch || client.edit || client.create || client.getClientId;

    return (
      <form noValidate>
        <ul className="nav nav-tabs nav-tabs-highlight mb-0">
          <li className="nav-item"><a href="#centered-tab1" className="nav-link active" data-toggle="tab">CLIENT
            INFORMATION</a>
          </li>
          <li className="nav-item"><a href="#centered-tab2" className="nav-link" data-toggle="tab">BILLING
            INFORMATION</a></li>
        </ul>

        <div className="tab-content border-top-0 rounded-top-0 mb-0 mt-3">
          <div className="tab-pane fade show active" id="centered-tab1">
            <div className='row'>
              <div className='col-8'>
                <div className="form-group">
                  <label className="form-label">
                    Show Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control js-client-name"
                    placeholder="Name"
                    disabled={isLoading}
                  />
                </div>
              </div>
              <div className='col-4'>
                <div className="form-group">
                  <label className="form-label">
                    Abbreviation <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control js-client-show-code"
                    placeholder="Code"
                    disabled={isLoading}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label className="form-label">
                    Client login Email
                  </label>
                  <input
                    type="email"
                    className="form-control js-client-email"
                    placeholder="Email"
                    disabled={isLoading}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label className="form-label">
                    Client Phone
                  </label>
                  <input
                    type="text"
                    className="form-control js-client-contact-phone-number"
                    placeholder="Phone"
                    disabled={isLoading}
                  />
                </div>
              </div>
            </div>
            <legend className="text-uppercase font-size-sm font-weight-bold">
              Address
            </legend>
            <ClientAddressForm
              disabled={isLoading}
              client={client.model}
            />
            <legend className="text-uppercase font-size-sm font-weight-bold">Additional Contact information</legend>
            <>
              <div className="form-group">
              <textarea
                className="form-control js-client-additional-notes"
                placeholder="Additional Information/Notes"
                disabled={isLoading}
              />
              </div>
              <div className="form-group">
                <label className="form-label">Default footer</label>
                <textarea
                  className="form-control js-client-default-footer"
                  placeholder={"Default footer"}
                  disabled={isLoading}
                />
              </div>
            </>
          </div>
          <div className="tab-pane fade" id="centered-tab2">
            <ClientInvoiceAddressForm
              disabled={isLoading}
              client={client.model}
            />
            <legend className="text-uppercase font-size-sm font-weight-bold">Additional Contact information</legend>
            <>
              <div className="form-group">
              <textarea
                className="form-control js-client-billing-additional-notes"
                placeholder="Additional Information/Notes"
                disabled={isLoading}
              />
              </div>
              <div className="form-group">
                <label className="form-label">Studio</label>
                <input
                  type="text"
                  className="form-control js-client-studio"
                  placeholder="Studio"
                  disabled={isLoading}
                />
              </div>
            </>
          </div>

        </div>

        <FormValidationError errors={formValidationErrors}/>
        <ApiError error={client.fetchError}/>
        <ApiError error={client.createError}/>
        <ApiError error={client.editError}/>

      </form>
    );
  }
}
