import React, {Component} from 'react';

import ReportsPageTemplate, {staticAlertHeader, staticAlertBody} from './ReportsPageTemplate';
import connect from "react-redux/es/connect/connect";
import * as artistsPerformanceActions from "../actions/reportsArtistsPerformance";
import CustomizableDataTable from '../components/CustomizableDataTable';
import moment from 'moment';
import StaticAlert from "../components/StaticAlert";
import {initialState} from "../reducers/filtersReducer";
import {Link} from "react-router-dom";

class ArtistsPerformanceReportsPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      diagramData: undefined,
      filter: {
        fromDate: this.props.filtersReducer.reportsArtistsPerformanceFromDate,
        toDate: this.props.filtersReducer.reportsArtistsPerformanceToDate,
        artists: this.props.filtersReducer.reportsArtistsPerformanceArtists,
        clients: this.props.filtersReducer.reportsArtistsPerformanceClients,
      }
    };
  }

  /**
   *
   * @returns {Promise<void>}
   */
  async componentDidMount() {
    await this.fetchArtistsPerformanceReports();
    this.setState({diagramData: this.getDiagramData()});
  };

  /**
   *
   * @param filter
   */
  onFilter = async filter => {
    let fromDate;
    let toDate;
    filter.forEach(i => {
      if (i.fieldName === "fromDate" && i.value) {
        fromDate = moment(i.value, "YYYY-M-D");
      } else if (i.fieldName === "toDate" && i.value) {
        toDate = moment(i.value, "YYYY-M-D");
      }
    });

    this.setState({
      filter: {
        ...this.state.filter,
        fromDate: fromDate,
        toDate: toDate,
      },
      diagramData: undefined // clear the old chart
    }, async () => {
      await this.fetchArtistsPerformanceReports();
      this.setState({diagramData: this.getDiagramData()});
    });
  };

  /**
   *
   * @returns {Promise<Promise<*>|*>}
   */
  fetchArtistsPerformanceReports = async () => {
    return this.props.fetchArtistsPerformanceReports(
      this.state.filter.fromDate ? this.state.filter.fromDate.format('YYYY-MM-DD') : null,
      this.state.filter.toDate ? this.state.filter.toDate.format('YYYY-MM-DD') : null,
      this.state.filter.artists ? this.state.filter.artists.map(i => i) : [],
      this.state.filter.clients ? this.state.filter.clients.map(i => i) : [],
    );
  };

  /**
   *
   */
  onClearAll = () => {
    this.setState({
      filter: {
        artists: [],
        clients: [],
      }
    }, () => {
      this.onFilter([
        {fieldName: "fromDate", value: initialState.reportsArtistsPerformanceFromDate.format('YYYY-M-D')},
        {fieldName: "toDate", value: initialState.reportsArtistsPerformanceToDate.format('YYYY-M-D')},
      ]);
    });
  };

  /**
   *
   */
  getDiagramData = () => {
    const artists = this.props.reportsArtistsPerformance.collection && this.props.reportsArtistsPerformance.collection.artists
      ? this.props.reportsArtistsPerformance.collection.artists
      : [];

    if (artists.length === 0) {
      return null;
    }

    // get only artists who has some data to display
    const artistsWithRejections = [];
    artists.forEach(i => {
      let hasRejections = false;
      i.reoccurring_tasks.forEach(j => {
        if (j.count_of_rejections) {
          hasRejections = true;
        }
      });
      if (hasRejections) {
        artistsWithRejections.push(i);
      }
    });
    // keys, artists names
    const keys = [];
    artistsWithRejections.forEach(i => {
      keys.push(i.name);
    });
    // columns
    const columns = [];
    this.props.tasksTypes.collection.forEach((i, columnsIndex) => {
      columns[columnsIndex] = [i.name];
      artistsWithRejections.forEach(j => {
        let rejectionsCount = 0;
        j.reoccurring_tasks.forEach(k => {
          if (k.task_type_id === i.id) {
            rejectionsCount += k.count_of_rejections;
          }
        });
        columns[columnsIndex].push(rejectionsCount);
      });
    });
    // remove columns with no data
    let i = columns.length;
    while (i--) {
      if (columns[i].slice(1).reduce((acc, curr) => acc + curr, 0) < 1) {
        columns.splice(i, 1);
      }
    }
    // groups used for stacking the columns
    const groups = [columns.map(i => i[0])];
    return {
      //new diagram types can be added in public_html_dev/js/app.js, see App.initBarDiagram(settings ? settings.barDiagram : settings);
      barDiagram: [
        {
          bindto: document.getElementById('jsReportsPageDiagram'),
          size: {height: 300},
          data: {
            columns: columns,
            type: 'bar',
            groups: groups,
          },
          bar: {
            width: {
              ratio: 0.5
            }
          },
          grid: {
            y: {
              show: true
            }
          },
          axis: {
            x: {
              type: 'category',
              categories: keys
            },
            y: {
              tick: {
                values: Array.from(Array(200).keys()),
              },
            },
          },
        }
      ],
    };
  };

  /**
   *
   * @param val
   */
  artistsFilterOnUpdate = (val) => {
    const artists = val || [];
    this.setState({
      filter: {...this.state.filter, artists}
    })
  };

  /**
   +   *
   +   * @param val
   +   */
  clientsFilterOnUpdate = val => {
    const clients = val || [];
    this.setState({
      filter: {...this.state.filter, clients}
    });
  };


  /**
   *
   * @returns {XML}
   */
  render() {
    const isLoading = this.props.reportsArtistsPerformance.fetch;

    // prepare data table collection
    const artists = this.props.reportsArtistsPerformance.collection && this.props.reportsArtistsPerformance.collection.artists
      ? this.props.reportsArtistsPerformance.collection.artists
      : [];
    const dataTableCollection = [];
    artists.forEach(i => {
      i.reoccurring_tasks.forEach(j => {
        dataTableCollection.push({
          assignee_last_activity: j.assignee_last_activity
            ? moment.unix(j.assignee_last_activity).format("YYYY-MM-DD") : "--",
          date: moment.unix(j.modified || j.created).format("YYYY-MM-DD"),
          artist: i.name,
          project_name: j.shot && j.shot.project && j.shot.project.name ? j.shot.project.name : `--`,
          project_href: j.shot && j.shot.project && j.shot.project.id ? `/project/${j.shot.project.id}` : `#`,
          shot_number: j.shot && j.shot.shot_number ? j.shot.shot_number : `--`,
          shot_href: j.shot && j.shot.id && j.shot.project && j.shot.project.id ? `/project/${j.shot.project.id}/shot/${j.shot.id}` : `#`,
          task: this.props.tasksTypes.collection.find(t => t.id === j.task_type_id).name,
          occurrences: j.count_of_rejections,
        })
      });
    });
    dataTableCollection.sort((a, b) => {
      return moment(a.date, "YYYY-MM-DD").format("x")
        - moment(b.date, "YYYY-MM-DD").format("x");
    });

    const content = dataTableCollection.length > 0 && (
      <CustomizableDataTable
        collection={dataTableCollection}
        columns={[
          {
            name: 'Assignee last activity',
            selector: 'assignee_last_activity',
            sortable: true,
          },
          {
            name: 'Task modified date',
            selector: 'date',
            sortable: true,
            center: true,
          },
          {
            name: 'Artist',
            selector: 'artist',
            center: true,
          },
          {
            name: 'Project name',
            selector: 'project_name',
            center: true,
            cell: row => row && row.project_href ?
              <Link to={row.project_href} className='relative'>{row.project_name}</Link> :
              <div>{row.project_name}</div>
          },
          {
            name: 'Shot number',
            selector: 'shot_number',
            center: true,
            cell: row => row && row.shot_href ?
              <Link to={row.shot_href} className='relative'>{row.shot_number}</Link> :
              <div>{row.shot_number}</div>
          },
          {
            name: 'Task',
            selector: 'task',
            center: true,
          },
          {
            name: 'Occurrences',
            selector: 'occurrences',
            center: true,
          },
        ]}
      />
    );


    // empty table alert
    const staticAlert = !dataTableCollection || !dataTableCollection.length ? (
      <StaticAlert
        header={staticAlertHeader}
        body={staticAlertBody}
      />
    ) : null;
    //

    return (
      <ReportsPageTemplate
        header="Artists performance reports"
        filters={[
          {
            placeholderFrom: "From date",
            placeholderTo: "To date",
            fieldNameFrom: "fromDate",
            fieldNameTo: "toDate",
            fieldName: "dateRange",
            defaultValueFrom: this.state.filter.fromDate ? this.state.filter.fromDate.format("YYYY-MM-DD") : "",
            defaultValueTo: this.state.filter.toDate ? this.state.filter.toDate.format("YYYY-MM-DD") : "",
            type: 'dateRange',
            wrapperClassName: 'col-sm-6',
          },
          {
            placeholder: "Artists",
            fieldName: "artists",
            type: 'userSelect',
            reactSelectProps: {
              isMulti: true,
              value: this.state.filter.artists,
            },
            onUpdate: this.artistsFilterOnUpdate,
            wrapperClassName: 'col-sm-6',
          },
          {
            placeholder: "Clients",
            fieldName: "clients",
            type: 'customerSelect',
            reactSelectProps: {
              isMulti: true,
              value: this.state.filter.clients,
            },
            onUpdate: this.clientsFilterOnUpdate,
            wrapperClassName: 'col-sm-6',
          },
        ]}
        onFilter={this.onFilter}
        onClearAll={this.onClearAll}
        diagramData={this.state.diagramData}
        isLoading={isLoading}
      >
        {content}
        {staticAlert}
      </ReportsPageTemplate>
    );
  }
}

const mapStateToProps = state => ({
  reportsArtistsPerformance: state.reportsArtistsPerformance,
  tasksTypes: state.tasksTypes,
  filtersReducer: state.filtersReducer,
});

const mapDispatchToProps = dispatch => ({
  fetchArtistsPerformanceReports: async (dateFrom, dateTo, artists, clients, type) =>
    await dispatch(artistsPerformanceActions.fetchArtistsPerformanceReports(dateFrom, dateTo, artists, clients, type)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArtistsPerformanceReportsPage);

