/**
 * Created by piotr.pozniak@thebeaverhead.com on 17/05/2022
 */

import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import PageTemplate from "../PageTemplate";
import { useUserStore } from "../../storeHooks/user";
import DiagramPanel from "./components/DiagramPanel";
import Filters from "../../components/Filters";
import { useStatusesStore } from "../../storeHooks/statuses";
import { useWorkloadDashboardStore } from "../../storeHooks/workloadDashboard";
import PanelTabs from "../../components/PanelTabs";

const initialState = {
  artistId: null,
  status: null,
  clientId: null,
  projectId: null,
};

const Tabs = {
  artistsTab: "tab-artists",
  projectsTab: "tab-projects",
};

const WorkloadDashboardPage = (props) => {
  const { user } = useUserStore();
  const userModel = user.model;
  const isDarkThemed = false;

  const { statuses } = useStatusesStore();

  const { workloadDashboard, fetchProjectsWorkload, fetchArtistsWorkload } =
    useWorkloadDashboardStore();

  const shotStatuses = useMemo(() => {
    return statuses.collection.filter((i) => i.type === "Shots");
  }, [statuses.collection]);

  const [filters, setFilters] = useState({ ...initialState });
  const [projectFilter, setProjectFilters] = useState(null);
  const [clientFilter, setClientFilters] = useState(null);
  const [artistFilter, setArtistFilters] = useState(null);
  const [currentTab, setCurrentTab] = useState(Tabs.artistsTab);

  useEffect(() => {
    window.updateJQuery();
  }, [currentTab]);

  /**
   *
   * @param e
   */
  const onApplyFilter = useCallback(
    (e) => {
      const updatedFilters = { ...initialState };

      e.map((i) => {
        updatedFilters[i.fieldName] = i.value;
      });

      updatedFilters.projectId = projectFilter ? projectFilter.id : null;
      updatedFilters.clientId = clientFilter ? clientFilter.id : null;
      updatedFilters.artistId = artistFilter ? artistFilter.id : null;

      setFilters(updatedFilters);
    },
    [currentTab, projectFilter, clientFilter, artistFilter]
  );


  /**
   *
   * @param e
   */
  const onClearAllFilters = useCallback((e) => {
    setFilters({ ...initialState });
  }, []);

  /**
   *
   * @param tabId
   */
  const onTabChange = useCallback(
    (tabId) => {
      setCurrentTab(tabId);

      if (
        tabId === Tabs.artistsTab &&
        artistFilter &&
        artistFilter.id === "unassigned"
      ) {
        setFilters({ ...filters, artistId: null });
        setArtistFilters(null);
      }
    },
    [onApplyFilter]
  );

  /**
   *
   * @param setter
   * @returns {(function(*): void)|*}
   */
  const onUpdateSelectFilter = useCallback(
    (setter) => (e) => {
      setter(e);
    },
    [currentTab, onApplyFilter]
  );

  const dashboardFilters = useMemo(
    () => [
      {
        placeholder: "Client",
        fieldName: "clients",
        type: "customerSelect",
        onUpdate: onUpdateSelectFilter(setClientFilters),
        reactSelectProps: {
          isClearable: true,
          isMulti: false,
        },
      },
      {
        placeholder: "Project",
        //defaultValue: name,
        fieldName: "projects",
        type: "projectSelect",
        onUpdate: onUpdateSelectFilter(setProjectFilters),
        reactSelectProps: {
          isClearable: true,
          isMulti: false,
        },
      },
      {
        data: shotStatuses,
        placeholder: "Shot Status",
        fieldName: "status",
        type: "select",
        value: filters.status,
      },
      {
        placeholder: "Artist",
        fieldName: "artists",
        type: "userSelect",
        addEmptyItem: currentTab === Tabs.projectsTab,
        emptyItemLabel: "Unassigned",
        emptyItemValue: "unassigned",
        onUpdate: onUpdateSelectFilter(setArtistFilters),
        reactSelectProps: {
          isClearable: true,
          isMulti: false,
        },
      },
    ],
    [currentTab, filters.status, onUpdateSelectFilter]
  );

  const tabContent =
    currentTab === Tabs.artistsTab ? (
      <DiagramPanel
        key={Tabs.artistsTab}
        tabId={Tabs.artistsTab}
        statuses={shotStatuses}
        filters={filters}
        workloadData={workloadDashboard.artistsBreakdown}
        isLoading={workloadDashboard.fetch}
        fetchAction={fetchArtistsWorkload}
        individualFieldName={"project_code"}
        itemName={"artist"}
        totalHoursLabel={"total bid hours"}
        totalShotsLabel={"assigned"}
        relativeToItemOptionLabel={"Relative to individual artist"}
        relativeToAllItemsOptionLabel={"Relative to all artists"}
      />
    ) : (
      <DiagramPanel
        key={Tabs.projectsTab}
        tabId={Tabs.projectsTab}
        statuses={shotStatuses}
        filters={filters}
        isLoading={workloadDashboard.fetch}
        workloadData={workloadDashboard.projectsBreakdown}
        fetchAction={fetchProjectsWorkload}
        individualFieldName={"assignee_name"}
        itemName={"project"}
        totalHoursLabel={"total bid hours"}
        totalShotsLabel={"working"}
        relativeToItemOptionLabel={"Relative to a project"}
        relativeToAllItemsOptionLabel={"Relative to all projects"}
      />
    );

  return (
    <PageTemplate
      header="Dashboard"
      subheader={userModel ? `${userModel.name} (${userModel.role})` : null}
      pageWrapperClassName={
        isDarkThemed ? "bg-dark page-header-dark-theme" : ""
      }
      pageFooterClassName={isDarkThemed ? "navbar-dark" : ""}
      breadcrumbClassName={isDarkThemed ? "breadcrumb-line-dark" : ""}
    >
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body">
              <PanelTabs
                tabs={[
                  {
                    id: Tabs.artistsTab,
                    label: "Artists",
                  },
                  {
                    id: Tabs.projectsTab,
                    label: "Projects",
                  },
                ]}
                defaultActiveTabId={Tabs.artistsTab}
                onTabClick={onTabChange}
              />
              <div className="tab-content">
                <Filters
                  filters={dashboardFilters}
                  onFilter={onApplyFilter}
                  onClearAll={onClearAllFilters}
                />

                {tabContent}
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageTemplate>
  );
};

WorkloadDashboardPage.defaultProps = {};

WorkloadDashboardPage.propTypes = {};

export default WorkloadDashboardPage;
