import React from "react";
import {
  Route,
  Switch,
  withRouter,
  Redirect,
  matchPath,
} from "react-router-dom";

import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import AwaitingVerificationPage from "./pages/AwaitingVerificationPage";
import PasswordReminderPage from "./pages/PasswordReminderPage";
import PasswordResetPage from "./pages/PasswordResetPage";
import DashboardPage from "./pages/DashboardPage/DashboardPage";
import ProfilePage from "./pages/ProfilePage";
import UsersPage from "./pages/UsersPage";
import ProjectsPage from "./pages/ProjectsPage/ProjectsPage";
import ProjectPage from "./pages/ProjectPage/ProjectPage";
import BidsPage from "./pages/BidsPage/BidsPage";
import BidPage from "./pages/BidPage/BidPage";
import ClientsPage from "./pages/ClientsPage";
import ShotPage from "./pages/ShotPage/ShotPage";
import SupplierEditPage from "./pages/SupplierEditPage/SupplierEditPage";
import ClientSettingsPage from "./pages/ClientSettingsPage";
import InvoicesPage from "./pages/InvoicesPage/InvoicesPage";
import ArtistsReportsPage from "./pages/ArtistsReportsPage";
import ArtistsPerformanceReportsPage from "./pages/ArtistsPerformanceReportsPage";
import InvoicesReportsPage from "./pages/InvoicesReportsPage";
import PredictionsReportsPage from "./pages/PredictionsReportsPage";
import PageTemplate from "./pages/PageTemplate";
import GainLossReportsPage from "./pages/GainLossReportsPage";
import ProjectsHoursReportsPage from "./pages/ProjectsHoursReportsPage";
import TimeLogsPage from "./pages/TimeLogsPage";
import ClientPage from "./pages/ClientPage";
import BidPreviewPage from "./pages/BidPreviewPage";
import BidInvoicesPage from "./pages/BidInvoicesPage/BidInvoicesPage";
import WorkloadDashboardPage from "./pages/WorkloadDashboardPage/WorkloadDashboardPage";

export default withRouter(
  class MainRoutesComponent extends React.Component {
    constructor(props) {
      super(props);

      this.previousMainComponentLocation = props.location;
    }

    /**
     *
     * @param nextProps
     * @param nextState
     * @returns {boolean}
     */
    shouldComponentUpdate(nextProps, nextState) {
      if (this.props.location.pathname !== nextProps.location.pathname) {
        // if next location is one of the modals with static path
        const modalLinkIndex = this.props.modalLinks.findIndex((modalLink) => {
          const match = matchPath(nextProps.location.pathname, {
            path: modalLink,
            exact: true,
            strict: false,
          });
          return match !== null;
        });

        if (modalLinkIndex >= 0) {
          return false;
        } else if (
          this.previousMainComponentLocation.pathname ===
          nextProps.location.pathname
        ) {
          return false;
        } else {
          this.previousMainComponentLocation = nextProps.location;
          return true;
        }
      } else {
        return false;
      }
    }

    /**
     *
     * @returns {*}
     */
    render() {
      return (
        <Switch location={this.previousMainComponentLocation}>
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/register" component={RegisterPage} />
          <Route
            exact
            path="/not-approved"
            component={AwaitingVerificationPage}
          />
          <Route
            exact
            path="/password-reminder"
            component={PasswordReminderPage}
          />
          <Route
            exact
            path="/password-reset/:email/:token"
            component={PasswordResetPage}
          />
          <Route
            exact
            path={["/dashboard/workload"]}
            component={WorkloadDashboardPage}
          />
          <Route
            exact
            path={["/", "/dashboard/:dashboardType?"]}
            component={DashboardPage}
          />
          <Route exact path="/profile" component={ProfilePage} />
          <Route
            exact
            path="/users/accept/:token/:email/:role"
            render={({ match: { params } }) => (
              <Redirect
                to={{
                  pathname: "/users/user/1/0",
                  state: {
                    ...params,
                    approve: true,
                    email: decodeURIComponent(params.email),
                  },
                }}
              />
            )}
          />
          <Route
            exact
            path="/users/reject/:token/:email"
            render={({ match: { params } }) => (
              <Redirect
                to={{
                  pathname: "/users/user/1/0",
                  state: {
                    ...params,
                    reject: true,
                    email: decodeURIComponent(params.email),
                  },
                }}
              />
            )}
          />
          <Route
            exact
            path="/users/:role/:page?/:verified?"
            render={(props) => <UsersPage key={props.match.params.verified} />}
          />
          <Route exact path="/projects/:slug?/:page" component={ProjectsPage} />
          <Route exact path="/bids/:slug?/:page" component={BidsPage} />
          <Route exact path="/clients/:page?" component={ClientsPage} />
          <Route exact path="/client/:id" component={ClientPage} />
          <Route exact path="/bid/:id/:type?" component={BidPage} />
          <Route exact path="/project/:id" component={ProjectPage} />
          <Route exact path="/project/:id/shot/:shotId" component={ShotPage} />
          <Route exact path="/platform-settings" component={SupplierEditPage} />
          <Route exact path="/client-settings" component={ClientSettingsPage} />
          <Route exact path="/invoices/:slug?/:page" component={InvoicesPage} />
          <Route exact path="/invoice/:bidId" component={BidInvoicesPage} />
          <Route exact path="/reports/artists" component={ArtistsReportsPage} />
          <Route
            exact
            path="/reports/artists_performance"
            component={ArtistsPerformanceReportsPage}
          />
          <Route
            exact
            path="/reports/invoices"
            component={InvoicesReportsPage}
          />
          <Route
            exact
            path="/reports/predictions"
            component={PredictionsReportsPage}
          />
          <Route
            exact
            path="/reports/gain_loss"
            component={GainLossReportsPage}
          />
          <Route
            exact
            path="/reports/projects_hours"
            component={ProjectsHoursReportsPage}
          />
          <Route exact path="/timelogs" component={TimeLogsPage} />
          <Route exact path="/b/:slug" component={BidPreviewPage} />
          <Route component={PageTemplate} />
        </Switch>
      );
    }
  }
);
