import React, { useCallback, useEffect, useMemo, useState } from "react";
import { withRouter } from "react-router-dom";
import { store } from "../../configureStore";
import Validator from "validatorjs";

import classNames from "classnames";

import ModalTemplate from "../ModalTemplate";

import connect from "react-redux/es/connect/connect";
import LoadingIndicator from "../../components/LoadingIndicator";
import ApiError from "../../components/ApiError";
import FormValidationError from "../../components/FormValidationError";

import CustomizableDataTable from "../../components/CustomizableDataTable";
import BidNotes from "./BidNotes";

import { formatter } from "../../helpers/tools";
import StaticAlert from "../../components/StaticAlert";
import { useBidStore } from "../../storeHooks/bid";
import { useBidInvoiceStore } from "../../storeHooks/bidInvoice";
import { useBidVersionStore } from "../../storeHooks/bidVersion";
import { useBidsInvoiceStore } from "../../storeHooks/bidsInvoice";
import { useProjectStore } from "../../storeHooks/project";
import DataTable from "react-data-table-component";

const staticShotsAlertHeader = "No records to display";
const staticShotsAlertBody = <>This table lists project's approved shots.</>;

const includeAdditionalCostDescription =
  "Only one invoice can include additional costs/discounts";

const shotsTableId = "invoiceEditModalShots";

const bulkActions = [];

const InvoiceEditModal = (props) => {
  const [state, setState] = useState({
    fetchBids: false,
    shotsList: [],
    formValidationErrors: null,
  });

  const isEditMode = props.match.params.invoiceId !== "new";

  const { project, fetchProject } = useProjectStore();
  const { bid, fetchBid } = useBidStore();
  const { bidInvoices } = useBidInvoiceStore();
  const { fetchBidInvoices, editInvoice, createInvoice } =
    useBidsInvoiceStore();
  const { bidVersion, fetchShotsByBidVersion } = useBidVersionStore();

  const shotsDataTableConfig = {
    columns: [
      {
        name: "Shot Number",
        selector: "shot_number",
        format: (row) => {
          const cellClassName = classNames(
            "js-customizable-table-to-highlight",
            {
              "disabled text-muted":
                getShotsUsedInInvoicesIds().indexOf(row.id) !== -1,
            }
          );
          return (
            <div className={cellClassName}>
              {row.shot_number ? row.shot_number : "--"}
            </div>
          );
        },
      },
      {
        name: "VFX tasks",
        selector: "current_version.task_types",
        format: (row) =>
          row.current_version &&
          row.current_version.task_types &&
          row.current_version.task_types.length
            ? row.current_version.task_types.map((item, index) => {
                return (
                  <span key={item.index + index.toString()}>
                    <span className="text-nowrap">
                      {item.name}
                      {index !== row.current_version.task_types.length - 1
                        ? ", "
                        : null}
                    </span>{" "}
                  </span>
                );
              })
            : "--",
      },
      {
        name: "Hours",
        selector: "current_version.hours",
        format: (row) =>
          Math.round(
            ((row.current_version && row.current_version.hours) || 0) * 100
          ) / 100,
        center: true,
      },
      {
        name: "Rate",
        selector: "current_version.rate",
        format: (row) =>
          row.current_version &&
          (row.current_version.rate || row.current_version.rate === 0)
            ? formatter.format(row.current_version.rate)
            : "--",
        center: true,
      },
      {
        name: "Total",
        selector: "current_version.cost_summary",
        format: (row) =>
          row.current_version &&
          (row.current_version.cost_summary ||
            row.current_version.cost_summary === 0)
            ? formatter.format(row.current_version.cost_summary)
            : "--",
        right: true,
      },
    ],
  };

  const isInitialized = useMemo(() => {
    return (
      bid.fetchSuccess &&
      project.fetchSuccess &&
      bidInvoices.fetchSuccess &&
      bidVersion.fetchShotsSuccess
    );
  }, [
    bid.fetchSuccess,
    project.fetchSuccess,
    bidInvoices.fetchSuccess,
    bidVersion.fetchShotsSuccess,
  ]);


  useEffect(() => {
    const {
      match: {
        params: { bidId },
      },
    } = props;

      const fetchData = async () => {
        await fetchBidInvoices(bidId);
        await fetchBid(bidId);
        const bidStore = store.getState().bid;
        await fetchProject(bidStore.model.project_id);

        await fetchShotsByBidVersion(
          store.getState().bid.model.recent_bid_version.id
        );
      };

      fetchData();


    setTimeout(window.updateJQuery, 25);
  }, [isEditMode]);

  useEffect(() => {
    if (isEditMode) {
      handleUpdateForm();
      selectShots();
      highlightRows();
    }
  }, [isInitialized]);

  useEffect(() => {
    if (state.formValidationErrors === null) {
      handleUpdateForm();
    }
  }, [state.formValidationErrors]);

  /**
   *
   * @param rows
   */
  const onCheckRow = useCallback(
    (rows) => {
      setState({ ...state, shotsList: rows.selectedRows });
    },
    [props.match.params.invoiceId]
  );

  /**
   *
   * @returns {Array}
   */
  const getShotsUsedInAllInvoicesIds = () => {
    const usedShots = [];
    (bidInvoices.collection || []).forEach((item) =>
      usedShots.push(...item.shots.map((shot) => shot.id))
    );
    return usedShots;
  };

  /**
   *
   * @returns {Array}
   */
  const getShotsUsedInOtherInvoicesIds = () => {
    const currentInvoiceId = props.match.params.invoiceId;
    const usedShots = [];
    (bidInvoices.collection || [])
      .filter((item) => item.id !== currentInvoiceId)
      .forEach((item) => usedShots.push(...item.shots.map((shot) => shot.id)));
    return usedShots;
  };

  /**
   *
   * @returns {Array}
   */
  const getShotsUsedInCurrentInvoicesIds = () => {
    const currentInvoiceId = props.match.params.invoiceId;
    const usedShots = [];
    const currentInvoiceAr = (bidInvoices.collection || []).filter(
      (item) => item.id === currentInvoiceId
    );

    const currentInvoice =
      currentInvoiceAr && currentInvoiceAr.length ? currentInvoiceAr[0] : null;

    if (currentInvoice) {
      usedShots.push(...currentInvoice.shots.map((shot) => shot.id));
    }
    return usedShots;
  };

  /**
   *
   * @returns {Array}
   */
  const getShotsUsedInInvoicesIds = () => {
    return isEditMode
      ? getShotsUsedInOtherInvoicesIds()
      : getShotsUsedInAllInvoicesIds();
  };

  /**
   *
   */
  const selectShots = () => {
    if (isEditMode) {
      selectThisInvoiceShots();
    } else {
      selectAllShots();
    }
  };

  /**
   *
   */
  const selectAllShots = () => {
    const shotsUsedInInvoicesIds = getShotsUsedInInvoicesIds();
    const approvedShots = (project.shots || []).filter(
      (item) => !!+item.has_approved_version
    );
    const chosenBidShots = approvedShots.filter(
      (approvedShot) =>
        !!bidVersion.shots.find(
          (bidVersionShot) => bidVersionShot.id === approvedShot.id
        )
    );
    const rows = document.querySelectorAll(`#${shotsTableId} .rdt_TableRow`);
    rows.forEach((item, index) => {
      if (
        chosenBidShots.length &&
        shotsUsedInInvoicesIds.indexOf((chosenBidShots[index] || {}).id) === -1
      ) {
        const checkBox = item.querySelector(".rdt_TableCell:first-child input");
        if (checkBox.checked === false) {
          setTimeout(() => {
            checkBox.click();
          }, 0);
        }
      }
    });
  };

  /**
   *
   */
  const selectThisInvoiceShots = () => {
    const shotsUsedInCurrentInvoicesIds = getShotsUsedInCurrentInvoicesIds();

    const approvedShots = (project.shots || []).filter(
      (item) => !!+item.has_approved_version
    );
    const chosenBidShots = approvedShots.filter(
      (approvedShot) =>
        !!bidVersion.shots.find(
          (bidVersionShot) => bidVersionShot.id === approvedShot.id
        )
    );
    const rows = document.querySelectorAll(`#${shotsTableId} .rdt_TableRow`);
    rows.forEach((item, index) => {
      if (
        shotsUsedInCurrentInvoicesIds.indexOf(
          (chosenBidShots[index] || {}).id
        ) !== -1
      ) {
        const checkBox = item.querySelector(".rdt_TableCell:first-child input");
        if (checkBox.checked === false) {
          setTimeout(() => {
            checkBox.click();
          }, 0);
        }
      }
    });
  };

  /**
   *
   */
  const handleUpdateForm = () => {
    const {
      match: {
        params: { invoiceId },
      },
    } = props;
    const currentInvoiceAr = (bidInvoices.collection || []).filter(
      (item) => item.id === invoiceId
    );
    const currentInvoice =
      currentInvoiceAr && currentInvoiceAr.length ? currentInvoiceAr[0] : null;

    const invoiceEditAdditionalCostCheckbox = document.querySelector(
      ".js-invoice-edit-additional-cost"
    );

    if (currentInvoice) {
      const { has_additional_costs, po_number } = currentInvoice;

      if (invoiceEditAdditionalCostCheckbox) {
        invoiceEditAdditionalCostCheckbox.checked = !!has_additional_costs;
      }

      const poNumberField = document.querySelector(
        ".js-invoice-edit-po-number"
      );
      if (poNumberField) {
        poNumberField.value = po_number ? po_number : "";
      }

      window.updateJQuery();
    } else {
      if (invoiceEditAdditionalCostCheckbox) {
        invoiceEditAdditionalCostCheckbox.checked =
          !isOneOfShotsHasApprovedVersion;
      }
    }
  };

  /**
   *
   */
  const handleSubmit = async (event) => {
    event.preventDefault();

    setState({ ...state, formValidationErrors: null });

    const data = {
      bid_id: bid.model.id,
      has_additional_costs: document.querySelector(
        ".js-invoice-edit-additional-cost"
      ).checked
        ? 1
        : 0,
      po_number: document.querySelector(".js-invoice-edit-po-number").value,
    };

    const shots = state.shotsList.map((item) => {
      return { id: item.id };
    });

    const rules = {};
    const validation = new Validator(data, rules);

    setState({ formValidationErrors: validation.errors.all() });

    if (validation.passes()) {
      if (isEditMode) {
        const {
          match: {
            params: { invoiceId },
          },
        } = props;
        await editInvoice(invoiceId, data, shots);
      } else {
        await createInvoice(bid.model.id, data, shots);
      }

      const bidInvoiceStore = store.getState().bidInvoices;
      if (
        (!isEditMode && bidInvoiceStore.createSuccess) ||
        (isEditMode && bidInvoiceStore.editSuccess)
      ) {
        props.onClose();
      }
    }
  };

  const highlightRows = () => {
    const rows = document.querySelectorAll(
      "#" + shotsTableId + " .rdt_TableRow"
    );
    if (rows) {
      rows.forEach((item) => {
        const cellWithClassNames = item.querySelector(
          ".js-customizable-table-to-highlight"
        );
        const classNames = cellWithClassNames.className;
        const closestRow = cellWithClassNames.closest(".rdt_TableRow");
        closestRow.className = closestRow.className + " " + classNames;
      });
    }
  };

  const { formValidationErrors } = state;
  const {
    match: {
      params: { invoiceId },
    },
  } = props;

  const { shots: bidVersionShots } = bidVersion;

  const currentInvoiceAr = (bidInvoices.collection || []).filter(
    (item) => item.id === invoiceId
  );
  const currentInvoice =
    currentInvoiceAr && currentInvoiceAr.length ? currentInvoiceAr[0] : null;

  const isLoading = !isInitialized ||
    bidInvoices.create ||
    bidInvoices.edit;

  const loadingIndicator = isLoading ? <LoadingIndicator /> : null;

  const projectName = bid.model.project
    ? `${bid.model.project.name} - ${bid.model.name}`
    : "";

  const modalTitle =
    (isEditMode ? "Edit" : "Create") + ` invoice for ${projectName}`;
  const confirmButtonLabel = isEditMode ? "Update" : "Create";

  const pureShots = project.shots;

  const approvedShots = pureShots.filter((item) =>
    Number.parseInt(item.has_approved_version)
  );

  const chosenBidShots = approvedShots.filter(
    (approvedShot) =>
      !!bidVersionShots.find(
        (bidVersionShot) => bidVersionShot.id === approvedShot.id
      )
  );

  const dataTable = (
    <CustomizableDataTable
      key={shotsTableId}
      id={shotsTableId}
      collection={chosenBidShots}
      columns={shotsDataTableConfig.columns}
      canSelectAll
      bulkActions={bulkActions}
      onCheckRow={onCheckRow}
    />
  );

  const staticAlertShots =
    !chosenBidShots.length && !isLoading ? (
      <StaticAlert
        header={staticShotsAlertHeader}
        body={staticShotsAlertBody}
      />
    ) : null;

  const isOneOfShotsHasApprovedVersion = bid.model.id
    ? !!(bidInvoices.collection || [])
        .filter((item) => item.bid.id === bid.model.id)
        .filter((item) => !!+item.has_additional_costs).length
    : true;

  const isCheckboxDisabled =
    isOneOfShotsHasApprovedVersion &&
    !(currentInvoice && currentInvoice.has_additional_costs * 1);

  const additionalCostCheckboxWrapper = classNames(
    "form-check",
    "d-inline-block",
    { disabled: isCheckboxDisabled || isLoading }
  );

  return (
    <ModalTemplate
      title={modalTitle}
      onClose={props.onClose}
      confirmButtonLabel={confirmButtonLabel}
      onConfirm={handleSubmit}
      cancelButtonLabel="Cancel"
      onCancel={props.onClose}
      loadingIndicator={loadingIndicator}
      disableConfirmButton={isLoading}
      className="modal-lg"
    >
      <form noValidate>
        {bid.model.id ? (
          <div>
            <p className="mt-3 mb-3">
              Select shots that are subject of invoicing
            </p>

            {dataTable}
            {staticAlertShots}

            <div className="form-group row">
              <label className="col-form-label col-lg-2">PO Number</label>
              <div className="col-lg-10">
                <input
                  type="text"
                  className="form-control js-invoice-edit-po-number"
                  disabled={isLoading}
                />
              </div>
            </div>

            <div className="form-group">
              <div className={additionalCostCheckboxWrapper}>
                <label className="form-check-label">
                  <input
                    type="checkbox"
                    className="form-check-input-styled js-invoice-edit-additional-cost"
                    data-fouc
                    disabled={isCheckboxDisabled || isLoading}
                  />
                  Include additional costs and discounts in this invoice
                </label>
              </div>
              <i
                className="icon-question4 ml-2 fs-14"
                data-popup="tooltip"
                data-original-title={includeAdditionalCostDescription}
              />
            </div>

            <BidNotes bid={bid.model} />
          </div>
        ) : null}

        <FormValidationError errors={formValidationErrors} />
        <ApiError
          error={
            project.fetchError ||
            bidInvoices.fetchError ||
            bidInvoices.createError ||
            bidInvoices.editError
          }
        />
      </form>
    </ModalTemplate>
  );
};
export default withRouter(InvoiceEditModal);
