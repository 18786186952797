import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import { connectRTC, disconnectRTC, setEntityUpdate } from "../actions/rtc";

export function useRTCStore() {
  const dispatch = useDispatch();
  const _rtc = useSelector((store) => store.rtc);

  const _connectRTC = useCallback(
    (roomName, entityId) => dispatch(connectRTC(roomName, entityId)),
    [dispatch]
  );

  const _disconnectRTC = useCallback(() => dispatch(disconnectRTC()), [
    dispatch,
  ]);

  const _setEntityUpdate = useCallback(
    (data) => dispatch(setEntityUpdate(data)),
    [dispatch]
  );

  return {
    rtc: _rtc,
    connectRTC: _connectRTC,
    disconnectRTC: _disconnectRTC,
    setEntityUpdate: _setEntityUpdate,
  };
}
