export const initialState = {
  fetch: false,
  fetchSuccess: false,
  fetchError: null,

  delete: false,
  deleteSuccess: false,
  deleteError: null,

  collection: [],
  pagination: null,
};

export default function reducer(
  state = {
    ...initialState,
  },
  action
) {
  switch (action.type) {
    case "PROJECTS_FETCH":
      return {
        ...state,
        fetch: true,
        fetchSuccess: false,
        fetchError: null,
      };

    case "PROJECTS_FETCH_FULFILLED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: true,

        collection: action.data.projects,
        pagination: action.data.pagination,
      };

    case "PROJECTS_FETCH_REJECTED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: false,
        fetchError: action.data,
      };

    case "PROJECT_CREATE_FULFILLED":
      const withNewProjectCollection = [...state.collection];
      withNewProjectCollection.unshift(action.data);
      return {
        ...state,
        collection: withNewProjectCollection,
      };

    case "PROJECT_EDIT_FULFILLED":
      let withEditedProjectCollection = state.collection.map((value) => {
        return value.id === action.data.id
          ? { ...action.data, shots: action.data.shots || value.shots }
          : { ...value };
      });
      return {
        ...state,
        collection: withEditedProjectCollection,
      };

    case "DEPOSIT_INVOICE_STATUS_EDIT_FULFILLED":
      return {
        ...state,
        collection: state.collection.map((project) =>
          project.id === action.data.id
            ? {
                ...project,
                deposit_invoice_status: action.data.deposit_invoice_status,
              }
            : { ...project }
        ),
      };

    case "PROJECT_DELETE":
      return {
        ...state,
        delete: true,
        deleteSuccess: false,
        deleteError: null,
      };

    case "PROJECT_DELETE_FULFILLED":
      let isDeleted = !!action.data.responseData.success;
      let collectionDeleted = isDeleted
        ? state.collection.filter((value) => value.id !== action.data.id)
        : state.collection;
      return {
        ...state,
        delete: false,
        deleteSuccess: isDeleted,

        collection: collectionDeleted,
      };

    case "PROJECT_DELETE_REJECTED":
      return {
        ...state,
        delete: false,
        deleteSuccess: false,
        deleteError: action.data,
      };

    case "PROJECT_FETCH_FULFILLED":
      let collectionWithFetchedProject = [...state.collection];
      if (
        state.collection.filter((item) => item.id === action.data.id).length
      ) {
        collectionWithFetchedProject = state.collection.map((item) =>
          item.id === action.data.id ? action.data : item
        );
      } else {
        collectionWithFetchedProject = [...state.collection, action.data];
      }
      return {
        ...state,
        collection: collectionWithFetchedProject,
      };

    case "CLEAR_ERRORS":
      return {
        ...initialState,
        collection: state.collection,
        pagination: state.pagination,
      };

    case "LOGOUT":
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
