import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";

import ModalTemplate from "../ModalTemplate";
import * as bidActions from "../../actions/bid";
import * as bidVersionActions from "../../actions/bidVersion";
import connect from "react-redux/es/connect/connect";
import { store } from "../../configureStore";
import LoadingIndicator from "../../components/LoadingIndicator";
import ApiError from "../../components/ApiError";
import CustomizableDataTable from "../../components/CustomizableDataTable";
import { formatter } from "../../helpers/tools";
import ShotsTableExpandable from "./components/ShotsTableExpandable";

class ProjectBidVersionsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.bidVersionsDataTableConfig = {
      columns: [
        {
          name: "Version",
          center: true,
          cell: (row) => (
            <div className="pr-2">
              <div className="version-history-line-hover-hide" />
              <div className="version-history-line" />
              <div>
                {row.created
                  ? new Date(row.created * 1000).toLocaleDateString()
                  : "--"}
                <Link
                  className="d-block text-primary line-height-base"
                  to={`/bid/${row.id}/historical`}
                  target="_blank"
                >
                  <i className="fas fa-external-link-alt mr-1 font-size-xs" />
                  version {row.create_order ? row.create_order : "--"}
                  <br />
                  {row.version_name ? ` (${row.version_name})` : ""}
                </Link>
              </div>
            </div>
          ),
        },
        {
          name: "Update",
          selector: "update",
          format: (row) => (row.update ? <div>{row.update}</div> : "--"),
        },
        /*{
          name: "Producer",
          selector: "producer",
          format: row =>
            row.producer ? (
              <div>
                {row.producer}
              </div>
            ) : (
              "--"
            )
        },*/
        {
          name: "Description",
          selector: "description",
          format: (row) =>
            row.description ? (
              <div>
                Description:
                <br />
                <b>{row.description}</b>
              </div>
            ) : (
              "--"
            ),
        },
        {
          name: "Footer note",
          center: true,
          selector: "footer_notes",
          format: (row) =>
            row.footer_notes ? (
              <div>
                Footer note:
                <br />
                <b>{row.footer_notes}</b>
              </div>
            ) : (
              "--"
            ),
        } /*,
        {
          name: "Shots",
          selector: "shots",
          format: row =>
            row.shots ? (
              <div className="text-right">
                Shots:
                <br />
                <b>{row.shots.length}</b>
              </div>
            ) : (
              "--"
            ),
          center: true
        }*/,
        {
          name: "Total price",
          center: true,
          selector: "total_price",
          format: (row) =>
            row.total_price ? (
              <div className="text-right">
                Total price:
                <br />
                <b>{formatter.format(row.total_price)}</b>
              </div>
            ) : (
              "--"
            ),
        },
      ],
    };
  }

  /**
   *
   */
  componentDidMount() {
    this.getBid();
  }

  /**
   *
   * @returns {Promise<void>}
   */
  async getBid() {
    if (
      this.props.user.token &&
      this.props.user.model &&
      this.props.match.params.bidVersionId &&
      !this.props.bidVersion.fetch
    ) {
      await this.props.fetchBidVersion(this.props.match.params.bidVersionId);
      const bidVersion = store.getState().bidVersion;
      if (bidVersion && bidVersion.model) {
        await this.props.fetchBid(bidVersion.model.bid_id);
        await this.props.fetchBidVersionsByBid(bidVersion.model.bid_id);
        await this.props.fetchShotsByBidVersion(
          this.props.match.params.bidVersionId
        );
      }
    }
  }

  /**
   *
   * @returns {any[]}
   */
  get versions() {
    const bidModel = this.props.bid.model;
    const bidVersions = this.props.bid.versions;
    const projectId = this.props.bid.model.project
      ? this.props.bid.model.project.id
      : null;

    let previousVersion = null;
    let previousTotalPrice = 0;

    return (bidVersions || []).map((i, idx, self) => {
      let update = "New version";
      let description = null;
      let footerNotes = null;
      let totalPrice =
        -i.discount +
        i.rush +
        (i.vfx_supv_prep_cost + i.vfx_supv_on_set_cost + i.vfx_supv_post_cost);
      let totalPriceDisplay = null;

      if (i.is_approved) {
        update = "Approved";
      } else if (i.is_rejected) {
        update = "Rejected";
        description = i.rejection_notes;
      }

      if (previousVersion) {
        if (previousVersion.footer_notes !== i.footer_notes) {
          footerNotes = i.footer_notes;
        }

        if (totalPrice !== previousTotalPrice) {
          totalPriceDisplay = totalPrice;
        }
      } else {
        footerNotes = i.footer_notes;
      }

      previousVersion = i;
      previousTotalPrice = totalPrice;

      if (idx + 1 !== self.length) {
        return {
          id: i.id,
          created: i.created,
          create_order: i.create_order,
          version_name: i.version_name,
          update: update,
          description: description,
          footer_notes: footerNotes,
          shots: i.shots || [],
          total_price: totalPriceDisplay,
          project_id: projectId,
        };
      } else {
        return {
          id: i.id,
          created: i.created,
          create_order: i.create_order,
          version_name: i.version_name,
          update: update,
          description: description,
          footer_notes: i.footer_notes,
          shots: i.shots || [],
          total_price: bidModel.total + totalPrice,
          project_id: projectId,
        };
      }
    });
  }

  /**
   *
   * @returns {*}
   */
  render() {
    const bid = this.props.bid;
    const bidModel = bid.model;
    const bidVersion = this.props.bidVersion;

    const loadingIndicator =
      bid.fetch || bidVersion.fetch || bid.fetchBidVersionsByBid ? (
        <LoadingIndicator />
      ) : null;

    const modalTitle = `All versions of Bid: ${bidModel.name || ""}`;

    const versions = this.versions;

    const expandableRowsComponent = <ShotsTableExpandable />;

    const dataTable = (
      <div className="data-table-themed_light data-table-themed_hide-expand-arrow data-table-themed_highlight-on-hover">
        <CustomizableDataTable
          collection={versions}
          columns={this.bidVersionsDataTableConfig.columns}
          expandableRows={true}
          expandOnRowClicked={true}
          expandableRowsComponent={expandableRowsComponent}
        />
      </div>
    );

    return (
      <ModalTemplate
        className="modal-xl"
        title={modalTitle}
        onClose={this.props.onClose}
        cancelButtonLabel="Cancel"
        onCancel={this.props.onClose}
        loadingIndicator={loadingIndicator}
      >
        {dataTable}
        <ApiError
          error={
            bid.fetchError ||
            bidVersion.fetchError ||
            bid.fetchBidVersionsByBidError
          }
        />
      </ModalTemplate>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  bid: state.bid,
  bidVersion: state.bidVersion,
});

const mapDispatchToProps = (dispatch) => ({
  fetchBid: async (id) => await dispatch(bidActions.fetchBid(id)),
  fetchBidVersion: async (id) =>
    await dispatch(bidVersionActions.fetchBidVersion(id)),
  fetchBidVersionsByBid: async (bidId) =>
    await dispatch(bidActions.fetchBidVersionsByBid(bidId)),
  fetchShotsByBidVersion: async (id) =>
    await dispatch(bidVersionActions.fetchShotsByBidVersion(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProjectBidVersionsModal));
