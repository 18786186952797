import React, { Component } from "react";
import PropTypes from "prop-types";

const Select = (props) => {

  const selectProps = {};

  if (props.defaultValue && !props.onChange) {
    selectProps.defaultValue = props.defaultValue;
  }
  else {
    selectProps.onChange = props.onChange;
    selectProps.value = props.value;

  }

  return (
    <select
      className={
        props.className + " form-control select " + props.initClassName
      }
      data-fouc
      disabled={props.disabled}
      name={props.name}
      {...selectProps}
    >
      {props.data.map((item, index) => {
        return (
          <option value={item.value} key={index} disabled={item.disabled}>
            {item.label}
          </option>
        );
      })}
    </select>
  );
};

Select.defaultProps = {
  className: "",
  initClassName: "",
}

Select.propTypes = {
  className: PropTypes.string,
  initClassName: PropTypes.string,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
  }))

}
export default Select;
