const initialState = {
  create: false,
  createSuccess: false,
  createError: null,

  edit: false,
  editSuccess: false,
  editError: null,

  fetch: false,
  fetchSuccess: false,
  fetchError: null,

  getClientId: false,
  getClientIdSuccess: false,
  getClientIdError: null,

  model: null,
  clientId: null,
};

export default function reducer(
  state = {
    ...initialState
  },
  action
) {
  switch (action.type) {
    case "CLIENT_CREATE":
      return {
        ...state,
        create: true,
        createSuccess: false,
        createError: null,
      };

    case "CLIENT_CREATE_FULFILLED":
      return {
        ...state,
        create: false,
        createSuccess: true,

        model: action.data,
      };

    case "CLIENT_CREATE_REJECTED":
      return {
        ...state,
        create: false,
        createSuccess: false,
        createError: action.data,
      };

    case "SET_CLIENT_EDIT":
      return {
        ...state,
        model: action.data
      };

    case "CLIENT_EDIT":
      return {
        ...state,
        edit: true,
        editSuccess: false,
        editError: null,
      };

    case "CLIENT_EDIT_FULFILLED":
      return {
        ...state,
        edit: false,
        editSuccess: true,

        model: action.data,
      };

    case "CLIENT_EDIT_REJECTED":
      return {
        ...state,
        edit: false,
        editSuccess: false,
        editError: action.data,
      };

    case "CLIENT_FETCH":
      return {
        ...state,
        fetch: true,
        fetchSuccess: false,
        fetchError: null,
      };

    case "CLIENT_FETCH_FULFILLED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: true,

        model: action.data,
      };

    case "CLIENT_FETCH_REJECTED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: false,
        fetchError: action.data,
      };

    case "CLIENT_GET_ID":
      return {
        ...state,
        getClientId: true,
        getClientIdSuccess: false,
        getClientIdError: null,
      };

    case "CLIENT_GET_ID_FULFILLED":
      return {
        ...state,
        getClientId: false,
        getClientIdSuccess: true,

        clientId: action.data.clients &&  action.data.clients[0] ? action.data.clients[0].id : null,

      };

    case "CLIENT_GET_ID_REJECTED":
      return {
        ...state,
        getClientId: false,
        getClientIdSuccess: false,
        getClientIdError: action.data,
      };

    case "CLEAR_ERRORS":
      return {
        ...initialState,
        model: state.model,
        clientId: state.clientId,
      };

    case "LOGOUT":
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
