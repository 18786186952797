import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import UserSelect from "./Selects/UserSelect";
import Select from "./Select";
import FormValidationError from "./FormValidationError";
import ApiError from "../components/ApiError";
import { tasksTypesToOptions } from "../helpers/tools";
import {useTasksTypesStore} from "../storeHooks/taskTypes";

const TasksEditLine = (props) => {
  const [tasksTypesOptions, setTasksTypesOptions] = useState([]);
  const {tasksTypes} = useTasksTypesStore();

  useEffect(() => {

    setTasksTypesOptions(tasksTypesToOptions(tasksTypes.collection));
  }, []);

  const data = props.data;
  const item = data.item;
  const index = data.index;
  const isLoading = props.isLoading;

  return (
    <div>
      <div className="row mb-n1">
        <div className="form-group col-lg-4">
          <label className="form-label text-nowrap">Assignee</label>
          <UserSelect
            onUpdate={props.formLineOnUpdate(index, "assignee_id")}
            disabled={isLoading}
            user={item.assignee ? item.assignee : { name: "Select Assignee" }}
            value={item.assignee ? item.assignee : { name: "Select Assignee" }}
            addEmptyItem={props.isEditMode}
            emptyItemLabel="Unassigned"
            emptyItemValue="unassigned"
            reactSelectProps={{ classNamePrefix: "bg-dark" }}
          />
        </div>

        <div className="form-group col-lg-4">
          <label className="form-label text-nowrap">Task type</label>
          {tasksTypesOptions.length ? (
            <Select
              data={tasksTypesOptions}
              value={item.task_type_id || tasksTypesOptions[0].value}
              initClassName="js-select2"
              className="js-task-type-select"
              disabled={isLoading}
              classNamePrefix="bg-dark"
            />
          ) : null}
        </div>

        <div className="form-group col-lg-4">
          <label className="form-label text-nowrap">Notes</label>
          <input
            type="text"
            className="form-control"
            placeholder="Notes"
            maxLength={60}
            value={item.notes || ""}
            onChange={props.formLineOnUpdate(index, "notes")}
            disabled={isLoading}
          />
        </div>
      </div>

      <FormValidationError errors={props.formValidationErrors[index]} />
      {index === 0 ? <ApiError error={props.apiError} /> : null}
    </div>
  );
};


export default TasksEditLine;

TasksEditLine.propTypes = {
  data: PropTypes.object.isRequired,
  formLineOnUpdate: PropTypes.func.isRequired,
  formValidationErrors: PropTypes.array.isRequired,
  apiError: PropTypes.object,
  isLoading: PropTypes.bool,
};
