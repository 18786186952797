/**
 * Created by piotr.pozniak@thebeaverhead.com on 25/05/2022.
 */

import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as tasksTypesActions from "../actions/tasksTypes";

export const useTasksTypesStore = () => {
  const dispatch = useDispatch();
  const _tasksTypes = useSelector((store) => store.tasksTypes);

  const fetchTasksTypes = useCallback(
    () => dispatch(tasksTypesActions.fetchTasksTypes()),
    [dispatch]
  );

  const createTaskType = useCallback(
    (data) => dispatch(tasksTypesActions.createTaskType(data)),
    [dispatch]
  );

  const deleteTaskType = useCallback(
    (id) => dispatch(tasksTypesActions.deleteTaskType(id)),
    [dispatch]
  );

  const editTaskType = useCallback(
    (id, data) => dispatch(tasksTypesActions.editTaskType(id, data)),
    [dispatch]
  );

  return {
    tasksTypes: _tasksTypes,
    fetchTasksTypes,
    createTaskType,
    deleteTaskType,
    editTaskType,
  };
};
