export const typeOptions = [
  {
    value: "Projects",
    label: "Projects",
    dashboardAllowedRoles: ["admin", "producer", "accountant"],
    allowedIn: ["is_list", "is_quick_stats", "is_chart"],
  },
  {
    value: "Bids",
    label: "Bids",
    dashboardAllowedRoles: ["admin", "producer", "accountant"],
    allowedIn: ["is_list", "is_quick_stats", "is_chart"],
  },
  {
    value: "Shots",
    label: "Shots",
    dashboardAllowedRoles: ["admin", "producer", "artist"],
    allowedIn: ["is_list", "is_quick_stats", "is_chart"],
  },
  {
    value: "Tasks",
    label: "Tasks",
    dashboardAllowedRoles: ["admin", "producer", "artist"],
    allowedIn: ["is_list"],
  },
  {
    value: "Invoices",
    label: "Invoices",
    dashboardAllowedRoles: ["admin", "producer", "accountant"],
    allowedIn: ["is_list", "is_quick_stats", "is_chart"],
  },
];
