import React, { Component } from "react";
import { history } from "../../configureStore";
import StaticAlert from "../../components/StaticAlert";
import PageTemplate from "../PageTemplate";
import * as bidVersionActions from "../../actions/bidVersion";
import * as bidActions from "../../actions/bid";
import * as bidsActions from "../../actions/bids";
import * as projectActions from "../../actions/project";
import * as appActions from "../../actions/app";
import connect from "react-redux/es/connect/connect";
import { Link, withRouter } from "react-router-dom";
import CustomizableDataTable from "../../components/CustomizableDataTable";
import DataGridPagination from "../../components/DataGridPagination";
import LoadingIndicator from "../../components/LoadingIndicator";
import DropdownMenu from "../../components/DropdownMenu";
import { filterAllowedRoles, formatter, hasRights } from "../../helpers/tools";
import BidVersionName from "../../components/BidVersionName";
import HasRights from "../../components/HasRights";
import ApiError from "../../components/ApiError";
import StatusEditable from "../../components/StatusEditable";
import moment from "moment";
import TotalFormat from "./TotalFormat";

const staticAlertHeader = "No records to display";
const staticAlertBody = (
  <>
    Either <Link to="/project/edit/new">create a new item</Link> or update your
    filters.
  </>
);
const clientStaticAlertBody = <>Update your filters.</>;

const trashAllowedRoles = ["admin", "producer", "accountant"];

class BidsPage extends Component {
  constructor(props) {
    super(props);

    const filterProps = this.filters;

    const filter = Object.keys(filterProps).map((key) => ({
      fieldName: key,
      value: filterProps[key],
    }));
    this.state = {
      filter,
      isFiltersApplied: !!filter.filter((i) => i && i.value).length,
      sortBy: props.filtersReducer.bidsSortBy,
      orderBy: props.filtersReducer.bidsOrderBy,
    };
  }

  /**
   *
   */
  componentDidMount() {
    if (this.props.user.token && this.props.user.model) {
      const page = this.props.filtersReducer.bidsPage;
      let slug = this.props.match.params.slug;
      if (
        slug === "trash" &&
        !hasRights(this.props.user.model, {}, trashAllowedRoles)
      ) {
        slug = undefined;
        history.replace(`/bids/${page}`);
      }

      if (this.props.filtersReducer.bidsPage !== this.props.match.params.page) {
        if (slug) {
          history.replace(`/bids/${slug}/${page}`);
        } else {
          history.replace(`/bids/${page}`);
        }
      } else {
        this.handleFilter();
      }
    }
  }

  /**
   * get filter from router
   * @return {{}}
   */
  get routerFilters() {
    let filters = {};

    if (this.props.match.params.slug === "archive") {
      filters.is_archived = 1;
    }

    if (this.props.match.params.slug === "trash") {
      filters.is_trash = 1;
    }

    return filters;
  }

  /**
   *
   * @return {{}}
   */
  get filters() {
    return { ...this.savedFilters, ...this.routerFilters };
  }

  /**
   * get filter from reducer
   * @return {{}}
   */
  get savedFilters() {
    let filters = {
      status:
        Array.isArray(this.props.filtersReducer.bidsStatus) &&
        this.props.filtersReducer.bidsStatus.length === 1
          ? this.props.filtersReducer.bidsStatus[0]
          : null,

      name: this.props.filtersReducer.bidsQuery,
      fromDate: this.props.filtersReducer.bidsFrom,
      toDate: this.props.filtersReducer.bidsTo,
    };

    if (
      Array.isArray(this.props.filtersReducer.bidsClients) &&
      this.props.filtersReducer.bidsClients.length
    ) {
      filters.clientFilter = this.props.filtersReducer.bidsClients[0];
    }

    return filters;
  }

  /**
   *
   * @returns {*}
   */
  get statuses() {
    return this.props.statuses.collection.filter((i) => i.type === "Projects");
  }

  /**
   *
   * @returns {{perPage: number, columns: *[], filters: *[]}}
   */
  get bidsProjectsDataTableConfig() {
    const statusFilter = this.state.filter.find(
      (x) => x.fieldName === "status"
    );
    const status = statusFilter && statusFilter.value ? statusFilter.value : "";

    const nameFilter = this.state.filter.find((x) => x.fieldName === "name");
    const name = nameFilter ? nameFilter.value : "";

    const fromDateFilter = this.state.filter.find(
      (x) => x.fieldName === "fromDate"
    );
    const fromDate = fromDateFilter ? fromDateFilter.value : "";

    const toDateFilter = this.state.filter.find(
      (x) => x.fieldName === "toDate"
    );
    const toDate = toDateFilter ? toDateFilter.value : "";

    const clientFilter = this.state.filter.find(
      (x) => x.fieldName === "clientFilter"
    );
    const clients = clientFilter ? clientFilter.value : null;

    const isRemoved =
      (this.state.filter.find((x) => x.fieldName === "is_archived") &&
        this.state.filter.find((x) => x.fieldName === "is_archived").value) ||
      (this.state.filter.find((x) => x.fieldName === "is_trash") &&
        this.state.filter.find((x) => x.fieldName === "is_trash").value);

    const projectStatuses = this.props.statuses.collection.filter(
      (i) => i.type === "Projects"
    );

    return {
      perPage: 30,
      columns: [
        {
          name: "Date modified",
          selector: "modified",
          format: (row) => (
            <span>
              {moment
                .unix(row.modified || row.created)
                .format("DD/MM/YY, HH:mm")}
            </span>
          ),
          sortable: true,
        },
        {
          name: "Project - Bid",
          selector: "name",
          format: (row) =>
            row && row.recent_bid_version && row.project ? (
              <Link
                to={"/bid/" + row.recent_bid_version.id}
                className="relative"
                onClick={this.handleDataTableRowClick(row)}
              >
                {row.project.name} - {row.name}
              </Link>
            ) : (
              <span>{row.name}</span>
            ),
          sortable: true,
        },
        {
          name: "Status",
          selector: "status",
          format: (row) => {
            return (
              <StatusEditable
                status={row.status}
                statuses={this.statuses}
                onChange={this.onStatusChange(row)}
                loading={this.props.bid.edit && this.state.editBidId === row.id}
                disabled={
                  !["admin", "producer", "accountant"].includes(
                    this.props.user.model.role
                  ) ||
                  (this.props.bid.edit && this.state.editBidId !== row.id)
                }
              />
            );
          },
          /*row && row.status ? (
              <StatusBadge
                status={row.status}
                statuses={this.statuses}
              />
            ) : (
              "--"
            ),*/
          sortable: true,
        },
        {
          name: "Client",
          selector: "client",
          sortable: true,
          format: (row) =>
            row && row.project && row.project.client
              ? row.project.client.name
              : "--",
        },
        {
          name: "Est. hours",
          selector: "project.required_hours",
          format: (row) =>
            row.project && row.project.required_hours
              ? Math.round((row.project.required_hours || 0) * 100) / 100
              : "--",
          center: true,
          width: "80px",
        },
        {
          name: "Total",
          selector: "total",
          format: TotalFormat,
          center: true,
          width: "120px",
        },
        {
          name: "Deposit",
          selector: "deposit",
          format: (row) => formatter.format(row.deposit || 0),
          center: true,
        },
        {
          name: "Current version",
          selector: "recent_bid_version.create_order",
          format: (row) => (
            <div className="text-center">
              <BidVersionName bidVersion={row.recent_bid_version} />
            </div>
          ),
          maxWidth: "100px",
          center: true,
        },
        {
          name: "Actions",
          right: true,
          width: "70px",
          cell: (row) => {
            let actionButtons = [];
            const projectStatus = row.project && row.project.status;

            const isProjectStatusInTodo =
              projectStatuses.findIndex(
                (i) => i.value === projectStatus && i.status_group === "todo"
              ) >= 0;

            if (!row.is_archived && !row.is_trash) {
              actionButtons.push({
                icon: "mi-mode-edit",
                action: this.onEditBidDetails,
                data: row,
                label: "Edit bid details...",
                allowedRoles: ["admin", "producer"],
              });
              actionButtons.push({
                icon: "mi-mode-edit",
                action: this.onEditProjectDetails,
                data: row,
                label: "Edit project details...",
                allowedRoles: ["admin", "producer"],
              });
              actionButtons.push({
                icon: "icon-link2",
                action: this.onShareBid,
                data: row,
                label: "Share via URL",
              });

              // allow to archive bid if its project is in "todo" status (so it's not available on projectd index page)
              if (!row.is_archived && isProjectStatusInTodo) {
                actionButtons.push({
                  icon: "mi-archive",
                  action: this.onArchiveBid(1),
                  data: row,
                  label: "Archive",
                  allowedRoles: ["admin", "producer"],
                });
              }

              actionButtons.push({
                icon: "mi-delete",
                action: this.onTrashBid(1),
                data: row,
                label: "Move to Trash",
                allowedRoles: ["admin", "producer"],
              });
            } else if (row.is_archived && !isProjectStatusInTodo) {
              actionButtons.push({
                icon: "mi-unarchive",
                action: this.onArchiveBid(0),
                data: row,
                label: "Unarchive",
                allowedRoles: ["admin", "producer"],
              });
            } else if (row.is_trash) {
              actionButtons.push({
                icon: "mi-restore",
                action: this.onTrashBid(0),
                data: row,
                label: "Restore",
                allowedRoles: ["admin", "producer"],
              });
              actionButtons.push({
                icon: "mi-money-off",
                action: this.onDeleteBid,
                data: row,
                label: "Permanently delete",
                allowedRoles: ["admin", "producer"],
              });
            }
            const filteredActionButtons = filterAllowedRoles(
              this.props.user.model,
              actionButtons
            );
            return filteredActionButtons.length ? (
              <DropdownMenu buttons={filteredActionButtons} />
            ) : null;
          },
          allowedRoles: isRemoved ? ["admin", "producer"] : null,
        },
      ],
      filters: [
        {
          placeholder: "Client",
          fieldName: "clients",
          type: "customerSelect",
          onUpdate: this.clientsFilterOnUpdate,
          reactSelectProps: {
            value: clients,
            isClearable: true,
            isMulti: false,
          },
          allowedRoles: ["admin", "producer"],
        },
        {
          data: this.statuses,
          placeholder: "Status",
          fieldName: "status",
          type: "select",
          value: status,
          allowedRoles: ["admin", "producer", "accountant", "client"],
        },
        {
          placeholder: "Project name or a code",
          defaultValue: name,
          fieldName: "name",
          type: "text",
          tooltipMessage:
            "This field should be at least 3 symbols to filter by a name or a code",
          allowedRoles: ["admin", "producer", "accountant", "client"],
        },
        {
          placeholderFrom: "Delivery date (from)",
          placeholderTo: "Delivery date (to)",
          fieldNameFrom: "fromDate",
          fieldNameTo: "toDate",
          fieldName: "dateRange",
          defaultValueFrom: fromDate,
          defaultValueTo: toDate,
          type: "dateRange",
          allowedRoles: ["admin", "producer", "accountant", "client"],
        },
      ],
    };
  }

  /**
   *
   * @param prevProps
   */
  componentDidUpdate(prevProps) {
    // update filters
    if (this.props.match.params.slug !== prevProps.match.params.slug) {
      const filterProps = this.filters;
      const filter = Object.keys(filterProps).map((key) => ({
        fieldName: key,
        value: filterProps[key],
      }));
      this.setState(
        {
          filter,
          sortBy: null,
          orderBy: null,
        },
        () => {
          document.querySelector(".js-bids-filter") &&
            document.querySelector(".js-bids-filter").reset();
          this.handleFilter();
        }
      );
    }

    // paginate
    if (
      prevProps.match.params.page !== this.props.match.params.page &&
      prevProps.match.params.slug === this.props.match.params.slug
    ) {
      this.handleFilter();
    }

    if (
      prevProps.bids.fetchError !== this.props.bids.fetchError &&
      this.props.bids.fetchError
    ) {
      const slug = this.props.match.params.slug;
      if (slug) {
        history.replace(`/bids/${slug}/1`);
      } else {
        history.replace(`/bids/1`);
      }
    }
  }

  /**
   *
   * @param bid
   * @returns {(function(*): void)|*}
   */
  handleDataTableRowClick = (bid) => (event) => {
    event.preventDefault();
    const recentBidVersion = bid.recent_bid_version;
    this.props.setEditProject(bid.project);
    this.props.setBidVersion(recentBidVersion);

    const url = "/bid/" + recentBidVersion.id;
    history.push(url);
  };

  /**
   *
   * @param page
   */
  onChangePage = (page) => {
    const state = this.props.router.location.state;
    const slug = this.props.match.params.slug;
    if (slug) {
      history.push(`/bids/${slug}/${page}`, state);
    } else {
      history.push(`/bids/${page}`, state);
    }
  };

  /**
   *
   * @param event
   */
  onPressAddBid = (event) => {
    event.preventDefault();
    history.push("/project-bid/edit/new");
  };

  /**
   *
   * @param bid
   */
  onEditBidDetails = (bid) => {
    this.props.setEditBid(bid);
    this.props.setEditProject(bid.project);
    history.push(`/project-bid/edit/${bid.id}`);
  };

  /**
   *
   * @param bid
   */
  onEditProjectDetails = (bid) => {
    //this.props.setEditBid(bid);
    this.props.setEditProject(bid.project);
    history.push(`/project/edit/${bid.project.id}`);
  };

  /**
   *
   * @param bid
   */
  onShareBid = (bid) => {
    history.push(`/bids/share/${bid.id}`);
  };

  /**
   *
   * @param value
   */
  onArchiveBid = (value) => async (bid) => {
    await this.props.editBid(bid.id, { is_archived: value });
    this.onTableFilter(this.state.filter);
  };

  /**
   *
   * @param value
   */
  onTrashBid = (value) => async (bid) => {
    await this.props.editBid(bid.id, { is_trash: value });
    this.onTableFilter(this.state.filter);
  };

  /**
   *
   * @param bid
   * @returns {Promise<void>}
   */
  onDeleteBid = async (bid) => {
    if (
      window.confirm(
        `Are you sure you want to delete ${bid.project.name} - ${bid.name}?`
      )
    ) {
      await this.props.deleteBid(bid.id);
      this.onTableFilter(this.state.filter);
    }
  };

  /**
   /**
   *
   * @param value
   */
  clientsFilterOnUpdate = (value) => {
    const filter = [...this.state.filter];
    let filterField = {
      fieldName: "clientFilter",
      value: null,
    };
    if (value) {
      filterField = {
        fieldName: "clientFilter",
        value: {
          label: value.email ? `${value.name} (${value.email})` : value.name,
          value: value.id,
        },
      };
    }
    const clientFilterIndex = filter.findIndex(
      (x) => x.fieldName === "clientFilter"
    );
    if (clientFilterIndex > -1) {
      if (value) {
        filter[clientFilterIndex] = filterField;
      } else {
        filter.splice(clientFilterIndex, 1);
      }
    } else if (value) {
      filter.push(filterField);
    }
    this.setState({ filter });
  };

  /**
   *
   * @param filter
   */
  onTableFilter = (filter = []) => {
    // special case, because clientFilter is not handled by jQuery on CustomizableDataTable
    const stateFilter = [...this.state.filter];
    const clientFilterIndex = stateFilter.findIndex(
      (x) => x.fieldName === "clientFilter"
    );
    if (clientFilterIndex > -1) {
      filter.push(stateFilter[clientFilterIndex]);
    }
    // special case 2, apply filters from the router
    const routerFilters = this.routerFilters;
    Object.keys(routerFilters).forEach((key) => {
      filter.push({
        fieldName: key,
        value: routerFilters[key],
      });
    });
    //
    this.setState({ filter, isFiltersApplied: !!filter.length }, () => {
      if (this.props.match.params.page !== "1") {
        const state = this.props.router.location.state;
        const slug = this.props.match.params.slug;
        if (slug) {
          history.replace(`/bids/${slug}/1`, state);
        } else {
          history.replace("/bids/1", state);
        }
      } else {
        this.handleFilter();
      }
    });
  };

  /**
   *
   * @param column
   * @param orderBy
   */
  onTableSort = (column, orderBy) => {
    this.setState(
      {
        sortBy: column.selector,
        orderBy,
      },
      this.handleFilter
    );
  };

  /**
   *
   */
  handleFilter = () => {
    const statusFilter = this.state.filter.find(
      (x) => x.fieldName === "status"
    );
    const status =
      statusFilter && statusFilter.value ? [statusFilter.value] : null;

    const clientFilter = this.state.filter.find(
      (x) => x.fieldName === "clientFilter"
    );
    const clients = clientFilter ? [clientFilter.value] : null;

    const nameFilter = this.state.filter.find((x) => x.fieldName === "name");
    const name = nameFilter ? nameFilter.value : null;

    const fromDateFilter = this.state.filter.find(
      (x) => x.fieldName === "fromDate"
    );
    const fromDate = fromDateFilter ? fromDateFilter.value : null;

    const toDateFilter = this.state.filter.find(
      (x) => x.fieldName === "toDate"
    );
    const toDate = toDateFilter ? toDateFilter.value : null;

    const is_archivedFilter = this.state.filter.find(
      (x) => x.fieldName === "is_archived"
    );
    const is_archived = is_archivedFilter ? is_archivedFilter.value : null;

    const is_trashFilter = this.state.filter.find(
      (x) => x.fieldName === "is_trash"
    );
    const is_trash = is_trashFilter ? is_trashFilter.value : null;

    this.props.fetchBids(
      this.props.match.params.page,
      this.bidsProjectsDataTableConfig.perPage,
      {
        status,
        clients,
        q: name,
        from: fromDate,
        to: toDate,
        sort_by: this.state.sortBy,
        order_by: this.state.orderBy,
        all_statuses: status ? 0 : 1,
        is_archived,
        is_trash,
      }
    );
  };

  /**
   *
   */
  onTableClearFilter = () => {
    const filter = [];
    this.setState(
      {
        filter,
        sortBy: null,
        orderBy: null,
        isFiltersApplied: false,
      },
      () => {
        this.onTableFilter(filter);
      }
    );
  };

  /**
   *
   * @param collapse
   */
  onToggleFilters = (collapse) => {
    this.props.collapseFilter("BidsPage", collapse);
  };

  /**
   *
   * @param row
   * @returns {(function(*): void)|*}
   */
  onStatusChange = (row) => async (value) => {
    if (value) {
      this.props.clearErrors();
      this.setState(
        {
          editBidId: row.id,
        },
        async () => {
          await this.props.editBid(row.id, { status: value });
          this.setState({
            editBidId: null,
          });
          window.updateJQuery();
        }
      );
    }
  };

  /**
   *
   * @returns {XML}
   */
  render() {
    const role =
      this.props.user && this.props.user.model
        ? this.props.user.model.role
        : "";

    const bids = this.props.bids;
    const project = this.props.project;
    const collectionBids = bids.collection;

    const loadingIndicator =
      bids.delete || bids.fetch || project.fetch || project.edit ? (
        <LoadingIndicator isModal />
      ) : null;
    const paginationIndicator =
      bids.fetch && collectionBids ? <LoadingIndicator isModal /> : null;

    const pagination =
      collectionBids && collectionBids.length ? (
        <DataGridPagination
          onChangePage={this.onChangePage}
          pagination={bids.pagination}
        />
      ) : null;

    const defaultSortAsc = this.props.filtersReducer.bidsOrderBy
      ? this.props.filtersReducer.bidsOrderBy === "asc"
      : null;

    const allowedColumns = filterAllowedRoles(
      this.props.user.model,
      this.bidsProjectsDataTableConfig.columns
    );

    const allowedFilters = filterAllowedRoles(
      this.props.user.model,
      this.bidsProjectsDataTableConfig.filters
    );

    const dataTableBids = (
      <div>
        <CustomizableDataTable
          collection={collectionBids}
          columns={allowedColumns}
          showFilters={true}
          collapseFilters={this.props.app.bidsPageFilterCollapsed}
          onToggleFilters={this.onToggleFilters}
          filters={allowedFilters}
          filterSelectorHtmlClass="js-bids-filter"
          onFilter={this.onTableFilter}
          isFiltersApplied={this.state.isFiltersApplied}
          onClearAll={this.onTableClearFilter}
          footerComponent={pagination}
          onSort={this.onTableSort}
          sortServer
          defaultSortField={this.props.filtersReducer.bidsSortBy}
          defaultSortAsc={defaultSortAsc}
        />
      </div>
    );

    const staticAlert =
      collectionBids && !collectionBids.length ? (
        <StaticAlert
          header={staticAlertHeader}
          body={role === "client" ? clientStaticAlertBody : staticAlertBody}
        />
      ) : null;

    const newBidButton = (
      <HasRights
        allowedRoles={["admin", "producer"]}
        user={this.props.user.model}
      >
        <Link
          to="!#"
          className="btn btn-link btn-float text-default"
          onClick={this.onPressAddBid}
        >
          <i className="mi-attach-money text-primary" />
          <span>Add bid</span>
        </Link>
      </HasRights>
    );

    let pageTitle = "Bids";
    if (
      this.state.filter.find((x) => x.fieldName === "is_archived") &&
      this.state.filter.find((x) => x.fieldName === "is_archived").value
    ) {
      pageTitle = "Archived Bids";
    } else if (
      this.state.filter.find((x) => x.fieldName === "is_trash") &&
      this.state.filter.find((x) => x.fieldName === "is_trash").value
    ) {
      pageTitle = "Trash Bids";
    }

    return (
      <PageTemplate header={pageTitle} rightTopMenu={newBidButton}>
        <ApiError error={project.editError || bids.deleteError} />
        {dataTableBids}
        {loadingIndicator}
        {staticAlert}
        {paginationIndicator}
      </PageTemplate>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
  user: state.user,
  bids: state.bids,
  bid: state.bid,
  project: state.project,
  statuses: state.statuses,
  filtersReducer: state.filtersReducer,
  router: state.router,
});

const mapDispatchToProps = (dispatch) => ({
  setBidVersion: (bid) => dispatch(bidVersionActions.setBidVersion(bid)),
  setEditProject: (project) => dispatch(projectActions.setEditProject(project)),
  setEditBid: (bid) => dispatch(bidActions.setEditBid(bid)),
  editBid: (id, data) => dispatch(bidActions.editBid(id, data)),
  fetchBids: async (page, limit, filters, type = "BidsPage") =>
    await dispatch(bidsActions.fetchBids(page, limit, filters, type)),
  deleteBid: async (id) => await dispatch(bidsActions.deleteBid(id)),
  collapseFilter: (type, collapse) =>
    dispatch(appActions.collapseFilter(type, collapse)),
  clearErrors: () => dispatch(appActions.clearErrors()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BidsPage));
