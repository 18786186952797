import { prettifyProperty } from "./error";
import moment from "moment";
import VariablesString from "./VariablesString";

/**
 *
 * @param name
 * @returns {string}
 */
export const getInitials = (name) => {
  if (!name || !name.length) {
    return "UU";
  }
  let initials = name.charAt(0).toUpperCase();

  const tokenized = name.split(" ");

  const isStringReg = /^[A-Za-z]*$/;

  if (tokenized.length === 1) {
    initials += name.charAt(1).toUpperCase();
  } else if (!isStringReg.test(tokenized[1].charAt(0))) {
    initials += name.charAt(1).toUpperCase();
  } else {
    initials += tokenized[1].charAt(0).toUpperCase();
  }

  return initials;
};

/**
 *
 * @param str
 * @returns {string}
 */
export const stringToColour = (str) => {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = "#";
  for (i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 0xff;
    colour += ("00" + value.toString(16)).substr(-2);
  }
  return colour;
};

/**
 *
 * @param color
 * @returns {string}
 */
export const lightOrDark = (color) => {
  // Variables for red, green, blue values
  let r, g, b, hsp;

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If HEX --> store the red, green, blue values in separate variables
    color = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    );

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    color = +("0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&"));

    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return "light";
  } else {
    return "dark";
  }
};

/**
 *
 * @param obj
 * @param string
 * @returns {*}
 */
export const getObjValueWithDotNotation = (obj, string) => {
  let parts = string.split(".");
  let newObj = obj[parts[0]];
  if (parts[1]) {
    parts.splice(0, 1);
    let newString = parts.join(".");
    return getObjValueWithDotNotation(newObj, newString);
  }
  return newObj;
};

/**
 *
 * @param obj
 * @param filter
 * @returns {*}
 */
export const filterComparisonAND = (obj, filter) => {
  return filter.every((filterItem) => {
    return (
      (getObjValueWithDotNotation(obj, filterItem.fieldName) || "")
        .toString()
        .toLowerCase()
        .indexOf(filterItem.value.toLowerCase()) !== -1
    );
  });
};

/**
 *
 * @param fullName
 * @returns {*}
 */
export const preformProjectCode = (fullName) => {
  if (typeof fullName !== "string") {
    return "";
  }
  fullName = fullName.trim();
  if (!fullName) {
    return "";
  }
  let trimmedName = fullName.trim();
  let pureName = trimmedName.replace(/\d+$/, "");
  pureName = pureName.trim().replace(/ {2,}/g, " ");
  let digitsMatch = trimmedName.match(/\d+$/);
  let digits = digitsMatch ? digitsMatch[0] : "";
  if (!pureName && digits) {
    return digits;
  }
  const digitsResult = digits ? digits : "";
  if (pureName.length < 3) {
    return pureName.toUpperCase() + digitsResult;
  }
  let result = "";
  const wordsAr = pureName.split(" ");
  if (wordsAr.length >= 3) {
    result =
      (wordsAr[0][0] + wordsAr[1][0] + wordsAr[2][0]).toUpperCase() +
      digitsResult;
  } else if (wordsAr.length === 2) {
    result =
      (wordsAr[0][0] + wordsAr[0][1] + wordsAr[1][0]).toUpperCase() +
      digitsResult;
  } else {
    result =
      (wordsAr[0][0] + wordsAr[0][1] + wordsAr[0][2]).toUpperCase() +
      digitsResult;
  }
  return result;
};

/**
 *
 * @type {Intl.NumberFormat}
 */
export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

/**
 *
 * @param user
 * @param item
 * @param allowedRoles
 */
export function hasRights(user, item, allowedRoles = []) {
  if (Object.keys(item).includes("create_user_id")) {
    return user.id === item.create_user_id;
  }

  if (Object.keys(item).includes("user")) {
    return user.id === item.user.id;
  }

  if (Object.keys(user).includes("role")) {
    if (Array.isArray(allowedRoles)) {
      return allowedRoles.includes(user.role);
    }
    if (typeof allowedRoles === "string") {
      return allowedRoles === user.role;
    }
  }

  return false;
}

/**
 *
 * @param user
 * @param collection
 * @returns {*}
 */
export function filterAllowedRoles(user, collection) {
  if (!user) {
    return collection;
  }
  return collection.filter((item) => {
    if (
      Object.keys(item).includes("allowedRoles") &&
      Array.isArray(item.allowedRoles) &&
      Object.keys(user).includes("role")
    ) {
      return item.allowedRoles.includes(user.role);
    }
    return true;
  });
}

/**
 *
 * @param user
 * @param collection
 * @returns {*}
 */
export function filterDataTableOptionsByRole(user, collection) {
  if (!user) {
    return collection;
  }
  return collection.map((item) => {
    return Object.keys(item).includes("roleOptions") &&
      Object.keys(user).includes("role") &&
      user.role in item.roleOptions
      ? { ...item, ...item.roleOptions[user.role] }
      : item;
  });
}

/**
 *
 * @param taskTypes
 * @returns {*}
 */
export function tasksTypesToOptions(taskTypes) {
  return taskTypes.map((item) => {
    return {
      value: item.id,
      label: item.name,
    };
  });
}

/**
 *
 * @param seconds
 * @returns {string}
 */
export function secondsToHm(seconds) {
  seconds = Number(seconds);
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);

  if (h === 0 && m === 0) {
    return "--";
  }

  const hDisplay = h > 0 ? h + "h" : "";
  const mDisplay = m > 0 ? m + "m" : "";
  return hDisplay + " " + mDisplay;
}

/**
 *
 * @param project
 * @param recentBidVersion
 * @returns {*}
 */
export function countTotalSummaryQuote(project, recentBidVersion) {
  const additionalCosts = recentBidVersion
    ? Number(recentBidVersion.vfx_supv_on_set_cost) +
      Number(recentBidVersion.vfx_supv_post_cost) +
      Number(recentBidVersion.vfx_supv_prep_cost)
    : 0;

  const discounts = recentBidVersion
    ? -Number(recentBidVersion.discount) + Number(recentBidVersion.rush)
    : 0;

  return project.quote + additionalCosts + discounts;
}

/**
 *
 * @param statusValue
 * @param statuses
 * @returns {string|null}
 */
export function getStatus(statusValue, statuses) {
  if (!statusValue) {
    return null;
  }
  const status = statuses.filter((item) => item.value === statusValue);
  return status.length ? status[0].label : prettifyProperty(statusValue);
}

/**
 *
 * @param arr
 * @param oldIndex
 * @param newIndex
 * @returns {*}
 */
export function arrayMove(arr, oldIndex, newIndex) {
  if (newIndex >= arr.length) {
    var k = newIndex - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
}

/**
 *
 * @param hex
 * @returns {string}
 */
export function invertHex(hex) {
  return (Number(`0x1${hex}`) ^ 0xffffff).toString(16).substr(1).toUpperCase();
}

/**
 *
 * @param selector
 * @return {boolean}
 */
export function onCopyText(selector) {
  let successful = false;

  const input = document.querySelector(selector);

  input.readOnly = false;
  input.contentEditable = true;
  input.focus();
  input.select();

  const range = document.createRange();
  range.selectNodeContents(input);

  const s = window.getSelection();
  s.removeAllRanges();
  s.addRange(range);

  input.setSelectionRange(0, 999999);

  try {
    successful = document.execCommand("copy");
  } catch (err) {
    successful = false;
  }

  s.removeAllRanges();
  s.addRange(range);
  input.blur();
  input.readOnly = true;
  input.contentEditable = false;

  return successful;
}

/**
 *
 * @param input
 * @param date in seconds
 */
export function inputSetDateAsValue(input, date) {
  if (window.navigator.userAgent.indexOf("Safari") > -1) {
    input.setAttribute("placeholder", "YYYY-MM-DD");
    input.value = date ? moment(date * 1000).format("YYYY-MM-DD") : null;
  } else {
    input.valueAsDate = date ? new Date(date * 1000) : null;
  }
}

/**
 *
 * @param a
 * @param b
 * @returns {number}
 */
export function shotsSortFn(a, b) {
  if (a.shot_number > b.shot_number) {
    return -1;
  }
  if (b.shot_number > a.shot_number) {
    return 1;
  }
  return 0;
}

/**
 *
 * @param shotNumber
 * @returns {*}
 */
export const incrementShotNumber = (shotNumber) => {
  const digitsBuffer = extractDigits(shotNumber);

  if (digitsBuffer.length > 0) {
    const prevShotNumberStr = digitsBuffer.join("");
    const prevShotNumber = Number(prevShotNumberStr);
    const prevShotNumberIncrement = prevShotNumber + 10;

    if (
      String(prevShotNumberIncrement).length > String(prevShotNumber).length
    ) {
      return shotNumber.replace(
        new RegExp(prevShotNumberStr + "$"),
        prevShotNumberIncrement
      );
    }

    return shotNumber.replace(
      new RegExp(prevShotNumber + "$"),
      prevShotNumberIncrement
    );
  }
  return shotNumber;
};

/**
 *
 * @param shotNumber
 * @returns {*[]}
 */
export const extractDigits = (shotNumber) => {
  const digitsBuffer = [];

  let i = shotNumber.length - 1;
  while (i >= 0) {
    if (isNaN(shotNumber.charAt(i))) {
      i = -1;
    } else {
      digitsBuffer.push(shotNumber.charAt(i));
      i -= 1;
    }
  }

  return digitsBuffer.reverse();
};

/**
 *
 * @param array
 * @param property
 * @returns {*}
 */
export const extractObjectWithMaxPropertyValue = (array, property) => {
  const numbersAr = array.map((obj) => obj[property]);
  const maxIndex = numbersAr.indexOf(Math.max(...numbersAr));
  return array[maxIndex];
};

/**
 *
 * @param ar
 * @param key
 * @returns {V[]}
 */
export const filterArrayUniqueByKey = (ar, key) => {
  return [
    ...new Map(
      ar.map((item) => [getObjValueWithDotNotation(item, key), item])
    ).values(),
  ];
};

/**
 *
 * @param string
 * @param variablesNames
 * @returns {{text: string, type: string}}
 */
export const validateVariablesString = (string, variablesNames) => {
  const variablesString = new VariablesString({
    string,
    variablesWithDataToReplace: variablesNames.map((item) => ({ name: item })),
  });
  return variablesString.validate();
};

/**
 *
 * @param namesAr
 * @param settings
 * @returns {{}}
 */
export const getSettingsByNames = (namesAr, settings) => {
  const settingsResultObj = {};

  settings
    .filter((item) => namesAr.indexOf(item.name) > -1)
    .forEach((item) => {
      settingsResultObj[item.name.split(".")[1]] = isNaN(item.value)
        ? item.value
        : +item.value;
    });

  return settingsResultObj;
};

/**
 *
 * @param bidModel
 * @returns {null|{po_number: (null|string), create_order: string, created: *, deposit: *, id: string, bid_id: *, type: string, status: *}}
 */
export const getDepositInvoice = (bidModel = {}) => {
  if (!bidModel || !bidModel.deposit_po_number) {
    return null;
  }
  return {
    id: `bid_id_${bidModel.id}`,
    bid_id: bidModel.id,
    create_order: `${bidModel.deposit_invoice_create_order || "--"} (Deposit ${bidModel.name})`,
    created: bidModel.deposit_invoice_created,
    po_number: bidModel.deposit_po_number,
    status: bidModel.deposit_invoice_status,
    deposit: bidModel.deposit,
    type: "deposit_invoice",
  };
};

/**
 *
 * @param obj
 * @returns {boolean}
 */
export const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

/**
 *
 * @param bidVersion
 * @returns {string|*}
 */
export const getBidVersionName = (bidVersion) => {
  return bidVersion ? bidVersion.version_name || bidVersion.create_order : "";
};

/**
 *
 * @param string
 * @param amount
 * @returns {*}
 */
export const increaseNumberInString = (string, amount) => {
  const regex = /[0-9]+(?!.*[0-9])/;
  const match = string.match(regex);
  const numberSize = match ? match.toString().length : 0;
  const increased = string.replace(regex, function (match) {
    return padLeadingZeros(parseInt(match, 10) + +amount, numberSize);
  });

  return increased !== string ? increased : `${string}${amount}`;
};

/**
 *
 * @param number
 * @param size
 * @returns {string}
 */
export const padLeadingZeros = (number, size) => {
  let string = number + "";
  while (string.length < size) {
    string = "0" + string;
  }
  return string;
};
