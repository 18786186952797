import React, {memo} from 'react';
import {formatError} from '../helpers/error';

const defaultHeading = 'The following error occur';
const defaultMessage = 'Could not perform this action';

const ApiError = props => {

    const errorObj = props.error;
    const heading = props.heading || defaultHeading;
    const messageDefault = props.defaultMessage || defaultMessage;
    return (
      errorObj ?
        <div className="validation-invalid-label">
          <strong>{heading}</strong>
          <div>
            {formatError(errorObj, messageDefault)
              .map((item, index) => <React.Fragment key={index}>{item}<br/></React.Fragment>)}
          </div>
        </div> :
        null
    );
}

export default memo(ApiError);
