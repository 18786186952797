import React, { useMemo } from "react";
import PropTypes from "prop-types";

import CustomizableDataTable from "../../../components/CustomizableDataTable";
import StatusEditable from "../../../components/StatusEditable";
import StaticAlert from "../../../components/StaticAlert";
import { useStatusesStore } from "../../../storeHooks/statuses";

const staticAlertHeader = "No records to display";
const staticAlertBody = <>No shots are chosen to move.</>;

const ShotsTable = (props) => {
  const { shots = [] } = props;
  const { statuses } = useStatusesStore();

  const shotStatuses = useMemo(
    () => statuses.collection.filter((i) => i.type === "Shot"),
    [statuses.collection]
  );

  const formattedShots = shots.map((shot) => ({
    ...shot,
    current_version: shot.versions[shot.versions.length - 1],
  }));

  /**
   *
   */
  const onStatusChange = () => {};

  const shotsDataTableConfig = {
    columns: [
      {
        name: "Shot Number",
        selector: "shot_number",
        sortable: true,
        format: (row) => row.shot_number || "--",
        width: "200px",
      },
      {
        name: "Status",
        selector: "status",
        sortable: true,
        format: (row) => {
          return (
            <div className="">
              <StatusEditable
                statuses={shotStatuses}
                status={row.status}
                onChange={onStatusChange}
                disabled={true}
              />
            </div>
          );
        },
      },
      {
        name: "Est. Hours",
        selector: "current_version.hours",
        right: true,
        format: (row) =>
          Math.round(
            ((row.current_version && row.current_version.hours) || 0) * 100
          ) / 100,
      },
    ],
  };

  const dataTable = formattedShots ? (
    <CustomizableDataTable
      collection={formattedShots}
      columns={shotsDataTableConfig.columns}
    />
  ) : null;

  const staticAlert = !formattedShots.length ? (
    <StaticAlert header={staticAlertHeader} body={staticAlertBody} />
  ) : null;

  return (
    <div>
      {dataTable}
      {staticAlert}
    </div>
  );
};

ShotsTable.propTypes = {
  shots: PropTypes.array,
};

export default ShotsTable;
