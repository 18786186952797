import React from "react";
import PropTypes from "prop-types";

const BarDiagram = (props) => {

  return (
    <div className="card">
      <div className="card-header header-elements-inline">
        <h5 className="card-title">{props.title || "Diagram"}</h5>
      </div>

      <div className="card-body">
        <div className="chart-container">
          <div className="chart" id={props.id} />
        </div>
      </div>
    </div>
  );
};

BarDiagram.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  isDarkThemed: PropTypes.bool
};

export default BarDiagram;


