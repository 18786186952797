import React, { Component } from "react";
import { history } from "../../configureStore";
import StaticAlert from "../../components/StaticAlert";
import PageTemplate from "../PageTemplate";
import connect from "react-redux/es/connect/connect";
import * as appActions from "../../actions/app";
import * as shotActions from "../../actions/shot";
import * as projectActions from "../../actions/project";
import * as tasksActions from "../../actions/tasks";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import moment from "moment";
import CustomizableDataTable from "../../components/CustomizableDataTable";
import ArtistsList from "../../components/ArtistsList";
import LoadingIndicator from "../../components/LoadingIndicator";
import ApiError from "../../components/ApiError";
import ShotNotes from "./components/ShotNotes";
import TasksCustomizableDataTable from "../../components/TasksCustomizableDataTable";
import { filterComparisonAND, formatter } from "../../helpers/tools";
import HasRights from "../../components/HasRights";
import * as activitiesActions from "../../actions/activities";
import RecentActivity from "../../components/RecentActivity";
import SeoBlock from "../../components/SeoBlock";
import { updateTasksAssignees } from "../../hooks/useOverwriteAssignee";
import PageHeader from "./components/PageHeader";

const staticVersionsAlertHeader = "No records to display";
const staticVersionsAlertBody = <>This table lists versions.</>;

class ShotPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filteredTasksCollection: null,
      tasksFilter: [],
      tasksFilterIsFiltersApplied: false,

      filteredVersionsCollection: null,
      versionsFilter: [],
      versionsFilterIsFiltersApplied: false,

      showBidRevisionsFullList: false,

      bulkUpdate: false,
      assignError: [],
    };

    this.versionsDataTableConfig = {
      columns: [
        {
          name: "Version",
          selector: "create_order",
          sortable: true,
          width: "110px",
          format: (row) => {
            const cellClassName = classNames(
              "js-customizable-table-to-highlight",
              { "bg-success text-white": row.is_approved },
              {
                "bg-primary text-white":
                  this.props.shot.model &&
                  this.props.shot.model.current_version.id === row.id,
              }
            );
            return (
              <div className={cellClassName}>
                {!row.create_order ? "--" : row.create_order}
              </div>
            );
          },
        },
        {
          name: "Tasks",
          selector: "task_types",
          format: (row) =>
            row.task_types && row.task_types.length
              ? row.task_types.map((item, index) => {
                  return (
                    <span key={item.index + index.toString()}>
                      <span>
                        {item.name}
                        {index !== row.task_types.length - 1 ? ", " : null}
                      </span>{" "}
                    </span>
                  );
                })
              : "--",
        },
        {
          name: "Description",
          selector: "description",
          format: (row) => (!row.description ? "--" : row.description),
        },
        {
          name: "Bid notes",
          selector: "notes",
          format: (row) => (
            <div className="white-space-pre">
              {!row.notes ? "--" : row.notes}
            </div>
          ),
        },
        {
          name: "Created",
          selector: "created",
          format: (row) =>
            !row.created
              ? "--"
              : moment.unix(row.created).format("DD/MM/YY, HH:mm"),
        },
        {
          name: "Est. Hours",
          selector: "hours",
          format: (row) => Math.round((row.hours || 0) * 100) / 100,
          center: true,
        },
        {
          name: "Rate",
          selector: "rate",
          format: (row) =>
            !row.rate && row.rate !== 0 ? "--" : formatter.format(row.rate),
          center: true,
        },
        {
          name: "Cost",
          selector: "cost_summary",
          format: (row) =>
            !row.cost_summary && row.cost_summary !== 0
              ? "--"
              : formatter.format(row.cost_summary),
          center: true,
        },
      ],
      filters: [
        {
          placeholder: "Version name",
          defaultValue: "",
          fieldName: "create_order",
          type: "text",
        },
      ],
    };

    this.manageDropdownConfig = [
      {
        icon: "icon-equalizer",
        action: () => {
          alert("On manage item 1 click");
        },
        data: null,
        label: "Manage 1",
      },
      {
        icon: "icon-equalizer",
        action: () => {
          alert("On manage item 2 click");
        },
        data: null,
        label: "Manage 2",
      },
    ];
  }

  /**
   *
   */
  async componentDidMount() {
    if (this.props.user.token && this.props.user.model) {
      await this.props.fetchShot(this.props.match.params.shotId);
      if (!this.props.project.model) {
        await this.props.fetchProject(this.props.match.params.id);
      }
      //await this.props.fetchTasks(this.props.match.params.shotId);
      //await fetchTasks(this.props.match.params.id)
      window.updateJQuery();
    }
  }

  /**
   *
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.user.token &&
      this.props.user.model &&
      prevProps.match.params.shotId !== this.props.match.params.shotId
    ) {
      await this.props.fetchShot(this.props.match.params.shotId);
      if (!this.props.project.model) {
        await this.props.fetchProject(this.props.match.params.id);
      }

      await this.props.fetchTasks(this.props.match.params.shotId);
      window.updateJQuery();
    }

    if (
      prevProps.tasks.assignError !== this.props.tasks.assignError ||
      prevState.assignError.length !== this.state.assignError.length
    ) {
      const assignError = this.state.assignError.length
        ? this.state.assignError
        : this.props.tasks.assignError;

      if (!assignError && Array.isArray(prevProps.tasks.assignError)) {
        this.purgeTasksTableErrors();
      }

      setTimeout(window.updateJQuery, 0);
    }

    if (
      prevProps.tasks.edit !== this.props.tasks.edit &&
      this.props.tasks.edit
    ) {
      this.purgeTasksTableErrors();
    }

    if (
      (prevProps.tasks.assign !== this.props.tasks.assign &&
        !this.props.tasks.assign) ||
      (prevProps.tasks.edit !== this.props.tasks.edit &&
        !this.props.tasks.edit) ||
      (prevProps.tasks.bulkAssign !== this.props.tasks.bulkAssign &&
        !this.props.tasks.bulkAssign)
    ) {
      this.setState({ bulkUpdate: false }, window.updateJQuery);
    }

    setTimeout(window.updateJQuery, 0);
  }

  /**
   *
   */
  connectActivitiesSocket = () => {
    this.props.connectActivitiesSocket({
      activity_type: "shot",
      shot_id: this.props.match.params.shotId,
    });
  };

  /**
   *
   * @param event
   */
  onPressAddTask = (event) => {
    event.preventDefault();
    this.props.clearErrors();
    const { id, shotId } = this.props.match.params;
    history.push(`/project/${id}/shot/${shotId}/task/new`);
  };

  /**
   *
   * @param event
   */
  onPressCollapseSidebar = (event) => {
    event.preventDefault();
    this.props.collapseSidebar(
      "ShotPage",
      !this.props.app.shotPageSidebarCollapsed
    );
  };

  /**
   *
   * @returns {any[] | (T | {name: string})[]}
   */
  get formattedTaskCollection() {
    const shotModel = this.props.shot.model || {};
    const pureTasks = shotModel.tasks || [];
    return pureTasks.map((task) => ({
      ...task,
      name: task.task_type.name,
    }));
  }

  /**
   *
   * @param tasksFilter
   */
  onTasksTableFilter = (tasksFilter) => {
    this.setState({
      tasksFilter,
      tasksFilterIsFiltersApplied: !!tasksFilter.length,
    });
    const collection = this.formattedTaskCollection;
    let arResult = collection
      ? collection.filter((item) => {
          return filterComparisonAND(item, tasksFilter);
        })
      : collection;
    this.setState({ filteredTasksCollection: arResult });
  };

  /**
   *
   */
  onTasksTableClearFilter = () => {
    this.setState({
      filteredTasksCollection: null,
      tasksFilter: [],
      tasksFilterIsFiltersApplied: false,
    });
  };

  /**
   *
   */
  onVersionsTableClearFilter = () => {
    this.setState({
      filteredVersionsCollection: null,
      versionsFilter: [],
      versionsFilterIsFiltersApplied: false,
    });
  };

  /**
   *
   * @param versionsFilter
   */
  onVersionsTableFilter = (versionsFilter = []) => {
    this.setState({
      versionsFilter,
      versionsFilterIsFiltersApplied: !!versionsFilter.length,
    });
    const collection = this.props.shot.model.versions;
    let arResult = collection
      ? collection.filter((item) => {
          return filterComparisonAND(item, versionsFilter);
        })
      : collection;
    this.setState({ filteredVersionsCollection: arResult });
  };

  /**
   *
   * @param task
   */
  onEditTask = (task) => {
    this.props.clearErrors();
    const { id, shotId } = this.props.match.params;
    history.push(`/project/${id}/shot/${shotId}/task/${task.id}`);
  };

  /**
   *
   * @param tasks
   */
  onAssignYourselfTasks = async (tasks) => {
    this.props.clearErrors();
    this.purgeTasksTableErrors();

    this.setState({ bulkUpdate: true });

    await updateTasksAssignees(
      Array.isArray(tasks) ? tasks : [tasks],
      this.props.user.model,
      this.props.user.model,
      async (ids) => {
        if (ids) {
          await this.props.changeAssignee(ids, this.props.user.model.id);
        }
        this.setState({ bulkUpdate: false });
      }
    );
  };

  /**
   *
   * @param task
   */
  onUnassignTask = async (task) => {
    this.props.clearErrors();
    this.purgeTasksTableErrors();

    this.setState({ bulkUpdate: true });
    await updateTasksAssignees(
      [task],
      null,
      this.props.user.model,
      async (ids) => {
        if (ids) {
          await this.props.changeAssignee(ids, null);
        }
        this.setState({ bulkUpdate: false });
      }
    );
  };

  /**
   *
   */
  purgeTasksTableErrors = () => {
    this.setState({ assignError: [] });
  };

  /**
   *
   * @param data
   * @param actions
   */
  onBulkUpdatePress = async (data, actions) => {
    const statusAction = actions.find((x) => x.fieldName === "status");
    const status =
      statusAction && statusAction.value ? statusAction.value : null;

    const assigneeAction = actions.find((x) => x.fieldName === "assignee");
    const assignee =
      assigneeAction && assigneeAction.value ? assigneeAction.value : null;
    this.setState({ bulkUpdate: true });

    if (status) {
      for (const task of data.selectedRows) {
        this.setState({ bulkUpdate: true }, window.updateJQuery);
        await this.props.editTask(task.id, { status });
      }
    }

    if (assignee) {
      this.setState({ bulkUpdate: true }, window.updateJQuery);

      await updateTasksAssignees(
        data.selectedRows,
        assignee.id !== "unassigned" ? assignee : null,
        this.props.user.model,
        async (ids) => {
          if (ids) {
            await this.props.changeAssignee(
              ids,
              assignee.id !== "unassigned" ? assignee.id : null
            );
          }
          this.setState({ bulkUpdate: false });
          this.purgeTasksTableErrors();
        }
      );
    }
  };

  /**
   *
   * @param lastActivityId
   */
  onFetchPreviousActivity = (lastActivityId) => {
    this.props.getPreviousActivities(lastActivityId, {
      activity_type: "shot",
      id: this.props.match.params.shotId,
    });
  };

  /**
   *
   */
  onNextShotClick = () => {
    const project = this.props.project;
    const shots = project.shots;
    const currentShotIndex = shots.findIndex(
      (item) => item.id === this.props.shot.model.id
    );

    if (currentShotIndex > -1) {
      const url =
        currentShotIndex === shots.length - 1
          ? `/project/${project.model.id}/shot/${shots[0].id}`
          : `/project/${project.model.id}/shot/${
              shots[currentShotIndex + 1].id
            }`;

      history.push(url);
    }
  };

  /**
   *
   */
  onPreviousShotClick = () => {
    const project = this.props.project;
    const shots = project.shots;
    const currentShotIndex = shots.findIndex(
      (item) => item.id === this.props.shot.model.id
    );

    if (currentShotIndex > -1) {
      const url =
        currentShotIndex === 0
          ? `/project/${project.model.id}/shot/${shots[shots.length - 1].id}`
          : `/project/${project.model.id}/shot/${
              shots[currentShotIndex - 1].id
            }`;

      history.push(url);
    }
  };

  /**
   *
   */
  onShowBidRevisionsFullList = () => {
    this.setState({ showBidRevisionsFullList: true }, () => {
      const rect = document
        .querySelector(".js-bid-revisions-table")
        .getBoundingClientRect();
      const scrollToY =
        rect.y + rect.height + window.pageYOffset - window.innerHeight + 100;
      window.scrollTo({
        left: 0,
        top: scrollToY,
      });
    });
  };

  /**
   *
   * @returns {XML}
   */
  render() {
    const shot = this.props.shot;
    const shotModel = shot.model || {};

    const pureTasks = shotModel.tasks || [];
    const filteredTasks = this.state.filteredTasksCollection;
    const tasks = filteredTasks || this.formattedTaskCollection;

    const pureVersions = shotModel.versions || [];
    const filteredVersions = this.state.filteredVersionsCollection;
    const lastVersionAr = pureVersions.length
      ? [pureVersions[pureVersions.length - 1]]
      : [];
    const versions = this.state.showBidRevisionsFullList
      ? filteredVersions || pureVersions
      : lastVersionAr;

    const project = this.props.project;

    const activities = this.props.activities;

    const isSidebarCollapsed = this.props.app.shotPageSidebarCollapsed;

    const isActivitiesLoading = activities.connecting;

    const isRefreshing =
      shot.fetch &&
      shotModel &&
      shotModel.id === this.props.match.params.shotId;

    const isProjectRefreshing =
      project.fetch &&
      project.model &&
      project.model.id === this.props.match.params.id;

    const loadingIndicator =
      shot.model === null ||
      (project.fetch && !isProjectRefreshing) ||
      this.props.tasks.fetch ||
      (shot.model.id !== this.props.match.params.shotId &&
        shot.fetch &&
        !isRefreshing) ? (
        <LoadingIndicator isModal />
      ) : null;

    const newTaskButton = (
      <a
        href="!#"
        className="btn btn-link btn-float text-white"
        onClick={this.onPressAddTask}
      >
        <i className="mi-subscriptions" />
        <span className="minw-110px">Add new task</span>
      </a>
    );

    const shotNotes =
      shotModel && shotModel.id ? (
        <ShotNotes
          shotData={shotModel}
          notesClassNames={{
            className: "border-primary",
            headerClassName: "bg-primary",
          }}
          contentMaxHeight={500}
        />
      ) : (
        <LoadingIndicator />
      );

    const dataTableVersions = versions ? (
      <div className="js-bid-revisions-table">
        <CustomizableDataTable
          collection={versions}
          columns={this.versionsDataTableConfig.columns}
          title="Bid Revisions"
          showFilters={this.state.showBidRevisionsFullList}
          filters={this.versionsDataTableConfig.filters}
          filterSelectorHtmlClass="js-versions-filter"
          onFilter={this.onVersionsTableFilter}
          onClearAll={this.onVersionsTableClearFilter}
          isFiltersApplied={this.state.versionsFilterIsFiltersApplied}
          searchId="versions"
          id="versions"
          filtersClassName="bg-dark"
        />
      </div>
    ) : null;

    const showAllVersionsButton =
      !this.state.showBidRevisionsFullList &&
      !(pureVersions && !pureVersions.length) ? (
        <button
          className="btn btn-primary mb-3"
          onClick={this.onShowBidRevisionsFullList}
        >
          Show all Bid Revisions
        </button>
      ) : null;

    const staticAlertVersions =
      pureVersions && !pureVersions.length ? (
        <StaticAlert
          header={staticVersionsAlertHeader}
          body={staticVersionsAlertBody}
          className="bg-dark"
        />
      ) : null;

    const rightTopMenu = (
      <HasRights
        allowedRoles={["admin", "producer"]}
        user={this.props.user.model}
      >
        {newTaskButton}
        {/*{newVersionButton}*/}
      </HasRights>
    );

    const taskAssignees = tasks
      .filter((task) => task.assignee !== null)
      .map((task) => task.assignee);
    const taskAssigneesUnique = taskAssignees.filter((assignee, index) => {
      return taskAssignees.findIndex((i) => i.id === assignee.id) >= index;
    });

    const mostRecentBidNote = pureVersions.length ? (
      <div className="white-space-pre">
        {pureVersions[pureVersions.length - 1].notes || "--"}
      </div>
    ) : null;

    const recentActivity = (
      <RecentActivity
        id="shot-page-activities"
        onConnect={this.connectActivitiesSocket}
        onFetchPrevious={this.onFetchPreviousActivity}
        className="bg-dark border-primary"
      />
    );

    const assignError = this.state.assignError.length
      ? this.state.assignError
      : this.props.tasks.assignError;

    const collapseSidebarIcon = isSidebarCollapsed ? (
      <i className="icon-enlarge6" />
    ) : (
      <i className="icon-shrink6" />
    );

    const activitiesLoadingIndicator = isActivitiesLoading ? (
      <i className="icon-spinner2 spinner" />
    ) : null;

    const collapseSidebarBtn = (
      <button
        type="button"
        className="btn btn-link btn-link_black ml-3"
        onClick={this.onPressCollapseSidebar}
        disabled={isActivitiesLoading}
      >
        {activitiesLoadingIndicator || collapseSidebarIcon}
      </button>
    );

    const estimatedHours = (
      <div className="ml-3">
        Est. Hour:{" "}
        <b>{shotModel.current_version && shotModel.current_version.hours}</b>
      </div>
    );

    const rightLine = (
      <div className="d-flex align-items-center">
        {estimatedHours}
        {collapseSidebarBtn}
      </div>
    );

    const leftColClassName = classNames(
      { "col-xl-9": !isSidebarCollapsed },
      { "col-xl-12": isSidebarCollapsed }
    );

    const pageContent = (
      <div className="row relative h-100 flex-column-reverse flex-xl-column">
        {!isSidebarCollapsed && (
          <div className="col-xl-3 limit-height-with-sibling-right-xl d-flex flex-column z-index-5">
            <HasRights
              allowedRoles={["admin", "producer"]}
              user={this.props.user.model}
            >
              <ArtistsList
                artists={taskAssigneesUnique}
                className="bg-dark border-primary"
              />
            </HasRights>

            {shotNotes}

            {recentActivity}
          </div>
        )}
        <div className="col">
          <div className="row">
            <div className={leftColClassName}>
              <ApiError error={shot.editError || shot.shotUpdateCreateError} />

              <div className="mb-5">
                <TasksCustomizableDataTable
                  pureTasks={pureTasks}
                  filteredTasks={tasks}
                  onFilter={this.onTasksTableFilter}
                  onClearAll={this.onTasksTableClearFilter}
                  isFiltersApplied={this.state.tasksFilterIsFiltersApplied}
                  onEditTask={this.onEditTask}
                  onAssignYourselfTasks={this.onAssignYourselfTasks}
                  onUnassignTask={this.onUnassignTask}
                  onBulkUpdatePress={this.onBulkUpdatePress}
                  bulkActionsLoading={this.state.bulkUpdate}
                  assignError={assignError}
                />
              </div>

              <HasRights
                allowedRoles={["admin", "producer"]}
                user={this.props.user.model}
              >
                {versions === null && <LoadingIndicator />}
                {versions !== null && dataTableVersions}
                {showAllVersionsButton}
                {staticAlertVersions}
              </HasRights>
              <HasRights allowedRoles={["artist"]} user={this.props.user.model}>
                <h5>Bid Notes</h5>
                <div className="mb-2">{mostRecentBidNote}</div>
              </HasRights>
            </div>
          </div>
        </div>
      </div>
    );

    const headerTitle = <PageHeader />;

    const shotsNavigationArrows = (
      <div className="mr-3">
        <i
          onClick={this.onPreviousShotClick}
          className="icon-circle-left2 mr-1 fs-23 mb-r pointer vmiddle hover-opacity-low"
          title="Previous Shot"
        />
        <i
          onClick={this.onNextShotClick}
          className="icon-circle-right2 ml-1 fs-23 mb-r pointer vmiddle hover-opacity-low"
          title="Next Shot"
        />
      </div>
    );

    const breadcrumbs = (
      <div className="breadcrumbs-full">
        <div className="d-flex align-items-center flex-1">
          {shotsNavigationArrows}
        </div>
      </div>
    );

    const backArrowPath = project.model
      ? `/project/${project.model.id}`
      : "/projects/1";

    return (
      <PageTemplate
        header={headerTitle}
        rightTopMenu={rightTopMenu}
        rightLine={rightLine}
        breadcrumbs={breadcrumbs}
        backArrowPath={backArrowPath}
        pageTitleClassName="flex-1 font-weight-semibold-child-full-width"
        pageWrapperClassName="bg-dark page-header-dark-theme"
        pageFooterClassName="navbar-dark"
        breadcrumbClassName="breadcrumb-line-dark"
        backButtonArrowIcon={"mi-keyboard-return icon-rotate-90deg"}
      >
        <SeoBlock
          title={
            shotModel &&
            shotModel.shot_number &&
            `Shot ${shotModel.shot_number}`
          }
        />
        {loadingIndicator}
        {pageContent}
      </PageTemplate>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  shot: state.shot,
  tasks: state.tasks,
  project: state.project,
  statuses: state.statuses,
  activities: state.activities,
  app: state.app,
});

const mapDispatchToProps = (dispatch) => ({
  fetchShot: async (id) => await dispatch(shotActions.fetchShot(id)),
  editShot: async (id, data) => await dispatch(shotActions.editShot(id, data)),
  createShotUpdate: async (shotId, data) =>
    await dispatch(shotActions.createShotUpdate(shotId, data)),
  deleteShotUpdate: async (id) =>
    await dispatch(shotActions.deleteShotUpdate(id)),
  fetchProject: async (id) =>
    await dispatch(projectActions.fetchProject(id, null, 1)),
  changeAssignee: async (tasks, assigneeId) =>
    await dispatch(tasksActions.changeAssignee(tasks, assigneeId)),
  fetchTasks: async (shotId) => await dispatch(tasksActions.fetchTasks(shotId)),
  editTask: async (taskId, data) =>
    await dispatch(tasksActions.editTask(taskId, data)),
  connectActivitiesSocket: (data) =>
    dispatch(activitiesActions.connectActivitiesSocket(data)),
  disconnectActivitiesSocket: () =>
    dispatch(activitiesActions.disconnectActivitiesSocket()),
  getPreviousActivities: (startFromActivityId, data) =>
    dispatch(
      activitiesActions.getPreviousActivities(startFromActivityId, data)
    ),
  collapseSidebar: (type, collapse) =>
    dispatch(appActions.collapseSidebar(type, collapse)),
  clearErrors: () => dispatch(appActions.clearErrors()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ShotPage));
