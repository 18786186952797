/**
 * Created by leper on 18/05/2022
 */

import React, { useEffect } from "react";
import PropTypes from "prop-types";

const BarDiagram = (props) => {
  const { workloadData } = props;

  useEffect(() => {
    if (workloadData.length) {
      const $ = window.jQuery;
      $(".wb-chart--progress.tooltip-container").on("mousemove", (e) => {
        const tooltip = e.currentTarget.querySelector(
          ":scope > .wb-chart--progress-tooltip"
        );
        tooltip.style.top = `${e.originalEvent.offsetY}px`;
        tooltip.style.left = `${e.originalEvent.offsetX}px`;

        $(tooltip).tooltip("show");
      });

      $(".wb-chart--progress.tooltip-container").on("mouseleave", (e) => {
        const tooltip = e.currentTarget.querySelector(
          ":scope > .wb-chart--progress-tooltip"
        );
        $(tooltip).tooltip("hide");
      });
    }
  }, [workloadData.length]);

  const hoursSummary = workloadData.map((i) => i.total_hours);
  const highestNumberOfHours = Math.max(...hoursSummary);

  const statusesAssoc = {};

  props.statuses.map((i) => {
    statusesAssoc[i.value] = i;
  });

  const bars = workloadData.length
    ? workloadData.map((record, idx) => {
        if (!record.number_of_shots) {
          return null;
        }

        const unit =
          100 /
          (props.valuesAlignedIndividually
            ? record.total_hours
            : highestNumberOfHours);

        let statusBarOffset = 0;

        const statusesSummary = [];
        const projectProgress = [];

        for (const statusCode in record.shots) {
          const entries = record.shots[statusCode];
          const projectsStatusProgress = [];

          // status width takes the width of all project hours in this status
          const statusHours = Object.values(entries).reduce((acc, i) => {
            return acc + i.hours;
          }, 0);

          const statusWidth = statusHours * unit;

          // do not show status if no hours
          if (!statusWidth) {
            continue;
          }

          // current status object
          const status = statusesAssoc[statusCode];

          if (!status) {
            console.warn("This status does not exist", statusCode);
          }
          const statusLabel = status.label;

          // creates statuses labels shown above the bar
          statusesSummary.push(
            <div
              key={`wbas-${statusCode}`}
              className={"wb-chart--status"}
              style={{ width: `${statusWidth}%`, color: `#${status.color}` }}
            >
              <span>{statusLabel}</span>
            </div>
          );

          let progressBarOffset = 0;
          for (const entryId in entries) {
            const entry = entries[entryId];
            const entryDisplayValue = entry[props.individualFieldName];

            // do not show project in bar if no hours
            if (!entry.hours) {
              continue;
            }

            const projectProgress = (entry.hours / statusHours) * 100;

            projectsStatusProgress.push(
              <div
                key={`wbap-${statusCode}-${entryId}`}
                className={"wb-chart--progress tooltip-container"}
                style={{
                  width: `${projectProgress}%`,
                  left: `${progressBarOffset}%`,
                }}
              >
                <i
                  className={"wb-chart--progress-tooltip"}
                  data-popup="tooltip"
                  data-placement="top"
                  data-animation={false}
                  data-trigger={"manual"}
                  data-original-title={`<p style="text-align: left">${entryDisplayValue}<br/>${
                    status.label
                  }<br/>${Math.round(entry.hours)} hrs
                    <br/>${Math.round(entry.shots_count)} shot(s)</p>`}
                  data-html={true}
                />
                <div className={"wb-chart--progress-bar"}>
                  {entryDisplayValue}
                </div>
                <div className={"wb-chart--progress-hours"}>
                  {Math.round(entry.hours)} hrs
                </div>
              </div>
            );

            progressBarOffset += projectProgress;
          }

          // groups all entries within this status
          projectProgress.push(
            <div
              key={`wbapg-${statusCode}`}
              className={"wb-chart--progress-status-bar"}
              style={{
                backgroundColor: `#${status.color}`,
                width: `${statusWidth}%`,
                left: `${statusBarOffset}%`,
              }}
            >
              {projectsStatusProgress}
            </div>
          );

          statusBarOffset += statusWidth;
        }

        const recordItem = record[props.itemName];

        return (
          <div key={`wda-${idx}-${recordItem.id}`} className={"wb-container"}>
            <div className={"wb-header"}>
              <h4>{recordItem.name}</h4>
              <div>
                <span>
                  <span className={"wb-header--stats-summary"}>
                    {record.number_of_shots} shots{" "}
                  </span>
                  ({props.totalShotsLabel})
                </span>
                <span>
                  <span className={"wb-header--stats-summary"}>
                    {Math.round(record.total_hours)} hours{" "}
                  </span>
                  ({props.totalHoursLabel})
                </span>
              </div>
            </div>
            <div className={"wb-chart"}>
              {statusesSummary}
              <div className={"wb-chart--bar"}>{projectProgress}</div>
            </div>
          </div>
        );
      })
    : null;

  return bars;
};

BarDiagram.defaultProps = {};

BarDiagram.propTypes = {
  valuesAlignedIndividually: PropTypes.bool,
  workloadData: PropTypes.array,
  itemName: PropTypes.string,
  individualFieldName: PropTypes.string,
  totalHoursLabel: PropTypes.string,
  totalShotsLabel: PropTypes.string,
};

export default BarDiagram;
