/**
 * Created by piotr.pozniak@thebeaverhead.com on 24/01/2022.
 */
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as appActions from "../actions/app";

export function useAppStore() {

  const dispatch = useDispatch();
  const _app = useSelector((store) => store.app);

  const clearErrors = useCallback(
    async () =>
      await dispatch(appActions.clearErrors()),
    [dispatch]
  );

  const collapseSideNav = useCallback(
    async (collapse) =>
      await dispatch(appActions.collapseSideNav(collapse)),
    [dispatch]
  );

  const collapseFilter = useCallback(
    async (type, collapse) =>
      await dispatch(appActions.collapseFilter(type, collapse)),
    [dispatch]
  );


  const collapseSidebar = useCallback(
    async (type, collapse) =>
      await dispatch(appActions.collapseSidebar(type, collapse)),
    [dispatch]
  );



  return {
    app: _app,
    clearErrors,
    collapseSideNav,
    collapseFilter,
    collapseSidebar
  };
}
