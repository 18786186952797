import PropTypes from "prop-types";

const TotalShotsCount = (props) => {
  const { shots, useShotsAsHoursBid, isLoading } = props;

  /**
   *
   * @returns {number}
   */
  const countShotsAsHours = () => {
    let count = shots
      .map((item) => item.versions[item.versions.length - 1].hours)
      .reduce((prevVal, currVal) => prevVal + Number(currVal), 0);
    return Math.ceil(count);
  };

  if (isLoading) {
    return "--";
  }

  return useShotsAsHoursBid ? countShotsAsHours() : shots.length;
};

TotalShotsCount.propTypes = {
  shots: PropTypes.array,
  useShotsAsHoursBid: PropTypes.bool,
  isLoading: PropTypes.bool,
};

TotalShotsCount.defaultProps = {
  shots: [],
};

export default TotalShotsCount;
