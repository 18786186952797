export const initialState = {
  fetch: false,
  fetchSuccess: false,
  fetchError: null,

  model: null,
};

export default function reducer(
  state = {
    ...initialState,
  },
  action
) {
  switch (action.type) {
    case "FETCH_CLIENT_DASHBOARD":
      return {
        ...state,
        fetch: true,
        fetchSuccess: false,
        fetchError: null,
      };

    case "FETCH_CLIENT_DASHBOARD_FULFILLED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: true,

        model: action.data,
      };

    case "FETCH_CLIENT_DASHBOARD_REJECTED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: false,
        fetchError: action.data,
      };

    case "CLEAR_ERRORS":
      return {
        ...initialState,
        model: state.model,
      };

    case "LOGOUT":
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
