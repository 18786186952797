import AxiosConfig from "../AxiosConfig";
import axios from "axios";

/**
 *
 * @param id
 * @returns {function(...[*]=)}
 */
export const fetchBidVersion = (id) => {
  return async (dispatch) => {
    dispatch({ type: "BID_VERSION_FETCH" });

    const url =
      AxiosConfig.getEndpointAddress() + "/api/bid_versions/" + id + ".json";

    await axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "BID_VERSION_FETCH_FULFILLED",
          data: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "BID_VERSION_FETCH_REJECTED",
          data: error,
        });
      });
  };
};

/**
 *
 * @param slug
 * @returns {Function}
 */
export const fetchBidFullInfoByBidSlug = (slug) => {
  return async (dispatch) => {
    dispatch({ type: "BID_BY_BID_SLUG_FETCH" });

    const url =
      AxiosConfig.getEndpointAddress() +
      "/api/bids/get_by_slug/" +
      slug +
      ".json?approved_shots=0";

    await axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "BID_BY_BID_SLUG_FETCH_FULFILLED",
          data: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "BID_BY_BID_SLUG_FETCH_REJECTED",
          data: error,
        });
      });
  };
};

/**
 *
 * @param data
 * @param id
 * @returns {function(...[*]=)}
 */
export const bidVersionUpdate = (data, id = null) => {
  return async (dispatch) => {
    dispatch({ type: "BID_VERSION_UPDATE" });

    const url =
      id === null
        ? AxiosConfig.getEndpointAddress() + "/api/bid_versions.json"
        : AxiosConfig.getEndpointAddress() +
          "/api/bid_versions/" +
          id +
          ".json";

    const httpMethod = id === null ? axios.post : axios.put;

    await httpMethod(url, data, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "BID_VERSION_UPDATE_FULFILLED",
          data: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "BID_VERSION_UPDATE_REJECTED",
          data: error,
        });
      });
  };
};

/**
 *
 * @param id
 * @returns {Function}
 */
export const bidVersionApprove = (id) => {
  return async (dispatch) => {
    dispatch({ type: "BID_VERSION_APPROVE" });

    const url =
      AxiosConfig.getEndpointAddress() + "/api/bids/approve/" + id + ".json";

    await axios
      .put(url, {}, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "BID_VERSION_APPROVE_FULFILLED",
          data: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "BID_VERSION_APPROVE_REJECTED",
          data: error,
        });
      });
  };
};

/**
 *
 * @param bidVersionId
 * @param page
 * @param limit
 * @returns {function(...[*]=)}
 */
export const fetchShotsByBidVersion = (bidVersionId, page = 1, limit = 200) => {

  return async (dispatch) => {
    dispatch({ type: "SHOTS_BY_BID_VERSION_FETCH" });

    const url =
      AxiosConfig.getEndpointAddress() +
      "/api/shots.json?" +
      AxiosConfig.objectToURLQuery({
        bid_version_id: bidVersionId,
        page,
        limit,
      });

    await axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "SHOTS_BY_BID_VERSION_FETCH_FULFILLED",
          data: {
            responseData: response.data,
            bidVersionId,
          },
        });
      })
      .catch(function (error) {
        dispatch({
          type: "SHOTS_BY_BID_VERSION_FETCH_REJECTED",
          data: error,
        });
      });
  };
};

/**
 *
 * @param bid
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export const setBidVersion = (bid) => {
  return (dispatch) => {
    dispatch({ type: "BID_VERSION_SET", data: bid });
  };
};
