import axios from 'axios';
import AxiosConfig from '../AxiosConfig';

/**
 *
 * @param shot_id
 * @param data
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export const createShotVersion = (shot_id, data) => {

  return async dispatch => {

    dispatch({type: "SHOT_VERSION_CREATE"});

    const url = AxiosConfig.getEndpointAddress() + "/api/shot_versions.json";
    const createShotVersionData = {...data, shot_id};

    await axios.post(
      url,
      createShotVersionData,
      AxiosConfig.getAuthConfig(),
    )
      .then((response) => {

        dispatch({
          type: "SHOT_VERSION_CREATE_FULFILLED",
          data: response.data,
        });

      })
      .catch(function (error) {

        dispatch({
          type: "SHOT_VERSION_CREATE_REJECTED",
          data: error,
        });

      });
  }
};

/**
 *
 * @param shotVersionId
 * @param data
 * @returns {function(*): Promise<AxiosResponse<any> | never>}
 */
export const editShotVersion = (shotVersionId, data) => {

  return dispatch => {

    dispatch({type: "SHOT_VERSION_EDIT"});

    const url = AxiosConfig.getEndpointAddress() + "/api/shot_versions/" + shotVersionId + ".json";

    return axios.put(
      url,
      data,
      AxiosConfig.getAuthConfig(),
    )
      .then((response) => {

        dispatch({
          type: "SHOT_VERSION_EDIT_FULFILLED",
          data: response.data,
        });

      })
      .catch(function (error) {

        dispatch({
          type: "SHOT_VERSION_EDIT_REJECTED",
          data: error,
        });

      });
  };
};