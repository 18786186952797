import React, { useMemo } from "react";
import PropTypes from "prop-types";

const InvoicesIssuedList = (props) => {
  const { invoice, filler } = props;

  const issuedInvoices = useMemo(
    () =>
      invoice.issued_invoices &&
      invoice.issued_invoices
        .map((item) => +item.create_order)
        .sort((a, b) => a - b)
        .join(", "),
    [invoice.issued_invoices]
  );

  if (!invoice || !invoice.issued_invoices) {
    return filler || "";
  }

  return (
    <div className="invoices-issued-list">
      {issuedInvoices ? issuedInvoices : filler}
    </div>
  );
};

InvoicesIssuedList.propTypes = {
  invoice: PropTypes.object,
  filler: PropTypes.string,
};

export default InvoicesIssuedList;
