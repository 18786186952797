/**
 * Created by piotr.pozniak@thebeaverhead.com on 20/07/2017.
 */


export const UserRole = {
  admin: 'admin',
  artist: 'artist',
  producer: 'producer',
  accountant: 'accountant',
  client: 'client',
}
