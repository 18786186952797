import React, {Component} from 'react';
import PropTypes from 'prop-types';

import PageTemplate from './PageTemplate';

import classNames from 'classnames';

import Select from "../components/Select";
import LoadingIndicator from "../components/LoadingIndicator";
import UserSelect from "../components/Selects/UserSelect";
import ReactSelect from 'react-select';
import CustomerSelect from "../components/Selects/CustomerSelect";
import ProjectSelect from "../components/Selects/ProjectSelect";
import SeoBlock from "../components/SeoBlock";

export default class ReportsPageTemplate extends Component {

  constructor(props) {
    super(props);

    this.state = {};
  }

  /**
   *
   * @param prevProps
   */
  componentDidUpdate(prevProps) {
    if (this.props.diagramData && (typeof prevProps.diagramData !== typeof this.props.diagramData)) {
      this.setDiagram();
    }
  };

  /**
   *
   */
  onFilter = () => {
    let filter = [];
    document.querySelectorAll('.js-report-page-filters select').forEach(item => {
      if (item.value) {
        filter.push({fieldName: item.name, value: item.value});
      }
    });
    document.querySelectorAll('.js-report-page-filters input').forEach(item => {
      if (item.value) {
        filter.push({fieldName: item.name, value: item.value});
      }
    });
    if (typeof this.props.onFilter === 'function') {
      this.props.onFilter(filter);
    }
  };


  /**
   *
   */
  onClearAll = () => {
    setTimeout(() => {
      window.updateJQuery(() => {
        if (typeof this.props.onClearAll === 'function') {
          this.props.onClearAll();
        }
      });
    }, 100);
  };

  /**
   *
   */
  setDiagram = () => {
    window.updateJQuery(null, this.props.diagramData);
  };


  /**
   *
   * @returns {XML}
   */
  render() {
    const isLoading = this.props.isLoading;
    const loadingIndicator = isLoading ? <LoadingIndicator isModal/> : null;

    const filters =
      (this.props.filters ? (
        <div className="card card-body mt-3 js-report-page-filters">
          <form className="clearfix">
            <div className="row">
              {this.props.filters.map(item => {
                const filterWrapperClassName = classNames(
                  {'col-sm-4': !item.wrapperClassName},
                  "mb-3",
                ) + ' ' + (item.wrapperClassName || '');
                return (
                  <div className={filterWrapperClassName} key={item.fieldName}>
                    {item.type === 'dateRange' ? (
                      <div className={"row"}>
                        <div className="col-sm-6">
                          <label className="form-label">{item.placeholderFrom}</label>
                          <input
                            type="date"
                            className="form-control"
                            style={styles.input}
                            placeholder={item.placeholderFrom}
                            defaultValue={item.defaultValueFrom}
                            name={item.fieldNameFrom}
                            autoComplete="off"
                          />
                        </div>
                        <div className="col-sm-6">
                          <label className="form-label">{item.placeholderTo}</label>
                          <input
                            type="date"
                            className="form-control"
                            style={styles.input}
                            placeholder={item.placeholderTo}
                            defaultValue={item.defaultValueTo}
                            name={item.fieldNameTo}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                    ) : null}
                    {item.type === 'select' && item.reactSelectProps && !item.reactSelectProps.isMulti ? (
                      <>
                        <label className="form-label">{item.placeholder}</label>
                        <Select
                          data={[{value: '', label: '-'}, ...item.data]}
                          initClassName="js-select2"
                          name={item.fieldName}
                          {...item.reactSelectProps}
                        />
                      </>
                    ) : null}
                    {item.type === 'select' && item.reactSelectProps && item.reactSelectProps.isMulti ? (
                      <>
                        <label className="form-label">
                          {item.placeholder}
                        </label>
                        <ReactSelect
                          isMulti
                          value={item.reactSelectProps.value}
                          onChange={item.onUpdate}
                          name={item.fieldName}
                          options={item.data}
                        />
                      </>
                    ) : null}
                    {item.type === 'text' ? (
                      <>
                        <label className="form-label">{item.placeholder}</label>
                        <input
                          type="text"
                          className="form-control"
                          style={styles.input}
                          placeholder={item.placeholder}
                          defaultValue={item.defaultValue}
                          name={item.fieldName}
                          autoComplete="off"
                        />
                      </>
                    ) : null}
                    {item.type === 'userSelect' ? (
                      <>
                        <label className="form-label">{item.placeholder}</label>
                        <UserSelect
                          onUpdate={item.onUpdate}
                          reactSelectProps={item.reactSelectProps}
                        />
                      </>
                    ) : null}
                    {item.type === 'customerSelect' ? (
                      <>
                        <label className="form-label">{item.placeholder}</label>
                        <CustomerSelect
                          onUpdate={item.onUpdate}
                          reactSelectProps={item.reactSelectProps}
                        />
                      </>
                    ) : null}
                    {item.type === 'projectSelect' ? (
                      <>
                        <label className="form-label">{item.placeholder}</label>
                        <ProjectSelect
                          onUpdate={item.onUpdate}
                          reactSelectProps={item.reactSelectProps}
                        />
                      </>
                    ) : null}
                    {item.type === "radio" ? (
                      <>
                        <label className="form-label">{item.placeholder}</label>
                        <div className="form-group">
                          {item.radio.map(radio => {
                            return (
                              <div key={radio.name} className="form-check form-check-inline">
                                <label className="form-check-label">
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    name={radio.name}
                                    checked={radio.checked}
                                    onChange={item.onChange}
                                  />
                                  {radio.label}
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      </>
                    ) : null}
                  </div>
                );
              })}
            </div>
            <button
              type="button"
              className="btn btn-primary float-right"
              onClick={this.onFilter}>
              Filter
            </button>
            <button type="reset" className="btn btn-warning mr-2 float-right" onClick={this.onClearAll}>
              Clear all
            </button>
          </form>
        </div>
      ) : null);

    const diagramStyle = !this.props.diagramData ? {display: "none"} : {};

    return (
      <PageTemplate
        header={this.props.header || 'Report'}
      >
        <div style={{display: 'flex', flexDirection: 'column-reverse'}}>
          <div className="reports-content-wrapper">
            {this.props.children}
          </div>
          <div className={"card card-body"} style={diagramStyle}>
            <div id="jsReportsPageDiagram"/>
          </div>
          {filters}
        </div>
        {loadingIndicator}
      </PageTemplate>
    );
  }
}

ReportsPageTemplate.propTypes = {
  header: PropTypes.oneOfType([
    PropTypes.node, PropTypes.string
  ]),
  filters: PropTypes.array,
  onFilter: PropTypes.func,
  onClearAll: PropTypes.func,
  diagramData: PropTypes.object,
  isLoading: PropTypes.bool,
};

const styles = {
  input: {height: 38}
};

export const staticAlertHeader = "There is no data for selected filters";
export const staticAlertBody = (
  <>
    Change filters to pull the reports.
  </>
);

// import React, {Component} from 'react';
//
// import ReportsPageTemplate from './ReportsPageTemplate';
// import connect from "react-redux/es/connect/connect";
// import * as projectsActions from "../actions/projects";
// import CustomizableDataTable from '../components/CustomizableDataTable';
//
// //All data in this class is a mock just to show the way ReportsPageTemplate can be implemented
// class ReportPageMock extends Component {
//
//   constructor(props) {
//     super(props);
//
//     this.state = {
//       diagramData: undefined,
//     };
//   }
//
//   /**
//    *
//    * @returns {Promise<void>}
//    */
//   async componentDidMount() {
//     //loading diagram data simulation
//     await this.props.fetchProjects();
//     this.setState({diagramData: this.getDiagramData()});
//   };
//
//   /**
//    *
//    * @param filter
//    */
//   onFilter = filter => {
//     console.log(filter);
//     //Then you can call actions which should use filter params
//   };
//
//   /**
//    *
//    */
//   onClearAll = () => {
//     console.log('onClearAll');
//   };
//
//   /**
//    *
//    */
//   getDiagramData = () => {
//     return {
//       //new diagram types can be added in public_html_dev/js/app.js, see App.initBarDiagram(settings ? settings.barDiagram : settings);
//       barDiagram: [
//         {
//           bindto: document.getElementById('jsReportsPageDiagram'),
//           size: {height: 300},
//           data: {
//             columns: [[1, 2, 3], [4, 5, 6]],
//             type: 'bar'
//           },
//           color: {
//             pattern: ['#2196F3', '#FF9800', '#4CAF50']
//           },
//           bar: {
//             width: {
//               ratio: 0.5
//             }
//           },
//           grid: {
//             y: {
//               show: true
//             }
//           },
//           axis: {
//             x: {
//               type: 'category',
//               categories: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
//             },
//           },
//         }
//       ],
//     };
//   };
//
//   /**
//    *
//    * @returns {XML}
//    */
//   render() {
//     const isLoading = this.props.projects.fetch;
//
//     const content = (
//       //Can be used any another suitable table
//       // from theme (e g assets/admin_theme/Template/layout_1/LTR/default/full/table_basic.html).
//       // Not exactly component CustomizableDataTable, this is just for example.
//       <CustomizableDataTable
//         collection={this.props.projects.collection}
//         columns={[
//           {
//             name: 'Client name',
//             selector: 'client.name',
//             sortable: true,
//           },
//           {
//             name: 'Project name',
//             selector: 'name',
//           },
//           {
//             name: 'Required hours',
//             selector: 'required_hours',
//             format: row => (row.required_hours || row.required_hours === 0) ? row.required_hours : '--',
//             center: true,
//           },
//           {
//             name: 'Current version',
//             selector: 'bid.create_order',
//             maxWidth: '100px',
//             center: true,
//           },
//         ]}
//       />
//     );
//
//     return (
//       <ReportsPageTemplate
//         header="Report mock"
//         filters={[
//           {
//             placeholderFrom: "From date",
//             placeholderTo: "To date",
//             fieldNameFrom: "fromDate",
//             fieldNameTo: "toDate",
//             fieldName: "dateRange",
//             type: 'dateRange',
//             wrapperClassName: 'col-sm-6',
//           },
//           {
//             placeholder: "Project name",
//             defaultValue: '',
//             fieldName: "projectName",
//             type: 'text',
//             wrapperClassName: 'col-sm-3',
//           },
//           {
//             data: this.props.statuses.collection.filter(i => i.type === 'Projects').statuses,
//             placeholder: "Choose status",
//             fieldName: "status",
//             type: 'select',
//             wrapperClassName: 'col-sm-3',
//           },
//         ]}
//         onFilter={this.onFilter}
//         onClearAll={this.onClearAll}
//         diagramData={this.state.diagramData}
//         isLoading={isLoading}
//       >
//         {content}
//       </ReportsPageTemplate>
//     );
//   }
// }
//
// const mapStateToProps = state => ({
//   projects: state.projects,
//   statuses: state.statuses
// });
//
// const mapDispatchToProps = dispatch => ({
//   fetchProjects: async (page, limit, status) => await dispatch(projectsActions.fetchProjects(page, limit, status)),
// });
//
// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(ReportPageMock);
