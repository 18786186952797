import React, { useEffect } from "react";
import PageTemplate from "./PageTemplate";
import UserAvatar from "../components/UserAvatar";
import LoadingIndicator from "../components/LoadingIndicator";
import * as bidsActions from "../actions/bids";
import * as invoicesReportsActions from "../actions/reportsInvoices";
import * as clientActions from "../actions/client";
import * as invoicesActions from "../actions/invoices";
import ClientAddressForm from "../components/ClientAddressForm";
import ClientInvoiceAddressForm from "../components/ClientInvoiceAddressForm";
import * as clientProjectsActions from "../actions/clientProjects";
import ProjectsTable from "./ClientPage/ProjectsTable";
import BidsTable from "./ClientPage/BidsTable";
import Invoices from "./ClientPage/Invoices";
import { useClientProjectsStore } from "../storeHooks/clientProjects";
import { useBidsStore } from "../storeHooks/bids";
import { useReportsInvoicesStore } from "../storeHooks/reportsInvoicesStore";
import { useClientStore } from "../storeHooks/client";
import { useProjectsStore } from "../storeHooks/projects";

const ClientPage = (props) => {
  const { projects } = useProjectsStore();
  const { bids } = useBidsStore();
  const { reportsInvoices } = useReportsInvoicesStore();
  const { client, fetchClient } = useClientStore();
  const { clientProjects } = useClientProjectsStore();

  useEffect(() => {
    fetchClient(props.match.params.id);
    /*  fetchInvoices(1, invoicesDataTableConfig.perPage, {
      clients: [props.match.params.id],
    });*/
  }, []);

  /**
   *
   * @returns {*}
   */

  const loadingIndicator = (bids.fetch ||
    projects.fetch ||
    projects.delete ||
    reportsInvoices.fetch ||
    client.fetch ||
    clientProjects.fetch) && <LoadingIndicator isModal />;

  const clientModel = client.model
    ? { ...client.model, role: "client" }
    : client.model;

  const clientName = clientModel ? clientModel.name : "";
  const clientEmail = clientModel ? clientModel.email : "";
  const clientPhone = clientModel ? clientModel.contact_phone : "";

  const basicInformationSection = (
    <div className={"row mb-3"}>
      <div className="col-md-4 col-lg-3">
        <div className="card">
          <div className="card-body text-center">
            <UserAvatar user={clientModel} className={"m-auto"} size={"md"} />
            <h6 className={"font-weight-semibold mb-0 mt-3"}>{clientName}</h6>
            <div>
              {clientEmail && <i className="icon-mail5 mr-1"></i>}
              {clientEmail}
            </div>
            <div>
              {clientPhone && <i className="icon-phone2 mr-1"></i>}
              {clientPhone}
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-8 col-lg-9">
        <div className="card w-100">
          <div className="card-body">
            <ul className="nav nav-tabs nav-tabs-highlight">
              <li className="nav-item">
                <a
                  href="#clientBasicInfo-tab1"
                  className="nav-link active"
                  data-toggle="tab"
                >
                  Address
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#clientBasicInfo-tab2"
                  className="nav-link"
                  data-toggle="tab"
                >
                  Invoice Address
                </a>
              </li>
            </ul>

            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id="clientBasicInfo-tab1"
              >
                <ClientAddressForm
                  disabled
                  showPlaceholder={false}
                  client={clientModel}
                />
              </div>

              <div className="tab-pane fade" id="clientBasicInfo-tab2">
                <ClientInvoiceAddressForm
                  disabled
                  showPlaceholder={false}
                  client={clientModel}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <PageTemplate header={clientModel ? clientModel.name : ""}>
      {loadingIndicator}
      {basicInformationSection}
      <ProjectsTable />
      <BidsTable />
      <Invoices />
    </PageTemplate>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  bids: state.bids,
  projects: state.projects,
  reportsInvoices: state.reportsInvoices,
  client: state.client,
  invoices: state.invoices,
  clientProjects: state.clientProjects,
  statuses: state.statuses,
});

const mapDispatchToProps = (dispatch) => ({
  fetchBids: (page, limit, filters) =>
    dispatch(bidsActions.fetchBids(page, limit, filters)),
  fetchInvoicesReports: (dateFrom, dateTo, status, clients) =>
    dispatch(
      invoicesReportsActions.fetchInvoicesReports(
        dateFrom,
        dateTo,
        status,
        clients
      )
    ),
  fetchClient: (id) => dispatch(clientActions.fetchClient(id)),
  fetchInvoices: (page, limit, filters) =>
    dispatch(invoicesActions.fetchInvoices(page, limit, filters)),
  fetchProjects: (page, limit, filters) =>
    dispatch(clientProjectsActions.fetchProjects(page, limit, filters)),
});

export default ClientPage;
