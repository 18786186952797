import React from "react";
import PropTypes from "prop-types";
import { ucFirst } from "../../../helpers/error";

const ImportantItemsHeader = ({ title }) => {
  return (
    <div className="card-header header-elements-sm-inline">
      <h6 className="card-title">{ucFirst(title)}</h6>
      <div className="header-elements">
        {/*<a href="!#" className="text-default daterange-ranges font-weight-semibold cursor-pointer dropdown-toggle">*/}
        {/*<i className="icon-calendar3 mr-2"/>*/}
        {/*<span/>*/}
        {/*</a>*/}
        <span className="daterange-ranges font-weight-semibold">
          <i className="icon-calendar3 mr-2" />
          <span />
        </span>
      </div>
    </div>
  );
};

ImportantItemsHeader.propTypes = {
  title: PropTypes.string,
};

export default ImportantItemsHeader;
