export default {
  /**
   *
   * @param data
   * @param csvFormat
   * @returns {boolean}
   */
  validateHeader: (data, csvFormat) => {
    let isValid = false;
    try {
      isValid =
        csvFormat.filter((item, index) => item.title === data[0][index].trim())
          .length === csvFormat.length;
    } catch (error) {
      console.error(error);
    }
    return isValid;
  },

  /**
   *
   * @param data
   * @param csvFormat
   * @returns {*}
   */
  formatRowsToObjects: (data, csvFormat) => {
    return data.map((csvRow) => {
      let resultRow = {};
      csvFormat.forEach((csvFormatItem, index) => {
        resultRow[csvFormatItem.key] = csvRow[index];
      });
      return resultRow;
    });
  },

  /**
   *
   * @param data
   * @param csvFormat
   * @returns {*}
   */
  pureRows: (data, csvFormat) => {
    return data.map((csvRow) => {
      let pured = {};
      csvFormat.forEach((csvFormatItem) => {
        if (typeof csvFormatItem.pure === "function") {
          pured[csvFormatItem.key] = csvFormatItem.pure(
            csvRow[csvFormatItem.key]
          );
        } else {
          pured[csvFormatItem.key] = csvRow[csvFormatItem.key];
        }
      });
      return pured;
    });
  },

  /**
   *
   * @param data
   * @param csvFormat
   * @returns {{errors: any[]}}
   */
  validateRows: (data, csvFormat) => {
    let errors = [];
    data.forEach((csvRow) => {
      csvFormat.forEach((csvFormatItem) => {
        if (typeof csvFormatItem.validate === "function") {
          if (!csvFormatItem.validate(csvRow[csvFormatItem.key])) {
            errors.push(csvFormatItem.errorMessage);
          }
        }
      });
    });
    const errorsAr = [...new Set(errors)];
    return errorsAr.length ? { errors: errorsAr } : null;
  },

  /**
   *
   * @param data
   * @param csvFormat
   * @returns {*}
   */
  removeEmptyRows: (data, csvFormat) => {
    return data.filter((csvRow) => {
      let isEmpty = true;
      csvFormat.forEach((csvFormatItem) => {
        if (!!csvRow[csvFormatItem.key]) {
          isEmpty = false;
        }
      });
      return !isEmpty;
    });
  },
};
