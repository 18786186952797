export default class VariablesString {
  constructor({
    string,
    variablesWithDataToReplace = [], // Array<{name: string, value: string}>
    openBracketSymbol = "[",
    closeBracketSymbol = "]",
  }) {
    this.string = string;
    this.variablesWithDataToReplace = variablesWithDataToReplace;
    this.variablesNames = variablesWithDataToReplace.map((item) => item.name);
    this.openBracketSymbol = openBracketSymbol;
    this.closeBracketSymbol = closeBracketSymbol;
  }

  /**
   *
   * @type {{text: string, type: string}}
   * @private
   */
  _bracketsOrderError = {
    type: "bracketsOrderError",
    text: "Please check the brackets order",
  };

  /**
   *
   * @type {{text: string, type: string}}
   * @private
   */
  _bracketsOpenCloseError = {
    type: "bracketsOpenCloseError",
    text: "Please check the brackets are properly open and closed",
  };

  /**
   *
   * @type {{text: string, type: string}}
   * @private
   */
  _bracketsEmptyVariableError = {
    type: "bracketsEmptyVariableError",
    text: "Please check the variables are set into the brackets",
  };

  /**
   *
   * @type {{text: string, type: string}}
   * @private
   */
  _notAllowedVariableError = {
    type: "notAllowedVariableError",
    text: "Please check for allowed variables",
  };

  /**
   *
   * @returns {{text: string, type: string}}
   */
  validateBrackets = () => {
    const string = this.string;
    let bracketsCounter = 0;

    for (let i = 0; i < string.length; i++) {
      if (string[i] === this.openBracketSymbol) {
        bracketsCounter++;
      } else if (string[i] === this.closeBracketSymbol) {
        bracketsCounter--;
      }
      if (bracketsCounter > 1 || bracketsCounter < 0) {
        return this._bracketsOrderError;
      }
      if (
        string[i - 1] &&
        string[i - 1] === this.openBracketSymbol &&
        string[i] === this.closeBracketSymbol
      ) {
        return this._bracketsEmptyVariableError;
      }
    }

    if (bracketsCounter !== 0) {
      return this._bracketsOpenCloseError;
    }
  };

  /**
   *
   * @returns {*}
   * @private
   */
  _getAllowedVariablesFromExtracted = () => {
    return this._extractVariables().filter((item) =>
      this.variablesNames.includes(item)
    );
  };

  /**
   *
   * @returns {{text: string, type: string}}
   */
  validateVariablesNames = () => {
    let error;
    const extractedVariables = this._extractVariables();
    const allowedVariablesFromExtracted = this._getAllowedVariablesFromExtracted(
      extractedVariables
    );

    if (extractedVariables.length !== allowedVariablesFromExtracted.length) {
      error = this._notAllowedVariableError;
    }

    return error;
  };

  /**
   *
   * @returns {{text: string, type: string}}
   */
  validate = () => {
    const validateBracketsError = this.validateBrackets();
    if (validateBracketsError) {
      return validateBracketsError;
    }

    const validateVariablesNamesError = this.validateVariablesNames();
    if (validateVariablesNamesError) {
      return validateVariablesNamesError;
    }
  };

  /**
   *
   * @returns {*}
   * @private
   */
  _extractVariables = () => {
    if (this.extractedVariables) {
      return this.extractedVariables;
    }

    this.extractedVariables = this.string
      .split(this.openBracketSymbol)
      .map((item) =>
        item.includes(this.closeBracketSymbol)
          ? item.split(this.closeBracketSymbol)[0]
          : null
      )
      .filter((item) => !!item);

    return this.extractedVariables;
  };

  /**
   *
   * @returns {*}
   * @private
   */
  _createResultString = () => {
    let string = this.string;

    this._extractVariables().forEach((item) => {
      const varDesc = this.variablesWithDataToReplace.find(
        (varDesc) => varDesc.name === item
      );
      string = string.replace(`[${item}]`, varDesc.value);
    });

    return string;
  };

  /**
   *
   * @returns {{text: string, type: string}|*}
   */
  createResultString = () => {
    const validateError = this.validate();

    if (validateError) {
      return validateError;
    }

    return this._createResultString();
  };
}
