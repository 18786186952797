import React, { Component } from "react";
import { history, store } from "../../configureStore";
import StaticAlert from "../../components/StaticAlert";
import DropdownMenu from "../../components/DropdownMenu";
import PageTemplate from "../PageTemplate";
import * as appActions from "../../actions/app";
import * as projectActions from "../../actions/project";
import * as shotActions from "../../actions/shot";
import * as shotVersionActions from "../../actions/shotVersion";
import * as rtcActions from "../../actions/rtc";
import connect from "react-redux/es/connect/connect";
import { Link, withRouter } from "react-router-dom";
import classNames from "classnames";
import withRTC from "../../hocs/withRTC";
import CustomizableDataTable from "../../components/CustomizableDataTable";
import LoadingIndicator from "../../components/LoadingIndicator";
import HoveredEdit from "../../components/HoveredEdit";
import ApiError from "../../components/ApiError";
import {
  filterAllowedRoles,
  filterComparisonAND,
  shotsSortFn,
  hasRights,
  filterDataTableOptionsByRole,
  getStatus,
} from "../../helpers/tools";
import ShotsTasksAssignees from "../../components/ShotsTasksAssignees";
import HasRights from "../../components/HasRights";
import ProgressBar from "../../components/ProgressBar";
import * as activitiesActions from "../../actions/activities";
import RecentActivity from "../../components/RecentActivity";
import { changeAssignee } from "../../actions/tasks";
import StatusEditable from "../../components/StatusEditable";
import TasksAssigneesFormat from "./components/TasksAssigneesFormat";
import NotesPreviewFormat from "./components/formatters/NotesPreviewFormat";
import ShotsExpandable from "./components/ShotsExpandable";
import Header from "./components/Header";
import SeoBlock from "../../components/SeoBlock";
import { UserRole } from "../../consts/user";
import { updateTasksAssignees } from "../../hooks/useOverwriteAssignee";
import { fetchShotsByBidVersion } from "../../actions/bidVersion";
import HoursFormat from "./components/formatters/HoursFormat";
import DescriptionFormat from "./components/formatters/DescriptionFormat";
import { getSelectedRowsExpandButtons } from "../../helpers/projectTable";

const shotsStatusesSort = (a, b) => (a.value > b.value ? -1 : 1);

const staticAlertHeader = "No records to display";
const staticAlertBody = <>This table lists shots.</>;

const shotsOutstandingText =
  "This shot has been added after bid has been approved by the client";

const shotVersionMaxLength = 16;

class ProjectPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filteredShotsCollection: null,
      filter: [],
      isFiltersApplied: false,
      haveExpandedRows: {},

      bulkUpdate: false,

      selectedShots: [],
    };

    this.statuses = this.props.statuses.collection.filter(
      (i) => i.type === "Shots"
    );

    this.shotsDataTableConfig = {
      columns: [
        {
          name: "Shot Number",
          selector: "shot_number",
          sortable: true,
          format: (row) => (
            <>
              <HasRights
                allowedRoles={[UserRole.admin, UserRole.producer]}
                user={this.props.user.model}
              >
                {row.is_outstanding && !row.current_version.is_approved ? (
                  <i
                    className="icon-exclamation icon-exclamation_in-table mb-0 mr-2"
                    data-popup="tooltip"
                    data-placement="right"
                    data-original-title={shotsOutstandingText}
                  />
                ) : (
                  <span />
                )}
              </HasRights>
              <HasRights
                allowedRoles={[
                  UserRole.admin,
                  UserRole.producer,
                  UserRole.accountant,
                  UserRole.artist,
                ]}
                user={this.props.user.model}
                placeholder={
                  <span className="text-primary">
                    {row.shot_number || "--"}
                  </span>
                }
              >
                <Link
                  className="relative"
                  to={`/project/${this.props.match.params.id}/shot/${row.id}`}
                >
                  {row.shot_number || "--"}
                </Link>
              </HasRights>
            </>
          ),
          width: "158px",
        },
        {
          name: "Version",
          selector: "last_shot_update",
          format: (row) => {
            const allowedRoles = [UserRole.admin, UserRole.producer];

            if (this.isCurrentUserAssignedToAnyTask(row)) {
              allowedRoles.push("artist");
            }

            let currentVersionPreview = <span className="text-nowrap">--</span>;
            let currentVersionValue = "--";

            if (row.last_shot_update) {
              const recentShotUpdate = row.last_shot_update;
              currentVersionPreview = recentShotUpdate.version;
              currentVersionValue = recentShotUpdate.version;
            }
            // BLP-1003: using shot_updates array as a workaround: bulk update will not return
            // last_shot_update but projects view endpoint returns it. To be updated
            // when we remove shot updates from shot view endpoint.
            else if (row.shot_updates && row.shot_updates.length) {
              const recentShotUpdate = row.shot_updates[0];
              currentVersionPreview = recentShotUpdate.version;
              currentVersionValue = recentShotUpdate.version;
            }

            const isLoading = this.state.versionInputUpdating === row.id;

            return isLoading ? (
              <i className="icon-spinner2 spinner fs-086rem" />
            ) : (
              <HasRights
                allowedRoles={allowedRoles}
                user={this.props.user.model}
                placeholder={currentVersionPreview}
              >
                <HoveredEdit
                  className={classNames(
                    "hovered-edit_for-dark-theme",
                    "overflow-visible",
                    "hovered-edit_buttons-right",
                    "hovered-edit_left-on-padding-size",
                    "hovered-edit_white-space-normal-in-table"
                  )}
                  preview={currentVersionPreview}
                  previewTitle={currentVersionValue}
                  handleConfirm={this.onShotVersionChange(row.id)}
                  handleFocus={this.shotVersionHandleFocus(row.id)}
                  cutPreviewLength="50px"
                  // disabled={isEditing}
                >
                  <input
                    defaultValue={currentVersionValue}
                    type="text"
                    maxLength={shotVersionMaxLength}
                    className="input-pure maxw-100p"
                    id={`js-hovered-version-${row.id}`}
                    placeholder="None"
                  />
                </HoveredEdit>
              </HasRights>
            );
          },
          allowOverflow: true,
          width: "75px",
        },
        {
          name: "Status",
          selector: "status",
          sortable: true,
          format: (row) => {
            const allowedRoles = [UserRole.admin, UserRole.producer];

            if (this.isCurrentUserAssignedToAnyTask(row)) {
              allowedRoles.push(UserRole.artist);
            }

            return (
              <div className="">
                <StatusEditable
                  status={row.status}
                  statuses={this.statuses}
                  onChange={this.onStatusChange(row)}
                  loading={
                    this.props.shot.edit && this.state.editShotId === row.id
                  }
                  disabled={
                    !allowedRoles.includes(this.props.user.model.role) ||
                    (this.props.shot.edit && this.state.editShotId !== row.id)
                  }
                />
              </div>
            );
          },
          width: "153px",
        },
        {
          name: "Description",
          selector: "current_version.description",
          minWidth: "200px",
          cell: (row) => (
            <DescriptionFormat
              shot={row}
              isExpanded={this.state.haveExpandedRows[row.id]}
              onClick={this.onNotePreviewClick}
            />
          ),
        },
        {
          name: "Notes",
          selector: "notes_preview",
          format: (row) => (
            <NotesPreviewFormat
              shot={row}
              isExpanded={this.state.haveExpandedRows[row.id]}
              onClick={this.onNotePreviewClick}
            />
          ),
          width: "200px",
        },
        /*{
          name: "Est. Hours",
          selector: "current_version.hours",
          cell: (row) => (
            <HoursFormat
              shot={row}
              isExpanded={this.state.haveExpandedRows[row.id]}
              onClick={this.onNotePreviewClick}
            />
          ),
          width: "110px",
          allowedRoles: [
            UserRole.admin,
            UserRole.producer,
            UserRole.accountant,
            UserRole.artist,
          ],
        },*/
        // {
        //   name: "Notes",
        //   selector: "current_version.notes",
        //   width: '100px',
        //   allowedRoles: ["client"],
        //   roleOptions: {
        //     client: {
        //       width: 'auto',
        //       right: true,
        //     },
        //   },
        // },
        {
          name: "Assignees",
          selector: "tasks",
          format: (row) => (
            <TasksAssigneesFormat project={row} user={this.props.user.model} />
          ),
          allowedRoles: [
            UserRole.admin,
            UserRole.producer,
            UserRole.accountant,
            UserRole.artist,
          ],
          width: "110px",
        },
        {
          name: "Actions",
          right: true,
          cell: (row) =>
            row.is_outstanding && !row.current_version.is_approved ? (
              <DropdownMenu
                buttons={[
                  {
                    action: this.handleShotApproved(row),
                    data: row,
                    label: "Mark as approved",
                  },
                ]}
                dropdownMenuClassName="bg-dark"
              />
            ) : null,
          allowedRoles: [UserRole.admin, UserRole.producer],
          width: "70px",
        },
      ],
      defaultSortedField: "shot_number",
      sortFunction: (rows, field, direction) => {
        switch (field) {
          case "shot_number":
            return direction === "asc"
              ? rows.sort(shotsSortFn).reverse()
              : rows.sort(shotsSortFn);

          case "status":
            const sort = (a, b) => (a.status > b.status ? -1 : 1);
            return direction === "asc"
              ? rows.sort(sort).reverse()
              : rows.sort(sort);

          default:
            return rows;
        }
      },
      filters: [
        {
          placeholder: "Shot number",
          defaultValue: "",
          fieldName: "shot_number",
          type: "text",
        },
        {
          data: this.statuses,
          placeholder: "Choose status",
          fieldName: "status",
          type: "select",
        },
        {
          placeholder: "Artists",
          fieldName: "artists",
          type: "userSelect",
          onUpdate: this.artistsFilterOnUpdate,
          reactSelectProps: {
            isMulti: false,
            value: null,
            isClearable: true,
            classNamePrefix: "bg-dark",
          },
        },
      ],
      bulkActions: [
        {
          placeholder: "Assign all shot(s) task(s) to",
          fieldName: "assignee",
          type: "userSelect",
          addEmptyItem: true,
          emptyItemLabel: "Unassigned",
          emptyItemValue: "unassigned",
          reactSelectProps: {
            isMulti: false,
            isClearable: true,
            classNamePrefix: "bg-dark",
          },
          allowedRoles: [UserRole.admin, UserRole.producer],
        },
        {
          data: this.statuses,
          placeholder: "Choose status",
          fieldName: "status",
          type: "select",
          // allowedRoles: [UserRole.admin, UserRole.producer],
        },
        {
          placeholder: "Version",
          fieldName: "version",
          type: "textinput",
          maxLength: shotVersionMaxLength,
          // allowedRoles: [UserRole.admin, UserRole.producer],
        },
        // {
        //   type: "spacer",
        // },
        // {
        // placeholder: "Update summary",
        // fieldName: "summary",
        // type: "textinput",
        // maxLength: 128
        // },
        {
          placeholder: "Update description",
          fieldName: "description",
          type: "textarea",
          maxLength: 1024,
          rows: 1,
          // allowedRoles: [UserRole.admin, UserRole.producer],
        },
        {
          placeholder: "Update Project File",
          fieldName: "project_file",
          type: "textinput",
          allowedRoles: [UserRole.admin, UserRole.producer],
        },
        {
          placeholder: "Update elements",
          fieldName: "elements",
          type: "textinput",
          allowedRoles: [UserRole.admin, UserRole.producer],
        },
        {
          label: "Bulk update",
          action: this.bulkUpdateAction,
        },
        {
          label: "Assign selected to me",
          action: this.bulkAssignToMe,
        },
      ],
    };

    this.manageDropdownConfig = [
      {
        icon: "icon-equalizer",
        action: () => {
          this.props.setEditProject(this.props.project.model);
          history.push(`/project/edit/${this.props.project.model.id}`, {
            theme: "dark",
          });
        },
        data: null,
        label: "Edit project details",
      },
    ];
  }

  /**
   *
   */
  async componentDidMount() {
    if (this.props.user.token && this.props.user.model) {
      await this.props.fetchProject(this.props.match.params.id, 1);
      this.props.connectRTC("project", this.props.match.params.id);
      window.updateJQuery();
    }
  }

  /**
   *
   */
  componentWillUnmount() {
    this.props.disconnectRTC();
  }

  /**
   *
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      (prevProps.project.createShotVersion &&
        !this.props.project.createShotVersion) ||
      prevProps.activities.collection.length !==
        !this.props.activities.collection.length
    ) {
      window.updateJQuery();
    }
  }

  /**
   *
   */
  connectActivitiesSocket = () => {
    this.props.connectActivitiesSocket({
      activity_type: "project",
      project_id: this.props.match.params.id,
    });
  };

  /**
   *
   * @param shot
   * @returns {Function}
   */
  handleShotApproved = (shot) => () => {
    this.props.createShotVersion(shot.id, {
      ...shot.current_version,
      is_approved: 1,
      task_types: shot.tasks.map((i) => ({ id: i.task_type_id })),
    });
  };

  /**
   *
   * @param event
   */
  onPressAddShot = (event) => {
    event.preventDefault();
    const projectId = this.props.match.params.id;
    history.push(`/project/${projectId}/create-shot`);
  };

  /**
   *
   * @param event
   */
  onPressCollapseSidebar = (event) => {
    event.preventDefault();
    this.props.collapseSidebar(
      "ProjectPage",
      !this.props.app.projectPageSidebarCollapsed
    );
  };

  /**
   *
   * @param filter
   */
  onTableFilter = (filter = []) => {
    const assigneeStateFilterIndex = this.state.filter.findIndex(
      (x) => x.fieldName === "assignee"
    );
    if (assigneeStateFilterIndex > -1) {
      filter.push(this.state.filter[assigneeStateFilterIndex]);
    }
    this.setState({ filter: filter, isFiltersApplied: !!filter.length }, () => {
      const assigneeFilterIndex = filter.findIndex(
        (x) => x.fieldName === "assignee"
      );
      const collection =
        filter[assigneeFilterIndex] && filter[assigneeFilterIndex].value
          ? this.props.project.shots.filter(
              (shot) =>
                shot.tasks &&
                shot.tasks.find(
                  (task) =>
                    task.assignee &&
                    task.assignee.id === filter[assigneeFilterIndex].value.value
                )
            )
          : this.props.project.shots;
      let arResult = collection
        ? collection.filter((item) => {
            return filterComparisonAND(
              item,
              filter.filter((item) => item.fieldName !== "assignee")
            );
          })
        : collection;
      this.setState({ filteredShotsCollection: arResult });
    });
  };

  /**
   *
   * @param value
   */
  artistsFilterOnUpdate = (value) => {
    const filter = [...this.state.filter];
    let filterField = {
      fieldName: "assignee",
      value: null,
    };
    if (value) {
      filterField = {
        fieldName: "assignee",
        value: {
          label: value.email ? `${value.name} (${value.email})` : value.name,
          value: value.id,
        },
        selectValue: value,
      };
    }
    const assigneeFilterIndex = filter.findIndex(
      (x) => x.fieldName === "assignee"
    );
    if (assigneeFilterIndex > -1) {
      if (value) {
        filter[assigneeFilterIndex] = filterField;
      } else {
        filter.splice(assigneeFilterIndex, 1);
      }
    } else if (value) {
      filter.push(filterField);
    }
    this.updateAssigneeFilter(filterField.value);
    this.setState({ filter });
  };

  /**
   *
   * @param value
   */
  updateAssigneeFilter = (value) => {
    this.shotsDataTableConfig.filters.find(
      (item) => item.fieldName === "artists"
    ).reactSelectProps.value = value;
  };

  /**
   *
   */
  onTableClearFilter = () => {
    this.updateAssigneeFilter(null);
    this.setState({
      filteredShotsCollection: null,
      filter: [],
      isFiltersApplied: false,
    });
  };

  /**
   *
   * @param data
   * @param bulkActions
   * @returns {Promise<void>}
   */
  bulkUpdateAction = async (data, bulkActions) => {
    const bulkEditShotsAllowedRoles = [
      UserRole.admin,
      UserRole.producer,
      UserRole.artist,
    ];

    if (bulkActions && bulkActions.length) {
      const { selectedRows } = data;

      this.setState({ bulkUpdate: true });

      // const summaryAction = bulkActions.find(x => x.fieldName === "summary");
      const versionAction = bulkActions.find((x) => x.fieldName === "version");
      const descriptionAction = bulkActions.find(
        (x) => x.fieldName === "description"
      );
      const assigneeAction = bulkActions.find(
        (x) => x.fieldName === "assignee"
      );

      let assignTasks = [];
      let assigneeId = null;

      if (assigneeAction && assigneeAction.value) {
        assigneeId =
          assigneeAction.value.id !== "unassigned"
            ? assigneeAction.value.id
            : null;
        selectedRows.forEach((shot) => {
          if (shot.tasks && shot.tasks.length) {
            shot.tasks.forEach((task) => {
              assignTasks.push(task);
            });
          }
        });
      }

      const hasVersion = !!(versionAction && versionAction.value);
      const hasDescription = !!(descriptionAction && descriptionAction.value);

      if (hasVersion || hasDescription) {
        for (const shot of selectedRows) {
          const version = this.generateBulkUpdateVersion(
            shot,
            hasVersion ? versionAction.value : null,
            !(hasVersion && !hasDescription)
          );

          await this.props.createShotUpdate(shot.id, {
            version,
            // summary: summaryAction ? summaryAction.value : null,
            description: descriptionAction ? descriptionAction.value : null,
          });
        }
      }

      if (bulkEditShotsAllowedRoles.includes(this.props.user.model.role)) {
        const updatedRows = selectedRows.map((row) => {
          let updatedRow = row;
          bulkActions.forEach((bulkAction) => {
            if (Object.keys(row).includes(bulkAction.fieldName)) {
              updatedRow = {
                ...updatedRow,
                [bulkAction.fieldName]: bulkAction.value,
              };
            }
          });
          delete updatedRow.shot_name;
          delete updatedRow.delivery_date;
          return updatedRow;
        });

        await this.props.bulkEditShots(updatedRows);

        if (!assignTasks.length) {
          this.setState({ bulkUpdate: false });
        }
      }

      if (assignTasks.length) {
        this.props.clearErrors();

        await updateTasksAssignees(
          assignTasks,
          assigneeAction.value,
          this.props.user.model,
          async (ids) => {
            if (ids) {
              await this.props.changeAssignee(ids, assigneeId);
            }
            this.setState({ bulkUpdate: false });
          }
        );
      }
    }
  };

  /**
   *
   * @param data
   * @param bulkActions
   * @returns {Promise<void>}
   */
  bulkAssignToMe = async (data, bulkActions) => {
    const tasks = [];
    for (const shot of data.selectedRows) {
      if (shot.tasks && shot.tasks.length) {
        for (const task of shot.tasks) {
          tasks.push(task);
        }
      }
    }

    let assigneeId = null;

    if (tasks.length) {
      this.setState({ bulkUpdate: true });
      assigneeId = this.props.user.model.id;

      await updateTasksAssignees(
        tasks,
        this.props.user.model,
        this.props.user.model,
        async (ids) => {
          if (ids) {
            await this.props.changeAssignee(ids, assigneeId);
          }
          this.setState({ bulkUpdate: false });
        }
      );
    }
  };

  /**
   *
   * @param shot
   * @param versionName
   * @param isPostfixNeeded
   * @returns {string}
   */
  generateBulkUpdateVersion = (shot, versionName, isPostfixNeeded) => {
    let version = versionName || "Initial";
    const postfix = " - bulk update";

    if (!isPostfixNeeded) {
      return version;
    }

    if (shot.last_shot_update && !versionName) {
      version = shot.last_shot_update.version;
      if (version.length <= 64) {
        if (version.includes(postfix)) {
          let [pureVersion, versionCount] = version.split(postfix);
          versionCount = Number(versionCount);
          if (!Number.isNaN(versionCount)) {
            if (versionCount === 0) {
              versionCount = 1;
            }
            version = `${pureVersion + postfix} ${versionCount + 1}`;
          }
        } else {
          version += postfix;
        }
      }
    } else {
      version += postfix;
    }

    return version;
  };

  /**
   *
   * @param pureShots
   * @returns {*}
   */
  getProgressAr = (pureShots) => {
    const notStarted = pureShots.filter(
      (item) => item.status === "not_started"
    );
    if (
      !pureShots ||
      !pureShots.length ||
      notStarted.length === pureShots.length
    ) {
      return [{ percent: "100%", text: "0% done", color: "bg-empty" }];
    }
    const statuses = this.props.statuses.collection.filter(
      (i) => i.type === "Shots"
    );

    let collection = {};
    pureShots.forEach((shot) => {
      if (shot.status !== "not_started") {
        const status = statuses.find((i) => i.value === shot.status);
        collection[status.value] = collection[status.value] || [];
        collection[status.value].push(shot);
      }
    });

    let progressAr = [];
    for (const statusValue in collection) {
      if (
        collection.hasOwnProperty(statusValue) &&
        collection[statusValue].length
      ) {
        if (statusValue === "not_started") {
          continue;
        }
        const percent = (
          (collection[statusValue].length * 100) /
          pureShots.length
        ).toFixed(0);
        const status = statuses.find((i) => i.value === statusValue);
        progressAr.push({
          percent: `${percent}%`,
          text: `${percent}% (${collection[statusValue].length}) ${status.label}`,
          color: status.color,
          value: status.value,
        });
      }
    }

    return progressAr.sort(shotsStatusesSort).reverse();
  };

  /**
   *
   * @param pureShots
   * @returns {boolean}
   */
  isProgressBarTextHidden = (pureShots) => {
    const notStarted = pureShots.filter(
      (item) => item.status === "not_started"
    );
    return !(
      !pureShots ||
      !pureShots.length ||
      notStarted.length === pureShots.length
    );
  };

  /**
   *
   */
  onFetchPreviousActivities = (lastActivityId) => {
    this.props.getPreviousActivities(lastActivityId, {
      activity_type: "project",
      id: this.props.match.params.id,
    });
  };

  /**
   *
   * @param row
   * @returns {Function}
   */
  onStatusChange = (row) => async (value) => {
    if (value) {
      this.props.clearErrors();
      this.setState(
        {
          editShotId: row.id,
        },
        async () => {
          await this.props.editShot(row.id, { status: value });
          if (store.getState().shot.editSuccess) {
            this.props.onChangeEntity("shotUpdate", {
              fieldName: "status",
              shotId: row.id,
              projectId: this.props.project.model.id,
              value,
            });
          }
          this.setState({
            editShotId: null,
          });
          window.updateJQuery();
        }
      );
    }
  };

  /**
   *
   * @param shot
   * @returns {boolean}
   */
  isCurrentUserAssignedToAnyTask = (shot) => {
    return !!(shot.tasks || []).filter(
      (item) =>
        this.props.user.model &&
        item.assignee &&
        item.assignee.id === this.props.user.model.id
    ).length;
  };

  /**
   *
   * @param id
   * @returns {function(): Promise<void>}
   */
  onShotVersionChange = (id) => async () => {
    this.setState({ versionInputUpdating: id });
    const input = document.querySelector(`#js-hovered-version-${id}`);
    if (input) {
      await this.props.createShotUpdate(id, {
        version: input.value,
      });
      if (store.getState().shot.shotUpdateCreateSuccess) {
        this.props.onChangeEntity("shotUpdateUpdate", {
          shotId: id,
          projectId: this.props.project.model.id,
          value: {
            id: `${id}_${+new Date()}`,
            version: input.value,
            shot_id: id,
            description: null,
          },
        });
      }
    }
    this.setState({ versionInputUpdating: undefined });
  };

  /**
   *
   * @param id
   * @returns {function(): void}
   */
  shotVersionHandleFocus = (id) => () => {
    setTimeout(() => {
      const input = document.querySelector(`#js-hovered-version-${id}`);
      if (input) {
        input.focus();
      }
    }, 0);
  };

  /**
   *
   * @param event
   * @param id
   */
  onNotePreviewClick = (event, id) => {
    if (event && event.target) {
      const parentRow = event.target.closest(".rdt_TableRow");
      const buttons = parentRow.querySelectorAll("button");

      // if there are selected shots and id shot is one of them, expand/collapse all selected shots and return.
      // in other case, expand/collapse only clicked shot
      if (
        this.state.selectedShots.length &&
        this.state.selectedShots.find((i) => i.id === id)
      ) {
        const selectedRowExpandButtons = getSelectedRowsExpandButtons();
        selectedRowExpandButtons.map((button) => {
          if (button) {
            button.click();
          }
        });
        return;
      }

      const expandButton = Array.from(buttons).filter(
        (button) => !!button.attributes["data-testid"]
      )[0];

      if (expandButton) {
        expandButton.click();
        setTimeout(() => {
          const isExpanded =
            parentRow.nextSibling &&
            parentRow.nextSibling.classList.contains("rdt_ExpanderRow");
          this.setState({
            haveExpandedRows: {
              ...this.state.haveExpandedRows,
              [id]: isExpanded,
            },
          });
        }, 0);
      }
    }
  };

  /**
   *
   * @param shots
   * @returns {[]}
   */
  extractShotStatusesStats = (shots) => {
    const notStartedValue = "not_started";
    let stats = [];
    const statusesExceptNotStarted = [...shots]
      .filter((item) => item.status && item.status !== notStartedValue)
      .map((item) => item.status);
    const statusesNotStarted = [...shots].filter(
      (item) => item.status === notStartedValue
    );
    const statusesUnique = new Set(statusesExceptNotStarted);
    statusesUnique.forEach((status) => {
      stats.push({
        value: status,
        name: getStatus(status, this.statuses),
        amount: statusesExceptNotStarted.filter((item) => item === status)
          .length,
      });
    });

    stats.sort(shotsStatusesSort).reverse();

    stats.push({
      value: notStartedValue,
      name: getStatus(notStartedValue, this.statuses),
      amount: statusesNotStarted.length,
    });

    return stats;
  };

  /**
   *
   * @param data
   */
  onCheckRow = (data) => {
    this.setState({ selectedShots: data.selectedRows });
  };

  /**
   *
   * @returns {XML}
   */
  render() {
    const shot = this.props.shot;
    const project = this.props.project;
    const projectModel = project.model || {};
    const pureShots = project.shots;
    const filteredShots = this.state.filteredShotsCollection;
    const shots = filteredShots || pureShots;

    const activities = this.props.activities;
    const isSidebarCollapsed = this.props.app.projectPageSidebarCollapsed;

    const isLoading = project.fetch || project.createShotVersion;
    const isRefreshing =
      project.fetch &&
      project.model &&
      project.model.id === this.props.match.params.id;

    const isActivitiesLoading = activities.connecting;

    const hasOutstandingShot = shots.some(
      (x) => x.is_outstanding && !x.current_version.is_approved
    );
    let dataTableColumns = this.shotsDataTableConfig.columns;

    if (!hasOutstandingShot) {
      dataTableColumns = this.shotsDataTableConfig.columns.filter(
        (x) => x.name !== "Actions"
      );
    }

    const tableColumnsFilteredRoles = filterAllowedRoles(
      this.props.user.model,
      dataTableColumns
    );

    const tableColumnsFilteredOptions = filterDataTableOptionsByRole(
      this.props.user.model,
      tableColumnsFilteredRoles
    );

    const areBulkActionsAllowed = hasRights(this.props.user.model, {}, [
      "admin",
      "producer",
      "artist",
    ]);

    const expandableRowsComponent = <ShotsExpandable />;
    const dataTable = shots ? (
      <div
        className={classNames("data-table-themed_hide-expand-arrow", {
          "data-table-themed_project-page-shift-selection":
            areBulkActionsAllowed
              ? !!this.shotsDataTableConfig.bulkActions
              : false,
        })}
      >
        <CustomizableDataTable
          key={hasOutstandingShot.toString()}
          collection={shots}
          columns={tableColumnsFilteredOptions}
          showFilters={true}
          filters={this.shotsDataTableConfig.filters}
          onFilter={this.onTableFilter}
          onClearAll={this.onTableClearFilter}
          isFiltersApplied={this.state.isFiltersApplied}
          defaultSortField={this.shotsDataTableConfig.defaultSortedField}
          sortFunction={this.shotsDataTableConfig.sortFunction}
          canSelectAll
          expandableRows
          expandableRowsComponent={expandableRowsComponent}
          bulkActions={
            areBulkActionsAllowed ? this.shotsDataTableConfig.bulkActions : []
          }
          bulkActionsLoading={
            this.state.bulkUpdate ||
            (!this.state.versionInputUpdating &&
              (this.props.project.bulkEdit ||
                this.props.shot.shotUpdateCreate ||
                this.props.tasks.assign))
          }
          filtersClassName="bg-dark"
          bulkActionsClassName="bg-dark bulk-actions-fixed"
          isBulkUpdatesFixed={true}
          shiftSelection={
            areBulkActionsAllowed
              ? !!this.shotsDataTableConfig.bulkActions
              : false
          }
          user={this.props.user.model}
          onCheckRow={this.onCheckRow}
        />
      </div>
    ) : null;

    const staticAlert =
      pureShots && !pureShots.length ? (
        <StaticAlert
          header={staticAlertHeader}
          body={staticAlertBody}
          className="bg-dark"
        />
      ) : null;

    const newShotButton = (
      <HasRights
        allowedRoles={["admin", "producer"]}
        user={this.props.user.model}
      >
        <a
          href="!#"
          className="btn btn-link btn-float text-white"
          onClick={this.onPressAddShot}
        >
          <i className="mi-panorama-horizontal" />
          <span>Add new shot</span>
        </a>
      </HasRights>
    );

    const assigneesMap = new Map();
    pureShots.forEach((shot) => {
      (shot.tasks || [])
        .filter((shotTask) => shotTask.assignee !== null)
        .forEach((shotTask) => {
          const assigneeStat = assigneesMap.has(shotTask.assignee.id)
            ? assigneesMap.get(shotTask.assignee.id)
            : { assignee: shotTask.assignee, tasks: [], shots: [] };

          assigneesMap.set(shotTask.assignee.id, {
            ...assigneeStat,
            tasks: [...assigneeStat.tasks, shotTask],
            shots: [...assigneeStat.shots, shot],
          });
        });
    });

    const assigneesList = Array.from(assigneesMap.values()).map((stat) => {
      const uniqueTasks = stat.tasks.filter(
        (task, index) => stat.tasks.indexOf(task) >= index
      );
      const uniqueShots = stat.shots.filter(
        (shot, index) => stat.shots.indexOf(shot) >= index
      );
      return {
        ...stat,
        tasks: uniqueTasks,
        shots: uniqueShots,
      };
    });

    const shotsTasksAssignees = (
      <HasRights
        allowedRoles={["admin", "producer", "accountant"]}
        user={this.props.user.model}
      >
        <ShotsTasksAssignees
          assignees={assigneesList}
          className="bg-dark border-primary"
        />
      </HasRights>
    );

    const recentActivity = (
      <HasRights
        allowedRoles={["admin", "producer", "artist", "accountant"]}
        user={this.props.user.model}
      >
        <RecentActivity
          id="project-page-activities"
          onConnect={this.connectActivitiesSocket}
          onFetchPrevious={this.onFetchPreviousActivities}
          className="bg-dark border-primary"
        />
      </HasRights>
    );

    const rightTopMenu = <>{newShotButton}</>;

    const manageDropdownButton = (
      <button
        type="button"
        className="btn btn-link btn-link_black dropdown-toggle ml-2"
        data-toggle="dropdown"
      >
        <i className="icon-gear mr-13px" />
        Manage
      </button>
    );

    const isRightColEmpty = !hasRights(
      this.props.user.model,
      {},
      shotsTasksAssignees.props.allowedRoles.concat(
        recentActivity.props.allowedRoles
      )
    );

    const collapseSidebarIcon = isSidebarCollapsed ? (
      <i className="icon-enlarge6" />
    ) : (
      <i className="icon-shrink6" />
    );

    const activitiesLoadingIndicator = isActivitiesLoading ? (
      <i className="icon-spinner2 spinner" />
    ) : null;

    const collapseSidebarBtn = !isRightColEmpty ? (
      <button
        type="button"
        className="btn btn-link btn-link_black"
        onClick={this.onPressCollapseSidebar}
        disabled={isActivitiesLoading}
      >
        {activitiesLoadingIndicator || collapseSidebarIcon}
      </button>
    ) : null;

    const manageDropdownOptions = [...this.manageDropdownConfig];

    if (this.state.selectedShots.length) {
      const shot = this.state.selectedShots[0];

      manageDropdownOptions.push({
        icon: "mi-content-copy",
        action: async () => {
          history.push(
            `/project/${shot.current_version.bid_version_id}/copy-shots-data`,
            {
              selectedShots: this.state.selectedShots,
              theme: "dark",
            }
          );
        },
        data: null,
        label: "Copy shot(s) data...",
      });
    }

    const dropdownManageMenu = (
      <HasRights
        key={`project-dropdown-has-options-${manageDropdownOptions.length}`}
        allowedRoles={["admin", "producer"]}
        user={this.props.user.model}
      >
        <DropdownMenu
          buttons={manageDropdownOptions}
          openButton={manageDropdownButton}
          dropdownMenuClassName="bg-dark"
        />
      </HasRights>
    );

    const rightLine = (
      <div className="d-flex flex-nowrap">
        {dropdownManageMenu}
        {collapseSidebarBtn}
      </div>
    );

    const description = projectModel.description ? (
      <p>
        <b>Description: </b>
        {projectModel.description}
      </p>
    ) : (
      ""
    );

    const progressAr = this.getProgressAr(pureShots);
    const isProgressBarTextHidden = this.isProgressBarTextHidden(pureShots);

    const isSidebarCollapsedCommon = isRightColEmpty || isSidebarCollapsed;

    const leftColClassName = classNames(
      { "col-xl-9": !isSidebarCollapsedCommon },
      { "col-xl-12": isSidebarCollapsedCommon }
    );

    const pageContent = (project.fetch && !isRefreshing) || (
      <div className="row relative h-100 flex-column-reverse flex-xl-column">
        {!isSidebarCollapsedCommon && (
          <div className="col-xl-3 limit-height-with-sibling-right-xl d-flex flex-column z-index-5">
            {shotsTasksAssignees}

            {recentActivity}
          </div>
        )}
        <div className="col">
          <div className="row">
            <div className={leftColClassName}>
              <ApiError error={project.editError || shot.editError} />

              {description}

              <Link
                to={{
                  pathname: `/project/${projectModel.id}/progress`,
                  state: { theme: "dark" },
                }}
              >
                <ProgressBar
                  progressClassNames="progress_height font-size-sm mt-2 mb-2"
                  progressAr={progressAr}
                  isTextHidden={isProgressBarTextHidden}
                />
              </Link>

              <h5>Shots ({pureShots.length})</h5>
              {shots === null && <LoadingIndicator />}
              <ApiError error={this.props.project.bulkEditError} />
              {this.props.tasks.bulkAssignErrors.map((error, index) => (
                <ApiError key={`err--${index}`} error={error} />
              ))}

              {shots !== null && dataTable}
              {staticAlert}
            </div>
          </div>
        </div>
      </div>
    );

    const headerTitle = <Header />;

    const stats = this.extractShotStatusesStats(pureShots);

    const breadcrumbs = (
      <div>
        {stats.map((item, index) => {
          return (
            <span className="mr-4 d-inline-block mt-1 mb-1" key={index}>
              {item.name}:&nbsp;
              <span className="badge badge-flat badge-pill border-primary text-primary-600">
                {item.amount}
              </span>
            </span>
          );
        })}
      </div>
    );

    const backArrowPath = "/projects/1";

    return (
      <PageTemplate
        header={headerTitle}
        rightTopMenu={rightTopMenu}
        rightLine={rightLine}
        breadcrumbs={breadcrumbs}
        pageWrapperClassName="bg-dark page-header-dark-theme"
        pageFooterClassName="navbar-dark"
        breadcrumbClassName="breadcrumb-line-dark"
        backArrowPath={backArrowPath}
        backButtonArrowIcon={"mi-keyboard-return icon-rotate-90deg"}
      >
        <SeoBlock title={projectModel.name} />
        {pageContent}
        {isLoading && !isRefreshing ? <LoadingIndicator isModal /> : null}
      </PageTemplate>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  project: state.project,
  tasksTypes: state.tasksTypes,
  shot: state.shot,
  statuses: state.statuses,
  activities: state.activities,
  tasks: state.tasks,
  app: state.app,
});

const mapDispatchToProps = (dispatch) => ({
  fetchProject: async (id, approvedShots) =>
    await dispatch(projectActions.fetchProject(id, approvedShots, 1)),
  editProject: async (id, data) =>
    await dispatch(projectActions.editProject(id, data)),
  setEditProject: (project) => dispatch(projectActions.setEditProject(project)),
  bulkEditShots: async (data) =>
    await dispatch(projectActions.bulkEditShots(data)),
  createShotUpdate: async (shot_id, data) =>
    await dispatch(shotActions.createShotUpdate(shot_id, data)),
  createShotVersion: (shot_id, data) =>
    dispatch(shotVersionActions.createShotVersion(shot_id, data)),
  connectActivitiesSocket: (data) =>
    dispatch(activitiesActions.connectActivitiesSocket(data)),
  getPreviousActivities: (startFromActivityId, data) =>
    dispatch(
      activitiesActions.getPreviousActivities(startFromActivityId, data)
    ),
  changeAssignee: async (tasksIds, assigneeId) =>
    await dispatch(changeAssignee(tasksIds, assigneeId)),
  editShot: (id, data) => dispatch(shotActions.editShot(id, data)),
  connectRTC: (roomName, entityId) =>
    dispatch(rtcActions.connectRTC(roomName, entityId)),
  disconnectRTC: () => dispatch(rtcActions.disconnectRTC()),
  collapseSidebar: (type, collapse) =>
    dispatch(appActions.collapseSidebar(type, collapse)),
  clearErrors: () => dispatch(appActions.clearErrors()),
  fetchShotsByBidVersion: (bidVersionId) =>
    dispatch(fetchShotsByBidVersion(bidVersionId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRTC(withRouter(ProjectPage)));
