import React, { Component } from "react";
import BidPageContent from "./BidPage/components/BidPageContent";

class BidPreviewPage extends Component {
  render() {
    return (
      <div className="page-wrapper m-2">
        <BidPageContent isPreview={true} />
      </div>
    );
  }
}

export default BidPreviewPage;
