import AxiosConfig from "../AxiosConfig";
import axios from "axios";

/**
 *
 * @param type
 * @returns {function(*): Promise<T>}
 */
export const fetchPredictionReports = (type) => {
  return (dispatch) => {
    dispatch({
      type: "PREDICTION_REPORTS_FETCH",
      data: { type },
    });

    const url =
      AxiosConfig.getEndpointAddress() +
      "/api/statistics/bids.json?" +
      AxiosConfig.objectToURLQuery({ bids_statuses: "bid_in_progress" });

    return axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "PREDICTION_REPORTS_FETCH_FULFILLED",
          data: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "PREDICTION_REPORTS_FETCH_REJECTED",
          data: error,
        });
      });
  };
};
