import React, { Component } from "react";
import { history } from "../../configureStore";
import StaticAlert from "../../components/StaticAlert";
import PageTemplate from "../PageTemplate";
import * as invoicesActions from "../../actions/invoices";
import * as bidActions from "../../actions/bid";
import * as appActions from "../../actions/app";
import connect from "react-redux/es/connect/connect";
import { Link, withRouter } from "react-router-dom";
import CustomizableDataTable from "../../components/CustomizableDataTable";
import DataGridPagination from "../../components/DataGridPagination";
import StatusEditable from "../../components/StatusEditable";
import NumberOfShots from "./components/NumberOfShots";
import InvoicesIssuedList from "./components/InvoicesIssuedList";
import InvoiceTotalFormat from "./components/InvoiceTotalFormat";
import LoadingIndicator from "../../components/LoadingIndicator";
import HasRights from "../../components/HasRights";
import { filterAllowedRoles, formatter, hasRights } from "../../helpers/tools";
import BidInvoicesTableExpandable from "./components/BidInvoicesTableExpandable";

const staticAlertHeader = "No records to display";
const staticAlertBody = <>There are no invoices available yet.</>;

const trashAllowedRoles = ["admin", "producer", "accountant"];

class InvoicesPage extends Component {
  constructor(props) {
    super(props);
    const filterProps = this.filters;

    const filter = Object.keys(filterProps).map((key) => ({
      fieldName: key,
      value: filterProps[key],
    }));

    this.state = {
      filter,
      isFiltersApplied: !!filter.filter((i) => i && i.value).length,
      sortBy: props.filtersReducer.invoicesSortBy || null,
      orderBy: props.filtersReducer.invoicesOrderBy || null,
    };

    this.statuses = this.props.statuses.collection.filter(
      (i) => i.type === "Projects"
    );
  }

  /**
   * get filter from router
   * @return {{}}
   */
  get routerFilters() {
    const filters = {};
    if (this.props.match.params.slug === "archive") {
      filters.is_archived = 1;
    }
    if (this.props.match.params.slug === "trash") {
      filters.is_trash = 1;
    }

    return filters;
  }

  /**
   *
   * @returns {{}}
   */
  get filters() {
    return { ...this.savedFilters, ...this.routerFilters };
  }

  /**
   *
   */
  get savedFilters() {
    const filters = {};

    if (
      Array.isArray(this.props.filtersReducer.invoicesStatus) &&
      this.props.filtersReducer.invoicesStatus.length === 1
    ) {
      filters.status = this.props.filtersReducer.invoicesStatus[0];
    }

    if (
      Array.isArray(this.props.filtersReducer.invoicesClients) &&
      this.props.filtersReducer.invoicesClients.length
    ) {
      filters.clientFilter = this.props.filtersReducer.invoicesClients[0];
    }

    filters.name = this.props.filtersReducer.invoicesQuery;
    // filters.fromDate = this.props.filtersReducer.invoicesFrom;
    // filters.toDate = this.props.filtersReducer.invoicesTo;

    return filters;
  }

  /**
   *
   * @returns {{perPage: number, columns: *[], filters: *[]}}
   */
  get invoicesDataTableConfig() {
    const statusFilter = this.state.filter.find(
      (x) => x.fieldName === "status"
    );
    const status = statusFilter && statusFilter.value ? statusFilter.value : "";

    const nameFilter = this.state.filter.find((x) => x.fieldName === "name");
    const name = nameFilter ? nameFilter.value : "";

    // const fromDateFilter = this.state.filter.find(x => x.fieldName === "fromDate");
    // const fromDate = fromDateFilter ? fromDateFilter.value : "";

    // const toDateFilter = this.state.filter.find(x => x.fieldName === "toDate");
    // const toDate = toDateFilter ? toDateFilter.value : "";

    const clientFilter = this.state.filter.find(
      (x) => x.fieldName === "clientFilter"
    );
    const clients = clientFilter ? clientFilter.value : null;

    return {
      perPage: 30,
      columns: [
        {
          name: "Bid",
          selector: "name",
          format: (row) => {
            return row.id ? (
              <Link className="relative" to={"/invoice/" + row.id}>
                {`${row.project.name} - ${row.name}`}
              </Link>
            ) : null;
          },

          sortable: true,
        },
        {
          name: "Client name",
          selector: "client",
          format: (row) =>
            row && row.project && row.project.client
              ? row.project.client.name
              : null,
          sortable: true,
        },
        {
          name: "Bid status",
          selector: "status",
          format: (row) => {
            return (
              <div className="">
                <StatusEditable
                  status={row.status}
                  statuses={this.statuses}
                  onChange={this.onStatusChange(row)}
                  loading={
                    this.props.bid.edit && this.state.editBidId === row.id
                  }
                  disabled={
                    !["admin", "producer", "accountant"].includes(
                      this.props.user.model.role
                    ) ||
                    (this.props.bid.edit && this.state.editBidId !== row.id)
                  }
                />
              </div>
            );
          },
          sortable: true,
        },
        {
          name: "Number of shots",
          selector: "number_of_shots",
          center: true,
          format: (row) => <NumberOfShots invoice={row} />,
        },
        {
          name: "Invoices issued",
          selector: "issued_invoices",
          center: true,
          format: (row) => <InvoicesIssuedList invoice={row} />,
        },
        {
          name: "Total",
          selector: "total",
          format: (row) => <InvoiceTotalFormat invoice={row} />,
          center: true,
          width: "120px",
        },
        {
          name: "Deposit",
          selector: "deposit",
          format: (row) => formatter.format(row.deposit || 0),
          center: true,
        },
      ],
      filters: [
        {
          placeholder: "Client",
          fieldName: "clients",
          type: "customerSelect",
          onUpdate: this.clientsFilterOnUpdate,
          reactSelectProps: {
            isMulti: false,
            value: clients,
            isClearable: true,
          },
          allowedRoles: ["admin", "producer"],
        },
        {
          placeholder: "Status",
          data: this.props.statuses.collection.filter((i) => i.type === "Bid"),
          fieldName: "status",
          type: "select",
          value: status,
        },
        {
          placeholder: "Project name or a code",
          defaultValue: name,
          fieldName: "name",
          type: "text",
          tooltipMessage:
            "This field should be at least 3 symbols to filter by a name or a code",
        },
      ],
    };
  }

  /**
   *
   */
  componentDidMount() {
    if (this.props.user.token && this.props.user.model) {
      const page = this.props.filtersReducer.invoicesPage;
      let slug = this.props.match.params.slug;
      if (
        slug === "trash" &&
        !hasRights(this.props.user.model, {}, trashAllowedRoles)
      ) {
        slug = undefined;
        history.replace(`/invoices/${page}`);
      }

      // go to the page saved in store
      if (
        this.props.filtersReducer.invoicesPage !== this.props.match.params.page
      ) {
        const state = this.props.router.location.state;
        if (slug) {
          history.replace(`/invoices/${slug}/${page}`, state);
        } else {
          history.replace(`/invoices/${page}`, state);
        }
      } else {
        this.handleFilter();
      }
    }
  }

  /**
   *
   * @param prevProps
   */
  componentDidUpdate(prevProps) {
    // update filters
    if (this.props.match.params.slug !== prevProps.match.params.slug) {
      const filterProps = this.filters;
      const filter = Object.keys(filterProps).map((key) => ({
        fieldName: key,
        value: filterProps[key],
      }));
      this.setState(
        {
          filter,
          sortBy: null,
          orderBy: null,
        },
        () => {
          document.querySelector(".js-invoices-filter") &&
            document.querySelector(".js-invoices-filter").reset();
          this.handleFilter();
        }
      );
    }

    // paginate
    if (
      prevProps.match.params.page !== this.props.match.params.page &&
      prevProps.match.params.slug === this.props.match.params.slug
    ) {
      this.handleFilter();
    }

    if (
      prevProps.invoices.fetchError !== this.props.invoices.fetchError &&
      this.props.invoices.fetchError
    ) {
      const page = Number(this.props.match.params.page);
      if (page > 1) {
        const state = this.props.router.location.state;
        const slug = this.props.match.params.slug;
        if (slug) {
          history.replace(`/invoices/${slug}/1`, state);
        } else {
          history.replace(`/invoices/1`, state);
        }
      }
    }
  }

  /**
   *
   * @param page
   */
  onChangePage = (page) => {
    const state = this.props.router.location.state;
    const slug = this.props.match.params.slug;
    if (slug) {
      history.push(`/invoices/${slug}/${page}`, state);
    } else {
      history.push(`/invoices/${page}`, state);
    }
  };

  // /**
  //  *
  //  * @param project
  //  */
  // onEditProjectDetails = project => {
  //   this.props.setEditProject(project);
  //   history.push(`/project/edit/${project.id}`);
  // };

  // /**
  //  *
  //  * @param project
  //  * @returns {Function}
  //  */
  // onClickProject = project => () => {
  //   this.props.setEditProject(project);
  // };

  /**
   *
   * @param status
   */
  statusFilterOnChange = (status) => {
    this.setState({
      filter: { ...this.state.filter, status: status },
    });
  };

  /**
   *
   * @param value
   */
  clientsFilterOnUpdate = (value) => {
    const filter = [...this.state.filter];
    let filterField = {
      fieldName: "clientFilter",
      value: null,
    };
    if (value) {
      filterField = {
        fieldName: "clientFilter",
        value: {
          label: value.email ? `${value.name} (${value.email})` : value.name,
          value: value.id,
        },
      };
    }
    const clientFilterIndex = filter.findIndex(
      (x) => x.fieldName === "clientFilter"
    );
    if (clientFilterIndex > -1) {
      if (value) {
        filter[clientFilterIndex] = filterField;
      } else {
        filter.splice(clientFilterIndex, 1);
      }
    } else if (value) {
      filter.push(filterField);
    }
    this.setState({ filter });
  };

  /**
   *
   * @param filter
   */
  onFilter = (filter = []) => {
    // special case, because clientFilter is not handled by jQuery on CustomizableDataTable
    const stateFilter = [...this.state.filter];
    const clientFilterIndex = stateFilter.findIndex(
      (x) => x.fieldName === "clientFilter"
    );
    if (clientFilterIndex > -1) {
      filter.push(stateFilter[clientFilterIndex]);
    }
    // special case 2, apply filters from the router
    const routerFilters = this.routerFilters;
    Object.keys(routerFilters).forEach((key) => {
      filter.push({
        fieldName: key,
        value: routerFilters[key],
      });
    });
    //
    this.setState({ filter, isFiltersApplied: !!filter.length }, () => {
      if (this.props.match.params.page !== "1") {
        const state = this.props.router.location.state;
        const slug = this.props.match.params.slug;
        if (slug) {
          history.replace(`/invoices/${slug}/1`, state);
        } else {
          history.replace("/invoices/1", state);
        }
      } else {
        this.handleFilter();
      }
    });
  };

  /**
   *
   * @returns {Promise<void>}
   */
  handleFilter = async () => {
    const statusFilter = this.state.filter.find(
      (x) => x.fieldName === "status"
    );
    const status =
      statusFilter && statusFilter.value ? [statusFilter.value] : null;

    const nameFilter = this.state.filter.find((x) => x.fieldName === "name");
    const name = nameFilter ? nameFilter.value : null;

    // const fromDateFilter = this.state.filter.find(x => x.fieldName === "fromDate");
    // const fromDate = fromDateFilter ? fromDateFilter.value : null;

    // const toDateFilter = this.state.filter.find(x => x.fieldName === "toDate");
    // const toDate = toDateFilter ? toDateFilter.value : null;

    const clientFilter = this.state.filter.find(
      (x) => x.fieldName === "clientFilter"
    );
    const clients = clientFilter ? [clientFilter.value] : null;

    const is_archivedFilter = this.state.filter.find(
      (x) => x.fieldName === "is_archived"
    );
    const is_archived = is_archivedFilter ? is_archivedFilter.value : null;

    const is_trashFilter = this.state.filter.find(
      (x) => x.fieldName === "is_trash"
    );
    const is_trash = is_trashFilter ? is_trashFilter.value : null;

    await this.props.fetchInvoices(
      this.props.match.params.page,
      this.invoicesDataTableConfig.perPage,
      {
        status,
        clients,
        q: name,
        // from: fromDate,
        // to: toDate,
        sort_by: this.state.sortBy,
        order_by: this.state.orderBy,
        is_archived,
        is_trash,
      }
    );

    window.updateJQuery();
  };

  /**
   *
   */
  onClearAll = () => {
    const filter = [];
    this.setState(
      {
        filter,
        sortBy: null,
        orderBy: null,
        isFiltersApplied: false,
      },
      () => {
        this.onFilter(filter);
      }
    );
  };

  /**
   *
   * @param column
   * @param orderBy
   */
  onTableSort = (column, orderBy) => {
    this.setState(
      {
        sortBy: column.selector,
        orderBy,
      },
      this.handleFilter
    );
  };

  /**
   *
   * @param collapse
   */
  onToggleFilters = (collapse) => {
    this.props.collapseFilter("InvoicesPage", collapse);
  };

  /**
   *
   * @param row
   * @returns {function(...[*]=)}
   */
  onStatusChange = (row) => (value) => {
    if (value) {
      this.props.clearErrors();

      this.setState(
        {
          editBidId: row.id,
        },
        async () => {
          await this.props.editBid(row.id, { status: value });
          this.setState({
            editBidId: null,
          });
          window.updateJQuery();
        }
      );
    }
  };

  /**
   *
   * @returns {XML}
   */
  render() {
    const invoices = this.props.invoices;
    const collection = invoices.collection;

    const paginationIndicator =
      invoices.fetch && collection ? <LoadingIndicator isModal /> : null;

    const pagination =
      collection && collection.length ? (
        <DataGridPagination
          onChangePage={this.onChangePage}
          pagination={invoices.pagination}
        />
      ) : null;

    const tableColumns = filterAllowedRoles(
      this.props.user.model,
      this.invoicesDataTableConfig.columns
    );

    const allowedFilters = filterAllowedRoles(
      this.props.user.model,
      this.invoicesDataTableConfig.filters
    );

    const expandableRowsComponent = <BidInvoicesTableExpandable />;

    const dataTable = collection ? (
      <div className="data-table-themed_cells-nowrap">
        <CustomizableDataTable
          columns={tableColumns}
          collection={collection}
          showFilters={true}
          collapseFilters={this.props.app.invoicesPageFilterCollapsed}
          onToggleFilters={this.onToggleFilters}
          filters={allowedFilters}
          filterSelectorHtmlClass="js-invoices-filter"
          responsive={false}
          className={collection.length ? null : "data-table-empty"}
          onFilter={this.onFilter}
          isFiltersApplied={this.state.isFiltersApplied}
          onClearAll={this.onClearAll}
          expandableRows={true}
          expandableRowsComponent={expandableRowsComponent}
          footerComponent={pagination}
          onSort={this.onTableSort}
          sortServer
          defaultSortField={this.state.sortBy}
          defaultSortAsc={this.state.orderBy === "asc"}
        />
      </div>
    ) : null;

    const staticAlert =
      collection && !collection.length ? (
        <HasRights
          allowedRoles={["admin", "producer", "accountant"]}
          user={this.props.user.model}
        >
          <StaticAlert header={staticAlertHeader} body={staticAlertBody} />
        </HasRights>
      ) : null;

    let pageTitle = "Invoices";
    if (
      this.state.filter.find((x) => x.fieldName === "is_archived") &&
      this.state.filter.find((x) => x.fieldName === "is_archived").value
    ) {
      pageTitle = "Archived Invoices";
    } else if (
      this.state.filter.find((x) => x.fieldName === "is_trash") &&
      this.state.filter.find((x) => x.fieldName === "is_trash").value
    ) {
      pageTitle = "Trash Invoices";
    }
    return (
      <PageTemplate header={pageTitle}>
        {collection === null && <LoadingIndicator />}
        {dataTable}
        {staticAlert}
        {paginationIndicator}
      </PageTemplate>
    );
  }
}

const mapStateToProps = (state) => ({
  app: state.app,
  user: state.user,
  statuses: state.statuses,
  invoices: state.invoices,
  bid: state.bid,
  filtersReducer: state.filtersReducer,
  router: state.router,
});

const mapDispatchToProps = (dispatch) => ({
  fetchInvoices: async (page, limit, filters, type = "InvoicesPage") =>
    await dispatch(invoicesActions.fetchInvoices(page, limit, filters, type)),
  collapseFilter: (type, collapse) =>
    dispatch(appActions.collapseFilter(type, collapse)),
  editBid: async (id, data) => await dispatch(bidActions.editBid(id, data)),
  clearErrors: () => dispatch(appActions.clearErrors()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InvoicesPage));
