import React, {Component} from 'react';

import * as usersActions from "../actions/users";
import connect from "react-redux/es/connect/connect";
import {withRouter} from "react-router-dom";

import ModalTemplate from './ModalTemplate';

import ProfileBasicForm from '../components/ProfileBasicForm';
import LoadingIndicator from "../components/LoadingIndicator";

class UsersEditModal extends Component {

  constructor(props) {
    super(props);

    this.state = {editUser: null};
  }

  /**
   *
   * @returns {Promise<void>}
   */
  async componentDidMount() {
    const users = this.props.users;
    const userId = this.props.match.params.id;
    let editUser = users.collection.find(item => item.id === userId);

    if (!editUser) {
      await this.props.fetchUser(userId);
    } else {
      this.setState({editUser});
    }
  }

  /**
   *
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    const users = this.props.users;
    const userId = this.props.match.params.id;
    const editUser = users.collection.find(i => i.id === userId);

    if (!this.state.editUser && editUser) {
      this.setState({editUser});
    }

    if (this.state.editUser !== null) {
      window.updateJQuery();
    }
  }

  /**
   *
   * @returns {XML}
   */
  render() {
    const user = this.props.user;
    const users = this.props.users;
    const allowRoleEdit = user.model ? ['admin', 'producer'].indexOf(user.model.role) !== -1 : false;
    const allowEmailEdit = user.model && user.model.role === 'admin';
    const isLoading = users.update || users.fetchUser;
    const updateSuccess = users.updateSuccess;
    const canSuspendAccount =  user.model ? ['admin'].indexOf(user.model.role) !== -1 : false;

    return (
      <ModalTemplate
        title="Edit Profile"
        onClose={this.props.onClose}
      >
        {this.state.editUser ? (
          <ProfileBasicForm
            profile={this.state.editUser}
            allowRoleEdit={allowRoleEdit}
            allowEmailEdit={allowEmailEdit}
            canSuspendAccount={canSuspendAccount}
            isLoading={isLoading}
            onSubmit={this.props.editUser}
            updateSuccess={updateSuccess}
            onClose={this.props.onClose}
            error={users.updateError}
          />
        ) : <LoadingIndicator/>}

      </ModalTemplate>
    );
  }
}


const mapStateToProps = state => ({
  user: state.user,
  users: state.users,
});

const mapDispatchToProps = dispatch => ({
  fetchUsers: () => dispatch(usersActions.fetch()),
  fetchUser: async id => await dispatch(usersActions.fetchUser(id)),
  editUser: (id, data) => dispatch(usersActions.editUser(id, data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(UsersEditModal)
);
