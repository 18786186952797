export const getSelectedRowsExpandButtons = () => {
  const allRows = document.querySelectorAll(".rdt_TableRow");

  // iterate through all rows and create an array of expandButtons of all items
  // that have first rdt_TableCell class with an input that is checked
  return Array.from(allRows)
    .filter((row) => {
      const firstCell = row.querySelector(".rdt_TableCell");
      if (firstCell) {
        const input = firstCell.querySelector("input");
        if (input) {
          return input.checked;
        }
      }
      return false;
    })
    .map((row) => {
      const buttons = row.querySelectorAll("button");
      return Array.from(buttons).filter(
        (button) => !!button.attributes["data-testid"]
      )[0];
    })
    .filter((button) => !!button);
};
