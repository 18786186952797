/**
 * Created by piotr.pozniak@thebeaverhead.com on 17/11/2021.
 */
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as bidsActions from "../actions/bids";

export function useBidsStore() {
  const dispatch = useDispatch();
  const _bids = useSelector((store) => store.bids);

  const fetchBids = useCallback(
    async (page, limit, filters, type) =>
      await dispatch(bidsActions.fetchBids(page, limit, filters, type)),
    [dispatch]
  );

  const deleteBid = useCallback(
    async (id) => await dispatch(bidsActions.deleteBid(id)),
    [dispatch]
  );

  return {
    bids: _bids,
    fetchBids,
    deleteBid,
  };
}
